import { EUserRole } from '../enums/user.enum';

export const UserRolesMock = {
    [EUserRole.Worker]: {
        title: 'Мастер',
    },
    [EUserRole.Client]: {
        title: 'Клиент',
    },
    [EUserRole.Backoffice]: {
        title: 'Админ',
    },
    [EUserRole.Partner]: {
        title: 'Партнер',
    },
};
