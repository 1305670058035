import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider, ruRU } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import ym, { YMInitializer } from 'react-yandex-metrika';

import Dialog from './components/Dialog';
import Toast from './components/Toast';
import AppRoutes from './routes';
import { store } from './store/store';
import 'dayjs/locale/ru';
import theme from './theme';
import './App.scss';

function App() {
    useEffect(() => {
        if (!process.env.REACT_APP_YANDEX_METRIKA) return;
        ym('hit', window.location.pathname);
    }, [window.location.pathname]);

    return (
        <Provider store={store}>
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={'ru'}
                localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
            >
                <ThemeProvider theme={theme}>
                    <HashRouter>
                        <CssBaseline />
                        <AppRoutes />
                        <Dialog />
                        <Toast />
                        {!!process.env.REACT_APP_YANDEX_METRIKA && (
                            <YMInitializer
                                accounts={[Number(process.env.REACT_APP_YANDEX_METRIKA)]}
                                options={{
                                    clickmap: true,
                                    trackLinks: true,
                                    accurateTrackBounce: true,
                                    webvisor: true,
                                    ecommerce: 'dataLayer',
                                    defer: true,
                                }}
                            />
                        )}
                    </HashRouter>
                </ThemeProvider>
            </LocalizationProvider>
        </Provider>
    );
}

export default App;
