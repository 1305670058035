import { MoreVert } from '@mui/icons-material';
import { Button, IconButton, Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { EPartsOrdersStatus } from '../../../../../../enums/partsOrders.enum';
import {
    setIsOpenCancelOrderAction,
    setIsOpenNextStatusOrderAction,
    setSetSelectedOrdersAction,
} from '../../../../../../store/actions/orders';
import { useAppDispatch } from '../../../../../../store/hooks';
import { issueSelector } from '../../../../../../store/selectors/issuesSelectors';
import { selectedOrdersSelector } from '../../../../../../store/selectors/ordersSelectors';
import { getNextStatusTitle } from '../../../../../../utils/helpers/getNextStatusTitle';

interface IButtonGroup {
    title: string;
    color: 'inherit' | 'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | undefined;
    handle: () => void;
    disabled?: boolean;
    tooltipTitle?: string;
}

function MenuButton() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const selectedOrders = useSelector(selectedOrdersSelector);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const { worker } = issue || {};

    function getButtonGroupItems(): IButtonGroup[] {
        const buttonGroupItems: IButtonGroup[] = [
            {
                title: `Перевод запчастей в "${
                    selectedOrders && selectedOrders.length && getNextStatusTitle({ status: selectedOrders[0].status })
                }"`,
                color: 'inherit',
                disabled: false,
                handle: handleNextStatusOrder,
            },
        ];
        if (selectedOrders && !worker && selectedOrders[0].status === EPartsOrdersStatus.InStock) {
            buttonGroupItems[0].disabled = true;
            buttonGroupItems[0].tooltipTitle = 'Необходимо назначить мастера';
        }

        buttonGroupItems.push({
            title: 'Отказ от запчастей',
            color: 'error',
            disabled: false,
            handle: handleCancelOrder,
        });

        buttonGroupItems.push({
            title: 'Снять выделение',
            color: 'inherit',
            disabled: false,
            handle: handleClearSelectedOrders,
        });

        return buttonGroupItems;
    }

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    function handleNextStatusOrder() {
        dispatch(setIsOpenNextStatusOrderAction(true));
    }

    function handleCancelOrder() {
        dispatch(setIsOpenCancelOrderAction({ isOpen: true, type: null }));
    }

    function handleClearSelectedOrders() {
        dispatch(setSetSelectedOrdersAction(null));
    }

    return selectedOrders && selectedOrders.length ? (
        <>
            <IconButton
                onClick={handlePopoverClick}
                disableRipple
                sx={{ position: 'absolute', right: 0 }}
            >
                <MoreVert />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="options-container">
                    {getButtonGroupItems().map((item) => {
                        return (
                            <Tooltip
                                key={item.title}
                                title={item.tooltipTitle}
                                placement="top"
                                followCursor
                            >
                                <div>
                                    <Button
                                        sx={{ width: '100%' }}
                                        className="option-item"
                                        color={item.color}
                                        disabled={item.disabled}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            item.handle();
                                            handlePopoverClose(event);
                                        }}
                                    >
                                        {item.title}
                                    </Button>
                                </div>
                            </Tooltip>
                        );
                    })}
                </div>
            </Popover>
        </>
    ) : null;
}

export default MenuButton;
