import { EIssueHelpType } from '../../enums/issues.enum';
import { ISpecialization } from '../../interfaces/specializations';

export const getSubOptionsCountString = (specializationsList: ISpecialization[], helpType: EIssueHelpType): string => {
    const subOptionsCount = specializationsList.reduce((sum, spec) => {
        if (spec.help_type === helpType) {
            // eslint-disable-next-line no-param-reassign
            sum += 1;
        }
        return sum;
    }, 0);
    return subOptionsCount ? `(${subOptionsCount})` : '';
};
