import { EChatGroupByUserActions } from '../../enums/actions.enum';
import { IChatGroupByUser, IChatGroupByUserState } from '../../interfaces/chatGroupByUsers';

export const setChatGroupByUserAction = (payload: IChatGroupByUser | null) => ({
    type: EChatGroupByUserActions.SetChatGroupByUserDetail,
    payload,
});

export const setChatGroupByUserFiltersAction = (payload: IChatGroupByUserState['filters']) => ({
    type: EChatGroupByUserActions.SetChatGroupByUserFilters,
    payload,
});

export const updateChatGroupByUserAction = (payload: IChatGroupByUser[]) => ({
    type: EChatGroupByUserActions.UpdateChatGroupByUserDetail,
    payload,
});
