import dayjs from 'dayjs';

import 'dayjs/locale/ru';
import { IIssue } from '../../interfaces/issues';

interface IGetTimeWindowParams {
    time_window_end_datetime_msk?: IIssue['time_window_end_datetime_msk'];
    time_window_start_datetime_msk?: IIssue['time_window_start_datetime_msk'];
}
export const getTimeWindowWithAllDate = ({
    time_window_start_datetime_msk: start,
    time_window_end_datetime_msk: end,
}: IGetTimeWindowParams): string => {
    if (!start && !end) return 'Не выбрана';
    const startD = dayjs(start).locale('ru');
    const endD = dayjs(end).locale('ru');

    return `${startD.format('DD.MM.YYYY')} ${startD.format('HH:mm')} - ${endD.format('DD.MM.YYYY')} ${endD.format(
        'HH:mm',
    )}`;
};
