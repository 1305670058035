import { NoteAddOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EditIssuePricesForm from './EditIssuePricesForm';

import {
    setFormValuesEditIssuePricesAction,
    setStaticPricesEditIssuePricesAction,
} from '../../../../../store/actions/edit/editIssuePrices';
import { useAppDispatch } from '../../../../../store/hooks';
import { dialogTitleEditIssuePricesSelector } from '../../../../../store/selectors/edit/editIssuePricesSelectors';

import './EditIssuePrices.scss';

interface IProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

function EditIssuePrices({ isOpen, setIsOpen }: IProps) {
    const dispatch = useAppDispatch();

    const dialogTitle = useSelector(dialogTitleEditIssuePricesSelector);

    function onClose() {
        setIsOpen(false);
        dispatch(setFormValuesEditIssuePricesAction(null));
        dispatch(setStaticPricesEditIssuePricesAction(null));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <Box className="edit-issue-prices-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <NoteAddOutlined className="title-icon" /> <div className="title-text">{dialogTitle}</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditIssuePricesForm onClose={onClose} />
            </DialogContent>
        </Dialog>
    );
}

export default EditIssuePrices;
