import { EPartsOrdersStatus } from '../../enums/partsOrders.enum';
import { partsOrdersStatusMock } from '../../mockData/partsOrders';

interface getNextStatusTitleParams {
    status: EPartsOrdersStatus | null;
}

export const getNextStatusTitle = ({ status }: getNextStatusTitleParams): string => {
    if (!status) return '';
    switch (status) {
        case EPartsOrdersStatus.InSearch:
            return partsOrdersStatusMock[EPartsOrdersStatus.WaitingApproval].title;
        case EPartsOrdersStatus.WaitingApproval:
            return partsOrdersStatusMock[EPartsOrdersStatus.InProgress].title;
        case EPartsOrdersStatus.InProgress:
            return partsOrdersStatusMock[EPartsOrdersStatus.Awaiting].title;
        case EPartsOrdersStatus.Awaiting:
            return partsOrdersStatusMock[EPartsOrdersStatus.InStock].title;
        case EPartsOrdersStatus.InStock:
            return partsOrdersStatusMock[EPartsOrdersStatus.WorkerShipped].title;
        case EPartsOrdersStatus.WorkerShipped:
            return partsOrdersStatusMock[EPartsOrdersStatus.WaitingDelivery].title;
        case EPartsOrdersStatus.WaitingDelivery:
            return partsOrdersStatusMock[EPartsOrdersStatus.Received].title;
        default:
            return '';
    }
};
