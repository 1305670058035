export const DeleteBasket = () => {
    return (
        <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.4"
                d="M14.9787 6.74009C14.9787 6.80129 14.4991 12.8677 14.2251 15.4208C14.0536 16.9876 13.0435 17.9379 11.5284 17.9649C10.3643 17.991 9.22474 18 8.10353 18C6.91317 18 5.74907 17.991 4.61911 17.9649C3.1548 17.9298 2.14387 16.9606 1.98107 15.4208C1.69924 12.8587 1.22835 6.80129 1.21959 6.74009C1.21084 6.5556 1.27036 6.38012 1.39114 6.23793C1.51018 6.10654 1.68173 6.02734 1.86204 6.02734H14.345C14.5245 6.02734 14.6873 6.10654 14.8159 6.23793C14.9358 6.38012 14.9962 6.5556 14.9787 6.74009Z"
                fill="#F56767"
            />
            <path
                d="M16.1994 3.57903C16.1994 3.20916 15.9079 2.91938 15.5578 2.91938H12.9337C12.3998 2.91938 11.9359 2.53961 11.8169 2.00415L11.6699 1.3481C11.4642 0.555258 10.7543 0 9.95786 0H6.24237C5.43713 0 4.73429 0.555258 4.52073 1.3913L4.38331 2.00505C4.2634 2.53961 3.79951 2.91938 3.26648 2.91938H0.642442C0.291462 2.91938 0 3.20916 0 3.57903V3.92101C0 4.28188 0.291462 4.58066 0.642442 4.58066H15.5578C15.9079 4.58066 16.1994 4.28188 16.1994 3.92101V3.57903Z"
                fill="#F56767"
            />
        </svg>
    );
};
