import { ECategoriesActions } from '../../enums/actions.enum';
import { ICategoriesState, TCategoriesActions } from '../../interfaces/categories';

const initState: ICategoriesState = {
    category: null,
    categories: null,
    search: null,
    pagination: { page: 0, size: 50, total: 0 },
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const categoriesReducer = (state = initialState, action: TCategoriesActions): ICategoriesState => {
    switch (action.type) {
        case ECategoriesActions.SetCategory:
            return {
                ...state,
                category: action.payload,
            };
        case ECategoriesActions.SetCategories:
            return {
                ...state,
                categories: action.payload,
            };
        case ECategoriesActions.SetSearchCategories:
            return {
                ...state,
                search: action.payload,
            };
        case ECategoriesActions.SetCategoriesPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        default:
            return state;
    }
};
