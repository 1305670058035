import { EChatsActions } from '../../enums/actions.enum';
import { IChatsState, TChatsActions } from '../../interfaces/chats';

const initState: IChatsState = {
    chats: null,
    isChatsTableLoader: false,
    pagination: { page: 0, size: 50, total: 0 },
    filters: null,
    sortOptions: new Map().set('unseen_count', 'desc'),
    totalUnseenCount: null,
    totalUnseenCountIssues: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const chatsReducer = (state = initialState, action: TChatsActions): IChatsState => {
    switch (action.type) {
        case EChatsActions.SetChats:
            return {
                ...state,
                chats: action.payload,
            };
        case EChatsActions.SetIsChatsTableLoader:
            return {
                ...state,
                isChatsTableLoader: action.payload,
            };
        case EChatsActions.SetChatsPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        case EChatsActions.SetChatsFilters:
            return {
                ...state,
                filters: action.payload,
            };
        case EChatsActions.SetChatsSortOptions:
            return {
                ...state,
                sortOptions: new Map(state.sortOptions).set(action.payload.sortField, action.payload.direction),
            };
        case EChatsActions.SetTotalUnseenCount:
            return {
                ...state,
                totalUnseenCount: action.payload,
            };
        case EChatsActions.SetTotalUnseenIssuesCount:
            return {
                ...state,
                totalUnseenCountIssues: action.payload,
            };
        default:
            return state;
    }
};
