import { workersApi } from 'api';
import { Button } from 'components/Button';
import { withWrapper } from 'hocs/with-wrapper';
import { useIntersection } from 'hooks/useIntersection';
import { fetchTransactions } from 'pages/accounting/transactions/store';
import { useAppDispatch } from 'store/hooks';
import { getFullName } from 'utils/helpers/getFullName';

import { Search } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
    CircularProgress,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputBaseComponentProps,
    Radio,
    RadioGroup,
    styled,
    TextField,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { IWorker } from 'interfaces/workers';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useDebouncedCallback } from 'use-lodash-debounce';

type Props = {
    open: boolean;
    defaultWorker?: IWorker;
    onClose: () => void;
    onSuccess?: () => void;
};

export const CorrectionModal: FC<Props> = ({ open, onClose, onSuccess, defaultWorker }) => {
    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [error, setError] = useState(true);

    const [type, setType] = useState('withdraw');
    const isWithdraw = type === 'withdraw';

    const [workers, setWorkers] = useState<IWorker[]>([]);
    const [worker, setWorker] = useState<IWorker | null>(null);

    const [loading, setLoading] = useState(false);
    const [sendLoading, setSendLoading] = useState(false);
    const [, forceUpdate] = useState(false);

    const [amount, setAmount] = useState(0);
    const [comment, setComment] = useState('');

    const page = useRef(1);
    const total = useRef(0);
    const search = useRef<HTMLInputElement>(null);

    const [ref] = useIntersection(
        {
            rootId: 'workers-search',
            onViewInter: async () => {
                if (!workers.length || workers.length >= total.current) return;

                page.current++;

                const { data } = await workersApi.getWorkersNew({ page: page.current, search: search.current?.value });

                setWorkers((prev) => [...prev, ...data.items]);
            },
        },
        [workers.length],
    );

    const handleSend = () => {
        setSendLoading(true);

        const data = {
            worker_id: worker?.id,
            amount: isWithdraw ? -amount : amount,
            transaction_type: 'correction',
            comment,
        };

        workersApi
            .doTransaction({ items: [data] })
            .then(() => {
                onClose();
                setConfirmOpen(false);
                enqueueSnackbar('Баланс успешно изменен', {
                    variant: 'success',
                });
                dispatch(fetchTransactions());
                onSuccess?.();
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
            .finally(() => setSendLoading(false));
    };

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setWorker(null);
        setLoading(true);
        if (search.current?.value) {
            workersApi
                .getWorkersNew({ search: e.target.value })
                .then(({ data }) => {
                    setWorkers(data.items);
                    total.current = data.total;
                })
                .finally(() => setLoading(false));
        } else forceUpdate((prev) => !prev);
    };

    const handleSearchDebounced = useDebouncedCallback(handleSearch, 700);

    useEffect(() => {
        if (worker && comment.trim() && amount) {
            setError(false);
        } else setError(true);
    }, [worker, comment, amount]);

    useEffect(() => {
        setWorker(defaultWorker ?? null);
        setWorkers([]);
        setType('withdraw');
        setError(true);
        setComment('');
        setAmount(0);
    }, [open]);

    const renderItems = () => {
        if (!search.current?.value) return null;
        if (loading) return withWrapper(StateContainer, () => <CircularProgress />);
        if (!workers.length && !worker) return withWrapper(StateContainer, () => <>Ничего не найдено</>);

        return workers.map((item) => (
            <WorkerItem
                onClick={() => {
                    if (!search.current) return;
                    search.current.value = `${item.last_name} ${item.first_name} ${item.middle_name}`;
                    setWorker(item);
                    setWorkers([]);
                }}
                key={item.id}
            >
                {getFullName(item) || ''}
            </WorkerItem>
        ));
    };

    return (
        <>
            <Dialog
                transitionDuration={{ exit: 0, enter: 200 }}
                maxWidth="xs"
                fullWidth
                onClose={onClose}
                open={open}
            >
                <Title>Корректировка</Title>
                <CloseButton onClick={onClose}>
                    <CloseIcon />
                </CloseButton>
                <DialogContent sx={{ paddingY: 0 }}>
                    <Wrapper>
                        <FormControl>
                            <RadioGroup
                                defaultValue="withdraw"
                                row
                                onChange={(e) => setType(e.target.value)}
                            >
                                <FormControlLabel
                                    value="withdraw"
                                    control={<Radio />}
                                    label="Списание"
                                />
                                <FormControlLabel
                                    value="deposit"
                                    control={<Radio />}
                                    label="Начисление"
                                />
                            </RadioGroup>
                        </FormControl>

                        {!defaultWorker ? (
                            <div>
                                <SearchTextField
                                    fullWidth
                                    inputRef={search}
                                    placeholder="Мастер"
                                    variant="outlined"
                                    size="small"
                                    onChange={handleSearchDebounced}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                    isExpand={Boolean(search.current?.value && workers.length)}
                                />
                                <ScrollContainer
                                    id="workers-search"
                                    isShow={Boolean(search.current?.value && workers.length)}
                                >
                                    {renderItems()}
                                    <div ref={ref} />
                                </ScrollContainer>
                            </div>
                        ) : null}

                        <NumericFormat
                            fullWidth
                            placeholder="Сумма"
                            customInput={TextField}
                            suffix=" ₽"
                            prefix={isWithdraw ? '- ' : '+ '}
                            onValueChange={(e) => setAmount(+e.value)}
                            inputProps={InputProps}
                            thousandSeparator=" "
                            allowNegative={false}
                            decimalScale={2}
                        />

                        <TextField
                            rows={4}
                            multiline
                            fullWidth
                            placeholder="Комментарий"
                            variant="outlined"
                            size="small"
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Wrapper>
                </DialogContent>
                <DialogActions sx={{ padding: '20px 24px' }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                    {isWithdraw ? (
                        <Button
                            disabled={error}
                            variant="contained"
                            onClick={() => setConfirmOpen(true)}
                        >
                            Списать
                        </Button>
                    ) : (
                        <Button
                            disabled={error}
                            variant="contained"
                            onClick={() => setConfirmOpen(true)}
                        >
                            Начислить
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog
                transitionDuration={{ exit: 0, enter: 200 }}
                maxWidth="xs"
                fullWidth
                onClose={() => setConfirmOpen(false)}
                open={confirmOpen}
            >
                <Title>Подтвердите действие</Title>
                <CloseButton onClick={() => setConfirmOpen(false)}>
                    <CloseIcon />
                </CloseButton>
                <DialogContent sx={{ paddingY: 0 }}>
                    {isWithdraw
                        ? `Списать ${amount} ₽ со счета мастера ${worker?.last_name} ${worker?.first_name}?`
                        : `Начислить ${amount} ₽ на счет мастера ${worker?.last_name} ${worker?.first_name}?`}
                </DialogContent>
                <DialogActions sx={{ padding: '20px 24px' }}>
                    <Button
                        variant="outlined"
                        onClick={() => setConfirmOpen(false)}
                    >
                        Отмена
                    </Button>
                    {isWithdraw ? (
                        <Button
                            loading={sendLoading}
                            variant="contained"
                            onClick={handleSend}
                        >
                            Списать
                        </Button>
                    ) : (
                        <Button
                            loading={sendLoading}
                            variant="contained"
                            onClick={handleSend}
                        >
                            Начислить
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
};

const Title = styled(DialogTitle)({
    fontSize: '24px',
    fontWeight: 600,
});

const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
});

const SearchTextField = styled(TextField)(({ isExpand }: { isExpand: boolean }) => ({
    '& fieldset': { borderBottomRightRadius: isExpand ? 0 : '4px', borderBottomLeftRadius: isExpand ? 0 : '4px' },
}));

const ScrollContainer = styled('div')(({ isShow }: { isShow: boolean }) => ({
    overflow: 'auto',
    maxHeight: '200px',
    border: isShow ? '1px solid #cfd8dc' : 'none',
    borderTop: 'none',
    borderRadius: '4px',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
}));

const CloseButton = styled(IconButton)({
    position: 'absolute',
    right: 8,
    top: 8,
    color: `${(theme: any) => theme.palette.grey[500]}`,
});

const StateContainer = styled('div')({
    display: 'flex',
    padding: '20px 0',
    alignItems: 'center',
    justifyContent: 'center',
});

const WorkerItem = styled('div')({
    padding: '8px 14px',
    borderBottom: '1px solid #cfd8dc',
    cursor: 'pointer',
    ':hover': {
        backgroundColor: '#E1E4FA',
    },
});

const InputProps: InputBaseComponentProps = {
    style: {
        padding: '8.5px 14px',
    },
};
