import Typography from '@mui/material/Typography';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ERoutes } from '../../../../../../enums/routes.enum';
import { ISearchItem } from '../../../../../../interfaces/search';
import { issueHelpTypeNamesMock } from '../../../../../../mockData/issues';
import { setIsOpenSearchAction } from '../../../../../../store/actions/search';
import { useAppDispatch } from '../../../../../../store/hooks';
import { textSearchSelector } from '../../../../../../store/selectors/searchSelectors';

interface IProps {
    items: ISearchItem[];
}

function IssueCodes({ items }: IProps) {
    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const textSearch = useSelector(textSearchSelector);

    return (
        <>
            {items.map(({ data }) => {
                const { issue_code } = data;

                return issue_code ? (
                    <div
                        className="search-item"
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                            navigate(`/${ERoutes.Issues}/${data.id}`);
                            dispatch(setIsOpenSearchAction(false));
                        }}
                        key={data.id}
                    >
                        <Typography>
                            Номер заказа{' '}
                            <Highlighter
                                searchWords={[textSearch || '']}
                                autoEscape
                                textToHighlight={`${issue_code}`}
                            />
                        </Typography>
                        <span className="search-item-helper-text">
                            Заявка № {data.oid} {issueHelpTypeNamesMock[data.help_type]}
                        </span>
                    </div>
                ) : null;
            })}
        </>
    );
}

export default IssueCodes;
