import { MoreVert } from '@mui/icons-material';
import { Button, IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { EPermissionsKeys, EPermissionsValues } from '../../../../../enums/permissions.enum';
import { IComment } from '../../../../../interfaces/issues';
import { profileSelector } from '../../../../../store/selectors/profileSelectors';
import { isAccessDenied } from '../../../../../utils/helpers/isAccesDenite';

interface IProps {
    comment: IComment;
    openDialog: (commentId?: string, comment?: string) => void;
    openDeleteDialog: (commentId: string) => void;
}

interface IButtonGroupItem {
    title: string;
    color: 'inherit' | 'error';
    handle: () => void;
}

function EditGroupButtons({ comment, openDialog, openDeleteDialog }: IProps) {
    const profile = useSelector(profileSelector);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    function getButtonGroupItems(): IButtonGroupItem[] {
        const buttonGroupItems: IButtonGroupItem[] = [];

        if (!profile?.is_superadmin && profile?.id === comment.user.id) {
            buttonGroupItems.push({
                title: 'Редактировать комментарий',
                color: 'inherit',
                handle: () => openDialog(comment.id, comment.comment),
            });
        } else if (profile?.is_superadmin) {
            if (
                isAccessDenied({
                    profile,
                    entityRole: EPermissionsKeys.IssueComments,
                    action: EPermissionsValues.Update,
                })
            ) {
                buttonGroupItems.push({
                    title: 'Редактировать комментарий',
                    color: 'inherit',
                    handle: () => openDialog(comment.id, comment.comment),
                });
            }
        }

        if (!profile?.is_superadmin && profile?.id === comment.user.id) {
            buttonGroupItems.push({
                title: 'Удалить комментарий',
                color: 'error',
                handle: () => openDeleteDialog(comment.id),
            });
        } else if (profile?.is_superadmin) {
            if (
                isAccessDenied({
                    profile,
                    entityRole: EPermissionsKeys.IssueComments,
                    action: EPermissionsValues.Delete,
                })
            ) {
                buttonGroupItems.push({
                    title: 'Удалить комментарий',
                    color: 'error',
                    handle: () => openDeleteDialog(comment.id),
                });
            }
        }
        return buttonGroupItems;
    }

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {!!getButtonGroupItems().length && (
                <>
                    <IconButton
                        onClick={handlePopoverClick}
                        disableRipple
                    >
                        <MoreVert />
                    </IconButton>
                    <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <div className="options-container">
                            {getButtonGroupItems().map((item) => {
                                return (
                                    <Button
                                        key={item.title}
                                        className="option-item"
                                        color={item.color}
                                        onClick={() => {
                                            item.handle();
                                            handlePopoverClose();
                                        }}
                                    >
                                        {item.title}
                                    </Button>
                                );
                            })}
                        </div>
                    </Popover>
                </>
            )}
        </>
    );
}

export default EditGroupButtons;
