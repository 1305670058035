import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ categories }: RootState) => categories;

export const categoriesSelector = createSelector(state, ({ categories }) => categories);
export const categoriesOptionsSelector = createSelector(state, ({ categories }) => {
    return (
        categories?.map((category) => {
            const { id, name } = category;
            return { value: id, label: name || '' };
        }) || null
    );
});
export const categoriesSearchSelector = createSelector(state, ({ search }) => search);
export const categoriesPaginationSelector = createSelector(state, ({ pagination }) => pagination);
