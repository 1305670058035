import { workersApi } from 'api';
import { Button } from 'components/Button';
import { getWorkerThunk } from 'store/actions/worker';
import { useAppDispatch } from 'store/hooks';
import { workerDetailSelector } from 'store/selectors/workerSelectors';

import { Add, Edit, NearMe, NoteAddOutlined, Remove, RestorePageOutlined } from '@mui/icons-material';
import { Paper, TextField, styled } from '@mui/material';
import { Map, YMaps, Placemark, Circle } from '@pbe/react-yandex-maps';
import classNames from 'classnames';
import { IWorkingArea } from 'interfaces/dicts';
import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';

import './WorkerServiceAreas.scss';

const MapButton = styled(Button)({
    height: '40px',
    width: '40px',
    minWidth: '40px',
    padding: 0,
    backgroundColor: '#fff',
    color: '#000',
    ':hover': {
        backgroundColor: '#fff',
    },
});

function RenderIf({ children, shouldRender }: { children: ReactNode; shouldRender: boolean }) {
    if (shouldRender) {
        return <> {children} </>;
    }
    return null;
}

const defaultArea = {
    id: '',
    lat: 55.75222,
    long: 37.61556,
    name: '',
    radius: 10000,
};

function WorkerServiceAreas() {
    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);

    const workingAreas = worker?.working_areas || [];

    const [loading, setLoading] = useState({
        addEdit: false,
        delete: false,
    });

    const [, forceUpdate] = useState(false);
    const [zoom, setZoom] = useState(10);
    const [mode, setMode] = useState('show');

    const [activeWorkingArea, setActiveWorkingArea] = useState(workingAreas?.[0] || defaultArea);

    const putWorkerArea = async () => {
        setLoading((p) => ({ ...p, addEdit: true }));
        await workersApi.putWorkerArea(activeWorkingArea.id, activeWorkingArea);
        setLoading((p) => ({ ...p, addEdit: false }));
        await dispatch(getWorkerThunk(worker?.id!));
        setMode('show');
        setActiveWorkingArea(activeWorkingArea);
    };

    const addWorkerArea = async () => {
        setLoading((p) => ({ ...p, addEdit: true }));
        const { data } = await workersApi.createWorkerArea({ worker_id: worker?.id!, ...activeWorkingArea });
        setLoading((p) => ({ ...p, addEdit: false }));
        await dispatch(getWorkerThunk(worker?.id!));
        setMode('show');
        setActiveWorkingArea(data as unknown as IWorkingArea);
    };

    const deleteWorkerArea = async () => {
        setLoading((p) => ({ ...p, delete: true }));
        await workersApi.deleteWorkerArea(activeWorkingArea.id);
        setLoading((p) => ({ ...p, delete: false }));
        await dispatch(getWorkerThunk(worker?.id!));
        setMode('show');
        const area = workingAreas.find((item) => item.id !== activeWorkingArea.id);
        setActiveWorkingArea(area || defaultArea);
    };

    return (
        <Paper className="service-areas">
            <div className="service-zones">
                <div className="heading">
                    <div className="title">
                        <RenderIf shouldRender={mode === 'add'}>
                            <NoteAddOutlined sx={{ color: '#0040F1', fontSize: '30px', marginRight: '10px' }} />
                            Добавить зону обслуживания
                        </RenderIf>
                        <RenderIf shouldRender={mode === 'edit'}>
                            <RestorePageOutlined sx={{ color: '#0040F1', fontSize: '30px', marginRight: '10px' }} />
                            Редактировать зону обслуживания
                        </RenderIf>
                        <RenderIf shouldRender={mode === 'show'}>Зоны обслуживания</RenderIf>
                    </div>
                    <div>
                        <RenderIf shouldRender={mode !== 'add'}>
                            <Button
                                onClick={() => {
                                    setMode('add');
                                    setActiveWorkingArea((prev) => ({
                                        ...prev,
                                        lat: prev.lat + 0.02,
                                        long: prev.long + 0.02,
                                        id: 'add',
                                        name: '',
                                    }));
                                }}
                                startIcon={<Add />}
                                color="primary"
                                variant="contained"
                            >
                                Добавить
                            </Button>
                        </RenderIf>
                    </div>
                </div>

                <RenderIf shouldRender={mode === 'add' || mode === 'edit'}>
                    <div style={{ margin: '20px 0' }}>
                        <TextField
                            onChange={(e) => setActiveWorkingArea((prev) => ({ ...prev, name: e.target.value }))}
                            sx={{ width: '100%' }}
                            variant="outlined"
                            label="Введите название зоны обслуживания"
                            value={activeWorkingArea?.name || ''}
                        />
                    </div>
                </RenderIf>

                <div className="map-wrapper">
                    <div className="map-controls">
                        <MapButton
                            onClick={() => setZoom((prev) => prev + 1)}
                            color="primary"
                            variant="contained"
                        >
                            <Add />
                        </MapButton>
                        <MapButton
                            onClick={() => setZoom((prev) => prev - 1)}
                            color="primary"
                            variant="contained"
                        >
                            <Remove />
                        </MapButton>
                        <MapButton
                            onClick={() => forceUpdate((prev) => !prev)}
                            color="primary"
                            variant="contained"
                        >
                            <NearMe />
                        </MapButton>
                    </div>

                    <div className="map-edit">
                        <RenderIf shouldRender={mode === 'add' || mode === 'edit'}>
                            <MapButton
                                sx={{ width: 'auto', padding: '0 10px' }}
                                onClick={() => {
                                    setActiveWorkingArea((prev) => ({
                                        ...prev,
                                        radius: prev.radius - 1000,
                                    }));
                                }}
                                color="primary"
                                variant="contained"
                            >
                                - 10 км
                            </MapButton>
                            <MapButton
                                sx={{ marginLeft: '10px', width: 'auto', padding: '0 10px' }}
                                onClick={() => {
                                    setActiveWorkingArea((prev) => ({
                                        ...prev,
                                        radius: prev.radius + 1000,
                                    }));
                                }}
                                color="primary"
                                variant="contained"
                            >
                                + 10 км
                            </MapButton>
                        </RenderIf>

                        <RenderIf shouldRender={mode === 'show'}>
                            <MapButton
                                onClick={() => {
                                    setMode('edit');
                                    setActiveWorkingArea(activeWorkingArea);
                                }}
                                color="primary"
                                variant="contained"
                            >
                                <Edit />
                            </MapButton>
                        </RenderIf>
                    </div>

                    <YMaps query={{ coordorder: 'longlat' }}>
                        <div style={{ position: 'relative', height: '100%' }}>
                            <Map
                                width="100%"
                                height="100%"
                                defaultState={{
                                    center: [37.61556, 55.75222],
                                    zoom: 10,
                                    controls: [],
                                }}
                                state={{
                                    center: [activeWorkingArea?.long || 37.61556, activeWorkingArea?.lat || 55.75222],
                                    zoom,
                                    controls: [],
                                }}
                            >
                                {workingAreas?.map((area) => (
                                    <React.Fragment key={area.id}>
                                        <RenderIf shouldRender={area.id === activeWorkingArea?.id && mode === 'edit'}>
                                            <Circle
                                                instanceRef={(value) => {
                                                    if (value) {
                                                        const circle = value as unknown as ymaps.Circle;
                                                        const dragEnd = () => {
                                                            setActiveWorkingArea((prev) => ({
                                                                ...prev,
                                                                long: circle.geometry?.getCoordinates()?.[0]!,
                                                                lat: circle.geometry?.getCoordinates()?.[1]!,
                                                            }));
                                                        };

                                                        (circle.events as any).types.dragend = [];
                                                        circle.events.add('dragend', dragEnd);
                                                    }
                                                }}
                                                geometry={[
                                                    [activeWorkingArea?.long, activeWorkingArea?.lat],
                                                    activeWorkingArea?.radius,
                                                ]}
                                                options={{
                                                    fillColor: '#ff000090',
                                                    strokeWidth: 0,
                                                    zIndex: 1000,
                                                    draggable: true,
                                                }}
                                            />

                                            <Placemark
                                                defaultOptions={{
                                                    preset: 'islands#greenDotIcon',
                                                }}
                                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                                geometry={[activeWorkingArea?.long, activeWorkingArea?.lat]}
                                            />
                                        </RenderIf>

                                        <RenderIf shouldRender={area.id !== activeWorkingArea?.id && mode === 'edit'}>
                                            <Circle
                                                geometry={[[area.long, area.lat], area.radius]}
                                                options={{
                                                    fillColor: '#2642ED60',
                                                    strokeWidth: 0,
                                                }}
                                            />

                                            <Placemark
                                                defaultOptions={{
                                                    preset: 'islands#greenDotIcon',
                                                }}
                                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                                geometry={[area.long, area.lat]}
                                            />
                                        </RenderIf>

                                        <RenderIf shouldRender={mode === 'show' || mode === 'add'}>
                                            <Circle
                                                geometry={[[area.long, area.lat], area.radius]}
                                                options={{
                                                    fillColor:
                                                        activeWorkingArea?.id === area.id ? '#2642ED90' : '#2642ED60',
                                                    strokeWidth: 0,
                                                }}
                                            />

                                            <Placemark
                                                defaultOptions={{
                                                    preset: 'islands#greenDotIcon',
                                                }}
                                                modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                                geometry={[area.long, area.lat]}
                                            />
                                        </RenderIf>
                                    </React.Fragment>
                                ))}
                                <RenderIf shouldRender={mode === 'add'}>
                                    <Circle
                                        instanceRef={(value) => {
                                            if (value) {
                                                const circle = value as unknown as ymaps.Circle;
                                                const dragEnd = () => {
                                                    setActiveWorkingArea((prev) => ({
                                                        ...prev,
                                                        long: circle.geometry?.getCoordinates()?.[0]!,
                                                        lat: circle.geometry?.getCoordinates()?.[1]!,
                                                    }));
                                                };

                                                (circle.events as any).types.dragend = [];
                                                circle.events.add('dragend', dragEnd);
                                            }
                                        }}
                                        geometry={[
                                            [activeWorkingArea?.long, activeWorkingArea?.lat],
                                            activeWorkingArea?.radius,
                                        ]}
                                        options={{
                                            fillColor: '#ff000090',
                                            strokeWidth: 0,
                                            zIndex: 1000,
                                            draggable: true,
                                        }}
                                    />

                                    <Placemark
                                        defaultOptions={{
                                            preset: 'islands#greenDotIcon',
                                        }}
                                        modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                        geometry={[activeWorkingArea?.long, activeWorkingArea?.lat]}
                                    />
                                </RenderIf>
                            </Map>
                        </div>
                    </YMaps>
                </div>

                <RenderIf shouldRender={mode === 'add' || mode === 'edit'}>
                    <div style={{ margin: '20px 0' }}>
                        <Button
                            onClick={() => {
                                if (mode === 'add') {
                                    addWorkerArea();
                                }
                                if (mode === 'edit') {
                                    putWorkerArea();
                                }
                            }}
                            color="primary"
                            variant="contained"
                            disabled={!activeWorkingArea?.name}
                            loading={loading.addEdit}
                        >
                            Сохранить
                        </Button>
                        <RenderIf shouldRender={mode === 'edit'}>
                            <Button
                                sx={{
                                    marginLeft: '10px',
                                    backgroundColor: '#F56767',
                                    ':hover': {
                                        backgroundColor: '#F56767',
                                    },
                                }}
                                onClick={deleteWorkerArea}
                                color="primary"
                                variant="contained"
                                loading={loading.delete}
                            >
                                Удалить
                            </Button>
                        </RenderIf>
                        <Button
                            sx={{ marginLeft: '10px' }}
                            onClick={() => {
                                setMode('show');
                                if (mode === 'add') {
                                    setActiveWorkingArea(workingAreas[0] || defaultArea);
                                }
                                if (mode === 'edit') {
                                    setActiveWorkingArea(activeWorkingArea || defaultArea);
                                }
                            }}
                            variant="outlined"
                        >
                            Отмена
                        </Button>
                    </div>
                </RenderIf>

                <div className="working-area">
                    {workingAreas?.map((area) => (
                        <button
                            key={area.id}
                            onClick={() => setActiveWorkingArea(area)}
                            className={classNames('working-area-item', area.id === activeWorkingArea?.id && 'active')}
                        >
                            <div className="working-area-title">{area.name}</div>
                        </button>
                    ))}
                </div>
            </div>
            {/* <div className="service-areas-title">
				<div className="title-text">Район обслуживания</div>
				<EditButton onClick={openEditWorkerServiceAreasModal} />
			</div>
			<div className="service-areas-container">
				{serviceAreas &&
					serviceAreas.map((serviceArea) => (
						<ServiceAreaItem key={serviceArea.id} serviceArea={serviceArea} />
					))}
			</div>
			<EditWorkerServiceAreas /> */}
        </Paper>
    );
}

export default WorkerServiceAreas;
