import { Paper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import WorkerCanceledIssueHistoryItem from './WorkerCanceledIssueHistoryItem';

import Loader from '../../../components/Loader';
import { workerCanceledIssueHistorySelector } from '../../../store/selectors/workerSelectors';
import './WorkerCanceledIssueHistory.scss';

function WorkerCanceledIssueHistory() {
    const workerCanceledIssueHistory = useSelector(workerCanceledIssueHistorySelector);

    return (
        <Paper className="canceled-issue-history">
            <div className="canceled-issue-history-title">История отказов</div>
            {!workerCanceledIssueHistory && <Loader />}
            {workerCanceledIssueHistory?.length ? (
                workerCanceledIssueHistory.map((issue, index) => (
                    <WorkerCanceledIssueHistoryItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        issue={issue}
                    />
                ))
            ) : (
                <span className="canceled-issue-history-empty-text">Пока нет заказов</span>
            )}
        </Paper>
    );
}

export default WorkerCanceledIssueHistory;
