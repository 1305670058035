import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import { IPrice } from '../../../interfaces/prices';
import { isPriceLoadingSelector } from '../../../store/selectors/pricesSelectors';
import priceFormatter from '../../../utils/helpers/priceFormatter';
import './PriceDetail.scss';

interface IProps {
    price: IPrice | null;
}

function PriceDetail({ price }: IProps) {
    const isPriceLoading = useSelector(isPriceLoadingSelector);

    const {
        name,
        amount,
        categories,
        description,
        excluded_in_price,
        included_in_price,
        additional_prices,
        is_active_on_widget,
        company,
    } = price || {};
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {isPriceLoading ? (
                <Loader />
            ) : (
                <div className="price-list">
                    <div className="price-item">
                        <div>Название</div>
                        <div>{name}</div>
                    </div>
                    <div className="price-item">
                        <div>Цена</div>
                        <div>{priceFormatter(amount)}</div>
                    </div>

                    {!!categories?.length && (
                        <div className="price-item">
                            <div>
                                Категории
                                <br />
                                техники
                            </div>
                            <div>{categories?.map((category) => category.name).join(', ')}</div>
                        </div>
                    )}
                    {description && (
                        <div className="price-item">
                            <div>Описание услуги</div>
                            <div>{description}</div>
                        </div>
                    )}
                    {company && (
                        <div className="price-item">
                            <div>Компания</div>
                            <div>{company.name}</div>
                        </div>
                    )}
                    {!!included_in_price?.length && (
                        <div className="price-item">
                            <div>
                                Что входит
                                <br />в стоимость
                            </div>
                            <div>
                                <ul>
                                    {included_in_price.map((service: any, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <li key={`${service}_${index}`}>{service}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    {!!excluded_in_price?.length && (
                        <div className="price-item">
                            <div>
                                Что не входит <br />в стоимость
                            </div>
                            <div>
                                <ul>
                                    {excluded_in_price.map((service: any, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <li key={`${service}_${index}`}>{service}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className="price-item">
                        <div>
                            Отображается <br />в виджете
                        </div>
                        <div>{is_active_on_widget ? 'Да' : 'Нет'}</div>
                    </div>
                    {!!additional_prices?.length && (
                        <div className="price-item">
                            <div>
                                Дополнит.
                                <br />
                                услуги
                            </div>
                            {/* eslint-disable-next-line no-shadow */}
                            <div>{additional_prices?.map((price) => price.name).join(', ')}</div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default PriceDetail;
