import { EIssueHelpType } from '../enums/issues.enum';
import { EWorkerStatus, EWorkerStatusColors, EWorkerVerifyStatus } from '../enums/workers.enum';

export const WorkerStatusesMock = {
    [EWorkerStatus.New]: {
        title: 'Новый',
        color: EWorkerStatusColors.RoyalBlue,
        description: 'Мастер недавно зарегистрировался, нужно проверить его данные',
        activePutStatuses: [EWorkerStatus.Testing, EWorkerStatus.Verified, EWorkerStatus.Disabled],
    },
    [EWorkerStatus.Testing]: {
        title: 'На тестировании',
        color: EWorkerStatusColors.Gold,
        description: 'Протестируйте мастера для дальнейшей работы',
        activePutStatuses: [EWorkerStatus.Verified, EWorkerStatus.Disabled],
    },
    [EWorkerStatus.Verified]: {
        title: 'Проверен',
        color: EWorkerStatusColors.MountainMeadow,
        description: 'Данные мастера проверены. Если мастер готов принимать заявки, измените статус',
        activePutStatuses: [
            EWorkerStatus.ConnectedWorkingReady,
            EWorkerStatus.ConnectedWorkingNotReady,
            EWorkerStatus.Disabled,
        ],
    },
    [EWorkerStatus.ConnectedWorkingReady]: {
        title: 'Работает (готов)',
        color: EWorkerStatusColors.MountainMeadow,
        description: 'Мастер готов для назначения на заявки',
        activePutStatuses: [EWorkerStatus.Testing, EWorkerStatus.Verified, EWorkerStatus.Disabled],
    },
    [EWorkerStatus.ConnectedWorkingNotReady]: {
        title: 'Работает (не готов)',
        color: EWorkerStatusColors.PacificBlue,
        description: 'Мастер не готов для назначения на заявки',
        activePutStatuses: [EWorkerStatus.Testing, EWorkerStatus.Verified, EWorkerStatus.Disabled],
    },
    [EWorkerStatus.Disabled]: {
        title: 'Отключен',
        color: EWorkerStatusColors.Red,
        description: 'Мастер отключен от системы',
        activePutStatuses: [
            EWorkerStatus.ConnectedWorkingReady,
            EWorkerStatus.ConnectedWorkingNotReady,
            EWorkerStatus.Testing,
            EWorkerStatus.Verified,
        ],
    },
    [EWorkerStatus.WaitingVerified]: {
        title: 'Ожидает проверки',
        color: EWorkerStatusColors.DaisyBush,
        description: 'Мастер недавно зарегистрировался. Если мастер подходит, то переведите его в тестирование',
        activePutStatuses: [EWorkerStatus.Testing, EWorkerStatus.Disabled],
    },
    [EWorkerStatus.Deleted]: {
        title: 'Удален',
        color: EWorkerStatusColors.Red,
        description: 'Мастер удалил свой профиль в приложении',
        activePutStatuses: [],
    },
} as {
    [key in EWorkerStatus]: {
        title: string;
        color: EWorkerStatusColors;
        description: string;
        activePutStatuses: EWorkerStatus[];
    };
};

export const workerStatusFiltersMock = [
    {
        title: 'На проверке',
        statuses: [EWorkerStatus.WaitingVerified, EWorkerStatus.Testing, EWorkerStatus.Verified],
    },
    {
        title: 'Подключенные',
        statuses: [EWorkerStatus.ConnectedWorkingReady, EWorkerStatus.ConnectedWorkingNotReady],
    },
    {
        title: 'Отключенные',
        statuses: [EWorkerStatus.Disabled],
    },
];

export const archivedWorkerStatusFiltersMock: EWorkerStatus[] = [EWorkerStatus.Disabled, EWorkerStatus.Deleted];

export const helpTypeForWorkersMock = [
    {
        value: EIssueHelpType.Repair,
        label: 'Ремонт',
    },
    {
        value: EIssueHelpType.Installation,
        label: 'Установка',
    },
];

export const WorkerVerifyStatusesMock = {
    [EWorkerVerifyStatus.NotVerified]: {
        title: 'Не проводилась',
        colorHex: '#e5e5e5',
        colorText: '#4f4f4f',
    },
    [EWorkerVerifyStatus.Processed]: {
        title: 'В процессе',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EWorkerVerifyStatus.Reject]: {
        title: 'Завершилась ошибкой',
        colorHex: '#EFAD4B66',
        colorText: '#EF6C00',
    },
    [EWorkerVerifyStatus.Blocked]: {
        title: 'Заблокирована',
        colorHex: '#F48FB166',
        colorText: '#D81B60',
    },
    [EWorkerVerifyStatus.Success]: {
        title: 'Прошла успешно',
        colorHex: '#5FEA7E66',
        colorText: '#388E3C',
    },
} as {
    [key in EWorkerVerifyStatus]: {
        title: string;
        colorHex: string;
        colorText: string;
    };
};
