import React from 'react';

import ImgInput from '../../../../../../../../components/inputs/ImgInput';
import { IPhotoFile } from '../../../../../../../../interfaces';

interface IProps {
    photoTitle: string;
    name: string;
    onChange: (files: IPhotoFile[] | null) => void;
    initFileResp: IPhotoFile | null;
}

function UploadPhotoFileDevice({ photoTitle, name, onChange, initFileResp }: IProps) {
    return (
        <div className="photo_item">
            <span>{photoTitle}</span>
            <ImgInput
                name={name}
                onChange={onChange}
                initFileResp={initFileResp ? [initFileResp] : []}
            />
        </div>
    );
}

export default UploadPhotoFileDevice;
