import { Paper, styled, Typography } from '@mui/material';

export const Field = styled('div')({
    display: 'flex',
    alignItems: 'center',
}); // double

export const SubTitle = styled(Typography)({
    width: '210px',
    fontSize: '14px',
});

export const FieldsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    gap: '15px',
    marginTop: '20px',
});

export const StyledPaper = styled(Paper)({
    marginTop: '20px',
    padding: '20px',
    height: '100%',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

export const CheckboxContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
});

export const CheckboxItem = styled('div')({
    display: 'flex',
    alignItems: 'start',
    gap: '8px',
});

export const ImportFile = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});
