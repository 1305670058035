import { AppDispatch } from 'store/store';

import { issuesApi, workersApi } from '../../api';
import { EArchiveActions } from '../../enums/actions.enum';
import {
    IArchiveResetAction,
    ISetArchiveIsIssuesTableLoaderAction,
    ISetArchiveIssuesAction,
    ISetArchiveIssuesFiltersAction,
    ISetArchiveIssuesPaginationAction,
    ISetArchiveIssuesTableHeadAction,
    ISetArchiveIsWorkersTableLoaderAction,
    ISetArchiveSelectedTabAction,
    ISetArchiveWorkersAction,
    ISetArchiveWorkersFiltersAction,
    ISetArchiveWorkersPaginationAction,
    ISetArchiveWorkersSortOptionsAction,
    TArchiveSelectedTab,
} from '../../interfaces/archive';
import { TDirection } from '../../interfaces/chats';
import { IIssue, IIssuesFilters, IIssuesTableHead } from '../../interfaces/issues';
import { IPagination } from '../../interfaces/pagination';
import { IWorker, IWorkersFilters, TWorkersSortField } from '../../interfaces/workers';
import { getOrderByStr } from '../../utils/helpers/getOrderByStr';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const archiveResetAction = (): IArchiveResetAction => ({
    type: EArchiveActions.ArchiveReset,
});

export const setArchiveSelectedTabAction = (payload: TArchiveSelectedTab): ISetArchiveSelectedTabAction => ({
    type: EArchiveActions.SetSelectedTab,
    payload,
});

export const setArchiveIssuesAction = (payload: IIssue[] | null): ISetArchiveIssuesAction => ({
    type: EArchiveActions.SetIssues,
    payload,
});

export const setArchiveIsIssuesTableLoaderAction = (payload: boolean): ISetArchiveIsIssuesTableLoaderAction => ({
    type: EArchiveActions.SetIsIssuesTableLoader,
    payload,
});

export const setArchiveIssuesPaginationAction = (payload: IPagination): ISetArchiveIssuesPaginationAction => ({
    type: EArchiveActions.SetIssuesPagination,
    payload,
});

export const setArchiveIssuesFiltersAction = (payload: IIssuesFilters | null): ISetArchiveIssuesFiltersAction => ({
    type: EArchiveActions.SetIssuesFilters,
    payload,
});

export const setArchiveIssuesTableHeadAction = (
    payload: IIssuesTableHead[] | null,
): ISetArchiveIssuesTableHeadAction => ({
    type: EArchiveActions.SetIssuesTableHead,
    payload,
});

export const setArchiveWorkersAction = (payload: IWorker[] | null): ISetArchiveWorkersAction => ({
    type: EArchiveActions.SetWorkers,
    payload,
});

export const setArchiveIsWorkersTableLoaderAction = (payload: boolean): ISetArchiveIsWorkersTableLoaderAction => ({
    type: EArchiveActions.SetIsWorkersTableLoader,
    payload,
});

export const setArchiveWorkersPaginationAction = (payload: IPagination): ISetArchiveWorkersPaginationAction => ({
    type: EArchiveActions.SetWorkersPagination,
    payload,
});

export const setArchiveWorkersFiltersAction = (payload: IWorkersFilters | null): ISetArchiveWorkersFiltersAction => ({
    type: EArchiveActions.SetWorkersFilters,
    payload,
});

export const setArchiveWorkersSortOptionsAction = (payload: {
    sortField: TWorkersSortField;
    direction: TDirection;
}): ISetArchiveWorkersSortOptionsAction => ({
    type: EArchiveActions.SetWorkersSortOptions,
    payload,
});

export interface IGetArchiveIssuesParams {
    filters?: IIssuesFilters | null;
    page?: IPagination['page'];
    size?: IPagination['size'];
}

export const getArchiveIssuesThunk =
    ({ filters, page, size }: IGetArchiveIssuesParams) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(setArchiveIsIssuesTableLoaderAction(true));
            const queriesObj: TArrayParams = [{ page: page ? page + 1 : 1 }, { size: size || 50 }];

            if (filters) {
                const {
                    order_by,
                    ratio_category_id__in,
                    is_archived,
                    created_at__gte,
                    created_at__lte,
                    time_window__gte,
                    time_window__lte,
                } = filters;

                if (order_by) {
                    queriesObj.push({ order_by: getOrderByStr(order_by) });
                }

                if (is_archived) {
                    queriesObj.push({ is_archived });
                }

                if (created_at__gte) {
                    queriesObj.push({ created_at__gte });
                }

                if (created_at__lte) {
                    queriesObj.push({ created_at__lte });
                }

                if (time_window__gte) {
                    queriesObj.push({ time_window__gte });
                }

                if (time_window__lte) {
                    queriesObj.push({ time_window__lte });
                }

                ratio_category_id__in?.forEach((id) => {
                    queriesObj.push({ ratio_category_id__in: id });
                });
            }
            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await issuesApi.getIssues(queries);
            const { items: issues, page: pageResp, size: sizeResp, total } = data;
            dispatch(setArchiveIssuesAction(issues));
            dispatch(setArchiveIssuesPaginationAction({ page: pageResp - 1, size: sizeResp, total }));
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            dispatch(setArchiveIsIssuesTableLoaderAction(false));
        }
    };

interface IGetArchiveWorkersThunkParams {
    page?: IPagination['page'];
    size?: IPagination['size'];
    filters?: IWorkersFilters | null;
    sortOptions?: Map<TWorkersSortField, TDirection>;
}

export const getArchiveWorkersThunk =
    ({ page, size, filters, sortOptions }: IGetArchiveWorkersThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(setArchiveIsWorkersTableLoaderAction(true));
            const queriesObj: TArrayParams = [{ page: page ? page + 1 : 1 }, { size: size || 50 }];

            if (filters) {
                const {
                    is_archived,
                    status__in,
                    area_id__in,
                    group_id__in,
                    search,
                    specialization__category_id__in,
                    specialization__help_type__in,
                } = filters;

                if (is_archived) {
                    queriesObj.push({ is_archived });
                }

                if (status__in) {
                    status__in.forEach((status) => {
                        queriesObj.push({ status__in: status });
                    });
                }

                if (area_id__in) {
                    area_id__in.forEach((area) => {
                        queriesObj.push({ area_id__in: area });
                    });
                }

                if (specialization__category_id__in) {
                    specialization__category_id__in.forEach((categoryId) => {
                        queriesObj.push({ specialization__category_id__in: categoryId });
                    });
                }

                if (specialization__help_type__in) {
                    specialization__help_type__in.forEach((helpTypeStr) => {
                        queriesObj.push({ specialization__help_type__in: helpTypeStr });
                    });
                }

                if (group_id__in) {
                    queriesObj.push({ group_id__in: group_id__in.id });
                }

                if (search) {
                    queriesObj.push({ search });
                }
            }

            if (sortOptions) {
                if (!sortOptions?.size) {
                    queriesObj.push({ order_by: 'smart' });
                }
                sortOptions.forEach((direction, sortField) => {
                    queriesObj.push({
                        order_by: !(direction === 'asc') ? `-${sortField}` : sortField,
                    });
                });
            }

            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await workersApi.getWorkers(queries);
            const { items: workers, page: pageResp, size: sizeResp, total } = data;
            dispatch(setArchiveWorkersAction(workers));
            dispatch(setArchiveWorkersPaginationAction({ page: pageResp - 1, size: sizeResp, total }));
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            dispatch(setArchiveIsWorkersTableLoaderAction(false));
        }
    };
