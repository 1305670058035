import { Button, Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import React, { MouseEvent } from 'react';

import { EIssueStatus } from '../../../enums/issues.enum';
import { issueStatusesMock } from '../../../mockData/issues';
import './IssueStatus.scss';

interface IProps {
    selectedIssueStatus: EIssueStatus[] | null;
    onChange: (issuesStatusStr: EIssueStatus[] | null) => void;
}

function IssueStatus({ onChange, selectedIssueStatus }: IProps) {
    function onClickFormGroup(e: MouseEvent) {
        e.stopPropagation();
    }

    function selectHelpType(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        checked
            ? onChange(
                  selectedIssueStatus
                      ? [...selectedIssueStatus, event.target.value as EIssueStatus]
                      : [event.target.value as EIssueStatus],
              )
            : onChange(
                  selectedIssueStatus &&
                      selectedIssueStatus.filter((helpTypeStr) => helpTypeStr !== event.target.value),
              );
    }

    function clearFilter() {
        onChange(null);
    }

    return (
        <Paper className="issues-status-wrapper">
            <div className="scroll-wrapper">
                {Object.entries(issueStatusesMock).map(([key, value]) => (
                    <FormGroup
                        key={key}
                        sx={{ marginLeft: 1 }}
                        onClick={onClickFormGroup}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={selectHelpType}
                                    value={key}
                                    checked={!!selectedIssueStatus?.some((spec) => spec === key)}
                                />
                            }
                            label={value.title}
                        />
                    </FormGroup>
                ))}
            </div>
            <Button
                fullWidth
                onClick={clearFilter}
            >
                Очистить фильтр
            </Button>
        </Paper>
    );
}

export default IssueStatus;
