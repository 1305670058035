import { chatGroupByUserSelector } from 'store/selectors/chatGroupByUserSelectors';

import { Box, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import AddChat from './components/AddChat';
import { Chat } from './components/Chat';
import { ChatsControls } from './components/ChatsControls';
import { IssueHistory } from './components/IssueHistory';
import { Reload } from './components/Reload';
import { UsersControls } from './components/UsersControls';
import { UsersList } from './components/UsersList';

import MainLayout from '../../layouts/MainLayout';
import ChatsUsersGroup from '../../modules/ChatGroupByUsersModule';

import './SupportPage.scss';

function SupportPage() {
    const user = useSelector(chatGroupByUserSelector);

    const [isIssueHistoryOpen, setIssueHistoryOpen] = useState(false);
    const [isAddChatOpen, setAddChatOpen] = useState(false);

    const type = user ? 'chat' : 'user';

    return (
        <MainLayout>
            <div className="support-page-wrapper">
                <div className="support-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        Поддержка
                    </Typography>
                </div>
                {type === 'user' ? (
                    <UsersControls onAddChatClick={() => setAddChatOpen(true)} />
                ) : (
                    <ChatsControls
                        isIssueHistoryOpen={isIssueHistoryOpen}
                        onAddChatClick={() => setAddChatOpen(true)}
                        onIssueHistoryClick={() => setIssueHistoryOpen((prev) => !prev)}
                    />
                )}
                <div className="support-page-main">
                    <Paper className="chats-users-group">
                        <ChatsUsersGroup />
                        <UsersList />
                    </Paper>
                    <div className="chats-users-chat">
                        {isIssueHistoryOpen && user ? (
                            <>
                                <Paper sx={{ height: '55%' }}>
                                    <Chat />
                                </Paper>
                                <Box className="issue-history-wrapper">
                                    <Paper sx={{ height: '100%' }}>
                                        <IssueHistory />
                                    </Paper>
                                </Box>
                            </>
                        ) : (
                            <Paper sx={{ height: '100%' }}>
                                <Chat />
                            </Paper>
                        )}
                    </div>
                    <Reload />
                </div>
                {isAddChatOpen && (
                    <AddChat
                        type={type}
                        onClose={() => setAddChatOpen(false)}
                    />
                )}
            </div>
        </MainLayout>
    );
}

export default SupportPage;
