import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import ArchiveIssueRow from './ArchiveIssueRow';

import { ESort } from '../../../../enums/tables.enum';
import {
    getArchiveIssuesThunk,
    setArchiveIssuesFiltersAction,
    setArchiveIssuesPaginationAction,
    setArchiveIssuesTableHeadAction,
} from '../../../../store/actions/archive';
import { useAppDispatch } from '../../../../store/hooks';
import {
    archiveIsIssuesTableLoaderSelector,
    archiveIssuesFiltersSelector,
    archiveIssuesPaginationSelector,
    archiveIssuesSelector,
    archiveIssuesTableHeadSelector,
} from '../../../../store/selectors/archiveSelectors';
import { debounceTimerHelper } from '../../../../utils/helpers/debounceTimer';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import IssuesTable from '../../../issues/IssuesTable';

function ArchiveIssuesTable() {
    const dispatch = useAppDispatch();

    const issues = useSelector(archiveIssuesSelector);
    const isLoading = useSelector(archiveIsIssuesTableLoaderSelector);
    const pagination = useSelector(archiveIssuesPaginationSelector);
    const filters = useSelector(archiveIssuesFiltersSelector);
    const tableHead = useSelector(archiveIssuesTableHeadSelector);

    useEffect(() => {
        dispatch(
            setArchiveIssuesPaginationAction({ page: 0, size: pagination?.size || 50, total: pagination?.total || 0 }),
        );
    }, [filters]);

    useEffect(() => {
        async function handle() {
            /* Необходимо, чтоб избежать рекурсии так как по завершении thunk меняется pagination?.page */
            await debounceTimerHelper({ callback: getIssues, delay: 200 });
        }
        handle();
    }, [pagination.page, pagination.size, filters]);

    async function getIssues() {
        try {
            await dispatch(
                getArchiveIssuesThunk({
                    page: pagination.page,
                    size: pagination.size,
                    filters: {
                        ...(filters || {}),
                        order_by: filters?.order_by || { propName: 'smart_bo', order: ESort.Desc },
                        is_archived: true,
                    },
                }),
            );
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <IssuesTable
            tableHead={tableHead}
            setTableHead={(value) => dispatch(setArchiveIssuesTableHeadAction(value))}
            filters={filters}
            isArchive
            setFilters={(value) => dispatch(setArchiveIssuesFiltersAction(value))}
            ComponentRow={ArchiveIssueRow}
            tableContent={issues}
            isLoading={isLoading}
            pagination={pagination}
            changePagination={(newPagination) => dispatch(setArchiveIssuesPaginationAction(newPagination))}
        />
    );
}

export default memo(ArchiveIssuesTable);
