import { IWorker } from '../../interfaces/map';

export const getMasterCoordsMap = (worker: IWorker): number[] => {
    switch (true) {
        case !!worker?.addresses?.length &&
            !!worker?.addresses[worker.addresses.length - 1]?.long &&
            !!worker?.addresses[worker.addresses.length - 1]?.lat: {
            return [
                Number(worker.addresses[worker.addresses.length - 1].long),
                Number(worker.addresses[worker.addresses.length - 1].lat),
            ];
        }
        default:
            return [];
    }
};
