import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editIssueStatus }: RootState) => editIssueStatus;

export const isOpenEditIssueStatusSelector = createSelector(state, ({ isOpen }) => isOpen);
export const issueIdEditIssueStatusSelector = createSelector(state, ({ issueId }) => issueId);
export const formValuesEditIssueStatusSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsEditIssueStatusSelector = createSelector(state, ({ errors }) => errors);
