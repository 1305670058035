import React from 'react';
import { useSelector } from 'react-redux';

import HeaderButton from './HeaderButton';

import { groupSelector } from '../../../../store/selectors/groupsSelector';
import './Header.scss';

function Header() {
    const group = useSelector(groupSelector);

    if (group) {
        return (
            <div className="groups-header">
                <p>{group.name}</p>
                <HeaderButton />
            </div>
        );
    }

    return null;
}

export default Header;
