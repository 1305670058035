import Accordion from 'components/Accordion';
import UserSmallData from 'components/UserSmallData';
import { EAutoAssignmentStatus, EIssueStatus } from 'enums/issues.enum';
import { IssueMasterSearchStatus } from 'mockData/issueMasterSearchStatuses';
import { ErrorsMasterSearchMock } from 'mockData/issues';
import CandidatesModal from 'modules/CandidatesModal';
import { setIsLoadingCMAction, setIsOpenCMAction } from 'store/actions/candidatesModal';
import { createIssueCandidatesThunk, startAutoAssignmentIssueThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';
import { getTimeWindowWithAllDate } from 'utils/helpers/getTimeWindowWithAllDate';

import { Button } from '@mui/material';
import { ICandidatesModalState } from 'interfaces/candidatesModal';
import React from 'react';
import { useSelector } from 'react-redux';

import Candidate from './Candidate';
import { WorkerSearchStatus } from './WorkerSearchStatus';

import './IssueWorker.scss';
import { issueForAutoAssignment } from '../../../../utils/helpers/issues';

function IssueWorker() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    function openCandidatesModalHandler() {
        dispatch(setIsOpenCMAction(true));
    }

    function autoAssignmentHandle() {
        if (!issue) return;
        try {
            dispatch(startAutoAssignmentIssueThunk([issue.id]));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function addCandidatesHandler(selectedWorkers: NonNullable<ICandidatesModalState['selectedWorkers']>) {
        if (!issue) return;
        try {
            dispatch(setIsLoadingCMAction(true));
            const workersIds = selectedWorkers.map(({ id }) => id);
            if (issue.candidates?.length) {
                issue.candidates.forEach(({ id }) => workersIds.push(id));
            }
            await dispatch(createIssueCandidatesThunk({ issueId: issue.id, workersIds }));
            dispatch(setIsOpenCMAction(false));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            dispatch(setIsLoadingCMAction(false));
        }
    }

    return issue ? (
        <Accordion
            title={
                <div className="iss-wr-title">
                    {/* eslint-disable-next-line react/no-unstable-nested-components */}
                    {(() => {
                        switch (true) {
                            case issue.status === EIssueStatus.MasterSearch ||
                                issue.status === EIssueStatus.MasterNotFound: {
                                if (issue?.auto_assignment_status === EAutoAssignmentStatus.Pending) {
                                    return (
                                        <>
                                            {IssueMasterSearchStatus[issue.auto_assignment_status!].icon} Назначение
                                            мастера
                                        </>
                                    );
                                } else if (issue?.auto_assignment_status === EAutoAssignmentStatus.Started) {
                                    return (
                                        <>
                                            {IssueMasterSearchStatus[issue.auto_assignment_status!].icon} Назначение
                                            мастера
                                        </>
                                    );
                                } else if (issue?.auto_assignment_status === EAutoAssignmentStatus.Error) {
                                    return (
                                        <>
                                            {IssueMasterSearchStatus[EAutoAssignmentStatus.Error].icon} Назначение
                                            мастера
                                        </>
                                    );
                                } else if (!issue?.auto_assignment_status) {
                                    return <>Назначение мастера</>;
                                } else {
                                    return null;
                                }
                            }
                            default: {
                                if (issue?.worker) {
                                    return (
                                        <>
                                            {IssueMasterSearchStatus[EAutoAssignmentStatus.Finished].icon} Назначение
                                            мастера
                                        </>
                                    );
                                }
                                return <>Назначение мастера</>;
                            }
                        }
                    })()}
                </div>
            }
            sx={{ mt: 3 }}
        >
            <div className="iss-wr-list">
                <div className="iss-wr-item">
                    <div>
                        <div>Мастер</div>
                        <div className="iss-wr-data">
                            {(() => {
                                switch (true) {
                                    case issue.status === EIssueStatus.MasterSearch ||
                                        issue.status === EIssueStatus.MasterNotFound: {
                                        if (issue?.auto_assignment_status === EAutoAssignmentStatus.Pending) {
                                            return (
                                                <WorkerSearchStatus
                                                    color={IssueMasterSearchStatus[issue.auto_assignment_status!].color}
                                                    title={IssueMasterSearchStatus[issue.auto_assignment_status!].title}
                                                    icon={IssueMasterSearchStatus[issue.auto_assignment_status!].icon}
                                                    time_start={issue?.auto_assignment_start_at}
                                                    time_end={issue?.auto_assignment_end_at}
                                                />
                                            );
                                        } else if (issue?.auto_assignment_status === EAutoAssignmentStatus.Started) {
                                            return (
                                                <WorkerSearchStatus
                                                    color={IssueMasterSearchStatus[issue.auto_assignment_status!].color}
                                                    title={IssueMasterSearchStatus[issue.auto_assignment_status!].title}
                                                    icon={IssueMasterSearchStatus[issue.auto_assignment_status!].icon}
                                                    time_start={issue?.auto_assignment_start_at}
                                                    time_end={issue?.auto_assignment_end_at}
                                                />
                                            );
                                        } else if (issue?.auto_assignment_status === EAutoAssignmentStatus.Error) {
                                            return (
                                                // eslint-disable-next-line react/jsx-no-useless-fragment
                                                <>
                                                    {issue?.auto_assignment_errors?.map((error) => (
                                                        <WorkerSearchStatus
                                                            key={error}
                                                            color={
                                                                IssueMasterSearchStatus[EAutoAssignmentStatus.Error]
                                                                    .color
                                                            }
                                                            title={ErrorsMasterSearchMock[error].title}
                                                            icon={
                                                                IssueMasterSearchStatus[EAutoAssignmentStatus.Error]
                                                                    .icon
                                                            }
                                                        />
                                                    ))}
                                                    {issue?.auto_assignment_start_at ||
                                                    issue?.auto_assignment_end_at ? (
                                                        <span style={{ color: '#26a69a', fontSize: '12px' }}>
                                                            Период поиска:
                                                            {getTimeWindowWithAllDate({
                                                                time_window_start_datetime_msk:
                                                                    issue.auto_assignment_start_at,
                                                                time_window_end_datetime_msk:
                                                                    issue.auto_assignment_end_at,
                                                            })}
                                                        </span>
                                                    ) : null}
                                                </>
                                            );
                                        } else if (!issue?.auto_assignment_status) {
                                            return null;
                                        } else {
                                            return <p>Данного EAutoAssignmentStatus нет!</p>;
                                        }
                                    }
                                    default: {
                                        if (issue?.worker) {
                                            return (
                                                <WorkerSearchStatus
                                                    color={
                                                        IssueMasterSearchStatus[EAutoAssignmentStatus.Finished].color
                                                    }
                                                    title={
                                                        IssueMasterSearchStatus[EAutoAssignmentStatus.Finished].title
                                                    }
                                                    icon={IssueMasterSearchStatus[EAutoAssignmentStatus.Finished].icon}
                                                    time_start={issue?.auto_assignment_start_at}
                                                    time_end={issue?.auto_assignment_end_at}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    }
                                }
                            })()}

                            {!!issue.worker && <UserSmallData user={issue.worker} />}
                        </div>
                    </div>
                    {(() => {
                        switch (true) {
                            case issue.status === EIssueStatus.MasterSearch ||
                                issue.status === EIssueStatus.MasterNotFound:
                                return (
                                    <div className="iss-wr-controls">
                                        {issueForAutoAssignment(issue) ? (
                                            <Button
                                                sx={{ ml: 'auto', height: 35 }}
                                                variant="contained"
                                                onClick={autoAssignmentHandle}
                                            >
                                                Автоподбор
                                            </Button>
                                        ) : null}
                                        <Button
                                            sx={{ ml: 'auto', height: 35 }}
                                            variant="contained"
                                            onClick={openCandidatesModalHandler}
                                        >
                                            Изменить
                                        </Button>
                                        <CandidatesModal
                                            addCandidatesCallback={addCandidatesHandler}
                                            defaultCandidates={issue?.candidates}
                                        />
                                    </div>
                                );
                            default:
                                return null;
                        }
                    })()}
                </div>
            </div>
            {!!issue.candidates?.length && !issue.worker && (
                <div className="iss-cndts-list">
                    {issue.candidates.map((candidate) => {
                        return candidate.worker ? (
                            <Candidate
                                candidate={candidate}
                                key={candidate.id}
                            />
                        ) : null;
                    })}
                </div>
            )}
        </Accordion>
    ) : null;
}

export default IssueWorker;
