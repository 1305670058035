import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Typography } from '@mui/material';
import React from 'react';
import './DialogTittle.scss';

function DialogTitle() {
    return (
        <Box className="price-dialog-title">
            <InfoOutlinedIcon
                color="secondary"
                sx={{ marginRight: '30px' }}
                fontSize="large"
            />{' '}
            <Typography variant="h6">Состав услуг</Typography>
        </Box>
    );
}

export default DialogTitle;
