import { Divider, FormControl } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import InputStack from '../../../../../components/inputs/InputStack';
import { setFormValuesPriceDialogAction } from '../../../../../store/actions/priceDialog';
import { useAppDispatch } from '../../../../../store/hooks';
import { formValuesPriceDialogSelector } from '../../../../../store/selectors/priceDialogSelector';

function PriceOptions() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesPriceDialogSelector);

    function onChangeIncludedInPrice(values: string[]) {
        dispatch(setFormValuesPriceDialogAction({ included_in_price: values }));
    }

    function onChangeExcludedInPrice(values: string[]) {
        dispatch(setFormValuesPriceDialogAction({ excluded_in_price: values }));
    }
    return (
        <>
            <FormControl
                margin="dense"
                fullWidth
            >
                <div className="in-price-label">ЧТО ВХОДИТ В СТОИМОСТЬ</div>
                <InputStack
                    addButtonLabel={'Добавить описание'}
                    onChange={onChangeIncludedInPrice}
                    values={formValues?.included_in_price || []}
                />
            </FormControl>
            <Divider />
            <FormControl
                margin="dense"
                fullWidth
            >
                <div className="in-price-label">ЧТО НЕ ВХОДИТ В СТОИМОСТЬ</div>
                <InputStack
                    addButtonLabel={'Добавить описание'}
                    onChange={onChangeExcludedInPrice}
                    values={formValues?.excluded_in_price || []}
                />
            </FormControl>
        </>
    );
}

export default PriceOptions;
