import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';

import ButtonGroup, { IButtonGroupItem } from '../../../../../components/ButtonGroup';
import { setDialogAction } from '../../../../../store/actions/dialog';
import { setIsOpenEditGroupAction } from '../../../../../store/actions/groups';
import { useAppDispatch } from '../../../../../store/hooks';
import DeleteGroup from '../../../DeleteGroup';
import './HeaderButton.scss';

function HeaderButton() {
    const dispatch = useAppDispatch();

    function getButtonGroupItems(): IButtonGroupItem[] {
        return [
            {
                title: 'Редактировать группу',
                handler: () => showModalEditGroupHandler(),
            },
            {
                title: 'Удалить группу',
                handler: () => showModalDeleteGroupHandler(),
            },
        ];
    }

    function showModalEditGroupHandler() {
        dispatch(setIsOpenEditGroupAction(true));
    }

    function showModalDeleteGroupHandler() {
        dispatch(
            setDialogAction({
                visible: true,
                onCancelText: false,
                onOkText: false,
                maxWidth: '600px',
                autoClose: false,
                content: <DeleteGroup />,
            }),
        );
    }

    return (
        <div className="groups-header_btn-wrapper">
            <ButtonGroup
                title=""
                color="inherit"
                buttonsInfo={getButtonGroupItems()}
                icon={<MoreVertIcon />}
            />
        </div>
    );
}

export default HeaderButton;
