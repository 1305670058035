import { EIssueFilter } from '../enums/search.enum';

export const searchFiltersMock = [
    {
        label: 'Номер заявки',
        value: EIssueFilter.Oid,
    },
    {
        label: 'Клиент',
        value: EIssueFilter.Client,
    },
    {
        label: 'Адрес вызова',
        value: EIssueFilter.Address,
    },
    {
        label: 'Код менеджера',
        value: EIssueFilter.SellerCode,
    },
    {
        label: 'Номер заказа',
        value: EIssueFilter.IssueCode,
    },
];
