import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IGetTokenReq, IGetTokenResp } from '../interfaces/auth';

export default class AuthAPI {
    getToken(data: IGetTokenReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/auth/token`,
            data,
        }) as AxiosPromise<IGetTokenResp>;
    }
}
