import { AppDispatch } from 'store/store';

import { setChatGroupByUserFiltersAction } from './chatGroupByUser';

import { chatsApi } from '../../api';
import { EChatsInfiniteActions } from '../../enums/actions.enum';
import { IChat, IChatsFilters, TChatsSortField, TDirection } from '../../interfaces/chats';
import {
    ISetChatsInfiniteAction,
    IIsLoadingChatsInfiniteAction,
    ISetChatsFiltersInfiniteAction,
    ISetChatsPaginationInfiniteAction,
    ISetChatsSortOptionsInfiniteAction,
    IInsertChatsInfiniteAction,
    IPutChatsInfiniteAction,
} from '../../interfaces/chatsInfinte';
import { IPagination } from '../../interfaces/pagination';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';

export const resetChatsInfiniteActions = () => ({
    type: EChatsInfiniteActions.ResetChatsInfinite,
});

export const putChatsAction = (payload: IChat[]): IPutChatsInfiniteAction => ({
    type: EChatsInfiniteActions.PutChatsInfinite,
    payload,
});

export const setChatsAction = (payload: IChat[]): ISetChatsInfiniteAction => ({
    type: EChatsInfiniteActions.SetChatsInfinite,
    payload,
});

export const setIsChatsTableLoaderAction = (payload: boolean): IIsLoadingChatsInfiniteAction => ({
    type: EChatsInfiniteActions.SetIsLoadingChatsInfinite,
    payload,
});

export const setChatsPaginationAction = (payload: IPagination | null): ISetChatsPaginationInfiniteAction => ({
    type: EChatsInfiniteActions.SetChatsPaginationInfinite,
    payload,
});

export const insertChatsInfiniteAction = (payload: IChat): IInsertChatsInfiniteAction => ({
    type: EChatsInfiniteActions.InsertChatsInfinite,
    payload,
});

export const setChatsFiltersAction = (payload: Partial<IChatsFilters> | null): ISetChatsFiltersInfiniteAction => ({
    type: EChatsInfiniteActions.SetChatsFiltersInfinite,
    payload,
});

export const setChatsSortOptionsAction = (payload: {
    sortField: TChatsSortField;
    direction: TDirection;
}): ISetChatsSortOptionsInfiniteAction => ({
    type: EChatsInfiniteActions.SetChatsSortOptionsInfinite,
    payload,
});

export interface IGetChatsThunkParams {
    page?: IPagination['page'];
    size?: IPagination['size'];
    filters?: Partial<IChatsFilters> | null;
    sortOptions?: Map<TChatsSortField, TDirection>;
}

export const getChatsInfiniteThunk =
    ({ page, size, sortOptions, filters }: IGetChatsThunkParams) =>
    async (dispatch: AppDispatch) => {
        const sortArr: Record<'order_by', string>[] = [];
        sortOptions?.forEach((direction, sortField) => {
            sortArr.push({
                order_by: !(direction === 'asc') ? `-${sortField}` : sortField,
            });
        });

        const sortSting = getQueryParamsAsString(sortArr);

        dispatch(
            setChatGroupByUserFiltersAction({
                has_unread_message: filters?.has_unread_message as boolean,
                chat_type: filters?.chat_type as string | null,
            }),
        );

        try {
            if (page && page <= 1) {
                dispatch(setIsChatsTableLoaderAction(true));
                dispatch(putChatsAction([]));
            }

            const { data } = await chatsApi.getChats({ page, size, ...filters }, sortSting);
            const { items: chats, page: pageResp, size: sizeResp, total } = data;
            dispatch(setChatsAction(chats));
            dispatch(setChatsPaginationAction({ page: pageResp, size: sizeResp, total }));
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            dispatch(setIsChatsTableLoaderAction(false));
        }
    };
