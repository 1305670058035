import { AppDispatch } from 'store/store';

import { companiesApi } from '../../api';
import { ECompaniesActions } from '../../enums/actions.enum';
import { ICompany, ISetCompaniesAction } from '../../interfaces/companies';

export const setCompaniesAction = (payload: ICompany[] | null): ISetCompaniesAction => ({
    type: ECompaniesActions.SetCompanies,
    payload,
});

export const getCompaniesThunk = (queries?: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await companiesApi.getCompanies(queries);
        dispatch(setCompaniesAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
