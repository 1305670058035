// eslint-disable-next-line no-shadow
export enum EUserRole {
    Worker = 'worker',
    Client = 'client',
    Backoffice = 'backoffice',
    Partner = 'partner',
}

// eslint-disable-next-line no-shadow
export const enum EUserGender {
    Male = 'male',
    Female = 'female',
    Other = 'other',
}
