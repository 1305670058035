import { servicesApi } from 'api';
import { createSlice } from 'store/hooks';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    integrations: [],

    loading: false,
};

const slice = createSlice({
    name: 'integrations',
    initialState,
    reducers: (create) => ({
        fetchIntegrations: create.asyncThunk(
            async ({ service_id }, { dispatch }) => {
                const params = getFilledParams({ size: 50, service_id });

                const { data } = await servicesApi.getIntegrations(params);

                dispatch(setIntegrations(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),
        setIntegrations: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.integrations = payload;
        }),
    }),
});

export const { setIntegrations, fetchIntegrations } = slice.actions;

export default slice.reducer;
