import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { workersApi } from '../../../../api';
import TextField from '../../../../components/inputs/TextField';
import { TErrors } from '../../../../interfaces';
import { getGroupsThunk, setIsOpenEditGroupAction, updateGroupThunk } from '../../../../store/actions/groups';
import { getWorkersThunk, setSearchAction, setSelectedWorkersIdsAction } from '../../../../store/actions/workers';
import { useAppDispatch } from '../../../../store/hooks';
import { groupSelector } from '../../../../store/selectors/groupsSelector';
import { selectedWorkersIdsSelector, workersFiltersSelector } from '../../../../store/selectors/workersSelectors';
import { getQueryParamsAsString } from '../../../../utils/helpers/getQueryParamsAsString';
import Search from '../../../WorkersSelect/Search';
import WorkersList from '../../../WorkersSelect/WorkersList';

function EditGroupForm() {
    const dispatch = useAppDispatch();

    const group = useSelector(groupSelector);
    const selectedWorkers = useSelector(selectedWorkersIdsSelector);
    const filters = useSelector(workersFiltersSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [name, setName] = useState<string | undefined | null>(group?.name);

    useEffect(() => {
        async function handle() {
            if (!filters) return;
            const { data } = await workersApi.getWorkers(
                getQueryParamsAsString([{ page: 1 }, { size: 50 }, { group_id__in: filters?.group_id__in?.id }]),
            );
            const { items: workers } = data;
            const workersIds = workers.map((worker) => {
                return worker.id;
            });
            dispatch(setSelectedWorkersIdsAction(workersIds));
        }
        handle();
    }, []);

    async function updateGroup() {
        if (!group) return;
        setIsLoading(true);
        if (name) {
            setErrors(null);
            dispatch(setIsOpenEditGroupAction(false));
            await dispatch(updateGroupThunk(group.id, { name, worker_ids: selectedWorkers || [] }));
            await dispatch(getWorkersThunk({ filters }));
            await dispatch(getGroupsThunk());
            dispatch(setSelectedWorkersIdsAction(null));
        } else {
            setErrors({
                name: {
                    message: 'Незаполненное поле',
                },
            });
        }
        setIsLoading(false);
    }

    function changeNameHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setName(e.target.value);
    }

    function searchWorker(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setSearchAction(event.target.value));
    }

    function closeDialog() {
        dispatch(setIsOpenEditGroupAction(false));
        dispatch(setSelectedWorkersIdsAction(null));
    }

    return (
        <div className="create-group-form-wrapper">
            <TextField
                errors={errors}
                name="name"
                placeholder="Название группы"
                fullWidth
                onChange={changeNameHandler}
                value={name}
            />
            <div className="workers-select-wrapper">
                <div className="search">
                    <Search
                        onChange={searchWorker}
                        placeholder="Поиск по фамилии, имени, id"
                    />
                </div>
                <WorkersList />
            </div>
            <div className="buttons-and-info-container">
                <div className="buttons">
                    <Button
                        onClick={closeDialog}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        sx={{ ml: 1 }}
                        onClick={updateGroup}
                    >
                        Отправить
                    </LoadingButton>
                </div>
                <div className="selected-workers-info">Выбрано: {selectedWorkers ? selectedWorkers?.length : 0}</div>
            </div>
        </div>
    );
}

export default memo(EditGroupForm);
