import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel, Paper } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import RatioCategoryFilter from './RatioCategoryFilter';

import CheckboxGroup from '../../../../../components/inputs/CheckboxGroup';
import { EIssueHelpType, EIssueStatus, EIssueTableHeadFilter } from '../../../../../enums/issues.enum';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import { ICategoryRatiosFilter } from '../../../../../interfaces/categoriesRatiosFilter';
import { IIssuesTableHead } from '../../../../../interfaces/issues';
import { issueHelpTypeNamesMock, issueStatusesMock, statusFiltersMock } from '../../../../../mockData/issues';
import Categories from '../../../../../modules/CategoriesFilter/Categories';
import { setIssuesFiltersAction } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { companiesOptionsSelector } from '../../../../../store/selectors/companiesSelectors';
import { issuesFiltersSelector, statusFiltersSelector } from '../../../../../store/selectors/issuesSelectors';
import './Filters.scss';

interface IProps {
    property: IIssuesTableHead['property'];
    filtersWrapperId: string;
}

function Filter({ property, filtersWrapperId }: IProps) {
    const dispatch = useAppDispatch();

    const statusFilters = useSelector(statusFiltersSelector);
    const filters = useSelector(issuesFiltersSelector);
    const companiesOptions = useSelector(companiesOptionsSelector);

    const [openWrapperId, setOpenWrapperId] = useState<string | null>(null);

    const filtersWrapperRef = useOutsideClick(() => {
        setOpenWrapperId(null);
    });

    const optionFilters = statusFilters || statusFiltersMock;

    function openFiltersHandle(wrapperId: string) {
        if (wrapperId === openWrapperId) {
            setOpenWrapperId(null);
            return;
        }
        setOpenWrapperId(wrapperId);
    }

    function changeHelpType(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        let newHelpTypes: EIssueHelpType[] = [];

        if (!filters?.help_type?.length) {
            newHelpTypes = [e.target.value as EIssueHelpType];
            dispatch(
                setIssuesFiltersAction({
                    ...(filters || {}),
                    help_type: newHelpTypes,
                }),
            );
            return;
        }

        if (checked) {
            newHelpTypes = [...filters.help_type, e.target.value as EIssueHelpType];
        } else {
            const newVals = [...filters.help_type];
            const findIndex = newVals.findIndex((helpType) => helpType === e.target.value);

            if (findIndex === -1) {
                console.error('Error!!!: changeHelpType() helpTypeCheckedVals value not found!');
                return;
            }

            newVals.splice(findIndex, 1);
            newHelpTypes = newVals;
        }
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                help_type: newHelpTypes?.length ? newHelpTypes : undefined,
            }),
        );
    }

    function changeStatus(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        let newStatuses: EIssueStatus[] = [];

        if (!filters?.status?.length) {
            newStatuses = [e.target.value as EIssueStatus];
            dispatch(
                setIssuesFiltersAction({
                    ...(filters || {}),
                    statuses: undefined,
                    status: newStatuses,
                }),
            );
            return;
        }

        if (checked) {
            newStatuses = [...filters.status, e.target.value as EIssueStatus];
        } else {
            const newVals = [...filters.status];
            const findIndex = newVals.findIndex((helpType) => helpType === e.target.value);

            if (findIndex === -1) {
                console.error('Error!!!: changeStatus() statusCheckedVals value not found!');
                return;
            }

            newVals.splice(findIndex, 1);
            newStatuses = newVals;
        }
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                status: newStatuses?.length ? newStatuses : undefined,
                statuses: !newStatuses?.length ? optionFilters.find((option) => option.isActive)?.statuses : undefined,
            }),
        );
    }

    function changeCreatedSource(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        let newCompanies: string[] = [];

        if (!filters?.partner_id__in?.length) {
            newCompanies = [e.target.value];
            dispatch(
                setIssuesFiltersAction({
                    ...(filters || {}),
                    partner_id__in: newCompanies,
                }),
            );
            return;
        }

        if (checked) {
            newCompanies = [...filters.partner_id__in, e.target.value];
        } else {
            const newVals = [...filters.partner_id__in];
            const findIndex = newVals.findIndex((helpType) => helpType === e.target.value);

            if (findIndex === -1) {
                console.error('Error!!!: changeCreatedSource() createdSourceCheckedVals value not found!');
                return;
            }

            newVals.splice(findIndex, 1);
            newCompanies = newVals;
        }
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                partner_id__in: newCompanies?.length ? newCompanies : undefined,
            }),
        );
    }

    function changeReminder(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                has_reminders: e.target.checked || undefined,
            }),
        );
    }

    // eslint-disable-next-line no-shadow
    function selectCRFIds(selectCRFIds: string[] | null) {
        dispatch(setIssuesFiltersAction({ ...(filters || {}), category_id: selectCRFIds }));
    }

    function onChangeRatiosHandler(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                ratio_category_id__in: categoriesIds?.length ? categoriesIds : null,
            }),
        );
    }

    return (
        <div
            ref={filtersWrapperRef}
            style={{ display: 'flex', alignItems: 'center' }}
        >
            <ExpandMoreIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                    openFiltersHandle(filtersWrapperId);
                }}
            />

            <Paper
                className={cn('table-filter-wrapper', {
                    isOpen: filtersWrapperId === openWrapperId,
                })}
            >
                {(() => {
                    switch (property) {
                        case EIssueTableHeadFilter.HelpType: {
                            const options = Object.entries(issueHelpTypeNamesMock).map(([val, name]) => {
                                return { value: val, label: name };
                            });
                            return (
                                <div className="help-type-table-filter">
                                    <CheckboxGroup
                                        name={'help_type'}
                                        options={options}
                                        onChange={changeHelpType}
                                        checkedValues={filters?.help_type || undefined}
                                    />
                                </div>
                            );
                        }
                        case EIssueTableHeadFilter.Status: {
                            const options = Object.entries(optionFilters).reduce(
                                (acc: { value: string; label: string }[], cur) => {
                                    if (cur[1].isActive) {
                                        cur[1].statuses.forEach((status) => {
                                            const label = (): string => {
                                                switch (status) {
                                                    case EIssueStatus.ProblemByMaster:
                                                        return 'Проблемная заявка (от мастера)';
                                                    case EIssueStatus.ProblemByClient:
                                                        return 'Проблемная заявка (от клиента)';
                                                    default:
                                                        return issueStatusesMock[status].title;
                                                }
                                            };
                                            acc.push({
                                                value: status as string,
                                                label: label(),
                                            });
                                        });
                                    }
                                    return acc;
                                },
                                [],
                            );
                            return (
                                <div className="statuses-table-filter">
                                    <CheckboxGroup
                                        name={'status'}
                                        options={options}
                                        onChange={changeStatus}
                                        checkedValues={filters?.status || undefined}
                                    />
                                </div>
                            );
                        }
                        case EIssueTableHeadFilter.Companies: {
                            return (
                                <div className="companies-table-filter">
                                    <CheckboxGroup
                                        name={'created_source'}
                                        options={companiesOptions!}
                                        onChange={changeCreatedSource}
                                        checkedValues={filters?.partner_id__in || undefined}
                                    />
                                </div>
                            );
                        }
                        case EIssueTableHeadFilter.Reminder: {
                            return (
                                <div className="reminder-table-filter">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name={'reminder'}
                                                checked={filters?.has_reminders || false}
                                                onChange={changeReminder}
                                            />
                                        }
                                        label="Установлено"
                                    />
                                </div>
                            );
                        }
                        case EIssueTableHeadFilter.Category: {
                            return (
                                <div className="categories-table-filter">
                                    <Categories
                                        onChange={selectCRFIds}
                                        selectedCRFIdsForWorkers={filters?.category_id || null}
                                    />
                                </div>
                            );
                        }
                        case EIssueTableHeadFilter.Region: {
                            return <RatioCategoryFilter onChange={onChangeRatiosHandler} />;
                        }
                        default:
                            console.log('Unknown filter EIssueTableHeadFilter');
                            return null;
                    }
                })()}
            </Paper>
        </div>
    );
}

export default Filter;
