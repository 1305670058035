/* Состояния мастера в заявке. PS: имена свойств объекта ни на что не влияют. Добавить иконки, если понадобится {title:'Поиск...', icon: <MaterialIcon/>} */
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { teal } from '@mui/material/colors';

import theme from '../theme';

export const workerIssueStatusMock = {
    search: {
        title: 'Поиск...',
        color: teal['400'],
        icon: <AccessTimeFilledRoundedIcon sx={{ color: teal['400'] }} />,
    },
    notApproved: {
        title: 'Мастер не подтвердил заявку',
        color: theme.palette.warning.light,
        icon: <ErrorSharpIcon sx={{ color: theme.palette.warning.light }} />,
    },
    notAssign: {
        title: 'Не назначен',
        color: theme.palette.warning.light,
        icon: <ErrorSharpIcon sx={{ color: theme.palette.warning.light }} />,
    },
    assign: {
        title: 'Назначен',
        color: theme.palette.success.light,
        icon: <CheckCircleSharpIcon sx={{ color: theme.palette.success.light }} />,
    },
};
