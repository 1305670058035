import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { ERoutes } from '../../enums/routes.enum';
import PartnerPage from '../../pages/partner';

function PartnerRoutes() {
    return (
        <Routes>
            <Route
                path={`${ERoutes.Partner}/`}
                element={<PartnerPage />}
            />
            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to={`${ERoutes.Partner}/`}
                    />
                }
            />
        </Routes>
    );
}

export default PartnerRoutes;
