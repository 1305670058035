import { EIssueHelpType } from '../../enums/issues.enum';
import { IFormValuesEditWorkerSpecializations } from '../../interfaces/edit/editWorkerSpecializations';
import { ISpecialization } from '../../interfaces/specializations';

export const divisionSpecializationByType = (specializations: ISpecialization[]) => {
    const helpTypeObj: Record<EIssueHelpType, string[]> = specializations.reduce((obj, item) => {
        if (!obj[item.help_type]) {
            // eslint-disable-next-line no-param-reassign
            obj[item.help_type] = [];
        }
        if (!obj[item.help_type].includes(item.category_id)) {
            obj[item.help_type]?.push(item.category_id);
        }
        return obj;
    }, {} as Record<EIssueHelpType, string[]>);

    const subOptions = Object.entries(helpTypeObj).map(([help_type, category_ids]) => {
        return { help_type, category_ids };
    }) as IFormValuesEditWorkerSpecializations[];

    return {
        helpTypeObj,
        subOptions,
    };
};
