import { EIssueStatus } from 'enums/issues.enum';
import { PaymentMethodMock } from 'mockData/issues';
import { issueSelector } from 'store/selectors/issuesSelectors';

import { useSelector } from 'react-redux';

import EditPaymentMethod from './EditPaymentMethod';

import './PaymentMethod.scss';

function PaymentMethod() {
    const issue = useSelector(issueSelector);

    const { payment_method, status } = issue || {};
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {status !== EIssueStatus.Solved && (
                <div className="iss-pm-wrap">
                    <div className="iss-pm-item">
                        <div>Способ оплаты</div>
                        <div>{!!payment_method && PaymentMethodMock[payment_method]}</div>
                        <EditPaymentMethod />
                    </div>
                </div>
            )}
        </>
    );
}

export default PaymentMethod;
