import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    deleteIssueReminderThunk,
    getIssueRemindersThunk,
    setIsOpenDeleteReminderActions,
    setReminderActions,
} from '../../../store/actions/reminders';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenDeleteReminderSelector, reminderSelector } from '../../../store/selectors/remindersSelector';
import { dateFormatter } from '../../../utils/helpers/dateFormatter';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';

function DeleteIssueReminder() {
    const { issueId } = useParams();
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenDeleteReminderSelector);
    const reminder = useSelector(reminderSelector);

    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

    const date =
        reminder?.datetime_delivery &&
        dateFormatter(
            reminder.datetime_delivery,
            {},
            {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            },
        );

    async function deleteReminder() {
        try {
            if (!reminder || !issueId) return;
            setIsBtnLoading(true);
            await dispatch(deleteIssueReminderThunk(reminder?.id));
            await dispatch(getIssueRemindersThunk(issueId));
            dispatch(setReminderActions(null));
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsBtnLoading(false);
        }
    }

    function handleClose() {
        dispatch(setIsOpenDeleteReminderActions(false));
    }

    return (
        <div className="iss-cndts-item">
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                    >
                        <ErrorOutlineIcon
                            color="error"
                            fontSize="small"
                        />
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>Удалить напоминание</Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>Хотите удалить напоминание?</Box>
                    <Box sx={{ fontWeight: 600 }}>{reminder?.text}</Box>
                    <Box sx={{ fontWeight: 600 }}>{date}</Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isBtnLoading}
                        disabled={isBtnLoading}
                        onClick={deleteReminder}
                        variant="contained"
                        sx={{ background: 'red' }}
                    >
                        Удалить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteIssueReminder;
