import { AppDispatch } from 'store/store';

import { categoriesApi } from '../../api';
import { ECategoriesActions } from '../../enums/actions.enum';
import { ECategoryStatus } from '../../enums/categories.enum';
import {
    ICategory,
    ICategoryDetail,
    ISetCategoriesAction,
    ISetCategoriesPaginationAction,
    ISetCategoryAction,
    ISetSearchCategoriesAction,
} from '../../interfaces/categories';
import { IFormValuesEditCategory } from '../../interfaces/edit/editCategory';
import { IPagination } from '../../interfaces/pagination';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';

export const setCategoryAction = (payload: ICategoryDetail | null): ISetCategoryAction => ({
    type: ECategoriesActions.SetCategory,
    payload,
});

export const setCategoriesAction = (payload: ICategoryDetail[] | null): ISetCategoriesAction => ({
    type: ECategoriesActions.SetCategories,
    payload,
});

export const setSearchCategoriesAction = (payload: string | null): ISetSearchCategoriesAction => ({
    type: ECategoriesActions.SetSearchCategories,
    payload,
});

export const setCategoriesPaginationAction = (payload: IPagination): ISetCategoriesPaginationAction => ({
    type: ECategoriesActions.SetCategoriesPagination,
    payload,
});

interface ICreateCategoryThunkParams {
    name: ICategory['name'];
    service?: any;
}
export const createCategoryThunk =
    ({ name }: ICreateCategoryThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data } = await categoriesApi.createCategory(name);
            dispatch(setCategoryAction(data));
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
export const patchCategoryThunk = (categoryId: string, formValues: IFormValuesEditCategory) => {
    return async () => {
        try {
            const { data } = await categoriesApi.patchCategory(categoryId, formValues.name!);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
interface IGetCategoriesThunkParams {
    page?: number;
    size?: number;
    search?: string | null;
    categories?: ICategoryDetail[];
    order_by?: string;
}
export const getCategoriesThunk =
    ({ page, size, search, categories, order_by }: IGetCategoriesThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queries = getQueryParamsAsString([
                { page: page || 1 },
                { size: size || 50 },
                { search: search || '' },
                { order_by: order_by || 'name' },
                /* по дефолту во всём приложении доступны только верифицированные категории */
                { status: ECategoryStatus.Verified },
            ]);

            const { data } = await categoriesApi.getCategories(queries);
            const { items, size: newSize, page: newPage, total } = data;

            if (categories) {
                dispatch(setCategoriesAction([...categories, ...items]));
            } else {
                dispatch(setCategoriesAction(items));
            }

            dispatch(setCategoriesPaginationAction({ total, page: newPage, size: newSize }));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
