import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TErrors } from '../../../../../interfaces';
import {
    editIssueStatusThunk,
    setErrorsEditIssueStatusAction,
    setIsOpenEditIssueStatusAction,
} from '../../../../../store/actions/edit/editIssueStatus';
import { setIssueAction } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    formValuesEditIssueStatusSelector,
    issueIdEditIssueStatusSelector,
} from '../../../../../store/selectors/edit/editIssueStatusSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();

    const issueId = useSelector(issueIdEditIssueStatusSelector);
    const formValues = useSelector(formValuesEditIssueStatusSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.status) {
            errs.status = { message: 'Обязательное поле' };
        }

        if (!formValues?.comment) {
            errs.comment = { message: 'Обязательное поле' };
        }

        dispatch(setErrorsEditIssueStatusAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        setIsBtnDisabled(!formValues);
    }, [formValues]);

    async function submitFormHandler() {
        if (!issueId || !formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                const editedIssue = await dispatch(editIssueStatusThunk(issueId, formValues));
                dispatch(setIssueAction(editedIssue));
                dispatch(setIsOpenEditIssueStatusAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="buttons-wrapper">
            <Button
                variant="outlined"
                disabled={isBtnDisabled}
                onClick={() => dispatch(setIsOpenEditIssueStatusAction(false))}
            >
                Отмена
            </Button>

            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isBtnDisabled}
                onClick={submitFormHandler}
            >
                Сохранить
            </LoadingButton>
        </div>
    );
}

export default FooterForm;
