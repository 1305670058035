import { CloseIcon } from 'static/Icons/CloseIcon';

import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';

import { CloseIconWrapper } from './styles';

import { ButtonsContainer } from '../../styles';

type Props = {
    isConfirmOpen: boolean;
    setConfirmOpen: (value: boolean) => void;
    createPriceList: () => void;
};

export const AddConfirm: FC<Props> = ({ isConfirmOpen, setConfirmOpen, createPriceList }) => {
    return (
        <Dialog
            open={isConfirmOpen}
            onClose={() => setConfirmOpen(false)}
            sx={{
                '.MuiDialog-paper': {
                    width: '400px',
                    borderRadius: '8px',
                },
            }}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setConfirmOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography variant="h5">Подтвердить действие</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ margin: '5px 0' }}>
                    Вы уверены, что хотите добавить услугу в выбранные прайс-листы: Какой-то прайс-лист 1, Какой-то
                    прайс-лист 2. Если в прайс-листе уже есть услуга с таким наименованием, то она изменится.
                </Typography>
                <ButtonsContainer>
                    <Button
                        onClick={createPriceList}
                        variant="contained"
                    >
                        Подтвердить
                    </Button>
                    <Button
                        onClick={() => setConfirmOpen(false)}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
