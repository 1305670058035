/* eslint-disable no-shadow */
export const enum EPartsOrdersStatus {
    InSearch = 'in_search',
    WaitingApproval = 'waiting_approval',
    InProgress = 'in_progress',
    Awaiting = 'awaiting',
    InStock = 'in_stock',
    WorkerShipped = 'worker_shipped',
    WaitingDelivery = 'waiting_delivery',
    Received = 'received',
    Canceled = 'canceled',
    Completed = 'completed',
    Closed = 'closed',
}
