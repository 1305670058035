import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { ESort } from '../../../../enums/tables.enum';
import { IStatusFilter } from '../../../../interfaces/issues';
import {
    setIssuesFiltersAction,
    setIssuesPaginationAction,
    setStatusFiltersAction,
} from '../../../../store/actions/issues';
import { useAppDispatch } from '../../../../store/hooks';
import { issuesFiltersSelector, statusFiltersSelector } from '../../../../store/selectors/issuesSelectors';
import './StatusFilters.scss';

function StatusFilters() {
    const dispatch = useAppDispatch();

    const statusFilters = useSelector(statusFiltersSelector);
    const filters = useSelector(issuesFiltersSelector);

    function onClickHandler(filter: IStatusFilter) {
        if (filter.isActive || !statusFilters) return;
        const newStatusFilters = statusFilters.map((fltr) => {
            if (filter.id === fltr.id) {
                return { ...fltr, isActive: true };
            } else {
                return { ...fltr, isActive: false };
            }
        });
        dispatch(setIssuesPaginationAction({ page: 0, size: 50, total: 0 }));
        dispatch(setStatusFiltersAction(newStatusFilters));
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                status: [],
                statuses: filter.statuses,
                order_by: { propName: 'smart_bo', order: ESort.Desc },
            }),
        );
    }

    return (
        <div className="status-filters-issues">
            {!!statusFilters &&
                statusFilters.map((filter) => {
                    const { id, title, count, color, isActive } = filter;
                    return (
                        <div
                            key={id}
                            onClick={() => {
                                onClickHandler(filter);
                            }}
                            tabIndex={0}
                            role="button"
                            className={cn('status-filter', { isActive })}
                        >
                            <div
                                className="background"
                                style={{ background: color }}
                            />
                            <span>{title}</span>
                            <span>{count}</span>
                        </div>
                    );
                })}
        </div>
    );
}

export default StatusFilters;
