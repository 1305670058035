import { createSelector } from 'reselect';

import { EIssueHistory } from '../../enums/issues.enum';
import { RootState } from '../rootReducer';

const state = ({ history }: RootState) => history;

export const historySelector = createSelector(state, ({ history }) => history);
export const historyStatusesSelector = createSelector(state, ({ history }) => {
    return (
        history?.filter((item) => item.trigger === EIssueHistory.Status || item.trigger === EIssueHistory.Canceled) ||
        null
    );
});
