import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ObserveModules from '../../../../modules/Observe';
import { addObserverThunk, deleteObserverThunk } from '../../../../store/actions/issues';
import { useAppDispatch } from '../../../../store/hooks';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import { profileSelector } from '../../../../store/selectors/profileSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';

function ObservesIssue() {
    const dispatch = useAppDispatch();
    const profile = useSelector(profileSelector);
    const issue = useSelector(issueSelector);

    const { id: profileId } = profile || {};
    const { id: issueId, observers } = issue || {};

    const isObserver = useMemo(() => {
        if (!profileId) return false;

        return !!observers?.find((observer) => observer.id === profileId);
    }, [observers]);

    async function changeObserveIssue() {
        try {
            if (!issueId || !profileId) return;

            const request = {
                user_ids: [profileId],
            };

            if (isObserver) {
                await dispatch(deleteObserverThunk({ issueId, request }));
            } else {
                await dispatch(addObserverThunk({ issueId, request }));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <ObserveModules
            observers={observers || null}
            isObserver={isObserver}
            onChange={changeObserveIssue}
        />
    );
}

export default ObservesIssue;
