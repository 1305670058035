import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editIssueStatusWithComment }: RootState) => editIssueStatusWithComment;

export const isOpenEditIssueStatusWithCommentSelector = createSelector(state, ({ isOpen }) => isOpen);
export const issueIdEditIssueStatusWithCommentSelector = createSelector(state, ({ issueId }) => issueId);
export const formValuesEditIssueStatusWithCommentSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsEditIssueStatusWithCommentSelector = createSelector(state, ({ errors }) => errors);
export const dataEditIssueStatusWithCommentSelector = createSelector(state, ({ data }) => data);
