import { servicesApi } from 'api';
import Loader from 'components/Loader';
import { FilterIn } from 'features/filter-in';
import MainLayout from 'layouts/MainLayout';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';

import { Search } from '@mui/icons-material';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { AxiosPromise } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { CreateService } from './components/create-service';
import { EditService } from './components/edit-service';
import { ServiceItem } from './components/service-item';
import { fetchServiceById, fetchServices, setFilters, setServiceById } from './store';
import { MainPaper, MuiDrawer, Container, ControlsWrapper, FiltersWrapper } from './styles';

export const ServicesPage = () => {
    const dispatch = useAppDispatch();

    const { services, filters, loading } = useSelector(servicesSelector);

    const [isOpen, setOpen] = useState<any>(false);
    const [isOpenEdit, setOpenEdit] = useState<any>(false);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(fetchServices());
    }, []);

    const filterSearch = (search: string) => {
        dispatch(setFilters({ search }));
        dispatch(fetchServices());
    };

    const filterSearchWithDebounced = useDebouncedCallback(filterSearch, 800);

    const converter = async (func: any, params: any) => {
        const res = await func({ size: 20, ...params });
        return { data: { items: res.data, total: res.data.length } };
    };

    const handleChangeFilter = (itemId: string, filterType: any) => {
        const getIds = () => {
            if (filters[filterType].includes(itemId as never)) {
                return filters[filterType].filter((id: string) => id !== itemId);
            } else {
                return [...filters[filterType], itemId];
            }
        };

        dispatch(setFilters({ [filterType]: getIds() }));
        dispatch(fetchServices());
    };

    const handleFilterClean = (key: string) => {
        dispatch(setFilters({ [key]: [] }));
        dispatch(fetchServices());
    };

    return (
        <MainLayout>
            <Container>
                <div className="services-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        Услуги
                    </Typography>
                </div>
                <ControlsWrapper>
                    <FiltersWrapper>
                        <TextField
                            placeholder="Поиск"
                            variant="outlined"
                            size="small"
                            onChange={(e) => filterSearchWithDebounced(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FilterIn
                            title="Категория"
                            onChange={(itemId) => handleChangeFilter(itemId, 'categories')}
                            activeIds={filters.categories}
                            fetcher={() => servicesApi.getCategories()}
                            cleaner={() => handleFilterClean('categories')}
                        />
                        <FilterIn
                            title="Тип услуги"
                            onChange={(itemId) => handleChangeFilter(itemId, 'service_types')}
                            activeIds={filters.service_types}
                            fetcher={(params) => converter(servicesApi.getServiceTypes, params) as AxiosPromise}
                            cleaner={() => handleFilterClean('service_types')}
                        />
                    </FiltersWrapper>
                    <Button
                        variant="contained"
                        onClick={(e) => {
                            dispatch(setServiceById(null));
                            setOpen(e.currentTarget);
                        }}
                    >
                        Создать услугу
                    </Button>
                    <MuiDrawer
                        open={Boolean(isOpen)}
                        anchor="right"
                        onClose={() => setOpen(false)}
                        hideBackdrop
                    >
                        <CreateService setOpen={setOpen} />
                    </MuiDrawer>
                </ControlsWrapper>
                <MainPaper>
                    {loading ? (
                        <Loader />
                    ) : (
                        services.map((item) => (
                            <div
                                onClick={() => {
                                    setLoading(true);
                                    dispatch(fetchServiceById(item.id)).then(() => {
                                        setOpenEdit(true);
                                        setLoading(false);
                                    });
                                }}
                                key={item.id}
                            >
                                <ServiceItem
                                    setOpen={setOpen}
                                    item={item}
                                />
                            </div>
                        ))
                    )}
                </MainPaper>
                <MuiDrawer
                    open={Boolean(isOpenEdit)}
                    anchor="right"
                    onClose={() => setOpenEdit(false)}
                    hideBackdrop
                >
                    {isLoading ? <Loader /> : <EditService setOpenEdit={setOpenEdit} />}
                </MuiDrawer>
            </Container>
        </MainLayout>
    );
};
