import { ESignInActions } from '../../enums/actions.enum';
import { ESignInTypes } from '../../enums/signIn.enum';
import { ISetEmailAction, ISetTypeAction } from '../../interfaces/signIn';

export const setTypeAction = (payload: ESignInTypes | null): ISetTypeAction => ({
    type: ESignInActions.SetType,
    payload,
});

export const setEmailAction = (payload: string | null): ISetEmailAction => ({
    type: ESignInActions.SetEmail,
    payload,
});

export const patchPassportThunk = () => async () => {
    try {
        console.log('patchPassportThunk');
    } catch (err) {
        console.error(err);
        throw err;
    }
};
