import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Accordion from '../../../../components/Accordion';
import PhotoFiles from '../../../../components/PhotoFiles';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import './PhotosMaster.scss';

function PhotosMaster() {
    const issue = useSelector(issueSelector);
    const { photos_of_works } = issue || {};

    return (
        <Accordion
            title={<Typography>Фото выполненных работ</Typography>}
            sx={{ mt: 3 }}
            expanded={false}
        >
            {photos_of_works?.length ? (
                photos_of_works.map(({ title, photo_files }) => (
                    <div
                        key={title}
                        className="photo-of-work-list"
                    >
                        <div className="title">{title}</div>
                        <PhotoFiles photoFiles={photo_files} />
                    </div>
                ))
            ) : (
                <div className="title">Фото нет</div>
            )}
        </Accordion>
    );
}

export default PhotosMaster;
