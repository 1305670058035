import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ pricesServices }: RootState) => pricesServices;

export const priceServicesSelector = createSelector(state, ({ services }) => services);
export const priceServiceSelector = createSelector(state, ({ service }) => service);
export const priceServicesTabSelector = createSelector(state, ({ servicesTab }) => servicesTab);
export const isPricesServicesLoadingSelector = createSelector(state, ({ isServiceLoading }) => isServiceLoading);
export const isOpenDeletePriceServicesSelector = createSelector(
    state,
    ({ isOpenDeletePricesServices }) => isOpenDeletePricesServices,
);
