import { ECreateIssueReminderActions } from '../../../enums/actions.enum';
import { ICreateIssueReminderState, TCreateIssueReminderActions } from '../../../interfaces/create/createIssueReminder';

const initState: ICreateIssueReminderState = {
    isOpen: false,
    issueId: null,
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const createIssueReminderReducer = (
    state = initialState,
    action: TCreateIssueReminderActions,
): ICreateIssueReminderState => {
    switch (action.type) {
        case ECreateIssueReminderActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case ECreateIssueReminderActions.SetIssueId:
            return {
                ...state,
                issueId: action.payload,
            };
        case ECreateIssueReminderActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case ECreateIssueReminderActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
