import Audio from './Audio';

import styles from './AudioFiles.module.scss';

import { IAudioFile } from '../../interfaces';

interface IProps {
    audioFiles: IAudioFile[];
}

function AudioFiles({ audioFiles }: IProps) {
    return (
        <div className={styles.wrapper}>
            {audioFiles.map((audio) => (
                <Audio
                    key={audio.id}
                    audio={audio.url}
                />
            ))}
        </div>
    );
}

export default AudioFiles;
