import { workersApi } from '../../../api';
import { EEditWorkerScheduleActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IFormValuesEditWorkerSchedule,
    ISetErrorsEditWorkerSchedule,
    ISetFormValuesEditWorkerSchedule,
    ISetIsOpenEditWorkerSchedule,
    ISetWorkerIdEditWorkerSchedule,
} from '../../../interfaces/edit/editWorkerSchedule';
import { IWorkerDetail } from '../../../interfaces/workers';

export const setIsOpenEditWorkerScheduleAction = (payload: boolean): ISetIsOpenEditWorkerSchedule => ({
    type: EEditWorkerScheduleActions.SetIsOpen,
    payload,
});

export const setWorkerIdEditWorkerScheduleAction = (
    payload: IWorkerDetail['id'] | null,
): ISetWorkerIdEditWorkerSchedule => ({
    type: EEditWorkerScheduleActions.SetWorkerId,
    payload,
});

export const setFormValuesEditWorkerScheduleAction = (
    payload: IFormValuesEditWorkerSchedule | null,
): ISetFormValuesEditWorkerSchedule => ({
    type: EEditWorkerScheduleActions.SetFormValues,
    payload,
});

export const setErrorsEditWorkerScheduleAction = (payload: TErrors | null): ISetErrorsEditWorkerSchedule => ({
    type: EEditWorkerScheduleActions.SetErrors,
    payload,
});

export const editWorkerScheduleThunk = (workerId: string, formValues: IFormValuesEditWorkerSchedule) => {
    return async () => {
        try {
            const { data } = await workersApi.editWorkerSchedule(workerId, {
                schedule: formValues,
            });
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
