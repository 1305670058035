import { EIssueStatusMap, EMapTabs } from '../enums/map.enum';

export const mapTabsMock = {
    [EMapTabs.Areas]: 'Зоны обслуживания',
    [EMapTabs.Issues]: 'Заявки',
    [EMapTabs.Workers]: 'Мастера',
};

export const MapInfoMock = {
    [EIssueStatusMap.Success]: {
        title: 'Мастер назначен',
        color: 'green',
    },
    [EIssueStatusMap.Warning]: {
        title: 'Есть подходящий мастер',
        color: 'yellow',
    },
    [EIssueStatusMap.Error]: {
        title: 'Мастер не может быть назначен',
        color: 'red',
    },
    [EIssueStatusMap.Done]: {
        title: 'Заявка выполнена или отменена',
        color: 'gray',
    },
};

export const UserTypeMock = {
    client: 'Клиент',
    worker: 'Мастер',
    backoffice: 'Оператор',
};
