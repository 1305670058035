import { EClientsActions } from '../../enums/actions.enum';
import { IClientsState, TClientsActions } from '../../interfaces/clients';

const initState: IClientsState = {
    client: null,
    clients: null,
    formValues: null,
    errors: null,
    pagination: null,
    isOpenCreateClient: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const clientsReducer = (state = initialState, action: TClientsActions): IClientsState => {
    switch (action.type) {
        case EClientsActions.SetClient:
            return {
                ...state,
                client: action.payload,
            };
        case EClientsActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EClientsActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case EClientsActions.SetClients:
            return {
                ...state,
                clients: action.payload,
            };
        case EClientsActions.SetIsOpenCreateClient:
            return {
                ...state,
                isOpenCreateClient: action.payload,
            };
        case EClientsActions.SetPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        default:
            return state;
    }
};
