import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ devices }: RootState) => devices;

export const homeDeviceDetailSelector = createSelector(state, ({ homeDeviceDetail }) => homeDeviceDetail);
export const homeDevicesSelector = createSelector(state, ({ homeDevices }) => homeDevices);
export const homeDevicesOptionsSelector = createSelector(state, ({ homeDevices }) => {
    return (
        homeDevices?.map((homeDevice) => {
            const { id, category, model, brand } = homeDevice;
            return { value: id, label: `${category?.name || ''} ${brand?.name || ''} ${model?.name || ''}` };
        }) || null
    );
});

export const isOpenCDSelector = createSelector(state, ({ isOpen }) => isOpen);
