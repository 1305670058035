import { AppDispatch } from 'store/store';

import { setCreateGroupWorkersListAction } from './create/createGroup';

import { workersApi } from '../../api';
import { EWorkersActions } from '../../enums/actions.enum';
import { IPagination } from '../../interfaces/pagination';
import {
    ISetIsWorkersTableLoaderAction,
    ISetSearchAction,
    ISetSelectedWorkersIdsAction,
    ISetWorkersAction,
    ISetWorkersCountAction,
    ISetWorkersFiltersAction,
    ISetWorkersPaginationAction,
    ISetWorkersSortOptionsAction,
    IWorker,
    IWorkersFilters,
    IWorkersResetAction,
    TDirection,
    TWorkersSortField,
} from '../../interfaces/workers';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const workersResetAction = (): IWorkersResetAction => ({
    type: EWorkersActions.WorkersReset,
});

export const setWorkersAction = (payload: IWorker[] | null): ISetWorkersAction => ({
    type: EWorkersActions.SetWorkers,
    payload,
});

export const setSearchAction = (payload: string | null): ISetSearchAction => ({
    type: EWorkersActions.SetSearch,
    payload,
});

export const setSelectedWorkersIdsAction = (payload: string[] | null): ISetSelectedWorkersIdsAction => ({
    type: EWorkersActions.SetSelectedWorkersIds,
    payload,
});

export const setIsWorkersTableLoaderAction = (payload: boolean): ISetIsWorkersTableLoaderAction => ({
    type: EWorkersActions.SetIsWorkersTableLoader,
    payload,
});

export const setWorkersPaginationAction = (payload: IPagination): ISetWorkersPaginationAction => ({
    type: EWorkersActions.SetWorkersPagination,
    payload,
});

export const setWorkersFiltersAction = (payload: IWorkersFilters | null): ISetWorkersFiltersAction => ({
    type: EWorkersActions.SetWorkersFilters,
    payload,
});

export const setWorkersSortOptionsAction = (payload: {
    sortField: TWorkersSortField;
    direction: TDirection;
}): ISetWorkersSortOptionsAction => ({
    type: EWorkersActions.SetWorkersSortOptions,
    payload,
});

export const setWorkersCountAction = (payload: number | null): ISetWorkersCountAction => ({
    type: EWorkersActions.SetWorkersCount,
    payload,
});

interface IGetWorkersThunkParams {
    page?: IPagination['page'];
    size?: IPagination['size'];
    filters?: IWorkersFilters | null;
    sortOptions?: Map<TWorkersSortField, TDirection>;
}

export const getWorkersThunk =
    ({ page, size, filters, sortOptions }: IGetWorkersThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(setIsWorkersTableLoaderAction(true));
            const queriesObj: TArrayParams = [{ page: page ? page + 1 : 1 }, { size: size || 50 }];

            if (filters) {
                const {
                    group_id__in,
                    area_id__in,
                    status__in,
                    search,
                    specialization__category_id__in,
                    specialization__help_type__in,
                } = filters;

                if (group_id__in) {
                    queriesObj.push({ group_id__in: group_id__in.id });
                }

                if (area_id__in) {
                    area_id__in.forEach((area) => {
                        queriesObj.push({ area_id__in: area });
                    });
                }

                if (status__in) {
                    status__in.forEach((status) => {
                        queriesObj.push({ status__in: status });
                    });
                }

                if (specialization__category_id__in) {
                    specialization__category_id__in.forEach((categoryId) => {
                        queriesObj.push({ specialization__category_id__in: categoryId });
                    });
                }

                if (specialization__help_type__in) {
                    specialization__help_type__in.forEach((helpType) => {
                        queriesObj.push({ specialization__help_type__in: helpType });
                    });
                }

                if (search) {
                    queriesObj.push({ search });
                }
            }

            if (sortOptions) {
                sortOptions.forEach((direction, sortField) => {
                    queriesObj.push({
                        order_by: !(direction === 'asc') ? `-${sortField}` : sortField,
                    });
                });
            }

            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await workersApi.getWorkers(queries);
            const { items: workers, page: pageResp, size: sizeResp, total } = data;
            dispatch(setWorkersAction(workers));
            dispatch(setWorkersPaginationAction({ page: pageResp - 1, size: sizeResp, total }));
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            dispatch(setIsWorkersTableLoaderAction(false));
        }
    };

export const getWorkersListThunk =
    ({ page, size, filters }: IGetWorkersThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [{ page: page ? page + 1 : 1 }, { size: size || 50 }];

            if (filters) {
                const { area_id__in, search, specialization__category_id__in, specialization__help_type__in } = filters;

                if (area_id__in) {
                    area_id__in.forEach((area) => {
                        queriesObj.push({ area_id__in: area });
                    });
                }
                if (specialization__help_type__in) {
                    specialization__help_type__in.forEach((helpType) => {
                        queriesObj.push({ specialization__help_type__in: helpType });
                    });
                }

                if (specialization__category_id__in) {
                    specialization__category_id__in.forEach((categoryId) => {
                        queriesObj.push({ specialization__category_id__in: categoryId });
                    });
                }

                if (search) {
                    queriesObj.push({ search });
                }
            }

            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await workersApi.getWorkers(queries);
            const { items: workers } = data;
            dispatch(setCreateGroupWorkersListAction(workers));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const getWorkersCountThunk = () => async (dispatch: AppDispatch) => {
    try {
        const { data } = await workersApi.getCountNew();
        dispatch(setWorkersCountAction(data?.count || 0));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
