import { AppDispatch } from 'store/store';

import { groupsApi } from '../../api';
import { EGroupsActions } from '../../enums/actions.enum';
import { IGroup, ISetGroupAction, ISetGroupsAction, ISetSsOpenEditGroupAction } from '../../interfaces/groups';

export const setGroupsAction = (payload: IGroup[] | null): ISetGroupsAction => ({
    type: EGroupsActions.SetGroups,
    payload,
});

export const setGroupAction = (payload: IGroup | null): ISetGroupAction => ({
    type: EGroupsActions.SetGroup,
    payload,
});

export const setIsOpenEditGroupAction = (payload: boolean): ISetSsOpenEditGroupAction => ({
    type: EGroupsActions.SetIsOpenEditGroup,
    payload,
});

export const getGroupsThunk = () => async (dispatch: AppDispatch) => {
    try {
        const { data } = await groupsApi.getGroups();
        const { items: groups } = data;
        dispatch(setGroupsAction(groups));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getGroupThunk = (groupId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await groupsApi.getGroup(groupId);
        dispatch(setGroupAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export interface IGroupUpdateData {
    name: string;
    worker_ids: string[];
}

export const updateGroupThunk = (groupId: string, updateData: IGroupUpdateData) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await groupsApi.updateGroup(groupId, updateData);
        dispatch(setGroupAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const deleteGroupThunk = (groupId: string) => async () => {
    try {
        await groupsApi.deleteGroup(groupId);
    } catch (err) {
        console.error(err);
        throw err;
    }
};
