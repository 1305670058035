import { EDayOfWeek } from 'enums/schedule.enum';
import { setFormValuesEditWorkerScheduleAction } from 'store/actions/edit/editWorkerSchedule';
import { useAppDispatch } from 'store/hooks';
import {
    errorsEditWorkerScheduleSelector,
    formValuesEditWorkerScheduleSelector,
} from 'store/selectors/edit/editWorkerScheduleSelectors';

import { IOption } from 'interfaces';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import DayFormItem from './DayFormItem';
import FooterForm from './FooterForm';

import './EditWorkerScheduleForm.scss';

function EditWorkerScheduleForm() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesEditWorkerScheduleSelector);
    const errors = useSelector(errorsEditWorkerScheduleSelector);

    function onChangeTime(
        option: IOption<string> | null,
        week_day: EDayOfWeek,
        type: 'start_time' | 'end_time',
        index: number,
    ) {
        const currentFormValues = formValues?.map((day) => {
            if (day.week_day === week_day) {
                return {
                    ...day,
                    time_windows: day.time_windows.map((time_window, i) => {
                        if (i === index) {
                            return {
                                ...time_window,
                                [type]: option?.value,
                            };
                        }
                        return time_window;
                    }),
                };
            }
            return day;
        });

        dispatch(setFormValuesEditWorkerScheduleAction(currentFormValues ?? null));
    }

    function onAddTime(week_day: EDayOfWeek) {
        const currentFormValues = formValues?.map((day) => {
            if (day.week_day === week_day) {
                return {
                    ...day,
                    time_windows: [...day.time_windows, { start_time: '', end_time: '' }],
                };
            }
            return day;
        });
        dispatch(setFormValuesEditWorkerScheduleAction(currentFormValues ?? null));
    }

    function onDeleteTime(week_day: EDayOfWeek, index: number) {
        const currentFormValues = formValues?.map((day) => {
            if (day.week_day === week_day) {
                return {
                    ...day,
                    time_windows: day.time_windows.filter((time, i) => i !== index),
                };
            }
            return day;
        });
        dispatch(setFormValuesEditWorkerScheduleAction(currentFormValues ?? null));
    }

    return (
        <div className="edit-worker-schedule-form-wrapper">
            {formValues?.map((day, index) => (
                <DayFormItem
                    dayData={day}
                    key={day.week_day}
                    onChangeTime={onChangeTime}
                    onAddTime={onAddTime}
                    onDeleteTime={onDeleteTime}
                    errors={errors}
                    index={index}
                />
            ))}
            <FooterForm />
        </div>
    );
}

export default memo(EditWorkerScheduleForm);
