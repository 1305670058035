import { Box, styled, InputBaseComponentProps } from '@mui/material';

export const ButtonsContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '20px',
});

export const EditIconWrapper = styled('span')({
    marginLeft: '10px',
    cursor: 'pointer',
});

export const CellInputProps: InputBaseComponentProps = {
    style: {
        textAlign: 'center',
        padding: '8px',
        width: '100px',
        fontWeight: '400',
        fontSize: '14px',
    },
};
