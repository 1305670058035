import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { memo } from 'react';

import { ESort } from '../../../../enums/tables.enum';
import { TChatsSortField } from '../../../../interfaces/chats';
import { TDirection, TWorkersSortField } from '../../../../interfaces/workers';

interface IProps {
    sortOptions: Map<TChatsSortField, TDirection>;
    changeSortOption: (value: { sortField: TWorkersSortField; direction: TDirection }) => void;
}

function WorkersTableHead({ sortOptions, changeSortOption }: IProps) {
    function sortHandler(sortField: TWorkersSortField) {
        const currentDirection = sortOptions.get(sortField);
        changeSortOption({ sortField, direction: currentDirection === 'desc' ? 'asc' : 'desc' });
    }

    // @ts-ignore
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        direction={sortOptions.get('created_at') || ESort.Asc}
                        onClick={() => sortHandler('created_at')}
                    >
                        Дата регистрации
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel hideSortIcon>ФИО</TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel hideSortIcon>ID</TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel hideSortIcon>Телефон</TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel hideSortIcon>Баланс</TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel hideSortIcon>Статус</TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel hideSortIcon>Верификация</TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default memo(WorkersTableHead);
