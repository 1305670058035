import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { memo, MouseEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useOutsideClick from '../../hooks/useOutsideClick';
import { cancelDialogAction } from '../../store/actions/dialog';
import { useAppDispatch } from '../../store/hooks';
import { dialogSelector } from '../../store/selectors/dialogSelectors';
import './Dialog.scss';

function Modal() {
    const dispatch = useAppDispatch();
    const {
        onClose,
        titleText,
        visible,
        content,
        loading,
        onOk,
        onOkText,
        onCancelText,
        onCancel,
        autoClose,
        maxWidth,
        minWidth,
        buttonOkFormId,
        size,
    } = useSelector(dialogSelector);

    const handleOutsideClick = () => onClose?.();
    const outsideClickRef = useOutsideClick(handleOutsideClick);

    useEffect(() => {
        if (visible) {
            document.getElementById('dialog-cancel-btn')?.focus();
        }
    }, [visible]);

    const handleOk = () => {
        onOk && onOk();
        autoClose && dispatch(cancelDialogAction());
    };

    const handleCancel = () => {
        onCancel && onCancel();
        autoClose && dispatch(cancelDialogAction());
    };

    const handleClose = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onClose && onClose();
        dispatch(cancelDialogAction());
    };

    const overlayClickHandler = () => {
        onClose && onClose();
        autoClose && dispatch(cancelDialogAction());
    };

    const styles = {
        maxWidth: maxWidth || 'inherit',
        minWidth: minWidth || 'inherit',
    };

    return visible ? (
        <div
            className={cn('dialog__overlay', size)}
            onClick={overlayClickHandler}
            role="button"
            tabIndex={0}
        >
            <div
                className="dialog"
                ref={outsideClickRef}
                style={styles}
            >
                <div className="dialog__header">
                    <div className="dialog-title">{titleText}</div>
                    <div
                        className="dialog__close"
                        onClick={handleClose}
                        role="button"
                        tabIndex={0}
                    >
                        <CloseIcon />
                    </div>
                </div>
                <div className="dialog__content">{content}</div>
                <div className="dialog__footer">
                    {onCancelText ? (
                        <div className="dialog__ok-button">
                            <Button
                                onClick={handleCancel}
                                type="button"
                                id={'dialog-cancel-btn'}
                                disabled={loading}
                            >
                                {onCancelText || 'НЕТ'}
                            </Button>
                        </div>
                    ) : (
                        ''
                    )}
                    {onOkText ? (
                        <div className="dialog__ok-button">
                            <Button
                                onClick={handleOk}
                                form={`${buttonOkFormId}`}
                                disabled={loading}
                            >
                                {onOkText || 'ДА'}
                            </Button>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    ) : null;
}

export default memo(Modal);
