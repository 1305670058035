import { EBrandsActions } from '../../enums/actions.enum';
import { IBrandsState, TBrandsActions } from '../../interfaces/brands';

const initState: IBrandsState = {
    brands: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const brandsReducer = (state = initialState, action: TBrandsActions): IBrandsState => {
    switch (action.type) {
        case EBrandsActions.SetBrands:
            return {
                ...state,
                brands: action.payload,
            };
        default:
            return state;
    }
};
