import { AxiosError } from 'axios';

interface IParams {
    err: AxiosError<any> | any;
    defaultStrError?: string;
    options?: { isIgnoreErrorCode: boolean };
}

export const getErrorString = ({
    err,
    defaultStrError = 'Не удалось загрузить данные',
    options = { isIgnoreErrorCode: true },
}: IParams): string => {
    const response = err?.response;
    if (!response) {
        return `Неизвестная ошибка error code:${err?.code}`;
    }
    const errorMessage = defaultStrError;

    if (response?.data) {
        const errr = response.data;
        if (typeof errr === 'object') {
            const values = Object.values(errr);
            if (options?.isIgnoreErrorCode) {
                const entries = Object.entries(errr);
                entries.forEach(([key, val]) => {
                    if (key === 'error_code') {
                        values.forEach((vl, index) => {
                            if (val === vl) values[index] = null;
                        });
                    }
                });
            }
            const resultArr: string[] = [];
            // eslint-disable-next-line no-shadow
            const recursive = (values: unknown) => {
                if (Array.isArray(values)) {
                    values.forEach((er) => {
                        if (typeof er === 'object' && er !== null) {
                            recursive(er);
                        } else {
                            resultArr.push(er);
                        }
                    });
                } else if (typeof values === 'object' && values !== null) {
                    const vals = Object.values(values);
                    vals.forEach((val) => {
                        if (typeof val === 'object' && val !== null) {
                            recursive(val);
                        } else {
                            resultArr.push(val as string);
                        }
                    });
                }
            };
            recursive(values);
            return resultArr.join(' ');
        }
    }
    return errorMessage;
};
