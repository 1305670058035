import { dateFormatter } from './dateFormatter';

import { ITimeWindow } from '../../interfaces/issues';

export const timeWindowsFormatter = (timeWindows: ITimeWindow[] | null | undefined): string => {
    /* TODO сделать по человечески и попросить бэк избавиться от массива и передавать просто дату и время */
    if (!timeWindows) return '';
    const time_window = timeWindows ? timeWindows[0] : null;
    if (!time_window) return '';
    const time = time_window ? time_window.time[0] : null;
    if (!time) return '';

    return `${dateFormatter(time_window.date, {}, { day: 'numeric', month: 'numeric', year: 'numeric' })} ${
        time?.start_time?.slice(0, -3) || ''
    } - ${time?.end_time?.slice(0, -3) || ''}`;
};
