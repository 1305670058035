import ClearIcon from '@mui/icons-material/Clear';
import { IconButton, Paper } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import EditWorkerSpecializations from './EditWorkerSpecializations';
import SpecializationsTabs from './SpecializationsTabs';

import { EditButton } from '../../../components/EditButton';
import {
    editWorkerSpecializationsThunk,
    setFormValuesEditWorkerSpecializationsAction,
    setIsOpenEditWorkerSpecializationsAction,
    setWorkerIdEditWorkerSpecializationsAction,
} from '../../../store/actions/edit/editWorkerSpecializations';
import { setWorkerSelectedSpecializations, setWorkerSpecializationsAction } from '../../../store/actions/worker';
import { useAppDispatch } from '../../../store/hooks';
import {
    workerDetailSelector,
    workerSelectedSpecializationsSelector,
    workerSelectedTabSelector,
} from '../../../store/selectors/workerSelectors';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { divisionSpecializationByType } from '../../../utils/helpers/divisionSpecializationByType';
import { getErrorString } from '../../../utils/helpers/getErrorString';
import './WorkerSpecializations.scss';

function WorkerSpecializations() {
    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);
    const selectedTab = useSelector(workerSelectedTabSelector);
    const workerSelectedSpecializations = useSelector(workerSelectedSpecializationsSelector);

    const { specializations: workerSpecializations } = worker || {};

    const selectedSpecializationList = workerSpecializations?.filter(({ help_type }) => help_type === selectedTab);

    useEffect(() => {
        if (!workerSpecializations) return;
        dispatch(setWorkerSelectedSpecializations(workerSpecializations));
    }, [workerSpecializations]);

    async function deleteWorkerSpecializationSunOption(subOptionId: string) {
        try {
            if (!worker || !workerSelectedSpecializations) return;

            const newSpecializations = workerSelectedSpecializations.filter(
                (spec) => !(spec.help_type === selectedTab && spec.category_id === subOptionId),
            );
            const { subOptions } = divisionSpecializationByType(newSpecializations);

            if (subOptions.findIndex(({ help_type }) => help_type === selectedTab) === -1) {
                subOptions.push({ help_type: selectedTab, category_ids: [] });
            }

            const editedSpecSubOptions = await dispatch(
                editWorkerSpecializationsThunk(
                    worker.id,
                    subOptions.length ? [...subOptions] : [{ help_type: selectedTab, category_ids: [] }],
                ),
            );
            editedSpecSubOptions && dispatch(setWorkerSpecializationsAction(editedSpecSubOptions));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function openEditWorkerSpecializationsModal() {
        if (!worker || !workerSpecializations) return;
        const { subOptions } = divisionSpecializationByType(workerSpecializations);
        dispatch(setWorkerIdEditWorkerSpecializationsAction(worker.id));
        dispatch(setFormValuesEditWorkerSpecializationsAction(subOptions));
        dispatch(setIsOpenEditWorkerSpecializationsAction(true));
    }

    return (
        <Paper className="worker-specializations">
            <div className="worker-specializations-title">
                <div className="title-text">Специализация</div>
                <EditButton onClick={openEditWorkerSpecializationsModal} />
            </div>
            <EditWorkerSpecializations />
            <SpecializationsTabs />
            {selectedSpecializationList && (
                <div className="specialization-options-container">
                    <div className="specialization-option">
                        <div className="specialization-sub-options-container">
                            {selectedSpecializationList.map((option) => (
                                <div
                                    className="specialization-sub-option"
                                    key={option.id}
                                >
                                    <div className="sub-option-title">{option.title}</div>
                                    <IconButton
                                        className="delete-button"
                                        onClick={() => deleteWorkerSpecializationSunOption(option.id)}
                                    >
                                        <ClearIcon className="delete-button-icon" />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </Paper>
    );
}

export default WorkerSpecializations;
