import cn from 'classnames';
import { format } from 'date-fns';
import { IMessageWithSenderInfo } from 'interfaces/chat';
import React, { useEffect, useRef } from 'react';

import Message from '../Message';

import './Messages.scss';

interface IProp {
    messages: IMessageWithSenderInfo[];
    messagesLoading: boolean;
    deleteMessage: (id: string) => void;
    markMessageAsViewed: (messageId: string) => void;
    setPreviewImgUrl: React.Dispatch<React.SetStateAction<string | null>>;
}

function Messages({ messages, deleteMessage, markMessageAsViewed, setPreviewImgUrl }: IProp) {
    function getMessagesFilteredByTime(
        messagesList: IMessageWithSenderInfo[],
    ): Record<string, IMessageWithSenderInfo[]> {
        const composedMessages: Record<string, IMessageWithSenderInfo[]> = { СЕГОДНЯ: [] };
        const todayDate = format(new Date(), 'dd.MM.yyyy');
        if (!messagesList) return { СЕГОДНЯ: [] };
        messagesList.forEach((message) => {
            const createdAtDate = format(new Date(message.created_at), 'dd.MM.yyyy');
            if (createdAtDate === todayDate) {
                composedMessages['СЕГОДНЯ'].push(message);
            } else if (!composedMessages[createdAtDate]) {
                composedMessages[createdAtDate] = [message];
            } else {
                composedMessages[createdAtDate].push(message);
            }
        });
        return composedMessages;
    }
    const messagesByDates = getMessagesFilteredByTime(messages);

    // подписка на "вход" сообщений в #viewport
    const messageRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const options = {
            root: document.querySelector('.messages-wrapper'),
            rootMargin: '0px',
            threshold: [0.5],
        };

        const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            const entry = entries[0];
            if (
                entry.intersectionRatio > 0.4 &&
                messageRef.current?.getAttribute('data-seen') === 'false' &&
                messageRef.current?.getAttribute('data-sender-type') !== 'backoffice'
            ) {
                markMessageAsViewed(messageRef.current?.getAttribute('id')!);
            }
        }, options);

        if (messageRef.current) observer.observe(messageRef.current);

        return () => {
            observer.disconnect();
        };
    }, [messages, messageRef.current]);

    return messages ? (
        <div className="messages">
            {Object.keys(messagesByDates)
                .reverse()
                .map((key) => {
                    return (
                        <div key={key}>
                            <div className="delimiter">{key}</div>
                            {messagesByDates[key]?.reverse().map((message) => {
                                return (
                                    <div key={message.id}>
                                        <div
                                            ref={
                                                message.is_viewed || message.sender_type === 'backoffice'
                                                    ? null
                                                    : messageRef
                                            }
                                            id={message.id}
                                            data-sender-type={message.sender_type || ''}
                                            data-sender-id={message.sender_id}
                                            data-seen={message.is_viewed}
                                            className={cn('message-wrapper', message.sender_type, message.message_type)}
                                        >
                                            <Message
                                                setPreviewImgUrl={setPreviewImgUrl}
                                                message={message}
                                                deleteMessage={deleteMessage}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    ) : null;
}

export default Messages;
