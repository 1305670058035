import React, { ReactNode } from 'react';

import { cancelDialogAction, setDialogAction } from '../../store/actions/dialog';
import { store } from '../../store/store';

interface IProps {
    errorMessage?: string | ReactNode;
    title?: string | ReactNode | null;
    innerMessage?: string;
    onCancelText?: string;
    onOkText?: string;
}

export default function defaultErrorCallback({
    errorMessage,
    innerMessage,
    title = 'Ошибка!',
    onCancelText = 'Закрыть',
    onOkText = 'Перезагрузить страницу',
}: IProps) {
    const onOk = () => {
        store.dispatch(cancelDialogAction());
        window.location.reload();
    };
    const onCancel = () => store.dispatch(cancelDialogAction());

    const actionData = {
        onOk,
        autoClose: false,
        onCancel,
        onClose: onCancel,
        maxWidth: '700px',
        onOkText,
        onCancelText,
        titleText: <span>{title}</span>,
        content: innerMessage ? (
            // eslint-disable-next-line react/no-danger
            <div
                className="error-callback-message"
                dangerouslySetInnerHTML={{ __html: innerMessage }}
            />
        ) : (
            <p className="error-callback-message">{errorMessage}</p>
        ),
    };

    store.dispatch(setDialogAction(actionData));
}
