import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Checkbox, InputAdornment, TextField } from '@mui/material';
import React from 'react';

import Loader from '../../../../../components/Loader';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import { IOption } from '../../../../../interfaces';
import './CustomAutocomplete.scss';

interface IProps {
    options: { value: string; label: string }[] | null;
    selectedIds: string[] | null | undefined;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSelect: (option: IOption<string> | null, checked: boolean) => void;
    loadMoreAppointmentsElem: React.RefObject<any>;
    isLoadMoreAppointments: boolean | null;
    label: string;
    error?: boolean;
    errorText?: string;
}

function CustomAutocomplete({
    options,
    selectedIds,
    isOpen,
    setIsOpen,
    onSearch,
    onSelect,
    loadMoreAppointmentsElem,
    isLoadMoreAppointments,
    label,
    error,
    errorText,
}: IProps) {
    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    return (
        <div
            ref={wrapperRef}
            className="auto-wrapper"
        >
            <TextField
                id="auto"
                label={label}
                placeholder=""
                fullWidth
                onChange={onSearch}
                error={error}
                helperText={errorText}
                onClick={() => setIsOpen(!isOpen)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                        </InputAdornment>
                    ),
                }}
            />
            {isOpen && (
                <div className="auto-container scroll-wrapper">
                    {(() => {
                        switch (true) {
                            case !options: {
                                return (
                                    <div style={{ height: '110px' }}>
                                        <Loader />
                                    </div>
                                );
                            }
                            case !!options?.length: {
                                return (
                                    <>
                                        {options?.map((option, i) => {
                                            return (
                                                <label
                                                    key={option.value}
                                                    htmlFor={`checkbox_${i}`}
                                                    className="auto-item"
                                                >
                                                    <Checkbox
                                                        id={`checkbox_${i}`}
                                                        onChange={(_, checked) => {
                                                            onSelect(option, checked);
                                                        }}
                                                        style={{ marginRight: 8 }}
                                                        checked={selectedIds?.includes(option.value)}
                                                    />
                                                    {option.label}
                                                </label>
                                            );
                                        })}
                                        <div
                                            // @ts-ignore
                                            ref={loadMoreAppointmentsElem}
                                            style={{ position: 'relative', bottom: 5, border: 'none', width: '100%' }}
                                        >
                                            <div style={{ height: 20 }}>{isLoadMoreAppointments && <Loader />}</div>
                                        </div>
                                    </>
                                );
                            }
                            default:
                                return <div className="auto-plug">Ничего не найдено</div>;
                        }
                    })()}
                </div>
            )}
        </div>
    );
}

export default CustomAutocomplete;
