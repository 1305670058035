import { getWorkersListThunk, setSelectedWorkersIdsAction } from 'store/actions/workers';
import { useAppDispatch } from 'store/hooks';
import { createGroupWorkersListSelector } from 'store/selectors/create/createGroupSelector';
import { selectedWorkersIdsSelector, workersFiltersSelector } from 'store/selectors/workersSelectors';

import { Avatar, Checkbox } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './WorkersList.scss';

function WorkersList() {
    const dispatch = useAppDispatch();

    const workers = useSelector(createGroupWorkersListSelector);
    const selectedWorkers = useSelector(selectedWorkersIdsSelector);
    const filters = useSelector(workersFiltersSelector);

    function selectWorker(e: ChangeEvent<HTMLInputElement>, checked: boolean) {
        checked
            ? dispatch(
                  setSelectedWorkersIdsAction(
                      selectedWorkers ? [...selectedWorkers, e.target.value] : [e.target.value],
                  ),
              )
            : dispatch(
                  setSelectedWorkersIdsAction(
                      selectedWorkers && selectedWorkers.filter((worker) => worker !== e.target.value),
                  ),
              );
    }

    useEffect(() => {
        async function handle() {
            await dispatch(
                getWorkersListThunk({
                    filters,
                }),
            );
        }
        handle();
    }, [filters]);

    return (
        <div className={workers && workers.length ? 'workers-list-wrapper' : 'empty-list'}>
            {workers && workers.length
                ? workers.map((worker) => (
                      <div
                          className="worker-container"
                          key={worker.id}
                      >
                          <div className="worker">
                              <Avatar
                                  className="avatar"
                                  src={worker.profile_photo_file?.url}
                              />
                              <div className="worker-data">
                                  <div className="worker-name">
                                      {worker.first_name} {worker.last_name}
                                  </div>
                                  <div className="worker-id">id: {worker.id}</div>
                              </div>
                          </div>
                          <Checkbox
                              onChange={selectWorker}
                              value={worker.id}
                              checked={!!selectedWorkers?.some((workerId) => workerId === worker.id)}
                          />
                      </div>
                  ))
                : 'Работники не найдены'}
        </div>
    );
}

export default WorkersList;
