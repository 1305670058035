import { EPriceDialogActions } from '../../enums/actions.enum';
import { IPriceDialogState, TPriceDialogActions } from '../../interfaces/priceDialog';

const initState: IPriceDialogState = {
    dialogState: { isOpen: false, isEdit: false },
    priceId: null,
    categories: null,
    categoriesPagination: { page: 0, size: 50, total: 0 },
    additionalPrices: null,
    additionalPricesPagination: { page: 0, size: 50, total: 0 },
    formValues: {},
    errors: null,
    selectedCategories: null,
    selectedAdditionalPrices: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const priceDialogReducer = (state = initialState, action: TPriceDialogActions): IPriceDialogState => {
    switch (action.type) {
        case EPriceDialogActions.SetIsOpen:
            return {
                ...state,
                dialogState: action.payload,
            };
        case EPriceDialogActions.SetPriceId:
            return {
                ...state,
                priceId: action.payload,
            };
        case EPriceDialogActions.SetCategories:
            return {
                ...state,
                categories: action.payload,
            };
        case EPriceDialogActions.SetCategoriesPagination:
            return {
                ...state,
                categoriesPagination: action.payload,
            };
        case EPriceDialogActions.SetAdditionalPrices:
            return {
                ...state,
                additionalPrices: action.payload,
            };
        case EPriceDialogActions.SetAdditionalPricesPagination:
            return {
                ...state,
                additionalPricesPagination: action.payload,
            };
        case EPriceDialogActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EPriceDialogActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case EPriceDialogActions.SetSelectedCategories:
            return {
                ...state,
                selectedCategories: action.payload,
            };
        case EPriceDialogActions.SetSelectedAdditionalPrices:
            return {
                ...state,
                selectedAdditionalPrices: action.payload,
            };
        default:
            return state;
    }
};
