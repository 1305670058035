import Centrifuge from 'centrifuge';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import defaultErrorCallback from './helpers/defaultErrorCallback';
import { getErrorString } from './helpers/getErrorString';

import { setCentrifugeInstanceAction, setIsConnectAction } from '../store/actions/centrifuge';
import { getWebsocketChannelThunk } from '../store/actions/websockets';
import { useAppDispatch } from '../store/hooks';
import { WSChannelSelector } from '../store/selectors/websocketsSelectors';

function CentrifugeConnector() {
    const dispatch = useAppDispatch();

    const WSChannel = useSelector(WSChannelSelector);

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getWebsocketChannelThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, []);

    useEffect(() => {
        if (!WSChannel) return;

        const centrifuge = new Centrifuge(`${process.env.REACT_APP_WSS}`, { minRetry: 50 });
        centrifuge.setToken(WSChannel?.auth_jwt);
        centrifuge.connect();

        centrifuge.on('connect', () => {
            dispatch(setIsConnectAction(true));
            console.log('Centrifuge is connect');
        });

        centrifuge.on('disconnected', (ctx) => {
            dispatch(setIsConnectAction(false));
            console.log('Centrifuge is disconnected', ctx);
        });

        dispatch(setCentrifugeInstanceAction(centrifuge));
    }, [WSChannel?.auth_jwt]);

    return <> </>;
}

export default memo(CentrifugeConnector);
