import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IPhotoFile } from '../interfaces';

export default class FilesAPI {
    postFile(data: FormData) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/files`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IPhotoFile>;
    }

    createFile(data: FormData) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/files`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IPhotoFile>;
    }

    deleteFile(fileId: IPhotoFile['id']) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/files/${fileId}`,
            isAuthorization: true,
        }) as AxiosPromise<IPhotoFile>;
    }
}
