export const ActiveRadioChecked = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="24"
                height="24"
                rx="12"
                fill="#E1E4FA"
            />
            <path
                d="M16.5 9L10.5 15L7.5 12"
                stroke="#0040F1"
                strokeWidth="2"
                strokeLinejoin="bevel"
            />
        </svg>
    );
};
