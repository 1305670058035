import { EWSHistoryType } from 'enums/wsHistory.enum';
import { updateChatGroupByUserAction } from 'store/actions/chatGroupByUser';
import { insertChatGroupByUsersAction, setReloadAction } from 'store/actions/chatGroupByUsers';
import { setChatsAction, setTotalUnseenCountAction, setTotalUnseenCountIssuesAction } from 'store/actions/chats';
import { insertChatsInfiniteAction } from 'store/actions/chatsInfinite';
import { getIssueThunk } from 'store/actions/issues';
import { setWSHistoryAction } from 'store/actions/websockets';
import { setWorkersCountAction } from 'store/actions/workers';
import { useAppDispatch } from 'store/hooks';
import { centrifugeInstanceSelector, centrifugeIsConnectSelector } from 'store/selectors/centrifugeSelectors';
import { chatGroupByUserSelector } from 'store/selectors/chatGroupByUserSelectors';
import { chatGroupByUsersFiltersSelector } from 'store/selectors/chatGroupByUsersSelectors';
import { chatDetailSelector } from 'store/selectors/chatSelectors';
import { chatsSelector } from 'store/selectors/chatsSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';
import { profileSelector } from 'store/selectors/profileSelectors';
import { historySelector } from 'store/selectors/websocketsSelectors';

import Centrifuge from 'centrifuge';
import { ICSChatUpdateExtraData, ICSEvent } from 'interfaces/centrifuge';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

let baseSubscribe: Centrifuge.Subscription | null = null;
let profileSubscribe: Centrifuge.Subscription | null = null;
const baseChannelName = 'notification:backoffice';

function CentrifugeDistributor() {
    const dispatch = useAppDispatch();

    const centrifuge = useSelector(centrifugeInstanceSelector) as Centrifuge | null;
    const isConnect = useSelector(centrifugeIsConnectSelector);
    const profile = useSelector(profileSelector);

    const userFilters = useSelector(chatGroupByUsersFiltersSelector);
    const issue = useSelector(issueSelector);
    const history = useSelector(historySelector);
    const chatDetail = useSelector(chatDetailSelector);
    const chats = useSelector(chatsSelector);
    const chatUser = useSelector(chatGroupByUserSelector);

    const [lastEvent, setLastEvent] = useState<ICSEvent | null>(null);

    useEffect(() => {
        function handle() {
            if (!centrifuge || !isConnect) return;

            baseSubscribe = centrifuge.subscribe(baseChannelName, (e) => {
                setLastEvent(e);
            });

            centrifuge.on('connected', (ctx) => {
                console.log('Centrifuge connected:', ctx);
            });

            centrifuge.on('connecting', (ctx) => {
                console.log('CONNECTING:', ctx);
            });
        }

        handle();

        return () => {
            if (baseSubscribe) {
                baseSubscribe.unsubscribe();
            }
        };
    }, [isConnect]);

    useEffect(() => {
        if (!profile || !centrifuge) return;

        profileSubscribe = centrifuge.subscribe(`${baseChannelName}:${profile.id}`, (e) => {
            setLastEvent(e);
        });

        // eslint-disable-next-line consistent-return
        return () => {
            if (profileSubscribe) {
                profileSubscribe.unsubscribe();
            }
        };
    }, [profile?.id]);

    /* Распределение event'ов это то что тебе нужно если что-то изменилось в объектах event. Всё что выше(useEffect'ы [isConnect],[profile?.id]) - касается только подписок(subscribe) не трогай. */
    useEffect(() => {
        function handle() {
            if (!lastEvent) return;

            function addNewHistoryItem(event: ICSEvent) {
                const newEvent = JSON.parse(JSON.stringify(event));
                newEvent.seen = false;

                //TODO: Либо попросить бэка присылать в том же виде, что и history[]
                const newHistory = {
                    created_at: newEvent.data.data.created_at,
                    data: { ...newEvent.data },
                    id: newEvent.data.data.notification_id,
                    seen: newEvent.seen,
                };
                dispatch(setWSHistoryAction(history ? [newHistory, ...history] : [newEvent]));
            }

            // В вебсокет при сообщениях в чате прилетает сразу три сообщения некоторые типы этих сообщений касаются только каунтеров в leftSideBar
            switch (lastEvent.data.type) {
                case EWSHistoryType.NewIssue: {
                    addNewHistoryItem(lastEvent);
                    break;
                }
                case EWSHistoryType.IssueNewMessage: {
                    addNewHistoryItem(lastEvent);
                    break;
                }
                case EWSHistoryType.IssueStatusUpdate: {
                    addNewHistoryItem(lastEvent);
                    break;
                }
                case EWSHistoryType.IssueTimeUpdate: {
                    addNewHistoryItem(lastEvent);
                    break;
                }
                case EWSHistoryType.IssueUpdate: {
                    const issueId = lastEvent.data.data.id;
                    if (issue?.id === issueId) {
                        dispatch(getIssueThunk(issueId));
                    }
                    break;
                }
                // Попросить бэк удалить этот эвент
                case EWSHistoryType.ChatSupportTotalUnseenCountUpdate:
                case EWSHistoryType.ChatUnseenCountUpdate: {
                    if (lastEvent.data?.type === EWSHistoryType.ChatUnseenCountUpdate) {
                        const currentChatId = lastEvent.data?.data?.id;
                        const newUnseenCountChat = Number(lastEvent.data?.data?.data?.unseen_count);

                        const newChats = chats?.map((chat) => {
                            if (chat.chat.id === currentChatId) {
                                return {
                                    ...chat,
                                    unseen_count: newUnseenCountChat,
                                };
                            }
                            return chat;
                        });
                        newChats && dispatch(setChatsAction(newChats));
                    }

                    if (lastEvent.data?.type === EWSHistoryType.ChatSupportTotalUnseenCountUpdate) {
                        const newUnseenCount = Number(lastEvent.data?.data?.data?.unseen_count);

                        /* открыта страница чата по которому изменился unseen_count */
                        const isCurrentChat =
                            lastEvent.data?.data?.id && chatDetail?.chat?.id === lastEvent.data?.data?.id;

                        if (!newUnseenCount || isCurrentChat) break;

                        dispatch(setTotalUnseenCountAction(newUnseenCount));
                    }
                    break;
                }
                case EWSHistoryType.WorkerNewCountUpdate:
                    if (!lastEvent.data?.data?.data?.count) break;
                    const newUnseenCount = Number(lastEvent.data?.data?.data?.count);
                    dispatch(setWorkersCountAction(newUnseenCount));
                    break;
                case EWSHistoryType.ChatUpdate:
                    const extra_data: ICSChatUpdateExtraData | undefined = lastEvent.data.data?.extra_data;
                    if (!extra_data) {
                        break;
                    }

                    dispatch(setTotalUnseenCountAction(extra_data?.chat_total_unseen_count));

                    const isCurrentChat = lastEvent.data?.data?.id && chatDetail?.chat?.id === lastEvent.data?.data?.id;
                    const isNewMessage = extra_data.event === 'new_message';
                    const isFilters = Object.values(userFilters.params).filter(Boolean).length;
                    const isBackoffice = extra_data.chat.last_message?.sender_type === 'backoffice';

                    if (!isBackoffice) {
                        if ((!isCurrentChat && isNewMessage && chatUser) ?? isFilters) {
                            dispatch(setReloadAction({ shouldReload: true }));
                        }
                    }

                    const { search, user_type } = userFilters.params;

                    if (user_type) {
                        dispatch(
                            insertChatGroupByUsersAction(
                                extra_data.users.filter((item) => item.user_type === user_type),
                            ),
                        );
                    } else if (search) {
                        const { user: searchedUser } = extra_data.users[0];
                        const name = `${searchedUser?.first_name} ${searchedUser?.last_name}`;

                        if (new RegExp(search, 'gi').test(name)) {
                            dispatch(insertChatGroupByUsersAction(extra_data.users));
                        }
                    } else {
                        dispatch(insertChatGroupByUsersAction(extra_data.users));
                    }

                    dispatch(updateChatGroupByUserAction(extra_data.users));

                    if (issue) {
                        if (issue.id === extra_data.chat.chat.issue_id) {
                            dispatch(setTotalUnseenCountIssuesAction(extra_data?.issue_unseen_count));

                            const { chat, users } = extra_data;
                            chat.participants = [users[0].user!];

                            dispatch(insertChatsInfiniteAction(extra_data.chat));
                        }
                    } else if (isBackoffice) {
                        if (lastEvent.data.data.id === chatUser?.last_message?.chat_id) {
                            dispatch(insertChatsInfiniteAction(extra_data.chat));
                        }
                    } else if (extra_data.users[0].user_id === chatUser?.user_id) {
                        dispatch(insertChatsInfiniteAction(extra_data.chat));
                    }

                    break;
                default:
                    break;
            }
        }

        handle();
    }, [lastEvent]);

    return null;
}

export default memo(CentrifugeDistributor);
