import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PricesServicesTable from './PricesServicesTable';
import ServiceRow from './PricesServicesTable/ServiceRow';

import { pricesServicesTableHeadMock } from '../../../mockData/pricesServices';
import { getServicesThunk, setIsPriceServicesLoadingAction } from '../../../store/actions/pricesServices';
import { useAppDispatch } from '../../../store/hooks';
import {
    isPricesServicesLoadingSelector,
    priceServicesSelector,
} from '../../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';

function PricesServices() {
    const dispatch = useAppDispatch();
    const services = useSelector(priceServicesSelector);
    const isServiceLoading = useSelector(isPricesServicesLoadingSelector);

    useEffect(() => {
        async function handle() {
            try {
                dispatch(setIsPriceServicesLoadingAction(true));
                await dispatch(getServicesThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                dispatch(setIsPriceServicesLoadingAction(false));
            }
        }
        handle();
    }, []);

    return (
        <PricesServicesTable
            tableHead={pricesServicesTableHeadMock}
            ComponentRow={ServiceRow}
            tableContent={services}
            isLoading={isServiceLoading}
        />
    );
}

export default PricesServices;
