import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IPaginationResp } from '../interfaces/pagination';
import { IMarkAsViewedReq, IWSChannel, IWSHistory } from '../interfaces/websockets';

export default class WebsocketsAPI {
    /* Канал по которому коннектится centrifuge */
    getChanel() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/websockets/channel`,
            isAuthorization: true,
        }) as AxiosPromise<IWSChannel>;
    }

    /* Список уведомлений */
    getHistory(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/websockets/history${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<IWSHistory>>;
    }

    /* Отметить уведомление из getHistory как прочитанное */
    postMarkAsViewed(data: IMarkAsViewedReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/websockets/markAsViewed`,
            isAuthorization: true,
            data,
        }) as AxiosPromise<{ message: string }>;
    }
}
