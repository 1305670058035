import { issuesApi } from '../../../api';
import { EEditIssueStatusActions } from '../../../enums/actions.enum';
import { EIssueStatus } from '../../../enums/issues.enum';
import { TErrors } from '../../../interfaces';
import {
    IFormValuesEditIssueStatus,
    ISetErrorsEditIssueStatusAction,
    ISetFormValuesEditIssueStatusAction,
    ISetIsOpenEditIssueStatusAction,
    ISetIssueIdEditIssueStatusAction,
} from '../../../interfaces/edit/editIssueStatus';
import { IIssue } from '../../../interfaces/issues';

export const setIsOpenEditIssueStatusAction = (payload: boolean): ISetIsOpenEditIssueStatusAction => ({
    type: EEditIssueStatusActions.SetIsOpen,
    payload,
});

export const setIssueIdEditIssueStatusAction = (payload: IIssue['id'] | null): ISetIssueIdEditIssueStatusAction => ({
    type: EEditIssueStatusActions.SetIssueId,
    payload,
});

export const setFormValuesEditIssueStatusAction = (
    payload: IFormValuesEditIssueStatus | null,
): ISetFormValuesEditIssueStatusAction => ({
    type: EEditIssueStatusActions.SetFormValues,
    payload,
});

export const setErrorsEditIssueStatusAction = (payload: TErrors | null): ISetErrorsEditIssueStatusAction => ({
    type: EEditIssueStatusActions.SetErrors,
    payload,
});

export const editIssueStatusThunk = (issueId: string, formValues: IFormValuesEditIssueStatus) => {
    return async () => {
        try {
            const { data } = await issuesApi.editIssueStatus(issueId, {
                status: formValues.status!.value as EIssueStatus,
                comment: formValues.comment!,
            });
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
