import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import StatusFilters from './StatusFilters';

import FilterDate from '../../../components/FilterDate';
import { TCategoryPreset } from '../../../interfaces/issues';
import { dateCallFiltersMock, dateOfCreatingFiltersMock } from '../../../mockData/issues';
import DirectionsFilter from '../../../modules/DirectionsFilter';
import ObserversFilter from '../../../modules/ObserversFilter';
import { setIssuesFiltersAction } from '../../../store/actions/issues';
import { useAppDispatch } from '../../../store/hooks';
import { issuesFiltersSelector } from '../../../store/selectors/issuesSelectors';
import { profileSelector } from '../../../store/selectors/profileSelectors';

import './IssuesFilters.scss';

function IssuesFilters() {
    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);
    const filters = useSelector(issuesFiltersSelector);

    function changeDateOfCreatingFilter(data: { startTime: string | null; endTime: string | null }) {
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                created_at__gte: data.startTime ? data.startTime : null,
                created_at__lte: data.endTime ? data.endTime : null,
            }),
        );
    }

    function changeDateCallFilter(data: { startTime: string | null; endTime: string | null }) {
        dispatch(
            setIssuesFiltersAction({
                ...(filters || {}),
                time_window__gte: data.startTime ? data.startTime : null,
                time_window__lte: data.endTime ? data.endTime : null,
            }),
        );
    }

    function onChangeCategoryPreset(categoryPreset: TCategoryPreset | null) {
        dispatch(setIssuesFiltersAction({ ...(filters || {}), category_preset: categoryPreset }));
    }

    function onChangeObserveIds(observers_ids: string[] | null) {
        dispatch(
            setIssuesFiltersAction({ ...(filters || {}), observers_ids: observers_ids?.length ? observers_ids : null }),
        );
    }

    function onChangeObserveIsNull(observers__isnull: boolean) {
        dispatch(setIssuesFiltersAction({ ...(filters || {}), observers__isnull }));
    }

    function onClearObserveIdsObserveIsNull() {
        dispatch(setIssuesFiltersAction({ ...(filters || {}), observers_ids: null, observers__isnull: false }));
    }

    return (
        <div className="issue-filters-wrapper">
            <Box
                display={'flex'}
                columnGap={2}
                width="100%"
            >
                <FilterDate
                    title="Дата создания"
                    filterItems={dateOfCreatingFiltersMock}
                    onChange={changeDateOfCreatingFilter}
                />
                <FilterDate
                    title="Дата вызова"
                    filterItems={dateCallFiltersMock}
                    onChange={changeDateCallFilter}
                />
                <DirectionsFilter
                    onChange={onChangeCategoryPreset}
                    selectedCategoryPreset={filters?.category_preset}
                />
                <ObserversFilter
                    onChangeObserveIds={onChangeObserveIds}
                    onChangeObserveIsNull={onChangeObserveIsNull}
                    onClear={onClearObserveIdsObserveIsNull}
                    profileId={profile?.id || null}
                    selectedObserverIds={filters?.observers_ids || null}
                    selectedObserverIsnull={!!filters?.observers__isnull}
                />
            </Box>
            <StatusFilters />
        </div>
    );
}

export default IssuesFilters;
