import Centrifuge from 'centrifuge';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Chat from './Chat';

import { chatsApi, filesApi } from '../../api';
import { EChatTypes } from '../../enums/chats.enum';
import { IChanel } from '../../interfaces/chanel';
import { IChatAvailable, IChatData } from '../../interfaces/chats';
import {
    addChatHistoryItemAction,
    deleteChatHistoryItemAction,
    deleteChatMessageThunk,
    getChatHistoryAndChatParticipantsThunk,
    markAsViewedChatHistoryItemsAction,
} from '../../store/actions/chat';
import { useAppDispatch } from '../../store/hooks';
import { centrifugeInstanceSelector } from '../../store/selectors/centrifugeSelectors';
import { chatMessagesWithSendersInfoSelector } from '../../store/selectors/chatSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import './ChatModule.scss';

interface IProps {
    channelId: IChanel['id'];
    chatId: IChatData['id'];
    headerText?: string;
    chat_type?: EChatTypes;
    chat_available?: IChatAvailable | null;
}

function ChatModule({ channelId, chatId, headerText, chat_type, chat_available }: IProps) {
    const dispatch = useAppDispatch();

    const chatMessages = useSelector(chatMessagesWithSendersInfoSelector);
    const centrifuge = useSelector(centrifugeInstanceSelector) as Centrifuge;

    const [isLoadingUploadFiles, setIsLoadingUploadFiles] = useState(false);
    const [isMessagesLoading, setIsMessagesLoading] = useState(false);

    useEffect(() => {
        getChatMessages();
        let subscription: Centrifuge.Subscription | null = null;
        if (!centrifuge || !channelId) return;
        subscription = centrifuge.subscribe(channelId, (ctx: any) => {
            switch (ctx.data.event) {
                case 'new_message':
                    dispatch(addChatHistoryItemAction(ctx.data.message));
                    break;
                case 'message_deleted':
                    dispatch(deleteChatHistoryItemAction(ctx.data.message.id));
                    break;
                case 'seen_messages':
                    dispatch(markAsViewedChatHistoryItemsAction(ctx.data.seen_messages));
                    break;
                default:
                    break;
            }
        });

        return () => {
            subscription && channelId && subscription.unsubscribe();
        };
    }, [chatId]);

    async function getChatMessages() {
        setIsMessagesLoading(true);
        try {
            if (chatId) {
                await dispatch(getChatHistoryAndChatParticipantsThunk(chatId));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
        setIsMessagesLoading(false);
    }

    async function deleteMessage(id: string) {
        await dispatch(deleteChatMessageThunk(chatId || '', id));
    }

    async function postMessageWithFiles(files: File[], text?: string) {
        if (!chatId) return;

        const promises = files.map((file) => {
            const formData = new FormData();
            formData.append('file', file);
            return filesApi.postFile(formData);
        });

        try {
            setIsLoadingUploadFiles(true);
            await Promise.all(promises).then(async (res) => {
                const attachments = res.map((item) => item.data);
                await chatsApi.postMessage({ chat: { id: chatId }, message: { text, attachments } });
            });
        } finally {
            setIsLoadingUploadFiles(false);
        }
    }

    async function sendMessage(text?: string, files?: File[]) {
        try {
            if (files) {
                await postMessageWithFiles(files, text);
            } else if (text) {
                chatId && (await chatsApi.postMessage({ chat: { id: chatId }, message: { text } }));
            }
        } catch (err) {
            console.error(err);
            defaultErrorCallback({
                errorMessage: getErrorString({
                    err,
                    defaultStrError: 'Не удалось отправить сообщение',
                }),
            });
        }
    }

    function markMessagesAsViewed(messageId: string) {
        if (!chatId) return;
        chatsApi.markMessagesAsViewed(chatId, messageId);
    }

    return (
        <div className="chat-wrapper">
            {!!headerText && <div className="chat-header">{headerText}</div>}
            <Chat
                messages={chatMessages}
                messagesLoading={isMessagesLoading}
                deleteMessage={(id) => deleteMessage(id)}
                sendMessage={(text, attachments) => sendMessage(text, attachments)}
                isLoadingUploadFiles={isLoadingUploadFiles}
                markMessageAsViewed={(messageId) => markMessagesAsViewed(messageId)}
                chat_type={chat_type}
                chat_available={chat_available}
            />
        </div>
    );
}

export default ChatModule;
