import { issueSelector } from 'store/selectors/issuesSelectors';

import { FormControl, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore // todo разобраться в чём проблема с типизацией в пакете она есть
import { useDebouncedCallback } from 'use-lodash-debounce';

import Autocomplete from '../../../../../components/inputs/Autocomplete';
import TextField from '../../../../../components/inputs/TextField';
import { IOption } from '../../../../../interfaces';
import { setFormValuesCIAction } from '../../../../../store/actions/create/createIssue';
import { getAddressesDictsThunk } from '../../../../../store/actions/dicts';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsCreateIssueSelector,
    formValuesCreateIssueSelector,
} from '../../../../../store/selectors/create/createIssuesSelectors';
import { addressesDictsSelector, addressesOptionsDictsSelector } from '../../../../../store/selectors/dictsSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

function Address() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const addressesOptions = useSelector(addressesOptionsDictsSelector);
    const errors = useSelector(errorsCreateIssueSelector);
    const formValues = useSelector(formValuesCreateIssueSelector);
    const address = useSelector(addressesDictsSelector);

    const changeAddressInpDebounced = useDebouncedCallback(changeAddressInputHandler, 800);

    async function changeAddressInputHandler(val: string) {
        try {
            if (!val) return;
            await dispatch(getAddressesDictsThunk({ address: val }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function changeAddressHandler(option: IOption | null) {
        dispatch(setFormValuesCIAction({ address: (option?.label as string) || null }));
        dispatch(setFormValuesCIAction({ addressFiasId: (option?.value as string) || null }));

        const currentAddress = address?.find((dicts) => dicts.name === option?.label);
        dispatch(setFormValuesCIAction({ lat: currentAddress?.lat || issue?.address_parts?.lat || null }));
        dispatch(setFormValuesCIAction({ long: currentAddress?.long || issue?.address_parts?.long || null }));
        dispatch(setFormValuesCIAction({ fias_id: currentAddress?.fias_id || issue?.address_parts?.fias_id || null }));
    }

    function changeApartmentHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ apartment: e.target.value || null }));
    }

    function changeEntranceHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ entrance: e.target.value || null }));
    }

    function changeFloorHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ floor: e.target.value || null }));
    }

    function changeIntercomHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ intercom: e.target.value || null }));
    }

    useEffect(() => {
        if (!issue) return;
        changeAddressHandler({ value: issue.address, label: issue.address });
    }, [issue]);

    return (
        <div>
            <Typography
                variant="caption"
                display="block"
                gutterBottom
                fontSize={12}
                sx={{ mt: 2 }}
            >
                Адрес вызова
            </Typography>
            <FormControl fullWidth>
                <Autocomplete
                    name={'address'}
                    inpPlaceholder="Город, улица, дом *"
                    onInpChange={changeAddressInpDebounced}
                    onChange={changeAddressHandler}
                    loadingText={'Загрузка...'}
                    options={addressesOptions || []}
                    isOptionEqualToValue={() => true}
                    defaultValue={{ value: issue?.address || '', label: issue?.address || '' }}
                />
            </FormControl>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="apartment"
                        onChange={changeApartmentHandler}
                        placeholder="Квартира"
                        labelText="Квартира"
                        fullWidth
                        params={{ margin: 'normal' }}
                        errors={errors}
                        value={issue?.address_parts?.apartment || ''}
                    />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="entrance"
                        errors={errors}
                        onChange={changeEntranceHandler}
                        placeholder="Подъезд"
                        labelText="Подъезд"
                        fullWidth
                        params={{ margin: 'normal' }}
                        value={issue?.address_parts?.entrance || ''}
                    />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="floor"
                        errors={errors}
                        onChange={changeFloorHandler}
                        placeholder="Этаж"
                        labelText="Этаж"
                        fullWidth
                        params={{ margin: 'normal' }}
                        value={issue?.address_parts?.floor || ''}
                    />
                </Grid>
                <Grid
                    item
                    md={3}
                    xs={6}
                >
                    <TextField
                        name="intercom"
                        errors={errors}
                        onChange={changeIntercomHandler}
                        placeholder="Домофон"
                        labelText="Домофон"
                        fullWidth
                        params={{ margin: 'normal' }}
                        value={issue?.address_parts?.intercom || ''}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        name="latitude"
                        labelText="Широта"
                        disabled
                        fullWidth
                        value={formValues?.lat || issue?.address_parts?.lat || ''}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        name="longitude"
                        labelText="Долгота"
                        disabled
                        fullWidth
                        value={formValues?.long || issue?.address_parts?.long || ''}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Address;
