import { Grid, Typography } from '@mui/material';
import React from 'react';

import TextField from '../../../../../components/inputs/TextField';
import { setFormValuesCIAction } from '../../../../../store/actions/create/createIssue';
import { useAppDispatch } from '../../../../../store/hooks';

function Additional() {
    const dispatch = useAppDispatch();

    function changeCodesIssueHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ codes_issue: e.target.value || null }));
    }

    function changeCodesCodeHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ codes_code: e.target.value || null }));
    }

    return (
        <div style={{ marginTop: 20 }}>
            <Typography
                variant="caption"
                display="block"
                gutterBottom
                fontSize={12}
            >
                Дополнительно
            </Typography>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        placeholder="Номер заявки партнера"
                        name={'codes_issue'}
                        onChange={changeCodesIssueHandler}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        placeholder="Код менеджера партнера"
                        name={'codes_code'}
                        onChange={changeCodesCodeHandler}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Additional;
