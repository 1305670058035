import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { ICreateDeviceReq, IDeviceDetail } from '../interfaces/devices';

export default class DevicesAPI {
    createDevice(createDeviceReq: ICreateDeviceReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/devices`,
            data: createDeviceReq,
            isAuthorization: true,
        }) as AxiosPromise<IDeviceDetail>;
    }

    getDeviceDetail(deviceId: IDeviceDetail['id']) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/devices/${deviceId}`,
            isAuthorization: true,
        }) as AxiosPromise<IDeviceDetail>;
    }

    getHomeDeviceByParams(queries: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/devices/getHomeDeviceByParams${
                queries || ''
            }`,
            isAuthorization: true,
        }) as AxiosPromise<{ id: IDeviceDetail['id'] }>;
    }
}
