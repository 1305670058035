import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import Autocomplete from '../../../../components/inputs/Autocomplete';
import TextField from '../../../../components/inputs/TextField';
import { EIssueStatus } from '../../../../enums/issues.enum';
import { IOption } from '../../../../interfaces';
import { issueStatusesMock } from '../../../../mockData/issues';
import { setFormValuesEditIssueStatusAction } from '../../../../store/actions/edit/editIssueStatus';
import { useAppDispatch } from '../../../../store/hooks';
import {
    errorsEditIssueStatusSelector,
    formValuesEditIssueStatusSelector,
} from '../../../../store/selectors/edit/editIssueStatusSelectors';
import './EditIssueStatusForm.scss';

interface IProps {
    currentStatus: EIssueStatus | null;
}

function EditIssueStatusForm({ currentStatus }: IProps) {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesEditIssueStatusSelector);
    const errors = useSelector(errorsEditIssueStatusSelector);

    const statusOptions: IOption<string>[] = Object.values(EIssueStatus)
        .map((value) => {
            let title: string = '';
            switch (value) {
                case EIssueStatus.ProblemByMaster:
                    title = 'Проблемная заявка (от мастера)';
                    break;
                case EIssueStatus.ProblemByClient:
                    title = 'Проблемная заявка (от клиента)';
                    break;
                default:
                    title = issueStatusesMock[value].title;
                    break;
            }
            return { value, label: title };
        })
        .filter((option) => option.value !== currentStatus);

    function onChangeStatus(option: IOption<string> | null) {
        dispatch(setFormValuesEditIssueStatusAction({ status: option || null }));
    }

    function onChangeComment(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditIssueStatusAction({ comment: e.target.value || null }));
    }

    return (
        <div className="edit-issue-status-form-wrapper">
            <Autocomplete
                name="status"
                label="Статус *"
                inpPlaceholder="В какой статус перевести"
                margin="dense"
                options={statusOptions}
                value={formValues?.status || null}
                errors={errors}
                onChange={onChangeStatus}
                isOptionEqualToValue={() => true}
            />
            <TextField
                name="comment"
                placeholder="Комментарий *"
                fullWidth
                onChange={onChangeComment}
                params={{ multiline: true, minRows: 4, margin: 'normal' }}
                errors={errors}
            />
            <FooterForm />
        </div>
    );
}

export default memo(EditIssueStatusForm);
