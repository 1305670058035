import { workersApi } from 'api';
import { Button } from 'components/Button';
import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { getWorkerThunk } from 'store/actions/worker';
import { useAppDispatch } from 'store/hooks';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';
import { workerDetailSelector } from 'store/selectors/workerSelectors';

import { Checkbox, FormControl, FormControlLabel, Paper, styled, TextField } from '@mui/material';
import { IWorkerPaymentMethods } from 'interfaces/workers';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

const initialState: Omit<IWorkerPaymentMethods, 'id'> = {
    first_name: '',
    bank_name: '',
    last_name: '',
    middle_name: '',
    account_number: '',
    bank_bik: '',
    self_employed_inn: '',
    is_ie: false,
};

export const WorkerRequisites = () => {
    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);
    const worker = useSelector(workerDetailSelector);

    const [shouldSave, setShouldSave] = useState(false);

    const [form, setForm] = useState<IWorkerPaymentMethods>(
        worker?.payment_details || (initialState as IWorkerPaymentMethods),
    );

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setShouldSave(true);

        if (e.target.name === 'is_ie') return;
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCheckboxChange = (_: React.SyntheticEvent, checked: boolean) => {
        setShouldSave(true);
        setForm((prev) => ({
            ...prev,
            is_ie: checked,
        }));
    };

    const handleCancel = () => {
        setForm(worker?.payment_details || (initialState as IWorkerPaymentMethods));
        setShouldSave(false);
    };

    const handleSend = () => {
        if (!worker) return;
        if (worker.payment_details) {
            workersApi
                .putPaymentMethods(form, worker.id)
                .then(() => {
                    dispatch(getWorkerThunk(worker.id));
                    enqueueSnackbar('Данные успешно обновлены', { variant: 'success' });
                    setShouldSave(false);
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, { variant: 'error' });
                });
        } else {
            workersApi
                .postPaymentMethods(form, worker.id)
                .then(() => {
                    dispatch(getWorkerThunk(worker.id));
                    enqueueSnackbar('Данные успешно обновлены', { variant: 'success' });
                    setShouldSave(false);
                })
                .catch((e) => {
                    enqueueSnackbar(e.message, { variant: 'error' });
                });
        }
    };

    const getError = () => {
        return (
            !form.first_name.trim() ||
            !form.last_name.trim() ||
            !form.account_number.trim() ||
            !form.bank_bik.trim() ||
            !form.bank_name.trim() ||
            (!form.self_employed_inn.trim() && !form.is_ie)
        );
    };

    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <Paper className="moy-sklad-container">
            <div className="moy-sklad-container-title">Реквизиты</div>
            <FormControlWrapper onChange={handleChange}>
                <TextField
                    disabled={!isOwner && !isCompanyOwner}
                    name="last_name"
                    value={form?.last_name}
                    label="Фамилия"
                    required
                />
                <TextField
                    disabled={!isOwner && !isCompanyOwner}
                    name="first_name"
                    value={form?.first_name}
                    label="Имя"
                    required
                />
                <TextField
                    disabled={!isOwner && !isCompanyOwner}
                    name="middle_name"
                    value={form?.middle_name}
                    label="Отчество (при наличии)"
                />
                <TextField
                    disabled={!isOwner && !isCompanyOwner}
                    name="account_number"
                    value={form?.account_number}
                    label="Номер счета"
                    required
                />
                <TextField
                    disabled={!isOwner && !isCompanyOwner}
                    name="bank_name"
                    value={form?.bank_name}
                    label="Название банка"
                    required
                />
                <TextField
                    disabled={!isOwner && !isCompanyOwner}
                    name="bank_bik"
                    value={form?.bank_bik}
                    label="БИК банка"
                    required
                />
                <TextField
                    disabled={(!isOwner && !isCompanyOwner) || form.is_ie}
                    name="self_employed_inn"
                    value={form?.self_employed_inn}
                    label="ИНН самозанятого (при наличии)"
                />
                <FormControlLabel
                    name="is_ie"
                    onChange={handleCheckboxChange}
                    checked={form?.is_ie}
                    control={<Checkbox disabled={Boolean((!isOwner && !isCompanyOwner) || form.self_employed_inn)} />}
                    label="Является ИП"
                />
            </FormControlWrapper>
            {shouldSave ? (
                <ButtonsWrapper>
                    <Button
                        variant="outlined"
                        onClick={handleCancel}
                    >
                        Отмена
                    </Button>
                    <Button
                        disabled={getError()}
                        variant="contained"
                        onClick={handleSend}
                    >
                        Сохранить
                    </Button>
                </ButtonsWrapper>
            ) : null}
        </Paper>
    );
};

const FormControlWrapper = styled(FormControl)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

const ButtonsWrapper = styled('div')({
    display: 'flex',
    gap: '15px',
    marginTop: '20px',
});
