import { ISubIssue } from '../../interfaces/issues';

export const getCategoriesNameIssue = (sub_issues: ISubIssue[] | null) => {
    const categoriesNameArray: string[] = [];

    // eslint-disable-next-line array-callback-return
    sub_issues?.map((sub_issue) => {
        categoriesNameArray.push(sub_issue.home_device.category?.name || '');
    });

    return categoriesNameArray.filter((str) => !!str).join(', ');
};
