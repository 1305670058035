import { TableCell, TableHead, TableRow } from '@mui/material';
import React, { memo } from 'react';

import { IPricesTableHead } from '../../../../../interfaces/prices';

interface IProps {
    tableHead: IPricesTableHead[] | null;
}

function PricesTableHead({ tableHead }: IProps) {
    return (
        <TableHead>
            <TableRow>
                {!!tableHead &&
                    tableHead.map(({ title, padding }) => {
                        return (
                            <TableCell
                                key={title}
                                padding={padding}
                                sx={{ height: 53 }}
                            >
                                {title}
                            </TableCell>
                        );
                    })}
            </TableRow>
        </TableHead>
    );
}

export default memo(PricesTableHead);
