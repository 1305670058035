import Autocomplete from 'components/inputs/Autocomplete';
import ImgInput from 'components/inputs/ImgInput';
import TextField from 'components/inputs/TextField';
import { EIssueCreatedSource, EIssueHelpType } from 'enums/issues.enum';
import { issueCreatedSourceMock } from 'mockData/issues';
import {
    getClientHomeDevicesThunk,
    getClientsThunk,
    setClientsAction,
    setIsOpenCreateClientAction,
} from 'store/actions/clients';
import { setFormValuesCIAction, setSelectedPricesCIAction } from 'store/actions/create/createIssue';
import { setHomeDevicesAction, setIsOpenCDAction } from 'store/actions/devices';
import { getPartnersThunk } from 'store/actions/partners';
import { getPricesThunk } from 'store/actions/prices';
import { useAppDispatch } from 'store/hooks';
import { clientsOptionsSelector, clientsSelector } from 'store/selectors/clientsSelectors';
import { errorsCreateIssueSelector, formValuesCreateIssueSelector } from 'store/selectors/create/createIssuesSelectors';
import { homeDevicesOptionsSelector, homeDevicesSelector } from 'store/selectors/devicesSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';
import { partnersOptionsSelector } from 'store/selectors/partnersSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';
import { getQueryParamsAsString } from 'utils/helpers/getQueryParamsAsString';

import AddIcon from '@mui/icons-material/Add';
import { Button, FormControl, Grid, InputLabel, Select, SelectChangeEvent, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IOption, IPhotoFile } from 'interfaces';
import { IClient, IClientDetail } from 'interfaces/clients';
import { IHomeDeviceDetail } from 'interfaces/devices';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Additional from './Additional';
import Address from './Address';
import CreateClient from './CreateClient';
import CreateDevice, { IOnCreateParams } from './CreateDevice';
import FooterForm from './FooterForm';
import Prices from './Prices';
import TimeWindows from './TimeWindows';

import './CreateIssueForm.scss';

function CreateIssueForm() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const formValues = useSelector(formValuesCreateIssueSelector);
    const clients = useSelector(clientsSelector);
    const clientsOptions = useSelector(clientsOptionsSelector);
    const homeDevices = useSelector(homeDevicesSelector);
    const homeDevicesOptions = useSelector(homeDevicesOptionsSelector);
    const partnersOptions = useSelector(partnersOptionsSelector);
    const errors = useSelector(errorsCreateIssueSelector);

    const [client, setClient] = useState<IClient | null>(null);
    const [clientInpVal, setClientInpVal] = useState<string | null>(null);
    const [selectedHomeDevice, setSelectedHomeDevice] = useState<IHomeDeviceDetail | null>(null);

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getClientsThunk());
                await dispatch(getPartnersThunk());
                dispatch(setFormValuesCIAction({ help_type: EIssueHelpType.Installation }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (clientInpVal === null) return;
                const queries = getQueryParamsAsString([{ search: clientInpVal }]);
                await dispatch(getClientsThunk(queries));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [clientInpVal]);

    useEffect(() => {
        async function handle() {
            try {
                if (!formValues?.client_id) return;
                if (!clients) return;

                await dispatch(getClientHomeDevicesThunk(formValues.client_id));
                const foundClient = clients.find(({ id }) => id === formValues.client_id);
                foundClient && setClient(foundClient);
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [formValues]);

    useEffect(() => {
        async function handle() {
            try {
                if (!formValues?.help_type) return;
                await dispatch(
                    getPricesThunk({
                        filters: {
                            help_type__in: formValues?.help_type,
                            partner_id: formValues?.partner_id,
                            with_additional: true,
                            with_paginate: false,
                            category_id__in: selectedHomeDevice?.category?.id ? [selectedHomeDevice.category.id] : null,
                        },
                    }),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [formValues?.help_type, formValues?.partner_id, selectedHomeDevice?.category?.id]);

    function openCreateClientHandler() {
        dispatch(setIsOpenCreateClientAction(true));
    }

    function onCreateClient(clientDetail: IClientDetail) {
        if (!clients) return;
        dispatch(setClientsAction([{ ...clientDetail }, ...clients]));
    }

    function changeClientHandler(option: IOption | null) {
        dispatch(setFormValuesCIAction({ client_id: option?.value as string }));
    }

    function searchClient(val: string | null) {
        setClientInpVal(val);
    }

    function openCreateDeviceHandler() {
        dispatch(setIsOpenCDAction(true));
    }

    function onCreateDevice({ newHomeDevice }: IOnCreateParams) {
        if (!homeDevices) return;
        dispatch(setHomeDevicesAction([{ ...newHomeDevice }, ...homeDevices]));
        dispatch(setIsOpenCDAction(false));
    }

    function changeDeviceHandler(option: IOption | null) {
        setSelectedHomeDevice(homeDevices?.find((i) => i.id === option?.value) || null);
        dispatch(setFormValuesCIAction({ home_device_id: option?.value as string }));
    }

    function changeHelpTypeHandler(e: SelectChangeEvent<EIssueHelpType>) {
        dispatch(setFormValuesCIAction({ help_type: e.target.value as EIssueHelpType }));
    }

    function changeDescriptionHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCIAction({ description: e.target.value as EIssueHelpType }));
    }

    function changePhotoFileHandler(files: IPhotoFile[] | null) {
        dispatch(
            setFormValuesCIAction({
                photo_files_ids:
                    files?.map(({ id }) => {
                        return id;
                    }) || null,
            }),
        );
    }

    function changeCreatedSourceHandler(option: IOption | null) {
        dispatch(setFormValuesCIAction({ created_source: (option?.value as EIssueCreatedSource) || null }));
    }

    function changePartnerIdHandler(option: IOption | null) {
        dispatch(setFormValuesCIAction({ partner_id: (option?.value as string) || null }));
        dispatch(setSelectedPricesCIAction(null));
    }

    useEffect(() => {
        if (!issue) return;

        dispatch(setFormValuesCIAction({ client_id: issue.client?.id }));
        dispatch(setFormValuesCIAction({ created_source: issue.created_source || null }));
    }, [issue]);

    return (
        <div className="create-issue-form-wrapper">
            <Autocomplete
                name="client_id"
                margin="dense"
                inpPlaceholder={'Клиент*'}
                topBtn={
                    <Button
                        onClick={openCreateClientHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить нового клиента
                    </Button>
                }
                noOptionsText={
                    <Button
                        onClick={openCreateClientHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить нового клиента
                    </Button>
                }
                onInpChange={searchClient}
                options={clientsOptions}
                onChange={changeClientHandler}
                isOptionEqualToValue={() => true}
                defaultValue={{
                    value: issue?.client?.id || '',
                    label: `${issue?.client?.last_name} ${issue?.client?.first_name} ${issue?.client?.middle_name}`,
                }}
            />
            <Autocomplete
                name="home_device_id"
                margin="dense"
                inpPlaceholder={'Техника*'}
                topBtn={
                    <Button
                        onClick={openCreateDeviceHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить новую технику
                    </Button>
                }
                noOptionsText={
                    <Button
                        onClick={openCreateDeviceHandler}
                        startIcon={<AddIcon />}
                    >
                        Добавить новую технику
                    </Button>
                }
                disabled={!formValues?.client_id}
                options={homeDevicesOptions}
                onChange={changeDeviceHandler}
            />
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Autocomplete
                        name={'created_source'}
                        margin="dense"
                        inpPlaceholder={'Источник'}
                        onChange={changeCreatedSourceHandler}
                        errors={errors}
                        options={Object.keys(issueCreatedSourceMock).map((key) => {
                            return {
                                value: key,
                                label: issueCreatedSourceMock[key as keyof typeof issueCreatedSourceMock],
                            };
                        })}
                        defaultValue={{
                            value: issue?.created_source || '',
                            label: issueCreatedSourceMock[issue?.created_source as keyof typeof issueCreatedSourceMock],
                        }}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Autocomplete
                        name={'partner_id'}
                        margin="dense"
                        inpPlaceholder={'Партнер'}
                        onChange={changePartnerIdHandler}
                        options={partnersOptions || []}
                        tooltipText="При смене партнера все услуги сбрасываются"
                    />
                </Grid>
            </Grid>
            <FormControl
                fullWidth
                margin="dense"
            >
                <InputLabel id="help_type-label">Тип услуги*</InputLabel>
                <Select
                    labelId="help_type-label"
                    name="help_type"
                    margin="dense"
                    label="Тип услуги*"
                    onChange={changeHelpTypeHandler}
                    defaultValue={EIssueHelpType.Installation}
                >
                    <MenuItem value={EIssueHelpType.Repair}>Ремонт</MenuItem>
                    <MenuItem value={EIssueHelpType.Installation}>Установка</MenuItem>
                </Select>
            </FormControl>
            <Prices />
            <TextField
                name="description"
                placeholder="Описание проблемы"
                fullWidth
                onChange={changeDescriptionHandler}
                params={{ multiline: true, minRows: 4, margin: 'normal' }}
            />
            {/*<Autocomplete*/}
            {/*	name={'tag'}*/}
            {/*	inpPlaceholder={'Тег'}*/}
            {/*	options={[]}*/}
            {/*	onChange={() => {*/}
            {/*		//Теги заложены на будущее пока их нет на бэке*/}
            {/*		console.log('test tag');*/}
            {/*	}}*/}
            {/*/>*/}
            <div className="photo-create-issue">
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                >
                    Фото
                </Typography>
                <ImgInput
                    name={'photo_file_ids'}
                    onChange={changePhotoFileHandler}
                />
            </div>
            <TimeWindows />
            <Address />
            <Additional />
            <CreateClient onCreate={onCreateClient} />
            {client && (
                <CreateDevice
                    onCreate={onCreateDevice}
                    defaultClient={client}
                />
            )}
            <FooterForm />
        </div>
    );
}

export default memo(CreateIssueForm);
