import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';

import EditWorkerScheduleForm from './EditWorkerScheduleForm';

import { setIsOpenEditWorkerScheduleAction } from '../../../../store/actions/edit/editWorkerSchedule';
import { useAppDispatch } from '../../../../store/hooks';
import { isOpenEditWorkerScheduleSelector } from '../../../../store/selectors/edit/editWorkerScheduleSelectors';

import './EditWorkerSchedule.scss';

function EditWorkerSchedule() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenEditWorkerScheduleSelector);

    function onClose() {
        dispatch(setIsOpenEditWorkerScheduleAction(false));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <Box className="edit-worker-schedule-dialog-title">
                    <div className="close-icon">
                        <CloseIcon
                            onClick={onClose}
                            sx={{ color: '#CCCCCC' }}
                        />
                    </div>
                    <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                    <div className="title-text">Изменение графика работы</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditWorkerScheduleForm />
            </DialogContent>
        </Dialog>
    );
}

export default EditWorkerSchedule;
