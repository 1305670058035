import { EEditIssueReminderActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    ISetIsOpenEditIssueReminderAction,
    IFormValuesEditIssueReminder,
    ISetErrorsEditIssueReminderAction,
    ISetFormValuesEditIssueReminderAction,
} from '../../../interfaces/edit/editReminder';

export const setIsOpenEditIssueReminderAction = (payload: boolean): ISetIsOpenEditIssueReminderAction => ({
    type: EEditIssueReminderActions.SetIsOpen,
    payload,
});

export const setFormValuesEditIssueReminderAction = (
    payload: IFormValuesEditIssueReminder | null,
): ISetFormValuesEditIssueReminderAction => ({
    type: EEditIssueReminderActions.SetFormValues,
    payload,
});

export const setErrorsEditIssueReminderAction = (payload: TErrors | null): ISetErrorsEditIssueReminderAction => ({
    type: EEditIssueReminderActions.SetErrors,
    payload,
});
