import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore // todo разобраться в чём проблема с типизацией в пакете она есть
import { useDebouncedCallback } from 'use-lodash-debounce';

import FooterForm from './FooterForm';
import Parts from './Parts';

import Autocomplete from '../../../../../../components/inputs/Autocomplete';
import ImgInput from '../../../../../../components/inputs/ImgInput';
import TextField from '../../../../../../components/inputs/TextField';
import { IPhotoFile } from '../../../../../../interfaces';
import { ICategoryOption } from '../../../../../../interfaces/categories';
import { IPartsOption } from '../../../../../../interfaces/parts';
import { setFormValuesCOAction } from '../../../../../../store/actions/create/createOrder';
import { setIsOpenCPAction } from '../../../../../../store/actions/create/createPart';
import { getPartsThunk, setPartFiltersAction } from '../../../../../../store/actions/parts';
import { useAppDispatch } from '../../../../../../store/hooks';
import {
    errorsCOSelector,
    formValuesCOSelector,
    homeDevicesOptionsCOSelector,
    isOpenCOSelector,
} from '../../../../../../store/selectors/create/createOrderSelectors';
import {
    partFiltersSelector,
    partPaginationSelector,
    partsOptionsSelector,
    partsSelector,
} from '../../../../../../store/selectors/partsSelectors';
import defaultErrorCallback from '../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../utils/helpers/getErrorString';
import './CreateOrderForm.scss';

const defaultListSize = 20;

function CreateOrderForm() {
    const dispatch = useAppDispatch();
    const { isEdit } = useSelector(isOpenCOSelector);

    const homeDevicesOptions = useSelector(homeDevicesOptionsCOSelector);
    const parts = useSelector(partsSelector);
    const partsOptions = useSelector(partsOptionsSelector) as IPartsOption[];
    const filters = useSelector(partFiltersSelector);
    const pagination = useSelector(partPaginationSelector);
    const formValues = useSelector(formValuesCOSelector);
    const errors = useSelector(errorsCOSelector);

    async function getPartsByScroll() {
        try {
            if (parts && pagination && pagination?.total > Number(pagination.size * pagination.page)) {
                await dispatch(getPartsThunk({ parts, page: pagination.page + 1, size: defaultListSize, filters }));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function showDialogCreatePart() {
        dispatch(setIsOpenCPAction(true));
    }

    function changeDeviceHandler(option: { value: string; label: string; category_id: string | undefined } | null) {
        dispatch(setFormValuesCOAction({ ...(formValues || {}), sub_issue_id: option?.value as string }));
        dispatch(setPartFiltersAction({ ...(filters || {}), category_id__in: [option?.category_id as string] }));
    }

    function sparePartSelectedHandler(option: ICategoryOption | null) {
        dispatch(setFormValuesCOAction({ ...(formValues || {}), spare_part_id: option?.value || null }));
    }

    function changeQuantityHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCOAction({ ...(formValues || {}), quantity: Number(event.target.value) }));
    }

    function changeIsClientPickUpHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCOAction({ ...(formValues || {}), is_client_pick_up: event.target.checked }));
    }

    async function onSearchHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setPartFiltersAction({ ...(filters || {}), search: event.target.value }));
    }

    const onSearchHandlerDebounced = useDebouncedCallback(onSearchHandler, 800);

    function changePhotoFilePartHandler(files: IPhotoFile[] | null) {
        dispatch(
            setFormValuesCOAction({
                ...(filters || {}),
                device_failure_photo_id:
                    files?.map(({ id }) => {
                        return id;
                    })[0] || null,
            }),
        );
    }

    return (
        <div className="add-part-form-wrapper">
            <Autocomplete
                name="sub_issue_id"
                margin="dense"
                inpPlaceholder={'Техника*'}
                options={homeDevicesOptions}
                value={homeDevicesOptions?.find((homeDevice) => homeDevice.value === formValues?.sub_issue_id) || null}
                errors={errors}
                onChange={changeDeviceHandler}
            />
            <Parts
                partsOptions={partsOptions}
                spare_part_id={formValues?.spare_part_id || null}
                getPartsByScroll={getPartsByScroll}
                partSelectHandler={sparePartSelectedHandler}
                errors={errors}
                getPartsBySearch={onSearchHandlerDebounced}
            />
            <TextField
                name="quantity"
                labelText="Количество *"
                fullWidth
                type="number"
                params={{ margin: 'normal' }}
                errors={errors}
                value={formValues?.quantity || ''}
                onChange={changeQuantityHandler}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        name="is_client_pick_up"
                        onChange={changeIsClientPickUpHandler}
                        checked={formValues?.is_client_pick_up || false}
                        disabled={isEdit}
                    />
                }
                label="Отправка в ПВЗ клиенту"
            />
            <div className="photo_item">
                Поломка
                <ImgInput
                    name={'photo_file_ids'}
                    onChange={changePhotoFilePartHandler}
                    initFileResp={formValues?.device_failure_photo ? [formValues?.device_failure_photo] : []}
                />
            </div>
            <Box
                mt={2}
                display="flex"
                justifyContent="flex-end"
            >
                <Button
                    variant="contained"
                    color="inherit"
                    onClick={showDialogCreatePart}
                >
                    Создать запчасть
                </Button>
            </Box>
            <FooterForm />
        </div>
    );
}

export default CreateOrderForm;
