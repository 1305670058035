import { EIssueFilter } from 'enums/search.enum';
import { searchFiltersMock } from 'mockData';
import { setFiltersSearchAction } from 'store/actions/search';
import { useAppDispatch } from 'store/hooks';
import { filtersSearchSelector } from 'store/selectors/searchSelectors';

import { Box, Typography } from '@mui/material';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import './SearchFiltersStyles.scss';
function SearchFilters() {
    const dispatch = useAppDispatch();
    const searchFilters = useSelector(filtersSearchSelector);

    function setFilterHandle(val: EIssueFilter) {
        dispatch(setFiltersSearchAction({ issue_filters: val }));
    }

    return (
        <Box className="search-filters">
            <Typography
                fontSize={14}
                fontWeight={400}
                color="#ADB5BD"
            >
                Вы можете выбрать параметр, по к-му хотите найти заявку
            </Typography>
            <Box className="filters-list">
                {searchFiltersMock.map(({ value, label }) => {
                    return (
                        <Box
                            key={value}
                            className={cn('filter-item', { active: searchFilters?.issue_filters === value })}
                            onClick={() => {
                                setFilterHandle(value);
                            }}
                        >
                            {label}
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
}

export default SearchFilters;
