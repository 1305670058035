import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ chatsInfinite }: RootState) => chatsInfinite;

export const chatsSelector = createSelector(state, ({ chats }) => chats);
export const chatsIsLoading = createSelector(state, ({ isLoading }) => isLoading);
export const chatsPaginationSelector = createSelector(state, ({ pagination }) => pagination);
export const chatsFiltersSelector = createSelector(state, ({ filters }) => filters);
export const chatsSortOptionsSelector = createSelector(state, ({ sortOptions }) => sortOptions);

export const chatsIsLoadingSelector = createSelector(state, ({ isLoading }) => isLoading);
export const chatsIsNextPageLoadingSelector = createSelector(
    state,
    ({ chats, isLoading }) => (chats && isLoading) || false,
);
export const chatsHasNextPageSelector = createSelector(state, ({ chats, pagination }) => {
    if (chats && pagination) {
        return chats.length < pagination.total;
    }
    return true;
});
