import { ESideBarActions } from '../../enums/actions.enum';
import { ISideBarState, TSideBarActions } from '../../interfaces/sideBar';

const initState: ISideBarState = {
    isOpen: true,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const sideBarReducer = (state = initialState, action: TSideBarActions): ISideBarState => {
    switch (action.type) {
        case ESideBarActions.SetIsOpen: {
            return { ...state, isOpen: action.payload };
        }
        default:
            return state;
    }
};
