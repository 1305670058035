import Loader from 'components/Loader';
import { EChatTypes } from 'enums/chats.enum';
import AddMessageForm from 'modules/Chat2Module/Chat/AddMessageForm';

import { IMessageWithSenderInfo } from 'interfaces/chat';
import { useEffect, useRef } from 'react';

import Messages from './Messages';
import './Chat.scss';

interface IProps {
    messages: IMessageWithSenderInfo[] | null;
    deleteMessage: (id: string) => void;
    sendMessage: (text?: string, files?: File[]) => void;
    isLoadingUploadFiles: boolean;
    markMessageAsViewed: (messageId: string) => void;
    chat_type?: EChatTypes;
}

function Chat({ messages, deleteMessage, sendMessage, isLoadingUploadFiles, markMessageAsViewed, chat_type }: IProps) {
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        function handle() {
            if (!wrapperRef.current) return;
            wrapperRef.current?.scrollTo(0, wrapperRef.current.scrollHeight);
        }
        handle();
    }, [messages]);

    const chatRef = useRef(null);

    return (
        <div className="chat">
            <div
                className="messages-wrapper"
                ref={wrapperRef}
            >
                {!messages && <Loader />}
                {messages?.length ? (
                    <Messages
                        messages={messages}
                        deleteMessage={deleteMessage}
                        markMessageAsViewed={markMessageAsViewed}
                        chat_type={chat_type}
                    />
                ) : (
                    <div className="empty-messages-label">Пока сообщений нет</div>
                )}
            </div>
            <AddMessageForm
                chatRef={chatRef}
                sendMessage={sendMessage}
                isLoadingUploadFiles={isLoadingUploadFiles}
            />
        </div>
    );
}

export default Chat;
