import { Paper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EditWorkerSchedule from './EditWorkerSchedule';

import { EditButton } from '../../../components/EditButton';
import { EDayOfWeekAbbreviatedRu } from '../../../mockData/schedule';
import {
    setFormValuesEditWorkerScheduleAction,
    setIsOpenEditWorkerScheduleAction,
    setWorkerIdEditWorkerScheduleAction,
} from '../../../store/actions/edit/editWorkerSchedule';
import { useAppDispatch } from '../../../store/hooks';
import { workerDetailSelector } from '../../../store/selectors/workerSelectors';
import './WorkerSchedule.scss';

function WorkerSchedule() {
    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);

    function openEditWorkerScheduleModal() {
        if (worker) {
            dispatch(setWorkerIdEditWorkerScheduleAction(worker.id));
            dispatch(setFormValuesEditWorkerScheduleAction(worker?.schedule));
            dispatch(setIsOpenEditWorkerScheduleAction(true));
        }
    }

    return (
        worker && (
            <Paper className="worker-schedule">
                <div className="worker-schedule-title">
                    <div className="title-text">График работы</div>
                    <EditButton onClick={openEditWorkerScheduleModal} />
                </div>
                <div className="days-container">
                    {worker?.schedule.map(({ week_day, time_windows }) => (
                        <div
                            className="day"
                            key={week_day}
                        >
                            <div className="day-name">{EDayOfWeekAbbreviatedRu[week_day]}</div>
                            {time_windows?.length
                                ? time_windows.map(({ start_time, end_time }) => (
                                      <div
                                          className="day-ours"
                                          key={`${start_time}-${end_time}`}
                                      >
                                          {start_time}-{end_time}
                                      </div>
                                  ))
                                : '-'}
                        </div>
                    ))}
                </div>
                <EditWorkerSchedule />
            </Paper>
        )
    );
}

export default WorkerSchedule;
