import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    AuthLayout: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    AuthLayout__Left: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '50%',
        background: '#F2F6FB',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    AuthLayout__Right: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: '50%',
        background: '#FFFFFF',
    },
    AuthLayout__ContainerLogo: {
        position: 'absolute',
        top: 80,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    AuthLayout__ContainerHashtagImage: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: 260,
        height: 250,
        overflow: 'hidden',
    },
    AuthLayout__ContainerChildren: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    AuthLayout__Children: {
        width: 440,
    },
    '@media (max-width: 1024px)': {
        AuthLayout__Left: {
            display: 'none',
        },
        AuthLayout__Right: {
            width: '100%',
        },
    },
});
