import { AppDispatch } from 'store/store';

import { dictsApi } from '../../api';
import { EDictsActions } from '../../enums/actions.enum';
import {
    IAddressDicts,
    IServiceArea,
    ISetAddressesDictsAction,
    ISetServiceAreasDictsAction,
} from '../../interfaces/dicts';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';

export const setAddressesDictsAction = (payload: IAddressDicts[] | null): ISetAddressesDictsAction => ({
    type: EDictsActions.SetAddresses,
    payload,
});

export const setServiceAreasDictsAction = (payload: IServiceArea[] | null): ISetServiceAreasDictsAction => ({
    type: EDictsActions.SetServiceAreas,
    payload,
});

interface IGetAddressesDictsThunkParams {
    address?: string;
}

export const getAddressesDictsThunk =
    ({ address }: IGetAddressesDictsThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data } = await dictsApi.getAddresses(getQueryParamsAsString([{ address }]));
            const { items: addressesDict } = data;
            dispatch(setAddressesDictsAction(addressesDict));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const getServiceAreasDictsThunk = () => async (dispatch: AppDispatch) => {
    try {
        const { data: serviceAreas } = await dictsApi.getServiceAreas();
        dispatch(setServiceAreasDictsAction(serviceAreas));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
