import { styled } from '@mui/material/styles';

const Span = styled('span')({
    display: 'inline-block',
    fontSize: '0.8125rem',
    backgroundColor: '#E1E4FA',
    borderRadius: '20px',
    padding: '0 8px',
    color: '#878EA5',
    height: '24px',
    lineHeight: '24px',
    transform: 'translateY(-2px)',
});

interface IProps {
    unseen_count: number;
}

export default function ChatUnseenCount({ unseen_count }: IProps) {
    return <Span>{unseen_count}</Span>;
}
