/* eslint-disable no-shadow */
export enum EWorkerStatus {
    New = 'new',
    Testing = 'testing',
    Verified = 'verified',
    ConnectedWorkingReady = 'connected_working_ready',
    ConnectedWorkingNotReady = 'connected_working_not_ready',
    Disabled = 'disabled',
    WaitingVerified = 'waiting_verified',
    Deleted = 'deleted',
}

export enum EWorkerVerifyStatus {
    NotVerified = 'not_verified',
    Processed = 'processed',
    Reject = 'reject',
    Blocked = 'blocked',
    Success = 'success',
}

export enum EWorkerStatusColors {
    RoyalBlue = '#3A57E8',
    MountainMeadow = '#1AA053',
    Gold = '#FFD200',
    DaisyBush = '#551AA0',
    PacificBlue = '#08B1BA',
    Manatee = '#8A92A6',
    Red = 'red',
}
