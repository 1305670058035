import { MoreVert } from '@mui/icons-material';
import { Button, IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';

import { IPriceService } from '../../../../../../interfaces/pricesServices';
import { setIsOpenPriceServicesDialogAction } from '../../../../../../store/actions/priceServicesDialog';
import {
    setIsOpenDeletePriceServicesAction,
    setPriceServiceAction,
} from '../../../../../../store/actions/pricesServices';
import { useAppDispatch } from '../../../../../../store/hooks';

interface IProps {
    service: IPriceService;
}

interface IButtonGroup {
    title: string;
    color: 'inherit' | 'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | undefined;
    handle: () => void;
}

function EditServiceButton({ service }: IProps) {
    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const ButtonsGroup: IButtonGroup[] = [
        {
            title: 'Редактировать',
            color: 'inherit',
            handle: openEditPriceServicesModal,
        },
        {
            title: 'Удалить',
            color: 'error',
            handle: deletePriceServices,
        },
    ];

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    async function deletePriceServices() {
        await dispatch(setPriceServiceAction(service));
        await dispatch(setIsOpenDeletePriceServicesAction(true));
    }

    async function openEditPriceServicesModal() {
        await dispatch(setPriceServiceAction(service));
        await dispatch(setIsOpenPriceServicesDialogAction({ isEdit: true, isOpen: true }));
    }

    return (
        <>
            <IconButton
                onClick={handlePopoverClick}
                disableRipple
            >
                <MoreVert />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="options-container">
                    {ButtonsGroup.map((item) => {
                        return (
                            <Button
                                key={item.title}
                                className="option-item"
                                color={item.color}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    item.handle();
                                    handlePopoverClose(event);
                                }}
                            >
                                {item.title}
                            </Button>
                        );
                    })}
                </div>
            </Popover>
        </>
    );
}

export default EditServiceButton;
