import { EOrdersActions } from '../../enums/actions.enum';
import { IOrdersState, TOrdersActions } from '../../interfaces/orders';

const initState: IOrdersState = {
    orders: null,
    order: null,
    isOpenNextStatusOrder: false,
    isOpenCancelOrder: false,
    isOrdersLoading: false,
    selectedOrders: null,
    cancelOrderType: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const ordersReducer = (state = initialState, action: TOrdersActions): IOrdersState => {
    switch (action.type) {
        case EOrdersActions.SetOrders:
            return {
                ...state,
                orders: action.payload,
            };
        case EOrdersActions.SetOrder:
            return {
                ...state,
                order: action.payload,
            };
        case EOrdersActions.SetIsOpenNextStatusOrder:
            return {
                ...state,
                isOpenNextStatusOrder: action.payload,
            };
        case EOrdersActions.SetIsOpenCancelOrder:
            return {
                ...state,
                isOpenCancelOrder: action.payload.isOpen,
                cancelOrderType: action.payload.type,
            };
        case EOrdersActions.SetIsOrdersLoading:
            return {
                ...state,
                isOrdersLoading: action.payload,
            };
        case EOrdersActions.SetSelectedOrders:
            return {
                ...state,
                selectedOrders: action.payload,
            };
        default:
            return state;
    }
};
