import { EEditIssueStatusWithCommentActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IDataEditIssueStatusWithComment,
    IFormValuesEditIssueStatusWithComment,
    ISetDataEditIssueStatusWithComment,
    ISetErrorsEditIssueStatusWithComment,
    ISetFormValuesEditIssueStatusWithComment,
    ISetIsOpenEditIssueStatusWithComment,
    ISetIssueIdEditIssueStatusWithComment,
} from '../../../interfaces/edit/editIssueStatusWithComment';
import { IIssue } from '../../../interfaces/issues';

export const setIsOpenEditIssueStatusWithCommentAction = (payload: boolean): ISetIsOpenEditIssueStatusWithComment => ({
    type: EEditIssueStatusWithCommentActions.SetIsOpen,
    payload,
});

export const setIssueIdEditIssueStatusWithCommentAction = (
    payload: IIssue['id'] | null,
): ISetIssueIdEditIssueStatusWithComment => ({
    type: EEditIssueStatusWithCommentActions.SetIssueId,
    payload,
});

export const setFormValuesEditIssueStatusWithCommentAction = (
    payload: IFormValuesEditIssueStatusWithComment | null,
): ISetFormValuesEditIssueStatusWithComment => ({
    type: EEditIssueStatusWithCommentActions.SetFormValues,
    payload,
});

export const setErrorsEditIssueStatusWithCommentAction = (
    payload: TErrors | null,
): ISetErrorsEditIssueStatusWithComment => ({
    type: EEditIssueStatusWithCommentActions.SetErrors,
    payload,
});

export const setDataEditIssueStatusWithCommentAction = (
    payload: IDataEditIssueStatusWithComment | null,
): ISetDataEditIssueStatusWithComment => ({
    type: EEditIssueStatusWithCommentActions.SetData,
    payload,
});
