import { Button } from '@mui/material';
import { memo } from 'react';
import { useSelector } from 'react-redux';

import { EIssueStatus } from '../../../../../enums/issues.enum';
import { issueChargePostThunk } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import './SendForPayment.scss';

function SendForPayment() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    async function sendIssueCharge() {
        try {
            if (issue) {
                await dispatch(issueChargePostThunk(issue.id));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    const isSendIssueChargeAllowed = issue?.status !== EIssueStatus.WaitingForReceipt;

    return (
        <div className="send-for-payment">
            <div className="info-text">
                {isSendIssueChargeAllowed
                    ? 'При отправке счета на оплату, заявка сменит статус на “Ожидание счета“ и клиенту придет ссылка на оплату'
                    : 'Отправить счет на оплату можно в статусе “Ожидание счета“'}
            </div>
            <Button
                variant="contained"
                disabled={isSendIssueChargeAllowed}
                onClick={() => sendIssueCharge()}
            >
                Отправить на оплату
            </Button>
        </div>
    );
}

export default memo(SendForPayment);
