import { Checkbox, styled, TextField, Typography } from '@mui/material';

export const ButtonsContainer = styled('div')({
    display: 'flex',
    gap: '8px',
    width: '60px',
    cursor: 'pointer',
});

export const ButtonsWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '15px',
    marginTop: '15px',
});

export const MuiTextField = styled(TextField)({
    width: '555px',
});

export const SubTitle = styled(Typography)({
    width: '150px',
    fontSize: '14px',
});

export const CheckTitle = styled(Typography)({
    fontSize: '12px',
    marginLeft: '7px',
});

export const CheckBox = styled(Checkbox)({
    padding: 0,
});

export const CheckBoxWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '555px',
}); // double
