import { moySkladApi } from '../../../api';
import { IFormValuesUnbindWorkerMoySklad } from '../../../interfaces/edit/unbindMoySklad';

export const unbindWorkerMoySkladThunk = (formValues: IFormValuesUnbindWorkerMoySklad) => {
    return async () => {
        try {
            const { data } = await moySkladApi.unbindWorkerStore(formValues);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
