import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TErrors } from '../../../../../../../../interfaces';
import { setErrorsAction, setIsOpenCPAction } from '../../../../../../../../store/actions/create/createPart';
import { createPartsThunk, getPartsThunk } from '../../../../../../../../store/actions/parts';
import { useAppDispatch } from '../../../../../../../../store/hooks';
import {
    errorsCPSelector,
    formValuesCPSelector,
} from '../../../../../../../../store/selectors/create/createPartSelectors';
import { partFiltersSelector } from '../../../../../../../../store/selectors/partsSelectors';
import defaultErrorCallback from '../../../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../../../utils/helpers/getErrorString';

function FooterForm() {
    const dispatch = useAppDispatch();
    const formValues = useSelector(formValuesCPSelector);
    const errors = useSelector(errorsCPSelector);
    const filters = useSelector(partFiltersSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.name) {
            errs.part_name = { message: 'Обязательное поле' };
        }

        if (!formValues?.item_number) {
            errs.part_item_name = { message: 'Обязательное поле' };
        }

        dispatch(setErrorsAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    async function submitFormHandler() {
        if (validator() && formValues) {
            try {
                setIsLoading(true);
                await dispatch(createPartsThunk({ data: formValues }));
                await dispatch(getPartsThunk({ filters }));
                dispatch(setIsOpenCPAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }
    return (
        <Box mt={2}>
            <Button
                onClick={() => dispatch(setIsOpenCPAction(false))}
                variant="outlined"
            >
                Отмена
            </Button>

            <LoadingButton
                onClick={submitFormHandler}
                loading={isLoading}
                variant="contained"
                sx={{ ml: 1 }}
            >
                Создать
            </LoadingButton>
        </Box>
    );
}

export default FooterForm;
