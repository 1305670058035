import { FormControl } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import React, { memo, useEffect, useMemo, useState } from 'react';

import { IOnChangeParam } from './types';

import { TErrors } from '../../../interfaces';

/* если понадобится изменить значение воспользуйся defaultValue - проблема с datePicker */

interface IProps {
    name: string;
    id?: string;
    onChange?: (val: Date | null) => void;
    errors?: TErrors | null;
    placeholder?: string;
    label?: string;
    defaultValue?: string | number | Dayjs | null;
    required?: boolean;
    params?: TextFieldProps;
    fullWidth?: boolean;
    disablePast?: boolean;
}

function DatePicker({
    name,
    id,
    onChange,
    errors,
    placeholder,
    label,
    defaultValue,
    required,
    params,
    fullWidth,
    disablePast,
}: IProps) {
    const [forceRerender, setForceRerender] = useState('');
    const [errorText, setErrorText] = useState<null | React.ReactNode>(null);
    const defaultValueLocal = useMemo(() => {
        if (defaultValue) {
            setForceRerender(Math.random().toFixed(10));
            return defaultValue;
        }
        return undefined;
    }, [defaultValue]);

    function onChangeHandler(val: IOnChangeParam | null) {
        if (val === null) {
            onChange && onChange(null);
            return;
        }

        if (typeof val === 'object') {
            onChange && onChange(val.$d);
        }
    }

    useEffect(() => {
        if (errors && name in errors) {
            if (Array.isArray(errors[name])) {
                const errs: { message: string }[] = errors[name];
                setErrorText(
                    errs.map((err) => {
                        return (
                            <span
                                key={name}
                                className="text__error"
                            >
                                {err?.message}
                            </span>
                        );
                    }),
                );
            } else {
                setErrorText(
                    <span
                        key={name}
                        className="text__error"
                    >
                        {errors[name]?.message}
                    </span>,
                );
            }
        } else {
            setErrorText(null);
        }
    }, [errors]);

    return (
        <FormControl
            error={!!errorText}
            fullWidth={fullWidth}
        >
            <MuiDatePicker
                key={forceRerender}
                onChange={(val) => onChangeHandler(val as unknown as IOnChangeParam)}
                label={label}
                disablePast={disablePast}
                slotProps={{
                    textField: {
                        error: !!errorText,
                        helperText: errorText,
                        defaultValue: defaultValueLocal,
                        name,
                        id,
                        placeholder,
                        required,
                        fullWidth,
                        ...params,
                    },
                }}
            />
        </FormControl>
    );
}

export default memo(DatePicker);
