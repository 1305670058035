import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IFormValuesPriceDialog } from '../interfaces/priceDialog';
import { IPrice, IPricesResp } from '../interfaces/prices';
import { IFormValuesPriceServicesDialog } from '../interfaces/priceServicesDialog';
import { IPriceService } from '../interfaces/pricesServices';

export default class PricesAPI {
    getPrices(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<IPricesResp>;
    }

    getPricesWithOutPagination(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<IPrice[]>;
    }

    getPrice(priceId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/${priceId}`,
            isAuthorization: true,
        }) as AxiosPromise<IPrice>;
    }

    createPrice(price?: IFormValuesPriceDialog) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices`,
            isAuthorization: true,
            data: price,
        }) as AxiosPromise<IPrice>;
    }

    updatePrice(priceId: string, price: IFormValuesPriceDialog) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/${priceId}`,
            isAuthorization: true,
            data: price,
        }) as AxiosPromise<IPrice>;
    }

    deletePrice(priceId: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/${priceId}`,
            isAuthorization: true,
        }) as AxiosPromise<void>;
    }

    getServices() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/services`,
            isAuthorization: true,
        }) as AxiosPromise<IPriceService[]>;
    }

    createServices(formData: IFormValuesPriceServicesDialog) {
        return axiosFetch({
            method: 'POST',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/services`,
            data: formData,
            isAuthorization: true,
        }) as AxiosPromise;
    }

    getService(serviceId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/services/${serviceId}`,
            isAuthorization: true,
        }) as AxiosPromise<IPriceService>;
    }

    updateService(serviceId: string, formData: IFormValuesPriceServicesDialog) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/services/${serviceId}`,
            data: formData,
            isAuthorization: true,
        }) as AxiosPromise<IPriceService>;
    }

    deleteService(serviceId: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/services/${serviceId}`,
            isAuthorization: true,
        }) as AxiosPromise<IPriceService>;
    }
}
