import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { issuesApi } from '../../../../../api';
import TextField from '../../../../../components/inputs/TextField';
import { ICodes } from '../../../../../interfaces/issues';
import { setIssueAction } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

interface IFormValues {
    codes: ICodes;
}
function EditIssueCode() {
    const dispatch = useAppDispatch();
    const issue = useSelector(issueSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<IFormValues | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const { id: issueId, codes } = issue || {};

    useEffect(() => {
        if (!isOpen) return;
        setFormValues({ codes: { issue: codes?.issue || '' } });
    }, [isOpen]);

    async function submitHandler() {
        if (!issueId || !formValues) return;
        try {
            setIsLoading(true);
            const { data } = await issuesApi.putIssue(issueId, formValues);
            dispatch(setIssueAction(data));
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    function changeCodeIssueHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...(formValues || {}), codes: { issue: e.target.value || '' } });
    }

    function handleClose() {
        setIsOpen(false);
    }

    return (
        <div className="val-prop-iss">
            {codes?.issue ? codes?.issue : <pre> </pre>}
            <IconButton
                className="edit-iss-prop-icon"
                onClick={() => setIsOpen(!isOpen)}
            >
                <EditSharpIcon />
            </IconButton>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box className="edit-issue-address-dialog-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменение номера заказа партнера</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        name="code_issue"
                        labelText="Код партнера"
                        fullWidth
                        params={{ margin: 'normal' }}
                        value={formValues?.codes.issue || ''}
                        onChange={changeCodeIssueHandler}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={submitHandler}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EditIssueCode;
