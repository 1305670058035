import { Grid } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../../components/inputs/TextField';
import { setFormValuesPriceDialogAction } from '../../../../../store/actions/priceDialog';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsPriceDialogSelector,
    formValuesPriceDialogSelector,
} from '../../../../../store/selectors/priceDialogSelector';

function Price() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesPriceDialogSelector);
    const errors = useSelector(errorsPriceDialogSelector);

    function onChangeName(e: ChangeEvent<HTMLInputElement>) {
        const name = e.target.value;
        dispatch(setFormValuesPriceDialogAction({ name }));
    }

    function onChangeAmount(e: ChangeEvent<HTMLInputElement>) {
        const amountString = e.target.value;
        dispatch(setFormValuesPriceDialogAction({ amount: amountString ? +amountString : null }));
    }

    return (
        <div>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    xs={8}
                >
                    <TextField
                        fullWidth
                        name={'price_name'}
                        labelText={'Название услуги *'}
                        params={{ margin: 'dense' }}
                        errors={errors}
                        onChange={onChangeName}
                        value={formValues?.name || ''}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <TextField
                        fullWidth
                        name={'amount'}
                        labelText={'Цена *'}
                        type={'number'}
                        params={{ margin: 'dense' }}
                        errors={errors}
                        onChange={onChangeAmount}
                        value={formValues?.amount || ''}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default Price;
