import { companiesApi, priceListsApi, servicesApi } from 'api';
import Loader from 'components/Loader';
import { priceListsSelector } from 'pages/price-lists/store/selectors';

import { MoreVert } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, Checkbox, Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { BasicsWrapper, ButtonItem, IconWrapper } from './styles';

import { Field, FieldsWrapper, MuiDivider, MuiTextField, SubTitle } from '../styles';

type Option = { label: string; value: string };

export const PriceListMainPage = () => {
    const [isOpen, setOpen] = useState<HTMLDivElement | null>(null);

    const { priceList } = useSelector(priceListsSelector);

    const [companies, setCompanies] = useState<Option[]>([]);
    const [serviceTypes, setServiceTypes] = useState<Option[]>([]);
    const [regions, setRegions] = useState<Option[]>([]);

    const fetchAutocompleteData = async () => {
        const [companiesRes, serviceTypesRes, regionsRes] = await Promise.all([
            companiesApi.getCompanies(),
            servicesApi.getServiceTypes(),
            priceListsApi.getRegionality({}),
        ]);

        const mapper = (item: { name: string; id: string }) => ({ label: item.name, value: item.id });

        setCompanies(companiesRes.data.map(mapper));
        setServiceTypes(serviceTypesRes.data.map(mapper));
        setRegions(regionsRes.data.map(mapper));
    };

    useEffect(() => {
        fetchAutocompleteData();
    }, []);

    const handleChange = (data: any) => {
        if (!priceList) return;

        priceListsApi.editPriceList(priceList?.id, data);
    };

    const handleDelete = () => {
        priceListsApi.deletePriceList(priceList?.id as string);
    };

    if (!priceList) return <Loader />;

    return (
        <BasicsWrapper>
            <div>
                <MuiDivider sx={{ width: '600px' }} />
                <IconWrapper onClick={(e) => setOpen(e.currentTarget)}>
                    <MoreVert sx={{ color: '#0040F1' }} />
                </IconWrapper>
                <Popover
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setOpen(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0' }}>
                        <ButtonItem color="inherit">Копировать прайс-лист</ButtonItem>
                        <ButtonItem color="inherit">Удалить прайс-лист</ButtonItem>
                        <ButtonItem color="inherit">Скачать PDF</ButtonItem>
                    </div>
                </Popover>
                <FieldsWrapper>
                    <Field>
                        <SubTitle>Партнер</SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={companies}
                            defaultValue={{ label: priceList?.company?.name || '', value: priceList?.company_id }}
                            onChange={(_, option) => {
                                const { value } = option as Option;

                                handleChange({ company_id: priceList?.company_id });
                                console.log(value);
                            }}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    placeholder="Выбрать"
                                    size="small"
                                />
                            )}
                            disabled
                        />
                    </Field>
                    <Field>
                        <SubTitle>Тип услуги</SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={serviceTypes}
                            defaultValue={{
                                label: priceList?.service_type?.name || '',
                                value: priceList?.service_type.id,
                            }}
                            onChange={(_, option) => {
                                const { value } = option as Option;

                                console.log(value);
                            }}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    defaultValue={priceList?.service_type?.name}
                                    placeholder="Выбрать"
                                    size="small"
                                />
                            )}
                            disabled
                        />
                    </Field>
                    <Field>
                        <SubTitle>Регион</SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={regions}
                            defaultValue={{
                                label: priceList?.region?.name || '',
                                value: priceList?.region.id,
                            }}
                            onChange={(_, option) => {
                                const { value } = option as Option;

                                console.log(value);
                            }}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    defaultValue={priceList?.region?.name}
                                    placeholder="Выбрать"
                                    size="small"
                                />
                            )}
                            disabled
                        />
                    </Field>
                    <Field>
                        <SubTitle>Системное наименование</SubTitle>
                        <MuiTextField
                            onBlur={(e) => handleChange({ system_name: e.target.value })}
                            defaultValue={priceList?.system_name}
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>Наименование</SubTitle>
                        <MuiTextField
                            onBlur={(e) => handleChange({ name: e.target.value })}
                            defaultValue={priceList?.name}
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>Активность</SubTitle>
                        <Checkbox
                            onClick={() => handleChange({ is_active: !priceList?.is_active })}
                            defaultChecked={priceList?.is_active}
                            sx={{ padding: 0 }}
                        />
                    </Field>
                    <Field>
                        <SubTitle>Дата создания</SubTitle>
                        <MuiTextField
                            defaultValue={priceList?.created_at.split('T')[0]}
                            size="small"
                            disabled
                        />
                    </Field>
                </FieldsWrapper>
            </div>

            <div style={{ display: 'flex', gap: '15px' }}>
                <Button variant="contained">Копировать</Button>
                <Button
                    onClick={handleDelete}
                    color="error"
                >
                    Удалить
                </Button>
            </div>
        </BasicsWrapper>
    );
};
