import { Autocomplete, Box, FormControl } from '@mui/material';
import React, { memo } from 'react';

import TextField from '../../../../../../../components/inputs/TextField';
import { TErrors } from '../../../../../../../interfaces';
import { IPartsOption } from '../../../../../../../interfaces/parts';

interface IProps {
    partsOptions: IPartsOption[] | null;
    spare_part_id: string | null;
    errors: TErrors | null;
    getPartsByScroll: () => void;
    getPartsBySearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    partSelectHandler: (option: IPartsOption | null) => void;
}

function Parts({ partsOptions, spare_part_id, errors, getPartsByScroll, getPartsBySearch, partSelectHandler }: IProps) {
    return (
        <Box mt={1}>
            <FormControl fullWidth>
                <Autocomplete
                    clearOnBlur={false}
                    renderInput={(params) => {
                        return (
                            <TextField
                                name={'spare_part_id'}
                                errors={errors}
                                placeholder="Запчасти*"
                                params={params}
                                onChange={getPartsBySearch}
                            />
                        );
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li
                                {...props}
                                key={option.value}
                            >
                                <div>
                                    <span>#</span>
                                    {option.item_number}
                                    <div style={{ fontSize: '14px' }}> {option.label} </div>
                                    {!!option?.categories?.length && (
                                        <div style={{ color: '#8c95a8', fontSize: '12px' }}>
                                            {option?.categories?.map((category) => category.name).join(', ')}
                                        </div>
                                    )}
                                </div>
                            </li>
                        );
                    }}
                    onChange={(_, option) => {
                        partSelectHandler(option);
                    }}
                    noOptionsText={'Нет запчастей..'}
                    loadingText={'Загрузка...'}
                    filterOptions={(options) => options}
                    loading={partsOptions === null}
                    options={partsOptions || []}
                    value={partsOptions?.find((part) => part.value === spare_part_id) || null}
                    ListboxProps={{
                        onScroll: (event) => {
                            const listEl = event.currentTarget;
                            if (listEl.scrollTop + listEl.clientHeight + 0.5 >= listEl.scrollHeight) {
                                getPartsByScroll();
                            }
                        },
                        sx: {
                            '& .MuiAutocomplete-option[aria-selected="true"]': {
                                backgroundColor: '#1976d214',
                            },
                        },
                    }}
                />
            </FormControl>
        </Box>
    );
}

export default memo(Parts);
