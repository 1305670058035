import { useState, useEffect } from 'react';

export type TUseAudioReturn = [boolean, () => void, string, number];

export const useAudio = (url: string): TUseAudioReturn => {
    const [audio] = useState<HTMLAudioElement>(new Audio(url));
    const [playing, setPlaying] = useState<boolean>(false);
    const [duration, setDuration] = useState<string>('00:00');
    const [timer, setTimer] = useState<number>(0);

    const toggle = () => {
        setPlaying(!playing);
    };

    useEffect(() => {
        playing ? audio.play() : audio.pause();
    }, [playing]);

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        audio.addEventListener('loadedmetadata', () => {
            if (audio.readyState > 0) {
                const minutes = `0${parseInt(String(audio.duration / 60), 10)}`;
                const seconds = `0${parseInt(String(audio.duration % 60), 10)}`;
                setDuration(`${minutes}:${seconds.slice(-2)}`);
            }
        });
        const timerAudio = setInterval(() => {
            setTimer(Math.ceil((audio.currentTime * 100) / audio.duration));
            const remainingTime = audio.duration - audio.currentTime;
            let minutes: number = 0;
            let seconds: number = 0;

            if (remainingTime) {
                minutes = Math.floor(remainingTime / 60);
                seconds = Math.floor(remainingTime % 60);
            } else {
                minutes = Math.floor(audio.duration / 60);
                seconds = Math.floor(audio.duration % 60);
            }

            setDuration(`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
        }, 1100);
        return () => {
            clearInterval(timerAudio);
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return [playing, toggle, duration, timer];
};
