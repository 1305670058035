import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ worker }: RootState) => worker;

export const workerDetailSelector = createSelector(state, ({ workerDetail }) => workerDetail);
export const workerSolvedIssueHistorySelector = createSelector(state, ({ solvedIssueHistory }) => solvedIssueHistory);
export const workerCanceledIssueHistorySelector = createSelector(
    state,
    ({ canceledIssueHistory }) => canceledIssueHistory,
);
export const workerSelectedTabSelector = createSelector(state, ({ selectedTab }) => selectedTab);
export const workerSelectedSpecializationsSelector = createSelector(
    state,
    ({ workerSelectedSpecializations }) => workerSelectedSpecializations,
);
