import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CancelOrder from './CancelOrder';
import CreateOrder from './CreateOrder';
import CreatePart from './CreateOrder/CreatePart';
import NextStatusOrder from './NextStatusOrder';
import Orders from './Orders';

import Accordion from '../../../../components/Accordion';
import { issueCreateOrderAllowed } from '../../../../mockData/issues';
import { partsOrderAllowed } from '../../../../mockData/partsOrders';
import { setIsOpenCOAction } from '../../../../store/actions/create/createOrder';
import { getOrdersThunk, setIsOrdersLoadingAction } from '../../../../store/actions/orders';
import { useAppDispatch } from '../../../../store/hooks';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import {
    isLoadingOrderSelector,
    ordersSelector,
    selectedOrdersSelector,
    sortOrdersBySubIssueSelector,
} from '../../../../store/selectors/ordersSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import { getTitleStatusesAllowedCreateOrder } from '../../../../utils/helpers/getTitleStatusesAllowedCreateOrder';
import './Parts.scss';

function PartsOrders() {
    const dispatch = useAppDispatch();
    const issue = useSelector(issueSelector);
    const partsOrders = useSelector(ordersSelector);
    const sortOrdersBySubIssue = useSelector(sortOrdersBySubIssueSelector);
    const isLoading = useSelector(isLoadingOrderSelector);
    const selectedOrders = useSelector(selectedOrdersSelector);

    const { id: issueId, help_type } = issue || {};

    useEffect(() => {
        async function handle() {
            try {
                if (!issueId) return;
                dispatch(setIsOrdersLoadingAction(true));

                await dispatch(getOrdersThunk({ issue_id: issueId }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                dispatch(setIsOrdersLoadingAction(false));
            }
        }
        handle();
    }, [issueId]);

    function showDialogCreateOrder() {
        dispatch(setIsOpenCOAction({ isEdit: false, isOpen: true }));
    }

    return help_type && partsOrderAllowed.includes(help_type) ? (
        <Accordion
            title={<Typography>Запчасти</Typography>}
            sx={{ mt: 3 }}
            expanded={false}
        >
            <CreateOrder />
            <CreatePart />
            <NextStatusOrder />
            <CancelOrder />
            <Orders
                partsOrders={partsOrders}
                sortOrdersBySubIssue={sortOrdersBySubIssue || null}
                isLoading={isLoading}
                selectedOrders={selectedOrders}
            />
            {issueCreateOrderAllowed.includes(issue?.status!) ? (
                <div className="order-btn">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={showDialogCreateOrder}
                    >
                        Добавить заказ
                    </Button>
                </div>
            ) : (
                <div>Заказы могу быть добавлены только в статусах: {getTitleStatusesAllowedCreateOrder()}</div>
            )}
        </Accordion>
    ) : null;
}

export default PartsOrders;
