import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import {
    IBindWorkerMoySklad,
    IFormValuesCreateWorkerMoySklad,
    IFormValuesGetWorkerAssortment,
    ISearchWorkerMoySklad,
    IWorkerMoySklad,
    IWorkerMoySkladAssortment,
    IWorkerMoySkladSearchResponse,
    IWorkerMoySkladStoreBindResponse,
} from '../interfaces/create/createMoySklad';
import { IFormValuesUnbindWorkerMoySklad, IWorkerMoySkladUnbind } from '../interfaces/edit/unbindMoySklad';

export default class MoySkladAPI {
    addWorkerStore(data: IFormValuesCreateWorkerMoySklad) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/moysklad/store`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerMoySklad>;
    }

    bindWorkerStore(data: IBindWorkerMoySklad) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/moysklad/store/bind`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerMoySkladStoreBindResponse>;
    }

    unbindWorkerStore(data: IFormValuesUnbindWorkerMoySklad) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/moysklad/store/unbind`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerMoySkladUnbind>;
    }

    getWorkerStoreAssortment(data: IFormValuesGetWorkerAssortment) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/moysklad/assortment/search`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerMoySkladAssortment[]>;
    }

    searchWorkerStore(data: ISearchWorkerMoySklad) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/moysklad/store/search`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IWorkerMoySkladSearchResponse[]>;
    }
}
