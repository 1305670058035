import { MoreVert } from '@mui/icons-material';
import { Button, IconButton, Popover, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { EPartsOrdersStatus } from '../../../../../../../enums/partsOrders.enum';
import { EPermissionsKeys, EPermissionsValues } from '../../../../../../../enums/permissions.enum';
import { TWorkerIssue } from '../../../../../../../interfaces/issues';
import { IOrder } from '../../../../../../../interfaces/orders';
import { partsOrderEditAllowed } from '../../../../../../../mockData/partsOrders';
import { setIsOpenCOAction } from '../../../../../../../store/actions/create/createOrder';
import {
    setIsOpenCancelOrderAction,
    setIsOpenNextStatusOrderAction,
    setOrderAction,
} from '../../../../../../../store/actions/orders';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { profileSelector } from '../../../../../../../store/selectors/profileSelectors';
import { getNextStatusTitle } from '../../../../../../../utils/helpers/getNextStatusTitle';
import { isAccessDenied } from '../../../../../../../utils/helpers/isAccesDenite';

interface IProps {
    order: IOrder;
    worker: TWorkerIssue | null;
    isDisabled: boolean;
}

interface IButtonGroup {
    title: string;
    color: 'inherit' | 'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | undefined;
    handle: () => void;
    disabled?: boolean;
    tooltipTitle?: string;
}

function EditOrderButton({ order, worker, isDisabled }: IProps) {
    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    function getButtonGroupItems(): IButtonGroup[] {
        const buttonGroupItems: IButtonGroup[] = [];

        if (getNextStatusTitle(order)) {
            buttonGroupItems.push({
                title: `Перевод в "${getNextStatusTitle(order)}"`,
                color: 'inherit',
                disabled: false,
                handle: nextStatusOrder,
            });
        }

        if (!worker && order.status === EPartsOrdersStatus.InStock) {
            buttonGroupItems[0].disabled = true;
            buttonGroupItems[0].tooltipTitle = 'Необходимо назначить мастера';
        }

        if (
            isAccessDenied({ profile, entityRole: EPermissionsKeys.SpareParts, action: EPermissionsValues.Update }) ||
            partsOrderEditAllowed.includes(order.status)
        ) {
            buttonGroupItems.push({
                title: 'Редактировать заказ',
                color: 'inherit',
                handle: editOrder,
            });
        }

        buttonGroupItems.push({
            title: 'Отказ от запчасти',
            color: 'error',
            disabled: false,
            handle: deleteOrder,
        });

        if (order.moysklad_loss) {
            buttonGroupItems.push({
                title: 'Удалить списание',
                color: 'error',
                disabled: false,
                handle: deleteOrderLoss,
            });
        }

        if (order.moysklad_move) {
            buttonGroupItems.push({
                title: 'Удалить перемещение',
                color: 'error',
                disabled: false,
                handle: deleteOrderMove,
            });
        }

        return buttonGroupItems;
    }

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    function nextStatusOrder() {
        dispatch(setOrderAction(order));
        dispatch(setIsOpenNextStatusOrderAction(true));
    }

    function deleteOrder() {
        dispatch(setOrderAction(order));
        dispatch(setIsOpenCancelOrderAction({ isOpen: true, type: null }));
    }

    function deleteOrderMove() {
        dispatch(setOrderAction(order));
        dispatch(setIsOpenCancelOrderAction({ isOpen: true, type: 'move' }));
    }

    function deleteOrderLoss() {
        dispatch(setOrderAction(order));
        dispatch(setIsOpenCancelOrderAction({ isOpen: true, type: 'loss' }));
    }

    async function editOrder() {
        dispatch(setOrderAction(order));
        dispatch(setIsOpenCOAction({ isEdit: true, isOpen: true }));
    }

    return (
        <>
            <IconButton
                onClick={handlePopoverClick}
                disableRipple
                disabled={isDisabled}
            >
                <MoreVert />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="options-container">
                    {getButtonGroupItems().map((item) => (
                        <Tooltip
                            key={item.title}
                            title={item.tooltipTitle}
                            placement="top"
                            followCursor
                        >
                            <div>
                                <Button
                                    sx={{ width: '100%' }}
                                    className="option-item"
                                    color={item.color}
                                    disabled={item.disabled}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        item.handle();
                                        handlePopoverClose(event);
                                    }}
                                >
                                    {item.title}
                                </Button>
                            </div>
                        </Tooltip>
                    ))}
                </div>
            </Popover>
        </>
    );
}

export default EditOrderButton;
