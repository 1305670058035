import CloseIcon from '@mui/icons-material/Close';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../../components/inputs/TextField';
import { TErrors } from '../../../../../interfaces';
import { ICreateInvoicingNotificationsReq } from '../../../../../interfaces/invoicingNotifications';
import { setSmsIsOpenINAction } from '../../../../../store/actions/invoicingNotifications';
import { useAppDispatch } from '../../../../../store/hooks';
import { dataINSelector, smsIsOpenINSelector } from '../../../../../store/selectors/invoicingNotificationsSelectors';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';

interface IProps {
    sendHandler: (formValues: ICreateInvoicingNotificationsReq) => void;
}

function SmsModal({ sendHandler }: IProps) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const dataIN = useSelector(dataINSelector);
    const isOpen = useSelector(smsIsOpenINSelector);

    const [formValues, setFormValues] = useState<Partial<ICreateInvoicingNotificationsReq> | null>(null);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);

    const defaultValues = useMemo<Partial<ICreateInvoicingNotificationsReq>>(() => {
        return {
            target: issue?.client?.phone_number,
            message_text: dataIN?.message_text,
            confirmation_url: dataIN?.confirmation_url,
        };
    }, [issue?.client?.phone_number, dataIN?.message_text, dataIN?.confirmation_url, isOpen]);

    const validator = useCallback(() => {
        const errs = {} as TErrors;
        if (!formValues?.target) {
            errs.target = { message: 'Обязательное поле' };
        }
        if (formValues?.target && formValues.target.length < 11) {
            errs.target = { message: 'Не менее 11 символов' };
        }
        setErrors(errs);
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        setFormValues(null);
        setErrors(null);
        setIsBtnDisabled(false);
    }, [isOpen]);

    useEffect(() => {
        if (!defaultValues) return;
        setFormValues(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    useEffect(() => {
        if (errors && Object.keys(errors).length) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [errors]);

    function onCloseHandler() {
        dispatch(setSmsIsOpenINAction(false));
    }

    function changeTarget(event: SelectChangeEvent<string>) {
        setFormValues(
            formValues ? { ...formValues, target: event.target.value || '' } : { target: event.target.value || '' },
        );
    }

    function onSend() {
        if (!formValues) return;
        if (validator()) {
            const newFormValues = { ...formValues, target: formValues.target?.replace('+', '') };
            sendHandler(newFormValues as NonNullable<ICreateInvoicingNotificationsReq>);
        }
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex' }}>
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NotificationsNoneIcon
                        color="primary"
                        sx={{ marginRight: 3 }}
                        fontSize="large"
                    />
                    <Typography variant="h6">Отправка уведомления по СМС</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="subtitle">Вы можете изменить номер телефона, либо использовать текущий</div>
                <FormControl
                    fullWidth
                    margin="dense"
                >
                    <InputLabel id="help_type-label">Телефон *</InputLabel>
                    <Select
                        labelId="help_type-label"
                        margin="dense"
                        label="Телефон *"
                        defaultValue={`+${defaultValues.target}`}
                        name="target"
                        fullWidth
                        onChange={changeTarget}
                    >
                        <MenuItem value={`+${defaultValues.target}`}>+{defaultValues.target}</MenuItem>
                        {issue?.client?.phones?.map(({ phone_number }, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <MenuItem
                                key={index}
                                value={`+${phone_number}`}
                            >
                                +{phone_number}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    errors={errors}
                    defaultValue={defaultValues.message_text}
                    labelText="Текст сообщения *"
                    name="message_text"
                    disabled
                    params={{ margin: 'normal' }}
                />
                <TextField
                    fullWidth
                    className="comment-field"
                    errors={errors}
                    defaultValue={defaultValues.confirmation_url}
                    labelText="Ссылка на оплату *"
                    name="confirmation_url"
                    disabled
                    params={{ margin: 'normal' }}
                />
            </DialogContent>
            <DialogActions className="buttons">
                <Button
                    onClick={onCloseHandler}
                    variant="outlined"
                >
                    Отмена
                </Button>
                <LoadingButton
                    disabled={isBtnDisabled}
                    variant="contained"
                    onClick={onSend}
                >
                    Отправить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default memo(SmsModal);
