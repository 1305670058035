import SearchIcon from '@mui/icons-material/Search';
import { Divider, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
// @ts-ignore
import { useDebouncedCallback } from 'use-lodash-debounce';

interface IProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
}

function Search({ onChange, placeholder }: IProps) {
    const changeInpHandleDebounced = useDebouncedCallback(onChange, 800);
    return (
        <TextField
            name="search"
            placeholder={placeholder}
            fullWidth
            onChange={changeInpHandleDebounced}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                        <Divider
                            sx={{ height: 28 }}
                            orientation="vertical"
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default Search;
