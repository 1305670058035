import { EToastActions } from '../../enums/actions.enum';
import { ICancelToastAction, ISetToastAction, IToastPayload } from '../../interfaces/toast';

export const setToastAction = (payload: IToastPayload): ISetToastAction => ({
    type: EToastActions.SetToast,
    payload,
});

export const cancelToastAction = (): ICancelToastAction => ({
    type: EToastActions.SetCancelToast,
});
