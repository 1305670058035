import CloseIcon from '@mui/icons-material/Close';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Filters from './Filters';
import Workers from './Workers';

import Loader from '../../components/Loader';
import { EWorkerStatus } from '../../enums/workers.enum';
import { ICandidatesModalState } from '../../interfaces/candidatesModal';
import { ICandidateIssue, TWorkerIssue } from '../../interfaces/issues';
import { setFiltersCMAction, setIsOpenCMAction, setSelectedWorkersCMAction } from '../../store/actions/candidatesModal';
import { setCategoriesRatiosFilterAction, setSelectedCRFAction } from '../../store/actions/categoriesRatiosFilter';
import { getWorkersThunk } from '../../store/actions/workers';
import { useAppDispatch } from '../../store/hooks';
import {
    filtersCMSelector,
    isLoadingCMSelector,
    isOpenCMSelector,
    selectedWorkersCMSelector,
} from '../../store/selectors/candidatesModalSelectors';
import { categoriesCRFSelector } from '../../store/selectors/categoriesRatiosFilterSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getCategoriesRFWithChecked } from '../../utils/helpers/getCategoriesRFWithChecked';
import { getErrorString } from '../../utils/helpers/getErrorString';

interface IProps {
    addCandidatesCallback: (selectedWorkers: NonNullable<ICandidatesModalState['selectedWorkers']>) => void;
    defaultCandidates?: ICandidateIssue[] | null;
}

function CandidatesModal({ addCandidatesCallback, defaultCandidates }: IProps) {
    const dispatch = useAppDispatch();

    const selectedWorkers = useSelector(selectedWorkersCMSelector);
    const filters = useSelector(filtersCMSelector);
    const isOpen = useSelector(isOpenCMSelector);
    const isLoading = useSelector(isLoadingCMSelector);
    const categories = useSelector(categoriesCRFSelector);

    const [isBtnLoading, setIsBtnLoading] = useState(false);

    useEffect(() => {
        if (isOpen) {
            if (defaultCandidates?.length) {
                const defaultWorkers = defaultCandidates.map(({ worker }) => worker).filter((worker) => worker);
                dispatch(setSelectedWorkersCMAction(defaultWorkers as TWorkerIssue[]));
            }
        } else {
            dispatch(setSelectedCRFAction(null));
            dispatch(setFiltersCMAction(null));
            dispatch(setSelectedWorkersCMAction(null));
        }
    }, [isOpen]);

    useEffect(() => {
        async function handle() {
            try {
                if (!isOpen) return;
                setIsBtnLoading(true);
                const defaultFilter = {
                    status__in: [EWorkerStatus.ConnectedWorkingNotReady, EWorkerStatus.ConnectedWorkingReady],
                };
                await dispatch(getWorkersThunk({ filters: { ...filters, ...defaultFilter }, size: 70 }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsBtnLoading(false);
            }
        }
        handle();
    }, [filters]);

    useEffect(() => {
        if (
            !filters?.area_id__in &&
            !filters?.specialization__category_id__in &&
            !filters?.specialization__help_type__in
        )
            return;
        dispatch(setSelectedWorkersCMAction(null));
    }, [filters?.area_id__in, filters?.specialization__category_id__in, filters?.specialization__help_type__in]);

    function handleClose() {
        dispatch(setIsOpenCMAction(false));
        dispatch(setSelectedCRFAction([]));
        dispatch(setFiltersCMAction(null));
        categories && dispatch(setCategoriesRatiosFilterAction(getCategoriesRFWithChecked(categories)));
    }

    function addCandidatesHandler() {
        selectedWorkers && addCandidatesCallback(selectedWorkers);
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="lg"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={handleClose}
        >
            <DialogTitle>
                <Box className="modal-description-title">
                    <div className="close-icon">
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <PeopleAltOutlinedIcon className="title-icon" />{' '}
                    <div className="title-text">Назначение мастера</div>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ minHeight: 600, position: 'relative' }}>
                {isLoading && <Loader />}
                <Filters />
                <Workers />
            </DialogContent>
            <DialogActions>
                {!!selectedWorkers?.length && (
                    <Box sx={{ typography: 'caption', mr: 2 }}>Выбрано: {selectedWorkers.length} </Box>
                )}
                <Button onClick={handleClose}>Отменить</Button>
                <LoadingButton
                    loading={isBtnLoading}
                    disabled={isBtnLoading}
                    onClick={addCandidatesHandler}
                    variant="contained"
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default memo(CandidatesModal);
