/* eslint-disable no-shadow */
export enum EMapTabs {
    Areas = 'areas',
    Issues = 'issues',
    Workers = 'workers',
}

export enum EIssueStatusMap {
    Done = 'done',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
}

export enum EPredictAssignmentErrors {
    BySchedule = 'by_schedule',
    BySpecializations = 'by_specializations',
    ByService_areas = 'by_service_areas',
    ByJoin = 'by_join',
    ByOverloaded = 'by_overloaded',
}
