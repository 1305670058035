import { Checkbox, InputBaseComponentProps, styled } from '@mui/material';

export const CellInputProps: InputBaseComponentProps = {
    style: {
        textAlign: 'center',
        padding: '8px',
        width: '100px',
        fontWeight: '400',
        fontSize: '14px',
    },
};

export const ButtonsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '5px',
    cursor: 'pointer',
});

export const CheckBox = styled(Checkbox)({
    padding: 0,
});

export const CheckItem = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
});
