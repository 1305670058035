import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import FilterDate from '../../../../components/FilterDate';
import { ICategoryRatiosFilter } from '../../../../interfaces/categoriesRatiosFilter';
import { dateCallFiltersMock, dateOfCreatingFiltersMock } from '../../../../mockData/issues';
import RatiosCategoriesFilter from '../../../../modules/CategoriesRatiosFilter';
import { setArchiveIssuesFiltersAction } from '../../../../store/actions/archive';
import { useAppDispatch } from '../../../../store/hooks';
import { archiveIssuesFiltersSelector } from '../../../../store/selectors/archiveSelectors';

function ArchiveIssuesFilters() {
    const dispatch = useAppDispatch();

    const filters = useSelector(archiveIssuesFiltersSelector);

    function changeDateOfCreatingFilter(data: { startTime: string | null; endTime: string | null }) {
        dispatch(
            setArchiveIssuesFiltersAction({
                ...(filters || {}),
                created_at__gte: data.startTime,
                created_at__lte: data.endTime,
            }),
        );
    }

    function changeDateCallFilter(data: { startTime: string | null; endTime: string | null }) {
        dispatch(
            setArchiveIssuesFiltersAction({
                ...(filters || {}),
                time_window__gte: data.startTime,
                time_window__lte: data.endTime,
            }),
        );
    }

    function onChangeRatiosHandler(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        dispatch(setArchiveIssuesFiltersAction({ ...(filters || {}), ratio_category_id__in: categoriesIds }));
    }

    return (
        <div className="archive-issue-filters-wrapper">
            <Box
                display="flex"
                columnGap={2}
                width="100%"
            >
                <RatiosCategoriesFilter onChange={onChangeRatiosHandler} />
                <FilterDate
                    title="Дата создания"
                    filterItems={dateOfCreatingFiltersMock}
                    onChange={changeDateOfCreatingFilter}
                />
                <FilterDate
                    title="Дата вызова"
                    filterItems={dateCallFiltersMock}
                    onChange={changeDateCallFilter}
                />
            </Box>
        </div>
    );
}

export default ArchiveIssuesFilters;
