import { EEditIssueStatusToMasterSearchActions } from '../../../enums/actions.enum';
import {
    IEditIssueStatusToMasterSearchState,
    TEditIssueStatusToMasterSearchActions,
} from '../../../interfaces/edit/editIssueStatusToMasterSearch';

const initState: IEditIssueStatusToMasterSearchState = {
    isOpen: false,
};

const initialState = { ...initState };

export const editIssueStatusToMasterSearchReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TEditIssueStatusToMasterSearchActions,
): IEditIssueStatusToMasterSearchState => {
    switch (action.type) {
        case EEditIssueStatusToMasterSearchActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        default:
            return state;
    }
};
