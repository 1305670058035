import { createSelector } from 'reselect';

import { getFullName } from '../../utils/helpers/getFullName';
import { RootState } from '../rootReducer';

const state = ({ clients }: RootState) => clients;

export const clientSelector = createSelector(state, ({ client }) => client);
export const clientsSelector = createSelector(state, ({ clients }) => clients);
export const formValuesCCSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsCCSelector = createSelector(state, ({ errors }) => errors);
export const clientsOptionsSelector = createSelector(state, ({ clients }) => {
    return (
        clients?.map((client) => {
            const { id } = client;
            //TODO поставить задачу для бэка имён нет
            return { value: id, label: getFullName(client) || '* Пустое имя *' };
        }) || null
    );
});
export const isOpenCreateClientSelector = createSelector(state, ({ isOpenCreateClient }) => isOpenCreateClient);
