const correctionHours = (date: Date, day: number) => date.setDate(date.getDate() - day);

const dateInMS = (hours: number, min: number, sec: number, ms: number) =>
    new Date(new Date().setUTCHours(hours, min, sec, ms));

export const todayStart = dateInMS(0, 0, 0, 0).toISOString();
export const todayEnd = dateInMS(23, 59, 0, 0).toISOString();

export const yesterdayStart = new Date(correctionHours(dateInMS(0, 0, 0, 0), 1)).toISOString();
export const yesterdayEnd = new Date(correctionHours(dateInMS(23, 59, 0, 0), 1)).toISOString();

export const tomorrowStart = new Date(correctionHours(dateInMS(0, 0, 0, 0), -1)).toISOString();
export const tomorrowEnd = new Date(correctionHours(dateInMS(23, 59, 0, 0), -1)).toISOString();

export const getDateStart = (date: Date) => {
    return new Date(correctionHours(new Date(new Date(date).setUTCHours(0, 0, 0, 0)), -1)).toISOString();
};

export const getDateEnd = (date: Date) => {
    return new Date(correctionHours(new Date(new Date(date).setUTCHours(23, 59, 0, 0)), -1)).toISOString();
};
