export const CloseIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.5321 9.26846C12.156 9.89176 12.156 10.9 11.5321 11.5215C11.2202 11.8332 10.8165 12 10.3945 12C9.99083 12 9.58716 11.8533 9.27523 11.5215L6.00917 8.2785L2.74312 11.5215C2.43119 11.8332 2.02752 12 1.6055 12C1.20183 12 0.779817 11.8332 0.46789 11.5215C-0.155963 10.9 -0.155963 9.89176 0.46789 9.25012L3.73395 6.00527L0.46789 2.74025C-0.155963 2.11878 -0.155963 1.09216 0.46789 0.468854C1.09174 -0.156285 2.10092 -0.156285 2.74312 0.468854L6.00917 3.73021L9.25688 0.468854C9.89908 -0.156285 10.9083 -0.156285 11.5321 0.468854C12.156 1.09216 12.156 2.11878 11.5321 2.74025L8.26606 6.00527L11.5321 9.26846Z"
                fill="#CCCCCC"
            />
        </svg>
    );
};
