import { EIssueHistory } from 'enums/issues.enum';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';
import { Chip, Popover, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import UserSmallData from '../../../../components/UserSmallData';
import { issueStatusesMock } from '../../../../mockData/issues';
import { getHistoryThunk } from '../../../../store/actions/history';
import { useAppDispatch } from '../../../../store/hooks';
import { historySelector } from '../../../../store/selectors/historySelector';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import { dateFormatter } from '../../../../utils/helpers/dateFormatter';
import './StatusHistory.scss';

function StatusHistory() {
    const { issueId } = useParams();
    const dispatch = useAppDispatch();

    const history = useSelector(historySelector);
    const issue = useSelector(issueSelector);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        async function handler() {
            if (!issueId) return;
            await dispatch(getHistoryThunk(issueId));
        }

        handler();
    }, [issueId, issue?.status]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getTimeWindow = (start: string, end: string) => {
        if (!start || !end) return;

        const date = start.split('T')[0].replaceAll('-', '.');
        const times = `${start.split('T')[1].slice(0, 5)}−${end.split('T')[1].slice(0, 5)}`;

        const temp = date.split('.');
        const year = temp[0].split('');
        year.splice(0, 2);
        temp[0] = year.join('');

        return `${temp.reverse().join('.')} ${times}`;
    };

    const format: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    };

    return (
        <div className="status-history">
            <button
                aria-describedby={id}
                onClick={handleClick}
                className="info-icon_btn"
            >
                <ErrorSharpIcon
                    color="info"
                    className="info-icon"
                />
            </button>
            <Popover
                className="status-history_popover"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="popover-header">
                    <div className="close-icon">
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <Typography
                        variant="h6"
                        textAlign="start"
                    >
                        История изменений
                    </Typography>
                </div>
                <div className="popover-list">
                    {history?.length ? (
                        history?.map((item) => {
                            if (
                                item.trigger === EIssueHistory.Status ||
                                item.trigger === EIssueHistory.Canceled ||
                                item.trigger === null
                            ) {
                                return (
                                    <div
                                        key={item.id}
                                        className="history_item"
                                    >
                                        <div className="time">{dateFormatter(item.created_at, {}, format)}</div>
                                        <div className="statuses">
                                            <Chip
                                                label={issueStatusesMock[item.old_value]?.title}
                                                sx={{
                                                    fontWeight: '600',
                                                    backgroundColor: issueStatusesMock[item.old_value]?.colorHex,
                                                    color: `${issueStatusesMock[item.old_value]?.colorText} !important`,
                                                }}
                                                size={'small'}
                                            />
                                            <ArrowForwardIcon color="disabled" />
                                            <Chip
                                                label={issueStatusesMock[item.new_value]?.title}
                                                sx={{
                                                    fontWeight: '600',
                                                    backgroundColor: `${issueStatusesMock[item.new_value]?.colorHex}`,
                                                    color: `${issueStatusesMock[item.new_value]?.colorText} !important`,
                                                }}
                                                size={'small'}
                                            />
                                        </div>
                                        {item.user ? (
                                            <UserSmallData user={item.user} />
                                        ) : (
                                            <div style={{ height: '39px' }}>автоматич.</div>
                                        )}
                                    </div>
                                );
                            } else if (item.trigger === EIssueHistory.TimeWindows) {
                                return (
                                    <div
                                        key={item.id}
                                        className="history_item"
                                    >
                                        <div className="time">{dateFormatter(item.created_at, {}, format)}</div>
                                        <span style={{ color: 'rgb(138, 146, 166)', fontSize: '12px' }}>
                                            Изменение времени вызова
                                        </span>
                                        <div
                                            className="statuses"
                                            style={{ marginTop: '10px' }}
                                        >
                                            <Chip
                                                label={getTimeWindow(
                                                    item?.extra_data?.find((data) => data.type === 'old')
                                                        ?.start_datetime!,
                                                    item?.extra_data?.find((data) => data.type === 'old')
                                                        ?.end_datetime!,
                                                )}
                                                sx={{ fontWeight: '600' }}
                                                size={'small'}
                                            />
                                            <ArrowForwardIcon
                                                sx={{ fontSize: '12px' }}
                                                color="disabled"
                                            />
                                            <Chip
                                                label={getTimeWindow(
                                                    item?.extra_data?.find((data) => data.type === 'new')
                                                        ?.start_datetime!,
                                                    item?.extra_data?.find((data) => data.type === 'new')
                                                        ?.end_datetime!,
                                                )}
                                                sx={{ fontWeight: '600' }}
                                                size={'small'}
                                            />
                                        </div>
                                        {item.user ? (
                                            <UserSmallData user={item.user} />
                                        ) : (
                                            <div style={{ height: '39px' }}>автоматич.</div>
                                        )}
                                    </div>
                                );
                            } else return null;
                        })
                    ) : (
                        <p className="plug">Статусы пока не изменялись</p>
                    )}
                </div>
            </Popover>
        </div>
    );
}

export default StatusHistory;
