import { Placemark } from '@pbe/react-yandex-maps';
import React, { useCallback } from 'react';

import { IGetPointPlacemark, IIssue, ISelectedIssuesPin } from '../../../../../../interfaces/map';
import { setSelectedPinIssueAction } from '../../../../../../store/actions/map';
import { useAppDispatch } from '../../../../../../store/hooks';
import { getColorIssueMap } from '../../../../../../utils/helpers/getColorIssueMap';

interface IProps {
    issue: IIssue;
    getPointIssueData: (issue: IIssue) => IGetPointPlacemark;
    selectedPinIssue: ISelectedIssuesPin | null;
}

function PlacemarkIssue({ issue, getPointIssueData, selectedPinIssue }: IProps) {
    const dispatch = useAppDispatch();
    const iconColor = getColorIssueMap(issue);

    const updateRef = useCallback(
        (newRef: any) => {
            switch (true) {
                case selectedPinIssue &&
                    selectedPinIssue.oid === issue.oid &&
                    selectedPinIssue.coordinates[0] === issue.address_parts?.long &&
                    selectedPinIssue.coordinates[1] === issue.address_parts?.lat: {
                    newRef?.options?.set('iconColor', 'blue');
                    newRef?.balloon?.open();
                    break;
                }
                default:
                    newRef?.options.set('iconColor', iconColor);
                    newRef?.balloon?.close();
                    break;
            }
            newRef?.events.add('balloonclose', () => {
                dispatch(setSelectedPinIssueAction(null));
            });

            newRef?.events.add('balloonopen', () => {
                dispatch(
                    setSelectedPinIssueAction({
                        oid: issue.oid,
                        coordinates: [issue.address_parts?.long as number, issue.address_parts?.lat as number],
                    }),
                );
            });
        },
        [selectedPinIssue],
    );

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {issue.address_parts && issue.address_parts.long && issue.address_parts.lat ? (
                <Placemark
                    instanceRef={updateRef}
                    key={issue.oid}
                    defaultOptions={{
                        preset: 'islands#circleIcon',
                        iconColor,
                    }}
                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                    geometry={[issue.address_parts?.long, issue.address_parts?.lat]}
                    properties={{
                        ...getPointIssueData(issue),
                    }}
                />
            ) : null}
        </>
    );
}

export default PlacemarkIssue;
