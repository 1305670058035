import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ partners }: RootState) => partners;

export const partnersSelector = createSelector(state, ({ partners }) => partners);
export const partnersOptionsSelector = createSelector(state, ({ partners }) => {
    return (
        partners?.map((partner) => {
            return { value: partner.id, label: partner.name || '' };
        }) || null
    );
});
