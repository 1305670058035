import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';

import ChatUnseenCount from './ChatUnseenCount';
import ChatUserType from './ChatUserType';

import { TUserType } from '../../interfaces/chats';
import { dateFormatter } from '../../utils/helpers/dateFormatter';

const ChatItemContainer = styled(ListItemButton)(() => ({
    height: '72px',
    overflow: 'hidden',
    color: '#232D42',
}));

const ChatItemContent = styled(ListItemText)({
    '& > span': {
        fontSize: '0.875rem',
    },
});

const ChatItemInfo = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});
const ChatItemInfoDateTime = styled('span')({});

const ChatItemText = styled('div')({
    fontWeight: 'bold',
});

const ChatItemMessage = styled('div')({
    display: 'flex',
    gap: '8px',
});

const ChatItemMessageUser = styled('div')(({ isUser }: { isUser: boolean }) => ({
    color: isUser ? '#0040F1' : '#000',
}));

const ChatItemMessageText = styled('div')({
    flex: 1,
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
});

interface IProps {
    datetime: string | null;
    text: string | null;
    unseen_count?: number | null;
    user_type?: TUserType | null;
    message_user: string | null;
    message_text: string | null;
    onClick?: () => void;
    selected?: boolean;
    avatar?: string | null;
}

export default function ChatItem({
    avatar,
    datetime,
    text,
    unseen_count,
    user_type,
    message_user,
    message_text,
    onClick,
    selected,
}: IProps) {
    return (
        <ChatItemContainer
            selected={selected}
            onClick={onClick}
        >
            {avatar === undefined ? null : (
                <ListItemAvatar>
                    <Avatar src={avatar || ''} />
                </ListItemAvatar>
            )}
            <ChatItemContent>
                <ChatItemInfo>
                    {datetime && (
                        <ChatItemInfoDateTime>
                            {dateFormatter(
                                datetime,
                                { isDateTime: true },
                                { day: 'numeric', month: 'numeric', year: 'numeric' },
                            )}
                        </ChatItemInfoDateTime>
                    )}
                    {user_type && <ChatUserType user_type={user_type} />}
                </ChatItemInfo>
                <ChatItemText sx={{ color: text === 'Пользователь удален' ? '#ADB5BD' : '#161C46' }}>
                    {text}
                </ChatItemText>
                <ChatItemMessage sx={{ alignItems: 'end' }}>
                    <ChatItemMessageUser isUser={!message_user?.match('Пользователь не найден')}>
                        {message_user}
                    </ChatItemMessageUser>
                    <ChatItemMessageText>{message_text}</ChatItemMessageText>
                    {unseen_count ? <ChatUnseenCount unseen_count={unseen_count} /> : null}
                </ChatItemMessage>
            </ChatItemContent>
        </ChatItemContainer>
    );
}
