import { EGroupsActions } from '../../enums/actions.enum';
import { IGroupsState, TGroupsActions } from '../../interfaces/groups';

const initState: IGroupsState = {
    groups: null,
    group: null,
    isOpenEditGroup: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const groupsReducer = (state = initialState, action: TGroupsActions): IGroupsState => {
    switch (action.type) {
        case EGroupsActions.SetGroups:
            return { ...state, groups: action.payload };
        case EGroupsActions.SetGroup:
            return { ...state, group: action.payload };
        case EGroupsActions.SetIsOpenEditGroup:
            return { ...state, isOpenEditGroup: action.payload };
        default:
            return { ...state };
    }
};
