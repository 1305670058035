import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';
import { categoriesSelector } from '../categoriesSelectors';

const state = ({ createPart }: RootState) => createPart;

/* CP === CreatePart */
export const formValuesCPSelector = createSelector(state, ({ formValues }) => formValues);
export const categoryOptionsCPSelector = createSelector([categoriesSelector], (categories) => {
    return categories?.map(({ id, name }) => {
        return { value: id, label: name };
    });
});
export const errorsCPSelector = createSelector(state, ({ errors }) => errors);
export const isOpenCPSelector = createSelector(state, ({ isOpenCreatePart }) => isOpenCreatePart);
