import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ search }: RootState) => search;

export const itemsSearchSelector = createSelector(state, ({ items }) => items);
export const filtersSearchSelector = createSelector(state, ({ filters }) => filters);
export const indiciesSearchSelector = createSelector(state, ({ indicies }) => indicies);
export const textSearchSelector = createSelector(state, ({ text }) => text);
export const isOpenSearchSelector = createSelector(state, ({ isOpen }) => isOpen);
