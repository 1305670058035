import { Avatar } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { EditButton } from '../../../../components/EditButton';
import { uploadWorkerAvatarThunk } from '../../../../store/actions/worker';
import { useAppDispatch } from '../../../../store/hooks';
import { workerDetailSelector } from '../../../../store/selectors/workerSelectors';
import './WorkerAvatar.scss';

function WorkerAvatar() {
    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);

    async function uploadWorkerAvatar(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event?.target?.files && event.target.files[0];
        if (worker && file) {
            const formData = new FormData();
            formData.append('file', file);
            await dispatch(uploadWorkerAvatarThunk(worker.id, formData));
        }
    }

    return (
        <div className="avatar-wrapper">
            <Avatar
                className="avatar"
                src={worker?.profile_photo_file?.url}
            />
            <label
                className="edit-avatar-button-wrapper"
                htmlFor="worker-card-avatar"
            >
                <input
                    className="edit-avatar-input"
                    id="worker-card-avatar"
                    type="file"
                    name="worker-card-avatar"
                    hidden
                    onChange={uploadWorkerAvatar}
                />
                <EditButton className="edit-avatar-button" />
            </label>
        </div>
    );
}

export default WorkerAvatar;
