import Loader from 'components/Loader';
import MainLayout from 'layouts/MainLayout';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { useAppDispatch } from 'store/hooks';

import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Controls } from './components/controls';
import { Table } from './components/table';
import { fetchPriceLists, setFilters } from './store';
import { Container } from './styles';

export const PriceListsPage = () => {
    const dispatch = useAppDispatch();

    const { priceLists, pagination, loading } = useSelector(priceListsSelector);

    useEffect(() => {
        dispatch(fetchPriceLists());

        return () => {
            dispatch(setFilters({}));
        };
    }, []);

    return (
        <MainLayout>
            <Container>
                <Typography
                    fontWeight={700}
                    sx={{ fontSize: '28px' }}
                >
                    Прайс-лист
                </Typography>
                <Controls />
                {loading ? (
                    <Loader />
                ) : (
                    <Table
                        pagination={pagination}
                        rows={priceLists}
                    />
                )}
            </Container>
        </MainLayout>
    );
};
