import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EditIssueReminderForm from './EditIssueReminderForm';

import { setIsOpenEditIssueReminderAction } from '../../../store/actions/edit/editIssueReminder';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenEditIssueReminderSelector } from '../../../store/selectors/edit/editIssueReminderSelectors';

import './EditIssueReminder.scss';

function EditIssueReminder() {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(isOpenEditIssueReminderSelector);

    const closeHandler = () => {
        dispatch(setIsOpenEditIssueReminderAction(false));
    };
    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={closeHandler}
        >
            <DialogTitle>
                <Box className="edit-issue-reminder-dialog-title">
                    <div
                        className="close-icon"
                        style={{ position: 'absolute', right: '15px', cursor: 'pointer' }}
                    >
                        <CloseIcon onClick={closeHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Редактировать напоминание</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditIssueReminderForm />
            </DialogContent>
        </Dialog>
    );
}

export default EditIssueReminder;
