import { EIssueStatus } from 'enums/issues.enum';
import { ERoutes } from 'enums/routes.enum';
import { EWSHistoryType } from 'enums/wsHistory.enum';
import { markAsViewedWSHistoryThunk } from 'store/actions/websockets';
import { useAppDispatch } from 'store/hooks';
import { historySelector } from 'store/selectors/websocketsSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import ErrorIcon from '@mui/icons-material/Error';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Avatar, Skeleton } from '@mui/material';
import { blue, green, indigo, red } from '@mui/material/colors';
import cn from 'classnames';
import { formatDistance } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { IWSHistory } from 'interfaces/websockets';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './HistoryItem.scss';

interface IProps {
    wsHistory: IWSHistory;
    onOpenHandle: () => void;
}

function WSHistoryItem({ wsHistory, onOpenHandle }: IProps) {
    const dispatch = useAppDispatch();
    const history = useSelector(historySelector);
    const { data } = wsHistory;

    async function markAsViewedHandler() {
        if (!wsHistory.id || wsHistory.seen || !history) return;
        try {
            await dispatch(markAsViewedWSHistoryThunk({ notification_ids: [wsHistory.id], wsHistory: history }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <div
            onClick={markAsViewedHandler}
            className={cn('notification-item', { unSeen: !wsHistory.seen })}
            role={'button'}
            tabIndex={0}
        >
            {(() => {
                switch (data?.type) {
                    case EWSHistoryType.NewIssue:
                    case EWSHistoryType.IssueStatusUpdate:
                    case EWSHistoryType.IssueTimeUpdate:
                    case EWSHistoryType.IssueCanceledByPartner:
                        /*Добавлена проверка на id, чтобы исключить попадания событий, которые прилетают по сокету без notification_id,
						такие события нет возможности прочитать */
                        if (!data.data || !data.data.id) return null;
                        return (
                            <Link
                                to={`/${ERoutes.Issues}/${data.data.id}`}
                                className="notification-link"
                                onClick={onOpenHandle}
                            >
                                {(() => {
                                    switch (true) {
                                        case data.data.data.new_status === EIssueStatus.MasterNotFound:
                                            return (
                                                <Avatar sx={{ bgcolor: red['600'] }}>
                                                    <ErrorIcon />
                                                </Avatar>
                                            );
                                        default:
                                            return (
                                                <Avatar sx={{ bgcolor: indigo.A100 }}>
                                                    <TextSnippetIcon />
                                                </Avatar>
                                            );
                                    }
                                })()}
                                <div className="notification-item-data">
                                    <div>{data.data?.display_text}</div>
                                    <div>
                                        {!!data.data?.created_at &&
                                            formatDistance(new Date(data.data?.created_at), new Date(), {
                                                addSuffix: true,
                                                locale: ru,
                                            })}
                                    </div>
                                </div>
                            </Link>
                        );
                    case EWSHistoryType.IssueNewMessage:
                        const type = data.data?.type;
                        return (
                            <Link
                                to={
                                    type === 'chat'
                                        ? `/${ERoutes.Chats}/${data.data?.id}`
                                        : `/${ERoutes.Issues}/${data.data?.id}`
                                }
                                className="notification-link"
                            >
                                <Avatar sx={{ bgcolor: green['300'] }}>
                                    <TextsmsOutlinedIcon />
                                </Avatar>
                                <div className="notification-item-data">
                                    <div>{data.data?.display_text}</div>
                                    <div>
                                        {!!data.data?.created_at &&
                                            formatDistance(new Date(data.data?.created_at), new Date(), {
                                                addSuffix: true,
                                                locale: ru,
                                            })}
                                    </div>
                                </div>
                            </Link>
                        );
                    case EWSHistoryType.ChatUnseenCountUpdate:
                        if (!data.data) return null;
                        const extraData = data.data.extra_data;
                        return (
                            <Link
                                to={`/${ERoutes.Issues}/${extraData?.issue_id}`}
                                className="notification-link"
                            >
                                <Avatar sx={{ bgcolor: green['300'] }}>
                                    <TextsmsOutlinedIcon />
                                </Avatar>
                                <div className="notification-item-data">
                                    <div>{data.data?.display_text || 'Новое сообщение в чате заявки'}</div>
                                    <div>
                                        {!!data.data?.created_at &&
                                            formatDistance(new Date(data.data?.created_at), new Date(), {
                                                addSuffix: true,
                                                locale: ru,
                                            })}
                                    </div>
                                </div>
                            </Link>
                        );
                    default:
                        return 'Неизвестный тип уведомления';
                }
            })()}
            {!wsHistory.seen && (
                <Skeleton
                    variant="circular"
                    sx={{ bgcolor: blue['700'], position: 'absolute', right: 10 }}
                    width={15}
                    height={15}
                />
            )}
        </div>
    );
}

export default WSHistoryItem;
