import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EIssueStatus } from '../../../../../../../enums/issues.enum';
import { TErrors } from '../../../../../../../interfaces';
import {
    setErrorsEditIssueStatusToCancelAction,
    setIsOpenEditIssueStatusToCancelAction,
} from '../../../../../../../store/actions/edit/editIssueStatusToCancel';
import { putIssueStatusThunk } from '../../../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../../../store/hooks';
import {
    cancelationReasonsSelector,
    errorsEditIssueStatusToCancelSelector,
    formValuesEditIssueStatusToCancelSelector,
    issueIdEditIssueStatusToCancelSelector,
} from '../../../../../../../store/selectors/edit/editIssueStatusToCancelSelectors';
import defaultErrorCallback from '../../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../../utils/helpers/getErrorString';
import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();

    const issueId = useSelector(issueIdEditIssueStatusToCancelSelector);
    const formValues = useSelector(formValuesEditIssueStatusToCancelSelector);
    const errors = useSelector(errorsEditIssueStatusToCancelSelector);
    const cancelationReasons = useSelector(cancelationReasonsSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        dispatch(setErrorsEditIssueStatusToCancelAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    const activeOption = cancelationReasons?.find((item) => item.id === formValues?.reason?.value);
    const isDisabled = Boolean((activeOption?.comment_required && !formValues?.comment) || !formValues?.reason);

    async function submitFormHandler() {
        if (!issueId || !formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                await dispatch(
                    putIssueStatusThunk({
                        issueId,
                        newStatus: EIssueStatus.Canceled,
                        putCancelIssueStatusData: {
                            reasons: formValues.reason?.value ? [formValues.reason?.value] : [],
                            comment: formValues?.comment || '',
                        },
                    }),
                );
                dispatch(setIsOpenEditIssueStatusToCancelAction(false));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="buttons-wrapper">
            <Button
                variant="outlined"
                onClick={() => dispatch(setIsOpenEditIssueStatusToCancelAction(false))}
            >
                Отмена
            </Button>

            <LoadingButton
                variant="contained"
                loading={isLoading}
                disabled={isDisabled}
                onClick={submitFormHandler}
            >
                Сохранить
            </LoadingButton>
        </div>
    );
}

export default FooterForm;
