import { servicesApi } from 'api';
import { createSlice } from 'store/hooks';

import { PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    specifications: [],

    loading: false,
};

const slice = createSlice({
    name: 'specifications',
    initialState,
    reducers: (create) => ({
        fetchSpecifications: create.asyncThunk<void, void>(
            async (_, { dispatch }) => {
                const { data } = await servicesApi.getServicesSpecs();

                dispatch(setSpecifications(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),
        setSpecifications: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.specifications = payload;
        }),
    }),
});

export const { setSpecifications, fetchSpecifications } = slice.actions;

export default slice.reducer;
