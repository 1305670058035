import { EIssueStatus } from '../../enums/issues.enum';
import { IIssue } from '../../interfaces/map';

export const getColorIssueMap = (issue: IIssue): string => {
    switch (true) {
        case issue.status === EIssueStatus.Solved || issue.status === EIssueStatus.Canceled:
            return 'gray';
        case !!issue.predict_assignment_errors?.length: {
            return 'red';
        }
        case !!issue.worker_id: {
            return 'green';
        }
        case !issue.predict_assignment_errors?.length && !issue.worker_id: {
            return 'yellow';
        }
        default:
            return 'yellow';
    }
};
