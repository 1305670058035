import { Box } from '@mui/material';

import Additional from './Additional';
import Client from './Client';
import FooterForm from './FooterForm';
import Prices from './Prices';

import './PartnerForm.scss';

function PartnerForm() {
    return (
        <Box className="partner-form-wrapper">
            <Client />
            <Prices />
            <Additional />
            <FooterForm />
        </Box>
    );
}

export default PartnerForm;
