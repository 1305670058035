import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../components/inputs/TextField';
import { TErrors } from '../../../../interfaces';
import WorkersSelect from '../../../../modules/WorkersSelect';
import { setSelectedCRFAction } from '../../../../store/actions/categoriesRatiosFilter';
import {
    createGroupThunk,
    setCreateGroupIsOpenAction,
    setCreateGroupWorkersListAction,
} from '../../../../store/actions/create/createGroup';
import { getGroupsThunk } from '../../../../store/actions/groups';
import {
    getWorkersListThunk,
    getWorkersThunk,
    setSearchAction,
    setSelectedWorkersIdsAction,
} from '../../../../store/actions/workers';
import { useAppDispatch } from '../../../../store/hooks';
import { selectedWorkersIdsSelector } from '../../../../store/selectors/workersSelectors';
import './CreateGroupForm.scss';

function CreateGroupForm() {
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<TErrors | null>(null);
    const [groupName, setGroupName] = useState<string | null>(null);
    const selectedWorkers = useSelector(selectedWorkersIdsSelector);

    function changeNameHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setGroupName(e.target.value);
    }

    async function createGroup() {
        setIsLoading(true);
        if (groupName) {
            setErrors(null);
            await dispatch(createGroupThunk({ groupName, workersIds: selectedWorkers || [] }));
            await dispatch(getWorkersThunk({}));
            await dispatch(getGroupsThunk());
            dispatch(setCreateGroupIsOpenAction(false));
        } else {
            setErrors({
                name: {
                    message: 'Незаполненное поле',
                },
            });
        }
        setIsLoading(false);
    }

    function closeDialog() {
        dispatch(setCreateGroupIsOpenAction(false));
    }

    useEffect(() => {
        (async () => {
            await dispatch(getWorkersListThunk({}));
        })();
        return () => {
            dispatch(setCreateGroupWorkersListAction(null));
            dispatch(setSelectedWorkersIdsAction(null));
            dispatch(setSearchAction(null));
            dispatch(setSelectedCRFAction(null));
        };
    }, []);

    return (
        <div
            className="create-group-form-wrapper"
            style={{ minHeight: '400px !important' }}
        >
            <TextField
                errors={errors}
                name="name"
                placeholder="Название группы"
                fullWidth
                onChange={changeNameHandler}
            />
            <WorkersSelect />
            <div className="buttons-and-info-container">
                <div className="buttons">
                    <Button
                        onClick={closeDialog}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        sx={{ ml: 1 }}
                        onClick={createGroup}
                    >
                        Отправить
                    </LoadingButton>
                </div>
                <div className="selected-workers-info">Выбрано: {selectedWorkers ? selectedWorkers.length : 0}</div>
            </div>
        </div>
    );
}

export default memo(CreateGroupForm);
