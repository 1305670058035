import { Box } from '@mui/material';
import React from 'react';

import { getTimeWindowWithAllDate } from '../../../../../utils/helpers/getTimeWindowWithAllDate';

interface IProps {
    title: string;
    color: string;
    icon?: React.ReactNode;
    time_start?: string | Date | null;
    time_end?: string | Date | null;
}

export function WorkerSearchStatus({ color, icon, title, time_start, time_end }: IProps) {
    return (
        <>
            <Box
                sx={{
                    color,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {icon}
                <span style={{ marginLeft: 10 }}>{title}</span>
            </Box>
            {time_start || time_end ? (
                <span style={{ color: '#26a69a', fontSize: '12px' }}>
                    Период поиска:
                    {getTimeWindowWithAllDate({
                        time_window_start_datetime_msk: time_start,
                        time_window_end_datetime_msk: time_end,
                    })}
                </span>
            ) : null}
        </>
    );
}
