import { moySkladApi } from '../../../api';
import { ECreateWorkerMoySkladActions, EWorkerActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IBindWorkerMoySklad,
    IFormValuesCreateWorkerMoySklad,
    IFormValuesGetWorkerAssortment,
    ISearchWorkerMoySklad,
    ISetErrorsCreateWorkerMoySklad,
    ISetFormValuesCreateWorkerMoySklad,
    ISetIsOpenCreateWorkerMoySklad,
    IWorkerMoySklad,
    IWorkerMoySkladAssortment,
} from '../../../interfaces/create/createMoySklad';
import {
    ISetWorkerMoySkladAction,
    ISetWorkerMoySkladAssortmentAction,
    ISetWorkerMoySkladUnbindAction,
} from '../../../interfaces/worker';

export const setIsOpenCreateWorkerMoySkladAction = (payload: boolean): ISetIsOpenCreateWorkerMoySklad => ({
    type: ECreateWorkerMoySkladActions.SetIsOpen,
    payload,
});

export const setWorkerMoySkladAction = (payload: IWorkerMoySklad | null): ISetWorkerMoySkladAction => ({
    type: EWorkerActions.SetWorkerMoySklad,
    payload,
});

export const setWorkerMoySkladAssortmentAction = (
    payload: IWorkerMoySkladAssortment[],
): ISetWorkerMoySkladAssortmentAction => ({
    type: EWorkerActions.SetWorkerMoySkladAssortment,
    payload,
});

export const setWorkerMoySkladUnbindAction = (payload: null): ISetWorkerMoySkladUnbindAction => ({
    type: EWorkerActions.SetWorkerMoySkladUnbind,
    payload,
});

export const setFormValuesCreateWorkerMoySkladAction = (
    payload: IFormValuesCreateWorkerMoySklad | null,
): ISetFormValuesCreateWorkerMoySklad => ({
    type: ECreateWorkerMoySkladActions.SetFormValues,
    payload,
});

export const setErrorsCreateWorkerMoySkladAction = (payload: TErrors | null): ISetErrorsCreateWorkerMoySklad => ({
    type: ECreateWorkerMoySkladActions.SetErrors,
    payload,
});

export const addWorkerMoySkladThunk = (formValues: IFormValuesCreateWorkerMoySklad) => {
    return async () => {
        try {
            const { data } = await moySkladApi.addWorkerStore(formValues);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const getWorkerAssortmentThunk = (formValues: IFormValuesGetWorkerAssortment) => {
    return async () => {
        try {
            const { data } = await moySkladApi.getWorkerStoreAssortment(formValues);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const searchWorkerMoySkaldThunk = (search: ISearchWorkerMoySklad) => {
    return async () => {
        try {
            const { data } = await moySkladApi.searchWorkerStore(search);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const bindWorkerMoySkaldThunk = (sklad: IBindWorkerMoySklad) => {
    return async () => {
        try {
            const { data } = await moySkladApi.bindWorkerStore(sklad);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
