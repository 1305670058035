import { ECandidatesModalActions } from '../../enums/actions.enum';
import { ICandidatesModalState, TCandidatesModalActions } from '../../interfaces/candidatesModal';

const initState: ICandidatesModalState = {
    isOpen: false,
    filters: null,
    selectedWorkers: null,
    isLoading: false,
};

const initialState = { ...initState };

export const candidatesModalReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TCandidatesModalActions,
): ICandidatesModalState => {
    switch (action.type) {
        case ECandidatesModalActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case ECandidatesModalActions.SetFilters:
            return {
                ...state,
                filters: action.payload,
            };
        case ECandidatesModalActions.SetSelectedWorkers:
            return {
                ...state,
                selectedWorkers: action.payload,
            };
        case ECandidatesModalActions.SetIsLoading:
            return {
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
};
