import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, List, ListItem, ListItemIcon } from '@mui/material';
import React from 'react';

import { IPrice } from '../../../../../../interfaces/prices';
import './DialogContent.scss';

interface IProps {
    price: IPrice;
}

function DialogContent({ price }: IProps) {
    const { included_in_price, excluded_in_price } = price;
    return (
        <Box className="price-dialog">
            {!!included_in_price?.length && (
                <>
                    <div className="list-title">Что входит в стоимость</div>
                    <List
                        dense
                        title={'Что входит в стоимость'}
                    >
                        {included_in_price.map((text, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <CheckCircleIcon
                                            color="info"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    {text}
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            )}
            {!!excluded_in_price?.length && (
                <>
                    <div className="list-title">Что не входит в стоимость</div>
                    <List dense>
                        {excluded_in_price.map((text, index) => {
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <CancelIcon
                                            color="error"
                                            fontSize="small"
                                        />
                                    </ListItemIcon>
                                    {text}
                                </ListItem>
                            );
                        })}
                    </List>
                </>
            )}
        </Box>
    );
}

export default DialogContent;
