import { EEditRatioActions } from '../../../enums/actions.enum';
import { IEditRatioState, TEditRatioActions } from '../../../interfaces/edit/editRatio';

const initState: IEditRatioState = {
    isOpen: false,
    ratioId: null,
    formValues: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editRatioReducer = (state = initialState, action: TEditRatioActions): IEditRatioState => {
    switch (action.type) {
        case EEditRatioActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditRatioActions.SetRatioId:
            return {
                ...state,
                ratioId: action.payload,
            };
        case EEditRatioActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        default:
            return state;
    }
};
