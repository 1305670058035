import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ createIssue }: RootState) => createIssue;

export const selectedPricesSelector = createSelector(state, ({ selectedPrices }) => selectedPrices);
export const formValuesCreateIssueSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsCreateIssueSelector = createSelector(state, ({ errors }) => errors);
export const isOpenCreateIssueSelector = createSelector(state, ({ isOpen }) => isOpen);
