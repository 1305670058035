import { ECandidatesModalActions } from '../../enums/actions.enum';
import {
    ISetFiltersCMAction,
    ISetIsLoadingCM,
    ISetIsOpenCMAction,
    ISetSelectedWorkersCMAction,
    TWorkersFiltersCM,
} from '../../interfaces/candidatesModal';

export const setIsOpenCMAction = (payload: boolean): ISetIsOpenCMAction => ({
    type: ECandidatesModalActions.SetIsOpen,
    payload,
});

export const setFiltersCMAction = (payload: TWorkersFiltersCM | null): ISetFiltersCMAction => ({
    type: ECandidatesModalActions.SetFilters,
    payload,
});

export const setSelectedWorkersCMAction = (
    payload: ISetSelectedWorkersCMAction['payload'] | null,
): ISetSelectedWorkersCMAction => ({
    type: ECandidatesModalActions.SetSelectedWorkers,
    payload,
});

export const setIsLoadingCMAction = (payload: boolean): ISetIsLoadingCM => ({
    type: ECandidatesModalActions.SetIsLoading,
    payload,
});
