import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { TGetTagsResp } from '../interfaces/tags';

export default class TagsAPI {
    getTags(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/tag${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<TGetTagsResp>;
    }
}
