import { Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import { ERoutes } from '../../enums/routes.enum';
import { IChatDetail } from '../../interfaces/chat';

const ChatHeaderWrapper = styled('div')({
    padding: '8px 16px',
    textAlign: 'center',
    '& > span': {
        color: '#161C46',
        fontWeight: 'bold',
        fontSize: '20px',
    },
});

interface IProps {
    chat: IChatDetail;
}

export function ChatHeader({ chat }: IProps) {
    const renderTitle = () => {
        if (chat.chat.chat_type === 'issue_related') {
            return (
                <Link
                    target="_blank"
                    to={`/${ERoutes.Issues}/${chat.chat?.issue_id}`}
                >
                    Клиент-мастер. Чат по заявке <span style={{ color: '#0040F1' }}>№{chat.chat?.issue_oid}</span>
                </Link>
            );
        } else if (chat.chat.issue_id) {
            return (
                <Link
                    target="_blank"
                    to={`/${ERoutes.Issues}/${chat.chat?.issue_id}`}
                >
                    Чат по заявке <span style={{ color: '#0040F1' }}>№{chat.chat?.issue_oid}</span>
                </Link>
            );
        } else if (chat.chat.chat_type === 'telegram_bot') return 'Телеграм бот';
        else return 'Чат поддержки';
    };

    return (
        <>
            <ChatHeaderWrapper>
                <span>{renderTitle()}</span>
            </ChatHeaderWrapper>
            <Divider />
        </>
    );
}
