import { AppDispatch } from 'store/store';

import { mapApi } from '../../api';
import { EMapActions } from '../../enums/actions.enum';
import { EMapTabs } from '../../enums/map.enum';
import {
    IArea,
    IFilters,
    IIssue,
    ISelectedArea,
    ISelectedIssuesPin,
    ISelectedWorkerPin,
    ISetAreasAction,
    ISetFiltersAction,
    ISetIsLoadingAction,
    ISetIssuesAction,
    ISetMapTabAction,
    ISetPinMapAction,
    ISetSelectedAreaMapAction,
    ISetSelectedIssuesAction,
    ISetSelectedPinIssueMapAction,
    ISetSelectedPinWorkerMapAction,
    ISetSelectedWorkersAction,
    ISetWorkersAction,
    IWorker,
} from '../../interfaces/map';
import { todayEnd, todayStart } from '../../utils/helpers/getDaysInMS';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const setFiltersMapAction = (payload: IFilters | null): ISetFiltersAction => ({
    type: EMapActions.SetFilters,
    payload,
});

export const setAreasMapAction = (payload: IArea[] | null): ISetAreasAction => ({
    type: EMapActions.SetAreas,
    payload,
});

export const setIssuesMapAction = (payload: IIssue[] | null): ISetIssuesAction => ({
    type: EMapActions.SetIssues,
    payload,
});

export const setWorkersMapAction = (payload: IWorker[] | null): ISetWorkersAction => ({
    type: EMapActions.SetWorkers,
    payload,
});

export const setMapTabAction = (payload: EMapTabs): ISetMapTabAction => ({
    type: EMapActions.SetMapTab,
    payload,
});

export const setSelectedIssuesAction = (payload: IIssue[] | null): ISetSelectedIssuesAction => ({
    type: EMapActions.SetSelectedIssues,
    payload,
});

export const setSelectedWorkersAction = (payload: IWorker[] | null): ISetSelectedWorkersAction => ({
    type: EMapActions.SetSelectedWorkers,
    payload,
});

export const setPinAction = (payload: number[] | null): ISetPinMapAction => ({
    type: EMapActions.SetPin,
    payload,
});

export const setSelectedPinIssueAction = (payload: ISelectedIssuesPin | null): ISetSelectedPinIssueMapAction => ({
    type: EMapActions.SetSelectedPinIssue,
    payload,
});

export const setSelectedPinWorkerAction = (payload: ISelectedWorkerPin | null): ISetSelectedPinWorkerMapAction => ({
    type: EMapActions.SetSelectedPinWorker,
    payload,
});

export const setSelectedAreaAction = (payload: ISelectedArea | null): ISetSelectedAreaMapAction => ({
    type: EMapActions.SetSelectedArea,
    payload,
});

export const setIsLoadingMapAction = (payload: boolean): ISetIsLoadingAction => ({
    type: EMapActions.SetIsLoading,
    payload,
});

interface getMapThunkParams {
    filters?: IFilters;
}
export const getMapThunk =
    ({ filters }: getMapThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(setIsLoadingMapAction(true));
            const queriesObj: TArrayParams = [];

            if (filters) {
                const { time_window__gte, time_window__lte, region_id__in, help_type, category_preset, status } =
                    filters;

                if (time_window__lte) {
                    queriesObj.push({ time_window__lte });
                } else {
                    queriesObj.push({ time_window__lte: todayEnd });
                }

                if (time_window__gte) {
                    queriesObj.push({ time_window__gte });
                } else {
                    queriesObj.push({ time_window__gte: todayStart });
                }

                help_type?.forEach((helpType) => {
                    queriesObj.push({ help_type: helpType });
                });

                if (category_preset) {
                    queriesObj.push({ category_preset });
                }

                region_id__in?.forEach((id) => {
                    queriesObj.push({ region_id__in: id });
                });

                // eslint-disable-next-line no-shadow
                status?.forEach((status) => {
                    queriesObj.push({ status });
                });
            }
            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await mapApi.getMap(queries);
            const { areas, issues, workers } = data;
            dispatch(setAreasMapAction(areas));

            dispatch(setIssuesMapAction(issues));
            dispatch(setSelectedIssuesAction(issues));

            dispatch(setWorkersMapAction(workers));
            dispatch(setSelectedWorkersAction(workers));
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            dispatch(setIsLoadingMapAction(false));
        }
    };
