import { servicePriceListsApi } from 'api';
import { Edit } from 'static/Icons/Edit';

import Add from '@mui/icons-material/Add';
import { Table as MuiTable, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { AddInPriceLists } from './modals/add-pricelist';
import { PriceAndCommission } from './modals/price-commission';
import { CellInputProps, EditIconWrapper } from './styles';

import { Cell, HeadCell, PlusButton } from '../../create-service/styles';

export type TPriceList = {
    amount: number;
    availability_type: any | null;
    categories: any | null;
    category_ids: any[];
    commission: any;
    created_at: string;
    description: string;
    external_id: any | null;
    id: string;
    include_descriptions: any[];
    is_active: boolean;
    is_active_integration: boolean;
    is_removed: boolean;
    name: string;
    price_list: any;
    service_type: any;
    services: any[];
    updated_at: string;
};

type Props = {
    rows: TPriceList[];
};

export const Table: FC<Props> = ({ rows }) => {
    const [isOpenModal, setOpenModal] = useState(false);
    const [isOpenCommission, setOpenCommission] = useState(false);
    const [isOpenCost, setOpenCost] = useState(false);

    const handleEditAmount = async (id: string, item: any, amount: string) => {
        const data = {
            name: item.name,
            description: item.description,
            service: item.services.map((service: any) => {
                return {
                    id: service.id,
                    is_deleted: false,
                };
            }),
            amount,
            commission: {
                value: item.commission.commission_value,
            },
            include_descriptions: [],
        };

        servicePriceListsApi
            .editServicesComplex(id, data)
            .then(() => {
                enqueueSnackbar('Изменено', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    return (
        <>
            <MuiTable sx={{ marginTop: '10px' }}>
                <TableHead>
                    <HeadCell>ПРАЙС-ЛИСТ</HeadCell>
                    <HeadCell>КОМПЛЕКС УСЛУГ</HeadCell>
                    <HeadCell>
                        СТОИМОСТЬ КОМПЛЕКСА УСЛУГ
                        <EditIconWrapper onClick={() => setOpenCost(true)}>
                            <Edit />
                        </EditIconWrapper>
                    </HeadCell>
                    <HeadCell>
                        КОМИССИЯ МАСТЕРА
                        <EditIconWrapper onClick={() => setOpenCommission(true)}>
                            <Edit />
                        </EditIconWrapper>
                    </HeadCell>
                </TableHead>
                <TableBody>
                    {rows?.map((row) => {
                        return (
                            <TableRow key={row.id}>
                                <Cell>{row.name}</Cell>
                                <Cell>{'---'}</Cell>
                                <Cell>
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        suffix=" ₽"
                                        customInput={TextField}
                                        value={row.amount}
                                        onBlur={(e) => {
                                            handleEditAmount(row.id, row, e.target.value.slice(0, -2));
                                        }}
                                    />
                                </Cell>
                                <Cell>
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        suffix=" ₽"
                                        customInput={TextField}
                                        value={row.commission?.commission_value}
                                    />
                                </Cell>
                            </TableRow>
                        );
                    })}
                </TableBody>
                <PlusButton
                    startIcon={<Add />}
                    onClick={() => setOpenModal(true)}
                >
                    Добавить в прайс-лист
                </PlusButton>
            </MuiTable>
            <AddInPriceLists
                setOpenModal={setOpenModal}
                isOpenModal={isOpenModal}
            />
            <PriceAndCommission
                title="Комиссия мастера для всех прайс-листов"
                isOpen={isOpenCommission}
                setOpen={setOpenCommission}
            />
            <PriceAndCommission
                title="Стоимость комплекса услуг для всех прайс-листов"
                isOpen={isOpenCost}
                setOpen={setOpenCost}
            />
        </>
    );
};
