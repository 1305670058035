import { dateFormatter } from 'utils/helpers/dateFormatter';

import { TableBody, TableCell, TableRow, styled } from '@mui/material';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

import { TTransation } from '../../store/types';

type Props = {
    rows: TTransation[];
};

export const transactionTypes = {
    withdraw: 'Вывод средств',
    deposit: 'Начисление',
    correction: 'Корректировка',
};

export const Body: FC<Props> = ({ rows }) => {
    const dateFormat: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };

    return (
        <TableBody>
            {rows.map((row) => {
                return (
                    <TableRow key={row.id}>
                        <Cell>{dateFormatter(row.created_at, {}, dateFormat)}</Cell>
                        <Cell>{transactionTypes[row.transaction_type]}</Cell>
                        <Cell>{row.worker ? `${row.worker?.last_name} ${row.worker?.first_name}` : '-'}</Cell>
                        <Cell>{row.initiator ? `${row.initiator?.last_name} ${row.initiator?.first_name}` : '-'}</Cell>
                        <Cell>{row.issue ? row.issue.oid : '-'}</Cell>
                        <Cell>
                            <NumericFormat
                                allowNegative={false}
                                displayType="text"
                                value={row.amount}
                                prefix={row.amount < 0 ? '- ' : ''}
                                suffix=" ₽"
                                thousandSeparator=" "
                            />
                        </Cell>
                        <Cell>{row.comment?.text}</Cell>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};

const Cell = styled(TableCell)({
    maxWidth: '250px',
    fontWeight: '400',
    fontSize: '14px',
});
