import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editWorkerFIO }: RootState) => editWorkerFIO;

export const isOpenEditWorkerFIOSelector = createSelector(state, ({ isOpen }) => isOpen);
export const workerIdEditWorkerFIOSelector = createSelector(state, ({ workerId }) => workerId);
export const formValuesEditWorkerFIOSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsEditWorkerFIOSelector = createSelector(state, ({ errors }) => errors);
