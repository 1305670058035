import { EEditCategoryActions } from '../../../enums/actions.enum';
import { IEditCategoryState, TEditCategoryActions } from '../../../interfaces/edit/editCategory';

const initState: IEditCategoryState = {
    isOpen: false,
    categoryId: null,
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editCategoryReducer = (state = initialState, action: TEditCategoryActions): IEditCategoryState => {
    switch (action.type) {
        case EEditCategoryActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditCategoryActions.SetCategoryId:
            return {
                ...state,
                categoryId: action.payload,
            };
        case EEditCategoryActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EEditCategoryActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
