import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CreateGroup from './CreateGroup';
import WorkersFilters from './Filters';
import WorkersTable from './WorkersTable';

import MainLayout from '../../layouts/MainLayout';
import EditGroup from '../../modules/GroupFilter/EditGroup';
import {
    getCategoriesRatiosFilterThunk,
    setSelectedCRFForWorkersAction,
} from '../../store/actions/categoriesRatiosFilter';
import { setCreateGroupIsOpenAction } from '../../store/actions/create/createGroup';
import { setGroupsAction } from '../../store/actions/groups';
import {
    getWorkersThunk,
    setWorkersPaginationAction,
    setWorkersSortOptionsAction,
    workersResetAction,
} from '../../store/actions/workers';
import { useAppDispatch } from '../../store/hooks';
import {
    isWorkersTableLoaderSelector,
    workersFiltersSelector,
    workersPaginationSelector,
    workersSelector,
    workersSortOptionsSelector,
} from '../../store/selectors/workersSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import './WorkersPage.scss';

function WorkersPage() {
    const dispatch = useAppDispatch();

    const pagination = useSelector(workersPaginationSelector);
    const filters = useSelector(workersFiltersSelector);
    const sortOptions = useSelector(workersSortOptionsSelector);
    const workers = useSelector(workersSelector);
    const isWorkersTableLoader = useSelector(isWorkersTableLoaderSelector);

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getCategoriesRatiosFilterThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
        return () => {
            dispatch(setSelectedCRFForWorkersAction(null));
            dispatch(workersResetAction());
            dispatch(setGroupsAction(null));
            dispatch(setWorkersPaginationAction({ page: 0, size: 50, total: 0 }));
        };
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (!pagination || !filters) return;
                await dispatch(getWorkersThunk({ page: pagination.page, size: pagination.size, filters, sortOptions }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [pagination?.page, pagination?.size, filters, sortOptions]);

    function showDialogCreateGroup() {
        dispatch(setCreateGroupIsOpenAction(true));
    }

    return (
        <MainLayout>
            <div className="workers-page-wrapper">
                <div className="workers-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        Мастера
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={showDialogCreateGroup}
                    >
                        Создать группу
                    </Button>
                    <CreateGroup />
                </div>
                <WorkersFilters />
                <WorkersTable
                    workers={workers}
                    isLoader={isWorkersTableLoader}
                    pagination={pagination}
                    changePagination={(newPagination) => dispatch(setWorkersPaginationAction(newPagination))}
                    sortOptions={sortOptions}
                    changeSortOption={(sortOption) => dispatch(setWorkersSortOptionsAction(sortOption))}
                />
                <EditGroup />
            </div>
        </MainLayout>
    );
}

export default WorkersPage;
