import { Button, Popover } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import { ICategories } from '../../../../../../interfaces/prices';
import './CategoriesGroup.scss';

interface IProps {
    categories: ICategories[] | null;
}
function CategoriesGroup({ categories }: IProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {categories?.length ? (
                <>
                    <div style={{ padding: '0px 8px' }}>{categories[0].name}</div>

                    {categories.length > 1 && (
                        <>
                            <Button
                                onClick={handlePopoverClick}
                                disableRipple
                            >
                                Ещё {categories.length - 1}
                            </Button>
                            <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                {
                                    <div className="categories-group-container">
                                        {categories.slice(1).map(({ id, name }) => (
                                            <MenuItem
                                                key={id}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handlePopoverClose(event);
                                                }}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </div>
                                }
                            </Popover>
                        </>
                    )}
                </>
            ) : (
                <div>0 шт.</div>
            )}
        </>
    );
}

export default CategoriesGroup;
