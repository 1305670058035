import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ chats }: RootState) => chats;

export const chatsSelector = createSelector(state, ({ chats }) => chats);
export const isChatsTableLoaderSelector = createSelector(state, ({ isChatsTableLoader }) => isChatsTableLoader);
export const chatsPaginationSelector = createSelector(state, ({ pagination }) => pagination);
export const chatsFiltersSelector = createSelector(state, ({ filters }) => filters);
export const chatsSortOptionsSelector = createSelector(state, ({ sortOptions }) => sortOptions);
export const chatsTotalUnseenCountSelector = createSelector(state, ({ totalUnseenCount }) => totalUnseenCount);
export const chatsTotalUnseenCountIssuesSelector = createSelector(
    state,
    ({ totalUnseenCountIssues }) => totalUnseenCountIssues,
);

export const chatsIsLoadingSelector = createSelector(
    state,
    ({ chats, isChatsTableLoader }) => chats === null && isChatsTableLoader,
);
export const chatsIsNextPageLoadingSelector = createSelector(
    state,
    ({ chats, isChatsTableLoader }) => (chats && isChatsTableLoader) || false,
);
export const chatsHasNextPageSelector = createSelector(state, ({ chats, pagination }) => {
    if (chats && pagination) {
        return chats.length < pagination.total;
    }
    return true;
});
