import { Box, Button, Typography } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChoosePartner } from './ChoosePartner';
import { ConfirmAutoAssignment } from './ConfirmAutoAssignment';
import { ConfirmMasterSearch } from './ConfirmMasterSearch';
import CreateIssue from './CreateIssue';
import IssuesFilters from './Filters';
import IssuesTable from './IssuesTable';
import IssueRow from './IssuesTable/IssueRow';

import { ESort } from '../../enums/tables.enum';
import { IIssuesFilters } from '../../interfaces/issues';
import MainLayout from '../../layouts/MainLayout';
import { defaultTableHeadMock, statusFiltersMock } from '../../mockData/issues';
import {
    getCategoriesRatiosFilterThunk,
    setCategoriesRatiosFilterAction,
    setSearchCRFAction,
    setSelectedCRFAction,
} from '../../store/actions/categoriesRatiosFilter';
import { getCompaniesThunk } from '../../store/actions/companies';
import { setIsOpenCreateIssueAction } from '../../store/actions/create/createIssue';
import {
    getCountersIssueThunk,
    getIssuesThunk,
    setIssuesAction,
    setIssuesFiltersAction,
    setIssuesPaginationAction,
    setIsTableLoaderAction,
    setSelectedIssuesAction,
    setTableHeadAction,
} from '../../store/actions/issues';
import { useAppDispatch } from '../../store/hooks';
import {
    isIssuesTableLoaderSelector,
    issuesFiltersSelector,
    issuesPaginationSelector,
    issuesSelector,
    selectedIssuesHasAnyForAutoAssignment,
    selectedIssuesHasAnyForChangeStatus,
    statusFiltersSelector,
    tableHeadIssuesSelector,
} from '../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';

import './IssuesPage.scss';

function IssuesPage() {
    const dispatch = useAppDispatch();

    const filters = useSelector(issuesFiltersSelector);
    const pagination = useSelector(issuesPaginationSelector);
    const issues = useSelector(issuesSelector);
    const tableHead = useSelector(tableHeadIssuesSelector);
    const isIssuesTableLoading = useSelector(isIssuesTableLoaderSelector);
    const statusFilters = useSelector(statusFiltersSelector);
    const enabledChangeStatusBtn = useSelector(selectedIssuesHasAnyForChangeStatus);
    const enabledAutoAssignmentBtn = useSelector(selectedIssuesHasAnyForAutoAssignment);

    const [choosePartnerOpen, setChoosePartnerOpen] = useState(false);
    const [confirmAutoAssignmentOpen, setConfirmAutoAssignmentOpen] = useState(false);
    const [confirmMasterSearchOpen, setConfirmMasterSearchOpen] = useState(false);

    useEffect(() => {
        async function handle() {
            try {
                const defaultFilters: IIssuesFilters = {
                    is_archived: false,
                    statuses: statusFilters?.length
                        ? statusFilters?.find((item) => item.isActive)?.statuses
                        : statusFiltersMock[0].statuses,
                    order_by: { propName: 'smart_bo', order: ESort.Desc },
                };

                dispatch(setIssuesPaginationAction({ page: 0, size: 50, total: 0 }));
                dispatch(setIssuesFiltersAction(defaultFilters));
                dispatch(setTableHeadAction(defaultTableHeadMock));
                await dispatch(
                    getCountersIssueThunk({
                        statusFilters: statusFilters || statusFiltersMock,
                        filters: defaultFilters,
                    }),
                );
                await dispatch(getCategoriesRatiosFilterThunk());
                await dispatch(getCompaniesThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();

        return () => {
            dispatch(setIssuesFiltersAction(null));
            dispatch(setIssuesPaginationAction({ page: 0, size: 50, total: 0 }));
            dispatch(setIssuesAction(null));
            dispatch(setSearchCRFAction(null));
            dispatch(setSelectedCRFAction(null));
            dispatch(setCategoriesRatiosFilterAction(null));
            dispatch(setSelectedIssuesAction(null));
        };
    }, []);

    useEffect(() => {
        async function handle() {
            try {
                if (!pagination || !filters) return;

                dispatch(setIsTableLoaderAction(true));
                await dispatch(getIssuesThunk({ page: pagination.page, size: pagination.size, filters }));
                await dispatch(getCountersIssueThunk({ statusFilters, filters }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                dispatch(setIsTableLoaderAction(false));
            }
        }

        handle();
    }, [pagination?.page, pagination?.size, filters]);

    function showDialogNewIssue() {
        dispatch(setIsOpenCreateIssueAction(true));
    }

    return (
        <MainLayout>
            <div className="issues-page-wrapper">
                <div className="issues-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        Главная
                    </Typography>
                    <Box>
                        {enabledAutoAssignmentBtn && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setConfirmAutoAssignmentOpen(true)}
                                sx={{ mr: 3 }}
                            >
                                Автоподбор
                            </Button>
                        )}
                        {enabledChangeStatusBtn && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setConfirmMasterSearchOpen(true)}
                                sx={{ mr: 3 }}
                            >
                                Поиск мастера
                            </Button>
                        )}

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={showDialogNewIssue}
                            sx={{ mr: 3 }}
                        >
                            Новая заявка
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setChoosePartnerOpen(true)}
                        >
                            Импорт заявок
                        </Button>
                    </Box>
                    <CreateIssue />
                    <ChoosePartner
                        open={choosePartnerOpen}
                        onClose={() => setChoosePartnerOpen(false)}
                    />
                    <ConfirmAutoAssignment
                        open={confirmAutoAssignmentOpen}
                        onClose={() => setConfirmAutoAssignmentOpen(false)}
                    />
                    <ConfirmMasterSearch
                        open={confirmMasterSearchOpen}
                        onClose={() => setConfirmMasterSearchOpen(false)}
                    />
                </div>
                <IssuesFilters />
                <IssuesTable
                    tableHead={tableHead}
                    setTableHead={(value) => dispatch(setTableHeadAction(value))}
                    filters={filters}
                    setFilters={(value) => dispatch(setIssuesFiltersAction(value))}
                    ComponentRow={IssueRow}
                    tableContent={issues}
                    isLoading={isIssuesTableLoading}
                    pagination={pagination}
                    changePagination={(newPagination) => dispatch(setIssuesPaginationAction(newPagination))}
                />
            </div>
        </MainLayout>
    );
}

export default memo(IssuesPage);
