import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ chatGroupByUsers }: RootState) => chatGroupByUsers;

export const chatGroupByUsersSelector = createSelector(state, ({ items }) => items);

export const chatGroupByUsersFiltersSelector = createSelector(state, ({ filters }) => filters);

export const chatGroupByUsersIsLoadingSelector = createSelector(
    state,
    ({ items, isLoading }) => items === null && isLoading,
);

export const chatGroupByUsersIsNextPageLoadingSelector = createSelector(
    state,
    ({ items, isLoading }) => (items && isLoading) || false,
);

export const chatGroupByUsersPaginationSelector = createSelector(state, ({ pagination }) => pagination);
export const chatGroupByUsersHasNextPageSelector = createSelector(state, ({ items, pagination }) => {
    if (items && pagination) {
        return items.length < pagination.total;
    }
    return true;
});

export const shouldReloadSelector = createSelector(state, ({ reload }) => reload);
