import { EIssueHelpType } from '../../enums/issues.enum';
import { IFormValuesEditWorkerSpecializations } from '../../interfaces/edit/editWorkerSpecializations';

export const getCurrentFormValuesSpecialization = (
    formValues: IFormValuesEditWorkerSpecializations[] | null,
    helpType: EIssueHelpType,
) => {
    if (!formValues?.length) return;

    // eslint-disable-next-line consistent-return
    return formValues?.find((obj) => {
        return obj?.help_type === helpType;
    })?.category_ids;
};
