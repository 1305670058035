import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import {
    IClient,
    IClientAddress,
    IClientDetail,
    ICreateClient,
    IUpdateClient,
    TClientsResp,
} from '../interfaces/clients';
import { IAddress } from '../interfaces/create/createIssuePartner';
import { ICreateHomeDeviceReq, IHomeDeviceDetail, IUpdateClientHomeDeviceReq } from '../interfaces/devices';
import { IPaginationResp } from '../interfaces/pagination';

export default class ClientsAPI {
    getClient(clientId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients/${clientId}`,
            isAuthorization: true,
        }) as AxiosPromise<IClientDetail>;
    }

    getClients(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients${
                queries || '?page=1&size=100'
            }`,
            isAuthorization: true,
        }) as AxiosPromise<TClientsResp>;
    }

    createClient(newClient: ICreateClient) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients`,
            data: newClient,
            isAuthorization: true,
        }) as AxiosPromise<IClientDetail>;
    }

    getHomeDevices(clientId: IClient['id']) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients/${clientId}/home_device`,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<IHomeDeviceDetail>>;
    }

    createHomeDevice(clientId: IClient['id'], request: ICreateHomeDeviceReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients/${clientId}/home_device`,
            data: request,
            isAuthorization: true,
        }) as AxiosPromise<IHomeDeviceDetail>;
    }

    deleteHomeDevice(clientId: IClient['id'], homeDeviceId: IHomeDeviceDetail['id']) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients/${clientId}/home_device/${homeDeviceId}`,
            isAuthorization: true,
        }) as AxiosPromise<IHomeDeviceDetail>;
    }

    updateHomeDevice(
        clientId: IClient['id'],
        homeDeviceId: IHomeDeviceDetail['id'],
        request: IUpdateClientHomeDeviceReq,
    ) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients/${clientId}/home_device/${homeDeviceId}`,
            data: request,
            isAuthorization: true,
        }) as AxiosPromise<IHomeDeviceDetail>;
    }

    createAddress(clientId: IClient['id'], address: IAddress) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients/${clientId}/address`,
            data: address,
            isAuthorization: true,
        }) as AxiosPromise<IClientAddress>;
    }

    updateClient(clientId: IClient['id'], postData: IUpdateClient) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clients/${clientId}`,
            data: postData,
            isAuthorization: true,
        }) as AxiosPromise<IHomeDeviceDetail>;
    }
}
