import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';

import { IOption, TErrors } from '../../../interfaces';

interface IProps<TVal> {
    name: string;
    options: ReadonlyArray<IOption<TVal>>;
    checkedValues?: TVal[];
    onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    errors?: TErrors | null;
}

function CheckboxGroup<TVal>({ name, options, checkedValues, onChange, errors }: IProps<TVal>) {
    const [errorText, setErrorText] = useState<null | React.ReactNode>(null);

    useEffect(() => {
        if (errors && name in errors) {
            if (Array.isArray(errors[name])) {
                const errs: { message: string }[] = errors[name];
                setErrorText(
                    errs.map((err) => {
                        return (
                            <span
                                key={name}
                                className="text__error"
                            >
                                {err?.message}
                            </span>
                        );
                    }),
                );
            } else {
                setErrorText(
                    <span
                        key={name}
                        className="text__error"
                    >
                        {errors[name]?.message}
                    </span>,
                );
            }
        } else {
            setErrorText(null);
        }
    }, [errors]);

    return (
        <FormControl
            fullWidth
            error={!!errorText}
        >
            <FormGroup>
                {options?.map((option) => (
                    <FormControlLabel
                        key={option.value?.toString()}
                        control={
                            <Checkbox
                                onChange={onChange}
                                value={option.value}
                                checked={!!checkedValues?.some((v) => v === option.value)}
                            />
                        }
                        label={option.label}
                    />
                ))}
            </FormGroup>
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
}

export default CheckboxGroup;
