import { Autocomplete, Box, FormControl } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import SelectedPrice from './SelectedPrice';

import TextField from '../../../../../components/inputs/TextField';
import { IPriceOption } from '../../../../../interfaces/prices';
import { setSelectedPricesCIAction } from '../../../../../store/actions/create/createIssue';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsCreateIssueSelector,
    selectedPricesSelector,
} from '../../../../../store/selectors/create/createIssuesSelectors';
import {
    pricesIsAdditionalOptionsSelector,
    pricesOptionsSelector,
    pricesSelector,
} from '../../../../../store/selectors/pricesSelectors';
import { toDivideAmount } from '../../../../../utils/helpers/toDivideAmount';

function Prices() {
    const dispatch = useAppDispatch();

    const prices = useSelector(pricesSelector);
    const pricesOptions = useSelector(pricesOptionsSelector);
    const pricesIsAdditionalOptions = useSelector(pricesIsAdditionalOptionsSelector);
    const selectedPrices = useSelector(selectedPricesSelector);
    const errors = useSelector(errorsCreateIssueSelector);

    function priceSelectHandler(option: IPriceOption | null) {
        if (!prices || !option) return;
        if (selectedPrices && selectedPrices.some(({ id }) => id === option.id)) return;

        const foundPrice = prices.find(({ id }) => id === option.id);

        if (!foundPrice) {
            console.error('Error: !foundPrice priceSelectHandle()');
            return;
        }

        const newSelectedPrices = selectedPrices ? [...selectedPrices, foundPrice] : [foundPrice];
        dispatch(setSelectedPricesCIAction(newSelectedPrices));
    }

    return (
        <Box mt={1}>
            <FormControl fullWidth>
                <Autocomplete
                    renderInput={(params) => {
                        return (
                            <TextField
                                name={'prices'}
                                errors={errors}
                                placeholder="Услуга *"
                                params={params}
                            />
                        );
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li
                                {...props}
                                key={option.id}
                            >
                                <div>
                                    {option.label}{' '}
                                    <span style={{ color: '#8c95a8' }}>({toDivideAmount(option.amount)} ₽)</span>
                                </div>
                            </li>
                        );
                    }}
                    onChange={(_, option) => {
                        priceSelectHandler(option);
                    }}
                    noOptionsText={'Нет услуг...'}
                    loadingText={'Загрузка...'}
                    loading={pricesOptions === null}
                    options={pricesOptions || []}
                />
            </FormControl>
            <FormControl
                fullWidth
                sx={{ marginTop: 2 }}
            >
                <Autocomplete
                    renderInput={(params) => {
                        return (
                            <TextField
                                name={'pricesIsAdditional'}
                                errors={errors}
                                placeholder="Доп. услуги"
                                params={params}
                            />
                        );
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li
                                {...props}
                                key={option.id}
                            >
                                <div>
                                    {option.label}{' '}
                                    <span style={{ color: '#8c95a8' }}>({toDivideAmount(option.amount)} ₽)</span>
                                </div>
                            </li>
                        );
                    }}
                    onChange={(_, option) => {
                        priceSelectHandler(option);
                    }}
                    noOptionsText={'Нет услуг...'}
                    loadingText={'Загрузка...'}
                    loading={pricesIsAdditionalOptions === null}
                    options={pricesIsAdditionalOptions || []}
                />
            </FormControl>
            {!!selectedPrices?.length &&
                selectedPrices.map((price) => (
                    <SelectedPrice
                        key={price.id}
                        price={price}
                    />
                ))}
        </Box>
    );
}

export default Prices;
