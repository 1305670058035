import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ERoutes } from '../../../../../enums/routes.enum';
import { ISelectedWorkerPin, IWorker } from '../../../../../interfaces/map';
import { setPinAction, setSelectedPinWorkerAction } from '../../../../../store/actions/map';
import { useAppDispatch } from '../../../../../store/hooks';
import { getFullName } from '../../../../../utils/helpers/getFullName';
import { getMasterCoordsMap } from '../../../../../utils/helpers/getMasterCoordsMap';

interface IProps {
    worker: IWorker;
    pin: ISelectedWorkerPin | null;
}

function WorkerItem({ worker, pin }: IProps) {
    const dispatch = useAppDispatch();

    const [isActive, setIsActive] = useState<boolean>(false);
    const workerCoord = getMasterCoordsMap(worker);

    useEffect(() => {
        if (pin && pin.id === worker.id) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [pin]);

    const onClickWorker = () => {
        if (!workerCoord.length) return;
        dispatch(setPinAction(workerCoord || null));
        setIsActive(true);
        setTimeout(() => {
            dispatch(setSelectedPinWorkerAction({ id: worker.id, coordinates: workerCoord }));
        }, 500);
    };

    return (
        <li
            className={cn('workers__content_item', { isActive })}
            onClick={onClickWorker}
        >
            <Link
                to={`/${ERoutes.Workers}/${worker.id}`}
                target="_blank"
            >
                <span>{getFullName(worker)}</span>
            </Link>
            {!getMasterCoordsMap(worker).length ? <i>адрес не указан</i> : null}
        </li>
    );
}

export default WorkerItem;
