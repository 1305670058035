import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ parts }: RootState) => parts;

export const partsSelector = createSelector(state, ({ parts }) => parts);
export const partSelector = createSelector(state, ({ part }) => part);
export const partsOptionsSelector = createSelector(state, ({ parts }) => {
    return parts?.map(({ id, name, categories, item_number }) => {
        return { value: id, label: name, categories, item_number };
    });
});
export const partFiltersSelector = createSelector(state, ({ filters }) => filters);
export const partPaginationSelector = createSelector(state, ({ pagination }) => pagination);
