import { EWorkerActions } from '../../enums/actions.enum';
import { EIssueHelpType } from '../../enums/issues.enum';
import { IWorkerState, TWorkerActions } from '../../interfaces/worker';

const initState: IWorkerState = {
    workerDetail: null,
    canceledIssueHistory: null,
    solvedIssueHistory: null,
    selectedTab: EIssueHelpType.Repair,
    workerSelectedSpecializations: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const workerReducer = (state = initialState, action: TWorkerActions): IWorkerState => {
    switch (action.type) {
        case EWorkerActions.WorkerReset:
            return initState;
        case EWorkerActions.SetWorkerDetail:
            return {
                ...state,
                workerDetail: action.payload,
            };
        case EWorkerActions.SetWorkerAvatar:
            return {
                ...state,
                workerDetail: state.workerDetail ? { ...state.workerDetail, profile_photo_file: action.payload } : null,
            };
        case EWorkerActions.SetCanceledIssueHistory:
            return {
                ...state,
                canceledIssueHistory: action.payload,
            };
        case EWorkerActions.SetSolvedIssueHistory:
            return {
                ...state,
                solvedIssueHistory: action.payload,
            };
        case EWorkerActions.SetSelectedTab:
            return {
                ...state,
                selectedTab: action.payload,
            };
        case EWorkerActions.SetWorkerFIO:
            return {
                ...state,
                workerDetail:
                    state.workerDetail && action.payload
                        ? {
                              ...state.workerDetail,
                              //TODO: !?
                              last_name: action.payload.last_name,
                              first_name: action.payload.first_name,
                              middle_name: action.payload.middle_name,
                          }
                        : state.workerDetail,
            };
        case EWorkerActions.SetWorkerSchedule:
            return {
                ...state,
                workerDetail: state.workerDetail
                    ? {
                          ...state.workerDetail,
                          schedule: action.payload ? action.payload.schedule : [],
                      }
                    : state.workerDetail,
            };
        case EWorkerActions.SetWorkerMoySklad:
            return {
                ...state,
                workerDetail: state.workerDetail
                    ? {
                          ...state.workerDetail,
                          moysklad_store: action.payload,
                      }
                    : state.workerDetail,
            };
        case EWorkerActions.SetWorkerMoySkladUnbind:
            return {
                ...state,
                workerDetail: state.workerDetail
                    ? {
                          ...state.workerDetail,
                          moysklad_store: action.payload,
                      }
                    : state.workerDetail,
            };
        case EWorkerActions.SetWorkerMoySkladAssortment:
            return {
                ...state,
                workerDetail: state.workerDetail
                    ? {
                          ...state.workerDetail,
                          assortment: Array.isArray(action.payload) ? action.payload : [],
                      }
                    : state.workerDetail,
            };
        case EWorkerActions.SetWorkerSpecializations:
            return {
                ...state,
                workerDetail: state.workerDetail
                    ? {
                          ...state.workerDetail,
                          specializations: action.payload || [],
                      }
                    : state.workerDetail,
            };
        case EWorkerActions.SetWorkerSelectedSpecializations:
            return {
                ...state,
                workerSelectedSpecializations: action.payload,
            };
        case EWorkerActions.SetWorkerServiceAreas:
            //TODO: !!?
            return {
                ...state,
                workerDetail:
                    state.workerDetail && action.payload
                        ? {
                              ...state.workerDetail,
                              service_areas: action.payload,
                          }
                        : state.workerDetail,
            };
        default:
            return state;
    }
};
