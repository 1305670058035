export type TArrayParams = { [key: string]: string | number | null | undefined | boolean | string[] }[] | undefined;

export function getQueryParamsAsString(
    arrParams: TArrayParams,
    { atFirst }: { atFirst?: boolean } = { atFirst: true },
): string {
    if (!arrParams || !arrParams.length) return '';
    const params = arrParams.map((param) => ({ ...param }));
    let result: string = atFirst ? '?' : '';
    params.forEach((param, index) => {
        const [key]: string[] = Object.keys(param);
        if (param[key] === undefined) {
            return;
        }
        if (index === 0) {
            result += atFirst ? `${key}=${param[key]}` : `&${key}=${param[key]}`;
            return;
        }
        result = `${result}&${key}=${param[key]}`;
    });
    return result;
}
