import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CloseIcon from '@mui/icons-material/Close';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { Avatar, Box } from '@mui/material';
import React, { memo } from 'react';

import { IPhotoFile } from '../../interfaces';
import { ICandidateIssue } from '../../interfaces/issues';
import { getFullName } from '../../utils/helpers/getFullName';
import { getTimeWindowWithAllDate } from '../../utils/helpers/getTimeWindowWithAllDate';
import './MasterSmallData.scss';

interface IProps {
    candidate: ICandidateIssue;
    widthAvatar?: number;
    heightAvatar?: number;
    afterFullName?: React.ReactNode;
    onAvatarClick?: (userId: string, photoFileId?: IPhotoFile['id']) => void;
}

function MasterSmallData({ candidate, afterFullName, onAvatarClick, widthAvatar = 28, heightAvatar = 28 }: IProps) {
    const { profile_photo_file } = candidate.worker || {};
    return (
        <Box className="master-small-data">
            <div className="master-small-data-wrapper">
                <Avatar
                    sx={{ width: widthAvatar, height: heightAvatar }}
                    variant="circular"
                    src={profile_photo_file?.url}
                    onClick={() => onAvatarClick && onAvatarClick(candidate.id, profile_photo_file?.id)}
                >
                    {!profile_photo_file?.url && <PersonSharpIcon />}
                </Avatar>
                <div className="master-small-data">
                    <div>
                        {candidate?.worker && getFullName(candidate.worker)}
                        <span style={{ marginLeft: 10 }}>{afterFullName}</span>
                    </div>
                    <div>
                        id: <span>{candidate.id}</span>
                    </div>
                    {candidate?.start_show_datetime_at || candidate?.end_show_datetime_at ? (
                        <div>
                            Время принятия заявки:{' '}
                            {getTimeWindowWithAllDate({
                                time_window_start_datetime_msk: candidate?.start_show_datetime_at,
                                time_window_end_datetime_msk: candidate?.end_show_datetime_at,
                            })}
                        </div>
                    ) : null}
                </div>
                {(() => {
                    switch (true) {
                        case candidate.is_show && !candidate.is_hide:
                            return <CheckCircleSharpIcon color="success" />;
                        case candidate.is_hide:
                            return <CloseIcon color="error" />;
                        default:
                            return null;
                    }
                })()}
            </div>
        </Box>
    );
}

export default memo(MasterSmallData);
