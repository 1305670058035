import { Placemark } from '@pbe/react-yandex-maps';
import React, { useCallback } from 'react';

import { IGetPointPlacemark, ISelectedWorkerPin, IWorker } from '../../../../../../interfaces/map';
import { setSelectedPinWorkerAction } from '../../../../../../store/actions/map';
import { useAppDispatch } from '../../../../../../store/hooks';
import { getMasterCoordsMap } from '../../../../../../utils/helpers/getMasterCoordsMap';

interface IProps {
    worker: IWorker;
    getPointWorkerData: (worker: IWorker) => IGetPointPlacemark;
    selectedPinWorker: ISelectedWorkerPin | null;
}

function PlacemarkWorker({ worker, getPointWorkerData, selectedPinWorker }: IProps) {
    const dispatch = useAppDispatch();

    const workerCoord = getMasterCoordsMap(worker);

    const updateRef = useCallback(
        (newRef: any) => {
            switch (true) {
                case selectedPinWorker &&
                    workerCoord.length &&
                    worker.id === selectedPinWorker.id &&
                    selectedPinWorker.coordinates[0] === workerCoord[0] &&
                    selectedPinWorker.coordinates[1] === workerCoord[1]: {
                    newRef?.options?.set('preset', 'islands#blueDotIcon');
                    newRef?.balloon?.open();
                    break;
                }
                default:
                    newRef?.options?.set('preset', 'islands#greenDotIcon');
                    newRef?.balloon?.close();
                    break;
            }
            newRef?.events.add('balloonclose', () => {
                dispatch(setSelectedPinWorkerAction(null));
            });
            newRef?.events.add('balloonopen', () => {
                dispatch(setSelectedPinWorkerAction({ id: worker.id, coordinates: workerCoord }));
            });
        },
        [selectedPinWorker],
    );

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {!worker?.addresses?.length || !getMasterCoordsMap(worker).length ? null : (
                <Placemark
                    instanceRef={updateRef}
                    key={worker.id}
                    defaultOptions={{
                        preset: 'islands#greenDotIcon',
                    }}
                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                    geometry={getMasterCoordsMap(worker)}
                    properties={{
                        ...getPointWorkerData(worker),
                    }}
                />
            )}
        </>
    );
}

export default PlacemarkWorker;
