import { ERating } from '../../enums/rating.enum';

export const getRatingName = (rating: number | null) => {
    switch (rating) {
        case 1:
            return `${rating} - ${ERating.Terribly}`;
        case 2:
            return `${rating} - ${ERating.Badly}`;
        case 3:
            return `${rating} - ${ERating.Okay}`;
        case 4:
            return `${rating} - ${ERating.Good}`;
        case 5:
            return `${rating} - ${ERating.Excellent}`;
        default:
            return null;
    }
};
