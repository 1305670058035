import { Button, styled, TableCell, TextField } from '@mui/material';

export const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
});

export const TextInput = styled(TextField)({
    width: '230px',
});

export const HeadCellCenter = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    padding: '16px 0',
    textAlign: 'center',
}); // double

export const CellCenter = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    padding: '16px 0',
    textAlign: 'center',
}); // double

export const PlusButton = styled(Button)({
    color: '#0040F1',
}); // double
