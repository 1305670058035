import { AppDispatch } from 'store/store';

import { issuesApi } from '../../api';
import { EIssueInvoicingNotificationsActions } from '../../enums/actions.enum';
import {
    ICreateInvoicingNotificationsReq,
    IINFilters,
    IInvoicingNotification,
    INotificationDataResp,
    ISetDataINAction,
    ISetEmailIsOpenINAction,
    ISetInvoicingNotificationsAction,
    ISetSmsIsOpenINAction,
} from '../../interfaces/invoicingNotifications';
import { IIssue } from '../../interfaces/issues';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const setSmsIsOpenINAction = (payload: boolean): ISetSmsIsOpenINAction => ({
    type: EIssueInvoicingNotificationsActions.SetSmsIsOpen,
    payload,
});

export const setEmailIsOpenINAction = (payload: boolean): ISetEmailIsOpenINAction => ({
    type: EIssueInvoicingNotificationsActions.SetEmailIsOpen,
    payload,
});

export const setDataINAction = (payload: INotificationDataResp | null): ISetDataINAction => ({
    type: EIssueInvoicingNotificationsActions.SetDataInvoicingNotifications,
    payload,
});

export const setInvoicingNotificationsAction = (
    payload: IInvoicingNotification[] | null,
): ISetInvoicingNotificationsAction => ({
    type: EIssueInvoicingNotificationsActions.SetInvoicingNotifications,
    payload,
});

export const getInvoicingNotificationDataThunk = (issueId: IIssue['id']) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await issuesApi.getInvoicingNotificationData(issueId);
        dispatch(setDataINAction(data));
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

interface IGetInvoicingNotificationsThunkParams {
    issueId: IIssue['id'];
    filters?: IINFilters;
}
export const getInvoicingNotificationsThunk =
    ({ issueId, filters }: IGetInvoicingNotificationsThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [];

            if (filters) {
                const { notification_type } = filters;

                if (notification_type) {
                    notification_type.forEach((nt) => {
                        queriesObj.push({ notification_type: nt });
                    });
                }
            }

            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await issuesApi.getInvoicingNotifications(issueId, queries);
            dispatch(setInvoicingNotificationsAction(data));
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const createInvoicingNotificationThunk =
    (newNotification: ICreateInvoicingNotificationsReq, notifications: IInvoicingNotification[]) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data } = await issuesApi.createInvoicingNotifications(newNotification.issue_id, newNotification);
            dispatch(setInvoicingNotificationsAction([...notifications, data]));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
