import { createSelector } from 'reselect';

import { IMessageWithSenderInfo } from '../../interfaces/chat';
import { RootState } from '../rootReducer';

const state = ({ chat }: RootState) => chat;

export const chatDetailSelector = createSelector(state, ({ chatDetail }) => chatDetail);
export const chatAuthorClientSelector = createSelector(state, ({ authorClient }) => authorClient);
export const chatAuthorWorkerSelector = createSelector(state, ({ authorWorker }) => authorWorker);
export const chatIssueHistoryForClientSelector = createSelector(
    state,
    ({ issueHistoryForClient }) => issueHistoryForClient,
);
export const chatIssueHistoryForWorkerSelector = createSelector(
    state,
    ({ issueHistoryForWorker }) => issueHistoryForWorker,
);
export const chatMessagesWithSendersInfoSelector = createSelector(state, ({ chatHistory, chatParticipants }) => {
    return (
        !chatHistory || !chatParticipants
            ? null
            : chatHistory
                  .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at))
                  .map((message) => {
                      const sender = chatParticipants.find((item) => item.id === message.sender_id);
                      return { ...message, sender };
                  })
    ) as IMessageWithSenderInfo[] | null;
});
