import { TablePagination } from '@mui/material';
import React from 'react';

import { IPagination } from '../../../../interfaces/pagination';
import './WorkersPagination.scss';

interface IProps {
    pagination: IPagination;
    changePagination: (newPagination: IPagination) => void;
}

function WorkersTablePagination({ pagination, changePagination }: IProps) {
    function changePageHandler(e: React.MouseEvent<HTMLButtonElement> | null, page: number) {
        pagination && changePagination({ ...pagination, page });
    }

    function changeSizeHandler(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const size = Number(e.target.value);
        pagination && size && changePagination({ ...pagination, size });
    }

    return pagination ? (
        <TablePagination
            className="workers-table-pagination"
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={pagination.total}
            rowsPerPage={pagination.size}
            page={pagination.page}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeSizeHandler}
        />
    ) : null;
}

export default WorkersTablePagination;
