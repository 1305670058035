import { ESort } from '../../enums/tables.enum';
import { TOrderBy } from '../../interfaces';

export const getOrderByStr = <T>(filter: TOrderBy<T> | undefined | null): string => {
    if (!filter) return '';
    const { propName, order } = filter;
    if (order === ESort.Asc) {
        return `-${propName}`;
    } else {
        return `${propName}`;
    }
};
