import { Button, Popover } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { setIsOpenPriceDialogAction } from '../../../store/actions/priceDialog';
import { setIsOpenDeletePriceAction } from '../../../store/actions/prices';
import { useAppDispatch } from '../../../store/hooks';
import { priceSelector } from '../../../store/selectors/pricesSelectors';

function PricesButtonGroup() {
    const dispatch = useAppDispatch();

    const price = useSelector(priceSelector);
    const [isOpen, setIsOpen] = useState(null);

    function deletePrice() {
        dispatch(setIsOpenDeletePriceAction(true));
    }

    function openPriceDialog() {
        dispatch(setIsOpenPriceDialogAction({ isEdit: true, isOpen: true, isAdditional: !price?.is_additional }));
    }

    return (
        <div className="button-group">
            <Button
                color="primary"
                variant="contained"
                onClick={(e: any) => setIsOpen(e.currentTarget)}
            >
                Меню
            </Button>
            {isOpen && (
                <Popover
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setIsOpen(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: { boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)' },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            openPriceDialog();
                            setIsOpen(null);
                        }}
                    >
                        Редактировать
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            deletePrice();
                            setIsOpen(null);
                        }}
                        sx={{ color: 'red' }}
                    >
                        Удалить
                    </MenuItem>
                </Popover>
            )}
        </div>
    );
}

export default PricesButtonGroup;
