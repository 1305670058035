import { Button, Paper, styled, TableCell, TablePagination } from '@mui/material';

export const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    textAlign: 'center',
}); // double

export const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    textAlign: 'center',
}); // double

export const TableWrapper = styled(Paper)({
    marginTop: '20px',
    position: 'relative',
    height: '100%',
    overflow: 'auto',
    borderRadius: '8px',
});

export const Pagination = styled(TablePagination)({
    display: 'block',
    backgroundColor: '#fff',
    position: 'sticky',
    bottom: 0,
});

export const ButtonItem = styled(Button)({
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '5px 14px',
    '.MuiButtonBase-root': {},
}); // double
