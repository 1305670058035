import { ECommissionsType } from '../../enums/prices.enum';

interface IGetDiscountParams {
    discount: number | null | undefined;
    discount_type: ECommissionsType | undefined;
}

export function getDiscount({ discount, discount_type }: IGetDiscountParams) {
    return discount_type === ECommissionsType.Percentage
        ? Number(((discount || 0) / 100).toFixed(3)) || null
        : Number((discount || 0).toFixed(3)) || null;
}
