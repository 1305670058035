import { ETagsActions } from '../../enums/actions.enum';
import { ITagsState, TTagsActions } from '../../interfaces/tags';

const initState: ITagsState = {
    tags: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const tagsReducer = (state = initialState, action: TTagsActions): ITagsState => {
    switch (action.type) {
        case ETagsActions.SetTags:
            return {
                ...state,
                tags: action.payload,
            };
        default:
            return state;
    }
};
