import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';

import IssueComments from './IssueComments';
import './IssueCommentsWrapper.scss';

function IssueCommentsWrapper() {
    return (
        <Paper className="issue-comments-wrapper">
            <Typography variant="h5">Комментарии</Typography>
            <IssueComments />
        </Paper>
    );
}

export default IssueCommentsWrapper;
