import React from 'react';
import { useSelector } from 'react-redux';

import IssueItem from './IssueItem';

import { selectedIssuesMapSelector, selectedPinIssueMapSelector } from '../../../../store/selectors/mapSelectors';
import '../SidebarContent.scss';

function IssuesContent() {
    const issues = useSelector(selectedIssuesMapSelector);
    const selectedPinIssue = useSelector(selectedPinIssueMapSelector);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {!!issues?.length && (
                <ul className="issues__content scroll-wrapper">
                    {issues?.map((issue) => (
                        <IssueItem
                            key={issue.id}
                            issue={issue}
                            pin={selectedPinIssue}
                        />
                    ))}
                </ul>
            )}
        </>
    );
}

export default IssuesContent;
