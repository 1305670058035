import React from 'react';
import { useSelector } from 'react-redux';

import EditWorkerFIO from './EditFIO';

import { EditButton } from '../../../../components/EditButton';
import {
    setFormValuesEditWorkerFIOAction,
    setIsOpenEditWorkerFIOAction,
    setWorkerIdEditWorkerFIOAction,
} from '../../../../store/actions/edit/editWorkerFIO';
import { useAppDispatch } from '../../../../store/hooks';
import { workerDetailSelector } from '../../../../store/selectors/workerSelectors';
import { getFullName } from '../../../../utils/helpers/getFullName';

import './WorkerFIO.scss';

function WorkerFIO() {
    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);

    function openEditWorkerFIOModal() {
        if (worker) {
            dispatch(setWorkerIdEditWorkerFIOAction(worker.id));
            dispatch(
                setFormValuesEditWorkerFIOAction({
                    last_name: worker.last_name,
                    first_name: worker.first_name,
                    middle_name: worker.middle_name,
                }),
            );
            dispatch(setIsOpenEditWorkerFIOAction(true));
        }
    }

    return (
        worker && (
            <div className="worker-fio-wrapper">
                <div className="worker-fio-value">{getFullName(worker)}</div>
                <EditButton onClick={openEditWorkerFIOModal} />
                <EditWorkerFIO />
            </div>
        )
    );
}

export default WorkerFIO;
