import VideoFiles from 'components/VideoFiles';

import { Grade } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import styles from './ClientReview.module.scss';

import Accordion from '../../../../components/Accordion';
import AudioFiles from '../../../../components/AudioFiles';
import PhotoFiles from '../../../../components/PhotoFiles';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import { getRatingName } from '../../../../utils/helpers/getRatingName';

function ClientReview() {
    const issue = useSelector(issueSelector);
    const { feedback } = issue || {};

    return (
        <Accordion
            title={<Typography>Отзыв клиента</Typography>}
            sx={{ mt: 3 }}
            expanded={false}
        >
            {feedback ? (
                <div className={styles.wrapper}>
                    <div className={styles.reasons}>
                        <span>Оценка мастеру</span>
                        <div className={styles.reasonsWrapper}>
                            <Grade sx={{ color: '#FB8C00' }} />
                            {getRatingName(feedback.rating_master)}
                        </div>
                        <span>Оценка сервису</span>
                        <div className={styles.reasonsWrapper}>
                            <Grade sx={{ color: '#FB8C00' }} />
                            {getRatingName(feedback.rating_call_center)}
                        </div>
                        {feedback.rating_call_center === 5 && feedback.rating_master === 5 ? null : (
                            <>
                                <span>Что хотелось бы улучшить</span>
                                <div style={{ margin: '10px 0' }}>
                                    <div className={styles.reasonsWrapper}>
                                        {feedback?.reasons?.length
                                            ? feedback?.reasons?.map((reason) => (
                                                  <div key={reason.id}>{reason.name}</div>
                                              ))
                                            : null}
                                    </div>

                                    {feedback.comment ? <pre className={styles.comment}>{feedback.comment}</pre> : null}
                                </div>
                                {feedback.audio_files ? (
                                    <div className={styles.audios}>
                                        <AudioFiles audioFiles={feedback.audio_files} />
                                    </div>
                                ) : null}
                                <div className={styles.photos}>
                                    {feedback.photo_files ? <PhotoFiles photoFiles={feedback.photo_files} /> : null}
                                    {feedback.video_files ? <VideoFiles videoFiles={feedback.video_files} /> : null}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            ) : (
                'Отзыв не оставлен'
            )}
        </Accordion>
    );
}

export default ClientReview;
