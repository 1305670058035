import { ECreatePartActions } from '../../../enums/actions.enum';
import { ICreatePartState, TCreatePartActions } from '../../../interfaces/create/createPart';

const initState: ICreatePartState = {
    formValues: null,
    errors: null,
    isOpenCreatePart: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const createPartReducer = (state = initialState, action: TCreatePartActions): ICreatePartState => {
    switch (action.type) {
        case ECreatePartActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };

        case ECreatePartActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case ECreatePartActions.SetIsOpenCreatePart:
            return {
                ...state,
                isOpenCreatePart: action.payload,
            };
        default:
            return state;
    }
};
