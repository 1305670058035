import { Params } from 'types';

import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IImportFileGsisResponse, IImportGsisRequestData } from '../interfaces/importGsis';

export default class IssuesImportAPI {
    /* Импорт файла gsis */
    importGsisFile(data: FormData, params: Params) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/GSISImportPreview`,
            data,
            params,
            isAuthorization: true,
        }) as AxiosPromise<IImportFileGsisResponse[]>;
    }

    importDnsFile(data: FormData, params: Params) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/DNSImportPreview`,
            data,
            params,
            isAuthorization: true,
        }) as AxiosPromise<IImportFileGsisResponse[]>;
    }

    saveData(data: IImportGsisRequestData[], params: Params) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/GSISImportInsert`,
            data,
            params,
            isAuthorization: true,
        }) as AxiosPromise;
    }
}
