import { workersApi } from 'api';
import { Button } from 'components/Button';
import { FilterIn } from 'features/filter-in';
import { FilterIs } from 'features/filter-is';
import { useAppDispatch } from 'store/hooks';

import { styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { WithdrawModal } from './components/modals/withdraw';
import { Table } from './components/table';
import { fetchWorkers, setFilters, setWorkerId } from './store';
import { withdrawsSelector } from './store/selectors';

const requisitesItems = [
    { value: true, title: 'Есть' },
    { value: false, title: 'Нет' },
];

const ieItems = [
    { value: true, title: 'Да' },
    { value: false, title: 'Нет' },
];

export const Withdraws = () => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);

    const { workers, workerIds, loading, filters, pagination } = useSelector(withdrawsSelector);

    const handleWithdrawWorkerIdsChange = (itemId: string) => {
        const getIds = () => {
            if (filters.pk__in.includes(itemId)) {
                return filters.pk__in.filter((id) => id !== itemId);
            } else {
                return [...filters.pk__in, itemId];
            }
        };

        dispatch(setFilters({ pk__in: getIds() }));
        dispatch(fetchWorkers());
    };

    const handleFilterInClear = (key: string) => {
        dispatch(setFilters({ [key]: [] }));
        dispatch(fetchWorkers());
    };

    const handleFilterIsIe = (value: boolean | null) => {
        dispatch(setFilters({ is_ie: value, has_requisite: value !== null ? true : filters.has_requisite }));
        dispatch(fetchWorkers());
    };

    const handleFilterIsRequisites = (value: boolean | null) => {
        dispatch(setFilters({ is_ie: value === false ? null : filters.is_ie, has_requisite: value }));
        dispatch(fetchWorkers());
    };

    useEffect(() => {
        dispatch(fetchWorkers());
    }, []);

    const isIeDisabled = filters.has_requisite === false;

    return (
        <>
            <Toolbar>
                <FilterIn
                    title="Мастер"
                    onChange={handleWithdrawWorkerIdsChange}
                    activeIds={filters.pk__in}
                    fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                    cleaner={() => handleFilterInClear('pk__in')}
                />
                <FilterIs
                    title="Реквизиты"
                    options={requisitesItems}
                    activeOption={
                        requisitesItems.find((item) => item.value === filters.has_requisite) ?? {
                            title: '',
                            value: null,
                        }
                    }
                    onChange={(value) => handleFilterIsRequisites(value)}
                />
                <FilterIs
                    title="Является ИП"
                    options={ieItems}
                    activeOption={ieItems.find((item) => item.value === filters.is_ie) ?? { title: '', value: null }}
                    disabled={isIeDisabled}
                    onChange={(value) => handleFilterIsIe(value)}
                />

                {workerIds.length ? (
                    <Button
                        onClick={() => {
                            dispatch(setWorkerId(''));
                            setOpen(true);
                        }}
                        sx={{ marginLeft: 'auto' }}
                        variant="contained"
                    >
                        Вывести для выбранных
                    </Button>
                ) : null}
            </Toolbar>

            <Table
                loading={loading}
                rows={workers}
                pagination={pagination}
            />
            <WithdrawModal
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const Toolbar = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    columnGap: '16px',
});
