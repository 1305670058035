import { EEditIssueStatusWithCommentActions } from '../../../enums/actions.enum';
import {
    IEditIssueStatusWithCommentState,
    TEditIssueStatusWithCommentActions,
} from '../../../interfaces/edit/editIssueStatusWithComment';

const initState: IEditIssueStatusWithCommentState = {
    isOpen: false,
    issueId: null,
    formValues: null,
    errors: null,
    data: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editIssueStatusWithCommentReducer = (
    state = initialState,
    action: TEditIssueStatusWithCommentActions,
): IEditIssueStatusWithCommentState => {
    switch (action.type) {
        case EEditIssueStatusWithCommentActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditIssueStatusWithCommentActions.SetIssueId:
            return {
                ...state,
                issueId: action.payload,
            };
        case EEditIssueStatusWithCommentActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EEditIssueStatusWithCommentActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case EEditIssueStatusWithCommentActions.SetData:
            return {
                ...state,
                data: action.payload,
            };
        default:
            return state;
    }
};
