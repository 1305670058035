import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editWorkerInfo }: RootState) => editWorkerInfo;

export const isOpenEditWorkerInfoSelector = createSelector(state, ({ isOpen }) => isOpen);
export const isLoadingInfoSelector = createSelector(state, ({ isLoading }) => isLoading);
export const additionalEducationSelector = createSelector(state, ({ additionalEducation }) => additionalEducation);
export const educationTypeSelector = createSelector(state, ({ educationType }) => educationType);
export const educationalProgramSelector = createSelector(state, ({ educationalProgram }) => educationalProgram);
export const institutionNameSelector = createSelector(state, ({ institutionName }) => institutionName);
export const errorsEditWorkerInfoSelector = createSelector(state, ({ errors }) => errors);
