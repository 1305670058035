import Loader from 'components/Loader';
import { centrifugeIsConnectSelector } from 'store/selectors/centrifugeSelectors';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { Button, IconButton, Popover, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import ListFiles from '../ListFiles';

import './AddMessageForm.scss';

interface IProps {
    sendMessage: (text?: string, attachments?: File[]) => void;
    isLoadingUploadFiles: boolean;
    chatRef: any;
}

function AddMessageForm({ sendMessage, isLoadingUploadFiles, chatRef }: IProps) {
    const IsConnect = useSelector(centrifugeIsConnectSelector);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isFilesPopoverOpen, setIsFilesPopoverOpen] = useState(false);

    const [text, setText] = useState('');
    const [comment, setComment] = useState('');
    const [files, setFiles] = useState<File[] | null>(null);

    const handleClose = () => {
        setText('');
        setComment('');
        setFiles(null);
        setIsFilesPopoverOpen(false);
    };

    function handleSendMessage() {
        if (text || files) {
            sendMessage(isFilesPopoverOpen ? comment : text, files || undefined);
            handleClose();
        }
    }

    const fileAttachButtonRef = useRef(null);

    useEffect(() => {
        if (!chatRef.current) return;
        const { current } = chatRef;

        const dragOver = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            current.classList.add('active');
        };

        const dragLeave = (e: DragEvent) => {
            e.preventDefault();
            e.stopPropagation();
            current.classList.remove('active');
        };

        const drop = (e: DragEvent) => {
            e.preventDefault();
            current.classList.remove('active');
            handleInputFileChange(e.dataTransfer?.files ?? null);
        };

        current.addEventListener('drop', drop);
        current.addEventListener('dragover', dragOver);
        current.addEventListener('dragleave', dragLeave);

        return () => {
            current.removeEventListener('drop', drop);
            current.removeEventListener('dragover', dragOver);
            current.addEventListener('dragleave', dragLeave);
        };
    }, [chatRef]);

    useEffect(() => {
        setAnchorEl(fileAttachButtonRef.current);
    }, [fileAttachButtonRef]);

    function handleInputFileChange(filesList: FileList | null) {
        setIsFilesPopoverOpen(true);
        setComment(text);

        const inputFiles = filesList;
        if (inputFiles) {
            const filesArr: File[] = [];
            Object.keys(inputFiles).forEach((key) => {
                if (key === 'length') return;
                const file = inputFiles[Number(key)];
                if (file) filesArr.push(file);
            });
            setFiles(filesArr);
        } else {
            setFiles(null);
        }
    }

    function fileDeleteCallback(fileDelete: File) {
        if (!fileDelete || !files) return;
        let foundIndex: number | null = null;

        files.forEach((file, index) => {
            if (file.lastModified === fileDelete.lastModified) {
                foundIndex = index;
            }
        });
        if (foundIndex === 0 || foundIndex) {
            const newFiles: File[] = [];

            files.forEach((file, index) => {
                if (index !== foundIndex) {
                    newFiles.push(file);
                }
            });
            setFiles(newFiles.length ? newFiles : null);

            if (!newFiles.length) setIsFilesPopoverOpen(false);
        }
    }

    return (
        <div className="add-message-form">
            <div className="file-button">
                <IconButton
                    ref={fileAttachButtonRef}
                    color="default"
                    disabled={!IsConnect}
                >
                    <label
                        className="chat-label"
                        htmlFor="chat-file"
                    />
                    <AttachFileIcon />
                    {isLoadingUploadFiles && <Loader />}
                </IconButton>

                <input
                    className="file-input-chat"
                    type="file"
                    multiple
                    // TODO: File formats
                    accept="image/*,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xlsx"
                    onChange={(e) => handleInputFileChange(e.currentTarget.files)}
                    name="chat-file"
                    id="chat-file"
                />
            </div>

            <Popover
                open={isFilesPopoverOpen && !!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <div className="files_popover">
                    {files && (
                        <ListFiles
                            files={files}
                            deleteCallback={fileDeleteCallback}
                        />
                    )}
                    <div>Комментарий</div>
                    <TextField
                        sx={{ width: '100%' }}
                        multiline
                        maxRows={4}
                        value={comment}
                        placeholder="Введите комментарий..."
                        disabled={!IsConnect}
                        onChange={(e) => {
                            setComment(e.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && event.shiftKey) {
                                return;
                            }

                            if (event.key === 'Enter') {
                                event.preventDefault();
                                handleSendMessage();
                            }
                        }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                        <Button
                            variant="contained"
                            onClick={handleSendMessage}
                        >
                            Отправить
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Отмена
                        </Button>
                    </div>
                </div>
            </Popover>
            <div className="text-input-wrapper">
                <TextField
                    className="text-field"
                    multiline
                    maxRows={5}
                    value={text}
                    placeholder="Введите сообщение..."
                    disabled={!IsConnect}
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && event.shiftKey) {
                            return;
                        }

                        if (event.key === 'Enter') {
                            event.preventDefault();
                            handleSendMessage();
                        }
                    }}
                />
            </div>
            <IconButton
                className="send-button"
                color="primary"
                disabled={(!text && !files) || !IsConnect}
                onClick={handleSendMessage}
            >
                <SendIcon />
            </IconButton>
        </div>
    );
}

export default AddMessageForm;
