import { EPriceServicesDialogActions } from '../../enums/actions.enum';
import { IPriceServicesDialogState, TEditPricesServicesActions } from '../../interfaces/priceServicesDialog';

const initState: IPriceServicesDialogState = {
    dialogState: { isOpen: false, isEdit: false },
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

export const priceServicesDialogReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TEditPricesServicesActions,
): IPriceServicesDialogState => {
    switch (action.type) {
        case EPriceServicesDialogActions.SetIsOpen:
            return { ...state, dialogState: action.payload };
        case EPriceServicesDialogActions.SetFormValues:
            return { ...state, formValues: action.payload };
        case EPriceServicesDialogActions.SetErrors:
            return { ...state, errors: action.payload };
        default:
            return state;
    }
};
