import React from 'react';
import { useSelector } from 'react-redux';

import Login from './Login';
import RecoverPassword from './RecoverPassword';

import { ESignInTypes } from '../../enums/signIn.enum';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import { typeSignInSelector } from '../../store/selectors/signInSelectors';

function SignInPage() {
    const type = useSelector(typeSignInSelector);
    return (
        <AuthLayout>
            {(() => {
                switch (true) {
                    case type === ESignInTypes.Recover || type === ESignInTypes.AfterRecover:
                        return <RecoverPassword />;
                    default:
                        return <Login />;
                }
            })()}
        </AuthLayout>
    );
}

export default SignInPage;
