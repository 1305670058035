import { EHistoryActions } from '../../enums/actions.enum';
import { IHistoryState, TetHistoryActions } from '../../interfaces/history';

const initState: IHistoryState = {
    history: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const historyReducer = (state = initialState, action: TetHistoryActions): IHistoryState => {
    switch (action.type) {
        case EHistoryActions.SetHistory:
            return { ...state, history: action.payload };
        default:
            return { ...state };
    }
};
