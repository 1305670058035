import { Button, FormControlLabel, FormGroup, Paper, Radio } from '@mui/material';
import React, { MouseEvent } from 'react';

import { TCategoryPreset } from '../../../interfaces/issues';
import { CategoryPresetMock } from '../../../mockData/issues';
import './Directions.scss';

interface IProps {
    selectedCategoryPreset: TCategoryPreset | undefined | null;
    onChange: (categoryPreset: TCategoryPreset | null) => void;
}

function Directions({ selectedCategoryPreset, onChange }: IProps) {
    function onClickFormGroup(e: MouseEvent) {
        e.stopPropagation();
    }

    function clearFilter() {
        onChange(null);
    }

    return (
        <Paper className="directions-wrapper">
            <div className="scroll-wrapper">
                {CategoryPresetMock.map(({ value, label }) => (
                    <FormGroup
                        key={value}
                        sx={{ marginLeft: 1 }}
                        onClick={onClickFormGroup}
                    >
                        <FormControlLabel
                            control={
                                <Radio
                                    onChange={() => onChange(value)}
                                    value={value}
                                    checked={selectedCategoryPreset === value}
                                />
                            }
                            label={label}
                        />
                    </FormGroup>
                ))}
            </div>
            <Button
                fullWidth
                onClick={clearFilter}
            >
                Очистить фильтр
            </Button>
        </Paper>
    );
}

export default Directions;
