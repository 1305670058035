import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IBrand, IBrandDetail, TGetBrandsResp } from '../interfaces/brands';

export default class BrandsAPI {
    /* order_by: name */
    getBrands(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/brands${
                queries || '?page=1&size=100'
            }`,
            isAuthorization: true,
        }) as AxiosPromise<TGetBrandsResp>;
    }

    /* При увеличении количества параметров сменить на объект */
    createBrand(name: IBrand['name']) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/brands`,
            data: { name },
            isAuthorization: true,
        }) as AxiosPromise<IBrandDetail>;
    }
}
