import { EEditWorkerScheduleActions } from '../../../enums/actions.enum';
import { IEditWorkerScheduleState, TEditWorkerScheduleActions } from '../../../interfaces/edit/editWorkerSchedule';

const initState: IEditWorkerScheduleState = {
    isOpen: false,
    workerId: null,
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editWorkerScheduleReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TEditWorkerScheduleActions,
): IEditWorkerScheduleState => {
    switch (action.type) {
        case EEditWorkerScheduleActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditWorkerScheduleActions.SetWorkerId:
            return {
                ...state,
                workerId: action.payload,
            };
        case EEditWorkerScheduleActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? action.payload : null,
            };
        case EEditWorkerScheduleActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
