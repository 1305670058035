import { ECreateIssueReminderActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IFormValuesCreateIssueReminder,
    ISetErrorsCreateIssueReminderAction,
    ISetFormValuesCreateIssueReminderAction,
    ISetIsOpenCreateIssueReminderAction,
    ISetIssueIdCreateIssueReminderAction,
} from '../../../interfaces/create/createIssueReminder';
import { IIssue } from '../../../interfaces/issues';

export const setIsOpenCreateIssueReminderAction = (payload: boolean): ISetIsOpenCreateIssueReminderAction => ({
    type: ECreateIssueReminderActions.SetIsOpen,
    payload,
});

export const setIssueIdCreateIssueReminderAction = (
    payload: IIssue['id'] | null,
): ISetIssueIdCreateIssueReminderAction => ({
    type: ECreateIssueReminderActions.SetIssueId,
    payload,
});

export const setFormValuesCreateIssueReminderAction = (
    payload: IFormValuesCreateIssueReminder | null,
): ISetFormValuesCreateIssueReminderAction => ({
    type: ECreateIssueReminderActions.SetFormValues,
    payload,
});

export const setErrorsCreateIssueReminderAction = (payload: TErrors | null): ISetErrorsCreateIssueReminderAction => ({
    type: ECreateIssueReminderActions.SetErrors,
    payload,
});
