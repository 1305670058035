import { companiesApi } from 'api';
import { ERoutes } from 'enums/routes.enum';

import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { ICompany } from 'interfaces/companies';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ChoosePartner.scss';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const ChoosePartner: FC<Props> = ({ open, onClose }) => {
    const [partners, setPartners] = useState<ICompany[]>([]);
    const [partnerId, setPartnerId] = useState('');
    const [partnerName, setPartnerName] = useState('');

    const getCompanies = async () => {
        const { data } = await companiesApi.getCompanies();
        setPartners(data.filter((item) => item.name === 'DNS' || item.name === 'Haier'));
    };

    useEffect(() => {
        getCompanies();
    }, []);

    return (
        <Dialog
            open={open}
            maxWidth={'sm'}
            fullWidth
            onClose={onClose}
            className="dialog"
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6">Выбор партнера для импорта</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ paddingTop: '20px' }}>
                    <FormControl fullWidth>
                        <InputLabel>Партнер</InputLabel>
                        <Select
                            required
                            defaultValue={partners?.[0]?.partner_id}
                            label="Партнер"
                        >
                            {partners.map((item) => (
                                <MenuItem
                                    key={item.id}
                                    onClick={() => {
                                        setPartnerId(item.partner_id);
                                        setPartnerName(item.name);
                                    }}
                                    value={item.partner_id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ display: 'flex', marginTop: '20px', gap: '20px', justifyContent: 'end' }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Отмена
                    </Button>
                    <Link
                        to={`/${ERoutes.ImportIssues}/${partnerId || partners?.[0]?.partner_id}?partner_name=${
                            partnerName || partners?.[0]?.name
                        }`}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                        >
                            Перейти к импорту
                        </Button>
                    </Link>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
