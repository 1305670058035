import { EMapActions } from '../../enums/actions.enum';
import { EMapTabs } from '../../enums/map.enum';
import { IMapState, TMapActions } from '../../interfaces/map';

const initState: IMapState = {
    filters: null,
    isLoadingMap: false,
    areas: null,
    issues: null,
    workers: null,
    mapTab: EMapTabs.Areas,

    selectedIssues: null,
    selectedWorkers: null,
    selectedArea: null,
    //Начальное состояние задано, чтобы решить проблему с отцентрированием карты при первичном рендере
    pin: [37.57, 55.75],
    selectedPinIssue: null,
    selectedPinWorker: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const mapReducer = (state = initialState, action: TMapActions): IMapState => {
    switch (action.type) {
        case EMapActions.SetFilters:
            return {
                ...state,
                filters: action.payload,
            };
        case EMapActions.SetIsLoading:
            return {
                ...state,
                isLoadingMap: action.payload,
            };
        case EMapActions.SetAreas:
            return {
                ...state,
                areas: action.payload,
            };
        case EMapActions.SetIssues:
            return {
                ...state,
                issues: action.payload,
            };
        case EMapActions.SetWorkers:
            return {
                ...state,
                workers: action.payload,
            };
        case EMapActions.SetMapTab:
            return {
                ...state,
                mapTab: action.payload,
            };
        case EMapActions.SetSelectedIssues:
            return {
                ...state,
                selectedIssues: action.payload,
            };
        case EMapActions.SetSelectedWorkers:
            return {
                ...state,
                selectedWorkers: action.payload,
            };
        case EMapActions.SetPin:
            return {
                ...state,
                pin: action.payload,
            };
        case EMapActions.SetSelectedPinIssue:
            return {
                ...state,
                selectedPinIssue: action.payload,
            };
        case EMapActions.SetSelectedPinWorker:
            return {
                ...state,
                selectedPinWorker: action.payload,
            };
        case EMapActions.SetSelectedArea:
            return {
                ...state,
                selectedArea: action.payload,
            };
        default:
            return state;
    }
};
