import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, IconButton } from '@mui/material';
import { common } from '@mui/material/colors';
import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';

import NotificationsTopBar from './Notifications';
import ProfileTopBar from './Profile';
import SearchTopBar from './Search';
import { useStyles } from './TopBarStyles';

import './TopBarStyles.scss';
import mainLogoSVG from '../../../static/images/mainLogo.svg';
import shortLogoSVG from '../../../static/images/shortLogo.svg';
import { setIsOpenSideBarAction } from '../../../store/actions/sideBar';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenSideBarSelector } from '../../../store/selectors/sideBarSelectors';

function TopBar() {
    const dispatch = useAppDispatch();
    const styles = useStyles();
    const isOpenSideBar = useSelector(isOpenSideBarSelector);

    return (
        <Box className={'top-bar'}>
            <Box className={cn('tb-logo-wrapper', { 'side-is-open': isOpenSideBar })}>
                <img
                    src={mainLogoSVG}
                    alt=""
                    className={cn('main-logo', { 'side-is-open': isOpenSideBar })}
                />
                <img
                    src={shortLogoSVG}
                    alt=""
                    className={cn('short-logo', { 'side-is-open': isOpenSideBar })}
                />
            </Box>
            <Box>
                <IconButton
                    onClick={() => {
                        dispatch(setIsOpenSideBarAction(!isOpenSideBar));
                    }}
                    className={styles.openSideBarBtn}
                    aria-label="upload picture"
                    component="label"
                    size="small"
                >
                    <ArrowBackIcon
                        style={{
                            color: common.white,
                            rotate: `${isOpenSideBar ? '0deg' : '180deg'}`,
                            transition: 'all easy 300ms',
                        }}
                    />
                </IconButton>
            </Box>
            <Box className="search-wrapper">
                <SearchTopBar />
            </Box>
            <Box className="notifications-wrapper">
                <NotificationsTopBar />
            </Box>
            <Box className="profile-wrapper">
                <ProfileTopBar />
            </Box>
        </Box>
    );
}

export default TopBar;
