import { EPricesActions } from '../../enums/actions.enum';
import { EPricesTabs } from '../../enums/prices.enum';
import { IPricesState, TPricesActions } from '../../interfaces/prices';

const initState: IPricesState = {
    prices: null,
    price: null,
    filters: null,
    isPriceFileLoading: false,
    pricesTab: EPricesTabs.Services,
    pagination: { page: 0, size: 50, total: 0 },
    isPriceLoading: false,
    isOpenDeletePrice: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const pricesReducer = (state = initialState, action: TPricesActions): IPricesState => {
    switch (action.type) {
        case EPricesActions.SetPrices:
            return {
                ...state,
                prices: action.payload,
            };
        case EPricesActions.SetPrice:
            return {
                ...state,
                price: action.payload,
            };

        case EPricesActions.SetFilters:
            return {
                ...state,
                filters: action.payload,
            };
        case EPricesActions.SetIsPriceFileLoading:
            return {
                ...state,
                isPriceFileLoading: action.payload,
            };
        case EPricesActions.SetPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        case EPricesActions.SetIsPriceLoading:
            return {
                ...state,
                isPriceLoading: action.payload,
            };
        case EPricesActions.SetIsOpenDeletePrice:
            return {
                ...state,
                isOpenDeletePrice: action.payload,
            };
        default:
            return state;
    }
};
