import { EChatGroupByUserActions } from '../../enums/actions.enum';
import { IChatGroupByUserState, TChatGroupByUserActions } from '../../interfaces/chatGroupByUsers';

const initState: IChatGroupByUserState = {
    detail: null,
    filters: {
        has_unread_message: false,
        chat_type: null,
    },
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const chatGroupByUserReducer = (state = initialState, action: TChatGroupByUserActions) => {
    switch (action.type) {
        case EChatGroupByUserActions.SetChatGroupByUserDetail:
            return {
                ...state,
                detail: action.payload,
            };
        case EChatGroupByUserActions.UpdateChatGroupByUserDetail:
            const user = action.payload.find(
                (item) => item.user_id === state.detail?.user_id && item.user_type === state.detail?.user_type,
            );

            if (!user) {
                return state;
            }
            return {
                ...state,
                detail: user,
            };
        case EChatGroupByUserActions.SetChatGroupByUserFilters:
            return {
                ...state,
                filters: action.payload,
            };
        default:
            return state;
    }
};
