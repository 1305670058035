import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { getJWTHelper } from './getJwtHelper';

import { ELocalStorageItems } from '../enums/localStorage.enum';

export interface IConfigFetch extends AxiosRequestConfig {
    errorCallback?: (error: AxiosError) => void;
    isAuthorization?: boolean;
    Accept?: string;
}

export const storageTokenName = ELocalStorageItems.token;

const requiredHeaders = {
    'x-request-id': Math.random(),
    'x-service-name': 'mytech-admin-ui',
};

export default async function axiosFetch(config: IConfigFetch) {
    let newConfig = { ...config };

    const { isAuthorization } = config;

    const token = localStorage.getItem(storageTokenName);

    if (isAuthorization && token) {
        newConfig = {
            ...config,
            headers: {
                ...config?.headers,
                authorization: `Bearer ${getJWTHelper()}`,
            },
        };
    }

    return axios({
        ...newConfig,
        headers: {
            ...newConfig.headers,
            ...requiredHeaders,
        },
        paramsSerializer: {
            indexes: null,
        },
    }).catch((error: AxiosError) => {
        const status = error?.response?.status;
        if (config.errorCallback) {
            config?.errorCallback?.(error);
        }
        if (isAuthorization && (status === 401 || status === 403)) {
            //authProvider.logout(null)
        }
        if (!localStorage.token) {
            //authProvider.logout(null)
        }
        throw error;
    });
}
