import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../../components/inputs/TextField';
import { setFormValuesPriceDialogAction } from '../../../../../store/actions/priceDialog';
import { useAppDispatch } from '../../../../../store/hooks';
import { formValuesPriceDialogSelector } from '../../../../../store/selectors/priceDialogSelector';
import './PriceDescriptions.scss';

function PriceDescriptions() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesPriceDialogSelector);

    function onChangeIsActiveOnWidget(_: React.SyntheticEvent<Element, Event>, checked: boolean) {
        dispatch(setFormValuesPriceDialogAction({ is_active_on_widget: checked }));
    }

    function onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesPriceDialogAction({ description: event.target.value }));
    }

    return (
        <div>
            <FormControlLabel
                control={<Checkbox checked={formValues?.is_active_on_widget || false} />}
                label="Отображать услугу на виджете"
                labelPlacement="end"
                onChange={onChangeIsActiveOnWidget}
            />
            <FormControl
                margin="dense"
                fullWidth
            >
                <div className="price-description-counter">{`${formValues?.description?.length || 0}/500`}</div>
                <TextField
                    fullWidth
                    name={'price_description'}
                    labelText={'Описание услуги'}
                    onChange={onChangeDescription}
                    params={{ multiline: true, minRows: 3, inputProps: { maxLength: 500 } }}
                    value={formValues?.description || ''}
                />
            </FormControl>
        </div>
    );
}

export default PriceDescriptions;
