import { ECreateWorkerMoySkladActions } from '../../../enums/actions.enum';
import { ICreateWorkerMoySkladState, TCreateWorkerMoySkladActions } from '../../../interfaces/create/createMoySklad';

const initState: ICreateWorkerMoySkladState = {
    isOpen: false,
    workerId: null,
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

export const createWorkerMoySkladReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TCreateWorkerMoySkladActions,
): ICreateWorkerMoySkladState => {
    switch (action.type) {
        case ECreateWorkerMoySkladActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
                formValues: null,
            };
        case ECreateWorkerMoySkladActions.SetWorkerId:
            return {
                ...state,
                workerId: action.payload,
            };
        case ECreateWorkerMoySkladActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case ECreateWorkerMoySkladActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };

        default:
            return state;
    }
};
