import { Polygon } from '@pbe/react-yandex-maps';
import React from 'react';

import { IArea, ISelectedArea } from '../../../../../interfaces/map';

interface IProps {
    areas: IArea[] | null;
    onClick: (index: number) => void;
    selectedArea: ISelectedArea | null;
}

function AreasMultiPolygons({ areas, onClick, selectedArea }: IProps) {
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {areas?.map((data, index) => {
                return data?.geo?.coordinates?.map((coord, i) => {
                    return (
                        <Polygon
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={`${data.id}_${i}`}
                            onClick={() => {
                                onClick(index);
                            }}
                            geometry={coord}
                            options={{
                                fillColor: selectedArea && selectedArea?.id === data.id ? '#042f94' : '#00aeac',
                                strokeColor: '#006237',
                                opacity: 0.5,
                                strokeWidth: 2,
                                strokeStyle: 'shortdash',
                            }}
                        />
                    );
                });
            })}
        </>
    );
}

export default AreasMultiPolygons;
