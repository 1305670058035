import { servicePriceListsApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import { fetchServicesComplex, setFilters } from 'pages/price-lists/store';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';

import { Search } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, InputAdornment, Table, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { Cell, CellInputProps, ControlsWrapper, HeadCell, ListItem, StyledButton, StyledPopover } from './styles';

import { priceListsSelector } from '../../store/selectors';
import { MuiDivider } from '../styles';

const filterItems = [
    { value: 1, title: 'Отобразить услуги без указанной цены' },
    { value: 2, title: 'Отобразить услуги без указанной комиссии мастера' },
    { value: 3, title: 'Отобразить услуги без цены и комиссии' },
    { value: 4, title: 'Отобразить услуги без цены или комиссии' },
];

export const PriceListComplexesServicesPage = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { priceListId } = useParams<{ priceListId: string }>();

    const { servicesComplex } = useSelector(priceListsSelector);

    const [isOpen, setOpen] = useState<any>(false);

    const handleChangeFilter = (value: any) => {
        if (value === 1) dispatch(setFilters({ amount_not_equal: 1 }));
        if (value === 2) dispatch(setFilters({ commission_not_equal: 1 }));
        if (value === 3) dispatch(setFilters({ has_not_amount_and_commission: true }));
        if (value === 4) dispatch(setFilters({ has_not_amount_or_commission: true }));
        dispatch(fetchServicesComplex(priceListId));
    };

    const handleClearFilters = () => {
        dispatch(setFilters({})); // !!
    };

    const handleDelete = (id: string) => {
        servicePriceListsApi
            .deleteServicesComplex(id)
            .then(() => {
                enqueueSnackbar('Удаление успешно выполнено', { variant: 'success' });
                dispatch(fetchServicesComplex(priceListId));
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    const handleEdit = (id: string, data: any) => {
        servicePriceListsApi
            .editServicesComplex(id, data)
            .then(() => {
                enqueueSnackbar('Изменение успешно выполнено', { variant: 'success' });
                dispatch(fetchServicesComplex(priceListId));
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    const filterSearch = (search: string) => {
        dispatch(setFilters({ search }));
        dispatch(fetchServicesComplex(priceListId));
    };

    const filterSearchWithDebounced = useDebouncedCallback(filterSearch, 800);

    useEffect(() => {
        dispatch(fetchServicesComplex(priceListId));
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <Button
                variant="contained"
                sx={{ position: 'absolute', right: '0', top: '-43px' }}
            >
                <Link
                    to={`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}/${ERoutes.AddComplexServices}`}
                >
                    Добавить
                </Link>
            </Button>
            <MuiDivider />
            <ControlsWrapper>
                <TextField
                    placeholder="Поиск по наименованию"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => filterSearchWithDebounced(e.target.value)}
                />
                <TextField
                    placeholder="Поиск по категории"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant={isOpen ? 'filterActive' : 'filter'}
                    onClick={(e) => setOpen(e.currentTarget)}
                    endIcon={<ExpandMoreIcon />}
                >
                    Фильтр по цене
                </Button>
                <StyledPopover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setOpen(false)}
                >
                    {filterItems.map((item) => (
                        <ListItem
                            key={item.title}
                            onClick={() => handleChangeFilter(item.value)}
                        >
                            <div>{item.title}</div>
                        </ListItem>
                    ))}
                    <StyledButton onClick={handleClearFilters}>Очистить фильтр</StyledButton>
                </StyledPopover>
            </ControlsWrapper>
            <Table>
                <TableHead>
                    <TableRow>
                        <HeadCell>НАИМЕНОВАНИЕ</HeadCell>
                        <HeadCell align="center">ЦЕНА</HeadCell>
                        <HeadCell
                            align="center"
                            sx={{ width: '170px' }}
                        >
                            КОМИССИЯ МАСТЕРА
                        </HeadCell>
                        <HeadCell align="center">УСЛУГИ</HeadCell>
                        <HeadCell
                            align="center"
                            sx={{ width: '230px' }}
                        >
                            ДОСТУПЕН В API ИНТЕГРАЦИИ
                        </HeadCell>
                        <HeadCell
                            align="center"
                            sx={{ width: '220px' }}
                        >
                            ВНЕШНИЙ ИДЕНТИФИКАТОР
                        </HeadCell>
                        <HeadCell align="center" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {servicesComplex?.map((item: any) => {
                        return (
                            <TableRow
                                key={item.id}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                    navigate(
                                        `/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}/${item.id}/${ERoutes.EditComplexServices}`,
                                    );
                                }}
                            >
                                <Cell>{item.name}</Cell>
                                <Cell align="center">
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        customInput={TextField}
                                        suffix=" ₽"
                                        value={item.amount}
                                        onBlur={(e) => {
                                            handleEdit(item.id, { amount: e.target.value.slice(0, -2) });
                                        }}
                                    />
                                </Cell>
                                <Cell align="center">
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        customInput={TextField}
                                        suffix=" ₽"
                                        value={item.commission.commission_value}
                                        onBlur={(e) => {
                                            handleEdit(item.id, { commission: { value: e.target.value.slice(0, -2) } });
                                        }}
                                    />
                                </Cell>
                                <Cell align="center">Какой-то список</Cell>
                                <Cell align="center">{item.is_active_integration ? 'Да' : 'Нет'}</Cell>
                                <Cell align="center">{item.external_id}</Cell>
                                <Cell
                                    onClick={() => handleDelete(item.id)}
                                    sx={{ paddingLeft: 0, cursor: 'pointer' }}
                                >
                                    <DeleteBasket />
                                </Cell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};
