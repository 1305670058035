import { Paper, Table, TableBody } from '@mui/material';
import React, { FunctionComponent } from 'react';

import PricesServicesTableHead from './PricesServicesTableHead';

import Loader from '../../../../components/Loader';
import Plug from '../../../../components/Plug';
import { IPricesTableHead } from '../../../../interfaces/prices';
import './PricesServices.scss';

interface IProps<T> {
    tableHead: IPricesTableHead[] | null;
    ComponentRow: FunctionComponent<T>;
    tableContent: T[] | null;
    isLoading: boolean;
}

function PricesServicesTable<T>({ tableHead, ComponentRow, tableContent, isLoading }: IProps<T>) {
    return (
        <Paper
            className={'prices-services-table-wrapper'}
            sx={{ marginTop: '25px' }}
        >
            {(() => {
                switch (true) {
                    case isLoading:
                        return <Loader />;
                    case !!tableContent && !!tableContent?.length:
                        return (
                            <Table
                                stickyHeader
                                size={'small'}
                                sx={{ background: 'white' }}
                            >
                                <PricesServicesTableHead tableHead={tableHead} />
                                <TableBody>
                                    {tableContent &&
                                        tableContent.map((rowContent: T & any) => {
                                            return (
                                                <ComponentRow
                                                    key={rowContent.id}
                                                    {...rowContent}
                                                />
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        );
                    default:
                        return <Plug />;
                }
            })()}
        </Paper>
    );
}

export default PricesServicesTable;
