let debounceTimer: any = null;
let resetTimer: any = null;
export interface IParamsDebounceTimer {
    callback: () => void;
    delay?: number;
    reset?: number;
}

export const debounceTimerHelper = ({ callback, reset, delay = 1000 }: IParamsDebounceTimer): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (reset && resetTimer !== reset) {
            clearTimeout(debounceTimer);
            resetTimer = null;
            resolve();
        }
        if (debounceTimer) clearTimeout(debounceTimer);
        debounceTimer = setTimeout(async () => {
            try {
                await callback();
                resolve();
            } catch (err: any) {
                console.error(err);
                reject(Error(err));
            }
        }, delay);
    });
};
