import { priceListsApi, servicePriceListsApi } from 'api';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/pagination';

import { TFilters, TPriceList, TState } from './types';

export const initialState: TState = {
    priceLists: [],
    priceList: null,

    servicesComplex: [],
    serviceComplex: null,

    descendants: [],

    services: [],

    filters: {
        search: '',
        has_not_amount_and_commission: null,
        has_not_amount_or_commission: null,
        commission_not_equal: null,
        amount_not_equal: null,
        pk__in: [],
        service_type_id__in: [],
        company_id__in: [],
        region_id__in: [],
        is_active: null,
    },

    pagination: {
        page: 1,
        size: 25,
        total: 0,
    },

    serviceComplexLoading: false,
    loading: false,
};

const slice = createSlice({
    name: 'priceLists',
    initialState,
    reducers: (create) => ({
        setPriceLists: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.priceLists = payload;
        }),

        setPriceList: create.reducer((state, { payload }: PayloadAction<TPriceList | null>) => {
            state.priceList = payload;
        }),

        fetchPriceLists: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { pagination, filters } = (getState() as RootState).priceLists;

                const params = getFilledParams({ size: pagination.size, page: pagination.page, ...filters });

                const { data } = await priceListsApi.getPriceLists(params);

                dispatch(setPagination({ total: data.total }));

                dispatch(setPriceLists(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        fetchPriceList: create.asyncThunk<void, string>(
            async (id: string, { dispatch }) => {
                const { data } = await priceListsApi.getPriceList(id);

                dispatch(setPriceList(data));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        fetchServicesComplex: create.asyncThunk(
            async (id, { dispatch, getState }) => {
                console.log('saad', id);

                const { filters } = (getState() as RootState).priceLists;

                const params = getFilledParams({
                    with_paginate: true,
                    price_list_id: id,
                    ...filters,
                });

                const { data } = await servicePriceListsApi.getServicesComplex(params);

                dispatch(setServicesComplex(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),
        setServicesComplex: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.servicesComplex = payload;
        }),

        fetchServiceComplex: create.asyncThunk(
            async (id: string, { dispatch }) => {
                const { data } = await servicePriceListsApi.getServiceComplex(id);

                dispatch(setServiceComplex(data));
            },
            {
                pending: (state) => {
                    state.serviceComplexLoading = true;
                },
                settled: (state) => {
                    state.serviceComplexLoading = false;
                },
            },
        ),
        setServiceComplex: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.serviceComplex = payload;
        }),

        setPagination: create.reducer((state, { payload }: PayloadAction<Partial<IPagination>>) => {
            state.pagination = { ...state.pagination, ...payload };
        }),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.pagination = { ...initialState.pagination, size: state.pagination.size };
            state.filters = { ...state.filters, ...(payload as any) };
        }),
    }),
});

export const {
    fetchPriceLists,
    fetchPriceList,
    fetchServicesComplex,
    fetchServiceComplex,
    setPriceLists,
    setPriceList,
    setServicesComplex,
    setPagination,
    setFilters,
    setServiceComplex,
} = slice.actions;

export default slice.reducer;
