import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ createWorkerMoySklad }: RootState) => createWorkerMoySklad;

export const isOpenCreateMoySkladSelector = createSelector(state, ({ isOpen }) => isOpen);
export const workerIdCreateWorkerMoySkladSelector = createSelector(state, ({ workerId }) => workerId);
export const formValuesCreateMoySkladSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsCreateMoySkladSelector = createSelector(state, ({ errors }) => errors);
