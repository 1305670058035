import { Params } from 'types';

export const getFilledParams = (params: Params) => {
    const result: any = {};

    for (const [key, value] of Object.entries(params)) {
        if (isFilledParam(value)) {
            result[key] = value;
        }
    }

    return result;
};

const isFilledParam = (value: unknown) => {
    return value !== null && value !== undefined && value !== '';
};
