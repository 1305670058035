import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IIssue } from '../interfaces/issues';
import { IIssuesObserveReq } from '../interfaces/issuesObserves';

export default class IssuesObserversAPI {
    /* Создание наблюдения за заявкой */
    addObserver(issueId: IIssue['id'], request: IIssuesObserveReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/observers/${issueId}`,
            data: request,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }

    /* Отмена наблюдения за заявкой */
    deleteObserver(issueId: IIssue['id'], request: IIssuesObserveReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/observers/${issueId}/delete`,
            data: request,
            isAuthorization: true,
        }) as AxiosPromise<IIssue>;
    }
}
