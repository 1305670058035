import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ websockets }: RootState) => websockets;

export const WSChannelSelector = createSelector(state, ({ channel }) => channel);

/* История событий в каналах centrifuge см. src/utils/centrifugeDistributor.tsx */
export const historySelector = createSelector(state, ({ history }) => history);
export const historyCounterSelector = createSelector(state, ({ history }) => {
    let counter = 0;
    history?.forEach(({ seen }) => {
        if (!seen) {
            counter += 1;
        }
    });
    return counter;
});
