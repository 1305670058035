import { ECreateIssueActions } from '../../../enums/actions.enum';
import { ICreateIssueState, TCreateIssueActions } from '../../../interfaces/create/createIssue';

const initState: ICreateIssueState = {
    formValues: null,
    selectedPrices: null,
    errors: null,
    isOpen: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const createIssueReducer = (state = initialState, action: TCreateIssueActions): ICreateIssueState => {
    switch (action.type) {
        case ECreateIssueActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case ECreateIssueActions.SetSelectedPrices:
            return {
                ...state,
                selectedPrices: action.payload,
            };
        case ECreateIssueActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case ECreateIssueActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        default:
            return state;
    }
};
