import { ChatItemHeader } from 'components/Chat/ChatItemHeader';
import ChatsInfinite from 'modules/ChatsInfiniteModule';
import { chatResetAction } from 'store/actions/chat';
import { setChatGroupByUserAction } from 'store/actions/chatGroupByUser';
import { useAppDispatch } from 'store/hooks';
import { chatGroupByUserFiltersSelector, chatGroupByUserSelector } from 'store/selectors/chatGroupByUserSelectors';

import { styled } from '@mui/material';
import { useSelector } from 'react-redux';

const ChatListInfiniteWrapperDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
});

export function UsersList() {
    const dispatch = useAppDispatch();
    const item = useSelector(chatGroupByUserSelector);
    const filters = useSelector(chatGroupByUserFiltersSelector);

    function onClick() {
        dispatch(setChatGroupByUserAction(null));
        dispatch(chatResetAction());
    }

    return (
        item && (
            <ChatListInfiniteWrapperDiv>
                <ChatItemHeader
                    item={item}
                    onClick={onClick}
                />
                <ChatsInfinite
                    filters={{
                        user_id: item.user_id,
                        user_type: item.user_type,
                        ...filters,
                    }}
                />
            </ChatListInfiniteWrapperDiv>
        )
    );
}
