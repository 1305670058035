import { AppDispatch } from 'store/store';

import { partsApi } from '../../api';
import { EPartsActions } from '../../enums/actions.enum';
import { IFormValuesCreatePart } from '../../interfaces/create/createPart';
import { IPagination } from '../../interfaces/pagination';
import {
    IGetPartsParams,
    IPart,
    IPartsFilters,
    ISetPartAction,
    ISetPartFiltersAction,
    ISetPartPaginationAction,
    ISetPartsAction,
} from '../../interfaces/parts';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const setPartsAction = (payload: IPart[] | null): ISetPartsAction => ({
    type: EPartsActions.SetParts,
    payload,
});

export const setPartAction = (payload: IPart | null): ISetPartAction => ({
    type: EPartsActions.SetPart,
    payload,
});

export const setPartFiltersAction = (payload: IPartsFilters | null): ISetPartFiltersAction => ({
    type: EPartsActions.SetPartFilters,
    payload,
});

export const setPartPaginationAction = (payload: IPagination): ISetPartPaginationAction => ({
    type: EPartsActions.SetPartPagination,
    payload,
});

export const getPartsThunk =
    ({ filters, page, size, parts }: IGetPartsParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [{ page: page || 1 }, { size: size || 20 }, { with_paginate: true }];

            if (filters) {
                const { category_id__in, search } = filters;
                category_id__in?.forEach((categoryId) => {
                    queriesObj.push({ category_id__in: categoryId });
                });

                if (search) {
                    queriesObj.push({ search });
                }
            }
            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await partsApi.getParts(queries);
            const { items, page: pageResp, size: sizeResp, total } = data;

            if (parts) {
                dispatch(setPartsAction([...parts, ...items]));
            } else {
                dispatch(setPartsAction(items));
            }

            dispatch(setPartPaginationAction({ page: pageResp, size: sizeResp, total }));
        } catch (err) {
            console.log(err);
            throw err;
        }
    };

interface createPartsThunkParams {
    data: IFormValuesCreatePart;
}

export const createPartsThunk =
    ({ data }: createPartsThunkParams) =>
    async () => {
        try {
            await partsApi.createParts(data);
        } catch (err) {
            console.log(err);
            throw err;
        }
    };
