import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TErrors } from '../../../../../interfaces';
import {
    setErrorsPriceServicesDialogAction,
    setIsOpenPriceServicesDialogAction,
} from '../../../../../store/actions/priceServicesDialog';
import {
    createServiceThunk,
    getServicesThunk,
    setPriceServiceAction,
    updateServiceThunk,
} from '../../../../../store/actions/pricesServices';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsPriceServicesDialogSelector,
    formValuesPriceServicesDialogSelector,
} from '../../../../../store/selectors/priceServicesDialogSelectors';
import { priceServiceSelector, priceServicesSelector } from '../../../../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import showToast from '../../../../../utils/helpers/showToast';

interface IProp {
    isEdit: boolean;
}

function FooterForm({ isEdit }: IProp) {
    const dispatch = useAppDispatch();

    const services = useSelector(priceServicesSelector);
    const service = useSelector(priceServiceSelector);
    const formValues = useSelector(formValuesPriceServicesDialogSelector);
    const errors = useSelector(errorsPriceServicesDialogSelector);

    const { id: serviceId } = service || {};

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.name) {
            errs.name = { message: 'Обязательное поле' };
        }

        dispatch(setErrorsPriceServicesDialogAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        setIsBtnDisabled(!formValues);
    }, [formValues]);

    async function updateServiceHandler() {
        if (!serviceId || !formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                await dispatch(updateServiceThunk(serviceId, formValues));
                await (services && dispatch(getServicesThunk()));
                dispatch(setIsOpenPriceServicesDialogAction({ isEdit: true, isOpen: false }));
                showToast({
                    isVisible: true,
                    message: 'Изменения сохранены',
                });
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    async function createServiceHandler() {
        if (!formValues) return;
        try {
            if (validator()) {
                setIsLoading(true);
                await dispatch(createServiceThunk(formValues));
                await dispatch(getServicesThunk());
                dispatch(setPriceServiceAction(null));
                dispatch(setIsOpenPriceServicesDialogAction({ isEdit: false, isOpen: false }));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    async function submitFormHandler() {
        switch (isEdit) {
            case true:
                await updateServiceHandler();
                break;
            default:
                await createServiceHandler();
                break;
        }
    }

    return (
        <Box mt={2}>
            <Button
                onClick={() => dispatch(setIsOpenPriceServicesDialogAction({ isEdit: false, isOpen: false }))}
                variant="outlined"
            >
                Отмена
            </Button>

            <LoadingButton
                onClick={submitFormHandler}
                disabled={isBtnDisabled}
                loading={isLoading}
                variant="contained"
                sx={{ ml: 1 }}
            >
                {isEdit ? 'Сохранить' : 'Создать'}
            </LoadingButton>
        </Box>
    );
}

export default FooterForm;
