import Accordion from 'components/Accordion';
import AudioFiles from 'components/AudioFiles';
import { issueSelector } from 'store/selectors/issuesSelectors';

import { Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Reason, ReasonsWrapper, Wrapper } from './styles';

type Props = {
    issueStatus: string;
};

export const ReasonRefusal: FC<Props> = ({ issueStatus }) => {
    const issue = useSelector(issueSelector);

    console.log(1, issueStatus);

    return (
        <Accordion
            title={<Typography>Причина отказа</Typography>}
            sx={{ mt: 3 }}
            expanded={false}
        >
            <Wrapper>
                <ReasonsWrapper>
                    <span>Отменил</span>
                    <Reason>{issue?.canceled_by}</Reason>
                </ReasonsWrapper>
                <ReasonsWrapper>
                    <span>Причины отмены</span>
                    <Reason>{issue?.cancel_reasons}</Reason>
                </ReasonsWrapper>
                <ReasonsWrapper>
                    <span>Комментарий</span>
                    <Reason>{issue?.cancel_comment}</Reason>
                </ReasonsWrapper>
                <ReasonsWrapper>
                    <span>Аудиофайлы</span>
                    <AudioFiles audioFiles={issue?.cancel_audio_files} />
                </ReasonsWrapper>
            </Wrapper>
        </Accordion>
    );
};
