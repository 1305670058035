import { AppDispatch } from 'store/store';

import { reminderApi } from '../../api';
import { EReminderActions } from '../../enums/actions.enum';
import { IFormValuesCreateIssueReminder } from '../../interfaces/create/createIssueReminder';
import { IFormValuesEditIssueReminder } from '../../interfaces/edit/editReminder';
import { IIssue } from '../../interfaces/issues';
import {
    IReminder,
    ISetIsOpenDeleteReminderAction,
    ISetReminderAction,
    ISetRemindersAction,
} from '../../interfaces/reminder';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const setRemindersActions = (payload: IReminder[] | null): ISetRemindersAction => ({
    type: EReminderActions.SetReminders,
    payload,
});

export const setReminderActions = (payload: IReminder | null): ISetReminderAction => ({
    type: EReminderActions.SetReminder,
    payload,
});

export const setIsOpenDeleteReminderActions = (payload: boolean): ISetIsOpenDeleteReminderAction => ({
    type: EReminderActions.SetIsOpenDelete,
    payload,
});

export const getIssueRemindersThunk = (issueId: IIssue['id']) => async (dispatch: AppDispatch) => {
    try {
        const queriesObj: TArrayParams = [{ is_send: false }];
        const queries = getQueryParamsAsString(queriesObj);

        const { data } = await reminderApi.getIssueReminders(issueId, queries);
        const { items } = data;
        dispatch(setRemindersActions(items));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getIssueReminderThunk = (reminderId: IReminder['id']) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await reminderApi.getIssueReminder(reminderId);
        dispatch(setReminderActions(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const createIssueReminderThunk = (issueId: string, formValues: IFormValuesCreateIssueReminder) => async () => {
    try {
        const { data } = await reminderApi.createIssueReminder(issueId, {
            datetime_delivery: new Date(
                formValues.date_delivery!.setHours(
                    formValues.time_delivery!.getHours(),
                    formValues.time_delivery!.getMinutes(),
                ),
            ).toISOString(),
            // TODO: в старой адмике отправляется всегда null - убрать, если не нужно
            remind_in_minute: null,
            send_only_bo_user_created: formValues.send_only_bo_user_created || false,
            text: formValues.text!,
        });
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const deleteIssueReminderThunk = (reminderId: IReminder['id']) => async () => {
    try {
        const { data } = await reminderApi.deleteIssueReminder(reminderId);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const editIssueReminderThunk =
    (reminderId: IReminder['id'], formValues: IFormValuesEditIssueReminder) => async () => {
        try {
            const { data: reminder } = await reminderApi.editIssueReminder(reminderId, {
                datetime_delivery: new Date(
                    formValues.date_delivery!.setHours(
                        formValues.time_delivery!.getHours(),
                        formValues.time_delivery!.getMinutes(),
                    ),
                ).toISOString(),
                // TODO: в старой адмике отправляется всегда null - убрать, если не нужно
                remind_in_minute: null,
                send_only_bo_user_created: formValues.send_only_bo_user_created || false,
                text: formValues.text!,
                is_active: true,
            });
            return reminder;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
