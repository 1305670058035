import src from 'static/images/emptyImg.png';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton } from '@mui/material';

import './ListFiles.scss';

interface IProps {
    files: File[];
    deleteCallback: (file: File) => void;
}

function ListFiles({ files, deleteCallback }: IProps) {
    return files.length ? (
        <div className="files-wrapper">
            <div className="files-counter">Загружено файлов: {files.length}</div>
            {files.map((file) => {
                const fileName = file?.name.length > 20 ? `${file?.name.slice(0, 20)}...` : file?.name;
                return (
                    <div
                        className="file-chat"
                        key={fileName}
                    >
                        <div className="file-data">
                            <object
                                className="file-preview"
                                data={URL.createObjectURL(file)}
                                type="image/png"
                            >
                                <img
                                    className="file-preview"
                                    src={src}
                                    alt="preview"
                                />
                            </object>
                            <div
                                className="file-name"
                                title={fileName}
                            >
                                {fileName}
                            </div>
                            <IconButton
                                className="delete-document-btn"
                                onClick={() => {
                                    file && deleteCallback(file);
                                }}
                                sx={{ marginLeft: 'auto' }}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </div>
                    </div>
                );
            })}
        </div>
    ) : null;
}

export default ListFiles;
