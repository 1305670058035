import { workersApi } from '../../../api';
import { EEditWorkerSpecializationsActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IFormValuesEditWorkerSpecializations,
    ISetErrorsEditWorkerSpecializations,
    ISetFormValuesEditWorkerSpecializations,
    ISetIsOpenEditWorkerSpecializations,
    ISetWorkerIdEditWorkerSpecializations,
} from '../../../interfaces/edit/editWorkerSpecializations';
import { IWorkerDetail } from '../../../interfaces/workers';

export const setIsOpenEditWorkerSpecializationsAction = (payload: boolean): ISetIsOpenEditWorkerSpecializations => ({
    type: EEditWorkerSpecializationsActions.SetIsOpen,
    payload,
});

export const setWorkerIdEditWorkerSpecializationsAction = (
    payload: IWorkerDetail['id'] | null,
): ISetWorkerIdEditWorkerSpecializations => ({
    type: EEditWorkerSpecializationsActions.SetWorkerId,
    payload,
});

export const setFormValuesEditWorkerSpecializationsAction = (
    payload: IFormValuesEditWorkerSpecializations[] | null,
): ISetFormValuesEditWorkerSpecializations => ({
    type: EEditWorkerSpecializationsActions.SetFormValues,
    payload,
});

export const setErrorsEditWorkerSpecializationsAction = (
    payload: TErrors | null,
): ISetErrorsEditWorkerSpecializations => ({
    type: EEditWorkerSpecializationsActions.SetErrors,
    payload,
});

export const editWorkerSpecializationsThunk = (
    workerId: string,
    formValues: IFormValuesEditWorkerSpecializations[],
) => {
    return async () => {
        try {
            const { data } = await workersApi.editWorkerSpecializations(workerId, {
                specializations: formValues || [],
            });
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
