import { AppDispatch } from 'store/store';

import { pricesApi } from '../../api';
import { EPricesServicesActions } from '../../enums/actions.enum';
import { EPricesServicesTabs } from '../../enums/pricesServices.enum';
import { IFormValuesPriceServicesDialog } from '../../interfaces/priceServicesDialog';
import {
    IPriceService,
    ISetIsOpenDeletePricesServicesAction,
    ISetIsServiceLoadingAction,
    ISetPricesServiceAction,
    ISetPricesServicesAction,
    ISetPricesServicesTabAction,
} from '../../interfaces/pricesServices';

export const setPriceServicesAction = (payload: IPriceService[] | null): ISetPricesServicesAction => ({
    type: EPricesServicesActions.SetServices,
    payload,
});

export const setPriceServiceAction = (payload: IPriceService | null): ISetPricesServiceAction => ({
    type: EPricesServicesActions.SetService,
    payload,
});

export const setPriceServicesTabAction = (payload: EPricesServicesTabs): ISetPricesServicesTabAction => ({
    type: EPricesServicesActions.SetServicesTab,
    payload,
});

export const setIsPriceServicesLoadingAction = (payload: boolean): ISetIsServiceLoadingAction => ({
    type: EPricesServicesActions.SetIsServiceLoading,
    payload,
});

export const setIsOpenDeletePriceServicesAction = (payload: boolean): ISetIsOpenDeletePricesServicesAction => ({
    type: EPricesServicesActions.SetIsOpenDeletePricesServices,
    payload,
});

export const getServicesThunk = () => async (dispatch: AppDispatch) => {
    try {
        const { data: services } = await pricesApi.getServices();
        dispatch(setPriceServicesAction(services));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getServiceThunk = (serviceId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await pricesApi.getService(serviceId);
        dispatch(setPriceServiceAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const createServiceThunk = (formData: IFormValuesPriceServicesDialog) => async () => {
    try {
        await pricesApi.createServices(formData);
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const updateServiceThunk =
    (serviceId: string, formData: IFormValuesPriceServicesDialog) => async (dispatch: AppDispatch) => {
        try {
            const { data } = await pricesApi.updateService(serviceId, formData);
            dispatch(setPriceServiceAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const deleteServiceThunk = (serviceId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await pricesApi.deleteService(serviceId);
        dispatch(setPriceServiceAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
