import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CreatePartForm from './CreatePartFrom';

import { getCategoriesThunk } from '../../../../../../store/actions/categories';
import {
    setErrorsAction,
    setFormValuesCPAction,
    setIsOpenCPAction,
} from '../../../../../../store/actions/create/createPart';
import { useAppDispatch } from '../../../../../../store/hooks';
import { categoriesSearchSelector } from '../../../../../../store/selectors/categoriesSelectors';
import { isOpenCPSelector } from '../../../../../../store/selectors/create/createPartSelectors';
import defaultErrorCallback from '../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../utils/helpers/getErrorString';

const defaultListSize = 20;

function CreatePart() {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(isOpenCPSelector);
    const search = useSelector(categoriesSearchSelector);

    useEffect(() => {
        if (!isOpen) {
            dispatch(setFormValuesCPAction(null));
            dispatch(setErrorsAction(null));
        }
    }, [isOpen]);

    useEffect(() => {
        if (!isOpen) return;
        async function handle() {
            try {
                await dispatch(getCategoriesThunk({ size: defaultListSize, search, page: 0 }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, [isOpen, search]);

    function onCloseHandler() {
        dispatch(setIsOpenCPAction(false));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div
                        className="close-icon"
                        style={{ position: 'absolute', right: '15px', cursor: 'pointer' }}
                    >
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Создание запчасти</Typography>
                </Box>
                <DialogContent>
                    <CreatePartForm />
                </DialogContent>
            </DialogTitle>
        </Dialog>
    );
}

export default CreatePart;
