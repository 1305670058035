import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { CircularProgress } from '@mui/material';

import styles from './Audio.module.scss';

import { useAudio } from '../../../hooks/useAudio';

interface IProps {
    audio: string;
}

function Audio({ audio }: IProps) {
    const [playing, toggle, duration, timer] = useAudio(audio);

    return (
        <div className={styles.wrapper}>
            <button
                className={styles.button}
                onClick={toggle}
            >
                {playing ? <PauseIcon color="primary" /> : <PlayArrowIcon color="primary" />}
                <CircularProgress
                    size="large"
                    variant="determinate"
                    value={playing ? timer - 100 : 0}
                    sx={{ position: 'absolute', width: 48, height: 48 }}
                />
            </button>
            <div className={styles.duration}>{duration}</div>
        </div>
    );
}

export default Audio;
