import Autocomplete from 'components/inputs/Autocomplete';
import TextField from 'components/inputs/TextField';
import { EIssueHelpType } from 'enums/issues.enum';
import {
    getCancelationReasonsThunk,
    setCancelationReasonsAction,
    setFormValuesEditIssueStatusToCancelAction,
} from 'store/actions/edit/editIssueStatusToCancel';
import { useAppDispatch } from 'store/hooks';
import {
    cancelationReasonsOptionsSelector,
    cancelationReasonsSelector,
    errorsEditIssueStatusToCancelSelector,
    formValuesEditIssueStatusToCancelSelector,
} from 'store/selectors/edit/editIssueStatusToCancelSelectors';

import { IOption } from 'interfaces';
import React, { ChangeEvent, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import './EditIssueStatusToCancelForm.scss';

interface IProps {
    issueHelpType: EIssueHelpType;
}

function EditIssueStatusToCancelForm({ issueHelpType }: IProps) {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesEditIssueStatusToCancelSelector);
    const cancelationReasons = useSelector(cancelationReasonsSelector);

    const errors = useSelector(errorsEditIssueStatusToCancelSelector);

    const cancelationReasonsOptions = useSelector(cancelationReasonsOptionsSelector);

    function onChangeReasons(options: IOption<string> | null) {
        dispatch(setFormValuesEditIssueStatusToCancelAction({ reason: options }));
        dispatch(setFormValuesEditIssueStatusToCancelAction({ comment: '' }));
    }

    function onChangeComment(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditIssueStatusToCancelAction({ comment: e.target.value.trim() }));
    }

    useEffect(() => {
        dispatch(getCancelationReasonsThunk({ filters: { help_type: [issueHelpType] } }));
        return () => {
            dispatch(setCancelationReasonsAction(null));
        };
    }, []);

    return (
        <div className="edit-issue-status-to-cancel-form-wrapper">
            <div className="info-text">Выберите причину, из-за которой вы переводите заявку в статус Отказ</div>
            <Autocomplete
                name="reasons"
                label="Причина отказа"
                options={cancelationReasonsOptions}
                onChange={onChangeReasons}
            />
            {cancelationReasons?.map((item) =>
                item.name === formValues?.reason?.label && item.comment_required ? (
                    <TextField
                        key={item.id}
                        name="comment"
                        placeholder="Комментарий"
                        fullWidth
                        params={{ margin: 'normal' }}
                        errors={errors}
                        onChange={onChangeComment}
                    />
                ) : null,
            )}
            <FooterForm />
        </div>
    );
}

export default memo(EditIssueStatusToCancelForm);
