import { AppDispatch } from 'store/store';

import { workersApi } from '../../../api';
import { EEditWorkerInfoActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IAddWorkerAddressReq,
    IEditWorkerInfoReq,
    ISetAdditionalEducationEditWorkerInfo,
    ISetEducationalProgramEditWorkerInfo,
    ISetEducationTypeEditWorkerInfo,
    ISetErrorsEditWorkerInfo,
    ISetInstitutionNameEditWorkerInfo,
    ISetIsOpenEditWorkerInfo,
} from '../../../interfaces/edit/editWorkerInfo';
import { IAdditionalEducationItem, IEducation } from '../../../interfaces/workers';
import { setWorkerAction } from '../worker';

export const setIsOpenEditWorkerInfoAction = (payload: boolean): ISetIsOpenEditWorkerInfo => ({
    type: EEditWorkerInfoActions.SetIsOpen,
    payload,
});

export const setErrorsEditWorkerInfoAction = (payload: TErrors | null): ISetErrorsEditWorkerInfo => ({
    type: EEditWorkerInfoActions.SetErrors,
    payload,
});

export const setAdditionalEducationAction = (
    payload: IAdditionalEducationItem[] | null,
): ISetAdditionalEducationEditWorkerInfo => ({
    type: EEditWorkerInfoActions.SetAdditionalEducation,
    payload,
});

export const setEducationTypeAction = (payload: string | null): ISetEducationTypeEditWorkerInfo => ({
    type: EEditWorkerInfoActions.SetEducationType,
    payload,
});

export const setInstitutionNameAction = (payload: string | null): ISetInstitutionNameEditWorkerInfo => ({
    type: EEditWorkerInfoActions.SetInstitutionName,
    payload,
});

export const setEducationalProgramAction = (payload: string | null): ISetEducationalProgramEditWorkerInfo => ({
    type: EEditWorkerInfoActions.SetEducationalProgram,
    payload,
});

export const editWorkerInfoThunk = (workerId: string, info: IEditWorkerInfoReq) => {
    return async () => {
        try {
            const { data } = await workersApi.editWorkerInfo(workerId, info);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const editWorkerAddressThunk = (workerId: string, info: IEditWorkerInfoReq) => {
    return async () => {
        try {
            const { data } = await workersApi.editWorkerAddress(workerId, info);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const editWorkerDescriptionThunk = (workerId: string, info: IEditWorkerInfoReq) => {
    return async () => {
        try {
            const { data } = await workersApi.editWorkerDescription(workerId, info);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const addWorkerAddressThunk = (workerId: string, address: IAddWorkerAddressReq) => {
    return async () => {
        try {
            const { data } = await workersApi.addWorkerAddress(workerId, address);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const editWorkerEducationThunk = (workerId: string, education: IEducation) => {
    return async () => {
        try {
            const { data } = await workersApi.editWorkerEducation(workerId, education);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const verifyResetWorkerThunk = (workerId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            const { data } = await workersApi.verifyResetWorker(workerId);
            dispatch(setWorkerAction(data));
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
