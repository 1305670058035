import { EEditIssueStatusActions } from '../../../enums/actions.enum';
import { IEditIssueStatusState, TEditIssueStatusActions } from '../../../interfaces/edit/editIssueStatus';

const initState: IEditIssueStatusState = {
    isOpen: false,
    issueId: null,
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editIssueStatusReducer = (
    state = initialState,
    action: TEditIssueStatusActions,
): IEditIssueStatusState => {
    switch (action.type) {
        case EEditIssueStatusActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditIssueStatusActions.SetIssueId:
            return {
                ...state,
                issueId: action.payload,
            };
        case EEditIssueStatusActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EEditIssueStatusActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
