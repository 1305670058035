import dayjs from 'dayjs';

import { clientsApi, issuesApi } from '../../../api';
import { ECreateIssueActions } from '../../../enums/actions.enum';
import { EIssueCreatedSource, EIssueHelpType } from '../../../enums/issues.enum';
import { TErrors } from '../../../interfaces';
import {
    ICreateIssueReq,
    IFormValuesCreateIssue,
    ISetErrorsCreateIssue,
    ISetFormValuesCreateIssue,
    ISetIsOpenCreateIssue,
    ISetSelectedPricesCreateIssue,
} from '../../../interfaces/create/createIssue';
import { IPrice } from '../../../interfaces/prices';

/* CI === CreateIssue */
export const setFormValuesCIAction = (payload: IFormValuesCreateIssue | null): ISetFormValuesCreateIssue => ({
    type: ECreateIssueActions.SetFormValues,
    payload,
});

export const setSelectedPricesCIAction = (payload: IPrice[] | null): ISetSelectedPricesCreateIssue => ({
    type: ECreateIssueActions.SetSelectedPrices,
    payload,
});

export const setErrorsAction = (payload: TErrors | null): ISetErrorsCreateIssue => ({
    type: ECreateIssueActions.SetErrors,
    payload,
});

export const setIsOpenCreateIssueAction = (payload: boolean): ISetIsOpenCreateIssue => ({
    type: ECreateIssueActions.SetIsOpen,
    payload,
});
interface ICreateIssueThunkParams {
    formValues: IFormValuesCreateIssue;
    selectedPrices: IPrice[];
}
export const createIssueThunk =
    ({ formValues, selectedPrices }: ICreateIssueThunkParams) =>
    async () => {
        try {
            if (!formValues.date || !formValues.time) {
                console.error('Error! createIssueThunk() !formValues.date || !formValues.time');
                return;
            }
            const {
                client_id,
                description,
                help_type,
                home_device_id,
                intercom,
                floor,
                entrance,
                apartment,
                codes_issue,
                codes_code,
                created_source,
                partner_id,
                address,
                photo_files_ids,
                lat,
                long,
                fias_id,
                by_loyalty,
            } = formValues;

            const date = dayjs(formValues.date).locale('ru').format('YYYY-MM-DD');

            const time = formValues.time?.split('-');

            const [startTime, endTime] = time;

            const pricesIds = selectedPrices.filter(({ service }) => !service.is_additional).map(({ id }) => id);

            const { data: newAddress } = await clientsApi.createAddress(client_id as string, {
                address: address as string,
                apartment: Number(apartment) || null,
                entrance: Number(entrance) || null,
                floor: Number(floor) || null,
                intercom: Number(intercom) || null,
                lat: lat || null,
                long: long || null,
                fias_id: fias_id || null,
            });

            const request: ICreateIssueReq = {
                prices: pricesIds,
                selected_suggestions: [],
                client_id: client_id as string,
                description: description as string,
                help_type: help_type as EIssueHelpType,
                sub_issues: [{ home_device_id: home_device_id as string, help_type: help_type as string }],
                photo_file_ids: photo_files_ids as string[],
                time_windows: [{ date, time: [{ start_time: startTime, end_time: endTime }] }],
                video_file_ids: [],
                sources: {
                    created_source: created_source as EIssueCreatedSource,
                    partner_id: partner_id as string,
                },
                codes: { issue: codes_issue as string, code: codes_code as string },
                address_id: newAddress.id as string,
                by_loyalty: by_loyalty || false,
            };

            const { data } = await issuesApi.createIssue(request);
            // eslint-disable-next-line consistent-return
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
