import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Paper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Header from './Header';

import { IGroup } from '../../../interfaces/groups';
import { groupsSelector } from '../../../store/selectors/groupsSelector';
import './Groups.scss';

interface IProps {
    selectedGroup: IGroup | null;
    onChange: (group: IGroup | null) => void;
}

function Groups({ selectedGroup, onChange }: IProps) {
    const groups = useSelector(groupsSelector);

    function clearFilter() {
        onChange(null);
    }

    return (
        <Paper className="groups-wrapper">
            <Header />
            <div className="scroll-wrapper">
                {groups
                    ? groups.map((group) => (
                          <Box
                              key={group.id}
                              onClick={() => onChange(group)}
                              sx={{
                                  padding: '14px',
                                  minHeight: '38px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                                  cursor: 'pointer',
                              }}
                          >
                              <Box
                                  sx={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      width: '100%',
                                  }}
                              >
                                  <Box>{group.name}</Box>
                                  {selectedGroup?.id === group.id && <CheckIcon />}
                              </Box>
                          </Box>
                      ))
                    : 'Группы не найдены'}
            </div>
            <Button
                sx={{
                    padding: '10px',
                }}
                onClick={() => clearFilter()}
                fullWidth
            >
                Очистить фильтр
            </Button>
        </Paper>
    );
}

export default Groups;
