import { ECreatePartActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IFormValuesCreatePart,
    ISetErrorsCreatePart,
    ISetFormValuesCreatePart,
    ISetIsOpenCreatePartAction,
} from '../../../interfaces/create/createPart';

/* CP === CreatePart */
export const setFormValuesCPAction = (payload: IFormValuesCreatePart | null): ISetFormValuesCreatePart => ({
    type: ECreatePartActions.SetFormValues,
    payload,
});

export const setErrorsAction = (payload: TErrors | null): ISetErrorsCreatePart => ({
    type: ECreatePartActions.SetErrors,
    payload,
});

export const setIsOpenCPAction = (payload: boolean): ISetIsOpenCreatePartAction => ({
    type: ECreatePartActions.SetIsOpenCreatePart,
    payload,
});
