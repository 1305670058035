import { Button, Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import React, { createRef, memo, MouseEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../components/inputs/TextField';
import Loader from '../../../components/Loader';
import { getCategoriesThunk, setSearchCategoriesAction } from '../../../store/actions/categories';
import { useAppDispatch } from '../../../store/hooks';
import {
    categoriesOptionsSelector,
    categoriesPaginationSelector,
    categoriesSearchSelector,
    categoriesSelector,
} from '../../../store/selectors/categoriesSelectors';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';
import './Categories.scss';

interface IProps {
    selectedCRFIdsForWorkers: string[] | null;
    onChange: (selectCRFIds: string[] | null) => void;
}

const defaultListSize = 20;

function Categories({ onChange, selectedCRFIdsForWorkers }: IProps) {
    const dispatch = useAppDispatch();
    const categoriesOptions = useSelector(categoriesOptionsSelector);
    const categories = useSelector(categoriesSelector);
    const search = useSelector(categoriesSearchSelector);
    const pagination = useSelector(categoriesPaginationSelector);

    const [isLoadMoreAppointments, setIsLoadMoreAppointments] = useState<boolean | null>(false);
    const [entriesObserver, setEntriesObserver] = useState<IntersectionObserverEntry[] | null>(null);
    const loadMoreAppointmentsElem = createRef<HTMLTableRowElement>();

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getCategoriesThunk({ size: defaultListSize }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();

        const observerOptions = {
            root: document.querySelector('.patients-table .archive'),
            threshold: 1.0,
        };
        const observer = new IntersectionObserver((entries) => {
            setEntriesObserver(entries);
        }, observerOptions);
        loadMoreAppointmentsElem.current && observer.observe(loadMoreAppointmentsElem.current);

        return () => {
            loadMoreAppointmentsElem.current && observer.unobserve(loadMoreAppointmentsElem.current);
        };
    }, []);

    useEffect(() => {
        async function getMoreAppointmentsOnObserve() {
            if (entriesObserver && entriesObserver[0]?.isIntersecting) {
                try {
                    if (
                        categories &&
                        pagination &&
                        pagination?.total > Number(pagination.size * pagination.page) &&
                        isLoadMoreAppointments === false
                    ) {
                        setIsLoadMoreAppointments(true);
                        await dispatch(
                            getCategoriesThunk({ categories, page: pagination.page + 1, size: defaultListSize }),
                        );
                    }
                } catch (err) {
                    defaultErrorCallback({ errorMessage: getErrorString({ err }) });
                } finally {
                    setIsLoadMoreAppointments(false);
                }
            }
        }
        getMoreAppointmentsOnObserve();
    }, [entriesObserver]);

    useEffect(() => {
        async function handle() {
            try {
                if (typeof search !== 'string') return;
                await dispatch(getCategoriesThunk({ search, size: defaultListSize }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [search]);

    async function onSearchHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSearchCategoriesAction(e.target.value));
    }

    function onClickFormGroup(e: MouseEvent) {
        e.stopPropagation();
    }

    function clearFilter() {
        onChange(null);
    }

    function selectSpecializations(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        checked
            ? onChange(
                  selectedCRFIdsForWorkers ? [...selectedCRFIdsForWorkers, event.target.value] : [event.target.value],
              )
            : onChange(
                  selectedCRFIdsForWorkers &&
                      selectedCRFIdsForWorkers.filter((category) => category !== event.target.value),
              );
    }

    return (
        <Paper className="categories-wrapper">
            <TextField
                name="search-ratios-categories"
                onChange={onSearchHandler}
                placeholder="Поиск по категориям"
                fullWidth
            />
            <div className="scroll-wrapper">
                {!!categoriesOptions?.length &&
                    categoriesOptions.map(({ value, label }) => (
                        <FormGroup
                            key={value}
                            sx={{ marginLeft: 1 }}
                            onClick={onClickFormGroup}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={selectSpecializations}
                                        value={value}
                                        checked={!!selectedCRFIdsForWorkers?.some((spec) => spec === value)}
                                    />
                                }
                                label={label}
                            />
                        </FormGroup>
                    ))}
                <FormGroup
                    ref={loadMoreAppointmentsElem}
                    sx={{ position: 'relative', bottom: 5, height: 20, border: 'none' }}
                >
                    <div>{isLoadMoreAppointments && <Loader />}</div>
                </FormGroup>
            </div>
            <Button
                fullWidth
                onClick={clearFilter}
            >
                Очистить фильтр
            </Button>
        </Paper>
    );
}

export default memo(Categories);
