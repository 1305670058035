import React from 'react';
import { useSelector } from 'react-redux';

import Loader from '../../../components/Loader';
import { IPriceService } from '../../../interfaces/pricesServices';
import { pricesServicesCommissionsTypeMock } from '../../../mockData/pricesServices';
import { isPricesServicesLoadingSelector } from '../../../store/selectors/pricesServicesSelector';
import './ServiceDetail.scss';

interface IProps {
    service: IPriceService | null;
}

function ServiceDetail({ service }: IProps) {
    const isServiceLoading = useSelector(isPricesServicesLoadingSelector);

    const { name, commissions, description } = service || {};
    const commissionType =
        commissions?.length && commissions[0].type ? pricesServicesCommissionsTypeMock[commissions[0].type] : '%';
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {isServiceLoading ? (
                <Loader />
            ) : (
                <div className="service-list">
                    <div className="service-item">
                        <div>Название</div>
                        <div>{name}</div>
                    </div>
                    {!!description && (
                        <div className="service-item">
                            <div>Описание</div>
                            <div>{description}</div>
                        </div>
                    )}
                    {!!commissions?.length && !!commissions[0].main_amount && (
                        <div className="service-item">
                            <div>
                                Комиссия
                                <br />с осн. услуг
                            </div>
                            <div>
                                {commissions[0].main_amount} {commissionType || null}
                            </div>
                        </div>
                    )}
                    {!!commissions?.length && !!commissions[0].additional_amount && (
                        <div className="service-item">
                            <div>
                                Комиссия
                                <br />с доп. услуг
                            </div>
                            <div>
                                {commissions[0].additional_amount} {commissionType || null}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export default ServiceDetail;
