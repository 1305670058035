import { EChatsInfiniteActions } from '../../enums/actions.enum';
import { IChat } from '../../interfaces/chats';
import { IChatsInfiniteState, TChatsInfiniteActions } from '../../interfaces/chatsInfinte';
import mergeArrays from '../../utils/helpers/mergeArrays';

const initState: IChatsInfiniteState = {
    chats: null,
    isLoading: false,
    pagination: { page: 0, size: 0, total: 0 },
    filters: null,
    sortOptions: new Map().set('last_message_at', 'desc').set('created_at', 'desc'),
};

const initialState = { ...initState };

function insertChats(current: IChat[], items: IChat[]) {
    let newItems = mergeArrays(current, items, (value) => value.chat.id);

    newItems = newItems.sort((a, b) => {
        if (a.last_message && b.last_message) {
            // @ts-ignore
            return new Date(b.last_message.created_at) - new Date(a.last_message.created_at);
        } else if (a.last_message && !b.last_message) {
            return -1;
        } else if (!a.last_message && b.last_message) {
            return 1;
        } else {
            // @ts-ignore
            return new Date(b.chat.created_at) - new Date(a.chat.created_at);
        }
    });

    return newItems.slice(0, current.length);
}

// eslint-disable-next-line default-param-last
export const chatsInfiniteReducer = (state = initialState, action: TChatsInfiniteActions): IChatsInfiniteState => {
    switch (action.type) {
        case EChatsInfiniteActions.ResetChatsInfinite:
            return {
                ...initialState,
            };
        case EChatsInfiniteActions.PutChatsInfinite:
            return {
                ...state,
                chats: insertChats(action.payload, []),
            };
        case EChatsInfiniteActions.SetChatsInfinite:
            return {
                ...state,
                chats: insertChats([...action.payload, ...(state.chats || [])], []),
            };
        case EChatsInfiniteActions.SetIsLoadingChatsInfinite:
            return {
                ...state,
                isLoading: action.payload,
            };
        case EChatsInfiniteActions.SetChatsPaginationInfinite:
            return {
                ...state,
                pagination: action.payload,
            };
        case EChatsInfiniteActions.SetChatsFiltersInfinite:
            return {
                ...state,
                filters: action.payload,
            };
        case EChatsInfiniteActions.SetChatsSortOptionsInfinite:
            return {
                ...state,
                sortOptions: new Map(state.sortOptions).set(action.payload.sortField, action.payload.direction),
            };
        case EChatsInfiniteActions.InsertChatsInfinite:
            const chats = insertChats(state.chats || [], [action.payload]);
            return {
                ...state,
                chats,
            };
        default:
            return state;
    }
};
