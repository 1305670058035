import { EIssueHelpType } from '../enums/issues.enum';

interface ITimeWindow {
    startTime: string;
    endTime: string;
}

export const timeWindowsMock = [
    {
        startTime: '09:00',
        endTime: '14:00',
    },
    {
        startTime: '15:00',
        endTime: '21:00',
    },
];

export const timeWindowsRepair = [
    {
        startTime: '09:00',
        endTime: '21:00',
    },
];

export const getTimeWindowsSlots = (helpType: EIssueHelpType | null | undefined): ITimeWindow[] => {
    if (helpType === EIssueHelpType.Repair) return timeWindowsRepair;
    return timeWindowsMock;
};
