import { StyledLink } from 'components/StyledLink';
import { ERoutes } from 'enums/routes.enum';
import { issueHelpTypeNamesMock, issueStatusesMock } from 'mockData/issues';
import { dateFormatter } from 'utils/helpers/dateFormatter';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Chip } from '@mui/material';
import { IIssue } from 'interfaces/issues';

import './IssueHistoryItem.scss';

interface IProps {
    issue: IIssue;
}

function IssueHistoryItem({ issue }: IProps) {
    return (
        <StyledLink
            to={`/${ERoutes.Issues}/${issue.id}`}
            target="_blank"
        >
            <Box className="issue-wrapper">
                <div className="issue-created-at">
                    {dateFormatter(
                        issue.created_at,
                        { isDateTime: false },
                        { day: 'numeric', month: 'numeric', year: 'numeric' },
                    )}
                </div>
                <div className="issue-title">
                    <span>
                        Заявка № {issue.oid}
                        .&nbsp;
                        {issueHelpTypeNamesMock[issue.help_type]}
                    </span>
                </div>
                <div className="issue-status">
                    <Chip
                        label={issueStatusesMock[issue.status]?.title}
                        sx={{
                            fontWeight: '600',
                            backgroundColor: issueStatusesMock[issue.status]?.colorHex,
                            color: issueStatusesMock[issue.status]?.colorText,
                        }}
                        size="small"
                        clickable={false}
                    />
                </div>
                <ArrowForwardIosIcon
                    className="issue-arrow"
                    style={{ width: 16, height: 16 }}
                />
            </Box>
        </StyledLink>
    );
}

export default IssueHistoryItem;
