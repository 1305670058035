import { AppDispatch } from 'store/store';

import { devicesApi } from '../../api';
import { EDevicesActions } from '../../enums/actions.enum';
import {
    ICreateDeviceReq,
    IDeviceDetail,
    IHomeDeviceDetail,
    ISetDevice,
    ISetHomeDevice,
    ISetHomeDevices,
    ISetIsOpenCD,
} from '../../interfaces/devices';

export const setDeviceAction = (payload: IDeviceDetail | null): ISetDevice => ({
    type: EDevicesActions.SetDevice,
    payload,
});

export const setHomeDeviceAction = (payload: IHomeDeviceDetail | null): ISetHomeDevice => ({
    type: EDevicesActions.SetHomeDevice,
    payload,
});

export const setHomeDevicesAction = (payload: IHomeDeviceDetail[] | null): ISetHomeDevices => ({
    type: EDevicesActions.SetHomeDevices,
    payload,
});

/* CD === create device */
export const setIsOpenCDAction = (payload: boolean): ISetIsOpenCD => ({
    type: EDevicesActions.SetIsOpen,
    payload,
});

export const createDeviceThunk = (request: ICreateDeviceReq) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await devicesApi.createDevice(request);
        dispatch(setDeviceAction(data));
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};
