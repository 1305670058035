import { AppDispatch } from 'store/store';

import { issuesApi, workersApi } from '../../api';
import { EWorkerActions } from '../../enums/actions.enum';
import { EIssueHelpType } from '../../enums/issues.enum';
import { IPhotoFile } from '../../interfaces';
import { IServiceArea } from '../../interfaces/dicts';
import { ICanceledIssueResp, ISolvedIssueResp } from '../../interfaces/issues';
import { ISpecialization } from '../../interfaces/specializations';
import {
    ISetCanceledIssueHistoryAction,
    ISetSolvedIssueHistoryAction,
    ISetWorkerAvatarAction,
    ISetWorkerDetailAction,
    ISetWorkerFIOAction,
    ISetWorkerScheduleAction,
    ISetWorkerSelectedSpecializations,
    ISetWorkerSelectedTabAction,
    ISetWorkerServiceAreasAction,
    ISetWorkerSpecializationsAction,
    IWorkerResetAction,
} from '../../interfaces/worker';
import { IWorkerDetail, IWorkSchedule } from '../../interfaces/workers';

export const workerResetAction = (): IWorkerResetAction => ({
    type: EWorkerActions.WorkerReset,
});

export const setWorkerAction = (payload: IWorkerDetail | null): ISetWorkerDetailAction => ({
    type: EWorkerActions.SetWorkerDetail,
    payload,
});

export const setWorkerAvatarAction = (payload: IPhotoFile | null): ISetWorkerAvatarAction => ({
    type: EWorkerActions.SetWorkerAvatar,
    payload,
});

export const setCanceledIssueHistoryAction = (
    payload: ICanceledIssueResp[] | null,
): ISetCanceledIssueHistoryAction => ({
    type: EWorkerActions.SetCanceledIssueHistory,
    payload,
});

export const setSolvedIssueHistoryAction = (payload: ISolvedIssueResp[] | null): ISetSolvedIssueHistoryAction => ({
    type: EWorkerActions.SetSolvedIssueHistory,
    payload,
});

export const setWorkerFIOAction = (payload: IWorkerDetail | null): ISetWorkerFIOAction => ({
    type: EWorkerActions.SetWorkerFIO,
    payload,
});

export const setWorkerScheduleAction = (payload: IWorkSchedule | null): ISetWorkerScheduleAction => ({
    type: EWorkerActions.SetWorkerSchedule,
    payload,
});

export const setWorkerServiceAreasAction = (payload: IServiceArea[] | null): ISetWorkerServiceAreasAction => ({
    type: EWorkerActions.SetWorkerServiceAreas,
    payload,
});

export const setWorkerSpecializationsAction = (payload: ISpecialization[] | null): ISetWorkerSpecializationsAction => ({
    type: EWorkerActions.SetWorkerSpecializations,
    payload,
});

export const setWorkerSelectedTabsAction = (payload: EIssueHelpType): ISetWorkerSelectedTabAction => ({
    type: EWorkerActions.SetSelectedTab,
    payload,
});

export const setWorkerSelectedSpecializations = (
    payload: ISpecialization[] | null,
): ISetWorkerSelectedSpecializations => ({
    type: EWorkerActions.SetWorkerSelectedSpecializations,
    payload,
});

export const getWorkerThunk = (workerId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await workersApi.getWorker(workerId);
        dispatch(setWorkerAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getCanceledIssueHistoryForWorkerThunk = (workerId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await issuesApi.getCanceledIssueHistoryForWorker(workerId);
        const { items: canceledIssueHistory } = data;
        dispatch(setCanceledIssueHistoryAction(canceledIssueHistory));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getSolvedIssueHistoryForWorkerThunk = (workerId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await issuesApi.getSolvedIssueHistoryForWorker(workerId);
        const { items: solvedIssueHistory } = data;
        dispatch(setSolvedIssueHistoryAction(solvedIssueHistory));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const uploadWorkerAvatarThunk = (workerId: string, file: FormData) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await workersApi.uploadWorkerAvatar(workerId, file);
        dispatch(setWorkerAvatarAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
