import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';

interface IProps {
    dateStart: Date | null;
    dateEnd: Date | null;
    setDateStart: (date: Date | null) => void;
    setDateEnd: (date: Date | null) => void;
}
/* TODO или переименовать или выяснить что за ерунда */
function DatePickers({ dateStart, dateEnd, setDateStart, setDateEnd }: IProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: '12px',
                mt: '10px',
            }}
        >
            <DatePicker
                value={dateStart}
                maxDate={dateEnd}
                onChange={(newValue) => setDateStart(newValue)}
            />
            <DatePicker
                value={dateEnd}
                minDate={dateStart}
                onChange={(newValue) => setDateEnd(newValue)}
            />
        </Box>
    );
}

export default DatePickers;
