import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PhoneInput from '../../../../../components/inputs/PhoneInput';
import TextField from '../../../../../components/inputs/TextField';
import { TErrors } from '../../../../../interfaces';
import { IClientDetail, ICreateClient } from '../../../../../interfaces/clients';
import {
    createClientThunk,
    setErrorsCCAction,
    setFormValuesCCAction,
    setIsOpenCreateClientAction,
} from '../../../../../store/actions/clients';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsCCSelector,
    formValuesCCSelector,
    isOpenCreateClientSelector,
} from '../../../../../store/selectors/clientsSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

interface IProps {
    onCreate?: (newClient: IClientDetail) => void;
}

function CreateClient({ onCreate }: IProps) {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesCCSelector);
    const errors = useSelector(errorsCCSelector);
    const isOpen = useSelector(isOpenCreateClientSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.last_name) {
            errs.last_name = { message: 'Обязательное поле' };
        }

        if (!formValues?.first_name) {
            errs.first_name = { message: 'Обязательное поле' };
        }

        if (!formValues?.phone_number) {
            errs.phone_number = { message: 'Обязательное поле' };
        }

        dispatch(setErrorsCCAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues]);

    useEffect(() => {
        if (!isOpen) {
            dispatch(setFormValuesCCAction(null));
            dispatch(setErrorsCCAction(null));
        }
    }, [isOpen]);

    useEffect(() => {
        if (errors) {
            validator();
        }
        if (!formValues) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [formValues]);

    function changeFirstNameHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCCAction({ first_name: e.target.value }));
    }
    function changeLastNameHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCCAction({ last_name: e.target.value }));
    }
    function changePhoneNumberHandler(val: string) {
        dispatch(setFormValuesCCAction({ phone_number: val }));
    }
    function onCloseHandler() {
        dispatch(setIsOpenCreateClientAction(false));
    }

    async function onCreateHandler() {
        if (!formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);
                const newClient = await dispatch(createClientThunk(formValues as ICreateClient));
                onCreate && onCreate(newClient);
                onCloseHandler();
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="paper"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Новый клиент</Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ minHeight: '500px' }}>
                <TextField
                    name="last_name"
                    errors={errors}
                    onChange={changeLastNameHandler}
                    placeholder="Фамилия*"
                    labelText="Фамилия*"
                    fullWidth
                    params={{ margin: 'normal' }}
                />
                <TextField
                    name="first_name"
                    errors={errors}
                    onChange={changeFirstNameHandler}
                    placeholder="Имя*"
                    labelText="Имя*"
                    fullWidth
                    params={{ margin: 'normal' }}
                />
                <PhoneInput
                    name="phone_number"
                    onChange={changePhoneNumberHandler}
                    placeholder="+7 900 000 00 00"
                    fullWidth
                    params={{ margin: 'normal' }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onCloseHandler}
                    disabled={isLoading}
                >
                    Отмена
                </Button>
                <LoadingButton
                    onClick={onCreateHandler}
                    loading={isLoading}
                    disabled={isBtnDisabled}
                    loadingPosition="center"
                    color="primary"
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateClient;
