import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ models }: RootState) => models;

export const modelSelector = createSelector(state, ({ model }) => model);
export const modelsSelector = createSelector(state, ({ models }) => models);
export const modelsOptionsSelector = createSelector(state, ({ models }) => {
    return (
        models?.map((model) => {
            const { id, name } = model;
            return { value: id, label: name };
        }) || null
    );
});
