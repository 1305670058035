import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import Categories from './Categories';

import useOutsideClick from '../../hooks/useOutsideClick';
import './CategoriesFilter.scss';

interface IProps {
    selectedCRFIdsForWorkers: string[] | null;
    onChange: (selectCRFIds: string[] | null) => void;
}

function CategoriesFilter({ selectedCRFIdsForWorkers, onChange }: IProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        return () => {
            onChange(null);
        };
    }, []);
    return (
        <div
            ref={wrapperRef}
            className={cn('categories-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant={selectedCRFIdsForWorkers?.length ? 'filterActive' : 'filter'}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                endIcon={<ExpandMoreIcon />}
            >
                Категория техники{' '}
                {!!selectedCRFIdsForWorkers?.length && (
                    <span style={{ marginLeft: 10 }}>{selectedCRFIdsForWorkers?.length}</span>
                )}
            </Button>
            <Categories
                onChange={onChange}
                selectedCRFIdsForWorkers={selectedCRFIdsForWorkers}
            />
        </div>
    );
}

export default CategoriesFilter;
