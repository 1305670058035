import { EPartsActions } from '../../enums/actions.enum';
import { IPartsState, TPartsActions } from '../../interfaces/parts';

const initState: IPartsState = {
    parts: null,
    part: null,
    filters: null,
    pagination: { page: 0, size: 20, total: 0 },
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const partsReducer = (state = initialState, action: TPartsActions): IPartsState => {
    switch (action.type) {
        case EPartsActions.SetParts:
            return {
                ...state,
                parts: action.payload,
            };
        case EPartsActions.SetPart:
            return {
                ...state,
                part: action.payload,
            };
        case EPartsActions.SetPartFilters:
            return {
                ...state,
                filters: action.payload,
            };
        case EPartsActions.SetPartPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        default:
            return state;
    }
};
