import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { ECreateIssuePartnerSteps } from '../../../enums/createIssuePartnerForm.enum';
import {
    setErrorsAction,
    setSelectedPricesAction,
    setSetFormStepAction,
    setSetFormValuesAction,
} from '../../../store/actions/create/createIssuePartner';
import { setIssueAction } from '../../../store/actions/issues';
import { useAppDispatch } from '../../../store/hooks';
import { issueSelector } from '../../../store/selectors/issuesSelectors';
import './IssueCreated.scss';

function IssueCreated() {
    const dispatch = useAppDispatch();
    const issue = useSelector(issueSelector);

    function backFormHandler() {
        dispatch(setIssueAction(null));
        dispatch(setSetFormValuesAction(null));
        dispatch(setSelectedPricesAction(null));
        dispatch(setErrorsAction(null));
        dispatch(setSetFormStepAction(ECreateIssuePartnerSteps.Form));
    }

    return (
        <div className="issue-created-wrapper">
            <div className="header-wrapper">
                <CheckCircleIcon
                    color="secondary"
                    fontSize="large"
                    style={{ marginRight: 18 }}
                />
                <Typography variant="h4">Заявка № {issue?.oid} создана</Typography>
            </div>
            <div className="issue-created-message">
                Клиенту придёт СМС-сообщение с номером заказа или пуш-уведомление, если у него установлено мобильное
                приложение Майтех. После этого с ним свяжется менеджер из Майтех.
            </div>
            <LoadingButton
                onClick={backFormHandler}
                sx={{
                    mt: 20,
                    maxWidth: 400,
                    background: '#1B2040',
                    borderRadius: '16px',
                    p: '19px',
                }}
                size="large"
                fullWidth
                variant="contained"
            >
                Создать новую заявку
            </LoadingButton>
        </div>
    );
}

export default IssueCreated;
