import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { Avatar } from '@mui/material';
import cn from 'classnames';
import { format } from 'date-fns';
import React from 'react';
import { useSelector } from 'react-redux';

import Menu from '../../../../components/Menu';
import { IMessageWithSenderInfo } from '../../../../interfaces/chat';
import { profileSelector } from '../../../../store/selectors/profileSelectors';
import { getDateHoursAndMinutes } from '../../../../utils/helpers/getDateHoursAndMinutes';
import { getFullName } from '../../../../utils/helpers/getFullName';
import './Message.scss';

interface IProps {
    message: IMessageWithSenderInfo;
    deleteMessage: (id: string) => void;
}

function Message({ message, deleteMessage }: IProps) {
    const profile = useSelector(profileSelector);
    const isCurrentUser: boolean = message.sender_id === profile?.id;

    return (
        <div className={cn('message', { interlocutor: !isCurrentUser, user: isCurrentUser })}>
            <Avatar
                className="avatar"
                src={message.sender?.photo_files?.url}
            />
            <div className="message-content">
                <div className="message-content-item">
                    <div className={cn('sender-name', { interlocutor: !isCurrentUser })}>
                        {isCurrentUser && profile
                            ? getFullName(profile)
                            : (message.sender && getFullName(message.sender)) || 'Удалённый пользователь'}
                    </div>
                    <div className="point-divider" />
                    <div className={cn('time', { interlocutor: !isCurrentUser, user: isCurrentUser })}>
                        {getDateHoursAndMinutes(message.created_at)}
                    </div>
                    <div className={cn('message-meta', { interlocutor: !isCurrentUser, user: isCurrentUser })}>
                        <div className={cn('seen-badge', { interlocutor: !isCurrentUser, user: isCurrentUser })}>
                            {message.seen ? (
                                <DoneAllIcon sx={{ width: 18, height: 18, alignItems: 'center' }} />
                            ) : (
                                <DoneIcon
                                    className="unseen"
                                    sx={{ width: 18, height: 18 }}
                                />
                            )}
                        </div>
                    </div>
                    {isCurrentUser && (
                        <Menu
                            icon={<MoreHoriz />}
                            iconClass="more-popover"
                            items={[
                                {
                                    title: 'Удалить',
                                    color: 'error',
                                    handle: () => deleteMessage(message.id),
                                },
                            ]}
                        />
                    )}
                </div>
                {message.attachments.length ? (
                    <div className="photo-files">
                        {message.attachments.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div
                                key={index}
                                className="image-wrapper"
                            >
                                <a
                                    href={item?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="image"
                                        src={item?.url}
                                        alt={item.name}
                                    />
                                    {item.created_at && (
                                        <div className="date">{format(new Date(item.created_at), 'dd.MM.yyyy')}</div>
                                    )}
                                </a>
                            </div>
                        ))}
                    </div>
                ) : null}

                <div className={'text'}>{message.text}</div>
            </div>
        </div>
    );
}

export default Message;
