import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-router-dom';

import { ESignInTypes } from '../../../enums/signIn.enum';
import envelopeImageSVG from '../../../static/images/envelopeImage.svg';
import { setTypeAction } from '../../../store/actions/signIn';
import { useAppDispatch } from '../../../store/hooks';
import { typeSignInSelector } from '../../../store/selectors/signInSelectors';

function RecoverPassword() {
    const dispatch = useAppDispatch();
    const type = useSelector(typeSignInSelector);

    const [email, setEmail] = useState<string | null>(null);

    const handleSubmit = async () => {
        setEmail(email);
        //TODO: API пока нет, выяснить. 17.02.23
        // try {
        //
        // } catch (err) {
        //     notify(getErrorString({ err }));
        // }
    };

    return (() => {
        switch (type) {
            case ESignInTypes.AfterRecover:
                return (
                    <Stack
                        spacing={4}
                        alignItems={'center'}
                        textAlign={'center'}
                    >
                        <img
                            src={envelopeImageSVG}
                            alt=""
                        />
                        <div>Ссылка для сброса пароля была выслана на почту {email}</div>
                        <Button
                            onClick={() => {
                                dispatch(setTypeAction(ESignInTypes.Login));
                            }}
                            variant={'contained'}
                        >
                            На главную
                        </Button>
                    </Stack>
                );
            default:
                return (
                    <>
                        <Button
                            onClick={() => {
                                dispatch(setTypeAction(ESignInTypes.Login));
                            }}
                        >
                            Назад
                        </Button>
                        <Stack spacing={2}>
                            <Typography
                                align={'center'}
                                fontSize={33}
                                fontWeight={'bold'}
                            >
                                Восстановить пароль
                            </Typography>
                            <Typography
                                align={'center'}
                                fontSize={16}
                                fontWeight={400}
                                color={'#8A92A6'}
                            >
                                Введите адрес электронной почты, и мы туда отправим ссылку для восстановления пароля
                            </Typography>
                        </Stack>
                        <Form onSubmit={handleSubmit}>
                            <Grid
                                container
                                direction="column"
                            >
                                <Grid
                                    gridRow={1}
                                    rowGap={1}
                                >
                                    <TextField
                                        name="email"
                                        type="email"
                                        label="Email"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                >
                                    <Button type="submit">Продолжить</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </>
                );
        }
    })();
}

export default RecoverPassword;
