import { EPartnersRatiosFilterActions } from '../../enums/actions.enum';
import { IPartnersRatiosFilterState, TPartnersRatiosFilterActions } from '../../interfaces/partnersRatiosFilter';

const initState: IPartnersRatiosFilterState = {
    partners: null,
    search: null,
    selectedPartner: null,
};

const initialState = { ...initState };

export const partnersRatiosFilterReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TPartnersRatiosFilterActions,
): IPartnersRatiosFilterState => {
    switch (action.type) {
        case EPartnersRatiosFilterActions.SetPartnersFilter:
            return {
                ...state,
                partners: action.payload,
            };
        case EPartnersRatiosFilterActions.SetSearch:
            return {
                ...state,
                search: action.payload,
            };
        case EPartnersRatiosFilterActions.SetSelectedPartner:
            return {
                ...state,
                selectedPartner: action.payload,
            };
        default:
            return state;
    }
};
