import { Button } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import Search from './Search';
import WorkersList from './WorkersList';

import { EIssueHelpType } from '../../enums/issues.enum';
import { ICategoryRatiosFilter } from '../../interfaces/categoriesRatiosFilter';
import { setSelectedCRFAction } from '../../store/actions/categoriesRatiosFilter';
import { setWorkersFiltersAction } from '../../store/actions/workers';
import { useAppDispatch } from '../../store/hooks';
import { workersFiltersSelector } from '../../store/selectors/workersSelectors';
import CategoriesFilter from '../CategoriesFilter';
import CategoriesRatiosFilter from '../CategoriesRatiosFilter';
import HelpTypeFilter from '../HelpTypeFilter';
import './WorkersSelect.scss';

function WorkersSelect() {
    const dispatch = useAppDispatch();

    const filters = useSelector(workersFiltersSelector);

    function selectRegion(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), area_id__in: categoriesIds }));
    }

    function searchWorker(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), search: event.target.value || null }));
    }

    function selectCRFIdsForWorkers(selectCRFIds: string[] | null) {
        dispatch(
            setWorkersFiltersAction({ ...(filters || {}), specialization__category_id__in: selectCRFIds || null }),
        );
    }
    function selectHelpTypeForWorkers(helpType: EIssueHelpType[] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), specialization__help_type__in: helpType || null }));
    }

    function resetFilters() {
        dispatch(setSelectedCRFAction(null));
    }

    return (
        <div className="workers-select-wrapper">
            <div className="search">
                <Search
                    onChange={searchWorker}
                    placeholder="Поиск по фамилии, имени, id"
                />
            </div>
            <div className="filters">
                <CategoriesRatiosFilter onChange={selectRegion} />
                <CategoriesFilter
                    selectedCRFIdsForWorkers={filters?.specialization__category_id__in || null}
                    onChange={selectCRFIdsForWorkers}
                />
                <HelpTypeFilter
                    selectedHelpType={filters?.specialization__help_type__in || null}
                    onChange={selectHelpTypeForWorkers}
                />
                <Button
                    size="small"
                    onClick={resetFilters}
                >
                    Сбросить фильтры
                </Button>
            </div>
            <WorkersList />
        </div>
    );
}

export default WorkersSelect;
