import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useRef, useState } from 'react';

import { filesApi } from '../../api';
import { IPhotoFile } from '../../interfaces';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import './fileUploadButton.scss';

interface IPhotoFileUploadProps {
    fileUpload: (file: IPhotoFile) => void;
}

function FileUploadButton({ fileUpload }: IPhotoFileUploadProps) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    async function handleInput(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event?.target?.files && event.target.files[0];
        if (!file) return;

        try {
            setIsLoading(true);
            const blob = new Blob([file]);
            const formData = new FormData();
            formData.append('file', blob);
            const { data } = await filesApi.createFile(formData);
            fileUpload(data);
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    }

    return (
        <div className="file-upload-button-wrapper">
            <LoadingButton
                disabled={isLoading}
                loading={isLoading}
                component="label"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
            >
                <input
                    ref={inputRef}
                    onChange={handleInput}
                    type="file"
                    hidden
                />
            </LoadingButton>
        </div>
    );
}

export default FileUploadButton;
