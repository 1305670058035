import Loader from 'components/Loader';
import Plug from 'components/Plug';
import { withWrapper } from 'hocs/with-wrapper';
import { useAppDispatch } from 'store/hooks';

import { Paper, Table as MuiTable, styled, TablePagination } from '@mui/material';
import { IPagination } from 'interfaces/pagination';
import { IWorker } from 'interfaces/workers';
import { ChangeEvent, FC } from 'react';

import { Body } from './body';
import { Head } from './head';

import { fetchWorkers, setPagination, setWorkerIds } from '../../store';

type Props = {
    rows: IWorker[];
    loading: boolean;
    pagination: IPagination;
};

export const Table: FC<Props> = ({ rows, loading, pagination }) => {
    const dispatch = useAppDispatch();

    const onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setPagination({ size: +e.target.value }));
        dispatch(fetchWorkers());
    };

    const onPageChange = (_: any, page: number) => {
        dispatch(setPagination({ page: page + 1 }));
        dispatch(fetchWorkers());
        dispatch(setWorkerIds([]));
    };

    if (loading) return withWrapper(Container, Loader);
    if (!rows.length) return withWrapper(Container, Plug);

    return (
        <Container>
            <MuiTable stickyHeader>
                <Head rows={rows} />
                <Body rows={rows} />
            </MuiTable>
            <Pagination
                rowsPerPageOptions={[5, 25, 50]}
                count={pagination.total}
                rowsPerPage={pagination.size}
                page={pagination.page - 1}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </Container>
    );
};

const Container = styled(Paper)({
    position: 'relative',
    height: '100%',
    overflow: 'auto',
});

const Pagination = styled(TablePagination)({
    display: 'block',
    backgroundColor: '#fff',
    position: 'sticky',
    bottom: 0,
});
