import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import dayjs from 'dayjs';
import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { useDebouncedCallback } from 'use-lodash-debounce';

import FooterForm from './FooterForm';

import DatePicker from '../../../../components/inputs/DatePicker';
import TextField from '../../../../components/inputs/TextField';
import TimePicker from '../../../../components/inputs/TimePicker';
import { setFormValuesEditIssueReminderAction } from '../../../../store/actions/edit/editIssueReminder';
import { useAppDispatch } from '../../../../store/hooks';
import {
    errorsEditIssueReminderSelector,
    formValuesEditIssueReminderSelector,
} from '../../../../store/selectors/edit/editIssueReminderSelectors';
import { reminderSelector } from '../../../../store/selectors/remindersSelector';

import './EditIssueReminderForm.scss';

function EditIssueReminderForm() {
    const dispatch = useAppDispatch();

    const reminder = useSelector(reminderSelector);
    const formValues = useSelector(formValuesEditIssueReminderSelector);
    const errors = useSelector(errorsEditIssueReminderSelector);

    useEffect(() => {
        if (!reminder) return;
        dispatch(
            setFormValuesEditIssueReminderAction({
                text: reminder.text,
                date_delivery: new Date(reminder.datetime_delivery),
                time_delivery: new Date(reminder.datetime_delivery),
                send_only_bo_user_created: reminder.send_only_bo_user_created,
            }),
        );
    }, [reminder]);

    /* Сделано для того, чтобы успеть записать 2 цифры в минутах */
    const onChangeTimeDebounced = useDebouncedCallback(onChangeTime, 500);

    function onChangeText(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditIssueReminderAction({ text: e.target.value || null }));
    }

    function onChangeDate(val: Date | null) {
        dispatch(setFormValuesEditIssueReminderAction({ date_delivery: val }));
    }

    function onChangeTime(val: Date | null) {
        dispatch(setFormValuesEditIssueReminderAction({ time_delivery: val || null }));
    }

    function onChangeSendOnlyBoUserCreated(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditIssueReminderAction({ send_only_bo_user_created: e.target.checked }));
    }

    return (
        <div className="edit-issue-reminder-form-wrapper">
            <TextField
                name="text"
                placeholder="Текст напоминания *"
                fullWidth
                errors={errors}
                onChange={onChangeText}
                value={formValues?.text || ''}
            />
            <div className="date-time">
                <div className="date-time-title">Дата и время напоминания</div>
                <div className="date-time-pickers">
                    <DatePicker
                        name="date_delivery"
                        label="Дата *"
                        fullWidth
                        disablePast
                        errors={errors}
                        onChange={onChangeDate}
                        defaultValue={dayjs(formValues?.date_delivery) || ''}
                    />
                    <TimePicker
                        name="time_delivery"
                        label="Время *"
                        fullWidth
                        disabled={!formValues?.date_delivery}
                        errors={errors}
                        onChange={onChangeTimeDebounced}
                        defaultValue={dayjs(formValues?.time_delivery) || ''}
                    />
                </div>
            </div>
            <FormControl className="send-only-bo-form-control">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={onChangeSendOnlyBoUserCreated}
                                checked={formValues?.send_only_bo_user_created || false}
                            />
                        }
                        label="Прислать напоминание только мне"
                    />
                </FormGroup>
            </FormControl>

            <FooterForm />
        </div>
    );
}

export default EditIssueReminderForm;
