import { EPermissionsKeys as EPerKeys, EPermissionsValues } from '../../enums/permissions.enum';
import { IProfile } from '../../interfaces/profile';
import { IRole } from '../../interfaces/roles';

interface IAccessDeniedParams {
    profile: IProfile | null;
    roles?: IRole;
    entityRole: EPerKeys;
    action: EPermissionsValues;
}
/* Хелпер для проверки возможность совершать действия
 * Принимает в себя profile и entityRole - это сущность в ролях, на бэке это разделы
 * Сначала проверяется поле is_superadmin, а далее уже проверяется определенный entityRole, который в себе может
 * нести ряд действий - EPermissionsValues, проверяем Owner, action - это дополнительное действие из EPermissionsValues,
 * в некоторых случаях нужна проверка
 * Порядок проверки важен, сначала is_superadmin -> company.permissions -> role.permissions
 * TODO: Требуется доработка для различных сценариев */

export const isAccessDenied = ({ profile, entityRole, action }: IAccessDeniedParams): boolean => {
    if (!profile) {
        return false;
    }

    if (!profile.is_superadmin) {
        if (profile?.company?.permissions) {
            if (
                profile?.company.permissions[entityRole].includes(EPermissionsValues.Owner) ||
                profile?.company.permissions[entityRole].includes(action)
            ) {
                return true;
            }
        } else if (profile?.role?.permissions) {
            if (
                profile?.role.permissions[entityRole].includes(EPermissionsValues.Owner) ||
                profile?.role.permissions[entityRole].includes(action)
            ) {
                return true;
            }
        }
        return false;
    }
    return true;
};
