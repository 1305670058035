import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ChatItem from '../../components/Chat/ChatItem';
import { getUserName } from '../../components/Chat/tools';
import InfiniteLoaderWrapper, { ItemProp } from '../../components/InfiniteLoaderWrapper';
import Loader from '../../components/Loader';
import { IChatGroupByUser } from '../../interfaces/chatGroupByUsers';
import { setChatGroupByUserAction } from '../../store/actions/chatGroupByUser';
import { getChatGroupByUsersThunk, resetChatGroupByUsersAction } from '../../store/actions/chatGroupByUsers';
import { ChatGroupByUserSize } from '../../store/constants';
import { useAppDispatch } from '../../store/hooks';
import {
    chatGroupByUsersFiltersSelector,
    chatGroupByUsersHasNextPageSelector,
    chatGroupByUsersIsLoadingSelector,
    chatGroupByUsersIsNextPageLoadingSelector,
    chatGroupByUsersSelector,
} from '../../store/selectors/chatGroupByUsersSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';

function ChatUsersGroupItemWrapper({ el, index, onClick }: ItemProp<IChatGroupByUser>) {
    function click() {
        if (onClick) {
            onClick(index);
        }
    }

    const { user_type, unseen_count, user, last_message } = el;

    return (
        <ChatItem
            avatar={(user?.photo_files?.previews && user?.photo_files?.previews[0]?.url) || null}
            datetime={last_message?.created_at || null}
            text={getUserName(user)}
            message_user={getUserName(last_message?.sender_user)}
            message_text={last_message?.text || null}
            user_type={user_type}
            unseen_count={unseen_count}
            onClick={click}
        />
    );
}

function ChatsUsersGroup() {
    const dispatch = useAppDispatch();

    const items = useSelector(chatGroupByUsersSelector);
    const filters = useSelector(chatGroupByUsersFiltersSelector);
    const isLoading = useSelector(chatGroupByUsersIsLoadingSelector);
    const hasNextPage = useSelector(chatGroupByUsersHasNextPageSelector);
    const isNextPageLoading = useSelector(chatGroupByUsersIsNextPageLoadingSelector);

    useEffect(() => {
        dispatch(resetChatGroupByUsersAction());
        return () => {
            dispatch(resetChatGroupByUsersAction());
        };
    }, []);

    async function getItems(startIndex: number) {
        try {
            await dispatch(
                getChatGroupByUsersThunk({
                    page: Math.floor(startIndex / ChatGroupByUserSize) + 1,
                    size: ChatGroupByUserSize,
                    ...filters.params,
                }),
            );
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function onClickChatUser(index: number) {
        if (!items) return;
        dispatch(setChatGroupByUserAction(items[index]));
    }

    return isLoading || filters.isLoading ? (
        <Loader />
    ) : (
        <InfiniteLoaderWrapper
            hasNextPage={hasNextPage}
            isNextPageLoading={isNextPageLoading}
            items={items || []}
            loadNextPage={getItems}
            Item={ChatUsersGroupItemWrapper}
            itemSize={72}
            onClickItem={onClickChatUser}
        />
    );
}

export default ChatsUsersGroup;
