import { EProfileActions } from '../../enums/actions.enum';
import { IProfileState, TProfileActions } from '../../interfaces/profile';

const initState: IProfileState = {
    isAuth: null,
    profile: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const profileReducer = (state = initialState, action: TProfileActions): IProfileState => {
    switch (action.type) {
        case EProfileActions.SetProfile:
            return {
                ...state,
                profile: action.payload,
            };
        case EProfileActions.SetIsAuth: {
            return { ...state, isAuth: action.payload };
        }
        case EProfileActions.Logout: {
            localStorage.removeItem('MY_TECH_TOKEN');
            // const prevUrl = window.location?.hash?.replace?.('#', '');
            // if (!prevUrl.includes('signin')) {
            // 	localStorage.setItem(localStorageItems.logoutlURL, prevUrl);
            // }
            return { ...initState, isAuth: false };
        }
        default:
            return state;
    }
};
