import React from 'react';

import MapContent from './MapContent';
import './Map.scss';

function Map() {
    return (
        <div className="map-detail-wrapper">
            <MapContent />
        </div>
    );
}

export default Map;
