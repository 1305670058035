import { AppDispatch } from 'store/store';

import { AxiosResponse } from 'axios';

import { issuesApi, issuesObserversApi } from '../../api';
import { EIssuesActions } from '../../enums/actions.enum';
import { EIssueStatus, EIssueTriggers } from '../../enums/issues.enum';
import { IPutCancelIssueStatusReq } from '../../interfaces/edit/editIssueStatusToCancel';
import { IPutSolvedIssueStatusReq } from '../../interfaces/edit/editIssueStatusToSolved';
import {
    changeIssueHelpTypeReq,
    changeIssuesMasterSearchReq,
    changeIssueWarrantyTypeReq,
    changeIssueSaleTypeReq,
    IAddressParts,
    IIssue,
    IIssuesFilters,
    IIssuesTableHead,
    INoteIssue,
    IPutIssueAppointmentReq,
    IPutIssueArchiveReq,
    IPutIssuePaymentMethodReq,
    ISetIsOpenCommentDialogAction,
    ISetIsOpenDeleteCommentDialogAction,
    ISetIssueAction,
    ISetIssuesAction,
    ISetIssuesFiltersAction,
    ISetIssuesPaginationAction,
    ISetIssuesTableHeadAction,
    ISetIsTableLoaderAction,
    ISetNotesIssueAction,
    ISetSelectedIssuesAction,
    ISetStatusFiltersAction,
    IStatusFilter,
    IStartAutoAssignmentIssueAction,
    ISetMasterSearchIssuesAction,
} from '../../interfaces/issues';
import { IAddObserverThunkParams } from '../../interfaces/issuesObserves';
import { IPagination } from '../../interfaces/pagination';
import { IWorker } from '../../interfaces/workers';
import { getOrderByStr } from '../../utils/helpers/getOrderByStr';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const setIssueAction = (payload: IIssue | null): ISetIssueAction => ({
    type: EIssuesActions.SetIssue,
    payload,
});

export const setIssuesAction = (payload: IIssue[] | null): ISetIssuesAction => ({
    type: EIssuesActions.SetIssues,
    payload,
});

export const setIssuesFiltersAction = (payload: IIssuesFilters | null): ISetIssuesFiltersAction => ({
    type: EIssuesActions.SetIssuesFilters,
    payload,
});

export const setStatusFiltersAction = (payload: IStatusFilter[] | null): ISetStatusFiltersAction => ({
    type: EIssuesActions.SetStatusFilters,
    payload,
});

export const setTableHeadAction = (payload: IIssuesTableHead[]): ISetIssuesTableHeadAction => ({
    type: EIssuesActions.SetIssuesTableHead,
    payload,
});

export const setIssuesPaginationAction = (payload: IPagination): ISetIssuesPaginationAction => ({
    type: EIssuesActions.SetIssuesPagination,
    payload,
});

export const setIsTableLoaderAction = (payload: boolean): ISetIsTableLoaderAction => ({
    type: EIssuesActions.SetIsTableLoader,
    payload,
});

export const setIsOpenCommentDialogAction = (payload: boolean): ISetIsOpenCommentDialogAction => ({
    type: EIssuesActions.SetIsOpenCommentDialog,
    payload,
});

export const setIsOpenDeleteCommentDialogAction = (payload: boolean): ISetIsOpenDeleteCommentDialogAction => ({
    type: EIssuesActions.SetIsOpenDeleteCommentDialog,
    payload,
});

export const setSelectedIssuesAction = (payload: IIssue['id'][] | null): ISetSelectedIssuesAction => ({
    type: EIssuesActions.SetSelectedIssues,
    payload,
});

export const setNotesIssueAction = (payload: INoteIssue[] | null): ISetNotesIssueAction => ({
    type: EIssuesActions.SetNotesIssue,
    payload,
});

export const setStartAutoAssignmentIssueAction = (payload: string[]): IStartAutoAssignmentIssueAction => ({
    type: EIssuesActions.StartAutoAssignmentIssue,
    payload,
});

export const setMasterSearchIssuesAction = (payload: string[]): ISetMasterSearchIssuesAction => ({
    type: EIssuesActions.SetMasterSearchIssuesAction,
    payload,
});

export interface IGetIssuesParams {
    filters?: IIssuesFilters | null;
    page?: IPagination['page'];
    size?: IPagination['size'];
}

export const getIssuesThunk =
    ({ filters, page, size }: IGetIssuesParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [{ page: page ? page + 1 : 1 }, { size: size || 50 }];
            if (filters) {
                const {
                    order_by,
                    statuses,
                    ratio_category_id__in,
                    is_archived,
                    created_at__gte,
                    created_at__lte,
                    time_window__gte,
                    time_window__lte,
                    category_preset,
                    help_type,
                    status,
                    partner_id__in,
                    has_reminders,
                    category_id,
                    observers_ids,
                    observers__isnull,
                } = filters;

                if (order_by) {
                    queriesObj.push({ order_by: getOrderByStr(order_by) });
                }

                if (is_archived !== undefined) {
                    queriesObj.push({ is_archived });
                }

                if (observers__isnull !== undefined) {
                    queriesObj.push({ observers__isnull });
                }

                if (has_reminders !== undefined) {
                    queriesObj.push({ has_reminders });
                }

                if (created_at__gte) {
                    queriesObj.push({ created_at__gte });
                }

                if (category_preset) {
                    queriesObj.push({ category_preset });
                }

                if (created_at__lte) {
                    queriesObj.push({ created_at__lte });
                }

                if (time_window__gte) {
                    queriesObj.push({ time_window__gte });
                }

                if (time_window__lte) {
                    queriesObj.push({ time_window__lte });
                }

                // eslint-disable-next-line no-shadow
                statuses?.forEach((status) => {
                    queriesObj.push({ status });
                });
                observers_ids?.forEach((observersId) => {
                    queriesObj.push({ observers_ids: observersId });
                });

                ratio_category_id__in?.forEach((id) => {
                    queriesObj.push({ ratio_category_id__in: id });
                });

                category_id?.forEach((id) => {
                    queriesObj.push({ category_id: id });
                });

                help_type?.forEach((helpType) => {
                    queriesObj.push({ help_type: helpType });
                });

                // eslint-disable-next-line no-shadow
                status?.forEach((status) => {
                    queriesObj.push({ status });
                });

                partner_id__in?.forEach((partner_id) => {
                    queriesObj.push({ partner_id__in: partner_id });
                });
            }
            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await issuesApi.getIssues(queries);
            const { items: issues, page: pageResp, size: sizeResp, total } = data;
            dispatch(setIssuesAction(issues));
            dispatch(setIssuesPaginationAction({ page: pageResp - 1, size: sizeResp, total }));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const getIssueThunk = (issueId: IIssue['id']) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await issuesApi.getIssue(issueId);
        dispatch(setIssueAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const addCommentThunk = (issueId: IIssue['id'], comment: { comment: string }) => async () => {
    try {
        const { data } = await issuesApi.addComment(issueId, comment);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const editCommentThunk =
    (issueId: IIssue['id'], commentId: string, comment: { comment: string }) => async () => {
        try {
            const { data } = await issuesApi.editComment(issueId, commentId, comment);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const deleteCommentThunk = (issueId: IIssue['id'], commentId: string) => async () => {
    try {
        const { data } = await issuesApi.deleteComment(issueId, commentId);
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

interface IPutIssueThunkParams {
    issueId: IIssue['id'];
    newIssue: Partial<IIssue>;
    issueTrigger?: EIssueTriggers | EIssueStatus;
}

export const putIssueThunk =
    ({ issueId, newIssue, issueTrigger }: IPutIssueThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            if (issueTrigger) {
                const { data } = await issuesApi.putIssueStatus(issueId, issueTrigger, null, newIssue);
                dispatch(setIssueAction(data));
            } else {
                const { data } = await issuesApi.putIssue(issueId, newIssue);
                dispatch(setIssueAction(data));
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface IPutIssueCandidatesThunkParams {
    issueId: IIssue['id'];
    workersIds: IWorker['id'][];
}

export const createIssueCandidatesThunk =
    ({ issueId, workersIds }: IPutIssueCandidatesThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            /* TODO выяснить что есть group_ids */
            const { data } = await issuesApi.createIssueCandidates(issueId, {
                worker_ids: workersIds,
                group_ids: [],
            });
            dispatch(setIssueAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface IPutIssueAppointmentThunkParams {
    issueId: IIssue['id'];
    data: IPutIssueAppointmentReq;
}

export const putIssueAppointmentThunk =
    ({ issueId, data }: IPutIssueAppointmentThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            let response: AxiosResponse<IIssue> | null = null;
            response = await issuesApi.putIssueAppointment(issueId, data);

            const { data: issue } = response;
            dispatch(setIssueAction(issue));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface IPutIssueAddressThunkParams {
    issueId: IIssue['id'];
    newAddressParts: IAddressParts;
}

export const putIssueAddressThunk =
    ({ issueId, newAddressParts }: IPutIssueAddressThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            let response: AxiosResponse<IIssue> | null = null;
            response = await issuesApi.putIssueAddress(issueId, newAddressParts);

            const { data: issue } = response;
            dispatch(setIssueAction(issue));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface IPutIssueStatusThunkParams {
    issueId: IIssue['id'];
    newStatus: EIssueStatus;
    putCancelIssueStatusData?: IPutCancelIssueStatusReq;
    putSolvedIssueStatusData?: IPutSolvedIssueStatusReq;
    comment?: string | null;
}

export const putIssueStatusThunk =
    ({ issueId, newStatus, putCancelIssueStatusData, putSolvedIssueStatusData, comment }: IPutIssueStatusThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            let response: AxiosResponse<IIssue> | null = null;
            /* TODO: выяснить чем на самом деле является второй аргумент putIssueStatus */
            switch (newStatus) {
                case EIssueStatus.New:
                    response = await issuesApi.putIssueStatus(issueId, EIssueStatus.New, comment);
                    break;
                case EIssueStatus.Processed:
                    response = await issuesApi.putIssueStatus(issueId, EIssueStatus.Processed, comment);
                    break;
                case EIssueStatus.MasterSearch:
                    response = await issuesApi.putIssueStatus(issueId, EIssueStatus.MasterSearch, comment);
                    break;
                case EIssueStatus.Canceled:
                    response = putCancelIssueStatusData
                        ? await issuesApi.putCancelIssueStatus(issueId, putCancelIssueStatusData)
                        : null;
                    break;
                case EIssueStatus.InProgress:
                    response = await issuesApi.putIssueStatus(issueId, EIssueStatus.InProgress, comment);
                    break;
                case EIssueStatus.WaitingForReceipt:
                    response = await issuesApi.putIssueStatus(issueId, EIssueTriggers.Done, comment);
                    break;
                case EIssueStatus.PaymentRequired:
                    response = await issuesApi.putIssueStatus(issueId, 'paymentRequired', comment);
                    break;
                case EIssueStatus.Solved:
                    response = await issuesApi.putIssueStatus(issueId, 'finish', comment, putSolvedIssueStatusData);
                    break;
                case EIssueStatus.WaitingCall:
                    response = await issuesApi.putIssueStatus(issueId, EIssueStatus.WaitingCall, comment);
                    break;
                case EIssueStatus.WaitingForCash:
                    response = await issuesApi.putIssueStatus(issueId, 'waitingForCash', comment);
                    break;
                case EIssueStatus.MasterOnWay:
                    response = await issuesApi.putIssueStatus(issueId, 'masterOnWay', comment);
                    break;
                case EIssueStatus.PartsSearch:
                    response = await issuesApi.putIssueStatus(issueId, 'partsSearch', comment);
                    break;
                case EIssueStatus.ActNRP:
                    response = await issuesApi.putIssueStatus(issueId, 'act_nrp', comment);
                    break;
                case EIssueStatus.SolvedNotPartner:
                    response = await issuesApi.putIssueStatus(issueId, 'solved_not_partner', comment);
                    break;
                default:
                    console.error('Unknown status');
            }
            if (!response?.data) {
                console.log('Warning!!! !response.data putIssueStatusThunk');
                return;
            }
            const { data: issue } = response;
            dispatch(setIssueAction(issue));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface IGetCountersIssueThunkParams {
    statusFilters: IStatusFilter[] | null;
    filters: IIssuesFilters;
}

export const getCountersIssueThunk =
    ({ statusFilters, filters }: IGetCountersIssueThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            if (!statusFilters) return;
            const counters: { [key: string]: EIssueStatus[] } = {};

            statusFilters.forEach(({ id, statuses }) => {
                counters[id] = statuses;
            });

            const { data } = await issuesApi.getCounters({ counters, filters });

            const newMockFilters = statusFilters.map((statusMock) => {
                const count = data[statusMock.id];
                return { ...statusMock, count };
            });

            dispatch(setStatusFiltersAction(newMockFilters));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const issueChargePostThunk = (issueId: IIssue['id']) => async (dispatch: AppDispatch) => {
    try {
        let response: AxiosResponse<IIssue> | null = null;
        response = await issuesApi.issueChargePost(issueId);

        const { data: issue } = response;
        dispatch(setIssueAction(issue));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

interface IPutPaymentMethodThunkParams {
    issueId: IIssue['id'];
    data: IPutIssuePaymentMethodReq;
}

export const putPaymentMethodThunk =
    ({ issueId, data }: IPutPaymentMethodThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data: issue } = await issuesApi.putPaymentMethod(issueId, data);
            dispatch(setIssueAction(issue));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface IPutIssueArchiveThunkParams {
    issueId: IIssue['id'];
    data: IPutIssueArchiveReq;
}

export const putIssueArchiveThunk =
    ({ issueId, data }: IPutIssueArchiveThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data: issue } = await issuesApi.putIssueArchive(issueId, data);
            dispatch(setIssueAction(issue));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const changeIssuesMasterSearchThunk = (data: changeIssuesMasterSearchReq) => async (dispatch: AppDispatch) => {
    try {
        await issuesApi.changeIssuesMasterSearch(data);
        dispatch(setMasterSearchIssuesAction(data.issue_ids));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const changeIssueHelpTypeThunk =
    (issueId: string, data: changeIssueHelpTypeReq) => async (dispatch: AppDispatch) => {
        try {
            const { data: issues } = await issuesApi.changeIssueHelpType(issueId, data);
            dispatch(setIssueAction(issues));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const getNotesMasterIssueThunk = (issueId: IIssue['id']) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await issuesApi.getNotesMasterIssue(issueId);
        dispatch(setNotesIssueAction(data));
        return data;
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const addObserverThunk =
    ({ issueId, request }: IAddObserverThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data: issues } = await issuesObserversApi.addObserver(issueId, request);
            dispatch(setIssueAction(issues));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const deleteObserverThunk =
    ({ issueId, request }: IAddObserverThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data: issues } = await issuesObserversApi.deleteObserver(issueId, request);
            dispatch(setIssueAction(issues));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const changeIssueWarrantyThunk =
    (issueId: string, data: changeIssueWarrantyTypeReq) => async (dispatch: AppDispatch) => {
        try {
            const { data: issues } = await issuesApi.changeIssueWarranty(issueId, data);
            dispatch(setIssueAction(issues));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const changeIssueSaleThunk =
    (issueId: string, data: changeIssueSaleTypeReq) => async (dispatch: AppDispatch) => {
        try {
            const { data: issues } = await issuesApi.changeIssueSale(issueId, data);
            dispatch(setIssueAction(issues));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const changeIssueActNRPThunk =
    (issueId: string, act_nrp_file_id: string | null) => async (dispatch: AppDispatch) => {
        try {
            const { data: issues } = await issuesApi.uploadActNrp(issueId, act_nrp_file_id);
            dispatch(setIssueAction(issues));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const startAutoAssignmentIssueThunk = (issueIds: string[]) => async (dispatch: AppDispatch) => {
    try {
        await issuesApi.startAutoAssignmentIssue(issueIds);
        dispatch(setStartAutoAssignmentIssueAction(issueIds));
    } catch (err) {
        console.log(err);
        throw err;
    }
};
