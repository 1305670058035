import { ChatHeader } from 'components/Chat/ChatHeader';
import ChatModule from 'modules/Chat2Module';
import { chatResetAction } from 'store/actions/chat';
import { useAppDispatch } from 'store/hooks';
import { chatDetailSelector } from 'store/selectors/chatSelectors';
import { chatsSelector } from 'store/selectors/chatsInfiniteSelectors';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export function Chat() {
    const dispatch = useAppDispatch();
    const chat = useSelector(chatDetailSelector);
    const chats = useSelector(chatsSelector);

    useEffect(() => {
        return () => {
            dispatch(chatResetAction());
        };
    }, []);

    if (!chat || !chats) {
        return (
            <div className="choose_chat">
                <span className="text">Выберите чат для начала работы</span>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <ChatHeader chat={chat} />
            <div style={{ flex: 1, overflow: 'hidden' }}>
                <ChatModule
                    channelId={chat.chat.channel_id}
                    chatId={chat.chat.id}
                    chat_available={chat.chat.chat_available}
                />
            </div>
        </div>
    );
}
