import { chatsApi, clientsApi, workersApi } from 'api';
import ChatUserType from 'components/Chat/ChatUserType';
import FilterAppType from 'components/FilterAppType';
import Loader from 'components/Loader';
import { EChatTypes, EChatUserTypes } from 'enums/chats.enum';
import { appTypeFiltersMock } from 'mockData/issues';
import { getChatDetailThunk } from 'store/actions/chat';
import { setChatGroupByUserAction } from 'store/actions/chatGroupByUser';
import { getChatsInfiniteThunk } from 'store/actions/chatsInfinite';
import { useAppDispatch } from 'store/hooks';
import { chatGroupByUserSelector } from 'store/selectors/chatGroupByUserSelectors';

import { Close, Search } from '@mui/icons-material';
import { Modal, styled, IconButton, Divider, TextField, InputAdornment, Avatar, Box } from '@mui/material';
import { IChatInfoReq, TUserType } from 'interfaces/chats';
import { IClient, TClientsResp } from 'interfaces/clients';
import { IWorker, TWorkersResp } from 'interfaces/workers';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

type Props = {
    type: 'user' | 'chat';
    onClose: () => void;
};

function AddChat({ onClose, type }: Props) {
    const dispatch = useAppDispatch();

    const chatUser = useSelector(chatGroupByUserSelector);

    const [users, setUsers] = useState<TClientsResp['items'] | TWorkersResp['items']>([]);
    const [isLoading, setLoading] = useState(false);

    const searchRef = useRef<HTMLInputElement>();

    const [filters, setFilters] = useState<{ user_type: string | null; search?: string }>({
        user_type: 'client',
        search: '',
    });

    const createChat = async (chat: IChatInfoReq) => {
        setLoading(true);
        const { data } = await chatsApi.postChatInfo(chat);
        const temp = {
            user_id: chat.user?.id,
            user_type: chat.user?.type,
        };

        const { data: newUser } = await chatsApi.getChatsUsers({ ...temp });

        if (chatUser) {
            dispatch(setChatGroupByUserAction(chatUser));
        } else {
            dispatch(setChatGroupByUserAction(newUser.items[0]));
        }

        await dispatch(
            getChatsInfiniteThunk({
                filters: temp,
                size: 30,
                sortOptions: new Map().set('last_message_at', 'desc').set('created_at', 'desc'),
            }),
        );
        await dispatch(getChatDetailThunk(data.chat.id));

        setLoading(false);
        onClose();
    };

    const filterUsersBySearch = async (search: string) => {
        setLoading(true);
        const {
            data: { items },
        } =
            filters.user_type === 'client'
                ? await clientsApi.getClients(`?search=${search.trim()}&user_type=${filters.user_type}`)
                : await workersApi.getWorkers(`?search=${search.trim()}&user_type=${filters.user_type}`);

        setUsers((prev) => items || prev);
        setLoading(false);
        setFilters((prev) => ({ ...prev, search }));
    };

    const stopFirstFilterUsersByTypeRef = useRef(true);

    const filterUsersByType = async (user_type: string | null) => {
        if (!filters.search) {
            setFilters((prev) => ({ ...prev, user_type }));
            return;
        }

        setUsers([]);

        setLoading(true);
        const {
            data: { items },
        } =
            filters.user_type === 'client'
                ? await workersApi.getWorkers(`?search=${filters.search?.trim()}`)
                : await clientsApi.getClients(`?search=${filters.search?.trim()}`);

        setUsers((prev) => items || prev);
        setFilters((prev) => ({ ...prev, user_type }));
        setLoading(false);
    };

    const filterUsersBySearchDebounced = useDebouncedCallback(filterUsersBySearch, 800);

    const renderList = (list: IClient[] | IWorker[]) => {
        if (isLoading) return <Loader />;
        else if (list?.length && filters.search)
            return list?.map((item) => (
                <User
                    key={item.id}
                    onClick={() => {
                        createChat({
                            user: {
                                id: item?.id!,
                                type: filters.user_type as EChatUserTypes,
                            },
                            chat_type: EChatTypes.Support,
                        });
                    }}
                >
                    <Avatar src={item?.photo_file?.url || (item as IWorker)?.profile_photo_file?.url} />
                    <Name>{`${item?.last_name || ''} ${item?.first_name || ''}`}</Name>
                    <Type>
                        <ChatUserType user_type={filters.user_type as TUserType} />
                    </Type>
                </User>
            ));
        else return filters.search ? 'Пользователи не найдены' : '';
    };

    return (
        <Modal
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            open
            onClose={onClose}
        >
            <Wrapper>
                <Header>
                    <Title>Создать чат</Title>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Header>
                <Divider />
                <Body>
                    {type === 'user' ? (
                        <>
                            <div>
                                <FilterAppType
                                    title="Тип пользователя"
                                    selectedId="client"
                                    onChange={(value) => {
                                        if (!stopFirstFilterUsersByTypeRef.current) {
                                            filterUsersByType(value);
                                        }

                                        stopFirstFilterUsersByTypeRef.current = false;
                                    }}
                                    filterItems={appTypeFiltersMock}
                                    closeAfterSelect
                                    unclearable
                                />
                            </div>
                            <div style={{ flex: 'auto' }}>
                                <TextField
                                    fullWidth
                                    placeholder="Поиск пользователя"
                                    variant="outlined"
                                    size="small"
                                    inputRef={searchRef}
                                    defaultValue={filters.search}
                                    onChange={(e) => {
                                        filterUsersBySearchDebounced(e.currentTarget.value);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Search />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                        </>
                    ) : chatUser?.user ? (
                        !isLoading && (
                            <User
                                sx={{ width: '100%' }}
                                onClick={() => {
                                    createChat({
                                        user: {
                                            id: chatUser?.user?.id!,
                                            type: chatUser?.user_type as EChatUserTypes,
                                        },
                                        chat_type: EChatTypes.Support,
                                    });
                                }}
                            >
                                <Avatar
                                    src={
                                        chatUser?.user?.photo_files?.url ||
                                        (chatUser?.user as unknown as IWorker)?.profile_photo_file?.url
                                    }
                                />
                                <Name>{`${chatUser?.user?.last_name || ''} ${chatUser?.user?.first_name || ''}`}</Name>
                                <Type>
                                    <ChatUserType user_type={chatUser?.user?.user_type as TUserType} />
                                </Type>
                            </User>
                        )
                    ) : (
                        !isLoading && <Box sx={{ width: '100%', textAlign: 'center' }}>Пользователь не найден</Box>
                    )}
                </Body>
                <Users
                    id="observer"
                    isLoading={isLoading}
                    isUser={type === 'user'}
                >
                    {renderList(users)}
                </Users>
            </Wrapper>
        </Modal>
    );
}

export default AddChat;

const Wrapper = styled('div')({
    width: '35%',
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
});

const Header = styled('div')({
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const Body = styled('div')({
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
});

const Users = styled('div')((props: { isLoading: boolean; isUser: boolean }) => ({
    position: 'relative',
    padding: `${props.isLoading ? '30px 16px 70px 16px' : !props.isUser ? '0' : '8px 16px 20px 16px'}`,
    textAlign: 'center',
    overflow: 'auto',
    maxHeight: '300px',
}));

const User = styled('div')({
    padding: '10px 15px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    border: '1px solid #00000040',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
        backgroundColor: '#E1E4FA',
        border: '1px solid #E1E4FA',
    },
});

const Title = styled('div')({
    color: '#161C46',
    fontWeight: 'bold',
    fontSize: '20px',
});

const Name = styled('div')({
    color: '#161C46',
    fontWeight: 'bold',
    fontSize: '16px',
});

const Type = styled('div')({
    color: '#161C46',
    marginLeft: 'auto',
});
