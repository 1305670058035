import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import Partners from './Partners';

import useOutsideClick from '../../hooks/useOutsideClick';
import { IPartner } from '../../interfaces/partners';
import { getPartnersThunk } from '../../store/actions/partners';
import { useAppDispatch } from '../../store/hooks';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import './PartnersFilter.scss';

interface IProps {
    selectedPartner: IPartner['id'] | null;
    onChange: (selectedPartnerId: IPartner['id'] | null) => void;
}

function PartnersFilter({ selectedPartner, onChange }: IProps) {
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getPartnersThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, []);

    return (
        <div
            ref={wrapperRef}
            className={cn('partners-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant="contained"
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className={selectedPartner ? 'is-active' : 'btn-filter'}
                endIcon={<ExpandMoreIcon />}
            >
                Партнеры
            </Button>
            <Partners
                selectedPartner={selectedPartner}
                onChange={onChange}
            />
        </div>
    );
}

export default PartnersFilter;
