import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { IOption } from 'interfaces';

import ImportTableHead from './ImportTableHead';
import ImportTableRow from './ImportTableRow';

import { IImportGsisData } from '../../../interfaces/importGsis';
import './ImportTable.scss';

interface IProps {
    data: IImportGsisData[];
    onChangeSelectedSimilarIssue: (idx: number, value: string | null) => void;
    onChangeDevice: (option: IOption | null, id: string) => void;
    options: IOption[];
    searchClient: (val: string) => void;
    onBlurSearch: () => void;
}

function ImportTable({
    data,
    onChangeSelectedSimilarIssue,
    onChangeDevice,
    options,
    searchClient,
    onBlurSearch,
}: IProps) {
    return (
        <Paper className={'import-table-wrapper'}>
            {data && data.length > 0 ? (
                <TableContainer className={'import-table-container'}>
                    <Table
                        stickyHeader
                        size={'small'}
                    >
                        <ImportTableHead />
                        <TableBody>
                            {data.map((item, idx) => {
                                return (
                                    <ImportTableRow
                                        idx={idx}
                                        item={item}
                                        key={item.issue.issue_code}
                                        options={options}
                                        onBlurSearch={onBlurSearch}
                                        onChangeSelectedSimilarIssue={onChangeSelectedSimilarIssue}
                                        onChangeDevice={onChangeDevice}
                                        searchClient={searchClient}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <div>Выберите файл для загрузки</div>
            )}
        </Paper>
    );
}

export default ImportTable;
