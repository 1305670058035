import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IProfile } from '../interfaces/profile';

export default class ProfileAPI {
    getProfile() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/profile`,
            isAuthorization: true,
        }) as AxiosPromise<IProfile>;
    }
}
