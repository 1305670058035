import TextField from 'components/inputs/TextField';
import Loader from 'components/Loader';
import {
    setCategoriesRatiosFilterAction,
    setSearchCRFAction,
    setSelectedCRFAction,
} from 'store/actions/categoriesRatiosFilter';
import { useAppDispatch } from 'store/hooks';
import {
    categoriesCRFSelector,
    searchingCRFSelector,
    selectedCRFSelector,
} from 'store/selectors/categoriesRatiosFilterSelectors';
import { getCategoriesRFWithChecked } from 'utils/helpers/getCategoriesRFWithChecked';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Paper,
} from '@mui/material';
import { ICategoryRatiosFilter } from 'interfaces/categoriesRatiosFilter';
import React, { ChangeEvent, memo, MouseEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';

import './Categories.scss';

interface IProps {
    isSingleSelect?: boolean;
}

export function Categories({ isSingleSelect }: IProps) {
    const dispatch = useAppDispatch();

    const categories = useSelector(categoriesCRFSelector);
    const selectedCategories = useSelector(selectedCRFSelector);
    const searchingCategories = useSelector(searchingCRFSelector);

    async function onCheckedHandler(e: ChangeEvent<HTMLInputElement>, checked: boolean) {
        if (!categories) return;

        const categoryId = e.target.value;

        let foundCategory: ICategoryRatiosFilter | null = null;

        const recursiveFind = (ctgr: ICategoryRatiosFilter[]) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const ctg of ctgr) {
                if (categoryId === ctg.id) {
                    foundCategory = { ...ctg, checked };
                    break;
                } else if (ctg.childs?.length) {
                    recursiveFind(ctg.childs);
                }
            }
        };

        let newCategories = categories.map((category) => ({ ...category }));
        if (isSingleSelect) {
            newCategories = categories.map((category) => ({ ...category, checked: false }));
        }

        recursiveFind(newCategories);

        const fnCategory = foundCategory as unknown as ICategoryRatiosFilter;
        if (!fnCategory) {
            console.error('Error!!! category not found in categories onCheckedHandler()');
            return;
        }

        const recursiveNewCategoriesChangeChecked = (ctgr: ICategoryRatiosFilter[]) => {
            return ctgr.map((category) => {
                const newCategory = { ...category, checked: isSingleSelect ? false : category.checked };
                if (newCategory.id === fnCategory.id) {
                    newCategory.checked = fnCategory.checked;
                    /* если изменился родитель содержащий дочерние переопределяем дочерние */
                    if (newCategory.childs?.length && fnCategory.childs?.length) {
                        newCategory.childs = newCategory.childs.map((ctg) => ({
                            ...ctg,
                            checked: isSingleSelect ? false : checked,
                        }));
                        fnCategory.childs = fnCategory.childs.map((ctg) => ({ ...ctg, checked }));
                    }
                    return newCategory;
                }
                if (newCategory.childs?.length) {
                    newCategory.childs = recursiveNewCategoriesChangeChecked(newCategory.childs);
                    return newCategory;
                }
                return newCategory;
            });
        };

        newCategories = recursiveNewCategoriesChangeChecked(newCategories);

        dispatch(setCategoriesRatiosFilterAction(newCategories));
        let newSelectedCategories = [...(selectedCategories || [])];

        if (fnCategory.checked) {
            const { childs } = fnCategory;
            if (childs?.length && !isSingleSelect) {
                // eslint-disable-next-line no-shadow
                const checkedChilds = childs.filter(({ checked }) => checked);
                newSelectedCategories = newSelectedCategories.filter(({ id }) => {
                    if (fnCategory.id === id) return false;
                    return checkedChilds.some((child) => child.id !== id);
                });
                dispatch(setSelectedCRFAction([...newSelectedCategories, ...checkedChilds, fnCategory]));
            } else if (isSingleSelect) {
                dispatch(setSelectedCRFAction([fnCategory]));
            } else {
                dispatch(setSelectedCRFAction([...newSelectedCategories, fnCategory]));
            }
        } else {
            const deleteIds: string[] = [];
            newSelectedCategories.forEach(({ id }) => {
                if (id === fnCategory.id) {
                    deleteIds.push(id);
                }
                fnCategory.childs?.forEach(({ id: childId }) => {
                    if (childId === id) {
                        deleteIds.push(id);
                    }
                });
            });

            newSelectedCategories = newSelectedCategories.filter((category) => {
                return !deleteIds.find((dId) => dId === category.id);
            });
            dispatch(setSelectedCRFAction(newSelectedCategories));
        }
    }

    function onSearchHandler(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setSearchCRFAction(e.target.value));
    }

    function onClickFormGroup(e: MouseEvent) {
        e.stopPropagation();
    }

    const getCategories = useCallback(
        (categoriesCRF: ICategoryRatiosFilter[]) => {
            return categoriesCRF.map((ctg) => {
                return (
                    <Accordion
                        key={ctg.id}
                        className="categories-accordion"
                        TransitionProps={{ unmountOnExit: true }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            id={ctg.id}
                        >
                            <FormGroup onClick={onClickFormGroup}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={onCheckedHandler}
                                            value={ctg.id}
                                            checked={ctg.checked}
                                        />
                                    }
                                    label={ctg.title}
                                />
                            </FormGroup>
                        </AccordionSummary>
                        <Divider />
                        {!!ctg.childs?.length && (
                            <AccordionDetails sx={{ backgroundColor: 'rgb(248, 249, 255)' }}>
                                {ctg.childs.map(({ id, checked, title }) => {
                                    return (
                                        <FormGroup
                                            key={id}
                                            sx={{ marginLeft: 1 }}
                                            onClick={onClickFormGroup}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={onCheckedHandler}
                                                        value={id}
                                                        checked={checked}
                                                    />
                                                }
                                                label={title}
                                            />
                                        </FormGroup>
                                    );
                                })}
                            </AccordionDetails>
                        )}
                    </Accordion>
                );
            });
        },
        [categories],
    );

    function onClear() {
        dispatch(setSelectedCRFAction(null));
        dispatch(setSearchCRFAction(null));
        categories && dispatch(setCategoriesRatiosFilterAction(getCategoriesRFWithChecked(categories)));
    }

    return (
        <Paper className="ratios-categories-wrapper">
            <TextField
                name="search-ratios-categories"
                onChange={onSearchHandler}
                placeholder="Поиск по регионам"
                fullWidth
            />
            <div className="scroll-wrapper">
                {(() => {
                    switch (true) {
                        case !!searchingCategories:
                            return !!searchingCategories && getCategories(searchingCategories);
                        case !!categories:
                            return !!categories && getCategories(categories);
                        default:
                            return <Loader />;
                    }
                })()}
            </div>
            <Button
                onClick={onClear}
                fullWidth
            >
                Очистить фильтр
            </Button>
        </Paper>
    );
}

export default memo(Categories);
