import { LoadingButton as MuiButton, LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material';
import { CSSProperties } from 'react';

const defaultStyles: CSSProperties = {
    height: '36.5px',
    fontSize: '14px',
    fontWeight: '600',
    textTransform: 'none',
};

const ContainedButton = styled(MuiButton)({
    ...defaultStyles,
    backgroundColor: '#0040F1',
    '&:hover': {
        backgroundColor: '#0040F1',
    },
});

const OutlinedButton = styled(MuiButton)({
    ...defaultStyles,
    color: '#0040F1',
    borderColor: '#0040F1',
    backgroundColor: '#fff',
    ':hover': {
        backgroundColor: '#fff',
    },
});

export function Button({ children, ...props }: LoadingButtonProps) {
    switch (props.variant) {
        case 'outlined':
            return <OutlinedButton {...props}>{children}</OutlinedButton>;
        default:
            return <ContainedButton {...props}>{children}</ContainedButton>;
    }
}
