import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import ReminderMenu from './ReminderMenu';
import ReminderTooltip from './ReminderTooltip';

import { getIssueRemindersThunk } from '../../store/actions/reminders';
import { useAppDispatch } from '../../store/hooks';
import { remindersSelector } from '../../store/selectors/remindersSelector';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';

function Reminder() {
    const { issueId } = useParams();
    const dispatch = useAppDispatch();
    const reminders = useSelector(remindersSelector);

    useEffect(() => {
        async function getReminders() {
            try {
                if (!issueId) return;
                await dispatch(getIssueRemindersThunk(issueId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        getReminders();
    }, [issueId]);

    if (!reminders?.length) {
        return null;
    }
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
        >
            <ReminderTooltip reminders={reminders} />
            {reminders.length}
            <ReminderMenu reminders={reminders} />
        </Box>
    );
}

export default Reminder;
