import { TableHead, TableRow } from '@mui/material';

import { HeadCell } from './styles';

export const Head = () => {
    return (
        <TableHead>
            <TableRow>
                <HeadCell sx={{ textAlign: 'start' }}>СИСТЕМНОЕ НАИМЕНОВАНИЕ</HeadCell>
                <HeadCell>НАИМЕНОВАНИЕ</HeadCell>
                <HeadCell>ПАРТНЕР</HeadCell>
                <HeadCell sx={{ width: '115px' }}>ТИП УСЛУГИ</HeadCell>
                <HeadCell>РЕГИОН</HeadCell>
                <HeadCell sx={{ width: '145px' }}>ДАТА СОЗДАНИЯ</HeadCell>
                <HeadCell sx={{ paddingRight: 0 }}>СТАТУС</HeadCell>
                <HeadCell sx={{ paddingLeft: 0 }} />
            </TableRow>
        </TableHead>
    );
};
