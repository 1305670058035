import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { ICategory } from '../interfaces/categories';
import { ICategoryRatios, IPartnerRatios, IRatioDetail } from '../interfaces/ratios';

export default class RatiosAPI {
    getCategories(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/regionality/tree${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<ICategoryRatios[]>;
    }

    getPartners(categoryId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/ratios/${categoryId}/partners`,
            isAuthorization: true,
        }) as AxiosPromise<IPartnerRatios[]>;
    }

    getRatio(categoryId: ICategory['id'], queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/ratios/${categoryId}/byCategory${
                queries || ''
            }`,
            isAuthorization: true,
        }) as AxiosPromise<IRatioDetail>;
    }

    editRatio(ratioId: string, data: { value: number }) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/ratios/${ratioId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IRatioDetail>;
    }
}
