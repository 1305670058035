import { Dialog } from '@mui/material';
import { memo, useState } from 'react';

import { IPhotoFile } from '../../interfaces';
import './PhotoFiles.scss';

interface IProps {
    photoFiles: IPhotoFile[];
}

function PhotoFiles({ photoFiles }: IProps) {
    const [activeUrl, setUrl] = useState('');
    return (
        <div className="dv-photo-files">
            {photoFiles.map(({ url, id }) => (
                <a
                    key={id}
                    onClick={() => setUrl(url)}
                    style={{ cursor: 'pointer' }}
                >
                    <img
                        src={url}
                        alt=""
                    />
                </a>
            ))}
            <Dialog
                sx={{ '.MuiPaper-root': { background: 'transparent', width: 'auto', maxWidth: 'fit-content' } }}
                open={Boolean(activeUrl)}
                onClose={() => setUrl('')}
                fullWidth
            >
                <img
                    src={activeUrl}
                    style={{
                        margin: '0 auto',
                        objectFit: 'contain',
                        maxHeight: '100vh',
                        maxWidth: '100%',
                        overflow: 'hidden',
                    }}
                    alt="preview"
                />
            </Dialog>
        </div>
    );
}

export default memo(PhotoFiles);
