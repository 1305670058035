import { Paper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import WorkerSolvedIssueHistoryItem from './WorkerSolvedIssueHistoryItem';

import Loader from '../../../components/Loader';
import { workerSolvedIssueHistorySelector } from '../../../store/selectors/workerSelectors';

import './WorkerSolvedIssueHistory.scss';

function WorkerSolvedIssueHistory() {
    const workerSolvedIssueHistory = useSelector(workerSolvedIssueHistorySelector);

    return (
        <Paper className="worker-solved-issue-history">
            <div className="issue-history-title">История заявок</div>
            {!workerSolvedIssueHistory && <Loader />}
            {workerSolvedIssueHistory?.length ? (
                workerSolvedIssueHistory.map((issue, index) => (
                    <WorkerSolvedIssueHistoryItem
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        issue={issue}
                    />
                ))
            ) : (
                <span className="empty-issue-history-text">Пока нет заказов</span>
            )}
        </Paper>
    );
}

export default WorkerSolvedIssueHistory;
