import { EGenerateXlsActions } from '../../enums/actions.enum';
import { IGenerateXlsState, TGenerateXlsActions } from '../../interfaces/generateXls';

const initState: IGenerateXlsState = {
    formValues: null,
    errors: null,
    isOpen: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const generateXlsReducer = (state = initialState, action: TGenerateXlsActions): IGenerateXlsState => {
    switch (action.type) {
        case EGenerateXlsActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EGenerateXlsActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case EGenerateXlsActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        default:
            return state;
    }
};
