import { EChatActions } from '../../enums/actions.enum';
import { IChatState, TChatActions } from '../../interfaces/chat';

const initState: IChatState = {
    chatDetail: null,
    authorClient: null,
    authorWorker: null,
    issueHistoryForClient: null,
    issueHistoryForWorker: null,
    chatHistory: null,
    chatParticipants: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const chatReducer = (state = initialState, action: TChatActions): IChatState => {
    switch (action.type) {
        case EChatActions.ChatReset:
            return initState;
        case EChatActions.SetChatDetail:
            return {
                ...state,
                chatDetail: action.payload,
            };
        case EChatActions.SetChatAuthorClient:
            return {
                ...state,
                authorClient: action.payload,
            };
        case EChatActions.SetChatAuthorWorker:
            return {
                ...state,
                authorWorker: action.payload,
            };
        case EChatActions.SetChatIssueHistoryForClient:
            return {
                ...state,
                issueHistoryForClient: action.payload,
            };
        case EChatActions.SetChatIssueHistoryForWorker:
            return {
                ...state,
                issueHistoryForWorker: action.payload,
            };
        case EChatActions.SetChatHistory:
            return {
                ...state,
                chatHistory: action.payload,
            };
        case EChatActions.AddChatHistoryItem:
            return {
                ...state,
                chatHistory: state.chatHistory ? [...state.chatHistory, action.payload] : [action.payload],
            };
        case EChatActions.DeleteChatHistoryItem:
            return {
                ...state,
                chatHistory: state.chatHistory?.filter((item) => item.id !== action.payload) || null,
            };
        case EChatActions.MarkAsViewedChatHistoryItems: {
            const newChatHistoryItems = state.chatHistory?.map((item) => {
                if (action.payload.includes(item.id)) {
                    const newItem = JSON.parse(JSON.stringify(item));
                    newItem.is_viewed = true;
                    return newItem;
                }
                return item;
            });
            return {
                ...state,
                chatHistory: newChatHistoryItems || null,
            };
        }
        case EChatActions.SetChatParticipants:
            return {
                ...state,
                chatParticipants: action.payload,
            };
        default:
            return state;
    }
};
