import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import { setIsOpenDeletePriceAction } from '../../../store/actions/prices';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenDeletePriceSelector, priceSelector } from '../../../store/selectors/pricesSelectors';

function DeletePrice() {
    const dispatch = useAppDispatch();

    const price = useSelector(priceSelector);
    const isOpen = useSelector(isOpenDeletePriceSelector);

    function handleClose() {
        dispatch(setIsOpenDeletePriceAction(false));
    }

    return (
        <div className="iss-cndts-item">
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        gap={1}
                    >
                        <ErrorOutlineIcon
                            color="error"
                            fontSize="small"
                        />
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {!price?.is_additional ? 'Удалить основную услугу' : 'Удалить дополнительную услугу'}
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        Хотите удалить услугу <strong>{price?.name}</strong>?
                    </Box>
                    <Box>После этого, она не будет доступна для выбора в заявках</Box>
                </DialogContent>
                <DialogActions>
                    <FooterForm />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeletePrice;
