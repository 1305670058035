import { EAutoAssignmentStatus, EIssueStatus } from '../../enums/issues.enum';
import { IIssue } from '../../interfaces/issues';

export const issueForAutoAssignment = (issue: IIssue) => {
    return !!(
        (issue.status === EIssueStatus.MasterNotFound || issue.status === EIssueStatus.MasterSearch) &&
        !(
            issue.auto_assignment_status === EAutoAssignmentStatus.Started ||
            issue.auto_assignment_status === EAutoAssignmentStatus.Pending
        ) &&
        issue.address_parts &&
        issue.address_parts.lat &&
        issue.address_parts.long
    );
};

export const issueForMasterSearch = (issue: IIssue) => {
    return !!(
        (issue.status === EIssueStatus.Processed || issue.status === EIssueStatus.MasterNotFound) &&
        issue.address_parts &&
        issue.address_parts.lat &&
        issue.address_parts.long
    );
};
