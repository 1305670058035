import { Checkbox, Chip } from '@mui/material';
import React, { memo } from 'react';

import DeviceOrder from './Device';
import EditOrderButton from './EditOrderButton';
import PhotoFile from './PhotoFile';

import { IOrder, ISelectedOrders, ISortOrder } from '../../../../../../interfaces/orders';
import { partsOrderEditButtonAllowed, partsOrdersStatusMock } from '../../../../../../mockData/partsOrders';
import { setSetSelectedOrdersAction } from '../../../../../../store/actions/orders';
import { useAppDispatch } from '../../../../../../store/hooks';
import { getFullNameHomeDevice } from '../../../../../../utils/helpers/getFullNameHomeDevice';
import './Order.scss';

interface IProps {
    orderSubIssue: ISortOrder;
    selectedOrders: ISelectedOrders[] | null;
}

function Order({ orderSubIssue, selectedOrders }: IProps) {
    const dispatch = useAppDispatch();
    const { client_id, home_device, subIssueOrders, issue_id, worker } = orderSubIssue;

    function onChangeSelectedOrders(order: IOrder, checked: boolean) {
        const { id, status, spare_part } = order || {};
        checked
            ? dispatch(
                  setSetSelectedOrdersAction(
                      selectedOrders ? [...selectedOrders, { id, status, spare_part }] : [{ id, status, spare_part }],
                  ),
              )
            : dispatch(
                  setSetSelectedOrdersAction(
                      selectedOrders && selectedOrders.filter(({ id: orderId }) => orderId !== id),
                  ),
              );
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {subIssueOrders?.length ? (
                <div className="order">
                    <div className="order-name">
                        {getFullNameHomeDevice(home_device)}
                        <DeviceOrder
                            client_id={client_id}
                            issue_id={issue_id}
                            home_device={home_device}
                            deviceName={getFullNameHomeDevice(home_device)}
                        />
                    </div>
                    <div className="order-photos">
                        <PhotoFile device_photo={home_device.device_photo_file || null} />
                        <PhotoFile device_photo={home_device.nameplate_photo_file || null} />
                    </div>
                    <div className="parts-list">
                        {subIssueOrders.map((order) => (
                            <div
                                key={order.id}
                                className="part"
                            >
                                <div className="part-name">
                                    <div className="part_desc">
                                        <div>Артикул</div>
                                        <div>{order.spare_part.item_number}</div>
                                    </div>
                                    <div className="part_desc">
                                        <div>Наименование</div>
                                        <div>{order.spare_part.name}</div>
                                    </div>
                                    <div className="part_desc">
                                        <div>Количество</div>
                                        <div>{`${order.quantity} шт.`}</div>
                                    </div>
                                    <div className="part_desc">
                                        <div>Отправка в ПВЗ</div>
                                        <div>{`${order.is_client_pick_up ? 'Да' : 'Нет'}`}</div>
                                    </div>
                                    {order.device_failure_photo && (
                                        <div className="part_desc">
                                            <div>Фото поломки</div>
                                            <PhotoFile device_photo={order.device_failure_photo} />
                                        </div>
                                    )}
                                </div>
                                <div className="part-info">
                                    <Chip
                                        label={partsOrdersStatusMock[order.status].title}
                                        sx={{
                                            fontWeight: '600',
                                            backgroundColor: partsOrdersStatusMock[order.status].colorHex,
                                            color: partsOrdersStatusMock[order.status].colorText,
                                            '&:hover': {
                                                backgroundColor:
                                                    `${partsOrdersStatusMock[order.status].colorHex}`.slice(0, -2) + 99,
                                            },
                                        }}
                                        size={'small'}
                                    />
                                    {partsOrderEditButtonAllowed.includes(order.status) && (
                                        <div>
                                            <Checkbox
                                                onChange={(_, checked) => onChangeSelectedOrders(order, checked)}
                                                value={order.id}
                                                checked={
                                                    !!(
                                                        selectedOrders &&
                                                        selectedOrders?.length &&
                                                        selectedOrders.find(
                                                            (selectedOrder) => selectedOrder.id === order.id,
                                                        )
                                                    )
                                                }
                                                disabled={
                                                    selectedOrders && selectedOrders?.length
                                                        ? selectedOrders[0].status !== order.status
                                                        : false
                                                }
                                            />
                                            <EditOrderButton
                                                order={order}
                                                worker={worker}
                                                isDisabled={
                                                    selectedOrders && !!selectedOrders?.length
                                                        ? selectedOrders[0].status === order.status
                                                        : false
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </>
    );
}

export default memo(Order);
