import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EChatTypes } from '../../../../enums/chats.enum';
import { IMessageWithSenderInfo } from '../../../../interfaces/chat';
import { profileSelector } from '../../../../store/selectors/profileSelectors';
import Message from '../Message';
import './Messages.scss';

interface IProp {
    messages: IMessageWithSenderInfo[];
    deleteMessage: (id: string) => void;
    markMessageAsViewed: (messageId: string) => void;
    chat_type?: EChatTypes;
}

function Messages({ messages, deleteMessage, markMessageAsViewed, chat_type }: IProp) {
    function getMessagesFilteredByTime(
        messagesList: IMessageWithSenderInfo[],
    ): Record<string, IMessageWithSenderInfo[]> {
        const composedMessages: Record<string, IMessageWithSenderInfo[]> = { СЕГОДНЯ: [] };
        const todayDate = format(new Date(), 'dd.MM.yyyy');
        if (!messagesList) return { СЕГОДНЯ: [] };
        messagesList.forEach((message) => {
            const createdAtDate = format(new Date(message.created_at), 'dd.MM.yyyy');
            if (createdAtDate === todayDate) {
                composedMessages['СЕГОДНЯ'].push(message);
            } else if (!composedMessages[createdAtDate]) {
                composedMessages[createdAtDate] = [message];
            } else {
                composedMessages[createdAtDate].push(message);
            }
        });
        return composedMessages;
    }
    const messagesByDates = getMessagesFilteredByTime(messages);

    // первое непрочитанное мной сообщение (id)
    const profileState = useSelector(profileSelector);
    const firstUnseenMessageId: string | null =
        [...messages].reverse().find((message) => {
            return message.sender?.id !== profileState?.id && !message.seen;
        })?.id || null;

    // отправить запрос через 500ms спустя "входа" нового сообщения в #viewport только в чате "МАСТЕР КЛИЕНТ"
    const [unseenMessageId, setUnseenMessageId] = useState<string>('');
    useEffect(() => {
        const timer = setTimeout(() => {
            const message = messages.find((messageItem) => messageItem.id === unseenMessageId);
            if (message && chat_type === EChatTypes.IssueRelated) {
                markMessageAsViewed(message.id);
            }
        }, 500);
        return () => clearTimeout(timer);
    }, [unseenMessageId]);

    // подписка на "вход" сообщений в #viewport
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries &&
                    entries.forEach((entry) => {
                        entry.intersectionRatio > 0 && setUnseenMessageId(entry.target.id);
                    });
            },
            {
                root: document.querySelector('#viewport'),
                rootMargin: '0px',
                threshold: [0.5],
            },
        );
        const messageElements = document.querySelectorAll('.message-wrapper');
        messageElements?.forEach((messageElement) => {
            observer.observe(messageElement);
        });
    }, [JSON.parse(JSON.stringify(messages))]);

    return messages ? (
        <div
            id="#viewport"
            className="messages"
        >
            {Object.keys(messagesByDates)
                .reverse()
                .map((key) => {
                    return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={key}>
                            <div className="delimiter">{key}</div>
                            {messagesByDates[key]?.reverse().map((message) => {
                                return (
                                    <div key={message.id}>
                                        {firstUnseenMessageId && firstUnseenMessageId === message.id && (
                                            <div className="delimiter">Новые сообщения</div>
                                        )}
                                        <div
                                            id={message.id}
                                            className="message-wrapper"
                                        >
                                            <Message
                                                message={message}
                                                deleteMessage={deleteMessage}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    ) : null;
}

export default Messages;
