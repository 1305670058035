import { ECreateGroupActions } from '../../../enums/actions.enum';
import { ICreateGroupState, TCreateGroupActions } from '../../../interfaces/create/createGroup';

const initState: ICreateGroupState = {
    isOpen: false,
    workersList: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const createGroupReducer = (state = initialState, action: TCreateGroupActions): ICreateGroupState => {
    switch (action.type) {
        case ECreateGroupActions.SetCreateGroupIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case ECreateGroupActions.SetWorkersList:
            return {
                ...state,
                workersList: action.payload,
            };
        default:
            return state;
    }
};
