import { EPriceServicesDialogActions } from '../../enums/actions.enum';
import { TErrors } from '../../interfaces';
import {
    IFormValuesPriceServicesDialog,
    IOpenPriceServicesDialog,
    ISetErrorsPriceServicesDialogAction,
    ISetFormValuesPriceServicesDialogAction,
    ISetIsOpenPriceServicesDialogAction,
} from '../../interfaces/priceServicesDialog';

export const setIsOpenPriceServicesDialogAction = (
    payload: IOpenPriceServicesDialog,
): ISetIsOpenPriceServicesDialogAction => ({
    type: EPriceServicesDialogActions.SetIsOpen,
    payload,
});

export const setFormValuesPriceServicesDialogAction = (
    payload: IFormValuesPriceServicesDialog | null,
): ISetFormValuesPriceServicesDialogAction => ({
    type: EPriceServicesDialogActions.SetFormValues,
    payload,
});

export const setErrorsPriceServicesDialogAction = (payload: TErrors | null): ISetErrorsPriceServicesDialogAction => ({
    type: EPriceServicesDialogActions.SetErrors,
    payload,
});
