import { Box, styled } from '@mui/material';

export const CheckboxWrapper = styled(Box)({
    marginTop: '14px',
    display: 'flex',
    gap: '10px',
});

export const ButtonsWrapper = styled('div')({
    display: 'flex',
    gap: '15px',
    marginTop: '23px',
});
