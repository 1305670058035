import { createSelector } from 'reselect';

import { EPermissionSpecial } from '../../enums/permissions.enum';
import { RootState } from '../rootReducer';

const state = ({ profile }: RootState) => profile;

export const profileIsAuthSelector = createSelector(state, ({ isAuth }) => isAuth);
export const profileSelector = createSelector(state, ({ profile }) => profile);
export const companyProfileSelector = createSelector(state, ({ profile }) => profile?.company || null);
export const roleProfileSelector = createSelector(state, ({ profile }) => profile?.role || null);
export const permissionsSelector = createSelector([companyProfileSelector, roleProfileSelector], (company, role) => {
    if (company) {
        return company.permissions;
    } else if (role) {
        return role.permissions;
    } else {
        return EPermissionSpecial.Owner;
    }
});
