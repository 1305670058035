import React from 'react';

import PhotoFiles from '../../../../../../../components/PhotoFiles';
import { IPhotoFile } from '../../../../../../../interfaces';

interface IProps {
    device_photo: IPhotoFile | null;
}

function PhotoFile({ device_photo }: IProps) {
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {device_photo ? (
                <div className="part-photo">
                    <PhotoFiles photoFiles={device_photo ? [device_photo] : []} />
                </div>
            ) : null}
        </>
    );
}

export default PhotoFile;
