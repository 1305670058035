import { Grid, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '../../../../../components/inputs/Autocomplete';
import DatePicker from '../../../../../components/inputs/DatePicker';
import { IOption } from '../../../../../interfaces';
import { getTimeWindowsSlots } from '../../../../../mockData/timeWindows';
import { setFormValuesCIAction } from '../../../../../store/actions/create/createIssue';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsCreateIssueSelector,
    formValuesCreateIssueSelector,
} from '../../../../../store/selectors/create/createIssuesSelectors';

function TimeWindows() {
    const dispatch = useAppDispatch();

    const errors = useSelector(errorsCreateIssueSelector);
    const formValues = useSelector(formValuesCreateIssueSelector);

    function changeDateHandler(val: Date | null) {
        dispatch(setFormValuesCIAction({ date: val }));
    }

    function changeTimeWindowHandler(option: IOption | null) {
        dispatch(setFormValuesCIAction({ time: (option?.value as string) || null }));
    }

    const timeWindows = useMemo(() => getTimeWindowsSlots(formValues?.help_type), [formValues?.help_type]);

    return (
        <div>
            <Typography
                variant="caption"
                display="block"
                gutterBottom
                fontSize={12}
                sx={{ mt: 2 }}
            >
                Дата и время вызова
            </Typography>
            <Grid
                container
                spacing={2}
                alignItems="center"
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <DatePicker
                        name="date"
                        placeholder="ДД.ММ.ГГГГ"
                        label="Дата вызова*"
                        fullWidth
                        disablePast
                        errors={errors}
                        onChange={changeDateHandler}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <Autocomplete
                        name={'time'}
                        inpPlaceholder={'Время вызова*'}
                        errors={errors}
                        options={timeWindows.map(({ startTime, endTime }) => {
                            return {
                                value: `${startTime}-${endTime}`,
                                label: `${startTime}-${endTime}`,
                            };
                        })}
                        onChange={changeTimeWindowHandler}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default TimeWindows;
