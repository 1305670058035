import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Popover } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

export interface IButtonGroupItem {
    title: string;
    disabled?: boolean;
    handler: () => void;
}

interface IProps {
    title: string;
    buttonsInfo: IButtonGroupItem[];
    color?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    icon?: React.ReactNode;
    variant?: 'text' | 'contained' | 'outlined';
}

function ButtonGroup({ title, buttonsInfo, color = 'primary', icon, variant = 'contained' }: IProps) {
    const [isOpen, setIsOpen] = useState(null);

    return (
        <div className="button-group">
            <Button
                color={color}
                variant={variant}
                onClick={(e: any) => setIsOpen(e.currentTarget)}
                endIcon={icon || <ExpandMoreIcon />}
            >
                {title}
            </Button>
            {isOpen && (
                <Popover
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setIsOpen(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: { boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)' },
                    }}
                >
                    {buttonsInfo.map((buttonInfo) => {
                        return (
                            <MenuItem
                                key={buttonInfo.title}
                                disabled={buttonInfo.disabled}
                                onClick={() => {
                                    buttonInfo.handler();
                                    setIsOpen(null);
                                }}
                            >
                                {buttonInfo.title}
                            </MenuItem>
                        );
                    })}
                </Popover>
            )}
        </div>
    );
}

export default ButtonGroup;
