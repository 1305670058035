import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import ReminderItem from './ReminderItem';

import { IReminder } from '../../../interfaces/reminder';

interface IProps {
    reminders: IReminder[];
}

function ReminderMenu({ reminders }: IProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <IconButton
                aria-owns="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
                size="small"
                sx={{ cursor: 'pointer' }}
            >
                <ExpandMoreIcon fontSize="small" />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {reminders.map((reminder) => (
                    <MenuItem key={reminder.id}>
                        <ReminderItem
                            reminder={reminder}
                            handleClose={handleClose}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default ReminderMenu;
