import { DependencyList, RefObject, useEffect, useRef } from 'react';

type Props = {
    rootId: string;
    onViewInter: () => void;
};

type Hook = ({ rootId, onViewInter }: Props, deps?: DependencyList) => [RefObject<HTMLDivElement>];

export const useIntersection: Hook = ({ rootId, onViewInter }, deps) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const options = {
            root: document.getElementById(rootId),
            rootMargin: '0px',
            threshold: [0.5],
        };

        const observer = new IntersectionObserver((entries: IntersectionObserverEntry[]) => {
            const entry = entries[0];
            if (entry.intersectionRatio > 0.4) {
                onViewInter();
            }
        }, options);

        if (ref.current) observer.observe(ref.current);

        return () => observer.disconnect();
    }, [ref.current, ...(deps || [])]);

    return [ref];
};
