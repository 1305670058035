import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EditIssueStatusForm from './EditIssueStatusForm';

import { EIssueStatus } from '../../../enums/issues.enum';
import {
    setErrorsEditIssueStatusAction,
    setFormValuesEditIssueStatusAction,
    setIsOpenEditIssueStatusAction,
} from '../../../store/actions/edit/editIssueStatus';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenEditIssueStatusSelector } from '../../../store/selectors/edit/editIssueStatusSelectors';

import './EditIssueStatus.scss';

interface IProps {
    currentStatus: EIssueStatus | null;
}

function EditIssueStatus({ currentStatus }: IProps) {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenEditIssueStatusSelector);

    function onClose() {
        dispatch(setIsOpenEditIssueStatusAction(false));
        dispatch(setFormValuesEditIssueStatusAction(null));
        dispatch(setErrorsEditIssueStatusAction(null));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <Box className="edit-issue-status-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                    <div className="title-text">Изменить статус в заявке</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditIssueStatusForm currentStatus={currentStatus} />
            </DialogContent>
        </Dialog>
    );
}

export default EditIssueStatus;
