export const AddGroup = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="11"
                cy="11"
                r="11"
                fill="#0040F1"
            />
            <path
                d="M11 6V11V16M16 11L6 11"
                stroke="#F5F7FC"
                strokeWidth="2"
            />
        </svg>
    );
};
