import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '../../../../../components/inputs/Autocomplete';
import TextField from '../../../../../components/inputs/TextField';
import { IOption } from '../../../../../interfaces';
import { IEducation } from '../../../../../interfaces/workers';
import {
    setAdditionalEducationAction,
    setEducationalProgramAction,
    setEducationTypeAction,
    setErrorsEditWorkerInfoAction,
    setInstitutionNameAction,
    setIsOpenEditWorkerInfoAction,
} from '../../../../../store/actions/edit/editWorkerInfo';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsEditWorkerInfoSelector,
    isLoadingInfoSelector,
    isOpenEditWorkerInfoSelector,
} from '../../../../../store/selectors/edit/editInfoSelectors';
import EditEducation from '../EditEducation';
import { workerExperience, workersLicenses } from '../index';
import './EditInfo.scss';

interface IProps {
    label: string;
    props: { defaultValue: string | IOption<string>[]; placeholder: string };
    editInfo: (value: string | string[] | IEducation) => void;
}

function EditInfo({ label, props, editInfo }: IProps) {
    const dispatch = useAppDispatch();

    const [value, setValue] = useState<string | string[]>([]);
    const [educationValue, setEducationValue] = useState<IEducation | null>(null);

    const isOpen = useSelector(isOpenEditWorkerInfoSelector);
    const isLoading = useSelector(isLoadingInfoSelector);
    const errors = useSelector(errorsEditWorkerInfoSelector);

    function changeField(e: React.ChangeEvent<HTMLInputElement>) {
        !e.target.value.trim()
            ? dispatch(setErrorsEditWorkerInfoAction({ name: { message: 'Незаполненное поле' } }))
            : dispatch(setErrorsEditWorkerInfoAction(null));
        if (props?.placeholder === 'Адрес' && e.target.value.length < 10 && e.target.value.trim()) {
            dispatch(setErrorsEditWorkerInfoAction({ name: { message: 'Недостаточно символов' } }));
        }
        setValue(e.target.value);
    }

    function selectLicenses(options: IOption<string>[]) {
        setValue(options.map((option) => option.value));
    }

    function setExperience(experience: string) {
        setValue(experience);
    }

    function changeEducation(education: IEducation) {
        setEducationValue(education);
    }

    function getDialogContent() {
        switch (label) {
            case 'Изменение категории прав':
                return (
                    <Autocomplete
                        name="driving-licences"
                        errors={errors}
                        margin={'normal'}
                        multiple
                        options={workersLicenses.map((license) => ({ value: license, label: license }))}
                        defaultValue={props?.defaultValue as IOption<string>[]}
                        label={props?.placeholder}
                        onChange={selectLicenses}
                    />
                );
            case 'Изменение стажа работы':
                return (
                    <RadioGroup
                        row
                        name="experience"
                        className="edit-experience"
                        defaultValue={props?.defaultValue}
                        onChange={(_, experienceValue) => {
                            setExperience(experienceValue);
                        }}
                    >
                        {workerExperience.map((experience, index) => {
                            return (
                                <FormControlLabel
                                    key={index}
                                    value={`${index}`}
                                    control={<Radio />}
                                    label={experience}
                                />
                            );
                        })}
                    </RadioGroup>
                );
            case 'Изменение образования':
                return <EditEducation onChange={changeEducation} />;
            default:
                return (
                    <TextField
                        name={'name'}
                        errors={errors}
                        sxStyle={{ mb: 4, mt: 2, width: 550 }}
                        placeholder={props?.placeholder}
                        defaultValue={props?.defaultValue as string}
                        onChange={changeField}
                        multiline
                    />
                );
        }
    }

    function onEditInfo() {
        if (label === 'Изменение образования' && educationValue) {
            editInfo(educationValue);
        } else {
            editInfo(value);
        }
    }

    useEffect(() => {
        if (props?.defaultValue) {
            typeof props.defaultValue === 'string'
                ? setValue(props.defaultValue)
                : setValue(props.defaultValue.map((defaultValue) => defaultValue.value));
        } else {
            setValue([]);
        }
    }, [props]);

    function onCloseHandler() {
        dispatch(setIsOpenEditWorkerInfoAction(false));
        dispatch(setErrorsEditWorkerInfoAction(null));
        dispatch(setAdditionalEducationAction(null));
        dispatch(setEducationTypeAction(null));
        dispatch(setInstitutionNameAction(null));
        dispatch(setEducationalProgramAction(null));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="edit-info-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <DriveFileRenameOutlineOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">{label}</Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ minHeight: '20vh' }}>
                {getDialogContent()}
                <div className="buttons">
                    <Button
                        onClick={onCloseHandler}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        sx={{ ml: 1 }}
                        disabled={!!errors}
                        onClick={onEditInfo}
                    >
                        Изменить
                    </LoadingButton>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default EditInfo;
