import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Avatar, IconButton } from '@mui/material';

import ChatUnseenCount from './ChatUnseenCount';
import ChatUserType from './ChatUserType';
import { getUserName } from './tools';

import { IChatGroupByUser } from '../../interfaces/chatGroupByUsers';
import './Chat.scss';

interface ChatItemProps {
    item: IChatGroupByUser;
    onClick?: () => void;
}

export function ChatItemHeader({ item, onClick }: ChatItemProps) {
    const { user, user_type, unseen_count } = item;
    return (
        <div className="chat_header">
            <div>
                <IconButton
                    onClick={onClick}
                    aria-label="arrow-back"
                >
                    <ArrowBackIcon />
                </IconButton>
            </div>
            <Avatar src={user?.photo_files?.previews?.[0]?.url} />
            <div className="chat_header_inner">
                <div>
                    <div>{getUserName(user)}</div>
                    <ChatUserType user_type={user_type} />
                </div>
                <div>{unseen_count ? <ChatUnseenCount unseen_count={unseen_count} /> : null}</div>
            </div>
        </div>
    );
}

//export ChatItemHeader;
