import { EEditIssuePricesActions } from '../../../enums/actions.enum';
import { IEditIssuePricesState, TEditIssuePricesActions } from '../../../interfaces/edit/editIssuePrices';

const initState: IEditIssuePricesState = {
    isOpen: false,
    isOpenClient: false,
    issueId: null,
    staticPrices: null,
    formValues: null,
    errors: null,
    searchPrices: null,
    allPrices: null,
    priceRecipient: null,
    dialogTitle: null,
};

const initialState = { ...initState };

export const editIssuePricesReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TEditIssuePricesActions,
): IEditIssuePricesState => {
    switch (action.type) {
        case EEditIssuePricesActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditIssuePricesActions.SetIsOpenClient:
            return {
                ...state,
                isOpenClient: action.payload,
            };
        case EEditIssuePricesActions.SetIssueId:
            return {
                ...state,
                issueId: action.payload,
            };
        case EEditIssuePricesActions.SetStaticPrices:
            return {
                ...state,
                staticPrices: action.payload,
            };
        case EEditIssuePricesActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload,
            };
        case EEditIssuePricesActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case EEditIssuePricesActions.SetSearchPrices:
            return {
                ...state,
                searchPrices: action.payload,
            };
        case EEditIssuePricesActions.SetAllPrices:
            return {
                ...state,
                allPrices: action.payload,
            };
        case EEditIssuePricesActions.SetPriceRecipient:
            return {
                ...state,
                priceRecipient: action.payload,
            };
        case EEditIssuePricesActions.SetDialogTitle:
            return {
                ...state,
                dialogTitle: action.payload,
            };
        default:
            return state;
    }
};
