import { EWebsocketsActions } from '../../enums/actions.enum';
import { IWSState, TWebsocketsApiActions } from '../../interfaces/websockets';

const initState: IWSState = {
    history: null,
    channel: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const websocketsReducer = (state = initialState, action: TWebsocketsApiActions): IWSState => {
    switch (action.type) {
        case EWebsocketsActions.SetWSHistory:
            return {
                ...state,
                history: action.payload,
            };

        case EWebsocketsActions.SetWSChannel:
            return {
                ...state,
                channel: action.payload,
            };
        default:
            return state;
    }
};
