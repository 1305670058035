import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';

import Observer from './Observer';

import useOutsideClick from '../../hooks/useOutsideClick';
import './ObserversFilter.scss';

interface IProps {
    selectedObserverIds: string[] | null;
    selectedObserverIsnull: boolean;
    profileId: string | null;
    onChangeObserveIds: (observers_ids: string[] | null) => void;
    onChangeObserveIsNull: (observers__isnull: boolean) => void;
    onClear: () => void;
}

function ObserversFilter({
    selectedObserverIds,
    selectedObserverIsnull,
    profileId,
    onChangeObserveIds,
    onChangeObserveIsNull,
    onClear,
}: IProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    return (
        <div
            ref={wrapperRef}
            className={cn('observe-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant={
                    (selectedObserverIds && !!selectedObserverIds?.length) || selectedObserverIsnull
                        ? 'filterActive'
                        : 'filter'
                }
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                endIcon={<ExpandMoreIcon />}
            >
                Наблюдатель{' '}
                {selectedObserverIds && !!selectedObserverIds?.length && (
                    <span style={{ marginLeft: 10 }}>{selectedObserverIds?.length}</span>
                )}
            </Button>
            <Observer
                selectedObserverIds={selectedObserverIds}
                onChangeObserveIds={onChangeObserveIds}
                onChangeObserveIsNull={onChangeObserveIsNull}
                onClear={onClear}
                profileId={profileId}
                selectedObserverIsnull={selectedObserverIsnull}
            />
        </div>
    );
}

export default ObserversFilter;
