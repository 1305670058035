import { AppDispatch } from 'store/store';

import { chatsApi } from '../../api';
import { EChatsActions } from '../../enums/actions.enum';
import {
    IChat,
    IChatsFilters,
    ISetChatsAction,
    ISetChatsFiltersAction,
    ISetChatsPaginationAction,
    ISetChatsSortOptionsAction,
    ISetIsChatsTableLoaderAction,
    ISetTotalUnseenCountAction,
    ISetTotalUnseenCountIssuesAction,
    TChatsSortField,
    TDirection,
} from '../../interfaces/chats';
import { IPagination } from '../../interfaces/pagination';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const resetChatsActions = () => ({
    type: EChatsActions.ResetChats,
});

export const setChatsAction = (payload: IChat[] | null): ISetChatsAction => ({
    type: EChatsActions.SetChats,
    payload,
});

export const setIsChatsTableLoaderAction = (payload: boolean): ISetIsChatsTableLoaderAction => ({
    type: EChatsActions.SetIsChatsTableLoader,
    payload,
});

export const setChatsPaginationAction = (payload: IPagination | null): ISetChatsPaginationAction => ({
    type: EChatsActions.SetChatsPagination,
    payload,
});

export const setChatsFiltersAction = (payload: Partial<IChatsFilters> | null): ISetChatsFiltersAction => ({
    type: EChatsActions.SetChatsFilters,
    payload,
});

export const setChatsSortOptionsAction = (payload: {
    sortField: TChatsSortField;
    direction: TDirection;
}): ISetChatsSortOptionsAction => ({
    type: EChatsActions.SetChatsSortOptions,
    payload,
});

export const setTotalUnseenCountAction = (payload: number | null): ISetTotalUnseenCountAction => ({
    type: EChatsActions.SetTotalUnseenCount,
    payload,
});

export const setTotalUnseenCountIssuesAction = (payload: number | null): ISetTotalUnseenCountIssuesAction => ({
    type: EChatsActions.SetTotalUnseenIssuesCount,
    payload,
});

interface IGetChatsThunkParams {
    page?: IPagination['page'];
    size?: IPagination['size'];
    filters?: Partial<IChatsFilters> | null;
    sortOptions?: Map<TChatsSortField, TDirection>;
}

function makeChatsQueries({ page, size, filters, sortOptions }: IGetChatsThunkParams): string {
    const queriesObj: TArrayParams = [{ page: page ? page + 1 : 1 }, { size: size || 50 }];

    queriesObj.push({ chat_type: 'support' });

    if (filters) {
        const {
            created_at__gte,
            created_at__lte,
            last_message_at__gte,
            last_message_at__lte,
            app_type,
            user_type,
            user_id,
        } = filters;

        if (created_at__gte) {
            queriesObj.push({ created_at__gte });
        }

        if (created_at__lte) {
            queriesObj.push({ created_at__lte });
        }

        if (last_message_at__gte) {
            queriesObj.push({ last_message_at__gte });
        }

        if (last_message_at__lte) {
            queriesObj.push({ last_message_at__lte });
        }

        if (app_type) {
            queriesObj.push({ app_type });
        }

        if (user_type) {
            queriesObj.push({ user_type });
        }

        if (user_id) {
            queriesObj.push({ user_id });
        }
    }

    if (sortOptions) {
        sortOptions.forEach((direction, sortField) => {
            queriesObj.push({
                order_by: !(direction === 'asc') ? `-${sortField}` : sortField,
            });
        });
    }

    return getQueryParamsAsString(queriesObj);
}

export const getChatsThunk = (params: IGetChatsThunkParams) => async (dispatch: AppDispatch) => {
    try {
        dispatch(setIsChatsTableLoaderAction(true));
        const queries = makeChatsQueries(params);
        const { data } = await chatsApi.getChatsOld(queries);
        const { items: chats, page: pageResp, size: sizeResp, total } = data;
        dispatch(setChatsAction(chats));
        dispatch(setChatsPaginationAction({ page: pageResp - 1, size: sizeResp, total }));
    } catch (err) {
        console.error(err);
        throw err;
    } finally {
        dispatch(setIsChatsTableLoaderAction(false));
    }
};

export const getTotalUnseenCountThunk = () => async (dispatch: AppDispatch) => {
    try {
        const { data } = await chatsApi.getTotalUnseenCount();
        dispatch(setTotalUnseenCountAction(data.count));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
