import { priceListsApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import { fetchPriceLists, setPriceList } from 'pages/price-lists/store';
import { EllipseGreen } from 'static/Icons/EllipseGreen';
import { ThreeDots } from 'static/Icons/ThreeDots';
import { useAppDispatch } from 'store/hooks';

import { Popover, TableBody, TableRow } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ButtonItem, Cell } from './styles';

import { CopyModal } from '../modals';

type Props = {
    rows: any[];
};

export const Body: FC<Props> = ({ rows }) => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [activePriceListId, setActivePriceListId] = useState();

    const [isOpen, setOpen] = useState<any>(false);
    const [isOpenCopyModal, setOpenCopyModal] = useState<any>(false);

    const handleDelete = () => {
        if (!activePriceListId) return;

        priceListsApi.deletePriceList(activePriceListId).then(() => {
            setOpen(false);
            dispatch(fetchPriceLists());

            enqueueSnackbar('Прайс лист успешно удален', { variant: 'success' });
        });
    };

    const handleCopy = () => {
        dispatch(setPriceList(rows.find((item) => item.id === activePriceListId)));
        setOpenCopyModal(true);
        setOpen(false);
    };

    return (
        <TableBody>
            {rows.map((item) => {
                return (
                    <TableRow
                        key={item.id}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`${item.id}/${ERoutes.PriceListMain}`)}
                    >
                        <Cell sx={{ textAlign: 'start' }}>
                            <Link to={`${item.id}`}>{item.name}</Link>
                        </Cell>
                        <Cell>{item.system_name}</Cell>
                        <Cell>{item.company?.name}</Cell>
                        <Cell>{item.service_type?.name}</Cell>
                        <Cell>{item.region?.name}</Cell>
                        <Cell>{item.created_at.slice(0, 10)}</Cell>
                        <Cell sx={{ position: 'relative', paddingRight: 0 }}>
                            <EllipseGreen status={item.is_active} />
                        </Cell>
                        <Cell
                            sx={{ paddingLeft: '10px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(e.currentTarget);
                                setActivePriceListId(item.id);
                            }}
                        >
                            <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <ThreeDots />
                            </span>
                        </Cell>
                    </TableRow>
                );
            })}
            <Popover
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorEl={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ '.MuiPaper-root': { borderRadius: '8px' } }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0' }}>
                    <ButtonItem
                        color="inherit"
                        onClick={handleCopy}
                    >
                        Копировать прайс-лист
                    </ButtonItem>
                    <ButtonItem
                        color="inherit"
                        onClick={handleDelete}
                    >
                        Удалить прайс-лист
                    </ButtonItem>
                    <ButtonItem color="inherit">Активировать</ButtonItem>
                    <ButtonItem color="inherit">Деактивировать</ButtonItem>
                </div>
            </Popover>
            {isOpenCopyModal && <CopyModal setOpen={setOpenCopyModal} />}
        </TableBody>
    );
};
