import React from 'react';

import PlacemarkWorker from './Placemark';

import { ISelectedWorkerPin, IWorker } from '../../../../../interfaces/map';
import { getFullName } from '../../../../../utils/helpers/getFullName';

interface IProps {
    workers: IWorker[] | null;
    selectedPinWorker: ISelectedWorkerPin | null;
}

function ClusterWorkers({ workers, selectedPinWorker }: IProps) {
    const getPointWorkerData = (worker: IWorker) => {
        return {
            balloonContentHeader: `<div>${getFullName(worker)}</div>`,
            hintContent: `${getFullName(worker)}`,
            balloonContentBody: `
				<div>
					<div>${worker.addresses ? worker.addresses[worker.addresses.length - 1].address : 'адрес не указан'}</div>
				</div>`,
        };
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {workers?.map((worker) => (
                <PlacemarkWorker
                    key={worker.id}
                    worker={worker}
                    getPointWorkerData={getPointWorkerData}
                    selectedPinWorker={selectedPinWorker}
                />
            ))}
        </>
    );
}

export default ClusterWorkers;
