import FilterAppType from 'components/FilterAppType';
import { appTypeFiltersMock } from 'mockData/issues';
import {
    getChatGroupByUsersFiltersThunk,
    setChatGroupByUsersFiltersAction,
    setReloadAction,
} from 'store/actions/chatGroupByUsers';
import { useAppDispatch } from 'store/hooks';
import { chatGroupByUsersFiltersSelector } from 'store/selectors/chatGroupByUsersSelectors';

import { Add, Search } from '@mui/icons-material';
import { Button, InputAdornment, Radio, TextField, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

const ControlsWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: '10px 0',
});

const Btn = styled(Button)({
    textTransform: 'none',
    marginLeft: 'auto',
});

type Props = {
    onAddChatClick: () => void;
};

export function UsersControls({ onAddChatClick }: Props) {
    const dispatch = useAppDispatch();

    const searchRef = useRef<HTMLInputElement>(null);

    const filters = useSelector(chatGroupByUsersFiltersSelector);

    const [hasUnread, setHasUnread] = useState(filters.params.has_unread_message || false);

    const filterByUserType = (user_type: string | null) => {
        if (filters.params.user_type === user_type) return;
        dispatch(getChatGroupByUsersFiltersThunk({ ...filters.params, user_type }));
    };

    const filterByUnreadMessage = (has_unread_message: boolean) => {
        dispatch(
            getChatGroupByUsersFiltersThunk({ ...filters.params, has_unread_message: has_unread_message || null }),
        );
    };

    const filterBySearch = (search: string) => {
        dispatch(getChatGroupByUsersFiltersThunk({ ...filters.params, search: search.trim() || null }));
    };

    const filterBySearchDebounced = useDebouncedCallback(filterBySearch, 800);

    const resetFilters = () => {
        setHasUnread(false);

        if (searchRef.current) {
            searchRef.current.value = '';
        }

        dispatch(
            setChatGroupByUsersFiltersAction({
                params: {
                    search: null,
                    has_unread_message: null,
                    user_type: null,
                },
                isLoading: false,
            }),
        );
    };

    const reloadCallback = () => {
        resetFilters();
    };

    useEffect(() => {
        dispatch(setReloadAction({ reloadCallback }));
    }, []);

    return (
        <ControlsWrapper>
            <TextField
                placeholder="Поиск пользователя"
                variant="outlined"
                inputRef={searchRef}
                defaultValue={filters.params.search}
                size="small"
                onChange={(e) => filterBySearchDebounced(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
            <FilterAppType
                selectedId={filters.params.user_type || undefined}
                title="Тип пользователя"
                onChange={filterByUserType}
                filterItems={appTypeFiltersMock}
                closeAfterSelect
            />

            <Button
                onClick={() => {
                    setHasUnread((prev) => {
                        filterByUnreadMessage(!prev);
                        return !prev;
                    });
                }}
                startIcon={
                    <Radio
                        checked={hasUnread}
                        sx={{ padding: 0 }}
                    />
                }
                variant="filter"
            >
                Непрочитанные
            </Button>
            <Btn
                onClick={onAddChatClick}
                variant="contained"
                startIcon={<Add />}
            >
                Создать чат
            </Btn>
        </ControlsWrapper>
    );
}
