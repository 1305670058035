import { EModelsActions } from '../../enums/actions.enum';
import { IModelsState, TModelsActions } from '../../interfaces/models';

const initState: IModelsState = {
    model: null,
    modelDetail: null,
    models: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const modelsReducer = (state = initialState, action: TModelsActions): IModelsState => {
    switch (action.type) {
        case EModelsActions.SetModel:
            return {
                ...state,
                model: action.payload,
            };
        case EModelsActions.SetModelDetail:
            return {
                ...state,
                modelDetail: action.payload,
            };
        case EModelsActions.SetModels:
            return {
                ...state,
                models: action.payload,
            };
        default:
            return state;
    }
};
