import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IServiceArea, TAddressesResp } from '../interfaces/dicts';

export default class DictsAPI {
    getServiceAreas() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/dicts/serviceAreas`,
            isAuthorization: true,
        }) as AxiosPromise<IServiceArea[]>;
    }

    getAddresses(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/dicts/addresses${queries}`,
            isAuthorization: true,
        }) as AxiosPromise<TAddressesResp>;
    }
}
