import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ map }: RootState) => map;

export const filtersMapSelector = createSelector(state, ({ filters }) => filters);
export const mapTabSelector = createSelector(state, ({ mapTab }) => mapTab);
export const areasMapSelector = createSelector(state, ({ areas }) => areas);
export const issuesMapSelector = createSelector(state, ({ issues }) => issues);
export const workersMapSelector = createSelector(state, ({ workers }) => workers);
export const selectedIssuesMapSelector = createSelector(state, ({ selectedIssues }) => selectedIssues);
export const selectedWorkersMapSelector = createSelector(state, ({ selectedWorkers }) => selectedWorkers);
export const selectedAreaMapSelector = createSelector(state, ({ selectedArea }) => selectedArea);
export const isLoadingMapSelector = createSelector(state, ({ isLoadingMap }) => isLoadingMap);
export const pinMapSelector = createSelector(state, ({ pin }) => pin);
export const selectedPinIssueMapSelector = createSelector(state, ({ selectedPinIssue }) => selectedPinIssue);
export const selectedPinWorkerMapSelector = createSelector(state, ({ selectedPinWorker }) => selectedPinWorker);
