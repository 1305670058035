import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { IArea } from '../../../../interfaces/map';
import { setPinAction, setSelectedAreaAction } from '../../../../store/actions/map';
import { useAppDispatch } from '../../../../store/hooks';
import { areasMapSelector, selectedAreaMapSelector } from '../../../../store/selectors/mapSelectors';
import { getFullRegion } from '../../../../utils/helpers/getFullRegion';
import '../SidebarContent.scss';

function AreasContent() {
    const dispatch = useAppDispatch();

    const areas = useSelector(areasMapSelector);
    const selectedArea = useSelector(selectedAreaMapSelector);

    const onSelectArea = (area: IArea, index: number) => {
        if (!area?.geo?.coordinates?.length) return;
        dispatch(setSelectedAreaAction({ id: area.id, index } || null));
        dispatch(setPinAction(area?.geo?.coordinates?.length ? area.geo.coordinates[0][0][0] : null));
    };

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {areas?.length ? (
                <ul className="areas__content scroll-wrapper">
                    {areas?.map((area, index) => (
                        <li
                            key={area.id}
                            className={cn('areas__content_item', { 'is-selected-area': selectedArea?.id === area.id })}
                            onClick={() => onSelectArea(area, index)}
                        >
                            <span>{getFullRegion(area)}</span>
                            {!area?.geo?.coordinates?.length ? <i>координаты не указаны</i> : null}
                        </li>
                    ))}
                </ul>
            ) : (
                'Для начала выберите фильтр "Регионы"'
            )}
        </>
    );
}

export default AreasContent;
