import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import { useAppDispatch } from 'store/hooks';

import { Button, Tab, Tabs, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

import { Header, ModalWrapper, TitleWrapper } from './styles';

import { fetchPriceList, setPriceList } from '../store';
import { priceListsSelector } from '../store/selectors';
import { Container } from '../styles';

export const PriceListPage = () => {
    const location = useLocation();
    const params = useParams<{ priceListId: string }>();

    const dispatch = useAppDispatch();

    const { priceList } = useSelector(priceListsSelector);

    const getSelectedTab = () => {
        if (location.pathname.match(ERoutes.PriceListMain)) return 0;
        if (location.pathname.match(ERoutes.PriceListComplexServices)) return 1;
    };

    useEffect(() => {
        if (!params.priceListId) return;

        dispatch(fetchPriceList(params.priceListId));

        return () => {
            dispatch(setPriceList(null));
        };
    }, []);

    return (
        <MainLayout>
            <Container>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <TitleWrapper>
                        <Typography
                            fontWeight={700}
                            sx={{ fontSize: '28px' }}
                        >
                            {priceList?.name}
                        </Typography>
                        <Button sx={{ color: '#0040F1' }}>
                            <Link to={`/${ERoutes.PriceLists}/`}>Назад</Link>
                        </Button>
                    </TitleWrapper>
                    <ModalWrapper>
                        <Header>
                            <Tabs value={getSelectedTab()}>
                                <Link to={ERoutes.PriceListMain}>
                                    <Tab
                                        value={0}
                                        label="Основное"
                                    />
                                </Link>
                                <Link to={ERoutes.PriceListComplexServices}>
                                    <Tab
                                        value={1}
                                        label="Комплексы услуг"
                                    />
                                </Link>
                            </Tabs>
                        </Header>
                        <Outlet />
                    </ModalWrapper>
                </div>
            </Container>
        </MainLayout>
    );
};
