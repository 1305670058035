import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
    getArchiveWorkersThunk,
    setArchiveWorkersPaginationAction,
    setArchiveWorkersSortOptionsAction,
} from '../../../../store/actions/archive';
import { useAppDispatch } from '../../../../store/hooks';
import {
    archiveIsWorkersTableLoaderSelector,
    archiveWorkersFiltersSelector,
    archiveWorkersPaginationSelector,
    archiveWorkersSelector,
    archiveWorkersSortOptionsSelector,
} from '../../../../store/selectors/archiveSelectors';
import { debounceTimerHelper } from '../../../../utils/helpers/debounceTimer';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import WorkersTable from '../../../workers/WorkersTable';

function ArchiveWorkersTable() {
    const dispatch = useAppDispatch();

    const workers = useSelector(archiveWorkersSelector);
    const isLoading = useSelector(archiveIsWorkersTableLoaderSelector);
    const pagination = useSelector(archiveWorkersPaginationSelector);
    const filters = useSelector(archiveWorkersFiltersSelector);
    const sortOptions = useSelector(archiveWorkersSortOptionsSelector);

    useEffect(() => {
        dispatch(
            setArchiveWorkersPaginationAction({ page: 0, size: pagination?.size || 50, total: pagination?.total || 0 }),
        );
    }, [filters]);

    useEffect(() => {
        async function handle() {
            /* Необходимо, чтоб избежать рекурсии так как по завершении thunk меняется pagination?.page */
            await debounceTimerHelper({ callback: getWorkers, delay: 200 });
        }
        handle();
    }, [pagination.page, pagination.size, filters, sortOptions]);

    async function getWorkers() {
        try {
            await dispatch(
                getArchiveWorkersThunk({
                    page: pagination.page,
                    size: pagination.size,
                    filters,
                    sortOptions,
                }),
            );
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <WorkersTable
            workers={workers}
            isLoader={isLoading}
            pagination={pagination}
            changePagination={(newPagination) => dispatch(setArchiveWorkersPaginationAction(newPagination))}
            sortOptions={sortOptions}
            changeSortOption={(sortOption) => dispatch(setArchiveWorkersSortOptionsAction(sortOption))}
        />
    );
}

export default memo(ArchiveWorkersTable);
