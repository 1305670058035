import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IRole } from '../interfaces/roles';

export default class RolesAPI {
    getRoles() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/roles`,
            isAuthorization: true,
        }) as AxiosPromise<IRole[]>;
    }
}
