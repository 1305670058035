import { AppDispatch } from 'store/store';

import { historyApi } from '../../api';
import { EHistoryActions } from '../../enums/actions.enum';
import { IHistory, ISetHistoryAction } from '../../interfaces/history';
import { IIssue } from '../../interfaces/issues';

export const setHistoryAction = (payload: IHistory[] | null): ISetHistoryAction => ({
    type: EHistoryActions.SetHistory,
    payload,
});

export const getHistoryThunk = (issueId: IIssue['id']) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await historyApi.getHistory(issueId);
        const { items } = data;
        dispatch(setHistoryAction(items));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
