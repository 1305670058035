export default function mergeArrays<T>(array1: T[], array2: T[], key: (value: T) => string): T[] {
    const map = array1.reduce((acc, value) => {
        acc.set(key(value), value);
        return acc;
    }, new Map<string, T>());

    array2.forEach((value) => {
        map.set(key(value), value);
    });

    return Array.from(map.values());
}
