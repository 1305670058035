import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import {
    IFormValuesCreateOrder,
    IPutCancelOrderThunkParams,
    IPutNextStatusOrderThunkParams,
    IUpdateOrderThunkParams,
} from '../interfaces/create/createOrder';

export default class OrdersAPI {
    /* Получение заказов */
    getOrders(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/partsOrders${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Создание заказа */
    createOrders(data: IFormValuesCreateOrder) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/partsOrders`,
            data,
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Редактирование заказа */
    updateOrder({ orderId, data }: IUpdateOrderThunkParams) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/partsOrders/${orderId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Отмена заказа */
    cancelOrder({ data }: IPutCancelOrderThunkParams) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/partsOrders/cancel`,
            data,
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Удаление перемещения */
    deleteOrderMove({ spare_part_order_ids, moveId }: { spare_part_order_ids: string[]; moveId: string }) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/moysklad/move/${moveId}/delete`,
            data: { spare_part_order_ids },
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Удаление списания */
    deleteOrderLoss({ spare_part_order_ids, lossId }: { spare_part_order_ids: string[]; lossId: string }) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/moysklad/loss/${lossId}/delete`,
            data: { spare_part_order_ids },
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Удаление заказа */
    putNextStatusOrder({ data }: IPutNextStatusOrderThunkParams) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/partsOrders/linearMove`,
            data,
            isAuthorization: true,
        }) as AxiosPromise;
    }
}
