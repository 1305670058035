import { Paper, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { FunctionComponent } from 'react';

import IssueTablePagination from './IssuesPagination';
import IssuesTableHead from './IssuesTableHead';

import Loader from '../../../components/Loader';
import Plug from '../../../components/Plug';
import { IIssuesFilters, IIssuesTableHead } from '../../../interfaces/issues';
import { IPagination } from '../../../interfaces/pagination';
import './IssuesTable.scss';

interface IProps<T> {
    tableHead: IIssuesTableHead[] | null;
    setTableHead: (value: IIssuesTableHead[]) => void;
    filters: IIssuesFilters | null;
    setFilters: (value: IIssuesFilters | null) => void;
    ComponentRow: FunctionComponent<T>;
    tableContent: T[] | null;
    isLoading: boolean;
    pagination: IPagination;
    changePagination: (newPagination: IPagination) => void;
    isArchive?: boolean;
}

function IssuesTable<T>({
    filters,
    setFilters,
    ComponentRow,
    tableContent,
    isLoading,
    pagination,
    changePagination,
    isArchive,
}: IProps<T>) {
    return (
        <Paper className={'issues-table-wrapper'}>
            <Table
                stickyHeader
                size={'small'}
            >
                <IssuesTableHead
                    filters={filters}
                    setFilters={setFilters}
                    isArchive={isArchive}
                />
                {(() => {
                    switch (true) {
                        case isLoading:
                            return <Loader />;
                        case !!tableContent && !!tableContent?.length:
                            return (
                                <TableBody>
                                    {tableContent &&
                                        tableContent.map((rowContent: T & any) => {
                                            return (
                                                <ComponentRow
                                                    key={rowContent.id}
                                                    {...rowContent}
                                                />
                                            );
                                        })}
                                </TableBody>
                            );
                        default:
                            return (
                                <TableBody>
                                    <TableRow>
                                        <TableCell
                                            colSpan={12}
                                            align="center"
                                        >
                                            <Plug />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            );
                    }
                })()}
            </Table>
            <IssueTablePagination
                isLoading={isLoading}
                pagination={pagination}
                changePagination={changePagination}
            />
        </Paper>
    );
}

export default IssuesTable;
