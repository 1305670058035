import { issuesApi } from 'api';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import type { PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/pagination';

import { TFilters, TState, TTransation } from './types';

export const initialState: TState = {
    transactions: [],

    loading: false,
    pagination: {
        page: 1,
        size: 50,
        total: 0,
    },
    filters: {
        worker_id__in: [],
        created_at__gte: '',
        created_at__lte: '',
    },
};

const slice = createSlice({
    name: 'transactions',
    initialState,
    reducers: (create) => ({
        setTransactions: create.reducer((state, { payload }: PayloadAction<TTransation[]>) => {
            state.transactions = payload;
        }),

        fetchTransactions: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { pagination, filters } = (getState() as RootState).transactions;

                const params = getFilledParams({ size: pagination.size, page: pagination.page, ...filters });

                const { data } = await issuesApi.getTransactions(params);

                dispatch(setPagination({ ...pagination, total: data.total }));

                dispatch(setTransactions(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.pagination = { ...initialState.pagination, size: state.pagination.size };
            state.filters = { ...state.filters, ...payload };
        }),

        setPagination: create.reducer((state, { payload }: PayloadAction<Partial<IPagination>>) => {
            state.pagination = { ...state.pagination, ...payload };
        }),
    }),
});

export const {
    setTransactions,
    fetchTransactions,

    setFilters,
    setPagination,
} = slice.actions;

export default slice.reducer;
