import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    Radio,
    TextField,
    RadioGroup,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import { ECommissionsType } from '../../../../enums/prices.enum';
import { EUserRole } from '../../../../enums/user.enum';
import { pricesServicesCommissionsTypeMock } from '../../../../mockData/pricesServices';
import {
    setErrorsPriceServicesDialogAction,
    setFormValuesPriceServicesDialogAction,
} from '../../../../store/actions/priceServicesDialog';
import { useAppDispatch } from '../../../../store/hooks';
import {
    errorsPriceServicesDialogSelector,
    formValuesPriceServicesDialogSelector,
} from '../../../../store/selectors/priceServicesDialogSelectors';
import { priceServiceSelector } from '../../../../store/selectors/pricesServicesSelector';

interface IProp {
    isEdit: boolean;
}
function ServiceDialogForm({ isEdit }: IProp) {
    const dispatch = useAppDispatch();
    const service = useSelector(priceServiceSelector);
    const formValues = useSelector(formValuesPriceServicesDialogSelector);
    const errors = useSelector(errorsPriceServicesDialogSelector);

    const commissionType = formValues?.commissions?.length
        ? formValues?.commissions[0].type && pricesServicesCommissionsTypeMock[formValues?.commissions[0].type]
        : '%';

    useEffect(() => {
        if (isEdit && service) {
            dispatch(
                setFormValuesPriceServicesDialogAction({
                    name: service.name,
                    description: service.description,
                    commissions: service.commissions?.length
                        ? service.commissions
                        : [{ type: ECommissionsType.Percentage, owner_type: EUserRole.Worker }],
                }),
            );
        } else {
            dispatch(
                setFormValuesPriceServicesDialogAction({
                    commissions: [{ type: ECommissionsType.Percentage, owner_type: EUserRole.Worker }],
                }),
            );
        }

        return () => {
            dispatch(setFormValuesPriceServicesDialogAction(null));
            dispatch(setErrorsPriceServicesDialogAction(null));
        };
    }, [isEdit, service]);

    function onChangeName(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setFormValuesPriceServicesDialogAction({
                ...(formValues || {}),
                name: event.target.value,
            }),
        );
    }
    function onChangeDescription(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setFormValuesPriceServicesDialogAction({
                ...(formValues || {}),
                description: event.target.value,
            }),
        );
    }
    function onChangeCommissionsType(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(
            setFormValuesPriceServicesDialogAction({
                ...(formValues || {}),
                commissions: formValues?.commissions?.length
                    ? [
                          {
                              ...formValues?.commissions[0],
                              type: event.target.value as ECommissionsType,
                              additional_amount: 0,
                              main_amount: 0,
                          },
                      ]
                    : [{ type: event.target.value as ECommissionsType, additional_amount: 0, main_amount: 0 }],
            }),
        );
    }
    function onChangeMainAmount(event: React.ChangeEvent<HTMLInputElement>) {
        const amount = Number(event.target.value);
        const currentCommissionType = formValues?.commissions?.length
            ? formValues?.commissions[0].type
            : ECommissionsType.Percentage;

        if (currentCommissionType === ECommissionsType.Percentage && (amount > 100 || amount < 0)) return;
        dispatch(
            setFormValuesPriceServicesDialogAction({
                ...(formValues || {}),
                commissions: formValues?.commissions?.length
                    ? [{ ...formValues?.commissions[0], main_amount: amount }]
                    : [{ main_amount: amount }],
            }),
        );
    }
    function onChangeAdditionalAmount(event: React.ChangeEvent<HTMLInputElement>) {
        const amount = Number(event.target.value);
        const currentCommissionType = formValues?.commissions?.length
            ? formValues?.commissions[0].type
            : ECommissionsType.Percentage;

        if (currentCommissionType === ECommissionsType.Percentage && (amount > 100 || amount < 0)) return;
        dispatch(
            setFormValuesPriceServicesDialogAction({
                ...(formValues || {}),
                commissions: formValues?.commissions?.length
                    ? [{ ...formValues?.commissions[0], additional_amount: amount }]
                    : [{ additional_amount: amount }],
            }),
        );
    }

    return (
        <div className="edit-category-form-wrapper">
            <TextField
                fullWidth
                name="name"
                placeholder="Название"
                sx={{ marginTop: 1 }}
                label="Название *"
                value={formValues?.name || ''}
                error={!!errors?.name}
                helperText={errors?.name?.message}
                onChange={onChangeName}
            />
            <TextField
                fullWidth
                name="description"
                placeholder="Описание"
                label="Описание"
                sx={{ marginTop: 5 }}
                value={formValues?.description || ''}
                onChange={onChangeDescription}
            />
            <FormControl
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    margin: '35px 0 15px 0',
                }}
            >
                <FormLabel id="demo-radio-buttons-group-label">КОМИССИЯ МАСТЕРУ</FormLabel>
                <RadioGroup
                    row
                    name="category_radio"
                    defaultValue={ECommissionsType.Percentage}
                    onChange={onChangeCommissionsType}
                >
                    {Object.entries(pricesServicesCommissionsTypeMock).map(([key, value]) => (
                        <FormControlLabel
                            key={key}
                            value={key}
                            control={<Radio />}
                            label={value}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <Grid
                container
                spacing={1}
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        label="Основные услуги"
                        type="number"
                        id="outlined-end-adornment"
                        value={formValues?.commissions?.length ? formValues?.commissions[0].main_amount || '' : ''}
                        onChange={onChangeMainAmount}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{commissionType}</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        label="Доп. услуги"
                        type="number"
                        id="outlined-end-adornment"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{commissionType}</InputAdornment>,
                        }}
                        value={
                            formValues?.commissions?.length ? formValues?.commissions[0].additional_amount || '' : ''
                        }
                        onChange={onChangeAdditionalAmount}
                    />
                </Grid>
            </Grid>
            <FooterForm isEdit={isEdit} />
        </div>
    );
}

export default ServiceDialogForm;
