import { SxProps, TextField as MuiTextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Theme } from '@mui/system';
import React, { memo, useEffect, useMemo, useState } from 'react';

import { TErrors } from '../../../interfaces';

export type TTextTypes = 'text' | 'email' | 'password' | 'number' | 'phone';

interface IProps {
    type?: TTextTypes;
    name: string;
    labelText?: string;
    id?: string;
    sxStyle?: SxProps<Theme> | undefined;
    className?: string;
    value?: string | number | null;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    errors?: TErrors | null;
    placeholder?: string;
    defaultValue?: string | number | null;
    required?: boolean;
    params?: TextFieldProps;
    fullWidth?: boolean;
    disabled?: boolean;
    multiline?: boolean;
}

function TextField({
    type = 'text',
    name,
    labelText,
    id,
    sxStyle,
    className,
    value,
    onChange,
    onBlur,
    onFocus,
    errors,
    placeholder,
    defaultValue,
    required,
    params,
    fullWidth,
    disabled,
    multiline,
}: IProps) {
    const [forceRerender, setForceRerender] = useState('');
    const [errorText, setErrorText] = useState<null | React.ReactNode>(null);

    const defaultValues = useMemo(() => {
        if (defaultValue) {
            setForceRerender(Math.random().toFixed(10));
            return defaultValue;
        }
        return undefined;
    }, [!!defaultValue]);

    useEffect(() => {
        if (errors && name in errors) {
            if (Array.isArray(errors[name])) {
                const errs: { message: string }[] = errors[name];
                setErrorText(
                    errs.map((err) => {
                        return (
                            <span
                                key={name}
                                className="text__error"
                            >
                                {err?.message}
                            </span>
                        );
                    }),
                );
            } else {
                setErrorText(
                    <span
                        key={name}
                        className="text__error"
                    >
                        {errors[name]?.message}
                    </span>,
                );
            }
        } else {
            setErrorText(null);
        }
    }, [errors]);

    return (
        <MuiTextField
            key={forceRerender}
            label={labelText}
            sx={sxStyle}
            className={className}
            error={!!errorText}
            helperText={errorText}
            defaultValue={defaultValues}
            disabled={disabled}
            multiline={multiline}
            {...{ type, name, id, value, onChange, onBlur, onFocus, placeholder, required, fullWidth, ...params }}
        />
    );
}

export default memo(TextField);
