import { EIssueInvoicingNotificationsActions } from '../../enums/actions.enum';
import { IInvoicingNotificationsState, TINActions } from '../../interfaces/invoicingNotifications';

const initState: IInvoicingNotificationsState = {
    smsIsOpen: false,
    emailIsOpen: false,
    notificationData: null,
    invoicingNotifications: null,
};

const initialState = { ...initState };

export const invoicingNotificationsReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TINActions,
): IInvoicingNotificationsState => {
    switch (action.type) {
        case EIssueInvoicingNotificationsActions.SetSmsIsOpen:
            return { ...state, smsIsOpen: action.payload };
        case EIssueInvoicingNotificationsActions.SetEmailIsOpen:
            return { ...state, emailIsOpen: action.payload };
        case EIssueInvoicingNotificationsActions.SetDataInvoicingNotifications:
            return { ...state, notificationData: action.payload };
        case EIssueInvoicingNotificationsActions.SetInvoicingNotifications:
            return { ...state, invoicingNotifications: action.payload };
        default:
            return { ...state };
    }
};
