import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ archive }: RootState) => archive;

export const archiveSelectedTab = createSelector(state, ({ selectedTab }) => selectedTab);

export const archiveIssuesSelector = createSelector(state, ({ issues }) => issues);
export const archiveIsIssuesTableLoaderSelector = createSelector(
    state,
    ({ isIssuesTableLoader }) => isIssuesTableLoader,
);
export const archiveIssuesPaginationSelector = createSelector(state, ({ issuesPagination }) => issuesPagination);
export const archiveIssuesFiltersSelector = createSelector(state, ({ issuesFilters }) => issuesFilters);
export const archiveIssuesTableHeadSelector = createSelector(state, ({ issuesTableHead }) => issuesTableHead);

export const archiveWorkersSelector = createSelector(state, ({ workers }) => workers);
export const archiveIsWorkersTableLoaderSelector = createSelector(
    state,
    ({ isWorkersTableLoader }) => isWorkersTableLoader,
);
export const archiveWorkersPaginationSelector = createSelector(state, ({ workersPagination }) => workersPagination);
export const archiveWorkersFiltersSelector = createSelector(state, ({ workersFilters }) => workersFilters);
export const archiveWorkersSortOptionsSelector = createSelector(state, ({ workersSortOptions }) => workersSortOptions);
