import AuthAPI from './authAPI';
import BrandsAPI from './brandsAPI';
import CategoriesAPI from './categoriesAPI';
import ChatsAPI from './chatsAPI';
import ClientsAPI from './clientsAPI';
import CompaniesAPI from './companiesAPI';
import DevicesAPI from './devicesAPI';
import DictsAPI from './dictsAPI';
import FilesAPI from './filesAPI';
import GroupsAPI from './groupsAPI';
import HistoryAPI from './historyAPI';
import IssuesImportAPI from './issueImportAPI';
import IssuesAPI from './issuesAPI';
import IssuesObserversAPI from './issuesObserversAPI';
import MapAPI from './mapAPI';
import ModelsAPI from './modelsAPI';
import MoySkladAPI from './moyskladAPI';
import OrdersAPI from './ordersAPI';
import PartnersAPI from './partnersAPI';
import PartsAPI from './partsAPI';
import PriceListsAPI from './priceListsAPI';
import PricesAPI from './pricesAPI';
import ProfileAPI from './profileAPI';
import RatiosAPI from './ratiosAPI';
import RemindersAPI from './remindersAPI';
import RolesAPI from './rolesAPI';
import RoutingAPI from './routingAPI';
import SearchAPI from './searchAPI';
import ServicesAPI from './servicesAPI';
import servicesPriceListsAPI from './servicesPriceListsAPI';
import SuggestionsAPI from './suggestionsAPI';
import TagsAPI from './tagsAPI';
import WebsocketsAPI from './websocketsAPI';
import WorkersAPI from './workersAPI';

export const authApi = new AuthAPI();
export const brandsApi = new BrandsAPI();
export const categoriesApi = new CategoriesAPI();
export const chatsApi = new ChatsAPI();
export const clientsApi = new ClientsAPI();
export const companiesApi = new CompaniesAPI();
export const devicesApi = new DevicesAPI();
export const dictsApi = new DictsAPI();
export const filesApi = new FilesAPI();
export const groupsApi = new GroupsAPI();
export const historyApi = new HistoryAPI();
export const issuesApi = new IssuesAPI();
export const issuesObserversApi = new IssuesObserversAPI();
export const mapApi = new MapAPI();
export const modelsApi = new ModelsAPI();
export const ordersApi = new OrdersAPI();
export const partnersApi = new PartnersAPI();
export const partsApi = new PartsAPI();
export const pricesApi = new PricesAPI();
export const profileApi = new ProfileAPI();
export const ratiosApi = new RatiosAPI();
export const reminderApi = new RemindersAPI();
export const rolesApi = new RolesAPI();
export const routingApi = new RoutingAPI();
export const searchApi = new SearchAPI();
export const suggestionsApi = new SuggestionsAPI();
export const tagsApi = new TagsAPI();
export const websocketsApi = new WebsocketsAPI();
export const workersApi = new WorkersAPI();
export const moySkladApi = new MoySkladAPI();
export const issueImportApi = new IssuesImportAPI();
export const servicesApi = new ServicesAPI();
export const priceListsApi = new PriceListsAPI();
export const servicePriceListsApi = new servicesPriceListsAPI();
