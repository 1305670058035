import { ICategoryRatiosFilter } from '../../interfaces/categoriesRatiosFilter';
import { ICategoryRatios } from '../../interfaces/ratios';

export const getCategoriesRFWithChecked = (
    categories: ICategoryRatios[] | ICategoryRatiosFilter[],
): ICategoryRatiosFilter[] => {
    return categories.map((category) => {
        const newCategory = { ...category, checked: false };
        if (newCategory.childs?.length) {
            newCategory.childs = getCategoriesRFWithChecked(newCategory.childs);
            return newCategory as ICategoryRatiosFilter;
        }
        return newCategory as ICategoryRatiosFilter;
    });
};
