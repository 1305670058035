import { EEditWorkerSpecializationsActions } from '../../../enums/actions.enum';
import {
    IEditWorkerSpecializationsState,
    TEditWorkerSpecializationsActions,
} from '../../../interfaces/edit/editWorkerSpecializations';

const initState: IEditWorkerSpecializationsState = {
    isOpen: false,
    workerId: null,
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

export const editWorkerSpecializationsReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TEditWorkerSpecializationsActions,
): IEditWorkerSpecializationsState => {
    switch (action.type) {
        case EEditWorkerSpecializationsActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditWorkerSpecializationsActions.SetWorkerId:
            return {
                ...state,
                workerId: action.payload,
            };
        case EEditWorkerSpecializationsActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload,
            };
        case EEditWorkerSpecializationsActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
