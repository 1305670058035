import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CreateIssueForm from './CreateIssueForm';

import {
    setIsOpenCreateIssueAction,
    setFormValuesCIAction,
    setErrorsAction,
    setSelectedPricesCIAction,
} from '../../../store/actions/create/createIssue';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenCreateIssueSelector } from '../../../store/selectors/create/createIssuesSelectors';

import './CreateIssue.scss';

function CreateIssueCopy() {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(isOpenCreateIssueSelector);

    useEffect(() => {
        if (!isOpen) {
            dispatch(setFormValuesCIAction(null));
            dispatch(setErrorsAction(null));
            dispatch(setSelectedPricesCIAction(null));
        }
    }, [isOpen]);

    function onCloseHandler() {
        dispatch(setIsOpenCreateIssueAction(false));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div
                        className="close-icon"
                        style={{ position: 'absolute', right: '15px', cursor: 'pointer' }}
                    >
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Копирование заявки</Typography>
                </Box>
            </DialogTitle>
            <DialogContent sx={{ minHeight: '90vh' }}>
                <CreateIssueForm />
            </DialogContent>
        </Dialog>
    );
}

export default CreateIssueCopy;
