import { Divider, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { EMapTabs } from '../../../../enums/map.enum';
import { mapTabsMock } from '../../../../mockData/map';
import { setMapTabAction } from '../../../../store/actions/map';
import { useAppDispatch } from '../../../../store/hooks';
import {
    areasMapSelector,
    mapTabSelector,
    selectedIssuesMapSelector,
    selectedWorkersMapSelector,
} from '../../../../store/selectors/mapSelectors';
import './SidebarTabs.scss';

function SidebarTabs() {
    const dispatch = useAppDispatch();

    const mapTab = useSelector(mapTabSelector);
    const areas = useSelector(areasMapSelector);
    const issues = useSelector(selectedIssuesMapSelector);
    const workers = useSelector(selectedWorkersMapSelector);

    const handleChangeTab = (event: React.SyntheticEvent, tabValue: EMapTabs) => {
        dispatch(setMapTabAction(tabValue));
    };
    return (
        <>
            <Tabs
                value={mapTab}
                onChange={handleChangeTab}
            >
                <Tab
                    value={EMapTabs.Areas}
                    label={mapTabsMock[EMapTabs.Areas]}
                    icon={areas?.length ? <div className="sidebar-tabs-map-count">{areas?.length}</div> : ''}
                    iconPosition="end"
                />
                <Tab
                    value={EMapTabs.Issues}
                    label={mapTabsMock[EMapTabs.Issues]}
                    icon={issues?.length ? <div className="sidebar-tabs-map-count">{issues?.length}</div> : ''}
                    iconPosition="end"
                />
                <Tab
                    value={EMapTabs.Workers}
                    label={mapTabsMock[EMapTabs.Workers]}
                    icon={workers?.length ? <div className="sidebar-tabs-map-count">{workers?.length}</div> : ''}
                    iconPosition="end"
                />
            </Tabs>
            <Divider />
        </>
    );
}

export default SidebarTabs;
