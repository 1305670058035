import React from 'react';
import { useSelector } from 'react-redux';

import AdditionalPrices from './AdditionalPrices';
import FooterForm from './FooterForm';
import Price from './Price';
import PriceCategories from './PriceCategories';
import PriceCompany from './PriceCompany';
import PriceDemonstrations from './PriceDemonstrations';
import PriceDescriptions from './PriceDescriptions';
import PriceOptions from './PriceOptions';

import { priceSelector } from '../../../../store/selectors/pricesSelectors';

interface IPriceFormProps {
    isEdit: boolean;
    isAdditional: boolean;
}

function PriceDialogForm({ isEdit, isAdditional }: IPriceFormProps) {
    const price = useSelector(priceSelector);

    return (
        <div className="create-service-form-wrapper">
            <Price />
            <PriceCategories />
            <PriceCompany />
            {isAdditional && (
                <>
                    <PriceDescriptions />
                    <PriceOptions />
                    <PriceDemonstrations />
                    {!price?.is_additional && <AdditionalPrices />}
                </>
            )}

            <FooterForm
                isEdit={isEdit}
                isAdditional={isAdditional}
            />
        </div>
    );
}

export default PriceDialogForm;
