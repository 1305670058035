import { issuesApi } from 'api';
import Accordion from 'components/Accordion';
import docImg from 'static/images/doc.png';
import emptyImg from 'static/images/emptyImg.png';
import pdfImg from 'static/images/pdf.png';
import { issueSelector } from 'store/selectors/issuesSelectors';

import { Download } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { TDocumentResp } from 'interfaces/issues';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Documents() {
    const issue = useSelector(issueSelector);

    const [documents, setDocuments] = useState<TDocumentResp>();

    const getDocuments = async () => {
        const { data } = await issuesApi.getDocuments({ issue_id: issue?.id });
        setDocuments(data);
    };

    useEffect(() => {
        getDocuments();
    }, [issue]);

    const getLink = (type: string) => {
        if (type.match('/pdf')) {
            return pdfImg;
        } else if (type.match('/doc')) {
            return docImg;
        } else return emptyImg;
    };

    const getType = (type: string) => {
        switch (type) {
            case 'avr':
                return 'Акт выполненных работ';
            case 'apk':
                return 'Акт проверки качества';
            default:
                return type;
        }
    };

    return (
        <Accordion
            title={<Typography>Документы</Typography>}
            sx={{ mt: 3 }}
            expanded={false}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                {documents?.items.length
                    ? documents?.items.map(({ id, file_data, status, document_type }) => (
                          <div
                              key={id}
                              style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                          >
                              <img
                                  width={50}
                                  height={50}
                                  src={getLink(file_data.mimetype)}
                                  alt="doc"
                              />
                              <div style={{ color: '#8a92a6' }}>
                                  {getType(document_type)} <br />
                                  ID: {id} <br />
                                  {status && `Статус: ${status}`}
                              </div>
                              <IconButton
                                  onClick={(event) => {
                                      event.stopPropagation();
                                      event.preventDefault();
                                      const a = document.createElement('a');
                                      a.setAttribute('href', file_data.url);
                                      a.setAttribute('target', '_blanck');
                                      a.click();
                                      a.remove();
                                  }}
                              >
                                  <Download />
                              </IconButton>
                          </div>
                      ))
                    : 'Документов нет'}
            </div>
        </Accordion>
    );
}

export default Documents;
