import { EEditIssueReminderActions } from '../../../enums/actions.enum';
import { IEditIssueReminderState, TEditIssueReminderActions } from '../../../interfaces/edit/editReminder';

const initState: IEditIssueReminderState = {
    isOpen: false,
    formValues: null,
    errors: null,
};

const initialState = { ...initState };

export const editIssueReminderReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TEditIssueReminderActions,
): IEditIssueReminderState => {
    switch (action.type) {
        case EEditIssueReminderActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditIssueReminderActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EEditIssueReminderActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
