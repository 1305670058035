import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useObserver from '../../../../../hooks/useObserver';
import { IOption } from '../../../../../interfaces';
import {
    getCategoriesPriceDialogThunk,
    setFormValuesPriceDialogAction,
    setSelectedCategoriesPriceDialogAction,
} from '../../../../../store/actions/priceDialog';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    categoriesPaginationPriceDialogSelector,
    categoriesOptionsPriceDialogSelector,
    errorsPriceDialogSelector,
    formValuesPriceDialogSelector,
    selectedCategoriesPriceDialogSelector,
    categoriesPriceDialogSelector,
} from '../../../../../store/selectors/priceDialogSelector';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import CustomAutocomplete from '../CustomAutocomplete';
import './PriceCategories.scss';

const defaultListSize = 20;

function PriceCategories() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesPriceDialogSelector);
    const categories = useSelector(categoriesPriceDialogSelector);
    const categoriesOptions = useSelector(categoriesOptionsPriceDialogSelector);
    const selectedCategories = useSelector(selectedCategoriesPriceDialogSelector);
    const pagination = useSelector(categoriesPaginationPriceDialogSelector);
    const errors = useSelector(errorsPriceDialogSelector);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { isLoadMoreAppointments, entriesObserver, loadMoreAppointmentsElem, setIsLoadMoreAppointments } =
        useObserver({
            className: '.auto-wrapper',
            isOpen,
        });

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getCategoriesPriceDialogThunk({ size: defaultListSize }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, []);

    useEffect(() => {
        async function getMoreAppointmentsOnObserve() {
            if (entriesObserver && entriesObserver[0]?.isIntersecting) {
                try {
                    if (
                        categories &&
                        pagination &&
                        pagination?.total > Number(pagination.size * pagination.page) &&
                        isLoadMoreAppointments === false
                    ) {
                        setIsLoadMoreAppointments(true);
                        await dispatch(
                            getCategoriesPriceDialogThunk({
                                categories,
                                page: pagination.page + 1,
                                size: defaultListSize,
                            }),
                        );
                    }
                } catch (err) {
                    defaultErrorCallback({ errorMessage: getErrorString({ err }) });
                } finally {
                    setIsLoadMoreAppointments(false);
                }
            }
        }
        getMoreAppointmentsOnObserve();
    }, [entriesObserver]);

    function onSelectCategory(option: IOption<string> | null, checked: boolean) {
        if (!option) return;

        // eslint-disable-next-line no-nested-ternary
        const categoryIds = checked
            ? formValues?.category_ids
                ? [...formValues.category_ids, option.value]
                : [option.value]
            : formValues?.category_ids
            ? formValues.category_ids.filter((so) => so !== option.value)
            : null;

        // eslint-disable-next-line no-nested-ternary
        const newSelectedCategories = checked
            ? selectedCategories
                ? [...selectedCategories, { id: option.value, name: option.label }]
                : [{ id: option.value, name: option.label }]
            : selectedCategories
            ? selectedCategories.filter(({ id }) => id !== option.value)
            : null;

        dispatch(setFormValuesPriceDialogAction({ category_ids: categoryIds }));
        dispatch(setSelectedCategoriesPriceDialogAction(newSelectedCategories));
    }

    async function searchCategories(event: React.ChangeEvent<HTMLInputElement>) {
        await dispatch(getCategoriesPriceDialogThunk({ search: event.target.value }));
    }

    function deleteSelectedCategory(id: string) {
        const newSelectedCategories = selectedCategories?.filter((category) => category.id !== id);
        const newCategoryIds = newSelectedCategories?.map((category) => category.id);

        dispatch(setSelectedCategoriesPriceDialogAction(newSelectedCategories || null));
        dispatch(setFormValuesPriceDialogAction({ category_ids: newCategoryIds }));
    }

    return (
        <>
            <CustomAutocomplete
                options={categoriesOptions || []}
                selectedIds={formValues?.category_ids}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSearch={searchCategories}
                onSelect={onSelectCategory}
                loadMoreAppointmentsElem={loadMoreAppointmentsElem}
                isLoadMoreAppointments={isLoadMoreAppointments}
                label="Категории техники *"
                error={!!errors?.category_ids}
                errorText={errors?.category_ids?.message}
            />

            {!!selectedCategories?.length && (
                <div className="selected-categories-container">
                    {selectedCategories?.map(({ id, name }) => (
                        <div
                            className="selected-category"
                            key={id}
                        >
                            <div className="sub-option-title">{name}</div>
                            <IconButton
                                className="delete-button"
                                onClick={() => deleteSelectedCategory(id)}
                            >
                                <ClearIcon className="delete-button-icon" />
                            </IconButton>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default memo(PriceCategories);
