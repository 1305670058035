import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { ISearchReq, TSearchResp } from '../interfaces/search';

export default class SearchAPI {
    postSearch(queries: string, data: ISearchReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/search${queries}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<TSearchResp>;
    }
}
