import { Map, YMaps, ZoomControl } from '@pbe/react-yandex-maps';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import AreasMultiPolygons from './AreasMultiPolygons';
import ClusterIssues from './ClusterIssues';
import ClusterWorkers from './ClusterWorkers';

import {
    setPinAction,
    setSelectedAreaAction,
    setSelectedIssuesAction,
    setSelectedWorkersAction,
} from '../../../../store/actions/map';
import { useAppDispatch } from '../../../../store/hooks';
import {
    areasMapSelector,
    issuesMapSelector,
    pinMapSelector,
    selectedAreaMapSelector,
    selectedPinIssueMapSelector,
    selectedPinWorkerMapSelector,
    workersMapSelector,
} from '../../../../store/selectors/mapSelectors';
import MapInfo from '../MapInfo';

function MapContent() {
    const dispatch = useAppDispatch();

    const areas = useSelector(areasMapSelector);
    const issues = useSelector(issuesMapSelector);
    const workers = useSelector(workersMapSelector);
    const selectedPinIssue = useSelector(selectedPinIssueMapSelector);
    const selectedPinWorker = useSelector(selectedPinWorkerMapSelector);
    const selectedArea = useSelector(selectedAreaMapSelector);
    const pin = useSelector(pinMapSelector);

    useEffect(() => {
        if (!selectedArea || !areas?.length) return;
        const selectedWorkers = workers?.filter((item) => areas[selectedArea.index]?.workers?.includes(item.id));
        const selectedIssues = issues?.filter((item) => areas[selectedArea.index]?.issues?.includes(item.id));
        dispatch(setSelectedWorkersAction(selectedWorkers || null));
        dispatch(setSelectedIssuesAction(selectedIssues || null));
    }, [selectedArea]);

    const onClickPolygon = (i: number) => {
        if (!areas?.length) return;
        // eslint-disable-next-line no-shadow
        const selectedArea = areas?.find((item, index) => index === i);
        dispatch(setSelectedAreaAction({ id: selectedArea!.id, index: i } || null));
    };

    const updateRef = (newRef: any) => {
        newRef?.events.add('click', () => {
            if (selectedArea) {
                dispatch(setSelectedAreaAction(null));
                dispatch(setSelectedWorkersAction(workers || null));
                dispatch(setSelectedIssuesAction(issues || null));
            }
            dispatch(setPinAction(newRef.getCenter()));
        });
        newRef?.events.add('balloonopen', () => {
            dispatch(setPinAction(newRef.getCenter()));
        });
    };

    return (
        // @ts-ignore
        <YMaps query={{ coordorder: 'longlat ' }}>
            <div style={{ position: 'relative', height: '100%' }}>
                <MapInfo />
                <Map
                    instanceRef={updateRef}
                    width="100%"
                    height="100%"
                    defaultState={{
                        center: [37.57, 55.75],
                        zoom: 9,
                        controls: [],
                    }}
                    state={{
                        center: pin?.length ? pin : [37.57, 55.75],
                        zoom: 9,
                        controls: [],
                    }}
                    options={{
                        yandexMapDisablePoiInteractivity: false,
                    }}
                >
                    <ClusterIssues
                        issues={issues}
                        selectedPinIssue={selectedPinIssue}
                    />
                    <ClusterWorkers
                        workers={workers}
                        selectedPinWorker={selectedPinWorker}
                    />
                    <AreasMultiPolygons
                        areas={areas}
                        onClick={onClickPolygon}
                        selectedArea={selectedArea}
                    />
                    <ZoomControl options={{ position: { left: 10, top: 100 } }} />
                </Map>
            </div>
        </YMaps>
    );
}

export default MapContent;
