import { Divider, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { EPricesServicesTabs } from '../../../enums/pricesServices.enum';
import { pricesServicesTabsMock } from '../../../mockData/pricesServices';
import { setPriceServicesTabAction } from '../../../store/actions/pricesServices';
import { useAppDispatch } from '../../../store/hooks';
import { priceServicesTabSelector } from '../../../store/selectors/pricesServicesSelector';

function ServiceTabs() {
    const dispatch = useAppDispatch();
    const serviceTabs = useSelector(priceServicesTabSelector);

    const handleChangeTab = (event: React.SyntheticEvent, tabValue: EPricesServicesTabs) => {
        dispatch(setPriceServicesTabAction(tabValue));
    };

    return (
        <>
            <Tabs
                value={serviceTabs}
                onChange={handleChangeTab}
            >
                {Object.entries(pricesServicesTabsMock).map(([key, value]) => (
                    <Tab
                        key={key}
                        value={key}
                        label={value}
                    />
                ))}
            </Tabs>
            <Divider />
        </>
    );
}

export default ServiceTabs;
