import { servicesApi } from 'api';
import { ActiveRadioChecked } from 'static/Icons/ActiveRadioChecked';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { InactiveRadioChecked } from 'static/Icons/InactiveRadioChecked';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';
import { specificationsSelector } from 'store/selectors/specificationsSelectors';

import Add from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, Table, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchSpecifications } from './store';
import { ButtonsContainer, CellInputProps, CheckBox, CheckItem } from './styles';

import { Cell, CellCenter, HeadCell, HeadCellCenter, PlusButton } from '../../create-service/styles';

export const Specifications = () => {
    const dispatch = useAppDispatch();

    const { serviceById } = useSelector(servicesSelector);
    const { specifications } = useSelector(specificationsSelector);

    const [isVisible, setVisible] = useState(false);
    const [addSpecifications, setAddSpecifications] = useState<any>();

    const handleConfirm = () => {
        servicesApi
            .createSpecification(addSpecifications)
            .then(() => {
                enqueueSnackbar('Успешно', { variant: 'success' });
                dispatch(fetchSpecifications());
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
        setAddSpecifications({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddSpecifications({});
        setVisible(false);
    };

    const handleDeleteSpecification = (id: string) => {
        id;
    };

    useEffect(() => {
        dispatch(fetchSpecifications());
    }, []);

    return (
        <Table>
            <TableHead>
                <HeadCell>НАИМЕНОВАНИЕ</HeadCell>
                <HeadCellCenter>SLUG</HeadCellCenter>
                <HeadCellCenter>ЕДИНИЦЫ ИЗМЕРЕНИЯ</HeadCellCenter>
                <HeadCellCenter>ЗНАЧЕНИЕ</HeadCellCenter>
                <HeadCellCenter sx={{ width: '50px' }} />
            </TableHead>
            <TableBody>
                {specifications?.map((item: any) => {
                    return (
                        <TableRow key={item.id}>
                            <Cell>{item.name}</Cell>
                            <CellCenter>{item.slug}</CellCenter>
                            <CellCenter>{item.units}</CellCenter>
                            <CellCenter>
                                {Array.isArray(item.value) ? (
                                    <div>
                                        {item.value.map((item: any) => {
                                            return (
                                                <CheckItem key={item.title}>
                                                    {item.type ? (
                                                        <CheckBox
                                                            icon={<InactiveRadioChecked />}
                                                            checkedIcon={<ActiveRadioChecked />}
                                                        />
                                                    ) : (
                                                        <CheckBox />
                                                    )}
                                                    <span>{item.title}</span>
                                                </CheckItem>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <TextField
                                        value={item.value}
                                        size="small"
                                        inputProps={CellInputProps}
                                    />
                                )}
                            </CellCenter>
                            <Cell>
                                <Button onClick={() => handleDeleteSpecification(item.id)}>
                                    <DeleteBasket />
                                </Button>
                            </Cell>
                        </TableRow>
                    );
                })}
                {isVisible ? (
                    <TableRow>
                        <Cell>
                            <Autocomplete
                                size="small"
                                sx={{ width: '260px' }}
                                popupIcon={<ExpandMoreIcon />}
                                options={['Мощность сплит-системы', '2', '3', '4']}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выберите услугу"
                                    />
                                )}
                                onChange={(_, name) => {
                                    setAddSpecifications((prev: any) => {
                                        return {
                                            ...prev,
                                            spec_data: { ...prev.spec_data, name },
                                        };
                                    });
                                }}
                            />
                        </Cell>
                        <Cell>
                            <TextField
                                onChange={(e) => {
                                    setAddSpecifications((prev: any) => {
                                        return {
                                            ...prev,
                                            spec_data: { ...prev.spec_data, slug: e.target.value },
                                        };
                                    });
                                }}
                                size="small"
                            />
                        </Cell>
                        <Cell>
                            <TextField
                                onChange={(e) => {
                                    setAddSpecifications((prev: any) => {
                                        return {
                                            ...prev,
                                            spec_data: { ...prev.spec_data, measurement_unit: e.target.value },
                                        };
                                    });
                                }}
                                size="small"
                            />
                        </Cell>
                        <Cell>
                            <TextField
                                onChange={(e) => {
                                    setAddSpecifications((prev: any) => {
                                        return {
                                            ...prev,
                                            value: e.target.value,
                                        };
                                    });
                                }}
                                size="small"
                            />
                        </Cell>
                        <Cell>
                            <ButtonsContainer>
                                <div onClick={handleConfirm}>
                                    <Confirm />
                                </div>
                                <div onClick={handleCancel}>
                                    <Cancel />
                                </div>
                            </ButtonsContainer>
                        </Cell>
                    </TableRow>
                ) : (
                    <TableRow>
                        <PlusButton
                            onClick={() => {
                                setAddSpecifications({
                                    service_id: serviceById?.id,
                                    spec_id: '53aa7796-d978-49c3-9a24-aa8a6c90ce3d',
                                    spec_data: {
                                        name: '',
                                        slug: '',
                                        measurement_unit: '',
                                    },
                                    value: '',
                                });
                                setVisible(true);
                            }}
                            startIcon={<Add />}
                        >
                            Добавить спецификацию
                        </PlusButton>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    );
};
