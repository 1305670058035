import { AppDispatch } from 'store/store';

import { setPriceAction } from './prices';

import { categoriesApi, pricesApi } from '../../api';
import { EPriceDialogActions } from '../../enums/actions.enum';
import { TErrors } from '../../interfaces';
import { ICategoryDetail } from '../../interfaces/categories';
import { IPagination } from '../../interfaces/pagination';
import {
    IAdditionalPrice,
    IFormValuesPriceDialog,
    IOpenPriceDialog,
    ISetAdditionalPriceDialog,
    ISetAdditionalPricesPaginationPriceDialog,
    ISetCategoriesPaginationPriceDialog,
    ISetCategoriesPriceDialog,
    ISetErrorsPriceDialog,
    ISetFormValuesPriceDialog,
    ISetIsOpenPriceDialog,
    ISetPriceIdPriceDialog,
    ISetSelectedAdditionalPricesPriceDialog,
    ISetSelectedCategoriesPriceDialog,
} from '../../interfaces/priceDialog';
import { ICategories, IPrice } from '../../interfaces/prices';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';

export const setIsOpenPriceDialogAction = (payload: IOpenPriceDialog): ISetIsOpenPriceDialog => ({
    type: EPriceDialogActions.SetIsOpen,
    payload,
});

export const setPriceIdPriceDialogAction = (payload: string | null): ISetPriceIdPriceDialog => ({
    type: EPriceDialogActions.SetPriceId,
    payload,
});

export const setCategoriesPriceDialogAction = (payload: ICategoryDetail[] | null): ISetCategoriesPriceDialog => ({
    type: EPriceDialogActions.SetCategories,
    payload,
});

export const setCategoriesPaginationPriceDialogAction = (
    payload: IPagination,
): ISetCategoriesPaginationPriceDialog => ({
    type: EPriceDialogActions.SetCategoriesPagination,
    payload,
});

export const setFormValuesPriceDialogAction = (payload: IFormValuesPriceDialog | null): ISetFormValuesPriceDialog => ({
    type: EPriceDialogActions.SetFormValues,
    payload,
});

export const setAdditionalPricesDialogAction = (payload: IPrice[] | null): ISetAdditionalPriceDialog => ({
    type: EPriceDialogActions.SetAdditionalPrices,
    payload,
});

export const setAdditionalPricesPaginationDialogAction = (
    payload: IPagination,
): ISetAdditionalPricesPaginationPriceDialog => ({
    type: EPriceDialogActions.SetAdditionalPricesPagination,
    payload,
});

export const setErrorsPriceDialogAction = (payload: TErrors | null): ISetErrorsPriceDialog => ({
    type: EPriceDialogActions.SetErrors,
    payload,
});

export const setSelectedCategoriesPriceDialogAction = (
    payload: ICategories[] | null,
): ISetSelectedCategoriesPriceDialog => ({
    type: EPriceDialogActions.SetSelectedCategories,
    payload,
});

export const setSelectedAdditionalPricesPriceDialogAction = (
    payload: IAdditionalPrice[] | null,
): ISetSelectedAdditionalPricesPriceDialog => ({
    type: EPriceDialogActions.SetSelectedAdditionalPrices,
    payload,
});

export const createPriceThunk = (price: IFormValuesPriceDialog) => async () => {
    try {
        await pricesApi.createPrice(price);
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const updatePriceThunk = (priceId: string, price: IFormValuesPriceDialog) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await pricesApi.updatePrice(priceId, price);
        dispatch(setPriceAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const deletePriceThunk = (priceId: string) => async () => {
    try {
        await pricesApi.deletePrice(priceId);
    } catch (err) {
        console.error(err);
        throw err;
    }
};

interface getCategoriesPriceDialogParams {
    page?: number;
    size?: number;
    search?: string;
    categories?: ICategoryDetail[];
    order_by?: string;
}
export const getCategoriesPriceDialogThunk =
    ({ page, size, search, categories, order_by }: getCategoriesPriceDialogParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queries = getQueryParamsAsString([
                { page: page || 1 },
                { size: size || 50 },
                { search },
                { order_by: order_by || 'name' },
                { status: 'verified' },
            ]);

            const { data } = await categoriesApi.getCategories(queries);
            const { items, size: newSize, page: newPage, total } = data;

            if (categories) {
                dispatch(setCategoriesPriceDialogAction([...categories, ...items]));
            } else {
                dispatch(setCategoriesPriceDialogAction(items));
            }
            dispatch(setCategoriesPaginationPriceDialogAction({ total, page: newPage, size: newSize }));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface getAdditionalPricesParams {
    page?: number;
    size?: number;
    search?: string;
    prices?: IPrice[];
    service_id: string | null;
}
export const getAdditionalPricesPriceDialogThunk =
    ({ page, size, search, prices, service_id }: getAdditionalPricesParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queries = getQueryParamsAsString([
                { page: page || 1 },
                { size: size || 50 },
                { search },
                { with_paginate: true },
                { is_additional: true },
                { service_id },
            ]);

            const { data } = await pricesApi.getPrices(queries);
            const { items, size: newSize, page: newPage, total } = data;

            if (prices) {
                dispatch(setAdditionalPricesDialogAction([...prices, ...items]));
            } else {
                dispatch(setAdditionalPricesDialogAction(items));
            }
            dispatch(setAdditionalPricesPaginationDialogAction({ total, page: newPage, size: newSize }));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
