import NotificationsIcon from '@mui/icons-material/Notifications';
import { Button, IconButton, Paper, Typography } from '@mui/material';
import cn from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import WSHistoryItem from './HistoryItem';

import useOutsideClick from '../../../../hooks/useOutsideClick';
import { IWSHistory } from '../../../../interfaces/websockets';
import { getWSHistoryThunk, markAsViewedWSHistoryThunk } from '../../../../store/actions/websockets';
import { useAppDispatch } from '../../../../store/hooks';
import { historyCounterSelector, historySelector } from '../../../../store/selectors/websocketsSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import './Notifications.scss';

function NotificationsTopBar() {
    const dispatch = useAppDispatch();

    const history = useSelector(historySelector);
    const counter = useSelector(historyCounterSelector);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        async function handel() {
            try {
                await dispatch(getWSHistoryThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handel();
    }, []);

    function onOpenHandle() {
        setIsOpen(!isOpen);
    }

    async function markAsReadHandler() {
        if (!history) return;
        try {
            const notification_ids = history.map(({ id }) => id).filter((id) => id) as NonNullable<IWSHistory['id']>[];
            await dispatch(markAsViewedWSHistoryThunk({ notification_ids, wsHistory: history }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <div
            className="notifications-wrapper"
            ref={wrapperRef}
        >
            <IconButton
                onClick={onOpenHandle}
                className="notifications-btn"
            >
                <NotificationsIcon />
                {!!counter && !isOpen && <span className="ntf-btn-pulse" />}
            </IconButton>
            <Paper className={cn('notifications-popover', { isOpen })}>
                <div className="notifications-header">
                    <Typography
                        variant="h6"
                        textAlign="start"
                    >
                        Уведомления {!!counter && `(${counter})`}
                    </Typography>
                    <Button
                        className="mark-as-read-btn"
                        variant="text"
                        onClick={markAsReadHandler}
                    >
                        Отметить все как прочитанные
                    </Button>
                </div>
                <div className="notifications-list">
                    {!!history &&
                        history.map((item, idx) => {
                            // eslint-disable-next-line react/no-array-index-key
                            return (
                                <WSHistoryItem
                                    key={idx}
                                    wsHistory={item}
                                    onOpenHandle={onOpenHandle}
                                />
                            );
                        })}
                </div>
            </Paper>
        </div>
    );
}

export default memo(NotificationsTopBar);
