import { Button } from 'components/Button';
import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { CorrectionModal } from 'pages/accounting/transactions/components/modals/correction';
import { Table as TransactionTable } from 'pages/accounting/transactions/components/table';
import { fetchTransactions, setFilters } from 'pages/accounting/transactions/store';
import { transactionsSelector } from 'pages/accounting/transactions/store/selectors';
import { getWorkerThunk } from 'store/actions/worker';
import { useAppDispatch } from 'store/hooks';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';
import { workerDetailSelector } from 'store/selectors/workerSelectors';

import { Paper, styled } from '@mui/material';
import { IWorker } from 'interfaces/workers';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export const WorkerTransactions = () => {
    const [open, setOpen] = useState(false);

    const { transactions, pagination, loading } = useSelector(transactionsSelector);
    const worker = useSelector(workerDetailSelector);
    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);

    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    const handleSuccess = () => {
        if (!worker) return;

        dispatch(getWorkerThunk(worker.id));
    };

    useEffect(() => {
        if (!worker) return;
        dispatch(setFilters({ worker_id__in: [worker.id] }));
        dispatch(fetchTransactions());

        return () => {
            dispatch(setFilters({ worker_id__in: [] }));
        };
    }, []);

    return (
        <Paper className="moy-sklad-container">
            <Head>
                <div className="moy-sklad-container-title">История транзакций</div>
                {isOwner || isCompanyOwner ? (
                    <Button
                        onClick={() => setOpen(true)}
                        sx={{ marginLeft: 'auto' }}
                        variant="contained"
                    >
                        Корректировка
                    </Button>
                ) : null}
            </Head>
            <CorrectionModal
                defaultWorker={worker as IWorker}
                open={open}
                onClose={() => setOpen(false)}
                onSuccess={handleSuccess}
            />
            <TableWrapper isCollapse={pagination.size > 5}>
                <TransactionTable
                    rows={transactions}
                    loading={loading}
                    pagination={pagination}
                />
            </TableWrapper>
        </Paper>
    );
};

const TableWrapper = styled('div')(({ isCollapse }: { isCollapse: boolean }) => ({
    height: isCollapse ? '500px' : '375px',
}));

const Head = styled('div')({
    display: 'flex',
    marginBottom: '15px',
});
