import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ workers }: RootState) => workers;

export const workersSelector = createSelector(state, ({ workers }) => workers);
export const selectedWorkersIdsSelector = createSelector(state, ({ selectedWorkersIds }) => selectedWorkersIds);
export const searchingWorkerSelector = createSelector(state, ({ search }) => search);
export const isWorkersTableLoaderSelector = createSelector(state, ({ isWorkersTableLoader }) => isWorkersTableLoader);
export const workersPaginationSelector = createSelector(state, ({ pagination }) => pagination);
export const workersFiltersSelector = createSelector(state, ({ filters }) => filters);
export const workersSortOptionsSelector = createSelector(state, ({ sortOptions }) => sortOptions);
export const countWorkersSelector = createSelector(state, ({ count }) => count);
