/* eslint-disable no-shadow */
export enum EWSHistoryType {
    //в уведомлениях в хедере
    IssueNewMessage = 'issue_new_message',
    //в уведомлениях в хедере
    IssueStatusUpdate = 'issue_status_update',
    IssueAddressUpdate = 'issue_address_update',
    //Обновление заявки при добавлении заказа или работы с ними
    IssueUpdate = 'issue_update',

    //клиент от партнера СберМегаМаркет отменяет заказ
    IssueCanceledByPartner = 'issue_canceled_by_partner',

    IssueTimeUpdate = 'issue_time_update',
    //в уведомлениях в хедере
    NewIssue = 'new_issue',
    //в уведомлениях в хедере
    NewChat = 'new_chat',
    WorkerVerification = 'worker_verification',
    //Количество воркеров подлежащих валидации (пока EWorkerStatus.WaitingPassportVerification)
    WorkerNewCountUpdate = 'worker_new_count_update',
    /* Remind Напоминания внизу (внизу чего? TODO выяснить. )*/
    Remind = 'remind',
    RemindInMinute = 'remind_in_minute',
    //количество непрочитанных, когда читается сообщение или отправляется
    ChatUnseenCountUpdate = 'chat_unseen_count_update',
    // Количество чатов поддержки с непрочитанными сообщениями
    ChatSupportTotalUnseenCountUpdate = 'chat_support_total_unseen_count_update',
    ChatUpdate = 'chat_update',
}
