import { styled, Typography } from '@mui/material';

export const CloseIconWrapper = styled('div')({
    position: 'absolute',
    top: '10px',
    right: '15px',
    cursor: 'pointer',
});

export const Field = styled('div')({
    display: 'flex',
    alignItems: 'center',
}); // double

export const SubTitle = styled(Typography)({
    width: '210px',
    fontSize: '14px',
}); // double

export const CopyItemWrapper = styled('div')({
    background: '#F2F6FB',
    padding: '20px 35px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    gap: '15px',
    marginTop: '10px',
});

export const DeleteIconWrapper = styled('div')({
    cursor: 'pointer',
});
