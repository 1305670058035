import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmationContent from './ConfirmationContent';

import { devicesApi } from '../../../../../api';
import Autocomplete from '../../../../../components/inputs/Autocomplete';
import ImgInput from '../../../../../components/inputs/ImgInput';
import TextField from '../../../../../components/inputs/TextField';
import Loader from '../../../../../components/Loader';
import { IOption, IPhotoFile, TErrors } from '../../../../../interfaces';
import { IBrandDetail } from '../../../../../interfaces/brands';
import { ICategoryDetail } from '../../../../../interfaces/categories';
import { IClient, IClientDetail } from '../../../../../interfaces/clients';
import { ICreateDeviceReq, IDeviceDetail, IFormValuesCD, IHomeDeviceDetail } from '../../../../../interfaces/devices';
import { IModelDetail } from '../../../../../interfaces/models';
import { createBrandThunk, getBrandsThunk } from '../../../../../store/actions/brands';
import { createCategoryThunk, getCategoriesThunk } from '../../../../../store/actions/categories';
import { createClientHomeDeviceThunk } from '../../../../../store/actions/clients';
import { createDeviceThunk, setIsOpenCDAction } from '../../../../../store/actions/devices';
import { cancelDialogAction, setDialogAction } from '../../../../../store/actions/dialog';
import { createModelThunk, getModelsThunk } from '../../../../../store/actions/models';
import { useAppDispatch } from '../../../../../store/hooks';
import { brandsOptionsSelector } from '../../../../../store/selectors/brandsSelectors';
import { categoriesOptionsSelector } from '../../../../../store/selectors/categoriesSelectors';
import { isOpenCDSelector } from '../../../../../store/selectors/devicesSelectors';
import { modelsOptionsSelector } from '../../../../../store/selectors/modelsSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import { getFullName } from '../../../../../utils/helpers/getFullName';
import { getQueryParamsAsString } from '../../../../../utils/helpers/getQueryParamsAsString';
import './CreateDevice.scss';

export interface IOnCreateParams {
    newHomeDevice: IHomeDeviceDetail;
    newDevice: IDeviceDetail;
    newCategory?: ICategoryDetail;
    newBrand?: IBrandDetail;
    newModel?: IModelDetail;
}

interface IProps {
    onCreate?: ({ newHomeDevice, newDevice, newCategory }: IOnCreateParams) => void;
    defaultClient: IClient | IClientDetail;
}

function CreateDevice({ onCreate, defaultClient }: IProps) {
    const dispatch = useAppDispatch();

    const categoriesOptions = useSelector(categoriesOptionsSelector);
    const brandsOptions = useSelector(brandsOptionsSelector);
    const modelsOptions = useSelector(modelsOptionsSelector);
    const isOpen = useSelector(isOpenCDSelector);

    const [formValues, setFormValues] = useState<IFormValuesCD | null>(null);
    const [errors, setErrors] = useState<TErrors | null>(null);

    const [isNewCategory, setIsNewCategory] = useState<boolean>(false);
    const [isNewBrand, setIsNewBrand] = useState<boolean>(false);
    const [isNewModel, setIsNewModel] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

    const validator = useCallback(() => {
        const errs = {} as TErrors;
        if (isNewCategory && !formValues?.category_name) {
            errs.category_name = { message: 'Обязательное поле' };
        }

        if (isNewBrand && !formValues?.brand_name) {
            errs.brand_name = { message: 'Обязательное поле' };
        }

        if (isNewModel && !formValues?.model_name) {
            errs.model_name = { message: 'Обязательное поле' };
        }

        if (!isNewCategory && !formValues?.category_id) {
            errs.category_id = { message: 'Обязательное поле' };
        }

        if (!isNewBrand && !formValues?.brand_id) {
            errs.brand_id = { message: 'Обязательное поле' };
        }

        setErrors(errs);
        return !Object.values(errs).some((val) => val);
    }, [formValues, isNewCategory, isNewBrand, isNewModel, isOpen]);

    useEffect(() => {
        async function handle() {
            if (!isOpen) {
                setFormValues(null);
                setErrors(null);
                setIsLoading(false);
                setIsBtnDisabled(true);
                setIsNewCategory(false);
                setIsNewBrand(false);
                setIsNewModel(false);
            } else {
                /* при расширении логики установки дефолтных значений - вынести в const defaultValues = useMemo */
                setFormValues({ ...formValues, clientId: defaultClient.id });
                try {
                    await dispatch(getCategoriesThunk({}));
                    await dispatch(getBrandsThunk());
                    await dispatch(getModelsThunk({}));
                } catch (err) {
                    defaultErrorCallback({ errorMessage: getErrorString({ err }) });
                }
            }
        }
        handle();
    }, [isOpen]);

    useEffect(() => {
        if (errors) {
            validator();
        }
        if (!formValues) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [formValues]);

    function changeNewCategoryHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...formValues, category_name: e.target.value });
    }
    async function changeCategoryHandler(option: IOption | null) {
        setFormValues({ ...formValues, category_id: option?.value as string });
        await dispatch(getBrandsThunk(getQueryParamsAsString([{ category_id: option?.value }])));
        await dispatch(
            getModelsThunk({ category_id: option?.value as string, brand_id: formValues?.brand_id || undefined }),
        );
    }
    async function changeCategoryInpHandler(val: string) {
        await dispatch(getCategoriesThunk({ search: val }));
    }

    function changeNewBrandHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...formValues, brand_name: e.target.value });
    }
    async function changeBrandHandler(option: IOption | null) {
        setFormValues({ ...formValues, brand_id: option?.value as string });
        await dispatch(
            getModelsThunk({
                category_id: formValues?.category_id || undefined,
                brand_id: (option?.value as string) || undefined,
            }),
        );
    }
    async function changeBrandInpHandler(val: string) {
        await dispatch(
            getBrandsThunk(getQueryParamsAsString([{ category_id: formValues?.category_id }, { search: val }])),
        );
    }

    function changeNewModelHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...formValues, model_name: e.target?.value });
    }
    function changeModelHandler(option: IOption | null) {
        setFormValues({ ...formValues, model_id: option?.value as string });
    }
    async function changeModelInpHandler(val: string) {
        await dispatch(
            getModelsThunk({
                search: val,
                category_id: formValues?.category_id || undefined,
                brand_id: formValues?.brand_id || undefined,
            }),
        );
    }

    function changeDevicePhotoFileHandler(files: IPhotoFile[] | null) {
        const file = files ? files[0] : null;
        setFormValues({ ...formValues, device_photo_file_id: file?.id || null });
    }

    function changeReceiptPhotoFileHandler(files: IPhotoFile[] | null) {
        const file = files ? files[0] : null;
        setFormValues({ ...formValues, receipt_photo_file_id: file?.id || null });
    }

    function changeWarrantyPhotoFileHandler(files: IPhotoFile[] | null) {
        const file = files ? files[0] : null;
        setFormValues({ ...formValues, warranty_photo_file_id: file?.id || null });
    }

    function changeSerialNumberHandler(e: React.ChangeEvent<HTMLInputElement>) {
        setFormValues({ ...formValues, serial_number: e.target?.value });
    }

    function onCloseHandler() {
        dispatch(setIsOpenCDAction(false));
    }

    async function onCreateHandler() {
        if (!formValues) return;

        if (validator()) {
            try {
                setIsLoading(true);

                const createDeviceReq: Partial<ICreateDeviceReq> = {};

                const {
                    category_name,
                    category_id,
                    brand_name,
                    brand_id,
                    model_name,
                    model_id,
                    receipt_photo_file_id,
                    device_photo_file_id,
                    warranty_photo_file_id,
                    serial_number,
                    clientId,
                } = formValues;
                if (category_name && isNewCategory) {
                    const newCategory = await dispatch(createCategoryThunk({ name: category_name }));
                    createDeviceReq.category_id = newCategory.id;
                } else if (category_id) {
                    createDeviceReq.category_id = category_id;
                }

                if (brand_name && isNewBrand) {
                    const newBrand = await dispatch(createBrandThunk({ name: brand_name }));
                    createDeviceReq.brand_id = newBrand.id;
                } else if (brand_id) {
                    createDeviceReq.brand_id = brand_id;
                }

                if (model_name && isNewModel) {
                    const newModel = await dispatch(createModelThunk({ name: model_name }));
                    createDeviceReq.model_id = newModel.id;
                } else if (model_id) {
                    createDeviceReq.model_id = model_id;
                }

                let newDevice: IDeviceDetail | null = null;

                /* Временное решение, пока бэк не сделает это всё через запрос из createDevice(createDeviceReq: ICreateDeviceReq) */
                try {
                    newDevice = await dispatch(createDeviceThunk(createDeviceReq as ICreateDeviceReq));
                } catch (err) {
                    const error = err as AxiosError;
                    if (error?.response?.status === 400) {
                        const { data } = await devicesApi.getHomeDeviceByParams(
                            getQueryParamsAsString([
                                { category_id: formValues.category_id },
                                { brand_id: formValues.brand_id },
                                { model_id: formValues.model_id },
                            ]),
                        );
                        const { data: deviceDetail } = await devicesApi.getDeviceDetail(data.id);
                        newDevice = deviceDetail;
                    }
                }

                if (!clientId) {
                    console.error('Error! onCreateHandler() !clientId');
                    return;
                }

                if (!newDevice) {
                    console.error('Error! onCreateHandler() !newDevice');
                    return;
                }

                const createHomeDeviceReq = {
                    clientId,
                    serial_number: serial_number as string,
                    home_device_id: newDevice?.id,
                    receipt_photo_file_id,
                    device_photo_file_id,
                    warranty_photo_file_id,
                };

                const newHomeDevice = await dispatch(createClientHomeDeviceThunk(createHomeDeviceReq));
                onCreate && onCreate({ newHomeDevice, newDevice });
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    function showModalConfirmation() {
        if (formValues && validator()) {
            dispatch(
                setDialogAction({
                    visible: true,
                    onCancel: () => {
                        dispatch(cancelDialogAction());
                    },
                    onOk: () => {
                        onCreateHandler();
                        dispatch(cancelDialogAction());
                    },
                    onCancelText: 'Отмена',
                    onOkText: 'Сохранить',
                    titleText: (
                        <Box className="new-issue-dialog-title">
                            <NoteAddOutlinedIcon
                                color="success"
                                sx={{ marginRight: '30px' }}
                                fontSize="large"
                            />{' '}
                            <Typography variant="h6">Добавились новые значения</Typography>
                        </Box>
                    ),
                    content: (
                        <ConfirmationContent
                            formValues={formValues}
                            client={defaultClient}
                            isNewCategory={isNewCategory}
                            isNewBrand={isNewBrand}
                            isNewModel={isNewModel}
                        />
                    ),
                    autoClose: false,
                }),
            );
        }
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="paper"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Новая техника</Typography>
                </Box>
            </DialogTitle>
            {isOpen && (
                <DialogContent sx={{ minHeight: '500px', position: 'relative' }}>
                    {isLoading && <Loader />}
                    <FormControl>
                        <RadioGroup
                            row
                            name="category_radio"
                            defaultValue="select"
                            onChange={(_, value) => {
                                setIsNewCategory(value === 'new');
                            }}
                        >
                            <FormControlLabel
                                value="select"
                                control={<Radio />}
                                label="Выбрать категорию"
                            />
                            <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label="Добавить новую категорию"
                            />
                        </RadioGroup>
                    </FormControl>
                    {isNewCategory ? (
                        <TextField
                            name="category_name"
                            errors={errors}
                            onChange={changeNewCategoryHandler}
                            placeholder="Категория*"
                            labelText="Категория*"
                            fullWidth
                            params={{ margin: 'normal' }}
                        />
                    ) : (
                        <Autocomplete
                            name="category_id"
                            inpPlaceholder="Категория*"
                            margin="normal"
                            errors={errors}
                            options={categoriesOptions}
                            onChange={changeCategoryHandler}
                            onInpChange={changeCategoryInpHandler}
                        />
                    )}
                    <FormControl>
                        <RadioGroup
                            row
                            name="brand_radio"
                            defaultValue="select"
                            onChange={(_, value) => {
                                setIsNewBrand(value === 'new');
                            }}
                        >
                            <FormControlLabel
                                value="select"
                                control={<Radio />}
                                label="Выбрать бренд"
                            />
                            <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label="Добавить новый бренд"
                            />
                        </RadioGroup>
                    </FormControl>
                    {isNewBrand ? (
                        <TextField
                            name="brand_name"
                            errors={errors}
                            onChange={changeNewBrandHandler}
                            placeholder="Бренд*"
                            labelText="Бренд*"
                            fullWidth
                            params={{ margin: 'normal' }}
                        />
                    ) : (
                        <Autocomplete
                            name="brand_id"
                            inpPlaceholder="Бренд*"
                            margin="normal"
                            errors={errors}
                            options={brandsOptions}
                            onChange={changeBrandHandler}
                            onInpChange={changeBrandInpHandler}
                        />
                    )}
                    <FormControl>
                        <RadioGroup
                            row
                            name="model_radio"
                            defaultValue="select"
                            onChange={(_, value) => {
                                setIsNewModel(value === 'new');
                            }}
                        >
                            <FormControlLabel
                                value="select"
                                control={<Radio />}
                                label="Выбрать модель"
                            />
                            <FormControlLabel
                                value="new"
                                control={<Radio />}
                                label="Добавить новую модель"
                            />
                        </RadioGroup>
                    </FormControl>
                    {isNewModel ? (
                        <TextField
                            name="model_name"
                            errors={errors}
                            onChange={changeNewModelHandler}
                            placeholder="Модель*"
                            labelText="Модель*"
                            fullWidth
                            params={{ margin: 'normal' }}
                        />
                    ) : (
                        <Autocomplete
                            name="model_id"
                            inpPlaceholder="Модель*"
                            margin="normal"
                            errors={errors}
                            options={modelsOptions}
                            onChange={changeModelHandler}
                            onInpChange={changeModelInpHandler}
                        />
                    )}
                    <TextField
                        name={'client'}
                        labelText="Клиент*"
                        placeholder="Клиент*"
                        disabled
                        params={{ margin: 'normal' }}
                        fullWidth
                        defaultValue={getFullName(defaultClient)}
                    />
                    <TextField
                        name="serial_number"
                        errors={errors}
                        onChange={changeSerialNumberHandler}
                        placeholder="Серийный номер"
                        labelText="Серийный номер"
                        fullWidth
                        params={{ margin: 'normal' }}
                    />
                    <div className="create-device-photo">
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            Фото техники
                        </Typography>
                        <ImgInput
                            name={'device_photo_file_id'}
                            onChange={changeDevicePhotoFileHandler}
                        />
                    </div>
                    <div className="create-device-photo">
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            Фото чека
                        </Typography>
                        <ImgInput
                            name={'receipt_photo_file_id'}
                            onChange={changeReceiptPhotoFileHandler}
                        />
                    </div>
                    <div className="create-device-photo">
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            Фото гарантии
                        </Typography>
                        <ImgInput
                            name={'warranty_photo_file_id'}
                            onChange={changeWarrantyPhotoFileHandler}
                        />
                    </div>
                </DialogContent>
            )}
            <DialogActions>
                <Button
                    onClick={onCloseHandler}
                    disabled={isLoading}
                >
                    Отмена
                </Button>
                <LoadingButton
                    onClick={showModalConfirmation}
                    loading={isLoading}
                    disabled={isBtnDisabled}
                    loadingPosition="center"
                    color="primary"
                >
                    Создать
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateDevice;
