/* eslint-disable no-shadow */

export enum EChatTypes {
    Support = 'support',
    IssueRelated = 'issue_related',
    TelegramBot = 'telegram_bot',
}

export enum EChatUserTypes {
    Client = 'client',
    Worker = 'worker',
}

export enum EMessageType {
    General = 'general',
    System = 'system',
}
