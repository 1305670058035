import { EPricesServicesActions } from '../../enums/actions.enum';
import { EPricesServicesTabs } from '../../enums/pricesServices.enum';
import { IPricesServicesState, TPricesServicesActions } from '../../interfaces/pricesServices';

const initState: IPricesServicesState = {
    services: null,
    service: null,
    servicesTab: EPricesServicesTabs.Description,
    isServiceLoading: false,
    isOpenDeletePricesServices: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const pricesServicesReducer = (state = initialState, action: TPricesServicesActions): IPricesServicesState => {
    switch (action.type) {
        case EPricesServicesActions.SetServices:
            return {
                ...state,
                services: action.payload,
            };
        case EPricesServicesActions.SetService:
            return {
                ...state,
                service: action.payload,
            };
        case EPricesServicesActions.SetServicesTab:
            return {
                ...state,
                servicesTab: action.payload,
            };
        case EPricesServicesActions.SetIsServiceLoading:
            return {
                ...state,
                isServiceLoading: action.payload,
            };
        case EPricesServicesActions.SetIsOpenDeletePricesServices:
            return {
                ...state,
                isOpenDeletePricesServices: action.payload,
            };
        default:
            return state;
    }
};
