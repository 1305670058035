import { EEditWorkerInfoActions } from '../../../enums/actions.enum';
import { IEditWorkerInfoState, TEditWorkerInfoActions } from '../../../interfaces/edit/editWorkerInfo';

const initState: IEditWorkerInfoState = {
    isOpen: false,
    isLoading: false,
    info: null,
    errors: null,
    educationType: null,
    educationalProgram: null,
    additionalEducation: null,
    institutionName: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editWorkerInfoReducer = (state = initialState, action: TEditWorkerInfoActions): IEditWorkerInfoState => {
    switch (action.type) {
        case EEditWorkerInfoActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditWorkerInfoActions.SetIsLoading:
            return {
                ...state,
                isLoading: action.payload,
            };
        case EEditWorkerInfoActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case EEditWorkerInfoActions.SetEducationalProgram:
            return {
                ...state,
                educationalProgram: action.payload,
            };
        case EEditWorkerInfoActions.SetInstitutionName:
            return {
                ...state,
                institutionName: action.payload,
            };
        case EEditWorkerInfoActions.SetEducationType:
            return {
                ...state,
                educationType: action.payload,
            };
        case EEditWorkerInfoActions.SetAdditionalEducation:
            return {
                ...state,
                additionalEducation: action.payload,
            };
        default:
            return state;
    }
};
