import { issueImportApi } from 'api';
import { getErrorString } from 'utils/helpers/getErrorString';
import { getQueryParamsAsString } from 'utils/helpers/getQueryParamsAsString';

import { Typography, Box, Button } from '@mui/material';
import { IOption } from 'interfaces';
import { ICategory } from 'interfaces/categories';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import ImportTable from './ImportTable';

import { categoriesApi } from '../../api';
import Loader from '../../components/Loader';
import { IImportGsisData, IImportFileGsisResponse } from '../../interfaces/importGsis';
import MainLayout from '../../layouts/MainLayout';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import showToast from '../../utils/helpers/showToast';
import './ImportGsisPage.scss';

export const ImportIssuesPage = () => {
    const [data, updateData] = useState<IImportGsisData[]>([]);
    const [isLoader, setLoader] = useState<boolean>(false);
    const [options, setOptions] = useState<ICategory[]>([]);

    const { partnerId } = useParams();
    const partnerName = useSearchParams()[0].get('partner_name');

    async function uploadFile(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event?.target?.files && event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setLoader(true);

            try {
                let dataImportFile;

                switch (partnerName) {
                    case 'Haier': {
                        dataImportFile = await issueImportApi.importGsisFile(formData, {
                            partner_id: partnerId,
                        });
                        break;
                    }
                    case 'DNS': {
                        dataImportFile = await issueImportApi.importDnsFile(formData, {
                            partner_id: partnerId,
                        });
                        break;
                    }
                    default:
                        break;
                }

                const transformedData = dataImportFile?.data.map((item: IImportFileGsisResponse) => {
                    const isLocal = item.issue.home_device.category.name !== '-' ? false : true;
                    return { ...item, selected_similar_issue: null, isLocal };
                });
                updateData(transformedData || []);
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setLoader(false);
            }
        }
    }

    function onChangeSelectedSimilarIssue(idx: number, value: string | null) {
        const newData = data.map((item, index) => {
            if (index !== idx) {
                return item;
            } else {
                return { ...item, selected_similar_issue: value !== 'new' ? value : null };
            }
        });
        updateData(newData);
    }

    async function onSave() {
        setLoader(true);

        const requestData = data.map((item) => {
            return {
                client: item.client,
                issue: {
                    ...item.issue,
                    similar_issue_id: item.selected_similar_issue,
                },
            };
        });

        try {
            await issueImportApi.saveData(requestData, { partner_id: partnerId });
            showToast({
                isVisible: true,
                message:
                    'Данные успешно импортированы. Нужно подождать некоторое время, чтобы изменения вступили в силу!',
            });
            updateData([]);
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setLoader(false);
        }
    }

    const searchClient = (val: string) => {
        getSearchDevices(val);
    };

    const getSearchDevices = (search: string | null) => {
        const queries = getQueryParamsAsString(search ? [{ search }] : [{ size: 100 }]);
        categoriesApi.getCategories(queries).then((res) => {
            setOptions(res.data.items);
        });
    };

    useEffect(() => {
        getSearchDevices(null);
    }, []);

    const onChangeDevice = (option: IOption | null, id: string) => {
        const changedData = data.map((item) => {
            if (item.client.id === id) {
                item.issue.home_device.category.id = String(option?.value || '-');
                item.issue.home_device.category.name = String(option?.label || '-');
                return item;
            } else {
                return item;
            }
        });
        updateData(changedData);
    };

    const onBlurSearch = () => {
        getSearchDevices(null);
    };

    const formattedOptions = options.map((item) => ({
        value: item.id,
        label: item.name,
    }));

    return (
        <MainLayout>
            <div className="import-gsis-page-wrapper">
                {isLoader && <Loader />}
                <div className="import-gsis-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        Импорт заявок {partnerName}
                    </Typography>
                    <Box>
                        <Button
                            className="import-table-upload-file"
                            variant="contained"
                            component="label"
                        >
                            Выбрать файл
                            <input
                                type="file"
                                hidden
                                onChange={uploadFile}
                            />
                        </Button>
                        <Button
                            className="import-table-save-button"
                            disabled={data?.length === 0}
                            variant="contained"
                            color="primary"
                            onClick={onSave}
                        >
                            Сохранить
                        </Button>
                    </Box>
                </div>
                <ImportTable
                    data={data}
                    options={formattedOptions}
                    onBlurSearch={onBlurSearch}
                    searchClient={searchClient}
                    onChangeDevice={onChangeDevice}
                    onChangeSelectedSimilarIssue={onChangeSelectedSimilarIssue}
                />
            </div>
        </MainLayout>
    );
};
