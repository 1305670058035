import { EDictsActions } from '../../enums/actions.enum';
import { IDictsState, TDictsActions } from '../../interfaces/dicts';

const initState: IDictsState = {
    addresses: null,
    serviceAreas: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const dictsReducer = (state = initialState, action: TDictsActions): IDictsState => {
    switch (action.type) {
        case EDictsActions.SetAddresses:
            return {
                ...state,
                addresses: action.payload,
            };
        case EDictsActions.SetServiceAreas:
            return {
                ...state,
                serviceAreas: action.payload,
            };
        default:
            return state;
    }
};
