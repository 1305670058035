import { Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import React, { MouseEvent } from 'react';

import { EIssueHelpType } from '../../../enums/issues.enum';
import { helpTypeForWorkersMock } from '../../../mockData/workers';
import './HelpType.scss';

interface IProps {
    selectedHelpType: EIssueHelpType[] | null;
    onChange: (helpTypeStr: EIssueHelpType[] | null) => void;
}

function HelpType({ onChange, selectedHelpType }: IProps) {
    function onClickFormGroup(e: MouseEvent) {
        e.stopPropagation();
    }

    function selectHelpType(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        checked
            ? onChange(
                  selectedHelpType
                      ? [...selectedHelpType, event.target.value as EIssueHelpType]
                      : [event.target.value as EIssueHelpType],
              )
            : onChange(
                  selectedHelpType && selectedHelpType.filter((helpTypeStr) => helpTypeStr !== event.target.value),
              );
    }

    return (
        <Paper className="help-type-wrapper">
            <div className="scroll-wrapper">
                {helpTypeForWorkersMock.map(({ value, label }) => (
                    <FormGroup
                        key={value}
                        sx={{ marginLeft: 1 }}
                        onClick={onClickFormGroup}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={selectHelpType}
                                    value={value}
                                    checked={!!selectedHelpType?.some((spec) => spec === value)}
                                />
                            }
                            label={label}
                        />
                    </FormGroup>
                ))}
            </div>
        </Paper>
    );
}

export default HelpType;
