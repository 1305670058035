import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Popover } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import { setIsOpenPriceDialogAction } from '../../../store/actions/priceDialog';
import { setIsOpenPriceServicesDialogAction } from '../../../store/actions/priceServicesDialog';
import { setIsOpenDeletePriceServicesAction } from '../../../store/actions/pricesServices';
import { useAppDispatch } from '../../../store/hooks';

function ServicesButtonGroup() {
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState(null);
    const [isOpenPrices, setIsOpenPrices] = useState(null);

    function deletePriceServices() {
        dispatch(setIsOpenDeletePriceServicesAction(true));
    }

    function openEditPriceServicesModal() {
        dispatch(setIsOpenPriceServicesDialogAction({ isEdit: true, isOpen: true }));
    }

    // eslint-disable-next-line no-shadow
    const createNewPrice = (isOpen: boolean, isEdit: boolean, isAdditional: boolean) => {
        dispatch(
            setIsOpenPriceDialogAction({
                isOpen,
                isEdit,
                isAdditional,
            }),
        );
        setIsOpenPrices(null);
        setIsOpen(null);
    };

    return (
        <div className="button-group">
            <Button
                color="primary"
                variant="contained"
                onClick={(e: any) => setIsOpen(e.currentTarget)}
                endIcon={<ExpandMoreIcon />}
            >
                Меню
            </Button>
            {isOpen && (
                <Popover
                    open={Boolean(isOpen)}
                    anchorEl={isOpen}
                    onClose={() => setIsOpen(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: { boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)' },
                    }}
                >
                    {isOpenPrices && (
                        <Popover
                            open={Boolean(isOpenPrices)}
                            anchorEl={isOpenPrices}
                            onClose={() => setIsOpenPrices(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                style: { boxShadow: '0px 10px 13px rgba(17, 38, 146, 0.05)' },
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    createNewPrice(true, false, true);
                                }}
                            >
                                Основную
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    createNewPrice(true, false, false);
                                }}
                            >
                                Дополнительную
                            </MenuItem>
                        </Popover>
                    )}
                    <MenuItem
                        onClick={(e: any) => setIsOpenPrices(e.currentTarget)}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        Добавить услугу
                        <ExpandMoreIcon />
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            openEditPriceServicesModal();
                            setIsOpen(null);
                        }}
                    >
                        Редактировать
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            deletePriceServices();
                            setIsOpen(null);
                        }}
                        sx={{ color: 'red' }}
                    >
                        Удалить
                    </MenuItem>
                </Popover>
            )}
        </div>
    );
}

export default ServicesButtonGroup;
