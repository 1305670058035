import StarIcon from '@mui/icons-material/Star';
import { Divider, Paper, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import WorkerAvatar from './Avatar';
import WorkerFIO from './FIO';
import Information from './Information';
import Verification from './Verification';

import { chatsApi } from '../../../api';
import { EChatTypes, EChatUserTypes } from '../../../enums/chats.enum';
import { IChatData } from '../../../interfaces/chats';
import ChatModule from '../../../modules/ChatModule';
import { workerDetailSelector } from '../../../store/selectors/workerSelectors';
import { dateFormatter } from '../../../utils/helpers/dateFormatter';
import './WorkerCard.scss';

function WorkerCard() {
    const worker = useSelector(workerDetailSelector);

    const [chatId, setChatId] = useState<IChatData['id'] | null>(null);
    const [chanelId, setChanelId] = useState<IChatData['channel_id'] | null>(null);

    useEffect(() => {
        async function handle() {
            if (!worker) return;
            const workerChatReq = {
                chat_type: EChatTypes.Support,
                user: {
                    id: worker.id,
                    type: EChatUserTypes.Worker,
                },
            };
            try {
                const { data } = await chatsApi.postChatInfo(workerChatReq);
                setChatId(data.chat.id);
                setChanelId(data.chat.channel_id);
            } catch (err) {
                console.log(err);
            }
        }
        handle();
    }, [worker]);

    return (
        worker && (
            <Paper
                className="worker-card"
                sx={{ p: 3 }}
            >
                <div className="worker-card-info">
                    <div className="worker-card-left">
                        <WorkerAvatar />
                        <WorkerFIO />
                        <div className="worker-rating">
                            {worker.rating > 0 ? (
                                <>
                                    <div className="worker-rating-label">Рейтинг</div>
                                    <div className="worker-rating-star-value-wrapper">
                                        <StarIcon className="rating-star" />
                                        <div className="rating-value">{worker.rating}</div>
                                    </div>
                                </>
                            ) : (
                                'Рейтинг не сформирован'
                            )}
                        </div>
                        <div className="worker-detail-info">
                            <div className="worker-detail-info-item">
                                <div>ID</div>
                                <div>{worker.id}</div>
                            </div>
                            <div className="worker-detail-info-item">
                                <div>Телефон</div>
                                <div>
                                    {worker.phone_number && worker.phone_number.length > 13 ? (
                                        <Tooltip
                                            title={worker.phone_number}
                                            placement="bottom"
                                        >
                                            <div>{worker.phone_number.slice(0, 13)}...</div>
                                        </Tooltip>
                                    ) : (
                                        worker.phone_number
                                    )}
                                </div>
                            </div>
                            <div className="worker-detail-info-item">
                                <div>Регистрация</div>
                                <div>
                                    {dateFormatter(
                                        worker.created_at,
                                        { isDateTime: true },
                                        { day: 'numeric', month: 'numeric', year: 'numeric' },
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="worker-card-center">
                        <div className="done-today-balance-wrapper">
                            <div className="worker-done-today">
                                <div className="worker-done-value">{worker.today_resolved_issues_count}</div>
                                <div className="worker-done-caption">Выполнено сегодня</div>
                            </div>
                            <Divider orientation="vertical" />
                            <div className="worker-balance">
                                <div className="worker-balance-value">
                                    {worker.balance?.toString() || 'Нет данных'} ₽
                                </div>
                                <div className="worker-balance-caption">Баланс</div>
                            </div>
                        </div>
                        <Verification />
                    </div>
                    <div className="worker-card-right">
                        {!!chanelId && !!chatId && (
                            <ChatModule
                                chatId={chatId}
                                channelId={chanelId}
                                headerText={'Чат с мастером'}
                            />
                        )}
                    </div>
                </div>
                <Information />
            </Paper>
        )
    );
}

export default WorkerCard;
