import { blue } from '@mui/material/colors';
import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
    openSideBarBtn: {
        background: blue[700],
        '&:hover': {
            background: `${blue[800]}!important`,
        },
    },
});
