import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EIssueHelpType } from '../../../../../enums/issues.enum';
import { changeIssueHelpTypeReq } from '../../../../../interfaces/issues';
import { issueHelpTypeEditingAllowed, issueHelpTypeNamesMock } from '../../../../../mockData/issues';
import { changeIssueHelpTypeThunk } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

function HelpType() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const { id: issueId, help_type } = issue || {};

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<changeIssueHelpTypeReq | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!help_type) return;
        setFormValues({ help_type });
    }, [help_type]);

    function handleClose() {
        setIsOpen(false);
    }

    function changeHelpTypeHandler(e: SelectChangeEvent<EIssueHelpType>) {
        setFormValues({ help_type: e.target.value as EIssueHelpType });
    }

    async function submitHandler() {
        try {
            if (!issueId || !formValues) return;
            setIsLoading(true);
            await dispatch(changeIssueHelpTypeThunk(issueId, formValues));
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="val-prop-iss">
            {(() => {
                switch (true) {
                    case !!help_type && issueHelpTypeEditingAllowed.includes(help_type): {
                        return (
                            <>
                                <div>{issueHelpTypeNamesMock[help_type!]}</div>
                                <IconButton
                                    onClick={() => {
                                        setIsOpen(!isOpen);
                                    }}
                                    className="edit-iss-prop-icon"
                                >
                                    <EditSharpIcon />
                                </IconButton>
                            </>
                        );
                    }
                    case !!help_type: {
                        return <div>{issueHelpTypeNamesMock[help_type!]}</div>;
                    }
                    default:
                        return <div>Тип не указан</div>;
                }
            })()}

            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box className="modal-description-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменение типа заявки</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <FormControl
                        fullWidth
                        margin="dense"
                    >
                        <InputLabel id="help-type-label">Тип заявки</InputLabel>
                        <Select
                            labelId="help-type-label"
                            name="help-type"
                            margin="dense"
                            label="Тип услуги"
                            onChange={changeHelpTypeHandler}
                            defaultValue={issue?.help_type}
                        >
                            <MenuItem value={EIssueHelpType.Repair}>Ремонт</MenuItem>
                            <MenuItem value={EIssueHelpType.Installation}>Установка</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={submitHandler}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default HelpType;
