export const Edit = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.66771 15.1354L13.4776 5.03577C13.902 4.49114 14.0529 3.86146 13.9114 3.22032C13.7888 2.63747 13.4304 2.08328 12.8928 1.66286L11.5817 0.621368C10.4404 -0.286355 9.02557 -0.190805 8.2144 0.850688L7.33721 1.98869C7.2262 2.12832 7.25128 2.33321 7.38577 2.44921C7.39105 2.45376 7.396 2.45769 7.40143 2.46204C7.54316 2.57568 9.61198 4.23446 9.65752 4.27137C9.80844 4.41469 9.92162 4.60579 9.94992 4.83511C9.99708 5.2842 9.68582 5.70462 9.22364 5.76195C9.0067 5.79061 8.7992 5.72373 8.64828 5.59951L6.31853 3.74585C6.20535 3.66081 6.03557 3.67896 5.94124 3.79362L0.404553 10.9599C0.0461303 11.4089 -0.076488 11.9918 0.0461303 12.5555L0.753544 15.6227C0.791273 15.7851 0.932755 15.8998 1.10253 15.8998L4.21515 15.8616C4.78109 15.852 5.30929 15.594 5.66771 15.1354ZM10.0266 14.1808H15.1021C15.5973 14.1808 16 14.5888 16 15.0905C16 15.5931 15.5973 16.0001 15.1021 16.0001H10.0266C9.53143 16.0001 9.12868 15.5931 9.12868 15.0905C9.12868 14.5888 9.53143 14.1808 10.0266 14.1808Z"
                fill="#281C50"
            />
        </svg>
    );
};
