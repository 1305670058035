import { servicePriceListsApi, servicesApi } from 'api';
import Loader from 'components/Loader';
import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import { fetchServiceComplex } from 'pages/price-lists/store';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { FootNote } from 'pages/services/components/footnote';
import { useAppDispatch } from 'store/hooks';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, TextField, Typography, Checkbox, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
    Asterisk,
    Container,
    Field,
    FieldsContainer,
    ModalWrapper,
    SubTitle,
    TitleWrapper,
    CellInputProps,
} from '../add-complex-services/styles';

type Option = { label: string; value: string };

export const EditComplexServices = () => {
    const dispatch = useAppDispatch();
    const { priceListId, serviceComplexId } = useParams<{ priceListId: string; serviceComplexId: string }>();

    const { serviceComplex } = useSelector(priceListsSelector);
    // console.log(serviceComplex);

    const [includesIds, setIncludesIds] = useState<any>(serviceComplex?.include_descriptions ?? []);
    includesIds;
    setIncludesIds;

    const [servicesOptions, setServicesOptions] = useState<Option[]>([]);
    const [descendants, setDescendants] = useState([]);
    // console.log(descendants);

    const handleEditServiceComplex = (data: any) => {
        servicePriceListsApi
            .editServicesComplex(serviceComplex?.id, data)
            .then(() => {
                enqueueSnackbar('Изменение успешно проведено', { variant: 'success' });
                dispatch(fetchServiceComplex(serviceComplexId as string));
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    useEffect(() => {
        if (!serviceComplexId) return;

        dispatch(fetchServiceComplex(serviceComplexId));

        servicesApi
            .getServices({ size: 50 })
            .then((res) => {
                setServicesOptions(res.data.items.map((item) => ({ label: item.name, value: item.id })));
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    }, []);

    useEffect(() => {
        if (!serviceComplex) return;

        const services_ids = serviceComplex?.services.map((item: any) => item.id);

        servicesApi.getDescendants({ services_ids }).then((res) => {
            setDescendants(res.data);
        });
    }, [serviceComplex]);

    const defaultServicesOptions = serviceComplex?.services.map((item: any) => ({ label: item.name, value: item.id }));

    if (!serviceComplex) return <Loader />;

    return (
        <MainLayout>
            <Container>
                <TitleWrapper>
                    <Typography
                        fontWeight={700}
                        sx={{ fontSize: '28px' }}
                    >
                        Изменить комплекс услуг
                    </Typography>
                    <Button sx={{ color: '#0040F1' }}>
                        <Link to={`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}`}>
                            Назад
                        </Link>
                    </Button>
                </TitleWrapper>
                <ModalWrapper>
                    <FieldsContainer>
                        <Field>
                            <SubTitle>
                                Услуги<Asterisk>*</Asterisk>
                            </SubTitle>
                            <Autocomplete
                                sx={{ width: '392px' }}
                                multiple
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={servicesOptions ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                )}
                                onChange={(_, value) => {
                                    const services = value.map((item) => ({
                                        service_id: item.value,
                                        is_deleted: false,
                                    }));
                                    handleEditServiceComplex({ services });
                                }}
                                defaultValue={defaultServicesOptions ?? []}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Наименование<Asterisk>*</Asterisk>
                            </SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                placeholder="Наименование"
                                onBlur={(e) => {
                                    handleEditServiceComplex({ name: e.target.value.trim() });
                                }}
                                defaultValue={serviceComplex.name}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Описание<Asterisk>*</Asterisk>
                            </SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                multiline
                                placeholder="Какое-то описание"
                                minRows={4}
                                onBlur={(e) => {
                                    handleEditServiceComplex({ description: e.target.value.trim() });
                                }}
                                defaultValue={serviceComplex.description}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Цена<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onBlur={(e) => handleEditServiceComplex({ amount: e.target.value.slice(0, -2) })}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                                defaultValue={serviceComplex.amount}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Комиссия мастера<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onBlur={(e) => {
                                    handleEditServiceComplex({ commission: { value: e.target.value.slice(0, -2) } });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                                defaultValue={serviceComplex.commission.commission_value}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                <div>Что входит в стоимость</div>
                                <div style={{ fontSize: '12px', color: '#A4A7B8' }}>
                                    Невыбранные элементы будут отображаться в блоке «Что не входит в стоимость»
                                </div>
                            </SubTitle>
                            <div>
                                {descendants?.map((descendant: any) => {
                                    const isChecked = serviceComplex.include_descriptions?.find(
                                        (item: any) => item.name === descendant.name,
                                    );
                                    // console.log(2, isChecked?.is_active);

                                    return (
                                        <div key={descendant.id}>
                                            <Checkbox
                                                checked={isChecked}
                                                sx={{ padding: '5px 0' }}
                                                onClick={() => {
                                                    handleEditServiceComplex({
                                                        include_descriptions: [
                                                            {
                                                                service_id: descendant.id,
                                                                is_deleted: false,
                                                                is_include_in_price: true,
                                                            },
                                                        ],
                                                    });
                                                }}
                                            />
                                            <span>{descendant.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </Field>
                        <Divider sx={{ width: '600px' }} />
                        <Field>
                            <SubTitle>Доступен в API-интеграции</SubTitle>
                            <Checkbox
                                onClick={() => {
                                    handleEditServiceComplex({
                                        is_active_integration: serviceComplex?.is_active_integration,
                                    });
                                }}
                                sx={{ padding: '5px 0' }}
                                defaultChecked={serviceComplex?.is_active_integration}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Внешний идентификатор</SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                onBlur={(e) => handleEditServiceComplex({ external_id: e.target.value })}
                                defaultValue={serviceComplex?.external_id}
                            />
                        </Field>
                    </FieldsContainer>

                    <FootNote />
                </ModalWrapper>
            </Container>
        </MainLayout>
    );
};
