export const Cancel = () => {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="26"
                height="26"
                rx="5"
                fill="#F56767"
            />
            <path
                d="M18.9998 7L7 18.9998M18.9999 19L7.00005 7.0002"
                stroke="#F5F7FC"
                strokeWidth="2"
            />
        </svg>
    );
};
