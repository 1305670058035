import { IToastPayload } from '../../interfaces/toast';
import { setToastAction } from '../../store/actions/toast';
import { store } from '../../store/store';

export default function showToast({ message, delay, severity, vertical, horizontal }: IToastPayload) {
    const actionData = {
        message,
        delay,
        severity,
        vertical,
        horizontal,
    };
    store.dispatch(setToastAction(actionData));
}
