export const ThreeDots = () => {
    return (
        <svg
            width="5"
            height="20"
            viewBox="0 0 5 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_7056_86394)">
                <path
                    d="M4.98926 2.5206C4.98926 1.13234 3.8712 -4.88718e-08 2.4962 -1.08975e-07C1.1212 -1.69078e-07 -0.000325729 1.13234 -0.00032579 2.5206C-0.000325851 3.90885 1.1212 5.04119 2.4962 5.04119C3.8712 5.04119 4.98926 3.90885 4.98926 2.5206Z"
                    fill="#A4A7B8"
                />
                <path
                    d="M5 10.002C5 8.61379 3.87847 7.48145 2.50347 7.48145C1.12847 7.48145 0.00694365 8.61379 0.00694359 10.002C0.00694353 11.3903 1.12847 12.5226 2.50347 12.5226C3.87847 12.5226 5 11.3903 5 10.002Z"
                    fill="#A4A7B8"
                />
                <path
                    d="M4.98926 17.4825C4.98926 16.0943 3.86773 14.9619 2.49273 14.9619C1.11773 14.9619 -0.000325729 16.0943 -0.00032579 17.4825C-0.000325851 18.8708 1.11773 19.9996 2.49273 19.9996C3.86773 19.9996 4.98926 18.8708 4.98926 17.4825Z"
                    fill="#A4A7B8"
                />
            </g>
            <defs>
                <clipPath id="clip0_7056_86394">
                    <rect
                        width="20"
                        height="5"
                        fill="white"
                        transform="translate(5) rotate(90)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
