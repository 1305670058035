import { AppDispatch } from 'store/store';

import { issuesApi } from '../../../api';
import { EEditIssueStatusToCancelActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    ICancelationReasonsFilters,
    IFormValuesEditIssueStatusToCancel,
    IIssueCancelationReason,
    ISetCancelationReasonsEditIssueStatusToCancel,
    ISetErrorsEditIssueStatusToCancel,
    ISetFormValuesEditIssueStatusToCancel,
    ISetIsOpenEditIssueStatusToCancel,
    ISetIssueIdEditIssueStatusToCancel,
} from '../../../interfaces/edit/editIssueStatusToCancel';
import { IIssue } from '../../../interfaces/issues';
import { getQueryParamsAsString, TArrayParams } from '../../../utils/helpers/getQueryParamsAsString';

export const setIsOpenEditIssueStatusToCancelAction = (payload: boolean): ISetIsOpenEditIssueStatusToCancel => ({
    type: EEditIssueStatusToCancelActions.SetIsOpen,
    payload,
});

export const setIssueIdEditIssueStatusToCancelAction = (
    payload: IIssue['id'] | null,
): ISetIssueIdEditIssueStatusToCancel => ({
    type: EEditIssueStatusToCancelActions.SetIssueId,
    payload,
});

export const setFormValuesEditIssueStatusToCancelAction = (
    payload: IFormValuesEditIssueStatusToCancel | null,
): ISetFormValuesEditIssueStatusToCancel => ({
    type: EEditIssueStatusToCancelActions.SetFormValues,
    payload,
});

export const setErrorsEditIssueStatusToCancelAction = (payload: TErrors | null): ISetErrorsEditIssueStatusToCancel => ({
    type: EEditIssueStatusToCancelActions.SetErrors,
    payload,
});

export const setCancelationReasonsAction = (
    payload: IIssueCancelationReason[] | null,
): ISetCancelationReasonsEditIssueStatusToCancel => ({
    type: EEditIssueStatusToCancelActions.SetCancelationReasons,
    payload,
});

interface IGetCancelationReasonsThunkParams {
    filters: ICancelationReasonsFilters | null;
}

export const getCancelationReasonsThunk = ({ filters }: IGetCancelationReasonsThunkParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [];

            if (filters) {
                const { help_type } = filters;

                if (help_type) {
                    help_type.forEach((ht) => {
                        queriesObj.push({ help_type: ht });
                    });
                }
            }

            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await issuesApi.getIssueCancelationReasons(queries);

            dispatch(setCancelationReasonsAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
