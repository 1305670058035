import { ECentrifugeActions } from '../../enums/actions.enum';
import { ICentrifugeState, TCentrifugeActions } from '../../interfaces/centrifuge';

const initState: ICentrifugeState = {
    centrifugeInstance: null,
    isConnect: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const centrifugeReducer = (state = initialState, action: TCentrifugeActions): ICentrifugeState => {
    switch (action.type) {
        case ECentrifugeActions.SetCentrifuge: {
            return { ...state, centrifugeInstance: action.payload };
        }
        case ECentrifugeActions.SetIsConnect: {
            return { ...state, isConnect: action.payload };
        }
        default:
            return state;
    }
};
