import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IChatDetail, IMessage, IPostMessageReq } from '../interfaces/chat';
import { TChatGroupByUsersResp } from '../interfaces/chatGroupByUsers';
import { IChatInfoReq, IChatInfoResp, IChatUser, TChatsResp } from '../interfaces/chats';
import { IGetChatsThunkParams } from '../store/actions/chatsInfinite';

export default class ChatsAPI {
    getChatsUsers(params?: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/users`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<TChatGroupByUsersResp>;
    }

    getChatsOld(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<TChatsResp>;
    }

    getChats(params?: IGetChatsThunkParams, sortString?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats${
                params ? sortString : `?${sortString}`
            }`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<TChatsResp>;
    }

    getChatDetail(chatId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/${chatId}`,
            isAuthorization: true,
        }) as AxiosPromise<IChatDetail>;
    }

    getChatHistory(chatId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/${chatId}/history`,
            isAuthorization: true,
        }) as AxiosPromise<IMessage[]>;
    }

    getChatParticipants(chatId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/${chatId}/participants`,
            isAuthorization: true,
        }) as AxiosPromise<IChatUser[]>;
    }

    getTotalUnseenCount(params?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/totalUnseenCount`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<{ count: number }>;
    }

    postMessage(data: IPostMessageReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/message`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<void>;
    }

    deleteMessage(chatId: string, messageId: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/${chatId}/message/${messageId}`,
            isAuthorization: true,
        }) as AxiosPromise<void>;
    }

    markMessagesAsViewed(chatId: string, messageId: string) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats/markAsViewed`,
            data: { chat_id: chatId, message_id: messageId },
            isAuthorization: true,
        }) as AxiosPromise<void>;
    }

    postChatInfo(data: IChatInfoReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/chats`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IChatInfoResp>;
    }
}
