import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import React, { memo } from 'react';

import { IPhotoFile } from '../../interfaces';
import './FilePreview.scss';

interface IProps {
    photoFiles: IPhotoFile[] | null;
}

function FilePreview({ photoFiles }: IProps) {
    if (photoFiles && photoFiles.length > 0)
        return (
            <div className="dv-photo-files-preview">
                {photoFiles.map(({ url, mimetype, id, previews }) => {
                    const preview = previews && previews[0];
                    switch (mimetype) {
                        case 'video/mp4':
                            return (
                                <a
                                    key={id}
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <PlayCircleOutlinedIcon
                                        color="primary"
                                        className="dv-pf-play-icon"
                                    />
                                    {preview?.url ? (
                                        <img
                                            src={preview?.url}
                                            alt=""
                                        />
                                    ) : null}
                                </a>
                            );
                        default:
                            return (
                                <a
                                    key={id}
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        src={preview?.url || url}
                                        alt=""
                                    />
                                </a>
                            );
                    }
                })}
            </div>
        );

    return <span>Фото отсутствует</span>;
}

export default memo(FilePreview);
