import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import { Avatar, Box } from '@mui/material';
import React, { memo } from 'react';

import { EUserRole } from '../../enums/user.enum';
import { IPhotoFile } from '../../interfaces';
import { UserRolesMock } from '../../mockData/user';
import { getFullName } from '../../utils/helpers/getFullName';
import './UserSmallData.scss';

interface IProps {
    user: {
        id: string;
        first_name: string;
        last_name: string;
        profile_photo_file?: IPhotoFile | null;
        photo_file?: IPhotoFile | null;
        user_type?: EUserRole;
    };
    widthAvatar?: number;
    heightAvatar?: number;
    afterFullName?: React.ReactNode;
    onAvatarClick?: (userId: string, photoFileId?: IPhotoFile['id']) => void;
}

function UserSmallData({ user, afterFullName, onAvatarClick, widthAvatar = 28, heightAvatar = 28 }: IProps) {
    const { profile_photo_file, photo_file } = user;
    return (
        <Box className="user-small-data-wrapper">
            <Avatar
                sx={{ width: widthAvatar, height: heightAvatar }}
                variant="circular"
                src={profile_photo_file?.url || photo_file?.url}
                onClick={() => onAvatarClick && onAvatarClick(user.id, profile_photo_file?.id || photo_file?.id)}
            >
                {(!profile_photo_file?.url || !photo_file?.url) && <PersonSharpIcon />}
            </Avatar>
            <div className="user-small-data">
                <div>
                    {getFullName(user)}
                    <span style={{ marginLeft: 10 }}>{afterFullName}</span>
                </div>
                <div>
                    {user?.user_type ? (
                        <span>{UserRolesMock[user.user_type].title}</span>
                    ) : (
                        <>
                            id: <span>{user.id}</span>
                        </>
                    )}
                </div>
            </div>
        </Box>
    );
}

export default memo(UserSmallData);
