import AddIcon from '@mui/icons-material/Add';
import { Avatar, Button, Divider } from '@mui/material';
import { format } from 'date-fns';
import React, { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import CommentDialog from './CommentDialog';
import DeleteCommentDialog from './DeleteCommentDialog';
import EditGroupButtons from './EditGroupButtons';

import {
    addCommentThunk,
    deleteCommentThunk,
    editCommentThunk,
    getIssueThunk,
    setIsOpenCommentDialogAction,
    setIsOpenDeleteCommentDialogAction,
} from '../../../../store/actions/issues';
import { useAppDispatch } from '../../../../store/hooks';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';

import './IssueComments.scss';

function IssueComments() {
    const dispatch = useAppDispatch();

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [defaultValue, setDefaultValue] = useState<string | undefined>('');
    const [selectedCommentId, setSelectedCommentId] = useState<string | undefined>('');

    const issue = useSelector(issueSelector);

    const sortedComments = useMemo(() => {
        return issue?.comments?.sort((a, b) => Date.parse(a.created_at as string) - Date.parse(b.created_at as string));
    }, [issue?.comments]);

    function openDialog(commentId?: string, comment?: string) {
        setIsEdit(!!commentId);
        setSelectedCommentId(commentId);
        setDefaultValue(comment || '');
        dispatch(setIsOpenCommentDialogAction(true));
    }

    function openDeleteDialog(commentId: string) {
        setSelectedCommentId(commentId);
        dispatch(setIsOpenDeleteCommentDialogAction(true));
    }

    async function deleteComment() {
        try {
            if (issue && selectedCommentId) {
                await dispatch(deleteCommentThunk(issue.id, selectedCommentId));
                dispatch(setIsOpenDeleteCommentDialogAction(false));
                setSelectedCommentId(undefined);
                await dispatch(getIssueThunk(issue.id));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function addOrEditComment(comment: string) {
        try {
            if (issue) {
                isEdit
                    ? await dispatch(editCommentThunk(issue.id, selectedCommentId!, { comment }))
                    : await dispatch(addCommentThunk(issue.id, { comment }));
                dispatch(setIsOpenCommentDialogAction(false));
                setIsEdit(false);
                setSelectedCommentId(undefined);
                setDefaultValue(undefined);
                await dispatch(getIssueThunk(issue.id));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return issue ? (
        <div style={{ paddingTop: '20px' }}>
            <div className="iss-cl-list">
                {sortedComments &&
                    sortedComments.map((comment) => (
                        <div key={comment.id}>
                            <div className="comment">
                                <div className="comment-data">
                                    <div className="commentator">
                                        <Avatar className="avatar" />
                                        <div className="commentator-data">
                                            <div className="commentator-name">
                                                {comment.user?.first_name} {comment.user?.last_name}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="comment-date">
                                        <div>
                                            Дата создания: {format(new Date(comment.created_at!), 'dd.MM.yyyy HH:mm')}
                                        </div>
                                        {Date.parse(comment.created_at as string) !==
                                        Date.parse(comment.updated_at as string) ? (
                                            <div>
                                                Дата обновления:{' '}
                                                {format(new Date(comment.updated_at!), 'dd.MM.yyyy HH:mm')}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="comment-text">{comment.comment}</div>
                                </div>
                                <EditGroupButtons
                                    comment={comment}
                                    openDialog={openDialog}
                                    openDeleteDialog={openDeleteDialog}
                                />
                            </div>
                            <Divider sx={{ marginBottom: 2 }} />
                        </div>
                    ))}
                <Button
                    fullWidth
                    color="primary"
                    onClick={() => openDialog()}
                >
                    <AddIcon
                        color="primary"
                        sx={{ marginRight: 1 }}
                        fontSize="medium"
                    />
                    Добавить комментарий
                </Button>
            </div>
            <CommentDialog
                isEdit={isEdit}
                addOrEditComment={addOrEditComment}
                defaultValue={defaultValue || ''}
            />
            <DeleteCommentDialog deleteComment={deleteComment} />
        </div>
    ) : null;
}

export default memo(IssueComments);
