import { EBindWorkerMoySkladActions } from '../../../enums/actions.enum';
import {
    ISetFormValuesWorkerMoySklad,
    ISetIsOpenBindWorkerMoySklad,
    ISetSearchResponseWorkerMoySklad,
} from '../../../interfaces/create/bindMoySkald';
import { IBindWorkerMoySklad, IWorkerMoySkladSearchResponse } from '../../../interfaces/create/createMoySklad';

export const setIsOpenBindWorkerMoySkladAction = (payload: boolean): ISetIsOpenBindWorkerMoySklad => ({
    type: EBindWorkerMoySkladActions.SetIsOpen,
    payload,
});

export const setSearchResponseWorkerMoySkladAction = (
    payload: IWorkerMoySkladSearchResponse[],
): ISetSearchResponseWorkerMoySklad => ({
    type: EBindWorkerMoySkladActions.SetSearchResponse,
    payload,
});

export const setFormValuesWorkerMoySkladAction = (payload: IBindWorkerMoySklad): ISetFormValuesWorkerMoySklad => ({
    type: EBindWorkerMoySkladActions.SetFormValues,
    payload,
});
