import { AppDispatch } from 'store/store';

import { modelsApi } from '../../api';
import { EModelsActions } from '../../enums/actions.enum';
import { IBrandDetail } from '../../interfaces/brands';
import { ICategoryDetail } from '../../interfaces/categories';
import { IModel, IModelDetail, ISetModel, ISetModelDetail, ISetModels } from '../../interfaces/models';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';

export const setModelAction = (payload: IModel | null): ISetModel => ({
    type: EModelsActions.SetModel,
    payload,
});

export const setModelDetailAction = (payload: IModelDetail | null): ISetModelDetail => ({
    type: EModelsActions.SetModelDetail,
    payload,
});

export const setModelsAction = (payload: IModel[] | null): ISetModels => ({
    type: EModelsActions.SetModels,
    payload,
});
interface ICreateModelThunkParams {
    name: IModel['name'];
}
export const createModelThunk =
    ({ name }: ICreateModelThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data } = await modelsApi.createModel(name);
            dispatch(setModelDetailAction(data));
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

interface IGetModelsThunkParams {
    page?: string | number;
    size?: string | number;
    search?: string;
    category_id?: ICategoryDetail['id'];
    brand_id?: IBrandDetail['id'];
}
export const getModelsThunk =
    ({ search, category_id, brand_id, page, size }: IGetModelsThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queries = getQueryParamsAsString([
                { search },
                { brand_id },
                { category_id },
                { page: page || 1 },
                { size: size || 50 },
            ]);

            const { data } = await modelsApi.getModels(queries);
            const { items: models } = data;
            dispatch(setModelsAction(models));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
