import './Loader.scss';

function Loader() {
    return (
        <div className="loader-container">
            <div className="lds-dual-ring">{''}</div>
        </div>
    );
}

export default Loader;
