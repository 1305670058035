import { EDevicesActions } from '../../enums/actions.enum';
import { IDevicesState, TDevicesActions } from '../../interfaces/devices';

const initState: IDevicesState = {
    deviceDetail: null,
    homeDeviceDetail: null,
    homeDevices: null,
    isOpen: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const devicesReducer = (state = initialState, action: TDevicesActions): IDevicesState => {
    switch (action.type) {
        case EDevicesActions.SetDevice:
            return {
                ...state,
                deviceDetail: action.payload,
            };
        case EDevicesActions.SetHomeDevice:
            return {
                ...state,
                homeDeviceDetail: action.payload,
            };
        case EDevicesActions.SetHomeDevices:
            return {
                ...state,
                homeDevices: action.payload,
            };
        case EDevicesActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        default:
            return state;
    }
};
