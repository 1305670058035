import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, IconButton, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import { IIssuesObserve } from '../../interfaces/issuesObserves';
import { getFullName } from '../../utils/helpers/getFullName';
import './Observe.scss';

interface IProps {
    observers: IIssuesObserve[] | null;
    isObserver: boolean;
    onChange: () => void;
}

function ObserveModules({ observers, isObserver, onChange }: IProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={1}
        >
            <IconButton
                aria-owns="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
                size="small"
                sx={{ cursor: 'pointer' }}
            >
                <VisibilityIcon color={isObserver ? 'primary' : 'disabled'} />
            </IconButton>
            {observers?.length ? observers.length : null}
            <Menu
                id="observe-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="observe-menu"
            >
                <MenuItem
                    divider
                    onClick={onChange}
                >
                    {isObserver ? (
                        <VisibilityOffIcon
                            color="disabled"
                            sx={{ marginRight: 1 }}
                        />
                    ) : (
                        <VisibilityIcon
                            color="primary"
                            sx={{ marginRight: 1 }}
                        />
                    )}
                    <div>{isObserver ? 'Не отслеживать' : 'Отслеживать'}</div>
                </MenuItem>

                {observers?.length ? (
                    observers.map(({ id, last_name, first_name }) => (
                        <MenuItem
                            key={id}
                            className="observe-menu-item"
                        >
                            <div>{getFullName({ last_name, first_name })}</div>
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem className="observe-menu-item">
                        <div>Заявку никто не отслеживает</div>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
}

export default ObserveModules;
