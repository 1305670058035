import { AppDispatch } from 'store/store';

import { rolesApi } from '../../api';
import { ERolesActions } from '../../enums/actions.enum';
import { IRole, ISetRolesAction } from '../../interfaces/roles';

export const setRolesAction = (payload: IRole[] | null): ISetRolesAction => ({
    type: ERolesActions.SetRoles,
    payload,
});

export const getRolesThunk = () => async (dispatch: AppDispatch) => {
    try {
        const { data: roles } = await rolesApi.getRoles();
        dispatch(setRolesAction(roles));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
