import { ordersApi } from '../../../api';
import { ECreateOrderActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IFormValuesCreateOrder,
    IIsOpenCreateOrder,
    ISetErrorsCreateOrderAction,
    ISetFormValuesCreateOrderAction,
    ISetIsOpenCreateOrderAction,
} from '../../../interfaces/create/createOrder';

/* CO === CreateOrder */
export const setFormValuesCOAction = (payload: IFormValuesCreateOrder | null): ISetFormValuesCreateOrderAction => ({
    type: ECreateOrderActions.SetFormValues,
    payload,
});

export const setErrorsCOAction = (payload: TErrors | null): ISetErrorsCreateOrderAction => ({
    type: ECreateOrderActions.SetErrors,
    payload,
});

export const setIsOpenCOAction = (payload: IIsOpenCreateOrder): ISetIsOpenCreateOrderAction => ({
    type: ECreateOrderActions.SetIsOpenCreateOrder,
    payload,
});

export const createOrdersThunk = (postData: IFormValuesCreateOrder) => async () => {
    try {
        await ordersApi.createOrders(postData);
    } catch (err) {
        console.log(err);
        throw err;
    }
};

export const editOrdersThunk = (orderId: string, postData: IFormValuesCreateOrder) => async () => {
    try {
        await ordersApi.updateOrder({ orderId, data: postData });
    } catch (err) {
        console.log(err);
        throw err;
    }
};
