import { ERatiosActions } from '../../enums/actions.enum';
import { IRatiosState, TRatiosActions } from '../../interfaces/ratios';

const initState: IRatiosState = {
    categories: null,
    partners: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const ratiosReducer = (state = initialState, action: TRatiosActions): IRatiosState => {
    switch (action.type) {
        case ERatiosActions.SetCategories:
            return {
                ...state,
                categories: action.payload,
            };
        case ERatiosActions.SetPartners:
            return {
                ...state,
                partners: action.payload,
            };
        default:
            return state;
    }
};
