import { Box, Chip } from '@mui/material';
import React from 'react';

import { ISolvedIssueResp } from '../../../../interfaces/issues';
import { issueHelpTypeNamesMock, issueStatusesMock } from '../../../../mockData/issues';
import { dateFormatter } from '../../../../utils/helpers/dateFormatter';
import './WorkerIssueHistoryItem.scss';

interface IProps {
    issue: ISolvedIssueResp;
}

function WorkerSolvedIssueHistoryItem({ issue }: IProps) {
    return (
        <Box className="solved-issue-wrapper">
            <div className="issue-created-at">
                {dateFormatter(
                    issue.created_at,
                    { isDateTime: false },
                    { day: 'numeric', month: 'numeric', year: 'numeric' },
                )}
            </div>
            <div className="issue-title">
                <span>
                    Заявка № {issue.oid}
                    .&nbsp;
                    {issueHelpTypeNamesMock[issue.help_type]}
                </span>
            </div>
            <div className="issue-status">
                <Chip
                    label={issueStatusesMock[issue.status]?.title}
                    sx={{
                        fontWeight: '600',
                        backgroundColor: issueStatusesMock[issue.status]?.colorHex,
                        color: issueStatusesMock[issue.status]?.colorText,
                    }}
                    size="small"
                    clickable={false}
                />
            </div>
        </Box>
    );
}

export default WorkerSolvedIssueHistoryItem;
