import { createSelector } from 'reselect';

import { ENotificationType } from '../../enums/invoicingNotifications.enum';
import { RootState } from '../rootReducer';

const state = ({ invoicingNotifications }: RootState) => invoicingNotifications;

export const smsIsOpenINSelector = createSelector(state, ({ smsIsOpen }) => smsIsOpen);
export const emailIsOpenINSelector = createSelector(state, ({ emailIsOpen }) => emailIsOpen);

export const dataINSelector = createSelector(state, ({ notificationData }) => notificationData);
export const invoicingNotificationsSelector = createSelector(
    state,
    ({ invoicingNotifications }) => invoicingNotifications,
);

export const invoicingNotificationsByTypeSelector = createSelector(
    [invoicingNotificationsSelector],
    (invoicingNotifications) => {
        if (!invoicingNotifications) return null;
        return {
            [ENotificationType.Sms]: invoicingNotifications.filter(
                ({ notification_type }) => notification_type === ENotificationType.Sms,
            ),
            [ENotificationType.Email]: invoicingNotifications.filter(
                ({ notification_type }) => notification_type === ENotificationType.Email,
            ),
            [ENotificationType.PushNotification]: invoicingNotifications.filter(
                ({ notification_type }) => notification_type === ENotificationType.PushNotification,
            ),
        };
    },
);
