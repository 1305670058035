import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import React, { ChangeEvent, memo } from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import DatePicker from '../../../../components/inputs/DatePicker';
import TextField from '../../../../components/inputs/TextField';
import TimePicker from '../../../../components/inputs/TimePicker';
import { setFormValuesCreateIssueReminderAction } from '../../../../store/actions/create/createIssueReminder';
import { useAppDispatch } from '../../../../store/hooks';
import {
    errorsCreateIssueReminderSelector,
    formValuesCreateIssueReminderSelector,
} from '../../../../store/selectors/create/createIssueReminderSelectors';
import './CreateIssueReminderForm.scss';

function CreateIssueReminderForm() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesCreateIssueReminderSelector);
    const errors = useSelector(errorsCreateIssueReminderSelector);

    function onChangeText(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCreateIssueReminderAction({ text: e.target.value || null }));
    }

    function onChangeDate(val: Date | null) {
        dispatch(setFormValuesCreateIssueReminderAction({ date_delivery: val }));
    }

    function onChangeTime(val: Date | null) {
        dispatch(setFormValuesCreateIssueReminderAction({ time_delivery: val || null }));
    }

    function onChangeSendOnlyBoUserCreated(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCreateIssueReminderAction({ send_only_bo_user_created: e.target.checked }));
    }

    return (
        <div className="create-issue-reminder-form-wrapper">
            <TextField
                name="text"
                placeholder="Текст напоминания *"
                fullWidth
                errors={errors}
                onChange={onChangeText}
            />
            <div className="date-time">
                <div className="date-time-title">Дата и время напоминания</div>
                <div className="date-time-pickers">
                    <DatePicker
                        name="date_delivery"
                        label="Дата *"
                        fullWidth
                        disablePast
                        errors={errors}
                        onChange={onChangeDate}
                    />
                    <TimePicker
                        name="time_delivery"
                        label="Время *"
                        fullWidth
                        disabled={!formValues?.date_delivery}
                        errors={errors}
                        onChange={onChangeTime}
                    />
                </div>
            </div>
            <FormControl className="send-only-bo-form-control">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={onChangeSendOnlyBoUserCreated}
                                checked={formValues?.send_only_bo_user_created || false}
                            />
                        }
                        label="Прислать напоминание только мне"
                    />
                </FormGroup>
            </FormControl>

            <FooterForm />
        </div>
    );
}

export default memo(CreateIssueReminderForm);
