import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Divider, Switch, SwitchProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import EditInfo from './EditInfo';
import Verify from './Verify';

import { EWorkerEducationType, EWorkerExperience, EWorkerLicenses } from '../../../../enums/editInfo.enum';
import { IOption } from '../../../../interfaces';
import { IEducation } from '../../../../interfaces/workers';
import { genderWorkerMock } from '../../../../mockData/worker';
import './Information.scss';
import {
    addWorkerAddressThunk,
    editWorkerAddressThunk,
    editWorkerDescriptionThunk,
    editWorkerEducationThunk,
    editWorkerInfoThunk,
    setIsOpenEditWorkerInfoAction,
} from '../../../../store/actions/edit/editWorkerInfo';
import { getWorkerThunk } from '../../../../store/actions/worker';
import { useAppDispatch } from '../../../../store/hooks';
import { workerDetailSelector } from '../../../../store/selectors/workerSelectors';

export const workerExperience: string[] = [
    EWorkerExperience.NoExperience,
    EWorkerExperience.OneYear,
    EWorkerExperience.TwoYears,
    EWorkerExperience.FourYears,
    EWorkerExperience.SixYears,
    EWorkerExperience.TenYears,
    EWorkerExperience.FifteenYears,
];

export const workerEducationTypes: string[] = [
    EWorkerEducationType.HigherProfessional,
    EWorkerEducationType.SecondaryProfessional,
    EWorkerEducationType.UnfinishedHigher,
    EWorkerEducationType.BasicGeneral,
    EWorkerEducationType.SecondaryGeneral,
];

export const workersLicenses: string[] = [
    EWorkerLicenses.CategoryM,
    EWorkerLicenses.CategoryA,
    EWorkerLicenses.CategoryB,
    EWorkerLicenses.CategoryBE,
    EWorkerLicenses.CategoryC,
    EWorkerLicenses.CategoryCE,
    EWorkerLicenses.CategoryD,
    EWorkerLicenses.CategoryDE,
    EWorkerLicenses.CategoryA1,
    EWorkerLicenses.CategoryB1,
    EWorkerLicenses.CategoryC1,
    EWorkerLicenses.CategoryC1E,
    EWorkerLicenses.CategoryD1,
    EWorkerLicenses.CategoryD1E,
];

function Information() {
    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);

    const [dialogLabel, setDialogLabel] = useState<string>('');
    const [dialogProps, setDialogProps] = useState<any>(null);
    const [dialogFunction, setDialogFunction] = useState<any>(null);

    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    async function changeIsHasPersonalCar(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        if (worker) {
            await dispatch(editWorkerInfoThunk(worker.id, { is_has_personal_car: checked }));
            dispatch(getWorkerThunk(worker.id));
        }
    }

    function changeEmail() {
        return async (email: string) => {
            if (worker) {
                await dispatch(editWorkerInfoThunk(worker.id, { email }));
                dispatch(getWorkerThunk(worker.id));
            }
            dispatch(setIsOpenEditWorkerInfoAction(false));
        };
    }

    function changeDrivingLicenses() {
        return async (driving_license_categories: string[]) => {
            if (worker) {
                await dispatch(editWorkerInfoThunk(worker.id, { driving_license_categories }));
                dispatch(getWorkerThunk(worker.id));
            }
            dispatch(setIsOpenEditWorkerInfoAction(false));
        };
    }

    function changeAddress() {
        return async (address: string) => {
            if (worker) {
                worker.addresses
                    ? await dispatch(editWorkerAddressThunk(worker.id, { address, addressId: worker.addresses[0].id }))
                    : await dispatch(addWorkerAddressThunk(worker.id, { address }));
                dispatch(getWorkerThunk(worker.id));
            }
            dispatch(setIsOpenEditWorkerInfoAction(false));
        };
    }

    function changeDescription() {
        return async (description: string) => {
            if (worker) {
                await dispatch(editWorkerDescriptionThunk(worker.id, { description }));
                dispatch(getWorkerThunk(worker.id));
            }
            dispatch(setIsOpenEditWorkerInfoAction(false));
        };
    }

    function changeExperience() {
        return async (experience: string) => {
            if (worker) {
                await dispatch(editWorkerInfoThunk(worker.id, { experience: +experience }));
                dispatch(getWorkerThunk(worker.id));
            }
            dispatch(setIsOpenEditWorkerInfoAction(false));
        };
    }

    function changeEducation() {
        return async (education: IEducation) => {
            if (worker) {
                await dispatch(editWorkerEducationThunk(worker.id, education));
                dispatch(getWorkerThunk(worker.id));
            }
            dispatch(setIsOpenEditWorkerInfoAction(false));
        };
    }

    function getDrivingCategoriesOptions() {
        return (
            worker &&
            worker.driving_license_categories.map((license) => {
                return {
                    value: license,
                    label: license,
                } as IOption<string>;
            })
        );
    }

    function openDialog(
        label: string,
        props: { placeholder?: string; defaultValue?: string | IOption<string>[] | null; educationProps?: IEducation },
        func: (value: string) => void,
    ) {
        setDialogLabel(label);
        setDialogProps(props);
        setDialogFunction(func);
        dispatch(setIsOpenEditWorkerInfoAction(true));
    }

    return (
        worker && (
            <div className="information">
                <Accordion className="information-accordion">
                    <AccordionSummary
                        className="information-accordion-summary"
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <div className="information-title">Вся информация</div>
                    </AccordionSummary>
                    <AccordionDetails className="information-accordion-details">
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Дата рождения
                            </Typography>
                            <Typography>{worker.birthdate || '-'}</Typography>
                        </div>
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Пол
                            </Typography>
                            <Typography>{worker.gender ? genderWorkerMock[worker.gender] : 'Не указано'}</Typography>
                        </div>
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                E-mail
                            </Typography>
                            <Typography mr={2}>{worker.email || '-'}</Typography>
                            <DriveFileRenameOutlineOutlinedIcon
                                className="edit-icon"
                                color="primary"
                                fontSize="small"
                                onClick={() =>
                                    openDialog(
                                        'Изменение почты',
                                        { placeholder: 'Email', defaultValue: worker.email },
                                        changeEmail,
                                    )
                                }
                            />
                        </div>
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Адрес
                            </Typography>
                            <Typography mr={2}>{worker.addresses ? worker.addresses[0].address : '-'}</Typography>
                            <DriveFileRenameOutlineOutlinedIcon
                                className="edit-icon"
                                color="primary"
                                fontSize="small"
                                onClick={() =>
                                    openDialog(
                                        'Изменение адреса',
                                        {
                                            defaultValue: worker.addresses ? worker.addresses[0].address : '',
                                            placeholder: 'Адрес',
                                        },
                                        changeAddress,
                                    )
                                }
                            />
                        </div>
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Категория прав
                            </Typography>
                            <Typography mr={2}>
                                {worker.driving_license_categories.length
                                    ? worker.driving_license_categories.join(', ')
                                    : '-'}
                            </Typography>
                            <DriveFileRenameOutlineOutlinedIcon
                                className="edit-icon"
                                color="primary"
                                fontSize="small"
                                onClick={() =>
                                    openDialog(
                                        'Изменение категории прав',
                                        { placeholder: 'Категория прав', defaultValue: getDrivingCategoriesOptions() },
                                        changeDrivingLicenses,
                                    )
                                }
                            />
                        </div>
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Личный транспорт
                            </Typography>
                            <IOSSwitch
                                defaultChecked={worker.is_has_personal_car}
                                onChange={changeIsHasPersonalCar}
                            />
                        </div>
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Стаж работы
                            </Typography>
                            <Typography mr={2}>
                                {worker.experience ? workerExperience[worker.experience] : '-'}
                            </Typography>
                            <DriveFileRenameOutlineOutlinedIcon
                                className="edit-icon"
                                color="primary"
                                fontSize="small"
                                onClick={() =>
                                    openDialog(
                                        'Изменение стажа работы',
                                        { defaultValue: worker?.experience ? worker.experience.toString() : '' },
                                        changeExperience,
                                    )
                                }
                            />
                        </div>
                        <div className="worker-info">
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Образование
                            </Typography>
                            <Typography mr={2}>
                                {worker.education
                                    ? `${worker.education.main_education.institution_name}, ${worker.education.main_education.education_type}, ${worker.education.main_education.educational_program}`
                                    : '-'}
                            </Typography>
                            <DriveFileRenameOutlineOutlinedIcon
                                className="edit-icon"
                                color="primary"
                                fontSize="small"
                                onClick={() =>
                                    openDialog(
                                        'Изменение образования',
                                        { educationProps: worker.education },
                                        changeEducation,
                                    )
                                }
                            />
                        </div>
                        {worker.education?.additional_education && <Divider sx={{ mb: 2, ml: 25 }} />}
                        {worker.education?.additional_education &&
                            worker.education.additional_education.map((education) => (
                                <Typography
                                    ml={25}
                                    key={Math.random()}
                                    variant="inherit"
                                    className="worker-subtitle"
                                >
                                    {education.title}
                                </Typography>
                            ))}
                        <div
                            className="worker-info"
                            style={{ flexWrap: 'nowrap' }}
                        >
                            <Typography
                                variant="inherit"
                                className="worker-subtitle"
                            >
                                Описание
                            </Typography>
                            <pre>{worker.description || '-'}</pre>
                            <DriveFileRenameOutlineOutlinedIcon
                                className="edit-icon"
                                color="primary"
                                fontSize="small"
                                onClick={() =>
                                    openDialog(
                                        'Редактирование описания',
                                        {
                                            defaultValue: worker.description || '',
                                            placeholder: 'Описание',
                                        },
                                        changeDescription,
                                    )
                                }
                            />
                        </div>
                        <Verify />
                    </AccordionDetails>
                </Accordion>
                <EditInfo
                    label={dialogLabel}
                    props={dialogProps}
                    editInfo={dialogFunction}
                />
            </div>
        )
    );
}

export default Information;
