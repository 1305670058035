import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IPartner } from '../interfaces/partners';

export default class PartnersAPI {
    getPartners(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/partners${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<IPartner[]>;
    }
}
