import { Box, styled } from '@mui/material';

export const ButtonsWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    marginTop: '30px',
});

export const CloseIconWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    marginTop: '10px',
});
