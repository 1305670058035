import { AppDispatch } from 'store/store';

import { ratiosApi } from '../../api';
import { ECategoriesRatiosFilterActions } from '../../enums/actions.enum';
import {
    ICategoryRatiosFilter,
    ISetCategoriesRatiosFilter,
    ISetSearchCategoriesRatios,
    ISetSelectedCategoriesRatios,
    ISetSelectedCategoriesRatiosForWorkers,
} from '../../interfaces/categoriesRatiosFilter';
import { getCategoriesRFWithChecked } from '../../utils/helpers/getCategoriesRFWithChecked';

export const setCategoriesRatiosFilterAction = (
    payload: ICategoryRatiosFilter[] | null,
): ISetCategoriesRatiosFilter => ({
    type: ECategoriesRatiosFilterActions.SetCategoriesFilter,
    payload,
});

/* Пояснения к неймингу (CRF) смотри в src/store/selectors/categoriesRatiosFilterSelectors.ts */
export const setSelectedCRFAction = (payload: ICategoryRatiosFilter[] | null): ISetSelectedCategoriesRatios => ({
    type: ECategoriesRatiosFilterActions.SetSelectedCategories,
    payload,
});

export const setSelectedCRFForWorkersAction = (
    payload: ICategoryRatiosFilter[] | null,
): ISetSelectedCategoriesRatiosForWorkers => ({
    type: ECategoriesRatiosFilterActions.SetSelectedCategoriesForWorkers,
    payload,
});

export const setSearchCRFAction = (payload: string | null): ISetSearchCategoriesRatios => ({
    type: ECategoriesRatiosFilterActions.SetSearch,
    payload,
});

export const getCategoriesRatiosFilterThunk = (queries?: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await ratiosApi.getCategories(queries);
        dispatch(setCategoriesRatiosFilterAction(getCategoriesRFWithChecked(data)));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
