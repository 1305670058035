import { styled } from '@mui/material/styles';

import { TUserType } from '../../interfaces/chats';
import { UserTypeMock } from '../../mockData/map';

interface IProps {
    user_type: TUserType;
}

const colors = {
    client: '#87A597',
    worker: '#A58787',
    backoffice: 'black',
};

const Span = styled('span')(({ user_type }: IProps) => ({
    color: colors[user_type],
}));

export default function ChatUserType(props: IProps) {
    const { user_type } = props;
    return <Span {...props}>{UserTypeMock[user_type]}</Span>;
}
