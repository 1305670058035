import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { setIsOpenBindWorkerMoySkladAction } from '../../../../../store/actions/create/bindMoySklad';
import { bindWorkerMoySkaldThunk, setWorkerMoySkladAction } from '../../../../../store/actions/create/createMoySklad';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    formValuesBindMoySkladSelector,
    searchResponseMoySkladSelector,
} from '../../../../../store/selectors/create/bindMoySklad';

import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();
    const formValues = useSelector(formValuesBindMoySkladSelector);
    const responseText = useSelector(searchResponseMoySkladSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);

    async function submitFormHandler() {
        if (formValues) {
            const bindFormValue = await dispatch(bindWorkerMoySkaldThunk(formValues));
            dispatch(setWorkerMoySkladAction(bindFormValue));
            dispatch(setIsOpenBindWorkerMoySkladAction(false));
        }
    }

    useEffect(() => {
        setIsBtnDisabled(!responseText);
    }, [responseText]);

    return (
        <div className="buttons-wrapper">
            <Button
                variant="contained"
                onClick={submitFormHandler}
                disabled={isBtnDisabled}
            >
                Привязать
            </Button>
            <Button
                variant="outlined"
                onClick={() => dispatch(setIsOpenBindWorkerMoySkladAction(false))}
            >
                Отмена
            </Button>
        </div>
    );
}

export default FooterForm;
