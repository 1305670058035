import { AppDispatch } from 'store/store';

import { issuesApi, pricesApi } from '../../../api';
import { EEditIssuePricesActions } from '../../../enums/actions.enum';
import {
    IFormValuesEditIssuePrices,
    IPutIssuePricesReq,
    ISetSearchPricesEditIssuePricesAction,
    ISetDialogTitleEditIssuePricesAction,
    ISetFormValuesEditIssuePricesAction,
    ISetIsOpenEditIssuePricesAction,
    ISetIssueIdEditIssuePricesAction,
    ISetPriceRecipientEditIssuePricesAction,
    ISetAllPricesEditIssuePricesAction,
    ISetStaticPricesEditIssuePricesAction,
    ISetIsOpenEditIssuePricesClientAction,
    ISetErrorsEditIssuePricesAction,
    IError,
} from '../../../interfaces/edit/editIssuePrices';
import { IIssue, TPriceRecipient } from '../../../interfaces/issues';
import { IPrice, IPricesFilters } from '../../../interfaces/prices';
import { getQueryParamsAsString, TArrayParams } from '../../../utils/helpers/getQueryParamsAsString';
import { setIssueAction } from '../issues';

export const setIsOpenEditIssuePricesAction = (payload: boolean): ISetIsOpenEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetIsOpen,
    payload,
});

export const setIsOpenEditIssuePricesClientAction = (payload: boolean): ISetIsOpenEditIssuePricesClientAction => ({
    type: EEditIssuePricesActions.SetIsOpenClient,
    payload,
});

export const setIssueIdEditIssuePricesAction = (payload: IIssue['id'] | null): ISetIssueIdEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetIssueId,
    payload,
});

export const setStaticPricesEditIssuePricesAction = (
    payload: IPutIssuePricesReq[] | null,
): ISetStaticPricesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetStaticPrices,
    payload,
});

export const setFormValuesEditIssuePricesAction = (
    payload: IFormValuesEditIssuePrices | null,
): ISetFormValuesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetFormValues,
    payload,
});

export const setErrorsEditIssuePricesAction = (payload: IError[] | null): ISetErrorsEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetErrors,
    payload,
});

export const setSearchPricesEditIssuePricesAction = (
    payload: IPrice[] | null,
): ISetSearchPricesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetSearchPrices,
    payload,
});

export const setAllPricesEditIssuePricesAction = (payload: IPrice[] | null): ISetAllPricesEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetAllPrices,
    payload,
});

export const setPriceRecipientEditIssuePricesAction = (
    payload: TPriceRecipient | null,
): ISetPriceRecipientEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetPriceRecipient,
    payload,
});

export const setDialogTitleEditIssuePricesAction = (payload: string | null): ISetDialogTitleEditIssuePricesAction => ({
    type: EEditIssuePricesActions.SetDialogTitle,
    payload,
});

export const editIssuePricesThunk = (issueId: string, newPrices: IPutIssuePricesReq[]) => {
    return async (dispatch: AppDispatch) => {
        try {
            const { data } = await issuesApi.putIssuePrices(issueId, newPrices);

            dispatch(setIssueAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

export const editIssuePricesSolvedThunk = (issueId: string, newPrices: IPutIssuePricesReq[]) => {
    return async (dispatch: AppDispatch) => {
        try {
            const { data } = await issuesApi.putIssuePricesSolved(issueId, newPrices);

            dispatch(setIssueAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};

interface IGetPricesEditIssuePricesThunkParams {
    params?: IPricesFilters | null;
}

export const getAllPricesEditIssuePricesThunk = ({ params }: IGetPricesEditIssuePricesThunkParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [{ with_additional: true }];
            if (params) {
                const { partner_id, region, feature_type, search, category_id__in } = params;

                if (search) {
                    queriesObj.push({ search });
                }
                if (feature_type) {
                    queriesObj.push({ feature_type });
                }
                if (partner_id) {
                    queriesObj.push({ partner_id });
                }
                if (region) {
                    queriesObj.push({ region });
                }
                if (category_id__in) {
                    queriesObj.push({ category_id__in });
                }
            }

            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await pricesApi.getPricesWithOutPagination(queries);
            dispatch(setAllPricesEditIssuePricesAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
