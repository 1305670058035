import { EBindWorkerMoySkladActions } from '../../../enums/actions.enum';
import { IBindWorkerMoySkladState, TBindWorkerMoySkladActions } from '../../../interfaces/create/bindMoySkald';

const initState: IBindWorkerMoySkladState = {
    isOpen: false,
    searchResponse: null,
    formValues: null,
};

const initialState = { ...initState };

export const bindWorkerMoySkladReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TBindWorkerMoySkladActions,
): IBindWorkerMoySkladState => {
    switch (action.type) {
        case EBindWorkerMoySkladActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
                formValues: null,
                searchResponse: null,
            };
        case EBindWorkerMoySkladActions.SetSearchResponse:
            return {
                ...state,
                searchResponse: action.payload,
            };
        case EBindWorkerMoySkladActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload,
            };
        default:
            return state;
    }
};
