import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, Typography, Link } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import IssueCreated from './IssueCreated';
import PartnerForm from './PartnerForm';

import { ECreateIssuePartnerSteps } from '../../enums/createIssuePartnerForm.enum';
import { EIssueHelpType } from '../../enums/issues.enum';
import { IPricesFilters } from '../../interfaces/prices';
import purpleLogoPNG from '../../static/images/purpleLogo.png';
import { getBrandsThunk } from '../../store/actions/brands';
import { getPricesThunk } from '../../store/actions/prices';
import { logout } from '../../store/actions/profile';
import { useAppDispatch } from '../../store/hooks';
import { formValuesSelector, stepSelector } from '../../store/selectors/create/createIssuePartnerSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';
import './PartnerPageStyles.scss';

/* Необходимы т.к. в форме партнёра доступны только prices с типом установка,
	из with_additional строится список дополнительных услуг с.м. pricesIsAdditionalOptionsSelector  */
const defaultPricesParams: IPricesFilters = {
    help_type__in: EIssueHelpType.Installation,
    with_additional: true,
    with_paginate: false,
};

function PartnerPage() {
    const dispatch = useAppDispatch();

    const step = useSelector(stepSelector);
    const formValues = useSelector(formValuesSelector);

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getPricesThunk({ filters: defaultPricesParams }));
                await dispatch(
                    getBrandsThunk(
                        getQueryParamsAsString([
                            {
                                /* 	TODO Warning!!! Mock данные чтоб, получить список брендов, бренд нужен при сохранении формы
									напоминать бэку выпилить при каждой возможности */
                                search: '-',
                            },
                            { order_by: 'name' },
                            { page: 1 },
                            { page_size: 10 },
                        ]),
                    ),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, []);

    useEffect(() => {
        async function handle() {
            if (!formValues?.address) return;
            const { address, addressFiasId } = formValues;
            try {
                await dispatch(
                    getPricesThunk({
                        filters: {
                            ...defaultPricesParams,
                            feature_type: addressFiasId ? 'region_fias_id' : 'string',
                            region: addressFiasId || address,
                        },
                    }),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [formValues?.address, formValues?.addressFiasId]);

    return (
        <Box className="main-partner">
            <Box className="partner-content">
                <Box className="parner-header">
                    <div className="header-top">
                        <img
                            src={purpleLogoPNG}
                            alt="logo"
                        />
                        <Button
                            color="secondary"
                            startIcon={<LogoutIcon />}
                            onClick={() => dispatch(logout())}
                        >
                            Выйти из профиля
                        </Button>
                    </div>
                    <Typography
                        variant="h3"
                        fontWeight={600}
                        mt={5}
                    >
                        Установка техники
                    </Typography>
                    <div>
                        <Link
                            target="_blank"
                            href="https://saved-files.storage.yandexcloud.net/saved-files/9dce486f-43e0-4ae8-9d48-5bb887ac6f20"
                        >
                            Инструкция
                        </Link>
                    </div>
                </Box>
                {(() => {
                    switch (step) {
                        case ECreateIssuePartnerSteps.Success:
                            return <IssueCreated />;
                        default:
                            return <PartnerForm />;
                    }
                })()}
            </Box>
        </Box>
    );
}

export default memo(PartnerPage);
