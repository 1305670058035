export interface IDateFormatterOptions {
    today?: boolean;
    isTime?: boolean;
    isDateTime?: boolean;
}

const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const dateFormatter = (
    date: string | Date | null,
    options?: IDateFormatterOptions,
    intlOptions: Intl.DateTimeFormatOptions = {
        timeZone: `${currentTimeZone || 'Europe/Moscow'}`,
        month: 'long',
        year: 'numeric',
        day: 'numeric',
        formatMatcher: 'basic',
    },
) => {
    if (!date) return '';
    const objDate = new Date(Date.parse(date as string));

    // eslint-disable-next-line no-param-reassign
    if (!intlOptions.timeZone) intlOptions.timeZone = 'Europe/Moscow';
    if (!options) return Intl.DateTimeFormat('ru', intlOptions).format(objDate);
    const today = options && options.today;

    const getToday = () => {
        const day = objDate.getDate();
        const now = new Date(Date.now());
        const difference = now.getDate() - day;
        switch (true) {
            case objDate.getMonth() !== now.getMonth():
                return Intl.DateTimeFormat('ru', intlOptions).format(objDate);
            case difference === 0:
                return `Сегодня, ${Intl.DateTimeFormat('ru', { hour: 'numeric', minute: 'numeric' }).format(objDate)}`;
            case difference === -1:
                return `Завтра, ${Intl.DateTimeFormat('ru', { hour: 'numeric', minute: 'numeric' }).format(objDate)}`;
            case difference === 1:
                return `Вчера, ${Intl.DateTimeFormat('ru', { hour: 'numeric', minute: 'numeric' }).format(objDate)}`;
            default:
                return Intl.DateTimeFormat('ru', intlOptions).format(objDate);
        }
    };

    const getTime = () => {
        return Intl.DateTimeFormat('ru', {
            timeZone: `${currentTimeZone || 'Europe/Moscow'}`,
            hour: 'numeric',
            minute: 'numeric',
        }).format(objDate);
    };

    if (today) {
        return getToday();
    }

    if (options?.isTime) {
        return getTime();
    }

    if (options?.isDateTime) {
        return `${Intl.DateTimeFormat('ru', intlOptions).format(objDate)} ${getTime()}`;
    }
    return Intl.DateTimeFormat('ru', intlOptions).format(objDate);
};
