import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editWorkerSpecializations }: RootState) => editWorkerSpecializations;

export const isOpenEditWorkerSpecializationsSelector = createSelector(state, ({ isOpen }) => isOpen);
export const workerIdEditWorkerSpecializationsSelector = createSelector(state, ({ workerId }) => workerId);
export const formValuesEditWorkerSpecializationsSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsEditWorkerSpecializationsSelector = createSelector(state, ({ errors }) => errors);
