import { ECommissionsType } from '../enums/prices.enum';
import { EPricesServicesTabs } from '../enums/pricesServices.enum';
import { IPricesTableHead } from '../interfaces/prices';

export const pricesServicesTabsMock = {
    [EPricesServicesTabs.Description]: 'Описание',
    [EPricesServicesTabs.BasicServices]: 'Основные услуги',
    [EPricesServicesTabs.AdditionalServices]: 'Дополнительные услуги',
};

export const pricesServicesTableHeadMock: IPricesTableHead[] = [
    {
        title: 'Название',
        padding: 'normal',
    },
    {
        title: '',
        padding: 'normal',
    },
];

export const pricesTableHeadMock: IPricesTableHead[] = [
    {
        title: 'Название',
        padding: 'normal',
    },
    {
        title: 'Цена',
        padding: 'normal',
    },
    {
        title: 'Категория техники',
        padding: 'normal',
    },
    {
        title: 'Компания',
        padding: 'normal',
    },
    {
        title: 'Отображается в виджете',
        padding: 'normal',
    },
    {
        title: '',
        padding: 'normal',
    },
];

export const additionalPricesTableHeadMock: IPricesTableHead[] = [
    {
        title: 'Название',
        padding: 'normal',
    },
    {
        title: 'Цена',
        padding: 'normal',
    },
    {
        title: 'Категория техники',
        padding: 'normal',
    },
    {
        title: '',
        padding: 'normal',
    },
];

export const pricesServicesCommissionsTypeMock = {
    [ECommissionsType.Percentage]: '%',
    [ECommissionsType.Fixation]: '₽',
};
