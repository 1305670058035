import { EDayOfWeek } from '../enums/schedule.enum';

export const week: EDayOfWeek[] = [
    EDayOfWeek.MONDAY,
    EDayOfWeek.TUESDAY,
    EDayOfWeek.WEDNESDAY,
    EDayOfWeek.THURSDAY,
    EDayOfWeek.FRIDAY,
    EDayOfWeek.SATURDAY,
    EDayOfWeek.SUNDAY,
];

export const EDayOfWeekRu = {
    [EDayOfWeek.MONDAY]: 'Понедельник',
    [EDayOfWeek.TUESDAY]: 'Вторник',
    [EDayOfWeek.WEDNESDAY]: 'Среда',
    [EDayOfWeek.THURSDAY]: 'Четверг',
    [EDayOfWeek.FRIDAY]: 'Пятница',
    [EDayOfWeek.SATURDAY]: 'Суббота',
    [EDayOfWeek.SUNDAY]: 'Воскресенье',
} as { [key in EDayOfWeek]: string };

export const EDayOfWeekAbbreviatedRu = {
    [EDayOfWeek.MONDAY]: 'ПН',
    [EDayOfWeek.TUESDAY]: 'ВТ',
    [EDayOfWeek.WEDNESDAY]: 'СР',
    [EDayOfWeek.THURSDAY]: 'ЧТ',
    [EDayOfWeek.FRIDAY]: 'ПТ',
    [EDayOfWeek.SATURDAY]: 'СБ',
    [EDayOfWeek.SUNDAY]: 'ВС',
} as { [key in EDayOfWeek]: string };
