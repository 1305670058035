/* eslint-disable no-shadow */

export const enum EIndicies {
    Clients = 'clients',
    Workers = 'workers',
    Issues = 'issues',
    BackofficeUsers = 'backoffice-users',
    HomeDevices = 'home_devices',
}

export const enum EIssueFilter {
    Oid = 'oid',
    Client = 'client',
    Address = 'address',
    SellerCode = 'seller_code',
    IssueCode = 'issue_code',
}
