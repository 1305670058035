import { Add } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import { Box, Button, IconButton } from '@mui/material';
import { ChangeEvent } from 'react';

import TextField from '../../../../../../../components/inputs/TextField';
import { IUpdateClientFormValues } from '../../../../../../../interfaces/clients';

interface IProps {
    formValues: IUpdateClientFormValues | null;
    setFormValues: (formValues: IUpdateClientFormValues | null) => void;
}
function AdditionalPhonesForm({ formValues, setFormValues }: IProps) {
    const changeAdditionalPhoneHandler = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        if (!formValues?.phones) return;

        const newPhones = formValues.phones.map((phone, position) => {
            if (position === index) {
                return { phone_number: event.target.value };
            }
            return phone;
        });

        setFormValues({ ...(formValues || {}), phones: [...newPhones] });
    };

    const addAdditionalPhoneHandler = () => {
        if (!formValues || !formValues?.phones) return;
        setFormValues({ ...(formValues || {}), phones: [...formValues.phones, { phone_number: '' }] });
    };

    const deleteAdditionalPhoneHandler = (index: number) => {
        if (!formValues || !formValues?.phones) return;
        const newPhones = formValues.phones.filter((phone, position) => position !== index);
        setFormValues({ ...(formValues || {}), phones: [...newPhones] });
    };

    return (
        <>
            {!!formValues?.phones?.length &&
                formValues?.phones.map((phone, index) => (
                    <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        gap={2}
                    >
                        <TextField
                            name="additional_phone"
                            labelText="Добавочный номер телефона"
                            fullWidth
                            params={{ margin: 'normal' }}
                            value={phone.phone_number || ''}
                            onChange={(event) => {
                                changeAdditionalPhoneHandler(event, index);
                            }}
                        />
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            sx={{ width: '45px', height: '45px' }}
                            onClick={() => deleteAdditionalPhoneHandler(index)}
                        >
                            <Delete />
                        </IconButton>
                    </Box>
                ))}
            {formValues?.phones?.length !== 3 && (
                <Button
                    className="add-button"
                    onClick={addAdditionalPhoneHandler}
                >
                    <Add /> Добавить номер
                </Button>
            )}
        </>
    );
}

export default AdditionalPhonesForm;
