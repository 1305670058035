import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../components/inputs/TextField';
import { EIssueHelpType } from '../../../enums/issues.enum';
import { ICategoryRatiosFilter } from '../../../interfaces/categoriesRatiosFilter';
import { setFiltersCMAction, setSelectedWorkersCMAction } from '../../../store/actions/candidatesModal';
import { setCategoriesRatiosFilterAction, setSelectedCRFAction } from '../../../store/actions/categoriesRatiosFilter';
import { useAppDispatch } from '../../../store/hooks';
import { filtersCMSelector, selectedWorkersCMSelector } from '../../../store/selectors/candidatesModalSelectors';
import { categoriesCRFSelector, selectedCRFSelector } from '../../../store/selectors/categoriesRatiosFilterSelectors';
import { workersSelector } from '../../../store/selectors/workersSelectors';
import { getCategoriesRFWithChecked } from '../../../utils/helpers/getCategoriesRFWithChecked';
import CategoriesFilter from '../../CategoriesFilter';
import CategoriesRatiosFilter from '../../CategoriesRatiosFilter';
import HelpTypeFilter from '../../HelpTypeFilter';
import './Filters.scss';

function Filters() {
    const dispatch = useAppDispatch();

    const categories = useSelector(categoriesCRFSelector);
    const selectedCategories = useSelector(selectedCRFSelector);
    const workers = useSelector(workersSelector);
    const selectedWorkers = useSelector(selectedWorkersCMSelector);
    const filters = useSelector(filtersCMSelector);

    useEffect(() => {
        const newFilters = filters ? { ...filters } : {};

        if (selectedCategories?.length) {
            newFilters.area_id__in = selectedCategories.map(({ id }) => id);
        } else {
            newFilters.area_id__in = null;
        }

        dispatch(setFiltersCMAction(newFilters));
    }, [selectedCategories]);

    function onChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
        const newFilters = filters ? { ...filters, search: e.target.value } : { search: e.target.value };
        dispatch(setFiltersCMAction(newFilters));
    }

    function onChangeRatiosHandler(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        const newFilters = filters ? { ...filters, area_id__in: categoriesIds } : { area_id__in: categoriesIds };
        dispatch(setFiltersCMAction(newFilters));
    }

    function selectCRFIdsForWorkers(selectCRFIds: string[] | null) {
        const newFilters = filters
            ? { ...filters, specialization__category_id__in: selectCRFIds }
            : { specialization__category_id__in: selectCRFIds };
        dispatch(setFiltersCMAction(newFilters));
    }

    function selectHelpTypeForWorkers(helpType: EIssueHelpType[] | null) {
        const newFilters = filters
            ? { ...filters, specialization__help_type__in: helpType }
            : { specialization__help_type__in: helpType };
        dispatch(setFiltersCMAction(newFilters));
    }

    function resetFiltersHandler() {
        dispatch(setSelectedCRFAction([]));
        dispatch(setFiltersCMAction(null));
        categories && dispatch(setCategoriesRatiosFilterAction(getCategoriesRFWithChecked(categories)));
    }

    const onSelectAllWorkers = () => {
        if (!workers) return;
        if (selectedWorkers?.length === workers.length) {
            dispatch(setSelectedWorkersCMAction(null));
        } else {
            const newSelectedWorkers = workers.map((worker) => {
                return { ...worker, isCandidate: true };
            });
            dispatch(setSelectedWorkersCMAction(newSelectedWorkers));
        }
    };

    return (
        <div className="candidates-filters">
            <TextField
                name="search"
                onChange={onChangeSearch}
                value={filters?.search}
                placeholder="Поиск по фамилии, имени, id"
                fullWidth
                sxStyle={{ mb: 3 }}
            />
            <CategoriesRatiosFilter onChange={onChangeRatiosHandler} />
            <CategoriesFilter
                selectedCRFIdsForWorkers={filters?.specialization__category_id__in || null}
                onChange={selectCRFIdsForWorkers}
            />
            <HelpTypeFilter
                selectedHelpType={filters?.specialization__help_type__in || null}
                onChange={selectHelpTypeForWorkers}
            />
            <Box sx={{ ml: 'auto' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            onChange={onSelectAllWorkers}
                            checked={selectedWorkers?.length === workers?.length}
                        />
                    }
                    label="Выбрать всех"
                />
                <Button
                    variant="text"
                    onClick={resetFiltersHandler}
                >
                    Сбросить фильтр
                </Button>
            </Box>
        </div>
    );
}

export default Filters;
