import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IGroup, TGetGroupsResp } from '../interfaces/groups';
import { IGroupUpdateData } from '../store/actions/groups';

export default class GroupsAPI {
    getGroups() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/groups`,
            isAuthorization: true,
        }) as AxiosPromise<TGetGroupsResp>;
    }

    getGroup(groupId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/groups/${groupId}`,
            isAuthorization: true,
        }) as AxiosPromise<IGroup>;
    }

    createGroup(groupName: string, workersIds: string[]) {
        return axiosFetch({
            method: 'post',
            data: { name: groupName, worker_ids: workersIds },
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/groups`,
            isAuthorization: true,
        }) as AxiosPromise<TGetGroupsResp>;
    }

    deleteGroup(groupId: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/groups/${groupId}`,
            isAuthorization: true,
        }) as AxiosPromise<string>;
    }

    updateGroup(groupId: string, data: IGroupUpdateData) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/groups/${groupId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<IGroup>;
    }
}
