import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import MainRoutes from './Main';
import PartnerRoutes from './Partner';

import { ERoutes } from '../enums/routes.enum';
import SignInPage from '../pages/signin';
import { getProfileThunk } from '../store/actions/profile';
import { getRolesThunk } from '../store/actions/roles';
import { useAppDispatch } from '../store/hooks';
import { RootState } from '../store/rootReducer';
import { companyProfileSelector, profileSelector } from '../store/selectors/profileSelectors';
import CentrifugeConnector from '../utils/centrifugeConnector';
import CentrifugeDistributor from '../utils/centrifugeDistributor';

function AppRoutes() {
    const dispatch = useAppDispatch();

    const isAuth = useSelector((state: RootState) => state.profile.isAuth);
    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);

    useEffect(() => {
        const handler = async () => {
            await dispatch(getProfileThunk());
            if (isAuth) {
                await dispatch(getRolesThunk());
            }
        };
        handler();
    }, [isAuth]);

    // TODO прикрутить lazy loading установить <Loader/> пока грузятся модули приложения
    if (isAuth === null) return null;

    if (isAuth === false) {
        return (
            <Routes>
                <Route
                    path={`${ERoutes.SignIn}/`}
                    element={<SignInPage />}
                />
                ;
                <Route
                    path="*"
                    element={
                        <Navigate
                            replace
                            to={`${ERoutes.SignIn}/`}
                        />
                    }
                />
            </Routes>
        );
    }

    switch (true) {
        case companyProfile?.is_partner:
            return <PartnerRoutes />;
        case !!profile:
            return (
                <>
                    <MainRoutes />
                    <CentrifugeConnector />
                    <CentrifugeDistributor />
                </>
            );
        default:
            return <>Loading...</>;
    }
}

export default AppRoutes;
