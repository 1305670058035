import ChatItem from 'components/Chat/ChatItem';
import { getChatType, getUserName } from 'components/Chat/tools';
import InfiniteLoaderWrapper, { ItemProp } from 'components/InfiniteLoaderWrapper';
import Loader from 'components/Loader';
import { chatResetAction, getChatDetailThunk } from 'store/actions/chat';
import { getChatsInfiniteThunk, resetChatsInfiniteActions } from 'store/actions/chatsInfinite';
import { ChatSize } from 'store/constants';
import { useAppDispatch } from 'store/hooks';
import { chatDetailSelector } from 'store/selectors/chatSelectors';
import {
    chatsHasNextPageSelector,
    chatsIsLoadingSelector,
    chatsIsNextPageLoadingSelector,
    chatsSelector,
} from 'store/selectors/chatsInfiniteSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import { IChat, IChatsFilters } from 'interfaces/chats';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

function ChatItemWrapper({ el, index, onClick }: ItemProp<IChat>) {
    function click() {
        if (onClick) {
            onClick(index);
        }
    }

    const chat = useSelector(chatDetailSelector);

    return (
        <ChatItem
            datetime={el.last_message?.created_at || null}
            text={getChatType(el)}
            message_user={getUserName(el.last_message?.sender_user)}
            message_text={el.last_message?.text || null}
            unseen_count={el.unseen_count}
            onClick={click}
            selected={chat?.chat.id === el.chat.id}
        />
    );
}

function IssueChatItemWrapper({ el, index, onClick }: ItemProp<IChat>) {
    function click() {
        if (onClick) {
            onClick(index);
        }
    }

    const chat = useSelector(chatDetailSelector);

    const getIssueChatType = (type: string) => {
        switch (type) {
            case 'worker':
                return 'Мастер.';
            case 'client':
                return 'Клиент.';
            case 'issue_related':
                return 'Клиент-Мастер';
            default:
                return '';
        }
    };

    return (
        <ChatItem
            datetime={el.last_message?.created_at || null}
            text={`
				${getIssueChatType(el?.chat.chat_type!) || getIssueChatType(el?.participants?.[0]?.user_type!)}
				${
                    getIssueChatType(el?.chat.chat_type!)
                        ? ''
                        : `${el?.participants[0]?.last_name || ''} ${el?.participants?.[0]?.first_name || ''}`
                }
		  	`}
            message_user={getUserName(el.last_message?.sender_user)}
            message_text={el.last_message?.text || null}
            unseen_count={el.unseen_count}
            onClick={click}
            selected={chat?.chat.id === el.chat.id}
        />
    );
}

interface IChatListProps {
    filters?: Partial<IChatsFilters> | null;
    isIssue?: boolean;
    onChatClick?: (chat: IChat) => void;
}

export default function ChatsInfiniteModule({ filters, isIssue, onChatClick }: IChatListProps) {
    const dispatch = useAppDispatch();

    const chats = useSelector(chatsSelector);
    const isLoading = useSelector(chatsIsLoadingSelector);
    const hasNextPage = useSelector(chatsHasNextPageSelector);
    const isNextPageLoading = useSelector(chatsIsNextPageLoadingSelector);

    useEffect(() => {
        dispatch(resetChatsInfiniteActions());
        return () => {
            dispatch(resetChatsInfiniteActions());
        };
    }, []);

    async function getChats(startIndex: number) {
        try {
            await dispatch(
                getChatsInfiniteThunk({
                    filters,
                    sortOptions: new Map().set('last_message_at', 'desc').set('created_at', 'desc'),
                    page: Math.floor(startIndex / ChatSize) + 1,
                    size: ChatSize,
                }),
            );
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function onClick(index: number) {
        if (!chats || !chats[index]) {
            return;
        }
        onChatClick?.(chats[index]);

        dispatch(chatResetAction());
        try {
            await dispatch(getChatDetailThunk(chats[index].chat.id));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <div style={{ flex: 1 }}>
            {isLoading ? (
                <Loader />
            ) : (
                <InfiniteLoaderWrapper
                    hasNextPage={hasNextPage}
                    isNextPageLoading={isNextPageLoading}
                    items={chats || []}
                    loadNextPage={getChats}
                    Item={isIssue ? IssueChatItemWrapper : ChatItemWrapper}
                    itemSize={72}
                    onClickItem={onClick}
                />
            )}
        </div>
    );
}
