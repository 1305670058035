import { EWorkersActions } from '../../enums/actions.enum';
import { IWorkersState, TWorkersActions } from '../../interfaces/workers';

const initState: IWorkersState = {
    workers: null,
    search: null,
    selectedWorkersIds: null,
    isWorkersTableLoader: false,
    pagination: { page: 0, size: 50, total: 0 },
    filters: null,
    sortOptions: new Map(),
    count: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const workersReducer = (state = initialState, action: TWorkersActions): IWorkersState => {
    switch (action.type) {
        case EWorkersActions.WorkersReset:
            return initState;
        case EWorkersActions.SetWorkers:
            return {
                ...state,
                workers: action.payload,
            };
        case EWorkersActions.SetSearch:
            return {
                ...state,
                search: action.payload,
            };
        case EWorkersActions.SetSelectedWorkersIds:
            return {
                ...state,
                selectedWorkersIds: action.payload,
            };
        case EWorkersActions.SetIsWorkersTableLoader:
            return {
                ...state,
                isWorkersTableLoader: action.payload,
            };
        case EWorkersActions.SetWorkersPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        case EWorkersActions.SetWorkersFilters:
            return {
                ...state,
                filters: action.payload,
            };
        case EWorkersActions.SetWorkersSortOptions:
            return {
                ...state,
                sortOptions: new Map(state.sortOptions).set(action.payload.sortField, action.payload.direction),
            };
        case EWorkersActions.SetWorkersCount:
            return {
                ...state,
                count: action.payload,
            };
        default:
            return state;
    }
};
