import { servicePriceListsApi, servicesApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import { fetchServicesComplex } from 'pages/price-lists/store';
import { FootNote } from 'pages/services/components/footnote';
import { useAppDispatch } from 'store/hooks';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, TextField, Typography, Checkbox, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { Link, useParams } from 'react-router-dom';

import {
    Asterisk,
    ButtonsContainer,
    Container,
    Field,
    FieldsContainer,
    ModalWrapper,
    SubTitle,
    TitleWrapper,
    CellInputProps,
} from './styles';

const initialState = {
    name: '',
    description: '',
    service_ids: [],
    amount: 0,
    commission: {
        value: 0,
    },
    include_descriptions: [],
    is_active_integration: false,
    availability_type: 'partner',
    external_id: '',
    price_lists: {
        all: true,
        type: 'all',
    },
};

type Option = { label: string; value: string };

export const AddComplexServices = () => {
    const dispatch = useAppDispatch();

    const { priceListId } = useParams();

    const [form, setForm] = useState<any>(initialState);

    const [servicesOptions, setServicesOptions] = useState<Option[]>([]);
    const [descendants, setDescendants] = useState([]);

    const handleCreateServicesComplex = () => {
        servicePriceListsApi
            .createServicesComplex(form)
            .then(() => {
                dispatch(fetchServicesComplex(priceListId));
                enqueueSnackbar('Комплекс услуг добавлен', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    useEffect(() => {
        servicesApi
            .getServices({ size: 50 })
            .then((res) => {
                setServicesOptions(res.data.items.map((item) => ({ label: item.name, value: item.id })));
            })
            .catch(() => {});
    }, []);

    return (
        <MainLayout>
            <Container>
                <TitleWrapper>
                    <Typography
                        fontWeight={700}
                        sx={{ fontSize: '28px' }}
                    >
                        Добавить комплекс услуг
                    </Typography>
                    <Button sx={{ color: '#0040F1' }}>
                        <Link to={`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}`}>
                            Назад
                        </Link>
                    </Button>
                </TitleWrapper>
                <ModalWrapper>
                    <FieldsContainer>
                        <Field>
                            <SubTitle>
                                Услуги<Asterisk>*</Asterisk>
                            </SubTitle>
                            <Autocomplete
                                sx={{ width: '392px' }}
                                multiple
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={servicesOptions ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                )}
                                onChange={(_, value) => {
                                    const services_ids = value.map((item) => item.value);
                                    setForm((prev: any) => {
                                        return {
                                            ...prev,
                                            service_ids: services_ids,
                                        };
                                    });
                                    servicesApi.getDescendants({ services_ids }).then((res) => {
                                        setDescendants(res.data);
                                    });
                                }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Наименование<Asterisk>*</Asterisk>
                            </SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                placeholder="Наименование"
                                onChange={(e) => {
                                    setForm((prev: any) => {
                                        return { ...prev, name: e.target.value.trim() };
                                    });
                                }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Описание<Asterisk>*</Asterisk>
                            </SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                multiline
                                placeholder="Какое-то описание"
                                minRows={4}
                                onChange={(e) => {
                                    setForm((prev: any) => {
                                        return { ...prev, description: e.target.value.trim() };
                                    });
                                }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Цена<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onValueChange={(e) => {
                                    setForm((prev: any) => {
                                        return { ...prev, amount: e.floatValue };
                                    });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Комиссия мастера<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onValueChange={(e) => {
                                    setForm((prev: any) => {
                                        return { ...prev, commission: { value: e.floatValue } };
                                    });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                <div>Что входит в стоимость</div>
                                <div style={{ fontSize: '12px', color: '#A4A7B8' }}>
                                    Невыбранные элементы будут отображаться в блоке «Что не входит в стоимость»
                                </div>
                            </SubTitle>
                            <div>
                                {descendants?.map((descendant: any) => {
                                    return (
                                        <div key={descendant.id}>
                                            <Checkbox
                                                sx={{ padding: '5px 0' }}
                                                onClick={() => {
                                                    const isInclude = form.include_descriptions.find(
                                                        (item: any) => item.service_id === descendant.id,
                                                    );
                                                    if (isInclude) {
                                                        const include_descriptions = form.include_descriptions.filter(
                                                            (item: any) => item.service_id !== descendant.id,
                                                        );
                                                        setForm((prev: any) => {
                                                            return {
                                                                ...prev,
                                                                include_descriptions,
                                                            };
                                                        });
                                                    } else {
                                                        setForm((prev: any) => {
                                                            return {
                                                                ...prev,
                                                                include_descriptions: [
                                                                    ...prev.include_descriptions,
                                                                    {
                                                                        service_id: descendant.id,
                                                                        is_include_in_price: true,
                                                                    },
                                                                ],
                                                            };
                                                        });
                                                    }
                                                }}
                                            />
                                            <span>{descendant.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </Field>
                        <Divider sx={{ width: '600px' }} />
                        <Field>
                            <SubTitle>Доступен в API-интеграции</SubTitle>
                            <Checkbox
                                onChange={() => {
                                    setForm((prev: any) => {
                                        return { ...prev, is_active_integration: !prev.is_active_integration };
                                    });
                                }}
                                sx={{ padding: '5px 0' }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Внешний идентификатор</SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                onChange={(e) => {
                                    setForm((prev: any) => {
                                        return { ...prev, external_id: e.target.value };
                                    });
                                }}
                            />
                        </Field>
                    </FieldsContainer>
                    <ButtonsContainer>
                        <Button
                            disabled={!form.amount || !form.commission.value || !form.name || !form.description}
                            variant="contained"
                            onClick={handleCreateServicesComplex}
                        >
                            Добавить
                        </Button>
                        <Button
                            sx={{ marginRight: '190px' }}
                            variant="outlined"
                        >
                            Отмена
                        </Button>
                        <FootNote />
                    </ButtonsContainer>
                </ModalWrapper>
            </Container>
        </MainLayout>
    );
};
