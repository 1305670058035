import Autocomplete from 'components/inputs/Autocomplete';
import { EDayOfWeekRu } from 'mockData/schedule';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Divider, Typography } from '@mui/material';

import { EDayOfWeek } from '../../../../../../enums/schedule.enum';
import { IOption, TErrors } from '../../../../../../interfaces';
import './EditWorkerScheduleForm.scss';
import { IWorkerScheduleDayItem } from '../../../../../../interfaces/edit/editWorkerSchedule';

export interface IDayFormItemProps {
    dayData: IWorkerScheduleDayItem;
    onChangeTime: (
        options: IOption<string> | null,
        week_day: EDayOfWeek,
        type: 'start_time' | 'end_time',
        index: number,
    ) => void;
    onAddTime: (week_day: EDayOfWeek) => void;
    onDeleteTime: (week_day: EDayOfWeek, index: number) => void;
    errors: TErrors | null;
    index: number;
}

function DayFormItem({
    dayData,
    onChangeTime,
    onAddTime,
    onDeleteTime,
    errors,
    index: parentIndex,
}: IDayFormItemProps) {
    const { week_day, time_windows } = dayData;

    function getTimePickerOptions(
        currentValue: IOption<string> | null,
        isStartOptions: boolean,
        dividerValue?: string,
    ): IOption<string>[] {
        const timeOptions: IOption<string>[] = Array.from({ length: 24 })
            .map((_, idx) => {
                const hour = idx > 9 ? idx : `0${String(idx)}`;
                return [`${hour}:00`, `${hour}:30`];
            })
            .flat()
            .map((item) => ({ value: item, label: item }));

        if (!time_windows || !time_windows.length) {
            return timeOptions;
        }

        return timeOptions.map((option) => {
            const optionHour = parseInt(option.value.split(':')[0], 10);
            const optionMinute = parseInt(option.value.split(':')[1], 10);
            const isDisabled = time_windows.some(({ start_time, end_time }) => {
                if (!start_time || !end_time) {
                    return false;
                }

                const startTimeHour = parseInt(start_time.split(':')[0], 10);
                const startTimeMinute = parseInt(start_time.split(':')[1], 10);
                const endTimeHour = parseInt(end_time.split(':')[0], 10);
                const endTimeMinute = parseInt(end_time.split(':')[1], 10);
                if (isStartOptions) {
                    return (
                        (optionHour > startTimeHour ||
                            (optionHour === startTimeHour && optionMinute >= startTimeMinute)) &&
                        (optionHour < endTimeHour || (optionHour === endTimeHour && optionMinute <= endTimeMinute))
                    );
                } else {
                    return (
                        (optionHour > startTimeHour ||
                            (optionHour === startTimeHour && optionMinute >= startTimeMinute)) &&
                        dividerValue &&
                        (optionHour < parseInt(dividerValue.split(':')[0], 10) ||
                            (optionHour === parseInt(dividerValue.split(':')[0], 10) &&
                                optionMinute <= parseInt(dividerValue.split(':')[1], 10)))
                    );
                }
            });
            return { ...option, disabled: isDisabled };
        });
    }

    return (
        <>
            <div className="week-day-container">
                <Typography
                    key={parentIndex}
                    sx={{
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '21px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                    }}
                >
                    {EDayOfWeekRu[week_day]}
                </Typography>
                <div className="edit-worker-schedule-modal-form-wrapper">
                    {time_windows.length ? (
                        time_windows.map(({ start_time, end_time }, index) => (
                            <div
                                key={index}
                                className="time-pickers"
                            >
                                <div className="time-picker-start-time">
                                    <Autocomplete
                                        name={`start_time_${parentIndex}_${index}`}
                                        label="С *"
                                        margin="dense"
                                        options={getTimePickerOptions(
                                            { label: start_time, value: start_time },
                                            true,
                                            end_time,
                                        )}
                                        value={{ value: start_time || '', label: start_time || '' } || null}
                                        onChange={(options) => onChangeTime(options, week_day, 'start_time', index)}
                                    />
                                </div>
                                <Divider
                                    orientation="horizontal"
                                    className="divider"
                                    sx={{ paddingTop: '10px' }}
                                />
                                <div className="time-picker-end-time">
                                    <Autocomplete
                                        name={`end_time_${parentIndex}_${index}`}
                                        label="По *"
                                        margin="dense"
                                        options={getTimePickerOptions(null, false, start_time)}
                                        value={{ value: end_time || '', label: end_time || '' } || null}
                                        errors={errors}
                                        onChange={(options) => onChangeTime(options, week_day, 'end_time', index)}
                                    />
                                </div>
                                <Button
                                    sx={{ minWidth: 0, padding: 0 }}
                                    onClick={() => onDeleteTime(week_day, index)}
                                >
                                    <DeleteIcon sx={{ color: '#F56767', minWidth: 0, padding: 0 }} />
                                </Button>
                            </div>
                        ))
                    ) : (
                        <Typography sx={{ fontSize: '14px', color: '#878EA5', marginRight: '266px' }}>
                            Время не задано
                        </Typography>
                    )}
                </div>
            </div>
            <Button
                sx={{ textTransform: 'inherit', marginLeft: '170px', padding: 0 }}
                onClick={() => onAddTime(week_day)}
            >
                <div className="add-time-container">
                    <AddRoundedIcon sx={{ fontSize: 'medium', color: '#0040F1' }} />
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '15px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            color: '#0040F1',
                            textTransform: 'inherit',
                        }}
                    >
                        Добавить время
                    </Typography>
                </div>
            </Button>
            <Divider className="divider" />
        </>
    );
}

export default DayFormItem;
