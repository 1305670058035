export const isTimeFuture = (date: string | Date | null | undefined): boolean => {
    if (!date) return false;
    let formatDate = date;

    if (typeof date === 'string') {
        const USDate = date.split('.');
        if (USDate[2]?.length < 4) {
            return false;
        }
        formatDate = `${USDate[2]}/${USDate[1]}/${USDate[0]}`;
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    const diff = new Date(formatDate)?.getTime() - new Date()?.getTime();
    return diff > 0;
};
