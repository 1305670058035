import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ createIssueReminder }: RootState) => createIssueReminder;

export const isOpenCreateIssueReminderSelector = createSelector(state, ({ isOpen }) => isOpen);
export const issueIdCreateIssueReminderSelector = createSelector(state, ({ issueId }) => issueId);
export const formValuesCreateIssueReminderSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsCreateIssueReminderSelector = createSelector(state, ({ errors }) => errors);
