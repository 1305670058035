import { Paper, styled, TablePagination } from '@mui/material';

export const Container = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

export const TableContainer = styled(Paper)({
    position: 'relative',
    height: '100%',
    overflow: 'auto',
});

export const Pagination = styled(TablePagination)({
    display: 'block',
    backgroundColor: '#fff',
    position: 'sticky',
    bottom: 0,
});
