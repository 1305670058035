import { companiesApi, servicesApi } from 'api';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';
import { integrationsSelector } from 'store/selectors/integrationsSelectors';
import { servicesSelector } from 'store/selectors/servicesSelector';

import Add from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, Table, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchIntegrations } from './store';
import { ButtonsContainer, TextInput, CellCenter, HeadCellCenter, PlusButton } from './styles';

export const Integrations = () => {
    const dispatch = useAppDispatch();

    const { serviceById } = useSelector(servicesSelector);
    const { integrations } = useSelector(integrationsSelector);

    const [isVisible, setVisible] = useState(false);
    const [addIntegration, setAddIntegration] = useState<any>();
    const [companies, setCompanies] = useState<any>();

    const handleAddIdentifier = (value: string) => {
        setAddIntegration((prev: any) => {
            return { ...prev, external_id: value };
        });
    };

    const handleConfirm = () => {
        servicesApi
            .createIntegration(addIntegration)
            .then(() => {
                enqueueSnackbar('Успешно', { variant: 'success' });
                dispatch(fetchIntegrations({ service_id: serviceById?.id }));
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });

        setAddIntegration({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddIntegration({});
        setVisible(false);
    };

    const handleDeleteIntegration = (id: string) => {
        servicesApi
            .deleteIntegration(id)
            .then(() => {
                enqueueSnackbar('Интеграция добавлена', { variant: 'success' });
                dispatch(fetchIntegrations({ service_id: serviceById?.id }));
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    const getCompanies = async () => {
        const { data } = await companiesApi.getCompanies();
        setCompanies(data);
    };

    useEffect(() => {
        dispatch(fetchIntegrations({ service_id: serviceById?.id }));
        getCompanies();
    }, []);

    const companiesOptions = companies?.map((item: any) => item.name);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <HeadCellCenter sx={{ width: '230px' }}>КОМПАНИЯ</HeadCellCenter>
                    <HeadCellCenter>ИДЕНТИФИКАТОР</HeadCellCenter>
                    <HeadCellCenter sx={{ width: '50px' }} />
                </TableRow>
            </TableHead>
            <TableBody>
                {integrations.map((item: any) => {
                    return (
                        <TableRow key={item.id}>
                            <CellCenter>{item.company.name}</CellCenter>
                            <CellCenter>{item.external_id}</CellCenter>
                            <CellCenter>
                                <Button onClick={() => handleDeleteIntegration(item.id)}>
                                    <DeleteBasket />
                                </Button>
                            </CellCenter>
                        </TableRow>
                    );
                })}
                {isVisible ? (
                    <TableRow>
                        <CellCenter>
                            <Autocomplete
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={companiesOptions ?? []}
                                isOptionEqualToValue={(option, value) => option === value}
                                filterSelectedOptions
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                )}
                                onChange={(_, option) => {
                                    const company_id = companies?.find((company: any) => company.name === option).id;

                                    setAddIntegration((prev: any) => {
                                        return { ...prev, company_id };
                                    });
                                }}
                            />
                        </CellCenter>
                        <CellCenter>
                            <TextInput
                                onChange={(e) => handleAddIdentifier(e.target.value)}
                                size="small"
                            />
                        </CellCenter>
                        <CellCenter>
                            <ButtonsContainer>
                                <div onClick={handleConfirm}>
                                    <Confirm />
                                </div>
                                <div onClick={handleCancel}>
                                    <Cancel />
                                </div>
                            </ButtonsContainer>
                        </CellCenter>
                    </TableRow>
                ) : null}
                <TableRow>
                    <PlusButton
                        onClick={() => {
                            setAddIntegration((prev: any) => {
                                return {
                                    ...prev,
                                    is_active: true,
                                    service_id: serviceById?.id,
                                    company_id: '',
                                    external_id: '',
                                };
                            });
                            setVisible(true);
                        }}
                        startIcon={<Add />}
                    >
                        Добавить
                    </PlusButton>
                </TableRow>
            </TableBody>
        </Table>
    );
};
