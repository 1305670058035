import React from 'react';

import PlacemarkIssue from './Placemark';

import { IIssue, ISelectedIssuesPin } from '../../../../../interfaces/map';
import { issueHelpTypeNamesMock } from '../../../../../mockData/issues';
import { timeWindowsFormatter } from '../../../../../utils/helpers/timeWindowsFormatter';

interface IProps {
    issues: IIssue[] | null;
    selectedPinIssue: ISelectedIssuesPin | null;
}

function ClusterIssues({ issues, selectedPinIssue }: IProps) {
    const getPointIssueData = (issue: IIssue) => {
        const homeDevices: string[] = [];
        issue.sub_issues?.map(({ home_device }) => {
            return homeDevices.push(
                [home_device.category?.name, home_device.brand?.name, home_device.model?.name]
                    .filter((str) => !!str)
                    .join(' '),
            );
        });
        return {
            balloonContentHeader: `<div>№ ${issue.oid}</div>`,
            iconContent: `${issueHelpTypeNamesMock[issue.help_type].slice(0, 1)}`,
            hintContent: `${issueHelpTypeNamesMock[issue.help_type]}`,
            balloonContentBody: `
				<div>
					<div>Дата вызова: ${timeWindowsFormatter(issue?.time_windows)}</div>
					<div>Техника: ${homeDevices ? homeDevices.join(' ') : 'не указана'}</div>
				</div>`,
        };
    };
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {issues?.map((issue) => (
                <PlacemarkIssue
                    key={issue.id}
                    issue={issue}
                    getPointIssueData={getPointIssueData}
                    selectedPinIssue={selectedPinIssue}
                />
            ))}
        </>
    );
}

export default ClusterIssues;
