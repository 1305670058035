import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { IconButton } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './PhotoFiles.scss';
import FilePreview from '../../../../../components/FilePreview';
import FileUploadButton from '../../../../../components/FileUploadButton/fileUploadButton';
import { IPhotoFile } from '../../../../../interfaces';
import { putIssueThunk } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

interface IProps {
    photoFiles: IPhotoFile[] | null;
}

interface IEditProps extends IProps {
    photoFiles: IPhotoFile[] | null;
    handleFileChanged: (ids: string[]) => void;
}

function PhotoFileEditMode({ photoFiles, handleFileChanged }: IEditProps) {
    const [photos, setPhotos] = useState<IPhotoFile[]>(photoFiles || []);

    function fileChanged() {
        handleFileChanged(photos.map((item: IPhotoFile) => item.id));
    }

    useEffect(() => {
        fileChanged();
    }, [photos]);

    function fileUpload(file: IPhotoFile) {
        if (photos.some((item: IPhotoFile) => item.id === file.id)) {
            return;
        }
        setPhotos([...photos, file]);
    }

    function fileRemove(id: string) {
        setPhotos(photos.filter((item: IPhotoFile) => item.id !== id));
    }

    return (
        <div className="dv-photo-files-edit">
            {photos.map((p) => {
                const preview = p.previews && p.previews[0];
                return (
                    <div
                        className="photo-file-wrapper"
                        key={p.id}
                    >
                        <IconButton
                            title="Удалить"
                            onClick={() => {
                                fileRemove(p.id);
                            }}
                            className="photo-file-delete"
                            color="error"
                            size="small"
                        >
                            <DeleteIcon />
                        </IconButton>
                        <img
                            src={preview?.url || p.url}
                            alt=""
                        />
                    </div>
                );
            })}
            <FileUploadButton fileUpload={fileUpload} />
        </div>
    );
}

function PhotoFiles({ photoFiles }: IProps) {
    const dispatch = useAppDispatch();
    const issue = useSelector(issueSelector);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [fileChanged, setFileChanged] = useState<string[]>([]);

    function handleEditMode(value: boolean) {
        setEditMode(value);
        setFileChanged([]);
    }

    function handleFileChanged(ids: string[]) {
        setFileChanged(ids);
    }

    async function handleApply() {
        if (!issue) {
            return;
        }
        try {
            await dispatch(putIssueThunk({ issueId: issue.id, newIssue: { photo_file_ids: fileChanged } }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            handleEditMode(false);
        }
    }

    return (
        <div className="photo-files-issue">
            {!editMode ? (
                <FilePreview photoFiles={photoFiles} />
            ) : (
                <PhotoFileEditMode
                    handleFileChanged={handleFileChanged}
                    photoFiles={photoFiles}
                />
            )}
            <>
                {!editMode ? (
                    <IconButton
                        onClick={() => {
                            handleEditMode(true);
                        }}
                        className="edit-iss-prop-icon"
                    >
                        <EditSharpIcon />
                    </IconButton>
                ) : (
                    <>
                        <IconButton
                            title="Сохранить"
                            onClick={handleApply}
                            className="save-remove-buttons"
                            sx={{ marginRight: '30px' }}
                        >
                            <CheckIcon />
                        </IconButton>
                        <IconButton
                            title="Отмена"
                            onClick={() => {
                                handleEditMode(false);
                            }}
                            className="save-remove-buttons"
                        >
                            <ClearIcon />
                        </IconButton>
                    </>
                )}
            </>
        </div>
    );
}

export default memo(PhotoFiles);
