import { FC, PropsWithChildren } from 'react';

export const withWrapper = (WrapperComponent: FC<PropsWithChildren>, InnerComponent: FC<JSX.IntrinsicAttributes>) => {
    return ((props?: JSX.IntrinsicAttributes) => {
        return (
            <WrapperComponent>
                <InnerComponent {...props} />
            </WrapperComponent>
        );
    })();
};
