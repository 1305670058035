import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PriceContent from './PriceContent';
import ServiceDetail from './ServiceDetail';
import ServicesButtonGroup from './ServicesButtonGroup';
import ServiceTabs from './ServiceTabs';

import { EPricesServicesTabs } from '../../enums/pricesServices.enum';
import MainLayout from '../../layouts/MainLayout';
import DeletePrice from '../../modules/PriceModals/DeletePrice';
import PriceDialog from '../../modules/PriceModals/PriceDialog';
import DeleteService from '../../modules/ServiceModals/DeleteService';
import ServiceDialog from '../../modules/ServiceModals/ServiceDialog';
import { setPricesAction } from '../../store/actions/prices';
import { getServiceThunk, setIsPriceServicesLoadingAction } from '../../store/actions/pricesServices';
import { useAppDispatch } from '../../store/hooks';
import { priceServiceSelector, priceServicesTabSelector } from '../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import './ServicePage.scss';

function ServicePage() {
    const { serviceId } = useParams();
    const dispatch = useAppDispatch();

    const serviceTabs = useSelector(priceServicesTabSelector);
    const service = useSelector(priceServiceSelector);

    useEffect(() => {
        async function handle() {
            try {
                if (!serviceId) return;
                dispatch(setIsPriceServicesLoadingAction(true));
                await dispatch(getServiceThunk(serviceId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                dispatch(setIsPriceServicesLoadingAction(false));
            }
        }
        handle();
        return () => {
            dispatch(setPricesAction(null));
        };
    }, [serviceId]);

    return (
        <MainLayout>
            <div className="service-page-wrapper">
                <div className="service-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        {service?.name || null}
                    </Typography>
                    <ServiceDialog />
                    <DeleteService />
                    <PriceDialog />
                    <DeletePrice />
                    <ServicesButtonGroup />
                </div>
                <ServiceTabs />
                {(() => {
                    switch (serviceTabs) {
                        case EPricesServicesTabs.Description: {
                            return <ServiceDetail service={service} />;
                        }
                        case EPricesServicesTabs.BasicServices: {
                            return <PriceContent />;
                        }
                        case EPricesServicesTabs.AdditionalServices: {
                            return <PriceContent />;
                        }
                        default:
                            return null;
                    }
                })()}
            </div>
        </MainLayout>
    );
}

export default ServicePage;
