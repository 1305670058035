import { servicesApi } from 'api';
import { chooseDelete, fetchCategories, fetchServices, fetchServiceTypes, setFilters } from 'pages/services/store';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    ButtonsContainer,
    Cell,
    CheckBox,
    CheckBoxWrapper,
    CheckTitle,
    HeadCell,
    MuiTextField,
    SubTitle,
} from './styles';

import { Asterisk, Field, FieldContainer, MuiAutocomplete, PlusButton } from '../../create-service/styles';
import { FootNote } from '../../footnote';
import { DeleteService } from '../../modals/delete-service';

type TOptions = {
    label: string;
    value: string;
};

// type TForm = {
//     parents: string[];
//     is_root: boolean;
//     children: string[];
//     description: string;
//     name: string;
//     service_types: string[];
//     categories: string[];
//     is_active: boolean;
// };

export const Basics = () => {
    const dispatch = useAppDispatch();

    const { services, serviceById, categories, service_types } = useSelector(servicesSelector);

    // const modifiedChildren = serviceById?.children?.map((child) => {
    //     return { ...child, is_deleted: false };
    // });

    // const modifiedParents = serviceById?.parents?.map((parent) => {
    //     return { ...parent, is_deleted: false };
    // });

    // const modifiedCategories = serviceById?.categories?.map((category) => {
    //     return { ...category, is_deleted: false };
    // });

    // const modifiedTypes = serviceById?.service_types?.map((type) => {
    //     return { ...type, is_deleted: false };
    // });

    // const initialStateForm: TForm = {
    //     parents: modifiedParents ?? [],
    //     is_root: serviceById?.is_root ?? true,
    //     description: serviceById?.description ?? '',
    //     name: serviceById?.name ?? '',
    //     service_types: modifiedTypes ?? [],
    //     categories: modifiedCategories ?? [],
    //     is_active: serviceById?.is_active ?? false,
    //     children: modifiedChildren ?? [],
    // };

    const [isVisible, setVisible] = useState(false);
    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [childServices, setChildServices] = useState<any>(serviceById?.children ?? []);
    const [addChildService, setAddChildService] = useState<any>();
    const [hasParent, setHasParent] = useState<boolean>(false);
    // const [form, setForm] = useState(initialStateForm);

    const handleConfirm = () => {
        handleEditService({ children: [addChildService] });
        setAddChildService({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddChildService({});
        setVisible(false);
    };

    const handleDelete = (id: string) => {
        dispatch(chooseDelete(id));
    };

    const handleEditService = (data: any) => {
        servicesApi
            .editService(serviceById?.id, data)
            .then(() => {
                enqueueSnackbar('Изменение успешно проведено', { variant: 'success' });
                dispatch(fetchServices());
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    useEffect(() => {
        dispatch(setFilters({ search: '' }));
        dispatch(fetchServices());
        dispatch(fetchServiceTypes());
        dispatch(fetchCategories());
    }, []);

    const optionsServices: TOptions[] = services.map((item) => ({ label: item.name, value: item.id }));
    const optionsTypes: TOptions[] = service_types.map((item) => ({ label: item.name, value: item.id }));
    const optionsCategories: TOptions[] = categories?.items?.map((item: any) => ({ label: item.name, value: item.id }));

    const defaultParents = {
        label: serviceById?.parents?.[0]?.name ?? '',
        value: serviceById?.parents?.[0]?.id ?? '',
        is_deleted: false,
    };

    const defaultCategories = {
        label: serviceById?.categories?.[0]?.name ?? '',
        value: serviceById?.categories?.[0]?.id ?? '',
        is_deleted: false,
    };

    const defaultServiceTypes = {
        label: serviceById?.service_types?.[0]?.name ?? '',
        value: serviceById?.service_types?.[0]?.id ?? '',
        is_deleted: false,
    };

    if (!serviceById) return null;

    return (
        <Box>
            <FieldContainer>
                <div>
                    <Field>
                        <SubTitle>
                            Родитель<Asterisk>*</Asterisk>
                        </SubTitle>
                        <MuiAutocomplete
                            multiple
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={optionsServices ?? []}
                            isOptionEqualToValue={(option, value) => option === value}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Выбрать"
                                />
                            )}
                            defaultValue={serviceById?.parents?.[0]?.id ? [defaultParents] : []}
                            onChange={(_, value) => {
                                const options = value as TOptions[];

                                const parents = options.map((option) => {
                                    return { id: option.value, is_deleted: false };
                                });

                                handleEditService({ parents });
                            }}
                            disabled={hasParent}
                        />
                    </Field>
                    <Field sx={{ marginTop: '10px' }}>
                        <SubTitle />
                        <CheckBox
                            onClick={() => {
                                setHasParent(!hasParent);
                            }}
                        />
                        <CheckTitle>Нет родителя</CheckTitle>
                    </Field>
                </div>
                <Field>
                    <SubTitle>
                        Наименование<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField
                        defaultValue={serviceById?.name}
                        onBlur={(e) => handleEditService({ name: e.target.value })}
                        size="small"
                    />
                </Field>
                <Field>
                    <SubTitle>
                        Описание<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField
                        multiline
                        placeholder="Какое-то описание"
                        minRows={4}
                        defaultValue={serviceById?.description}
                        onBlur={(e) => handleEditService({ description: e.target.value })}
                    />
                </Field>
                <Field>
                    <SubTitle>Категории</SubTitle>
                    <MuiAutocomplete
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={optionsCategories ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Категории"
                            />
                        )}
                        defaultValue={serviceById?.categories?.[0]?.id ? [defaultCategories] : []}
                        onChange={(_, value) => {
                            const options = value as TOptions[];

                            const categories = options.map((option) => {
                                return { id: option.value, is_deleted: false };
                            });

                            handleEditService({ categories });
                        }}
                    />
                </Field>
                <Field>
                    <SubTitle>Типы услуг</SubTitle>
                    <MuiAutocomplete
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={optionsTypes ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Типы услуг"
                            />
                        )}
                        defaultValue={serviceById?.service_types?.[0]?.id ? [defaultServiceTypes] : []}
                        onChange={(_, value) => {
                            const options = value as TOptions[];

                            const service_types = options.map((option) => {
                                return { id: option.value, is_deleted: false };
                            });

                            handleEditService({ service_types });
                        }}
                    />
                </Field>
                <Field sx={{ marginTop: '10px' }}>
                    <SubTitle>Активная</SubTitle>
                    <CheckBoxWrapper>
                        <CheckBox
                            onClick={() => handleEditService({ is_active: !serviceById?.is_active })}
                            defaultChecked={serviceById?.is_active}
                        />
                        <FootNote />
                    </CheckBoxWrapper>
                </Field>
            </FieldContainer>
            <Box sx={{ marginTop: '28px' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Дочерние услуги</Typography>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <HeadCell sx={{ width: '450px' }}>НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                        <HeadCell sx={{ width: '450px' }}>ВКЛЮЧАТЬ В СТОИМОСТЬ</HeadCell>
                        <HeadCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {childServices.map((item: any) => {
                        return (
                            <TableRow key={item?.id}>
                                <Cell>
                                    {/* {optionsServices.find((option) => option.value === item.id)?.label} */}
                                    {item.name}
                                </Cell>
                                <Cell>
                                    <Checkbox checked={item?.is_include_in_price} />
                                </Cell>
                                <Cell>
                                    <Button
                                        onClick={() => {
                                            handleDelete(item.id);
                                            setIsOpenDelete(true);
                                        }}
                                    >
                                        <DeleteBasket />
                                    </Button>
                                </Cell>
                            </TableRow>
                        );
                    })}
                    <DeleteService
                        onClose={setIsOpenDelete}
                        setChildServices={setChildServices}
                        isOpen={isOpenDelete}
                    />
                    {isVisible ? (
                        <TableRow>
                            <Cell>
                                <Autocomplete
                                    size="small"
                                    sx={{ width: '260px' }}
                                    popupIcon={<ExpandMoreIcon />}
                                    options={optionsServices ?? []}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выберите услугу"
                                        />
                                    )}
                                    onChange={(_, value) => {
                                        setAddChildService({
                                            id: value?.value,
                                            is_include_in_price: false,
                                            is_deleted: false,
                                        });
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <Checkbox
                                    onChange={(e) => {
                                        setAddChildService((prev: any) => {
                                            return { ...prev, is_include_in_price: e.target.checked };
                                        });
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <ButtonsContainer>
                                    <div onClick={handleConfirm}>
                                        <Confirm />
                                    </div>
                                    <div onClick={handleCancel}>
                                        <Cancel />
                                    </div>
                                </ButtonsContainer>
                            </Cell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <PlusButton
                                onClick={() => setVisible(true)}
                                startIcon={<AddIcon />}
                            >
                                Добавить услугу
                            </PlusButton>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
};
