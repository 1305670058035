import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '../../../../../components/inputs/Autocomplete';
import { IOption } from '../../../../../interfaces';
import { setFormValuesPriceDialogAction } from '../../../../../store/actions/priceDialog';
import { useAppDispatch } from '../../../../../store/hooks';
import { companiesOptionsSelector } from '../../../../../store/selectors/companiesSelectors';
import { errorsPriceDialogSelector } from '../../../../../store/selectors/priceDialogSelector';
import { priceSelector } from '../../../../../store/selectors/pricesSelectors';

function PriceCompany() {
    const dispatch = useAppDispatch();

    const companiesOptions = useSelector(companiesOptionsSelector);
    const price = useSelector(priceSelector);
    const errors = useSelector(errorsPriceDialogSelector);

    const [forceRerender, setForceRerender] = useState('');

    const getCompany = useMemo(() => {
        if (price && price?.company) {
            setForceRerender(Math.random().toFixed(10));
            dispatch(setFormValuesPriceDialogAction({ partner_id: price?.partner_id }));
            return {
                value: price?.partner_id,
                label: price?.company.name,
            };
        }
        return null;
    }, [!!price]);

    function changeCompanyIdHandler(option: IOption | null) {
        dispatch(setFormValuesPriceDialogAction({ partner_id: (option?.value as string) || null }));
    }
    return (
        <Autocomplete
            name="company_id"
            margin="dense"
            key={forceRerender}
            inpPlaceholder={'Компании'}
            onChange={changeCompanyIdHandler}
            defaultValue={getCompany}
            options={companiesOptions || []}
            errors={errors}
        />
    );
}

export default PriceCompany;
