import { CloseIcon } from 'static/Icons/CloseIcon';

import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FC } from 'react';

import { ButtonsWrapper, CloseIconWrapper } from './styles';

type Props = {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
    title: string;
};

export const PriceAndCommission: FC<Props> = ({ isOpen, setOpen, title }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={() => setOpen(false)}
            sx={{
                '.MuiDialog-paper': {
                    width: '450px',
                    borderRadius: '8px',
                },
            }}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography variant="h5">{title}</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Стоимость"
                    sx={{ marginTop: '14px', width: '400px' }}
                />
                <ButtonsWrapper>
                    <Button variant="contained">Подтвердить</Button>
                    <Button variant="outlined">Отмена</Button>
                </ButtonsWrapper>
            </DialogContent>
        </Dialog>
    );
};
