import { EIssueTableHeadFilter } from '../../enums/issues.enum';
import { IIssue, IIssuesFilters } from '../../interfaces/issues';

interface IProps {
    filters: IIssuesFilters | null;
    property: keyof IIssue;
}

export const activeFilter = ({ filters, property }: IProps) => {
    switch (true) {
        case property === EIssueTableHeadFilter.Status && !!filters?.status?.length:
            return {
                isActive: true,
                count: filters?.status?.length,
            };
        case property === EIssueTableHeadFilter.HelpType && !!filters?.help_type?.length:
            return {
                isActive: true,
                count: filters?.help_type?.length,
            };
        case property === EIssueTableHeadFilter.Reminder && !!filters?.has_reminders:
            return {
                isActive: true,
                count: null,
            };
        case property === EIssueTableHeadFilter.Companies && !!filters?.partner_id__in?.length:
            return {
                isActive: true,
                count: filters?.partner_id__in?.length,
            };
        case property === EIssueTableHeadFilter.Category && !!filters?.category_id?.length:
            return {
                isActive: true,
                count: filters?.category_id?.length,
            };
        case property === EIssueTableHeadFilter.Region && !!filters?.ratio_category_id__in?.length:
            return {
                isActive: true,
                count: filters?.ratio_category_id__in?.length,
            };
        default: {
            return {
                isActive: false,
                count: null,
            };
        }
    }
};
