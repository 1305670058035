import { ECompaniesActions } from '../../enums/actions.enum';
import { ICompaniesState, TCompaniesActions } from '../../interfaces/companies';

const initState: ICompaniesState = {
    companies: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const companiesReducer = (state = initialState, action: TCompaniesActions): ICompaniesState => {
    switch (action.type) {
        case ECompaniesActions.SetCompanies:
            return {
                ...state,
                companies: action.payload,
            };
        default:
            return state;
    }
};
