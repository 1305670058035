import LoadingButton from '@mui/lab/LoadingButton';
import { Button, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { EPaymentMethod } from '../../../../../../../enums/issues.enum';
import { putPaymentMethodThunk } from '../../../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../../../store/hooks';
import { issueSelector } from '../../../../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../../../utils/helpers/getErrorString';

interface IProps {
    onClose: () => void;
}

function EditPaymentMethodForm({ onClose }: IProps) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const { id: issueId } = issue || {};

    const [paymentMethod, setPaymentMethod] = useState<EPaymentMethod>(EPaymentMethod.Cash);
    const [isLoading, setIsLoading] = useState(false);

    async function submitHandler() {
        try {
            if (!issueId || !paymentMethod) return;
            setIsLoading(true);
            await dispatch(putPaymentMethodThunk({ issueId, data: { payment_method: paymentMethod } }));
            onClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    function changePaymentMethodHandler(e: SelectChangeEvent<EPaymentMethod>) {
        setPaymentMethod(e.target.value as EPaymentMethod);
    }

    return (
        <div className="payment-method-form">
            <FormControl
                fullWidth
                margin="dense"
            >
                <InputLabel id="payment-method-label">Способ оплаты</InputLabel>
                <Select
                    labelId="payment-method-label"
                    name="payment-method"
                    margin="dense"
                    label="Способ оплаты"
                    onChange={changePaymentMethodHandler}
                    defaultValue={paymentMethod}
                >
                    <MenuItem value={EPaymentMethod.Cash}>Наличные</MenuItem>
                    <MenuItem value={EPaymentMethod.WithoutCash}>Безналичные</MenuItem>
                </Select>
            </FormControl>
            <div className="buttons-wrapper">
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Отмена
                </Button>

                <LoadingButton
                    variant="contained"
                    loading={isLoading}
                    onClick={submitHandler}
                >
                    Сохранить
                </LoadingButton>
            </div>
        </div>
    );
}

export default EditPaymentMethodForm;
