import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { EIssueHelpType } from '../../../../enums/issues.enum';
import { ICategoryRatiosFilter } from '../../../../interfaces/categoriesRatiosFilter';
import { IGroup } from '../../../../interfaces/groups';
import CategoriesFilter from '../../../../modules/CategoriesFilter';
import CategoriesRatiosFilter from '../../../../modules/CategoriesRatiosFilter';
import GroupFilter from '../../../../modules/GroupFilter';
import HelpTypeFilter from '../../../../modules/HelpTypeFilter';
import Search from '../../../../modules/WorkersSelect/Search';
import { setArchiveWorkersFiltersAction } from '../../../../store/actions/archive';
import { useAppDispatch } from '../../../../store/hooks';
import { archiveWorkersFiltersSelector } from '../../../../store/selectors/archiveSelectors';
import './ArchiveWorkersFilters.scss';

function ArchiveWorkersFilters() {
    const dispatch = useAppDispatch();

    const filters = useSelector(archiveWorkersFiltersSelector);

    function onChangeCategoriesHandler(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        dispatch(
            setArchiveWorkersFiltersAction({
                ...(filters || {}),
                area_id__in: categoriesIds,
            }),
        );
    }

    function onChangeGroupHandler(group: IGroup | null) {
        dispatch(setArchiveWorkersFiltersAction({ ...(filters || {}), group_id__in: group }));
    }

    function searchWorker(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setArchiveWorkersFiltersAction({ ...filters, search: event.target.value }));
    }

    function selectCRFIdsForWorkers(selectCRFIds: string[] | null) {
        dispatch(
            setArchiveWorkersFiltersAction({
                ...(filters || {}),
                specialization__category_id__in: selectCRFIds,
            }),
        );
    }
    function selectHelpTypeForWorkers(helpType: EIssueHelpType[] | null) {
        dispatch(
            setArchiveWorkersFiltersAction({
                ...(filters || {}),
                specialization__help_type__in: helpType,
            }),
        );
    }

    return (
        <div className="archive-workers-filters-wrapper">
            <div className="extra-filters">
                <CategoriesRatiosFilter onChange={onChangeCategoriesHandler} />
                <CategoriesFilter
                    selectedCRFIdsForWorkers={filters?.specialization__category_id__in || null}
                    onChange={selectCRFIdsForWorkers}
                />
                <HelpTypeFilter
                    selectedHelpType={filters?.specialization__help_type__in || null}
                    onChange={selectHelpTypeForWorkers}
                />
                <GroupFilter
                    selectedGroup={filters?.group_id__in || null}
                    onChange={onChangeGroupHandler}
                />
            </div>
            <Search
                onChange={searchWorker}
                placeholder="Поиск по ФИО, номеру телефона..."
            />
        </div>
    );
}

export default ArchiveWorkersFilters;
