import { EEditIssueStatusToCancelActions } from '../../../enums/actions.enum';
import {
    IEditIssueStatusToCancelState,
    TEditIssueStatusToCancelActions,
} from '../../../interfaces/edit/editIssueStatusToCancel';

const initState: IEditIssueStatusToCancelState = {
    isOpen: false,
    issueId: null,
    formValues: null,
    errors: null,
    cancelationReasons: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editIssueStatusToCancelReducer = (
    state = initialState,
    action: TEditIssueStatusToCancelActions,
): IEditIssueStatusToCancelState => {
    switch (action.type) {
        case EEditIssueStatusToCancelActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditIssueStatusToCancelActions.SetIssueId:
            return {
                ...state,
                issueId: action.payload,
            };
        case EEditIssueStatusToCancelActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case EEditIssueStatusToCancelActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case EEditIssueStatusToCancelActions.SetCancelationReasons:
            return {
                ...state,
                cancelationReasons: action.payload,
            };
        default:
            return state;
    }
};
