import { AppDispatch } from 'store/store';

import { chatsApi, clientsApi, issuesApi, workersApi } from '../../api';
import { EChatActions } from '../../enums/actions.enum';
import {
    IAddChatHistoryItemAction,
    IChatDetail,
    IChatResetAction,
    IDeleteChatHistoryItemAction,
    IMarkAsViewedChatHistoryItemsAction,
    IMessage,
    ISetChatAuthorClientAction,
    ISetChatAuthorWorkerAction,
    ISetChatDetailAction,
    ISetChatHistoryAction,
    ISetChatIssueHistoryForClientAction,
    ISetChatIssueHistoryForWorkerAction,
    ISetChatParticipantsAction,
} from '../../interfaces/chat';
import { IChatUser } from '../../interfaces/chats';
import { IClient } from '../../interfaces/clients';
import { IIssue } from '../../interfaces/issues';
import { IWorkerDetail } from '../../interfaces/workers';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const chatResetAction = (): IChatResetAction => ({
    type: EChatActions.ChatReset,
});

export const setChatDetailAction = (payload: IChatDetail | null): ISetChatDetailAction => ({
    type: EChatActions.SetChatDetail,
    payload,
});

export const setChatAuthorClientAction = (payload: IClient | null): ISetChatAuthorClientAction => ({
    type: EChatActions.SetChatAuthorClient,
    payload,
});

export const setChatAuthorWorkerAction = (payload: IWorkerDetail | null): ISetChatAuthorWorkerAction => ({
    type: EChatActions.SetChatAuthorWorker,
    payload,
});

export const setChatIssueHistoryForClientAction = (payload: IIssue[] | null): ISetChatIssueHistoryForClientAction => ({
    type: EChatActions.SetChatIssueHistoryForClient,
    payload,
});

export const setChatIssueHistoryForWorkerAction = (payload: IIssue[] | null): ISetChatIssueHistoryForWorkerAction => ({
    type: EChatActions.SetChatIssueHistoryForWorker,
    payload,
});

export const setChatHistoryAction = (payload: IMessage[] | null): ISetChatHistoryAction => ({
    type: EChatActions.SetChatHistory,
    payload,
});

export const addChatHistoryItemAction = (payload: IMessage): IAddChatHistoryItemAction => ({
    type: EChatActions.AddChatHistoryItem,
    payload,
});

export const deleteChatHistoryItemAction = (payload: string): IDeleteChatHistoryItemAction => ({
    type: EChatActions.DeleteChatHistoryItem,
    payload,
});

export const markAsViewedChatHistoryItemsAction = (payload: string[]): IMarkAsViewedChatHistoryItemsAction => ({
    type: EChatActions.MarkAsViewedChatHistoryItems,
    payload,
});

export const setChatParticipantsAction = (payload: IChatUser[] | null): ISetChatParticipantsAction => ({
    type: EChatActions.SetChatParticipants,
    payload,
});

export const getChatDetailThunk = (chatId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await chatsApi.getChatDetail(chatId);
        dispatch(setChatDetailAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getChatAuthorClientThunk = (authorId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await clientsApi.getClient(authorId);

        dispatch(setChatAuthorClientAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getChatAuthorWorkerThunk = (authorId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await workersApi.getWorker(authorId);

        dispatch(setChatAuthorWorkerAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getChatIssueHistoryForClientThunk = (authorId: string) => async (dispatch: AppDispatch) => {
    try {
        const queriesObj: TArrayParams = [{ client_id: authorId }];
        const queries = getQueryParamsAsString(queriesObj);

        const { data } = await issuesApi.getIssues(queries);
        const { items: issues } = data;

        dispatch(setChatIssueHistoryForClientAction(issues));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getChatIssueHistoryForWorkerThunk = (authorId: string) => async (dispatch: AppDispatch) => {
    try {
        const queriesObj: TArrayParams = [{ worker_id: authorId }];
        const queries = getQueryParamsAsString(queriesObj);

        const { data } = await issuesApi.getIssues(queries);
        const { items: issues } = data;

        dispatch(setChatIssueHistoryForWorkerAction(issues));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getChatHistoryAndChatParticipantsThunk = (chatId: string) => async (dispatch: AppDispatch) => {
    try {
        const promises = [chatsApi.getChatHistory(chatId), chatsApi.getChatParticipants(chatId)];

        await Promise.all(promises).then(async (res) => {
            dispatch(setChatHistoryAction(res[0].data as IMessage[]));
            dispatch(setChatParticipantsAction(res[1].data as IChatUser[]));
        });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const deleteChatMessageThunk = (chatId: string, messageId: string) => async (dispatch: AppDispatch) => {
    try {
        await chatsApi.deleteMessage(chatId, messageId);
        dispatch(deleteChatHistoryItemAction(messageId));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
