import { EChatTypes } from '../../enums/chats.enum';
import { IChatDetail } from '../../interfaces/chat';
import { IChat, IChatUser } from '../../interfaces/chats';

export const getUserName = (u: IChatUser | null | undefined): string => {
    if (!u) return 'Пользователь не найден';
    if (u.is_delete) return 'Пользователь удален';
    return `${u.last_name || ''} ${u.first_name || ''}`;
};

export const getChatType = (c: IChat | IChatDetail | null | undefined, suffix_issue_number: boolean = true): string => {
    if (!c) return '';
    let result = '';
    if (c.chat.chat_type === EChatTypes.IssueRelated) result = 'Клиент-мастер';
    if (c.chat.chat_type === EChatTypes.TelegramBot) result = 'Телеграм Бот';
    if (c.chat.chat_type === EChatTypes.Support) result = 'Поддержка';
    if (suffix_issue_number && c.chat.issue_id) {
        result = `${result}. Заявка №${c.chat.issue_oid}`.replace('Поддержка.', '');
    }
    return result;
};
