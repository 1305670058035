/* eslint-disable no-shadow */
export enum EIssueHelpType {
    Video = 'video',
    Installation = 'installation',
    Repair = 'repair',
    Consultation = 'consultation',
}

export enum EIssueStatus {
    New = 'new',
    Processed = 'processed',
    MasterSearch = 'master_search',
    MasterNotFound = 'master_not_found',
    MasterArrived = 'master_arrived',
    MasterOnWay = 'master_on_way',
    InProgress = 'in_progress',
    SolvedWithProblem = 'solved_with_problem',
    PaymentRequired = 'payment_required',
    WaitingForReceipt = 'waiting_for_receipt',
    WaitingCall = 'waiting_call',
    Solved = 'solved',
    Canceled = 'canceled',
    WaitingApprovedByClient = 'waiting_approved_by_client',
    ProblemByClient = 'problem_by_client',
    WaitingApprovedByMaster = 'waiting_approved_by_master',
    ProblemByMaster = 'problem_by_master',
    WaitingForCash = 'waiting_for_cash',
    PartsSearch = 'parts_search',
    PartsDelivery = 'parts_delivery',
    PartsRequest = 'parts_request',
    SolvedNotPartner = 'solved_not_partner',
    Clarification = 'clarification',
    Diagnostic = 'diagnostic',
    ActNRP = 'act_nrp',
}

export enum EDiagnosticStatus {
    InProgress = 'in_progress',
    Finished = 'finished',
}

export const enum EIssueCreatedSource {
    Widget = 'widget',
    SellerForm = 'seller_form',
    ExternalForm = 'external_form',
    CallCenter = 'call_center',
    Backoffice = 'backoffice',
    App = 'app',
    Marketplace = 'marketplace',
    Classified = 'classified',
    Sws = 'sws',
    ChatAvito = 'chat_avito',
    TelegramBot = 'telegram_bot',
}

export const enum EIssueTriggers {
    /* добавить цены в заявку */
    SetPrice = 'set_price',
    /* оплата по заявке прошла успешно */
    PaidSucceeded = 'paid_succeeded',
    /* ожидание оплаты */
    PaymentRequired = 'payment_required',
    /* отмена клиентом */
    CancelClient = 'cancel_client',
    /* отмена мастером */
    CancelWorker = 'cancel_worker',
    /* отмена админом */
    CancelAdmin = 'cancel_admin',
    /* мастер прибыл */
    MasterArrived = 'master_arrived',
    /* заявка обработана */
    Processed = 'processed',
    /*заявка в работе*/
    InProgress = 'in_progress',
    /* мастер принял заявку в работу */
    Accept = 'accept',
    /* заявка выполнена */
    Done = 'done',
    /* Ожидает счета */
    WaitingForReceipt = 'waiting_for_receipt',

    /*обновление даты вызова клиентом*/
    UpdateTimeWindows = 'update_time_windows',
    /*обновление даты вызова вдмином*/
    ChangeAppointmentDatetime = 'change_appointment_datetime',
    /*изменение методы оплаты*/
    UpdateCardId = 'update_card_id',

    /*назначить мастеров на заявку*/
    Assignee = 'assignee',
    /*показывать скрытую мастером заявку, мастеру снова*/
    ShowAssignee = 'show_assignee',
    /*скрыть предложенную заявку у мастера*/
    HideAssignee = 'hide_assignee',

    /*оставить фидбэк*/
    Feedback = 'feedback',

    /*добавить заявку в архив*/
    SetIsArchivedTrue = 'set_is_archived_true',
    /*убрать заявку из архива*/
    SetIsArchivedFalse = 'set_is_archived_false',

    /* Неизвестные триггеры, но они есть на беке, да некоторые дублируются с EIssueStatus, необходимо выяснить есть ли прямая связь */
    UpdateAddress = 'update_address',
    SavePrice = 'save_price',
    WaitingCall = 'waiting_call',
    MasterSearch = 'master_search',
    Solved = 'solved',
}

export const enum EIssueHistory {
    Status = 'status',
    Address = 'address',
    Prices = 'prices',
    HelpType = 'help_type',
    Worker = 'worker',
    Appointment = 'appointment',
    TimeWindows = 'time_windows',
    Canceled = 'canceled',
    StatusChange = 'status_change',
}

export const enum EPaymentMethod {
    Cash = 'cash',
    WithoutCash = 'without_cash',
}

export const enum EAutoAssignmentErrors {
    //мастер не подобран по дате вызова
    BySchedule = 'by_schedule',

    //мастер не подобран по специализации
    BySpecializations = 'by_specializations',

    //мастер не подобран по зонам обслуживаниям
    ByServiceAreas = 'by_service_areas',

    //не найден мастер по всем трем полям выше
    ByJoin = 'by_join',

    //время подбора мастера истекло
    BySelectionTime = 'by_selection_time',

    //все мастера отказались от заявки
    ByAllWorkerHide = 'by_all_worker_hide',

    //не указана дата вызова
    ByTimeWindow = 'by_time_window',
}

export const enum EAutoAssignmentStatus {
    Pending = 'pending',
    Started = 'started',
    Finished = 'finished',
    Error = 'error',
}

export const enum ECategoryPreset {
    SplitSystem = 'split_system',
    Other = 'other',
}

export const enum EIssueTableHeadFilter {
    Category = 'category',
    HelpType = 'help_type',
    Status = 'status',
    Companies = 'companies',
    Region = 'region',
    Reminder = 'reminder',
}

export const enum EObserversFilter {
    CurrentUser = 'split_system',
    Unassigned = 'unassigned',
}

export const enum EFeedbackStatus {
    New = 'new',
    Verified = 'verified',
    Hidden = 'hidden',
    Published = 'published',
}
