import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IModel, IModelDetail } from '../interfaces/models';
import { IPaginationResp } from '../interfaces/pagination';

export default class ModelsAPI {
    getModels(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/models${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<IModel>>;
    }

    /* При увеличении количества параметров сменить на объект */
    createModel(name: IModel['name']) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/models`,
            data: { name },
            isAuthorization: true,
        }) as AxiosPromise<IModelDetail>;
    }
}
