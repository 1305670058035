import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import { setIsOpenDeletePriceServicesAction } from '../../../store/actions/pricesServices';
import { useAppDispatch } from '../../../store/hooks';
import {
    isOpenDeletePriceServicesSelector,
    priceServiceSelector,
} from '../../../store/selectors/pricesServicesSelector';

function DeleteService() {
    const dispatch = useAppDispatch();

    const service = useSelector(priceServiceSelector);
    const isOpen = useSelector(isOpenDeletePriceServicesSelector);

    function handleClose() {
        dispatch(setIsOpenDeletePriceServicesAction(false));
    }

    return (
        <div className="iss-cndts-item">
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box
                        display="flex"
                        justifyContent="start"
                        alignItems="center"
                        gap={1}
                    >
                        <ErrorOutlineIcon
                            color="error"
                            fontSize="small"
                        />
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>Удалить тип услуги</Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        Хотите удалить тип услуги <strong>{service?.name}</strong>?
                    </Box>
                </DialogContent>
                <DialogActions>
                    <FooterForm />
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteService;
