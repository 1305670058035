import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';
import './EditButton.scss';

export function EditButton(props: IconButtonProps) {
    return (
        <IconButton
            {...props}
            className="edit-button"
            component="span"
            disableRipple
        >
            <DriveFileRenameOutlineRoundedIcon className="edit-button-icon" />
        </IconButton>
    );
}
