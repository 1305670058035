import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IGetMapResp } from '../interfaces/map';

export default class MapAPI {
    /* Список заявок */
    getMap(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/routing/map${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<IGetMapResp>;
    }
}
