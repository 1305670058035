import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { ICategoryRatiosFilter } from '../../../../../../interfaces/categoriesRatiosFilter';
import { Categories as RatioCategories } from '../../../../../../modules/CategoriesRatiosFilter/Categories';
import { selectedCRFSelector } from '../../../../../../store/selectors/categoriesRatiosFilterSelectors';
import './RatioCategoryFilter.scss';

interface IProps {
    onChange: (categoriesIds: ICategoryRatiosFilter['id'][] | null) => void;
}

function RatioCategoryFilter({ onChange }: IProps) {
    const selectedCategories = useSelector(selectedCRFSelector);

    useEffect(() => {
        const categoriesIds =
            selectedCategories?.map(({ id }) => {
                return id;
            }) || null;

        onChange(categoriesIds);
    }, [selectedCategories]);

    return (
        <div className="region-table-filter">
            <RatioCategories />
        </div>
    );
}

export default RatioCategoryFilter;
