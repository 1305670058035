import { Box, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CategoriesGroup from './CategoriesGroup';
import EditServiceButton from './EditPriceButton';

import { EPricesServicesTabs } from '../../../../../enums/pricesServices.enum';
import { ERoutes } from '../../../../../enums/routes.enum';
import { IPrice } from '../../../../../interfaces/prices';
import { priceServicesTabSelector } from '../../../../../store/selectors/pricesServicesSelector';
import priceFormatter from '../../../../../utils/helpers/priceFormatter';

function PriceRow(price: IPrice) {
    const navigate = useNavigate();

    const serviceTabs = useSelector(priceServicesTabSelector);

    const { id, name, amount, categories, company, is_active_on_widget } = price;

    const isAdditional = serviceTabs === EPricesServicesTabs.BasicServices;

    const goToPricePage = () => {
        navigate(`/${ERoutes.Price}/${id}`);
    };

    return (
        <TableRow
            onClick={goToPricePage}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell>{name}</TableCell>
            <TableCell sx={{ width: '100px' }}>{priceFormatter(amount)}</TableCell>
            <TableCell>
                <CategoriesGroup categories={categories} />
            </TableCell>
            {isAdditional && (
                <>
                    <TableCell>{company?.name || 'не указан'}</TableCell>
                    <TableCell
                        align="center"
                        sx={{ width: '206px' }}
                    >
                        {is_active_on_widget ? 'Да' : 'Нет'}
                    </TableCell>
                </>
            )}
            <TableCell>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <EditServiceButton price={price} />
                </Box>
            </TableCell>
        </TableRow>
    );
}

export default PriceRow;
