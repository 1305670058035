import { createSelector } from 'reselect';

import { issueSelector } from './issuesSelectors';

import { ISortOrder } from '../../interfaces/orders';
import { RootState } from '../rootReducer';

const state = ({ orders }: RootState) => orders;

export const ordersSelector = createSelector(state, ({ orders }) => orders);
export const orderSelector = createSelector(state, ({ order }) => order);
export const sortOrdersBySubIssueSelector = createSelector([issueSelector, ordersSelector], (issue, orders) => {
    if (!issue || !orders) return null;

    return issue.sub_issues?.map((subIssue) => {
        const subIssueOrders = orders.filter((order) => order.sub_issue_id === subIssue.id);
        return {
            ...subIssue,
            subIssueOrders,
            client_id: issue.client?.id,
            issue_id: issue.id,
            worker: issue.worker,
        } as ISortOrder;
    });
});
export const isOpenNextStatusOrderSelector = createSelector(
    state,
    ({ isOpenNextStatusOrder }) => isOpenNextStatusOrder,
);

export const cancelOrderInfo = createSelector(state, ({ isOpenCancelOrder, cancelOrderType }) => ({
    isOpenCancelOrder,
    cancelOrderType,
}));
export const isLoadingOrderSelector = createSelector(state, ({ isOrdersLoading }) => isOrdersLoading);
export const selectedOrdersSelector = createSelector(state, ({ selectedOrders }) => selectedOrders);
