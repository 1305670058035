export const variables = {
    shadows: {
        button: {
            default:
                '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            hover: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
        },
        modal: {
            default:
                'rgba(0, 0, 0, 0.2) 0 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
            popUp: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
            menu: '0px 10px 13px rgba(17, 38, 146, 0.05)',
        },
    },
    colors: {
        white: '#ffffff',
        black: '#000000',
        lightBlack: '#00000014',
        athensGray: '#f0f2f4',
        selectiveYellow: '#ffb80021',
        gold: '#ffd200',
        mountainMeadow: '#1aa053ff',
        gallery: '#efefef',
        pictonBlue: '#48c1f4',
        selago: '#eaedfb',
        lightBlue: '#0040f1',

        // Text colors
        ebonyClay: '#232d42',
        manatee: '#8a92a6',
        denim: '#1976d2',
        royalBlue: '#3a57e8', // одинаковые цвета
        cornflowerBlue: '#3a57e8', // одинаковые цвета
        portGore: '#212342',
        silverChalice: '#ababab3f',
        dustyGray: '#949494',
        dustyGray_opacity: '#94949454',
        lightGray: '#e9ecef',
        editIconColor: 'rgba(0, 0, 0, 0.54)',
    },
    sizes: {
        topBarHeight: '80px',
        widthSidebarIsOpen: '258px',
        widthSidebar: '114px',
    },
};
