/* eslint-disable no-shadow */

export enum ENotificationType {
    Sms = 'sms',
    Email = 'email',
    PushNotification = 'push_notification',
}

export enum ENotificationStatus {
    Failed = 'failed',
    Succeeded = 'succeeded',
}
