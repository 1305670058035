import { buildCreateSlice, asyncThunkCreator } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from './store';

export const createSlice = buildCreateSlice({
    creators: { asyncThunk: asyncThunkCreator },
});

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
