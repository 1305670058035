import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editIssueStatusToCancel }: RootState) => editIssueStatusToCancel;

export const isOpenEditIssueStatusToCancelSelector = createSelector(state, ({ isOpen }) => isOpen);
export const issueIdEditIssueStatusToCancelSelector = createSelector(state, ({ issueId }) => issueId);
export const formValuesEditIssueStatusToCancelSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsEditIssueStatusToCancelSelector = createSelector(state, ({ errors }) => errors);
export const cancelationReasonsSelector = createSelector(state, ({ cancelationReasons }) => cancelationReasons);
export const cancelationReasonsOptionsSelector = createSelector(state, ({ cancelationReasons }) => {
    return (
        cancelationReasons?.map((reason) => {
            const { id, name } = reason;
            return { value: id, label: name || '' };
        }) || null
    );
});
