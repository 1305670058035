import React from 'react';

import MenuButton from './MenuButton';
import Order from './Order';

import Loader from '../../../../../components/Loader';
import { IOrder, ISelectedOrders, ISortOrder } from '../../../../../interfaces/orders';

interface IProps {
    sortOrdersBySubIssue: ISortOrder[] | null;
    partsOrders: IOrder[] | null;
    isLoading: boolean;
    selectedOrders: ISelectedOrders[] | null;
}

function Orders({ sortOrdersBySubIssue, partsOrders, isLoading, selectedOrders }: IProps) {
    return (
        <>
            <MenuButton />
            {(() => {
                switch (true) {
                    case isLoading:
                        return <Loader />;
                    case !!partsOrders?.length:
                        return (
                            <div className="order-list">
                                {sortOrdersBySubIssue?.map((orderSubIssue) => (
                                    <Order
                                        key={orderSubIssue.id}
                                        orderSubIssue={orderSubIssue}
                                        selectedOrders={selectedOrders}
                                    />
                                ))}
                            </div>
                        );
                    default:
                        return <div className="order-list">Нет добавленных заказов</div>;
                }
            })()}
        </>
    );
}

export default Orders;
