import { Chip } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { ERoutes } from '../../../../../enums/routes.enum';
import { IIssue, ISelectedIssuesPin } from '../../../../../interfaces/map';
import { issueStatusesMock } from '../../../../../mockData/issues';
import { setPinAction, setSelectedPinIssueAction } from '../../../../../store/actions/map';
import { useAppDispatch } from '../../../../../store/hooks';
import { getColorIssueMap } from '../../../../../utils/helpers/getColorIssueMap';

interface IProps {
    issue: IIssue;
    pin: ISelectedIssuesPin | null;
}

function IssueItem({ issue, pin }: IProps) {
    const dispatch = useAppDispatch();

    const [isActive, setIsActive] = useState<boolean>(false);

    useEffect(() => {
        if (pin && pin.oid === issue.oid) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    }, [pin]);

    const onChangeIsActive = () => {
        if (!issue.address_parts?.long || !issue.address_parts?.lat) return;
        dispatch(setPinAction([issue.address_parts?.long as number, issue.address_parts?.lat as number]));
        setIsActive(true);
        setTimeout(() => {
            dispatch(
                setSelectedPinIssueAction({
                    oid: issue.oid,
                    coordinates: [issue.address_parts?.long as number, issue.address_parts?.lat as number],
                }),
            );
        }, 500);
    };

    return (
        <li
            key={issue.id}
            onClick={onChangeIsActive}
            className={cn('issues__content_item', { isActive })}
        >
            <div
                className="issues__content_icon"
                style={{ backgroundColor: getColorIssueMap(issue) }}
            />
            <div>
                <strong>№ {issue.oid} </strong>
                <Chip
                    label={issueStatusesMock[issue.status]?.title}
                    sx={{
                        fontWeight: '600',
                        backgroundColor: issueStatusesMock[issue.status]?.colorHex,
                        color: issueStatusesMock[issue.status]?.colorText,
                        '&:hover': {
                            backgroundColor: `${issueStatusesMock[issue.status]?.colorHex}`.slice(0, -2) + 99,
                        },
                    }}
                    size={'small'}
                    clickable
                />
            </div>

            <Link
                to={`/${ERoutes.Issues}/${issue.id}`}
                target="_blank"
                className="issues__content_link"
            >
                <span>{issue.address}</span>
            </Link>
            {issue.address_parts && issue.address_parts.long && issue.address_parts.lat ? null : (
                <i>неверные координаты</i>
            )}
        </li>
    );
}

export default IssueItem;
