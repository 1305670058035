import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip } from '@mui/material';
import React from 'react';

import { IIssuesObserve } from '../../../../../interfaces/issuesObserves';
import { getFullName } from '../../../../../utils/helpers/getFullName';

interface IProps {
    observers: IIssuesObserve[] | null;
}

function Observe({ observers }: IProps) {
    return (
        <Tooltip
            arrow
            title={
                <div className="observe-list">
                    {observers?.map(({ id, first_name, last_name }) => {
                        return (
                            <div
                                className="observe-item"
                                key={id}
                            >
                                {getFullName({ first_name, last_name })}
                            </div>
                        );
                    })}
                </div>
            }
        >
            <VisibilityIcon color="primary" />
        </Tooltip>
    );
}

export default Observe;
