import { ECategoriesRatiosFilterActions } from '../../enums/actions.enum';
import { ICategoriesRatiosFilterState, TCategoriesRatiosFilterActions } from '../../interfaces/categoriesRatiosFilter';

const initState: ICategoriesRatiosFilterState = {
    categories: null,
    search: null,
    selectedCategories: null,
    selectedCategoriesForWorkers: null,
};

const initialState = { ...initState };

export const categoriesRatiosFilterReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TCategoriesRatiosFilterActions,
): ICategoriesRatiosFilterState => {
    switch (action.type) {
        case ECategoriesRatiosFilterActions.SetCategoriesFilter:
            return {
                ...state,
                categories: action.payload,
            };
        case ECategoriesRatiosFilterActions.SetSearch:
            return {
                ...state,
                search: action.payload,
            };
        case ECategoriesRatiosFilterActions.SetSelectedCategories:
            return {
                ...state,
                selectedCategories: action.payload,
            };
        case ECategoriesRatiosFilterActions.SetSelectedCategoriesForWorkers:
            return {
                ...state,
                selectedCategoriesForWorkers: action.payload,
            };
        default:
            return state;
    }
};
