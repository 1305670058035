import { workersApi } from '../../../api';
import { EEditWorkerFIOActions } from '../../../enums/actions.enum';
import { TErrors } from '../../../interfaces';
import {
    IFormValuesEditWorkerFIO,
    ISetErrorsEditWorkerFIO,
    ISetFormValuesEditWorkerFIO,
    ISetIsOpenEditWorkerFIO,
    ISetWorkerIdEditWorkerFIO,
} from '../../../interfaces/edit/editWorkerFIO';
import { IWorkerDetail } from '../../../interfaces/workers';

export const setIsOpenEditWorkerFIOAction = (payload: boolean): ISetIsOpenEditWorkerFIO => ({
    type: EEditWorkerFIOActions.SetIsOpen,
    payload,
});

export const setWorkerIdEditWorkerFIOAction = (payload: IWorkerDetail['id'] | null): ISetWorkerIdEditWorkerFIO => ({
    type: EEditWorkerFIOActions.SetWorkerId,
    payload,
});

export const setFormValuesEditWorkerFIOAction = (
    payload: IFormValuesEditWorkerFIO | null,
): ISetFormValuesEditWorkerFIO => ({
    type: EEditWorkerFIOActions.SetFormValues,
    payload,
});

export const setErrorsEditWorkerFIOAction = (payload: TErrors | null): ISetErrorsEditWorkerFIO => ({
    type: EEditWorkerFIOActions.SetErrors,
    payload,
});

export const editWorkerFIOThunk = (workerId: string, formValues: IFormValuesEditWorkerFIO) => {
    return async () => {
        try {
            const { data } = await workersApi.editWorkerFIO(workerId, formValues);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
