/* eslint-disable no-shadow */
export enum EWorkerLicenses {
    CategoryM = 'M',
    CategoryA = 'A',
    CategoryB = 'B',
    CategoryBE = 'BE',
    CategoryC = 'C',
    CategoryCE = 'CE',
    CategoryD = 'D',
    CategoryDE = 'DE',
    CategoryA1 = 'A1',
    CategoryB1 = 'B1',
    CategoryC1 = 'C1',
    CategoryC1E = 'C1E',
    CategoryD1 = 'D1',
    CategoryD1E = 'D1E',
}

export enum EWorkerExperience {
    NoExperience = 'до 1 года',
    OneYear = 'от 1 до 2',
    TwoYears = 'от 2 до 4',
    FourYears = 'от 4 до 6',
    SixYears = 'от 6 до 10',
    TenYears = 'от 10 до 15',
    FifteenYears = 'более 15',
}

export enum EWorkerEducationType {
    HigherProfessional = 'Высшее профессиональное',
    SecondaryProfessional = 'Среднее профессиональное',
    UnfinishedHigher = 'Неоконченное высшее',
    BasicGeneral = 'Основное общее',
    SecondaryGeneral = 'Среднее общее',
}
