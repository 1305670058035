import { fetchServiceById } from 'pages/services/store';
import { AddGroup } from 'static/Icons/AddGroup';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { EllipseGreen } from 'static/Icons/EllipseGreen';
import { useAppDispatch } from 'store/hooks';

import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';

import {
    Badge,
    CategoriesContainer,
    CategoryItem,
    ChildWrapper,
    CloseIconWrapper,
    IconWrapper,
    ExpandIcon,
    Service,
    ServiceWrapper,
    CategoriesWrapper,
    MuiPopover,
    CategoriesBox,
} from './styles';

type TItem = {
    id: string;
    categories: any[];
    children: any[];
    category_ids: any[];
    description: string;
    is_active: boolean;
    is_removed: boolean;
    is_root: boolean;
    name: string;
    parents: unknown;
    service_types: any[];
    created_at: string;
    updated_at: string;
};

type Props = {
    item: TItem;
    setOpen: (value: any) => void;
};

export const ServiceItem: FC<Props> = ({ item, setOpen }) => {
    const dispatch = useAppDispatch();

    const [isExpand, setExpand] = useState(false);
    const [openServices, setOpenServices] = useState<any>(false);
    const [openCategories, setOpenCategories] = useState<any>(false);

    const isServicesShouldOpening = item.service_types?.length > 2;
    const isCategoriesShouldOpening = item.categories?.length > 2;

    const filteredServices = item.service_types?.filter((item, idx) => idx > 1 && item);
    const filteredCategories = item.categories?.filter((item, idx) => idx > 1 && item);

    return (
        <div>
            <Service>
                <ServiceWrapper>
                    {item?.children?.length ? (
                        <ExpandIcon
                            isOpen={isExpand}
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpand((prev) => !prev);
                            }}
                        />
                    ) : null}
                    <EllipseGreen status={item.is_active} />
                    <Typography sx={{ fontSize: '14px' }}>{item.name}</Typography>
                </ServiceWrapper>
                <CategoriesContainer>
                    {item?.service_types?.[0]?.name ? (
                        <CategoriesBox
                            isShouldOpening={isServicesShouldOpening}
                            isActive={openServices}
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenServices(e.currentTarget);
                            }}
                        >
                            <span>{item?.service_types?.[0]?.name}</span>
                            {isServicesShouldOpening ? (
                                <Badge isActive={openServices}>+ {filteredServices?.length}</Badge>
                            ) : null}
                        </CategoriesBox>
                    ) : null}

                    {item?.categories?.[0]?.name ? (
                        <CategoriesBox
                            isShouldOpening={isCategoriesShouldOpening}
                            isActive={openCategories}
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenCategories(e.currentTarget);
                            }}
                        >
                            <span>{item?.categories?.[0]?.name}</span>
                            {isCategoriesShouldOpening ? (
                                <Badge isActive={openCategories}>+ {filteredCategories?.length}</Badge>
                            ) : null}
                        </CategoriesBox>
                    ) : null}

                    {isCategoriesShouldOpening ? (
                        <MuiPopover
                            open={openCategories}
                            anchorEl={openCategories}
                            onClose={() => setOpenCategories(false)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box>
                                <CloseIconWrapper onClick={() => setOpenCategories(false)}>
                                    <CloseIcon />
                                </CloseIconWrapper>
                                <div style={{ color: '#BCBFD1' }}>Категории техники</div>
                                <CategoriesWrapper>
                                    {filteredCategories?.map((item) => {
                                        return <CategoryItem key={item.id}>{item.name}</CategoryItem>;
                                    })}
                                </CategoriesWrapper>
                            </Box>
                        </MuiPopover>
                    ) : null}
                </CategoriesContainer>
                <IconWrapper
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(fetchServiceById(item.id)).then(() => {
                            setOpen(true);
                        });
                    }}
                >
                    <AddGroup />
                </IconWrapper>
            </Service>
            {item?.children?.length
                ? item.children.map((item: any) => {
                      return (
                          <ChildWrapper
                              isOpen={isExpand}
                              key={item.id}
                          >
                              <ServiceItem
                                  setOpen={setOpen}
                                  item={item}
                              />
                          </ChildWrapper>
                      );
                  })
                : null}
        </div>
    );
};
