import { filesApi } from 'api';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import cn from 'classnames';
import { IPhotoFile } from 'interfaces';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import Loader from '../../Loader';
import './ImgInput.scss';

interface IProps {
    name: string;
    onChange: (files: IPhotoFile[] | null) => void;
    /* true = к загрузке доступен только один файл, false = много файлов */
    isAlone?: boolean;
    className?: string;
    initFileResp?: IPhotoFile[];
}

function ImgInput({ name, onChange, isAlone = true, className, initFileResp = [] }: IProps) {
    const [filesResp, setFilesResp] = useState<IPhotoFile[]>(initFileResp);
    const [isLoading, setIsLoading] = useState(false);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            uploadHandler(acceptedFiles[0]);
        },
        [filesResp],
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles: isAlone ? 1 : 10 });

    useEffect(() => {
        if (initFileResp === filesResp) return;
        onChange && onChange(filesResp.length ? filesResp : null);
    }, [filesResp]);

    const deleteFile = (fileId: IPhotoFile['id']) => {
        if (!filesResp) {
            console.error('Error!: !acceptedFiles');
        }
        const foundIndex = filesResp.findIndex((file) => file.id === fileId);
        if (foundIndex === -1) return;
        const newFiles = [...filesResp];
        newFiles.splice(foundIndex, 1);
        setFilesResp(newFiles);
    };

    const uploadHandler = async (file: File) => {
        try {
            setIsLoading(true);
            const blob = new Blob([file]);
            const formData = new FormData();
            formData.append('file', blob);
            const { data } = await filesApi.createFile(formData);
            setFilesResp([...filesResp, data]);
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    };
    /* TODO выяснить api у бэка. Выяснено - Удаление файлов пока не предусмотрено */

    return (
        <div className={cn('upload-files-wrapper', className)}>
            {!!filesResp?.length && (
                <ul>
                    {filesResp.map((file) => (
                        <li
                            key={file.id}
                            className="file-preview"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteFile(file.id);
                                }}
                                className="delete-file"
                            >
                                удалить
                            </button>
                            <div className="img-preview-wrapper">
                                <img
                                    src={file?.url}
                                    alt=""
                                />
                                <div className="file-name">
                                    {file.name}
                                    <div className="file-type">.{file.mimetype.split('/')[1]}</div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
            {isAlone && !!filesResp.length ? null : (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <div className="dropzone-inner">
                        <input {...{ ...getInputProps(), name }} />
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <ControlPointOutlinedIcon
                                fontSize="large"
                                color="primary"
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImgInput;
