import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

export default class servicesPriceListsAPI {
    getServicesComplex(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getServiceComplex(id: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    createServicesComplex(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    editServicesComplex(id: string, data: any) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/${id}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    deleteServicesComplex(id: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
