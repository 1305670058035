import TextField from 'components/inputs/TextField';
import {
    setFormValuesCreateWorkerMoySkladAction,
    setIsOpenCreateWorkerMoySkladAction,
} from 'store/actions/create/createMoySklad';
import { useAppDispatch } from 'store/hooks';
import { errorsCreateMoySkladSelector, isOpenCreateMoySkladSelector } from 'store/selectors/create/createMoySklad';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';
import './CreateWorkerMoySklad.scss';

function CreateWorkerMoySklad() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenCreateMoySkladSelector);
    const errors = useSelector(errorsCreateMoySkladSelector);

    function onClose() {
        dispatch(setIsOpenCreateWorkerMoySkladAction(false));
    }

    function onChangeStoreName(e: React.ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();
        dispatch(setFormValuesCreateWorkerMoySkladAction({ name: e.target.value || null }));
    }

    function onChangeStoreAddress(e: React.ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();
        dispatch(setFormValuesCreateWorkerMoySkladAction({ address: e.target.value || null }));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <div className="close-icon-box">
                    <CloseRoundedIcon
                        sx={{ color: '#CCCCCC', fontSize: '2rem' }}
                        onClick={onClose}
                    />
                </div>
                <Box className="edit-worker-specializations-dialog-title">
                    <Typography sx={{ fontSize: '24px', fontWeight: '600', lineHeight: '28.8px' }}>
                        Cоздать склад
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="edit-worker-fio-form-wrapper">
                    <TextField
                        fullWidth
                        name="name"
                        labelText="Наименование"
                        params={{ margin: 'dense' }}
                        errors={errors}
                        onChange={onChangeStoreName}
                    />
                    <TextField
                        fullWidth
                        name="address"
                        labelText="Адресс"
                        params={{ margin: 'dense' }}
                        errors={errors}
                        onChange={onChangeStoreAddress}
                    />
                </div>
                <FooterForm />
            </DialogContent>
        </Dialog>
    );
}

export default CreateWorkerMoySklad;
