import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { Avatar, Box, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { ERoutes } from '../../../../enums/routes.enum';
import { IWorker } from '../../../../interfaces/workers';
import { WorkerStatusesMock, WorkerVerifyStatusesMock } from '../../../../mockData/workers';
import { dateFormatter } from '../../../../utils/helpers/dateFormatter';
import { getFullName } from '../../../../utils/helpers/getFullName';

interface IProps {
    candidate: IWorker;
}

function ChatRow({ candidate }: IProps) {
    const location = useLocation();

    const isArchivePage: boolean = location.pathname.split('/').includes('archive');

    return (
        <TableRow
            onClick={() => {
                window.open(
                    isArchivePage
                        ? `#/${ERoutes.Archive}/worker/${candidate.id}`
                        : `#/${ERoutes.Workers}/${candidate.id}`,
                    '_blank',
                );
            }}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell>
                {dateFormatter(
                    candidate.created_at,
                    { isDateTime: true },
                    { day: 'numeric', month: 'numeric', year: 'numeric' },
                )}
            </TableCell>
            <TableCell>
                <Box
                    display="flex"
                    alignItems={'center'}
                    gap={1}
                >
                    <Avatar
                        sx={{ width: 24, height: 24 }}
                        src={candidate.profile_photo_file?.url}
                    />
                    {getFullName(candidate)}
                </Box>
            </TableCell>
            <TableCell>{candidate.id}</TableCell>
            <TableCell style={{ maxWidth: 200, overflow: 'hidden' }}>
                {candidate.phone_number.length > 13 ? (
                    <Tooltip
                        title={candidate.phone_number}
                        placement="bottom"
                    >
                        <div>{candidate.phone_number.slice(0, 13)}...</div>
                    </Tooltip>
                ) : (
                    candidate.phone_number
                )}
            </TableCell>
            <TableCell>{candidate.balance} ₽</TableCell>
            <TableCell>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    color={WorkerStatusesMock[candidate.status]?.color}
                >
                    <PanoramaFishEyeIcon
                        style={{
                            width: 11,
                            height: 11,
                            stroke: WorkerStatusesMock[candidate.status]?.color,
                            strokeWidth: 3,
                        }}
                    />
                    {WorkerStatusesMock[candidate.status]?.title}
                </Stack>
            </TableCell>
            <TableCell>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    {WorkerVerifyStatusesMock[candidate.verify_status]?.title ?? '-'}
                </Stack>
            </TableCell>
        </TableRow>
    );
}

export default ChatRow;
