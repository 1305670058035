import { createRef, useEffect, useState } from 'react';

interface IProps {
    className: string;
    isOpen?: boolean;
}

export default function useObserver({ className, isOpen }: IProps) {
    const [isLoadMoreAppointments, setIsLoadMoreAppointments] = useState<boolean | null>(false);
    const [entriesObserver, setEntriesObserver] = useState<IntersectionObserverEntry[] | null>(null);
    const loadMoreAppointmentsElem = createRef<HTMLDivElement>();

    useEffect(() => {
        const observerOptions = {
            root: document.querySelector(className),
            threshold: 1.0,
        };
        const observer = new IntersectionObserver((entries) => {
            setEntriesObserver(entries);
        }, observerOptions);
        loadMoreAppointmentsElem.current && observer.observe(loadMoreAppointmentsElem.current);

        return () => {
            loadMoreAppointmentsElem.current && observer.unobserve(loadMoreAppointmentsElem.current);
        };
    }, [isOpen]);

    return {
        loadMoreAppointmentsElem,
        entriesObserver,
        isLoadMoreAppointments,
        setIsLoadMoreAppointments,
    };
}
