import { EPartnersActions } from '../../enums/actions.enum';
import { IPartnersState, TPartnersActions } from '../../interfaces/partners';

const initState: IPartnersState = {
    partners: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const partnersReducer = (state = initialState, action: TPartnersActions): IPartnersState => {
    switch (action.type) {
        case EPartnersActions.SetPartners:
            return {
                ...state,
                partners: action.payload,
            };
        default:
            return state;
    }
};
