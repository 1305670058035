import { ComponentType } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

interface IProps {
    // eslint-disable-next-line react/no-unused-prop-types
    width: string | number;
    // eslint-disable-next-line react/no-unused-prop-types
    height: string | number;
}

export interface ItemProp<T> {
    el: T;
    index: number;
    onClick?: (index: number) => void;
}

export interface IInfiniteLoaderProps<T> {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    items: T[];
    loadNextPage: (startIndex: number, stopIndex: number) => void;
    Item: ComponentType<ItemProp<T>>;
    itemSize: number;
    onClickItem?: (index: number) => void;
}

function InfiniteLoaderWrapper<T>({
    hasNextPage,
    items,
    loadNextPage,
    Item,
    itemSize,
    onClickItem,
}: IInfiniteLoaderProps<T>) {
    const itemCount = hasNextPage ? items.length + 1 : items.length;
    const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

    // eslint-disable-next-line react/no-unstable-nested-components
    function item({ index, style }: ListChildComponentProps) {
        if (!isItemLoaded(index)) {
            return <div>Loading...</div>;
        }

        return (
            <div style={style}>
                <Item
                    el={items[index]}
                    index={index}
                    onClick={onClickItem}
                />
            </div>
        );
    }

    return (
        <AutoSizer>
            {({ height, width }: IProps) => (
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    loadMoreItems={loadNextPage}
                    itemCount={itemCount}
                >
                    {({ onItemsRendered, ref }) => (
                        <FixedSizeList
                            width={width}
                            height={height}
                            itemCount={itemCount}
                            itemSize={itemSize}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                        >
                            {item}
                        </FixedSizeList>
                    )}
                </InfiniteLoader>
            )}
        </AutoSizer>
    );
}

export default InfiniteLoaderWrapper;
