import { companiesApi, priceListsApi, servicesApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import { Container } from 'pages/services/styles';
import { ActiveRadioChecked } from 'static/Icons/ActiveRadioChecked';
import { InactiveRadioChecked } from 'static/Icons/InactiveRadioChecked';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, Checkbox, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    ButtonsContainer,
    CheckboxContainer,
    CheckboxItem,
    Field,
    FieldsContainer,
    ImportFile,
    StyledPaper,
    SubTitle,
} from './styles';

type Option = { label: string; value: string };

export const CreatePriceListsPage = () => {
    const navigate = useNavigate();

    const [checkValue, setCheckValue] = useState<string>('default');

    const [form, setForm] = useState({
        name: '',
        system_name: '',
        company_id: '',
        service_type_id: '',
        region_id: '',
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const [companies, setCompanies] = useState<Option[]>([]);
    const [serviceTypes, setServiceTypes] = useState<Option[]>([]);
    const [regions, setRegions] = useState<Option[]>([]);

    const fetchAutocompleteData = async () => {
        const [companiesRes, serviceTypesRes, regionsRes] = await Promise.all([
            companiesApi.getCompanies(),
            servicesApi.getServiceTypes(),
            priceListsApi.getRegionality({}),
        ]);

        const mapper = (item: { name: string; id: string }) => ({ label: item.name, value: item.id });

        setCompanies(companiesRes.data.map(mapper));
        setServiceTypes(serviceTypesRes.data.map(mapper));
        setRegions(regionsRes.data.map(mapper));
    };

    useEffect(() => {
        fetchAutocompleteData();
    }, []);

    const createPriceList = async () => {
        priceListsApi
            .createPriceList(form)
            .then(() => {
                navigate(`/${ERoutes.PriceLists}/`);
                enqueueSnackbar('Прайс лист успешно создан', { variant: 'success' });
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.error.message, { variant: 'error' });
            });
    };

    return (
        <MainLayout>
            <Container>
                <Typography
                    fontWeight={700}
                    sx={{ fontSize: '28px' }}
                >
                    Создать прайс-лист
                </Typography>
                <StyledPaper>
                    <div>
                        <FieldsContainer>
                            <Field>
                                <SubTitle>Партнер</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    options={companies}
                                    onChange={(_, option) => {
                                        const { value } = option as Option;

                                        handleChange({
                                            target: { name: 'company_id', value },
                                        } as ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Тип услуги</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    options={serviceTypes}
                                    onChange={(_, option) => {
                                        const { value } = option as Option;

                                        handleChange({
                                            target: { name: 'service_type_id', value },
                                        } as ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Регион</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    options={regions}
                                    onChange={(_, option) => {
                                        const { value } = option as Option;

                                        handleChange({
                                            target: { name: 'region_id', value },
                                        } as ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Системное наименование</SubTitle>
                                <TextField
                                    name="system_name"
                                    onChange={handleChange}
                                    sx={{ width: '392px' }}
                                    size="small"
                                />
                            </Field>
                            <Field>
                                <SubTitle>Наименование</SubTitle>
                                <TextField
                                    name="name"
                                    onChange={handleChange}
                                    sx={{ width: '392px' }}
                                    size="small"
                                />
                            </Field>
                        </FieldsContainer>
                        <CheckboxContainer>
                            <Typography sx={{ marginTop: '30px', fontSize: '20px', fontWeight: 600 }}>
                                Варианты создания
                            </Typography>
                            <CheckboxItem>
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    icon={<InactiveRadioChecked />}
                                    checkedIcon={<ActiveRadioChecked />}
                                    checked={checkValue === 'withoutServices'}
                                    onClick={() => setCheckValue('withoutServices')}
                                />
                                <span>Создать без услуг</span>
                            </CheckboxItem>
                            <CheckboxItem>
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    icon={<InactiveRadioChecked />}
                                    checkedIcon={<ActiveRadioChecked />}
                                    checked={checkValue === 'servicesMatchType'}
                                    onClick={() => setCheckValue('servicesMatchType')}
                                />
                                <span>Создать с услугами, которые подходят по типу</span>
                            </CheckboxItem>
                            <CheckboxItem>
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    icon={<InactiveRadioChecked />}
                                    checkedIcon={<ActiveRadioChecked />}
                                    checked={checkValue === 'importFile'}
                                    onClick={() => setCheckValue('importFile')}
                                />
                                <span>Импорт услуг из файла</span>
                                {checkValue === 'importFile' ? (
                                    <ImportFile>
                                        <div>
                                            <div>
                                                <Button variant="outlined">Загрузить файл</Button>
                                                <Button sx={{ color: '#0040F1' }}>Скачать шаблон</Button>
                                            </div>
                                        </div>
                                        <span style={{ color: '#BCBFD1', fontSize: '12px', marginTop: '8px' }}>
                                            Поддерживаемые форматы: XLS, XLSX
                                        </span>
                                    </ImportFile>
                                ) : null}
                            </CheckboxItem>
                        </CheckboxContainer>
                    </div>
                    <ButtonsContainer>
                        <Button
                            variant="contained"
                            onClick={createPriceList}
                        >
                            Создать
                        </Button>
                        <Button variant="outlined">
                            <Link to={`/${ERoutes.PriceLists}/`}>Отмена</Link>
                        </Button>
                    </ButtonsContainer>
                </StyledPaper>
            </Container>
        </MainLayout>
    );
};
