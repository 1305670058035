import { ECreateOrderActions } from '../../../enums/actions.enum';
import { ICreateOrderState, TCreateOrderActions } from '../../../interfaces/create/createOrder';

const initState: ICreateOrderState = {
    formValues: null,
    errors: null,
    isOpenCreateOrder: { isOpen: false, isEdit: false },
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const createOrderReducer = (state = initialState, action: TCreateOrderActions): ICreateOrderState => {
    switch (action.type) {
        case ECreateOrderActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };

        case ECreateOrderActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        case ECreateOrderActions.SetIsOpenCreateOrder:
            return {
                ...state,
                isOpenCreateOrder: action.payload,
            };
        default:
            return state;
    }
};
