import { Button } from '@mui/material';
import React from 'react';

import { setIsOpenEditIssueStatusToMasterAction } from '../../../../../../../store/actions/edit/editIssueStatusToMasterSearch';
import { useAppDispatch } from '../../../../../../../store/hooks';
import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();

    return (
        <div className="buttons-wrapper">
            <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch(setIsOpenEditIssueStatusToMasterAction(false))}
            >
                Ок
            </Button>
        </div>
    );
}

export default FooterForm;
