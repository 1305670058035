import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Addresses from './Addresses';
import Clients from './Clients';
import IssueCodes from './IssueCodes';
import Oids from './Oids';
import SellerCodes from './SellerCodes';

import { EIssueFilter } from '../../../../../enums/search.enum';
import { filtersSearchSelector, itemsSearchSelector } from '../../../../../store/selectors/searchSelectors';
import './SearchItemsStyles.scss';

function SearchItems() {
    const items = useSelector(itemsSearchSelector);
    const searchFilters = useSelector(filtersSearchSelector);
    return (
        <div className="search-items">
            <Typography
                fontSize={14}
                fontWeight={400}
                color="#ADB5BD"
            >
                Результаты поиска ({items?.length})
            </Typography>
            <Box className="items-list">
                {(() => {
                    if (!searchFilters || !items) return null;
                    const { issue_filters } = searchFilters;
                    switch (issue_filters) {
                        case EIssueFilter.Oid:
                            return <Oids items={items} />;
                        case EIssueFilter.Client:
                            return <Clients items={items} />;
                        case EIssueFilter.Address:
                            return <Addresses items={items} />;
                        case EIssueFilter.SellerCode:
                            return <SellerCodes items={items} />;
                        case EIssueFilter.IssueCode:
                            return <IssueCodes items={items} />;
                        default:
                            return null;
                    }
                })()}
            </Box>
        </div>
    );
}

export default SearchItems;
