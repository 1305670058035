import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import EmailModal from './EmailModal';
import SmsModal from './SmsModal';

import { ENotificationStatus, ENotificationType } from '../../../../enums/invoicingNotifications.enum';
import { EIssueStatus } from '../../../../enums/issues.enum';
import {
    createInvoicingNotificationThunk,
    getInvoicingNotificationDataThunk,
    getInvoicingNotificationsThunk,
    setEmailIsOpenINAction,
    setInvoicingNotificationsAction,
    setSmsIsOpenINAction,
} from '../../../../store/actions/invoicingNotifications';
import { useAppDispatch } from '../../../../store/hooks';
import {
    dataINSelector,
    invoicingNotificationsByTypeSelector,
    invoicingNotificationsSelector,
} from '../../../../store/selectors/invoicingNotificationsSelectors';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import theme from '../../../../theme';
import { dateFormatter } from '../../../../utils/helpers/dateFormatter';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import './InvoicingNotifications.scss';

function InvoicingNotifications() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const invoicingNotifications = useSelector(invoicingNotificationsSelector);
    const INByType = useSelector(invoicingNotificationsByTypeSelector);
    const dataIN = useSelector(dataINSelector);

    const [typeLoading, setTypeLoading] = useState<ENotificationType | null>(null);

    const isAccess = useMemo(() => issue?.status === EIssueStatus.PaymentRequired, [issue?.status]);

    useEffect(() => {
        async function handle() {
            try {
                if (!isAccess || !issue) return;
                await dispatch(getInvoicingNotificationDataThunk(issue.id));

                const defaultFilters = {
                    notification_type: [
                        ENotificationType.PushNotification,
                        ENotificationType.Sms,
                        ENotificationType.Email,
                    ],
                };
                await dispatch(
                    getInvoicingNotificationsThunk({
                        issueId: issue.id,
                        filters: defaultFilters,
                    }),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
        return () => {
            dispatch(setInvoicingNotificationsAction(null));
        };
    }, [isAccess, issue, issue?.id]);

    async function createNotification(notificationType: ENotificationType, target: string) {
        try {
            if (!issue || !dataIN || !invoicingNotifications) return;
            setTypeLoading(notificationType);

            const newNotification = {
                issue_id: issue.id,
                confirmation_url: dataIN.confirmation_url,
                message_text: dataIN.message_text,
                notification_type: notificationType,
                target: target || '',
            };
            dispatch(setSmsIsOpenINAction(false));
            dispatch(setEmailIsOpenINAction(false));
            await dispatch(createInvoicingNotificationThunk(newNotification, invoicingNotifications));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setTypeLoading(null);
        }
    }

    function showSmsModal() {
        dispatch(setSmsIsOpenINAction(true));
    }

    function showEmailModal() {
        dispatch(setEmailIsOpenINAction(true));
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {issue?.status !== EIssueStatus.Solved && (
                <div className="invoicing-notifications-wrapper">
                    <Typography
                        fontWeight={400}
                        mb={2}
                    >
                        Уведомление клиенту об оплате
                    </Typography>
                    <div className="notification-btn-item">
                        <div>Пуш</div>
                        <div>
                            <LoadingButton
                                variant="outlined"
                                sx={{ px: 5 }}
                                onClick={() =>
                                    createNotification(ENotificationType.PushNotification, 'user_device_ids')
                                }
                                loading={typeLoading === ENotificationType.PushNotification}
                                disabled={!isAccess || typeLoading === ENotificationType.PushNotification}
                            >
                                Отправить
                            </LoadingButton>
                            {INByType && INByType[ENotificationType.PushNotification]?.length ? (
                                INByType[ENotificationType.PushNotification].map(({ id, created_at, status }) => {
                                    return (
                                        <div
                                            className="notification-date"
                                            key={id}
                                        >
                                            {' '}
                                            {status !== ENotificationStatus.Failed ? (
                                                <DoneIcon color="success" />
                                            ) : (
                                                <CloseIcon color="error" />
                                            )}{' '}
                                            {dateFormatter(created_at, { isDateTime: true })}{' '}
                                        </div>
                                    );
                                })
                            ) : (
                                <Typography
                                    fontSize={16}
                                    color={theme.palette.grey['500']}
                                    mt={2}
                                    fontWeight={500}
                                >
                                    1-е пуш-уведомление отправится автоматически при выставлении счета
                                </Typography>
                            )}
                        </div>
                    </div>
                    <div className="notification-btn-item">
                        <div>По смс</div>
                        <div>
                            <LoadingButton
                                variant="outlined"
                                sx={{ px: 5 }}
                                onClick={showSmsModal}
                                loading={typeLoading === ENotificationType.Sms}
                                disabled={!isAccess || typeLoading === ENotificationType.Sms}
                            >
                                Отправить
                            </LoadingButton>
                            {INByType && INByType[ENotificationType.Sms]?.length ? (
                                INByType[ENotificationType.Sms].map(({ id, created_at, status, target }) => {
                                    return (
                                        <div
                                            className="notification-date"
                                            key={id}
                                        >
                                            {' '}
                                            {status !== ENotificationStatus.Failed ? (
                                                <DoneIcon color="success" />
                                            ) : (
                                                <CloseIcon color="error" />
                                            )}{' '}
                                            {dateFormatter(created_at, { isDateTime: true })}{' '}
                                            <Typography
                                                fontSize={14}
                                                ml={2}
                                                color={theme.palette.grey['500']}
                                            >
                                                {target}
                                            </Typography>
                                        </div>
                                    );
                                })
                            ) : (
                                <Typography
                                    fontSize={16}
                                    color={theme.palette.grey['500']}
                                    mt={2}
                                    fontWeight={500}
                                >
                                    Чтобы отправить уведомление, вам нужно отправить счет на оплату
                                </Typography>
                            )}
                            <SmsModal
                                sendHandler={(formValues) => {
                                    createNotification(ENotificationType.Sms, formValues.target);
                                }}
                            />
                        </div>
                    </div>
                    <div className="notification-btn-item">
                        <div>По почте</div>
                        <div>
                            <LoadingButton
                                variant="outlined"
                                sx={{ px: 5 }}
                                onClick={showEmailModal}
                                loading={typeLoading === ENotificationType.Email}
                                disabled={!isAccess || typeLoading === ENotificationType.Email}
                            >
                                Отправить
                            </LoadingButton>
                            {INByType && INByType[ENotificationType.Email]?.length ? (
                                INByType[ENotificationType.Email].map(({ id, created_at, status, target }) => {
                                    return (
                                        <div
                                            className="notification-date"
                                            key={id}
                                        >
                                            {' '}
                                            {status !== ENotificationStatus.Failed ? (
                                                <DoneIcon color="success" />
                                            ) : (
                                                <CloseIcon color="error" />
                                            )}{' '}
                                            {dateFormatter(created_at, { isDateTime: true })}{' '}
                                            <Typography
                                                fontSize={14}
                                                ml={2}
                                                color={theme.palette.grey['500']}
                                            >
                                                {target}
                                            </Typography>
                                        </div>
                                    );
                                })
                            ) : (
                                <Typography
                                    fontSize={16}
                                    color={theme.palette.grey['500']}
                                    mt={2}
                                    fontWeight={500}
                                >
                                    Чтобы отправить уведомление, вам нужно отправить счет на оплату
                                </Typography>
                            )}
                            <EmailModal
                                sendHandler={(formValues) => {
                                    createNotification(ENotificationType.Email, formValues.target);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default memo(InvoicingNotifications);
