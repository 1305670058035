import { chatResetAction } from 'store/actions/chat';
import { setChatGroupByUserAction } from 'store/actions/chatGroupByUser';
import { getChatGroupByUsersFiltersThunk, setReloadAction } from 'store/actions/chatGroupByUsers';
import { useAppDispatch } from 'store/hooks';
import { shouldReloadSelector } from 'store/selectors/chatGroupByUsersSelectors';

import { Cached } from '@mui/icons-material';
import { Tooltip as MuiTooltip, TooltipProps, styled } from '@mui/material';
import { useSelector } from 'react-redux';

const ReloadButton = styled('button')(({ isActive }: { isActive?: boolean }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: isActive ? '#0040F1' : '#E1E4FA',
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    width: '40px',
    height: '40px',
    color: '#fff',
    cursor: 'pointer',
}));

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip
        {...props}
        classes={{ popper: className }}
    />
))`
    & .MuiTooltip-tooltip {
        background: #0040f1;
        font-size: 12px;
    }

    & .MuiTooltip-arrow {
        color: #0040f1;
    }
`;

export function Reload() {
    const dispatch = useAppDispatch();
    const { shouldReload, reloadCallback } = useSelector(shouldReloadSelector);

    const reload = () => {
        reloadCallback?.();
        dispatch(setReloadAction({ shouldReload: false }));
        dispatch(setChatGroupByUserAction(null));
        dispatch(chatResetAction());
        dispatch(getChatGroupByUsersFiltersThunk({}));
    };

    return (
        <Tooltip
            placement="right"
            title="Есть новые сообщения. Нажмите, чтобы обновить чаты"
            arrow
            open={shouldReload}
        >
            <ReloadButton
                disabled={!shouldReload}
                onClick={reload}
                isActive={shouldReload}
            >
                <Cached sx={{ color: shouldReload ? '#fff' : '#A4A7B8' }} />
            </ReloadButton>
        </Tooltip>
    );
}
