import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import useObserver from '../../../../../hooks/useObserver';
import { IOption } from '../../../../../interfaces';
import {
    getAdditionalPricesPriceDialogThunk,
    setFormValuesPriceDialogAction,
    setSelectedAdditionalPricesPriceDialogAction,
    setSelectedCategoriesPriceDialogAction,
} from '../../../../../store/actions/priceDialog';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    additionalPriceDialogSelector,
    additionalPricesPaginationPriceDialogSelector,
    formValuesPriceDialogSelector,
    pricesOptionsPriceDialogSelector,
    selectedAdditionalPricesPriceDialogSelector,
} from '../../../../../store/selectors/priceDialogSelector';
import { priceSelector } from '../../../../../store/selectors/pricesSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import CustomAutocomplete from '../CustomAutocomplete';
import './AdditionalPrices.scss';

const defaultListSize = 20;

function AdditionalPrices() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesPriceDialogSelector);

    const price = useSelector(priceSelector);
    const prices = useSelector(additionalPriceDialogSelector);
    const pricesOptions = useSelector(pricesOptionsPriceDialogSelector);
    const selectedPrices = useSelector(selectedAdditionalPricesPriceDialogSelector);
    const pagination = useSelector(additionalPricesPaginationPriceDialogSelector);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { isLoadMoreAppointments, entriesObserver, loadMoreAppointmentsElem, setIsLoadMoreAppointments } =
        useObserver({
            className: '.auto-wrapper',
            isOpen,
        });

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(
                    getAdditionalPricesPriceDialogThunk({
                        size: defaultListSize,
                        service_id: price?.service?.id || null,
                    }),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [price]);

    useEffect(() => {
        async function getMoreAppointmentsOnObserve() {
            if (entriesObserver && entriesObserver[0]?.isIntersecting) {
                try {
                    if (
                        prices &&
                        pagination &&
                        price &&
                        pagination?.total > Number(pagination.size * pagination.page) &&
                        isLoadMoreAppointments === false
                    ) {
                        setIsLoadMoreAppointments(true);
                        await dispatch(
                            getAdditionalPricesPriceDialogThunk({
                                prices,
                                service_id: price.service?.id,
                                page: pagination.page + 1,
                                size: defaultListSize,
                            }),
                        );
                    }
                } catch (err) {
                    defaultErrorCallback({ errorMessage: getErrorString({ err }) });
                } finally {
                    setIsLoadMoreAppointments(false);
                }
            }
        }
        getMoreAppointmentsOnObserve();
    }, [entriesObserver]);

    function onSelectPrice(option: IOption<string> | null, checked: boolean) {
        if (!option) return;

        // eslint-disable-next-line no-nested-ternary
        const additionalPricesIds = checked
            ? formValues?.additional_prices
                ? [...formValues.additional_prices, option.value]
                : [option.value]
            : formValues?.additional_prices
            ? formValues.additional_prices.filter((so) => so !== option.value)
            : null;

        // eslint-disable-next-line no-nested-ternary
        const newSelectedAdditionalPrices = checked
            ? selectedPrices
                ? [...selectedPrices, { id: option.value, name: option.label }]
                : [{ id: option.value, name: option.label }]
            : selectedPrices
            ? selectedPrices.filter(({ id }) => id !== option.value)
            : null;

        dispatch(setFormValuesPriceDialogAction({ additional_prices: additionalPricesIds }));
        dispatch(setSelectedAdditionalPricesPriceDialogAction(newSelectedAdditionalPrices));
    }

    async function searchAdditionalPrice(event: React.ChangeEvent<HTMLInputElement>) {
        await dispatch(
            getAdditionalPricesPriceDialogThunk({
                size: defaultListSize,
                service_id: price?.service?.id || null,
                search: event.target.value,
            }),
        );
    }

    function deleteSelectedPrice(id: string) {
        // eslint-disable-next-line no-shadow
        const newSelectedCategories = selectedPrices?.filter((price) => price.id !== id);
        // eslint-disable-next-line no-shadow
        const newCategoryIds = newSelectedCategories?.map((price) => price.id);

        dispatch(setSelectedCategoriesPriceDialogAction(newSelectedCategories || null));
        dispatch(setFormValuesPriceDialogAction({ category_ids: newCategoryIds }));
    }

    return (
        <>
            <CustomAutocomplete
                options={pricesOptions}
                selectedIds={formValues?.additional_prices}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onSearch={searchAdditionalPrice}
                onSelect={onSelectPrice}
                loadMoreAppointmentsElem={loadMoreAppointmentsElem}
                isLoadMoreAppointments={isLoadMoreAppointments}
                label="Дополнительные услуги"
            />
            {!!selectedPrices?.length && (
                <div className="selected-categories-container">
                    {selectedPrices?.map(({ id, name }) => (
                        <div
                            className="selected-category"
                            key={id}
                        >
                            <div className="sub-option-title">{name}</div>
                            <IconButton
                                className="delete-button"
                                onClick={() => deleteSelectedPrice(id)}
                            >
                                <ClearIcon className="delete-button-icon" />
                            </IconButton>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
}

export default memo(AdditionalPrices);
