import { EIssueStatus } from '../../enums/issues.enum';
import { issueCreateOrderAllowed, issueStatusesMock } from '../../mockData/issues';

export const getTitleStatusesAllowedCreateOrder = () => {
    const arrayTitleStatuses = Object.entries(issueStatusesMock).map(([key, value]) => {
        if (issueCreateOrderAllowed.includes(key as EIssueStatus)) {
            return value.title;
        }
        return null;
    });
    return arrayTitleStatuses.filter((str) => !!str).join(', ');
};
