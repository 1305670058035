import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import DatePicker from '../../../../../components/inputs/DatePicker';
import { setFormValuesGenerateXlsAction } from '../../../../../store/actions/generateXls';
import { useAppDispatch } from '../../../../../store/hooks';
import { errorsGenerateXlsSelector } from '../../../../../store/selectors/generateXlsSelectors';

function TimeWindows() {
    const dispatch = useAppDispatch();

    const errors = useSelector(errorsGenerateXlsSelector);

    function changeGteHandler(val: Date | null) {
        dispatch(setFormValuesGenerateXlsAction({ time_window__gte: val }));
    }

    function changeLteHandler(val: Date | null) {
        dispatch(setFormValuesGenerateXlsAction({ time_window__lte: val }));
    }

    return (
        <div className="time-windows-wrapper">
            <Grid
                container
                spacing={2}
                alignItems="center"
            >
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <DatePicker
                        name="time_window__gte"
                        placeholder="ДД.ММ.ГГГГ"
                        fullWidth
                        errors={errors}
                        onChange={changeGteHandler}
                    />
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                >
                    <DatePicker
                        name="time_window__lte"
                        placeholder="ДД.ММ.ГГГГ"
                        fullWidth
                        errors={errors}
                        onChange={changeLteHandler}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default TimeWindows;
