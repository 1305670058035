import ErrorIcon from '@mui/icons-material/Error';
import { Box } from '@mui/material';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import EditIssueAppointment from './EditIssueAppointment';
import EditIssueCode from './EditIssueCode';

import Accordion from '../../../../components/Accordion';
import { EIssueHelpType } from '../../../../enums/issues.enum';
import { issueAddressEditingAllowed, issueDateEditingAllowed } from '../../../../mockData/issues';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import { timeWindowsFormatter } from '../../../../utils/helpers/timeWindowsFormatter';
import EditIssueAddress from '../IssueAddress/EditIssueAddress';
import './IssueAddress.scss';

function IssueAddress() {
    const issue = useSelector(issueSelector);

    const { region, codes } = issue || {};

    return issue ? (
        <Accordion
            title="Адрес и дата вызова"
            sx={{ mt: 3 }}
        >
            <div className="iss-ad-list">
                <div className="iss-ad-item">
                    <div>Дата вызова</div>
                    {issueDateEditingAllowed.includes(issue.status) ? (
                        <EditIssueAppointment />
                    ) : (
                        <div>{timeWindowsFormatter(issue?.time_windows)}</div>
                    )}
                </div>
                <div className="iss-ad-item">
                    <div>
                        Номер заказа <br />
                        партнера
                    </div>
                    <EditIssueCode />
                </div>
                {!!codes?.code && (
                    <div className="iss-ad-item">
                        <div>Код менеджера</div>
                        <div>{codes.code}</div>
                    </div>
                )}
                {!!region && (
                    <div className="iss-ad-item">
                        <div>Регион</div>
                        <div>{region}</div>
                    </div>
                )}
                <Box
                    className="iss-ad-item"
                    sx={{ alignItems: 'center' }}
                >
                    <Box display="flex">
                        Адрес вызова{' '}
                        {issue?.help_type !== EIssueHelpType.Consultation &&
                            issue?.help_type !== EIssueHelpType.Video &&
                            (!issue.address_parts?.lat || !issue.address_parts?.long) && (
                                <ErrorIcon
                                    fontSize="small"
                                    color="warning"
                                />
                            )}
                    </Box>

                    {issueAddressEditingAllowed.includes(issue.status) ? (
                        <EditIssueAddress />
                    ) : (
                        <div>{issue.address}</div>
                    )}
                </Box>
            </div>
        </Accordion>
    ) : null;
}

export default memo(IssueAddress);
