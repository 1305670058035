import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { ICompany } from '../interfaces/companies';

export default class CompaniesAPI {
    getCompanies(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<ICompany[]>;
    }
}
