import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import MasterSmallData from '../../../../../components/MasterSmallData';
import { EIssueTriggers } from '../../../../../enums/issues.enum';
import { ICandidateIssue } from '../../../../../interfaces/issues';
import { putIssueThunk } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import theme from '../../../../../theme';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import { getFullName } from '../../../../../utils/helpers/getFullName';

interface IProps {
    candidate: ICandidateIssue;
}

function Candidate({ candidate }: IProps) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

    async function acceptWorkerHandel() {
        try {
            if (!issue || !candidate) return;

            let time_window_id;

            if (issue.time_windows.length) {
                const times = issue.time_windows[issue.time_windows.length - 1].time;
                if (!times) {
                    console.error(
                        'Error! !issue.time_windows[issue.time_windows.length - 1].time acceptWorkerHandel()',
                    );
                    return;
                }

                time_window_id = times[times.length - 1].id;
            }

            setIsBtnLoading(true);

            await dispatch(
                putIssueThunk({
                    issueId: issue.id,
                    newIssue: { worker_id: candidate?.worker?.id, time_window_id },
                    issueTrigger: EIssueTriggers.Accept,
                }),
            );
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsBtnLoading(false);
        }
    }

    function handleClose() {
        setIsOpen(false);
    }

    return (
        <div className="iss-cndts-item">
            <MasterSmallData candidate={candidate} />
            <Button
                onClick={() => setIsOpen(true)}
                variant="text"
                size="small"
            >
                Назначить
            </Button>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <div className="close-icon">
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TuneIcon sx={{ mr: 2, color: theme.palette.success.main }} />
                        Назначение мастера
                    </Box>
                </DialogTitle>
                <DialogContent>
                    Вы действительно хотите назначить мастера:{' '}
                    <Box sx={{ fontWeight: 600, display: 'inline' }}>
                        {candidate.worker && getFullName(candidate.worker)}
                    </Box>{' '}
                    ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isBtnLoading}
                        disabled={isBtnLoading}
                        onClick={acceptWorkerHandel}
                        variant="contained"
                    >
                        Назначить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Candidate;
