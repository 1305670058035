/* eslint-disable no-shadow */

export const enum EDayOfWeek {
    SUNDAY = 'SU',
    MONDAY = 'MO',
    TUESDAY = 'TU',
    WEDNESDAY = 'WE',
    THURSDAY = 'TH',
    FRIDAY = 'FR',
    SATURDAY = 'SA',
}
