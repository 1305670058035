import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import CreateOrderForm from './CreateOrderForm';

import {
    setErrorsCOAction,
    setFormValuesCOAction,
    setIsOpenCOAction,
} from '../../../../../store/actions/create/createOrder';
import { setOrderAction } from '../../../../../store/actions/orders';
import { getPartsThunk, setPartFiltersAction } from '../../../../../store/actions/parts';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    homeDevicesOptionsCOSelector,
    isOpenCOSelector,
} from '../../../../../store/selectors/create/createOrderSelectors';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import { orderSelector } from '../../../../../store/selectors/ordersSelectors';
import { partFiltersSelector } from '../../../../../store/selectors/partsSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

function CreateOrder() {
    const dispatch = useAppDispatch();
    const { isEdit, isOpen } = useSelector(isOpenCOSelector);
    const issue = useSelector(issueSelector);
    const order = useSelector(orderSelector);
    const filters = useSelector(partFiltersSelector);
    const homeDevicesOptions = useSelector(homeDevicesOptionsCOSelector);

    useEffect(() => {
        if (!isOpen) {
            dispatch(setOrderAction(null));
        }
    }, [isOpen]);

    useEffect(() => {
        if (isEdit && order) {
            const category_id__in = homeDevicesOptions?.find(
                (option) => option.value === order?.sub_issue_id,
            )?.category_id;
            dispatch(
                setFormValuesCOAction({
                    sub_issue_id: order?.sub_issue_id,
                    device_failure_photo: order?.device_failure_photo || null,
                    device_failure_photo_id: order?.device_failure_photo_id,
                    quantity: order?.quantity,
                    spare_part_id: order?.spare_part.id,
                    is_client_pick_up: order?.is_client_pick_up,
                }),
            );
            dispatch(setPartFiltersAction({ category_id__in: category_id__in ? [category_id__in] : null }));
        }
    }, [isEdit, order]);

    function onCloseHandler() {
        dispatch(setIsOpenCOAction({ isEdit: false, isOpen: false }));
    }

    useEffect(() => {
        if (!isOpen) {
            dispatch(setFormValuesCOAction(null));
            dispatch(setErrorsCOAction(null));
            dispatch(setPartFiltersAction(null));
            if (!issue) return;
            dispatch(setFormValuesCOAction({ issue }));
        }
    }, [isOpen, issue]);

    useEffect(() => {
        async function handle() {
            try {
                if (isEdit && filters) {
                    await dispatch(getPartsThunk({ filters, page: 0, size: 20 }));
                } else if (isOpen && !isEdit) {
                    await dispatch(getPartsThunk({ filters, page: 0, size: 20 }));
                }
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, [filters, isOpen, isEdit]);

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="new-issue-dialog-title">
                    <div
                        className="close-icon"
                        style={{ position: 'absolute', right: '15px', cursor: 'pointer' }}
                    >
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">{isEdit ? 'Редактирование заказа' : 'Добавление заказа'}</Typography>
                </Box>
                <DialogContent>
                    <CreateOrderForm />
                </DialogContent>
            </DialogTitle>
        </Dialog>
    );
}

export default CreateOrder;
