import { TablePagination } from '@mui/material';
import React from 'react';

import { IPagination } from '../../../../interfaces/pagination';
import './IssuesPagination.scss';

interface IProps {
    isLoading: boolean;
    pagination: IPagination;
    changePagination: (newPagination: IPagination) => void;
}

function IssueTablePagination({ isLoading, pagination, changePagination }: IProps) {
    function changePageHandler(e: React.MouseEvent<HTMLButtonElement> | null, page: number) {
        pagination && changePagination({ ...pagination, page });
    }

    function changeSizeHandler(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const size = Number(e.target.value);
        pagination && size && changePagination({ ...pagination, size });
    }

    return pagination ? (
        <TablePagination
            className="issues-table-pagination"
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={pagination.total}
            rowsPerPage={pagination.size}
            page={pagination.page}
            onPageChange={changePageHandler}
            onRowsPerPageChange={changeSizeHandler}
            backIconButtonProps={
                isLoading
                    ? {
                          disabled: true,
                      }
                    : undefined
            }
            nextIconButtonProps={
                isLoading
                    ? {
                          disabled: true,
                      }
                    : undefined
            }
        />
    ) : null;
}

export default IssueTablePagination;
