import { issuesApi } from 'api';
import Loader from 'components/Loader';
import { useIntersection } from 'hooks/useIntersection';
import { chatGroupByUserSelector } from 'store/selectors/chatGroupByUserSelectors';
import { chatsSelector } from 'store/selectors/chatsInfiniteSelectors';

import { Divider, styled } from '@mui/material';
import { IIssue } from 'interfaces/issues';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import IssueHistoryItem from '../../chat/components/IssueHistoryItem';

export function IssueHistory() {
    const chats = useSelector(chatsSelector);
    const user = useSelector(chatGroupByUserSelector);

    const [issues, setIssues] = useState<IIssue[]>([]);
    const [isLoading, setLoading] = useState(true);

    const authorId = { [`${user?.user_type}_id`]: user?.user?.id };

    const page = useRef(1);
    const total = useRef(0);

    const onViewInter = () => {
        if (total.current === issues.length) return;

        page.current++;

        issuesApi
            .getIssueHistory(authorId, {
                page: page.current,
                size: 50,
                order_by: '-created_at',
                with_paginate: true,
            })
            .then(({ data }) => setIssues((prev) => [...prev, ...data.items]))
            .finally(() => setLoading(false));
    };

    const [ref] = useIntersection({ rootId: 'historyInfinity', onViewInter }, [issues.length, total.current]);

    useEffect(() => {
        if (issues.length) return;

        setLoading(true);

        issuesApi
            .getIssueHistory(authorId, { page: 1, size: 50, order_by: '-created_at', with_paginate: true })
            .then(({ data }) => {
                setIssues(data.items);
                total.current = data.total;
            })
            .finally(() => setLoading(false));
    }, [user]);

    if (isLoading) {
        return (
            <div style={{ position: 'relative', height: '100%' }}>
                <Loader />
            </div>
        );
    }

    if (!chats) {
        return (
            <div className="choose_chat">
                <span className="text">Выберите пользователя</span>
            </div>
        );
    }

    return (
        <Wrapper id="historyInfinity">
            <HeaderWrapper>
                <Title>История заявок</Title>
            </HeaderWrapper>
            <Divider />
            <BodyWrapper>
                <div>
                    {issues.map((issue) => (
                        <IssueHistoryItem
                            key={issue.id}
                            issue={issue}
                        />
                    ))}
                    <div ref={ref} />
                </div>
            </BodyWrapper>
        </Wrapper>
    );
}

const Wrapper = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

const HeaderWrapper = styled('div')({
    padding: '8px 16px',
});

const BodyWrapper = styled('div')({
    padding: '8px 16px',
    overflowY: 'auto',
});

const Title = styled('div')({
    color: '#161C46',
    fontWeight: 'bold',
    fontSize: '20px',
});
