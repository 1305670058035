import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EditGroupForm from './EditGroupForm';

import { setIsOpenEditGroupAction } from '../../../store/actions/groups';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenEditGroupSelector } from '../../../store/selectors/groupsSelector';

function EditGroup() {
    const dispatch = useAppDispatch();

    const isOpenEditGroup = useSelector(isOpenEditGroupSelector);

    const handleClose = () => {
        dispatch(setIsOpenEditGroupAction(false));
    };

    return (
        <Dialog
            open={isOpenEditGroup}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
        >
            <DialogTitle>
                <Box className="new-group-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={handleClose} />
                    </div>
                    <DriveFileRenameOutlineOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Редактировать группу мастеров</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditGroupForm />
            </DialogContent>
        </Dialog>
    );
}

export default EditGroup;
