import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import EditWorkerSpecializationsForm from './EditWorkerSpecializationsForm';

import { setIsOpenEditWorkerSpecializationsAction } from '../../../../store/actions/edit/editWorkerSpecializations';
import { useAppDispatch } from '../../../../store/hooks';
import { isOpenEditWorkerSpecializationsSelector } from '../../../../store/selectors/edit/editWorkerSpecializationsSelectors';
import './EditWorkerSpecializations.scss';

function EditWorkerSpecializations() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenEditWorkerSpecializationsSelector);

    function onClose() {
        dispatch(setIsOpenEditWorkerSpecializationsAction(false));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <Box className="edit-worker-specializations-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                    <div className="title-text">Добавить категорию</div>
                </Box>
            </DialogTitle>
            <DialogContent>
                <EditWorkerSpecializationsForm />
            </DialogContent>
        </Dialog>
    );
}

export default EditWorkerSpecializations;
