import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ companies }: RootState) => companies;

export const companiesSelector = createSelector(state, ({ companies }) => companies);
export const companiesOptionsSelector = createSelector(state, ({ companies }) => {
    return (
        companies?.map((company) => {
            return { value: company.partner_id, label: company.name || '' };
        }) || null
    );
});
