import { EEditWorkerServiceAreasActions } from '../../../enums/actions.enum';
import {
    IEditWorkerServiceAreasState,
    TEditWorkerServiceAreasActions,
} from '../../../interfaces/edit/editWorkerServiceAreas';

const initState: IEditWorkerServiceAreasState = {
    isOpen: false,
    workerId: null,
    formValues: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const editWorkerServiceAreasReducer = (
    state = initialState,
    action: TEditWorkerServiceAreasActions,
): IEditWorkerServiceAreasState => {
    switch (action.type) {
        case EEditWorkerServiceAreasActions.SetIsOpen:
            return {
                ...state,
                isOpen: action.payload,
            };
        case EEditWorkerServiceAreasActions.SetWorkerId:
            return {
                ...state,
                workerId: action.payload,
            };
        case EEditWorkerServiceAreasActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        default:
            return state;
    }
};
