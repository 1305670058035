import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { ICreateIssueReminderReq, ICreateIssueReminderResp } from '../interfaces/create/createIssueReminder';
import { IEditIssueReminderReq } from '../interfaces/edit/editReminder';
import { IIssue } from '../interfaces/issues';
import { IReminder, TRemindersResp } from '../interfaces/reminder';

export default class RemindersAPI {
    /* Получение напомнинаний по заявке */
    getIssueReminders(issueId: IIssue['id'], queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/reminders/issues/${issueId}${
                queries || ''
            }`,
            isAuthorization: true,
        }) as AxiosPromise<TRemindersResp>;
    }

    /* Получение напомнинания */
    getIssueReminder(reminderId: IReminder['id']) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/reminders/${reminderId}`,
            isAuthorization: true,
        }) as AxiosPromise<IReminder>;
    }

    /* Создание напомнинания */
    createIssueReminder(issueId: IIssue['id'], data: ICreateIssueReminderReq) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/reminders/issues/${issueId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<ICreateIssueReminderResp>;
    }

    /* Удаление напомнинания */
    deleteIssueReminder(reminderId: IReminder['id']) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/reminders/${reminderId}`,
            isAuthorization: true,
        }) as AxiosPromise;
    }

    /* Редактирование напомнинания */
    editIssueReminder(reminderId: IReminder['id'], data: IEditIssueReminderReq) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/reminders/${reminderId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise;
    }
}
