import { Box, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import EditServiceButton from './EditPriceButton';

import { ERoutes } from '../../../../../enums/routes.enum';
import { IPriceService } from '../../../../../interfaces/pricesServices';
import { setPricesFiltersAction } from '../../../../../store/actions/prices';
import { useAppDispatch } from '../../../../../store/hooks';

function ServiceRow(service: IPriceService) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { id, name } = service;

    const goToServicePage = () => {
        dispatch(setPricesFiltersAction({ service_id__in: id }));
        navigate(`/${ERoutes.PriceServices}/${id}`);
    };

    return (
        <TableRow
            onClick={goToServicePage}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell>{name}</TableCell>
            <TableCell>
                <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <EditServiceButton service={service} />
                </Box>
            </TableCell>
        </TableRow>
    );
}

export default ServiceRow;
