import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import Directions from './Directions';

import useOutsideClick from '../../hooks/useOutsideClick';
import { TCategoryPreset } from '../../interfaces/issues';
import './DirectionsFilter.scss';

interface IProps {
    selectedCategoryPreset: TCategoryPreset | undefined | null;
    onChange: (categoryPreset: TCategoryPreset | null) => void;
}

function DirectionsFilter({ selectedCategoryPreset, onChange }: IProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        if (!selectedCategoryPreset) return;
        // eslint-disable-next-line consistent-return
        return () => {
            onChange(null);
        };
    }, [selectedCategoryPreset]);

    return (
        <div
            ref={wrapperRef}
            className={cn('directions-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant={selectedCategoryPreset ? 'filterActive' : 'filter'}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                endIcon={<ExpandMoreIcon />}
            >
                Направления{' '}
            </Button>
            <Directions
                onChange={onChange}
                selectedCategoryPreset={selectedCategoryPreset}
            />
        </div>
    );
}

export default DirectionsFilter;
