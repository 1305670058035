import { workersApi } from 'api';
import { Button } from 'components/Button';
import FilterDate from 'components/FilterDate';
import { FilterIn } from 'features/filter-in';
import { useAppDispatch } from 'store/hooks';

import { styled } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { CorrectionModal } from './components/modals/correction';
import { Table } from './components/table';
import { fetchTransactions, setFilters } from './store';
import { transactionsSelector } from './store/selectors';

const filterItems = [
    { id: 'Сегодня', title: 'Сегодня' },
    { id: 'Вчера', title: 'Вчера' },
    { id: 'Период', title: 'Период' },
];

export const Transactions = () => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);

    const { transactions, loading, filters, pagination } = useSelector(transactionsSelector);

    const handleWorkerIdsChange = (itemId: string) => {
        const getIds = () => {
            if (filters.worker_id__in.includes(itemId)) {
                return filters.worker_id__in.filter((id) => id !== itemId);
            } else {
                return [...filters.worker_id__in, itemId];
            }
        };

        dispatch(setFilters({ worker_id__in: getIds() }));
        dispatch(fetchTransactions());
    };

    const handleFilterInClear = (key: string) => {
        dispatch(setFilters({ [key]: [] }));
        dispatch(fetchTransactions());
    };

    const handleTransactionCreatedDateChange = (date: { startTime: string | null; endTime: string | null }) => {
        dispatch(
            setFilters({
                created_at__gte: date.startTime?.split('T')[0],
                created_at__lte: date.endTime?.split('T')[0],
            }),
        );
        dispatch(fetchTransactions());
    };

    useLayoutEffect(() => {
        dispatch(
            setFilters({
                created_at__lte: '',
                created_at__gte: '',
            }),
        );
    }, []);

    useEffect(() => {
        dispatch(fetchTransactions());
    }, []);

    return (
        <>
            <Toolbar>
                <FilterDate
                    title="Дата создания"
                    filterItems={filterItems}
                    onChange={handleTransactionCreatedDateChange}
                />
                <FilterIn
                    title="Мастер"
                    onChange={handleWorkerIdsChange}
                    activeIds={filters.worker_id__in}
                    fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                    cleaner={() => handleFilterInClear('worker_id__in')}
                />

                <Button
                    onClick={() => setOpen(true)}
                    sx={{ marginLeft: 'auto' }}
                    variant="contained"
                >
                    Корректировка
                </Button>
            </Toolbar>

            <Table
                loading={loading}
                rows={transactions}
                pagination={pagination}
            />
            <CorrectionModal
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const Toolbar = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    columnGap: '16px',
});
