import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ categoriesRatiosFilter }: RootState) => categoriesRatiosFilter;

/* CategoriesRatiosFilter === сокращение CRF */
export const categoriesCRFSelector = createSelector(state, ({ categories }) => categories);
export const searchCRFSelector = createSelector(state, ({ search }) => search);
/* Подписаться на categoriesCRFSelector и делать выборку здесь нельзя т.к. будет лишний рекурсивный обход categories */
export const selectedCRFSelector = createSelector(state, ({ selectedCategories }) => selectedCategories);
export const selectedCRFForWorkersSelector = createSelector(
    state,
    ({ selectedCategoriesForWorkers }) => selectedCategoriesForWorkers,
);
export const searchingCRFSelector = createSelector(
    [categoriesCRFSelector, searchCRFSelector],
    (categoriesRatios, searchVal) => {
        // TODO спросить у бэка по каким искать
        if (!searchVal || !categoriesRatios) return null;

        return categoriesRatios.filter(({ value, name, title, subtitle, init_value }) => {
            return `${value}${name}${title}${subtitle}${init_value}`.toLowerCase().includes(searchVal.toLowerCase());
        });
    },
);
