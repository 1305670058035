import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Paper } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { IPartner } from '../../../interfaces/partners';
import { partnersSelector } from '../../../store/selectors/partnersSelectors';
import Header from '../../GroupFilter/Groups/Header';
import './Partners.scss';

interface IProps {
    selectedPartner: IPartner['id'] | null;
    onChange: (selectedPartnerId: IPartner['id'] | null) => void;
}

function Partners({ selectedPartner, onChange }: IProps) {
    const partners = useSelector(partnersSelector);

    function clearFilter() {
        onChange(null);
    }

    return (
        <Paper className="partners-wrapper">
            <Header />
            <div className="scroll-wrapper">
                {partners
                    ? partners.map((partner) => (
                          <Box
                              key={partner.id}
                              onClick={() => onChange(partner.id)}
                              className="partners-container"
                          >
                              <Box className="partner">
                                  <Box>{partner.name}</Box>
                                  {selectedPartner === partner.id && <CheckIcon />}
                              </Box>
                          </Box>
                      ))
                    : 'Партнеры не найдены'}
            </div>
            <Button
                sx={{
                    padding: '10px',
                }}
                onClick={() => clearFilter()}
                fullWidth
            >
                Очистить фильтр
            </Button>
        </Paper>
    );
}

export default Partners;
