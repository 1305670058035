import { servicesApi } from 'api';
import { chooseDelete, fetchCategories, fetchServices, fetchServiceTypes } from 'pages/services/store';
import { Cancel } from 'static/Icons/Cancel';
import { Confirm } from 'static/Icons/Confirm';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    ButtonsContainer,
    ButtonsWrapper,
    CheckBox,
    CheckBoxWrapper,
    CheckTitle,
    MuiTextField,
    SubTitle,
} from './styles';

import { FootNote } from '../../footnote';
import { DeleteService } from '../../modals/delete-service';
import { Cell, Field, HeadCell, MuiAutocomplete, Asterisk, FieldContainer, PlusButton } from '../styles';

type TOptions = {
    label: string;
    value: string;
};

type TForm = {
    parents: string[];
    is_root: boolean;
    children: string[];
    description: string;
    name: string;
    service_types: string[];
    categories: string[];
    is_active: boolean;
};

const initialStateForm: TForm = {
    parents: [],
    is_root: true,
    children: [],
    description: '',
    name: '',
    service_types: [],
    categories: [],
    is_active: false,
};

type Props = {
    setOpen: (value: boolean) => void;
};

export const Basics: FC<Props> = ({ setOpen }) => {
    const dispatch = useAppDispatch();

    const { service_types, categories, services, serviceById } = useSelector(servicesSelector);

    const [isOpenDelete, setIsOpenDelete] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [childServices, setChildServices] = useState<any[]>([]);
    const [addChildService, setAddChildService] = useState<any>();

    const [hasParent, setHasParent] = useState<boolean>(false);

    const [form, setForm] = useState(initialStateForm);

    const handleConfirm = () => {
        setChildServices((prev) => [...prev, addChildService]);
        setAddChildService({});
        setVisible(false);
    };

    const handleCancel = () => {
        setAddChildService({});
        setVisible(false);
    };

    const handleDelete = (id: string) => {
        dispatch(chooseDelete(id));
    };

    const handleCreateService = () => {
        servicesApi
            .createService(form)
            .then(() => {
                enqueueSnackbar('Успешно', { variant: 'success' });
                dispatch(fetchServices());
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    useEffect(() => {
        setForm((prev: any) => {
            return { ...prev, children: childServices };
        });
    }, [childServices]);

    useEffect(() => {
        dispatch(fetchServiceTypes());
        dispatch(fetchCategories());
    }, []);

    const optionsTypes: TOptions[] = service_types.map((item) => ({ label: item.name, value: item.id }));

    const optionsServices: TOptions[] = services.map((item) => ({ label: item.name, value: item.id }));

    const optionsCategories: TOptions[] = categories?.items?.map((item: any) => ({ label: item.name, value: item.id }));

    const inputRef = useRef<TOptions[]>([]);

    useEffect(() => {
        setForm((prev: any) => {
            return { ...prev, children: childServices };
        });
    }, [childServices]);

    useEffect(() => {
        dispatch(fetchServiceTypes());
        dispatch(fetchCategories());
        if (serviceById) {
            inputRef.current = [{ label: serviceById?.name, value: serviceById?.id }];
            setForm((prev: any) => {
                return { ...prev, parents: [serviceById?.id] };
            });
        }
    }, []);

    return (
        <Box>
            <FieldContainer>
                <div>
                    <Field>
                        <SubTitle>
                            Родитель<Asterisk>*</Asterisk>
                        </SubTitle>
                        <MuiAutocomplete
                            multiple
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={optionsServices ?? []}
                            isOptionEqualToValue={(option, value) => option === value}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Выбрать"
                                />
                            )}
                            onChange={(_, value) => {
                                if (hasParent) return;
                                const options = value as TOptions[];

                                inputRef.current = options;

                                const parents = options?.map((option) => option.value);

                                setForm((prev: any) => {
                                    return { ...prev, parents };
                                });
                            }}
                            disabled={hasParent}
                            value={inputRef.current}
                        />
                    </Field>
                    <Field sx={{ marginTop: '10px' }}>
                        <SubTitle />
                        <CheckBox
                            onClick={() => {
                                inputRef.current = [];
                                setForm((prev: any) => {
                                    return { ...prev, parents: [] };
                                });
                                setHasParent(!hasParent);
                            }}
                        />
                        <CheckTitle>Нет родителя</CheckTitle>
                    </Field>
                </div>
                <Field>
                    <SubTitle>
                        Наименование<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField
                        onChange={(e) =>
                            setForm((prev: any) => {
                                return { ...prev, name: e.target.value };
                            })
                        }
                        size="small"
                    />
                </Field>
                <Field>
                    <SubTitle>
                        Описание<Asterisk>*</Asterisk>
                    </SubTitle>
                    <MuiTextField
                        multiline
                        placeholder="Какое-то описание"
                        minRows={4}
                        onChange={(e) =>
                            setForm((prev: any) => {
                                return { ...prev, description: e.target.value };
                            })
                        }
                    />
                </Field>
                <Field>
                    <SubTitle>Категории</SubTitle>
                    <MuiAutocomplete
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={optionsCategories ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Категории"
                            />
                        )}
                        onChange={(_, value) => {
                            const options = value as TOptions[];

                            const categories = options?.map((option) => option.value);

                            setForm((prev: any) => {
                                return {
                                    ...prev,
                                    categories,
                                };
                            });
                        }}
                    />
                </Field>
                <Field>
                    <SubTitle>Типы услуг</SubTitle>
                    <MuiAutocomplete
                        multiple
                        size="small"
                        popupIcon={<ExpandMoreIcon />}
                        options={optionsTypes ?? []}
                        isOptionEqualToValue={(option, value) => option === value}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="Типы услуг"
                            />
                        )}
                        onChange={(_, value) => {
                            const options = value as TOptions[];

                            const service_types = options?.map((option) => option.value);

                            setForm((prev: any) => {
                                return {
                                    ...prev,
                                    service_types,
                                };
                            });
                        }}
                    />
                </Field>
                <Field sx={{ marginTop: '10px' }}>
                    <SubTitle>Активная</SubTitle>
                    <CheckBoxWrapper>
                        <CheckBox
                            onChange={() =>
                                setForm((prev: any) => {
                                    return { ...prev, is_active: !prev.is_active };
                                })
                            }
                        />
                        <FootNote />
                    </CheckBoxWrapper>
                </Field>
            </FieldContainer>
            <Box sx={{ marginTop: '28px' }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Дочерние услуги</Typography>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <HeadCell sx={{ width: '450px' }}>НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                        <HeadCell sx={{ width: '450px' }}>ВКЛЮЧАТЬ В СТОИМОСТЬ</HeadCell>
                        <HeadCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {childServices.map((item) => {
                        return (
                            <TableRow key={item.id}>
                                <Cell>{optionsServices.find((option) => option.value === item.id)?.label}</Cell>
                                <Cell>
                                    <Checkbox checked={item.is_include_in_price} />
                                </Cell>
                                <Cell>
                                    <Button
                                        onClick={() => {
                                            handleDelete(item.id);
                                            setIsOpenDelete(true);
                                        }}
                                    >
                                        <DeleteBasket />
                                    </Button>
                                </Cell>
                            </TableRow>
                        );
                    })}
                    <DeleteService
                        onClose={setIsOpenDelete}
                        setChildServices={setChildServices}
                        isOpen={isOpenDelete}
                    />
                    {isVisible ? (
                        <TableRow>
                            <Cell>
                                <Autocomplete
                                    size="small"
                                    sx={{ width: '260px' }}
                                    popupIcon={<ExpandMoreIcon />}
                                    options={optionsServices ?? []}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выберите услугу"
                                        />
                                    )}
                                    onChange={(_, value) => {
                                        setAddChildService({ id: value?.value, is_include_in_price: false });
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <Checkbox
                                    onChange={(e) => {
                                        setAddChildService((prev: any) => {
                                            return { ...prev, is_include_in_price: e.target.checked };
                                        });
                                    }}
                                />
                            </Cell>
                            <Cell>
                                <ButtonsContainer>
                                    <div onClick={handleConfirm}>
                                        <Confirm />
                                    </div>
                                    <div onClick={handleCancel}>
                                        <Cancel />
                                    </div>
                                </ButtonsContainer>
                            </Cell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <PlusButton
                                onClick={() => setVisible(true)}
                                startIcon={<AddIcon />}
                            >
                                Добавить услугу
                            </PlusButton>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <ButtonsWrapper>
                <Button
                    onClick={() => setOpen(false)}
                    color="error"
                >
                    Отмена
                </Button>
                <Button
                    onClick={handleCreateService}
                    variant="contained"
                    disabled={(!form.parents.length && !hasParent) || !form.name || !form.description}
                >
                    Создать
                </Button>
            </ButtonsWrapper>
        </Box>
    );
};
