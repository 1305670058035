import dayjs from 'dayjs';

import { issuesApi } from '../../../api';
import { ECreateIssuePartnerActions } from '../../../enums/actions.enum';
import { ECreateIssuePartnerSteps } from '../../../enums/createIssuePartnerForm.enum';
import { TErrors } from '../../../interfaces';
import {
    ICreateIssuePartnerFormValues,
    ICreateIssuePartnerReq,
    ISetErrorsAction,
    ISetFormValuesAction,
    ISetSelectedPricesAction,
    ISetStepAction,
} from '../../../interfaces/create/createIssuePartner';
import { IPrice } from '../../../interfaces/prices';
import { store } from '../../store';

export const setSelectedPricesAction = (payload: IPrice[] | null): ISetSelectedPricesAction => ({
    type: ECreateIssuePartnerActions.SetSelectedPrices,
    payload,
});

export const setSetFormValuesAction = (payload: ICreateIssuePartnerFormValues | null): ISetFormValuesAction => ({
    type: ECreateIssuePartnerActions.SetFormValues,
    payload,
});

export const setSetFormStepAction = (payload: ECreateIssuePartnerSteps | null): ISetStepAction => ({
    type: ECreateIssuePartnerActions.SetStep,
    payload,
});

export const setErrorsAction = (payload: TErrors | null): ISetErrorsAction => ({
    type: ECreateIssuePartnerActions.SetErrors,
    payload,
});

interface ICreateIssuePartnerThunkParams {
    formValues: ICreateIssuePartnerFormValues;
    selectedPrices: IPrice[];
}

export const createIssuePartnerThunk =
    ({ formValues, selectedPrices }: ICreateIssuePartnerThunkParams) =>
    async () => {
        try {
            //start Warning!!! Временное решение!!! При каждой возможности, напоминать бэку избавиться от подобной логики.
            // Чтоб создать заявку бэку нужен id специального бренда у которого name === '-'
            const state = store.getState();
            const { brands } = state.brands;
            if (!brands) {
                const errText = 'Error! !brands createIssuePartnerThunk';
                console.error(errText);
                throw Error(errText);
            }
            const foundBrand = brands.find(({ name }) => name === '-');
            if (!foundBrand) {
                const errText = 'Error! !foundBrand createIssuePartnerThunk';
                console.error(errText);
                throw Error(errText);
            }
            const brandId = foundBrand.id;
            //end

            const request: ICreateIssuePartnerReq = {
                client: {
                    phone_number: formValues.phone_number?.replace('+', '') as string,
                    first_name: formValues.first_name as string,
                    address: {
                        address: formValues.address as string,
                        entrance: Number(formValues.entrance) || null,
                        floor: Number(formValues.floor) || null,
                        apartment: Number(formValues.apartment) || null,
                        intercom: Number(formValues.intercom) || null,
                        lat: formValues.lat || null,
                        long: formValues.long || null,
                        fias_id: formValues.fias_id || null,
                    },
                },
                time_windows: [
                    {
                        date: dayjs(formValues.date as string)
                            .locale('ru')
                            .format('YYYY-MM-DD'),
                        time: [
                            {
                                //Дефолтное значение напомнить бэку убрать
                                start_time: '09:00',
                                end_time: '11:00',
                            },
                        ],
                    },
                ],
                sub_issues: [
                    {
                        brand_id: brandId,
                        category_id: selectedPrices[0].categories?.length ? selectedPrices[0].categories[0].id : '',
                    },
                ],
                prices: selectedPrices.map(({ id }) => id),
                description: formValues.description as string,
                codes: {
                    code: formValues.codes?.code as string,
                    issue: formValues.codes?.issue as string,
                },
            };
            const { data } = await issuesApi.createPartnerIssue(request);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
