import { EDialogActions } from '../../enums/actions.enum';
import { EDialogSize } from '../../enums/dialog.enum';
import { IDialogState, TDialogAction } from '../../interfaces/dialog';

const initState: IDialogState = {
    visible: false,
    onOk: null,
    content: null,
    onClose: null,
    onCancel: null,
    onOkText: null,
    onCancelText: null,
    titleText: null,
    loading: false,
    autoClose: true,
    size: EDialogSize.base,
    maxWidth: undefined,
    minWidth: undefined,
    width: undefined,
    footer: null,
    buttonOkFormId: null,
};

const initialState: IDialogState = { ...initState };

// eslint-disable-next-line default-param-last
export const dialogReducer = (state = initialState, action: TDialogAction): IDialogState => {
    switch (action.type) {
        case EDialogActions.SetDialog: {
            return {
                ...state,
                visible: true,
                ...action.payload,
            };
        }
        case EDialogActions.CancelDialog: {
            return {
                ...initState,
            };
        }
        case EDialogActions.SetLoading: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        default:
            return state;
    }
};
