import NotificationsIcon from '@mui/icons-material/Notifications';
import { Tooltip } from '@mui/material';
import React from 'react';

import { IReminder } from '../../../interfaces/reminder';
import { dateFormatter } from '../../../utils/helpers/dateFormatter';

interface IProps {
    reminders: IReminder[];
}

function ReminderTooltip({ reminders }: IProps) {
    return (
        <Tooltip
            arrow
            title={
                <div className="reminders-list">
                    {reminders?.map(({ text, id, datetime_delivery, send_only_bo_user_created }) => {
                        const date = dateFormatter(
                            datetime_delivery,
                            {},
                            {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                            },
                        );
                        return (
                            <div
                                className="reminders-item"
                                key={id}
                            >
                                {text} ({send_only_bo_user_created ? 'личное' : 'общее'})<div>{date}</div>
                            </div>
                        );
                    })}
                </div>
            }
        >
            <NotificationsIcon color="primary" />
        </Tooltip>
    );
}

export default ReminderTooltip;
