import { groupsApi } from '../../../api';
import { ECreateGroupActions } from '../../../enums/actions.enum';
import { ISetCreateGroupIsOpenAction, ISetCreateGroupWorkersListAction } from '../../../interfaces/create/createGroup';
import { IWorker } from '../../../interfaces/workers';

export const setCreateGroupIsOpenAction = (payload: boolean): ISetCreateGroupIsOpenAction => ({
    type: ECreateGroupActions.SetCreateGroupIsOpen,
    payload,
});

export const setCreateGroupWorkersListAction = (payload: IWorker[] | null): ISetCreateGroupWorkersListAction => ({
    type: ECreateGroupActions.SetWorkersList,
    payload,
});

interface ICreateGroup {
    groupName: string;
    workersIds: string[];
}
export const createGroupThunk = ({ groupName, workersIds }: ICreateGroup) => {
    return async () => {
        try {
            const { data } = await groupsApi.createGroup(groupName, workersIds);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
};
