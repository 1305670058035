import { Button, Checkbox, FormControl, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { authApi } from '../../../api';
import { ELocalStorageItems } from '../../../enums/localStorage.enum';
import { ERoutes } from '../../../enums/routes.enum';
import { getProfileThunk } from '../../../store/actions/profile';
import { setEmailAction } from '../../../store/actions/signIn';
import { useAppDispatch } from '../../../store/hooks';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';

function Login() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    async function handleSubmit(e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
        };

        const { email, password } = target;

        try {
            const { data } = await authApi.getToken({ email: email.value, password: password.value });
            if (!data?.auth) {
                defaultErrorCallback({ errorMessage: 'Error!!! API response token not found' });
                return;
            }
            localStorage.setItem(ELocalStorageItems.token, data.auth.access_token);
            await dispatch(getProfileThunk());
            navigate(`/${ERoutes.Issues}`);
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <>
            <Stack spacing={2}>
                <Typography
                    align={'center'}
                    fontSize={33}
                    fontWeight={'bold'}
                >
                    Вход в систему
                </Typography>
                <Typography
                    align={'center'}
                    fontSize={16}
                    fontWeight={400}
                    color={'#8A92A6'}
                >
                    Для входа необходимо ввести адрес электронной почты и пароль
                </Typography>
            </Stack>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth>
                    <TextField
                        name="email"
                        type="text"
                        label="Email"
                        variant="outlined"
                        onChange={(e) => {
                            setEmailAction(e.target.value);
                        }}
                        margin="normal"
                    />
                    <TextField
                        name="password"
                        type="password"
                        label="Пароль"
                        variant="outlined"
                        margin="normal"
                    />

                    <Grid
                        container
                        justifyContent="space-between"
                    >
                        <FormControlLabel
                            control={<Checkbox defaultChecked />}
                            label="Запомнить меня"
                        />
                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                    >
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Войти
                        </Button>
                    </Grid>
                </FormControl>
            </form>
        </>
    );
}

export default Login;
