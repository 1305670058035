import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { IFormValuesCreatePart } from '../interfaces/create/createPart';
import { TPartResp } from '../interfaces/parts';

export default class PartsAPI {
    /*Получение деталей*/
    getParts(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/parts${queries || ''}`,
            isAuthorization: true,
        }) as AxiosPromise<TPartResp>;
    }

    /*Получение деталей*/
    createParts(data: IFormValuesCreatePart) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/parts`,
            data,
            isAuthorization: true,
        }) as AxiosPromise;
    }
}
