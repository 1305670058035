import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ brands }: RootState) => brands;

export const brandsSelector = createSelector(state, ({ brands }) => brands);
export const brandsOptionsSelector = createSelector(state, ({ brands }) => {
    return (
        brands?.map((brand) => {
            const { id, name } = brand;
            return { value: id, label: name || '' };
        }) || null
    );
});
