import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Chip, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IPrice } from '../../../../../../interfaces/prices';
import { setSelectedPricesCIAction } from '../../../../../../store/actions/create/createIssue';
import { cancelDialogAction, setDialogAction } from '../../../../../../store/actions/dialog';
import { useAppDispatch } from '../../../../../../store/hooks';
import { selectedPricesSelector } from '../../../../../../store/selectors/create/createIssuesSelectors';
import { pricesSelector } from '../../../../../../store/selectors/pricesSelectors';
import priceFormatter from '../../../../../../utils/helpers/priceFormatter';
import DialogContent from '../DialogContent';
import DialogTitle from '../DialogTittle';
import './SelectedPrice.scss';

interface IProps {
    price: IPrice;
}

function SelectedPrice({ price }: IProps) {
    const dispatch = useAppDispatch();

    const prices = useSelector(pricesSelector);
    const selectedPrices = useSelector(selectedPricesSelector);

    useEffect(() => {
        function handle() {
            if (!prices || !selectedPrices) return;
            /* Пересчёт цены в случае получения prices по региону,
			с.м. useEffect([formValues?.address, formValues?.addressFiasId])
			src/pages/partner/index.tsx */
            const newSelectedPrices = selectedPrices.map((sPrice) => {
                const foundPrice = prices.find(({ id }) => id === sPrice.id);
                if (foundPrice) {
                    return foundPrice;
                } else {
                    return sPrice;
                }
            });
            dispatch(setSelectedPricesCIAction(newSelectedPrices));
        }
        handle();
    }, [prices]);

    function deletePriceHandler() {
        if (!selectedPrices) return;

        const newSelectedPrices = [...selectedPrices];
        const foundIndex = newSelectedPrices.findIndex(({ id }) => id === price.id);

        if (foundIndex === -1) {
            console.error('!foundIndex deletePriceHandler()');
            return;
        }

        newSelectedPrices.splice(foundIndex, 1);
        dispatch(setSelectedPricesCIAction(newSelectedPrices));
    }

    function showModalHandler() {
        dispatch(
            setDialogAction({
                visible: true,
                onCancel: () => {
                    dispatch(cancelDialogAction());
                },
                onCancelText: false,
                onOkText: false,
                titleText: <DialogTitle />,
                content: <DialogContent price={price} />,
                autoClose: true,
                maxWidth: '600px',
            }),
        );
    }

    return (
        <div className="price-wrapper">
            <Box className="price-header">
                <div className="price-name">{price.name}</div>
                <div className="price-right">
                    {priceFormatter(price.ratios?.total_ratio_amount || price.amount)}
                    {/* eslint-disable-next-line react/jsx-no-bind */}
                    <IconButton
                        onClick={deletePriceHandler}
                        aria-label="delete"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </Box>
            <Box className="price-content">
                {(!!price.included_in_price?.length || !!price.excluded_in_price?.length) && (
                    <Chip
                        color="secondary"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={showModalHandler}
                        icon={<InfoIcon />}
                        label="Что входит в стоимость"
                    />
                )}
            </Box>
        </div>
    );
}

export default SelectedPrice;
