export const getNumWord = (value: number | undefined | null, words: string[]): string => {
    if (typeof value !== 'number') return '';

    const val = Math.abs(value) % 100;
    const num = val % 10;
    if (val > 10 && val < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num === 1) return words[0];
    return words[2];
};
