import { TService, TServiceType } from 'pages/services/store/types';

import { AxiosPromise } from 'axios';
import { IPaginationResp } from 'interfaces/pagination';

import axiosFetch from './axiosFetch';

export default class ServicesAPI {
    getServices(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/services`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<TService>>;
    }

    getServiceTypes() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/services`,
            isAuthorization: true,
        }) as AxiosPromise<TServiceType[]>;
    }

    getCategories() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/categories`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getServiceById(serviceId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/services/${serviceId}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    createService(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/services`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    editService(serviceId: string | undefined, data: any) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/services/${serviceId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getServicesSpecs() {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/specs`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    createSpecification(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/servicesSpecs`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getIntegrations(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/servicesExternals`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getIntegrationById(id: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/servicesExternals/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    createIntegration(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/servicesExternals`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    deleteIntegration(id: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/servicesExternals/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getDescendants(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/services/linearDescendants`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
