import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button } from '@mui/material';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import Groups from './Groups';

import useOutsideClick from '../../hooks/useOutsideClick';
import { IGroup } from '../../interfaces/groups';
import { getGroupsThunk } from '../../store/actions/groups';
import { useAppDispatch } from '../../store/hooks';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import './GroupFilter.scss';

interface IProps {
    selectedGroup: IGroup | null;
    onChange: (group: IGroup | null) => void;
}

function GroupFilter({ selectedGroup, onChange }: IProps) {
    const dispatch = useAppDispatch();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const wrapperRef = useOutsideClick(() => {
        setIsOpen(false);
    });

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getGroupsThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, []);

    return (
        <div
            ref={wrapperRef}
            className={cn('filter groups-filter-btn-wrapper', { isOpen })}
        >
            <Button
                variant={selectedGroup ? 'filterActive' : 'filter'}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                endIcon={<ExpandMoreIcon />}
            >
                Группа
            </Button>
            <Groups
                selectedGroup={selectedGroup}
                onChange={onChange}
            />
        </div>
    );
}

export default GroupFilter;
