import Typography from '@mui/material/Typography';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ERoutes } from '../../../../../../enums/routes.enum';
import { ISearchItem } from '../../../../../../interfaces/search';
import { setIsOpenSearchAction } from '../../../../../../store/actions/search';
import { useAppDispatch } from '../../../../../../store/hooks';
import { textSearchSelector } from '../../../../../../store/selectors/searchSelectors';

interface IProps {
    items: ISearchItem[];
}

function Oids({ items }: IProps) {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const textSearch = useSelector(textSearchSelector);

    return (
        <>
            {items.map(({ data }) => {
                return (
                    <div
                        className="search-item"
                        tabIndex={0}
                        role="button"
                        onClick={() => {
                            navigate(
                                data.is_archived ? `/${ERoutes.Archive}/${data.id}` : `/${ERoutes.Issues}/${data.id}`,
                            );
                            dispatch(setIsOpenSearchAction(false));
                        }}
                        key={data.id}
                    >
                        <Typography>
                            Заявка №
                            <Highlighter
                                searchWords={[textSearch || '']}
                                autoEscape
                                textToHighlight={String(data?.oid || '')}
                                activeClassName="highlighter-text"
                            />
                        </Typography>
                    </div>
                );
            })}
        </>
    );
}

export default Oids;
