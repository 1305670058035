import React from 'react';
import { useSelector } from 'react-redux';

import AreasContent from './AreasContent';
import IssuesContent from './IssuesContent';
import SidebarTabs from './SidebarTabs';
import WorkersContent from './WorkersContent';

import { EMapTabs } from '../../../enums/map.enum';
import { mapTabSelector } from '../../../store/selectors/mapSelectors';
import './SidebarMap.scss';

function SidebarMap() {
    const mapTab = useSelector(mapTabSelector);

    return (
        <div className="sidebar-map-wrapper">
            <SidebarTabs />
            <div className="sidebar-map-content">
                {(() => {
                    switch (mapTab) {
                        case EMapTabs.Areas:
                            return <AreasContent />;
                        case EMapTabs.Issues:
                            return <IssuesContent />;
                        default:
                            return <WorkersContent />;
                    }
                })()}
            </div>
        </div>
    );
}

export default SidebarMap;
