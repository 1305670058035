import { Button, Chip, List, ListItem, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { EWorkerVerifyStatus } from '../../../../../enums/workers.enum';
import { WorkerVerifyStatusesMock } from '../../../../../mockData/workers';
import { verifyResetWorkerThunk } from '../../../../../store/actions/edit/editWorkerInfo';
import { useAppDispatch } from '../../../../../store/hooks';
import { workerDetailSelector } from '../../../../../store/selectors/workerSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

import './verify.scss';

function Verify() {
    const dispatch = useAppDispatch();
    const worker = useSelector(workerDetailSelector);
    const verifyStatusInfo = worker && WorkerVerifyStatusesMock[worker.verify_status];
    const [disabled, setDisabled] = useState<boolean>(false);

    async function verifyReset() {
        setDisabled(true);
        try {
            if (worker) {
                await dispatch(verifyResetWorkerThunk(worker.id));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setDisabled(false);
        }
    }

    return (
        verifyStatusInfo && (
            <div className="worker-info">
                <Typography
                    variant="inherit"
                    className="worker-subtitle"
                >
                    Результат верификации
                </Typography>
                <div>
                    <div className="worker_verify_info">
                        <Chip
                            label={verifyStatusInfo.title}
                            sx={{
                                fontWeight: '600',
                                backgroundColor: verifyStatusInfo.colorHex,
                                color: verifyStatusInfo.colorText,
                            }}
                            size={'small'}
                        />
                        {worker.verify_status === EWorkerVerifyStatus.Blocked && (
                            <Button
                                disabled={disabled}
                                size="small"
                                variant="contained"
                                onClick={verifyReset}
                            >
                                Отправить на проверку
                            </Button>
                        )}
                    </div>
                    <List
                        dense
                        className="worker_verify_errors"
                    >
                        {worker.verify_error_bo &&
                            worker.verify_error_bo.map((item) => (
                                <ListItem key={item}>
                                    <ListItemText>{item}</ListItemText>
                                </ListItem>
                            ))}
                    </List>
                </div>
            </div>
        )
    );
}

export default Verify;
