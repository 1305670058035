import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editWorkerSchedule }: RootState) => editWorkerSchedule;

export const isOpenEditWorkerScheduleSelector = createSelector(state, ({ isOpen }) => isOpen);
export const workerIdEditWorkerScheduleSelector = createSelector(state, ({ workerId }) => workerId);
export const formValuesEditWorkerScheduleSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsEditWorkerScheduleSelector = createSelector(state, ({ errors }) => errors);
