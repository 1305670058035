import { Avatar, Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { logout } from '../../../../store/actions/profile';
import { useAppDispatch } from '../../../../store/hooks';
import { profileSelector } from '../../../../store/selectors/profileSelectors';

function ProfileTopBar() {
    const dispatch = useAppDispatch();
    const profile = useSelector(profileSelector);
    return profile ? (
        <Box
            display="flex"
            alignItems="center"
            onClick={() => {
                dispatch(logout());
            }}
        >
            <IconButton>
                <Avatar sizes="xl" />
            </IconButton>
            <Box>
                <Typography>{profile.first_name}</Typography>
                <Typography>admin</Typography>
            </Box>
        </Box>
    ) : null;
}

export default ProfileTopBar;
