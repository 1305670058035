import { Divider, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { EIssueHelpType } from '../../../../enums/issues.enum';
import { helpTypeForWorkersMock } from '../../../../mockData/workers';
import { setWorkerSelectedTabsAction } from '../../../../store/actions/worker';
import { useAppDispatch } from '../../../../store/hooks';
import {
    workerSelectedSpecializationsSelector,
    workerSelectedTabSelector,
} from '../../../../store/selectors/workerSelectors';
import { getSubOptionsCountString } from '../../../../utils/helpers/getSubOptionsCountString';

function SpecializationsTabs() {
    const dispatch = useAppDispatch();

    const selectedTab = useSelector(workerSelectedTabSelector);
    const specializations = useSelector(workerSelectedSpecializationsSelector);

    const handleChangeTab = (tabValue: EIssueHelpType) => {
        dispatch(setWorkerSelectedTabsAction(tabValue));
    };

    return specializations?.length ? (
        <>
            <Tabs
                value={selectedTab}
                onChange={(event: React.SyntheticEvent, tabValue) => handleChangeTab(tabValue)}
            >
                {helpTypeForWorkersMock.map(({ value, label }) => (
                    <Tab
                        key={value}
                        value={value}
                        label={`${label} ${getSubOptionsCountString(specializations, value)}`}
                    />
                ))}
            </Tabs>
            <Divider />
        </>
    ) : null;
}

export default SpecializationsTabs;
