import { Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import Accordion from '../../../../components/Accordion';
import FilePreview from '../../../../components/FilePreview';
import { IDiagnostic } from '../../../../interfaces/issues';
import { diagnosticStatusMock } from '../../../../mockData/issues';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';

interface IProps {
    diagnostic: IDiagnostic;
}

function DiagnosticInfo({ diagnostic }: IProps) {
    return (
        <>
            <div className="dv-pr-item">
                <div>Статус</div>
                <div className="val-prop-iss">{diagnosticStatusMock[diagnostic.status]}</div>
            </div>
            <div className="dv-pr-item">
                <div>Требуется ремонт</div>
                <div className="val-prop-iss">{diagnostic.is_required_repair ? 'Да' : 'Нет'}</div>
            </div>
            <div className="dv-pr-item">
                <div>Гарантийная установка</div>
                <div className="val-prop-iss">{diagnostic.is_warranty ? 'Да' : 'Нет'}</div>
            </div>
            <div className="dv-pr-item">
                <div>Требуются запчасти</div>
                <div className="val-prop-iss">{diagnostic.is_required_parts ? 'Да' : 'Нет'}</div>
            </div>
        </>
    );
}

function Diagnostic() {
    const issue = useSelector(issueSelector);
    const photoContainers = issue?.photos_of_diagnostic || [];

    return (
        <Accordion
            title={<Typography>Результаты диагностики</Typography>}
            sx={{ mt: 3 }}
            expanded={false}
        >
            <div className="dv-pr-list">
                {issue && issue.diagnostic ? (
                    <DiagnosticInfo diagnostic={issue.diagnostic} />
                ) : (
                    'Диагностика не проводилась'
                )}

                {photoContainers.map((item) => (
                    <div
                        key={item.id}
                        className="dv-pr-item"
                    >
                        <div>{item.title}</div>
                        <div>
                            <FilePreview photoFiles={item.photo_files} />
                        </div>
                    </div>
                ))}
            </div>
        </Accordion>
    );
}

export default Diagnostic;
