import { Box, Tab, Tabs } from '@mui/material';
import React from 'react';

import './Toggle.scss';
import { TArchiveSelectedTab } from '../../../interfaces/archive';

interface IProps {
    selectedTab: TArchiveSelectedTab;
    setSelectedTab: (value: TArchiveSelectedTab) => void;
}

function Toggle({ selectedTab, setSelectedTab }: IProps) {
    const toggleValues: TArchiveSelectedTab[] = ['Заявки', 'Мастера'];

    return (
        <div className="archive-toggle">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    variant="scrollable"
                    value={selectedTab || false}
                    onChange={(event: any, value: TArchiveSelectedTab) => setSelectedTab(value)}
                >
                    {toggleValues.map((toggleItem, index) => {
                        return (
                            <Tab
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                label={toggleItem}
                                value={toggleItem}
                            />
                        );
                    })}
                </Tabs>
            </Box>
        </div>
    );
}

export default Toggle;
