import { SxProps } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { Theme } from '@mui/system';
import cn from 'classnames';
import { MuiTelInput } from 'mui-tel-input';
import { MuiTelInputInfo } from 'mui-tel-input/dist/index.types';
import React, { memo, useEffect, useMemo, useState } from 'react';

import { TErrors } from '../../../interfaces';

interface IProps {
    name: string;
    labelText?: string;
    id?: string;
    sxStyle?: SxProps<Theme> | undefined;
    value?: string | number | null;
    onChange?: (value: string, info: MuiTelInputInfo) => void;
    errors?: TErrors | null;
    placeholder?: string;
    defaultValue?: string | null;
    required?: boolean;
    params?: TextFieldProps;
    fullWidth?: boolean;
}

function PhoneInput({ name, onChange, errors, defaultValue, params, fullWidth }: IProps) {
    const [forceRerender, setForceRerender] = useState('');
    const [errorText, setErrorText] = useState<null | React.ReactNode>(null);
    const [valueLocal, setValueLocal] = useState<string | undefined>();

    const defaultValueLocal = useMemo(() => {
        if (defaultValue) {
            setForceRerender(Math.random().toFixed(10));
            if (defaultValue.length > 10) {
                return defaultValue.slice(-10);
            }
            return defaultValue;
        }
        return undefined;
    }, [!!defaultValue]);

    function onChangeHandler(val: string, info: MuiTelInputInfo) {
        const maxLength = 11;
        if (info.nationalNumber && info.nationalNumber.length >= maxLength) return;

        setValueLocal(val);
        onChange && onChange(val.replaceAll(' ', '').replace('+', ''), info);
    }

    useEffect(() => {
        setValueLocal(defaultValueLocal || undefined);
    }, [defaultValueLocal]);

    useEffect(() => {
        if (errors && name in errors) {
            if (Array.isArray(errors[name])) {
                const errs: { message: string }[] = errors[name];
                setErrorText(
                    errs.map((err) => {
                        return (
                            <span
                                key={name}
                                className="text__error"
                            >
                                {err?.message}
                            </span>
                        );
                    }),
                );
            } else {
                setErrorText(
                    <span
                        key={name}
                        className="text__error"
                    >
                        {errors[name]?.message}
                    </span>,
                );
            }
        } else {
            setErrorText(null);
        }
    }, [errors]);

    return (
        <MuiTelInput
            key={forceRerender}
            className={cn('phone-inp', { fullWidth })}
            defaultCountry={'RU'}
            forceCallingCode
            disableDropdown
            {...params}
            onChange={onChangeHandler}
            value={valueLocal}
            error={!!errorText}
            helperText={errorText}
            fullWidth
        />
    );
}

export default memo(PhoneInput);
