import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { ICategory, ICategoryDetail, TGetCategoriesResp } from '../interfaces/categories';

export default class CategoriesAPI {
    getCategories(queries?: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/categories${queries}`,
            isAuthorization: true,
        }) as AxiosPromise<TGetCategoriesResp>;
    }

    /* При увеличении количества параметров сменить на объект */
    createCategory(name?: ICategory['name']) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/categories`,
            data: { name },
            isAuthorization: true,
        }) as AxiosPromise<ICategoryDetail>;
    }

    /* При увеличении количества параметров сменить name на объект */
    patchCategory(categoryId: ICategory['id'], name?: ICategory['name']) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/categories/${categoryId}`,
            data: { name },
            isAuthorization: true,
        }) as AxiosPromise<ICategoryDetail>;
    }

    deleteCategory(categoryId: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/categories/${categoryId}`,
            isAuthorization: true,
        }) as AxiosPromise<void>;
    }
}
