import TextField from 'components/inputs/TextField';
import { EIssueHelpType } from 'enums/issues.enum';
import { issueStatusesMock } from 'mockData/issues';
import { setFormValuesEditIssueStatusWithCommentAction } from 'store/actions/edit/editIssueStatusWithComment';
import { useAppDispatch } from 'store/hooks';
import {
    dataEditIssueStatusWithCommentSelector,
    errorsEditIssueStatusWithCommentSelector,
} from 'store/selectors/edit/editIssueStatusWithCommentSelectors';

import { ChangeEvent, memo } from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import './EditIssueStatusWithCommentForm.scss';

interface IProps {
    issueHelpType: EIssueHelpType;
    reason: string | undefined;
}

function EditIssueStatusWithCommentForm({ reason }: IProps) {
    const dispatch = useAppDispatch();

    const errors = useSelector(errorsEditIssueStatusWithCommentSelector);
    const data = useSelector(dataEditIssueStatusWithCommentSelector);

    function onChangeComment(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditIssueStatusWithCommentAction({ comment: e.target.value.trim() }));
    }

    return (
        <div className="edit-issue-status-with-comment-form-wrapper">
            {data?.newStatus === 'processed' || data?.newStatus === 'waiting_call' ? (
                <>
                    <div className="info-text">
                        Укажите причину, из-за которой вы переводите заявку в статус{' '}
                        {data?.newStatus ? issueStatusesMock[data.newStatus].title : 'Неизвестный статус'}
                    </div>
                    <TextField
                        name="comment"
                        placeholder="Комментарий"
                        fullWidth
                        params={{ margin: 'normal' }}
                        errors={errors}
                        onChange={onChangeComment}
                    />
                </>
            ) : (
                <div style={{ color: '#8a92a6', fontSize: '19px', marginBottom: '32px' }}>
                    Статус заявки будет изменен на {reason}
                </div>
            )}
            <FooterForm />
        </div>
    );
}

export default memo(EditIssueStatusWithCommentForm);
