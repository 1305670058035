import { EArchiveActions } from '../../enums/actions.enum';
import { IArchiveState, TArchiveActions } from '../../interfaces/archive';
import { archiveTableHeadMock } from '../../mockData/issues';
import { archivedWorkerStatusFiltersMock } from '../../mockData/workers';

const initState: IArchiveState = {
    selectedTab: 'Заявки',

    issues: null,
    isIssuesTableLoader: false,
    issuesPagination: { page: 0, size: 50, total: 0 },
    issuesFilters: { is_archived: true },
    issuesTableHead: archiveTableHeadMock,

    workers: null,
    isWorkersTableLoader: false,
    workersPagination: { page: 0, size: 50, total: 0 },
    workersFilters: { is_archived: true, status__in: archivedWorkerStatusFiltersMock },
    workersSortOptions: new Map(),
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const archiveReducer = (state = initialState, action: TArchiveActions): IArchiveState => {
    switch (action.type) {
        case EArchiveActions.ArchiveReset:
            return {
                ...initState,
                selectedTab: state.selectedTab,
            };
        case EArchiveActions.SetSelectedTab:
            return {
                ...state,
                selectedTab: action.payload,
            };
        case EArchiveActions.SetIssues:
            return {
                ...state,
                issues: action.payload,
            };
        case EArchiveActions.SetIsIssuesTableLoader:
            return {
                ...state,
                isIssuesTableLoader: action.payload,
            };
        case EArchiveActions.SetIssuesPagination:
            return {
                ...state,
                issuesPagination: action.payload,
            };
        case EArchiveActions.SetIssuesFilters: {
            return {
                ...state,
                issuesFilters: action.payload,
            };
        }
        case EArchiveActions.SetIssuesTableHead:
            return {
                ...state,
                issuesTableHead: action.payload,
            };

        case EArchiveActions.SetWorkers:
            return {
                ...state,
                workers: action.payload,
            };
        case EArchiveActions.SetIsWorkersTableLoader:
            return {
                ...state,
                isWorkersTableLoader: action.payload,
            };
        case EArchiveActions.SetWorkersPagination:
            return {
                ...state,
                workersPagination: action.payload,
            };
        case EArchiveActions.SetWorkersFilters:
            return {
                ...state,
                workersFilters: action.payload,
            };
        case EArchiveActions.SetWorkersSortOptions:
            return {
                ...state,
                workersSortOptions: new Map(state.workersSortOptions).set(
                    action.payload.sortField,
                    action.payload.direction,
                ),
            };
        default:
            return state;
    }
};
