import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { IPartner } from '../../../../interfaces/partners';
import CategoriesFilter from '../../../../modules/CategoriesFilter';
import PartnersFilter from '../../../../modules/PartnersFiler';
import Search from '../../../../modules/WorkersSelect/Search';
import { setPricesFiltersAction } from '../../../../store/actions/prices';
import { useAppDispatch } from '../../../../store/hooks';
import { pricesFiltersSelector } from '../../../../store/selectors/pricesSelectors';
import './PricesFilters.scss';

function PriceFilters() {
    const dispatch = useAppDispatch();
    const filters = useSelector(pricesFiltersSelector);

    function selectCRFIdsForPrices(selectCRFIds: string[] | null) {
        dispatch(setPricesFiltersAction({ ...(filters || {}), category_id__in: selectCRFIds }));
    }

    function selectedPartner(selectedPartnerId: IPartner['id'] | null) {
        dispatch(setPricesFiltersAction({ ...(filters || {}), partner_id: selectedPartnerId }));
    }

    function searchPrices(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setPricesFiltersAction({ ...(filters || {}), search: event.target.value }));
    }

    return (
        <div className="prices-filters-wrapper">
            <div className="extra-filters">
                <CategoriesFilter
                    selectedCRFIdsForWorkers={filters?.category_id__in || null}
                    onChange={selectCRFIdsForPrices}
                />
                <PartnersFilter
                    selectedPartner={filters?.partner_id || null}
                    onChange={selectedPartner}
                />
            </div>
            <Search
                onChange={searchPrices}
                placeholder="Поиск по названию услуги..."
            />
        </div>
    );
}

export default PriceFilters;
