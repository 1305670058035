import { styled } from '@mui/material';

export const Wrapper = styled('div')({
    // margin: 0;

    span: {
        display: 'inline-block',
        color: '#9e9e9e',
    },
});

export const ReasonsWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',

    span: {
        width: '150px',
    },

    '&:not(:first-child)': {
        marginTop: '32px',
    },

    // '> div': {
    //     '&:first-child': {
    //         color: 'rgb(138, 146, 166)',
    //         fontSize: '14px',
    //         minWidth: '150px',
    //         maxWidth: '150px',
    //         marginRight: '8px',
    //     },

    //     '&:last-child': {
    //         position: 'relative',
    //         display: 'inline-block',
    //         fontWeight: '500',
    //         paddingRight: '40px',
    //     },
    // },
});

export const Reason = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '5px',

    div: {
        padding: '4px 6px',
        borderRadius: '2px',
        background: '#eaedfb',
    },
});
