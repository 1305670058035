import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PriceFilters from './Filters';
import PricesTable from './PricesTable';
import PriceRow from './PricesTable/PriceRow';

import { EPricesServicesTabs } from '../../../enums/pricesServices.enum';
import { additionalPricesTableHeadMock, pricesTableHeadMock } from '../../../mockData/pricesServices';
import { getPricesThunk, setIsPriceLoadingAction, setPaginationAction } from '../../../store/actions/prices';
import { useAppDispatch } from '../../../store/hooks';
import {
    isPriceLoadingSelector,
    paginationSelector,
    pricesFiltersSelector,
    pricesSelector,
} from '../../../store/selectors/pricesSelectors';
import { priceServiceSelector, priceServicesTabSelector } from '../../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../utils/helpers/getErrorString';

function PriceContent() {
    const dispatch = useAppDispatch();
    const prices = useSelector(pricesSelector);
    const priceService = useSelector(priceServiceSelector);
    const pagination = useSelector(paginationSelector);
    const isPriceLoading = useSelector(isPriceLoadingSelector);
    const filters = useSelector(pricesFiltersSelector);
    const serviceTabs = useSelector(priceServicesTabSelector);

    useEffect(() => {
        async function handle() {
            try {
                if (!pagination || !priceService) return;
                dispatch(setIsPriceLoadingAction(true));
                await dispatch(
                    getPricesThunk({
                        filters: {
                            ...(filters || {}),
                            is_additional: serviceTabs !== EPricesServicesTabs.BasicServices,
                            service_id__in: priceService?.id,
                            with_paginate: true,
                        },
                        page: pagination.page,
                        size: pagination.size,
                    }),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                dispatch(setIsPriceLoadingAction(false));
            }
        }
        handle();
    }, [serviceTabs, filters, pagination?.page, pagination?.size]);

    return (
        <>
            <PriceFilters />
            <PricesTable
                tableHead={
                    serviceTabs === EPricesServicesTabs.BasicServices
                        ? pricesTableHeadMock
                        : additionalPricesTableHeadMock
                }
                ComponentRow={PriceRow}
                tableContent={prices}
                isLoading={isPriceLoading}
                pagination={pagination}
                changePagination={(newPagination) => dispatch(setPaginationAction(newPagination))}
            />
        </>
    );
}

export default PriceContent;
