import React, { memo } from 'react';

import FooterForm from './FooterForm';
import './EditIssueStatusToMasterSearchForm.scss';

function EditIssueStatusToMasterSearchForm() {
    return (
        <div className="edit-issue-status-to-master-search-form-wrapper">
            <div className="info-text">Обновите адрес в заявке для того, чтобы были указаны координаты</div>
            <FooterForm />
        </div>
    );
}

export default memo(EditIssueStatusToMasterSearchForm);
