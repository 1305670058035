import { routingApi } from '../../api';
import { EGenerateXlsActions } from '../../enums/actions.enum';
import { TErrors } from '../../interfaces';
import {
    IFormValuesGenerateXls,
    ISetErrorsGenerateXls,
    ISetFormValuesGenerateXls,
    ISetIsOpenGenerateXls,
} from '../../interfaces/generateXls';
import { getDateEnd, getDateStart } from '../../utils/helpers/getDaysInMS';

interface IGenerateXlsThunkParams {
    formValues: Partial<IFormValuesGenerateXls>;
}

export const setIsOpenGenerateXlsAction = (payload: boolean): ISetIsOpenGenerateXls => ({
    type: EGenerateXlsActions.SetIsOpen,
    payload,
});

export const setFormValuesGenerateXlsAction = (
    payload: Partial<IFormValuesGenerateXls> | null,
): ISetFormValuesGenerateXls => ({
    type: EGenerateXlsActions.SetFormValues,
    payload,
});

export const setErrorsGenerateXlsAction = (payload: TErrors | null): ISetErrorsGenerateXls => ({
    type: EGenerateXlsActions.SetErrors,
    payload,
});

export const generateXlsThunk =
    ({ formValues }: IGenerateXlsThunkParams) =>
    async () => {
        try {
            if (!formValues.time_window__gte || !formValues.time_window__lte) {
                console.error('Error! generateXlsThunk()');
                return;
            }

            const gte = getDateStart(new Date(formValues.time_window__gte));
            const lte = getDateEnd(new Date(formValues.time_window__lte));
            const { data } = await routingApi.generateXls({
                gte,
                lte,
            });
            const outputFilename = `${Date.now()}.xlsx`;
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // eslint-disable-next-line consistent-return
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
