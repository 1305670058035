import CloseIcon from '@mui/icons-material/Close';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import TextField from '../../../../../components/inputs/TextField';
import { IIssue } from '../../../../../interfaces/issues';
import { putIssueThunk } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import theme from '../../../../../theme';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import './Note.scss';

function Note() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<{ note: IIssue['note'] } | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    function changeNoteHandler(e: React.ChangeEvent<HTMLInputElement>) {
        const val = e.target.value;
        setFormValues({ note: val });
    }

    function handleClose() {
        setIsOpen(false);
    }

    async function putNoteHandler() {
        try {
            if (!formValues || !issue) return;
            setIsLoading(true);
            await dispatch(putIssueThunk({ issueId: issue.id, newIssue: formValues }));
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="val-prop-iss">
            <pre>{issue?.note}</pre>
            <IconButton
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className="edit-iss-prop-icon"
            >
                <EditSharpIcon />
            </IconButton>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box className="modal-description-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <SmsOutlinedIcon className="title-icon" /> <div className="title-text">Примечание</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ fontSize: 24, color: theme.palette.grey['600'] }}>
                        Данное примечание увидите только вы и мастер
                    </Box>
                    <TextField
                        name="note"
                        defaultValue={issue?.note}
                        onChange={changeNoteHandler}
                        placeholder="Примечание"
                        fullWidth
                        params={{ multiline: true, minRows: 4, margin: 'normal' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={putNoteHandler}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default memo(Note);
