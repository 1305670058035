import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ERoutes } from '../../../../enums/routes.enum';
import {
    deleteServiceThunk,
    getServicesThunk,
    setIsOpenDeletePriceServicesAction,
} from '../../../../store/actions/pricesServices';
import { useAppDispatch } from '../../../../store/hooks';
import { priceServiceSelector, priceServicesSelector } from '../../../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import showToast from '../../../../utils/helpers/showToast';

function FooterForm() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const service = useSelector(priceServiceSelector);
    const services = useSelector(priceServicesSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { id: serviceId } = service || {};

    async function submitFormHandler() {
        try {
            if (!serviceId) return;
            setIsLoading(true);
            await dispatch(deleteServiceThunk(serviceId));
            await dispatch(getServicesThunk());
            await dispatch(setIsOpenDeletePriceServicesAction(false));
            await (!services && navigate(`/${ERoutes.Prices}`));
            showToast({
                isVisible: true,
                message: 'Тип услуги удален',
            });
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Box mt={2}>
            <Button
                onClick={() => dispatch(setIsOpenDeletePriceServicesAction(false))}
                variant="outlined"
            >
                Отмена
            </Button>

            <LoadingButton
                onClick={submitFormHandler}
                loading={isLoading}
                color="error"
                variant="contained"
                sx={{ ml: 1 }}
            >
                Удалить
            </LoadingButton>
        </Box>
    );
}

export default FooterForm;
