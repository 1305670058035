import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

/* TODO Теги - выяснить что это такое */
export default class SuggestionsAPI {
    getSuggestions(queries: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/suggestions${queries}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
