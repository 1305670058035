import { setIsOpenEditIssueReminderAction } from 'store/actions/edit/editIssueReminder';
import { getIssueReminderThunk, setIsOpenDeleteReminderActions } from 'store/actions/reminders';
import { useAppDispatch } from 'store/hooks';
import { dateFormatter } from 'utils/helpers/dateFormatter';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IReminder } from 'interfaces/reminder';
import React, { useState } from 'react';

interface IProps {
    reminder: IReminder;
    handleClose: () => void;
}

function ReminderItem({ reminder, handleClose }: IProps) {
    const dispatch = useAppDispatch();
    const { text, send_only_bo_user_created, datetime_delivery, id } = reminder;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const date = dateFormatter(
        datetime_delivery,
        {},
        {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        },
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseReminderMenu = () => {
        handleClose();
        setAnchorEl(null);
    };

    const openEditReminderHandler = async () => {
        dispatch(setIsOpenEditIssueReminderAction(true));
        await dispatch(getIssueReminderThunk(id));
        handleCloseReminderMenu();
    };

    const openDeleteReminderHandler = async () => {
        dispatch(setIsOpenDeleteReminderActions(true));
        await dispatch(getIssueReminderThunk(id));
        handleCloseReminderMenu();
    };

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            sx={{ width: '100%' }}
        >
            <>
                <div className="reminders-item">
                    {text} ({send_only_bo_user_created ? 'личное' : 'общее'})<div>{date}</div>
                </div>
                <IconButton
                    aria-owns="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit"
                    size="small"
                >
                    <MoreVert fontSize="small" />
                </IconButton>
            </>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={openEditReminderHandler}>Редактировать напоминание</MenuItem>
                <MenuItem
                    onClick={openDeleteReminderHandler}
                    sx={{ color: 'red' }}
                >
                    Удалить напоминание
                </MenuItem>
            </Menu>
        </Box>
    );
}

export default ReminderItem;
