import { Checkbox, Divider, FormControl, FormGroup, FormHelperText, Tab, Tabs } from '@mui/material';
import React, { ChangeEvent, createRef, memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

import Loader from '../../../../../components/Loader';
import { EIssueHelpType } from '../../../../../enums/issues.enum';
import { helpTypeForWorkersMock } from '../../../../../mockData/workers';
import { getCategoriesThunk } from '../../../../../store/actions/categories';
import { setFormValuesEditWorkerSpecializationsAction } from '../../../../../store/actions/edit/editWorkerSpecializations';
import { setWorkerSelectedTabsAction } from '../../../../../store/actions/worker';
import { useAppDispatch } from '../../../../../store/hooks';
import { categoriesPaginationSelector, categoriesSelector } from '../../../../../store/selectors/categoriesSelectors';
import {
    errorsEditWorkerSpecializationsSelector,
    formValuesEditWorkerSpecializationsSelector,
} from '../../../../../store/selectors/edit/editWorkerSpecializationsSelectors';
import { workerSelectedTabSelector } from '../../../../../store/selectors/workerSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getCurrentFormValuesSpecialization } from '../../../../../utils/helpers/getCurrentFormValuesSpecialization';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import './EditWorkerSpecializationsForm.scss';

function EditWorkerSpecializationsForm() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesEditWorkerSpecializationsSelector);
    const errors = useSelector(errorsEditWorkerSpecializationsSelector);

    const categories = useSelector(categoriesSelector);
    const selectedTab = useSelector(workerSelectedTabSelector);
    const pagination = useSelector(categoriesPaginationSelector);
    const optionsContainerRef = useRef<null | HTMLDivElement>(null);

    const [isLoadMoreAppointments, setIsLoadMoreAppointments] = useState<boolean | null>(false);
    const [entriesObserver, setEntriesObserver] = useState<IntersectionObserverEntry[] | null>(null);
    const loadMoreAppointmentsElem = createRef<HTMLTableRowElement>();

    useEffect(() => {
        const observerOptions = {
            root: document.querySelector('.patients-table .archive'),
            threshold: 1.0,
        };
        const observer = new IntersectionObserver((entries) => {
            setEntriesObserver(entries);
        }, observerOptions);
        loadMoreAppointmentsElem.current && observer.observe(loadMoreAppointmentsElem.current);

        return () => {
            loadMoreAppointmentsElem.current && observer.unobserve(loadMoreAppointmentsElem.current);
        };
    }, []);

    useEffect(() => {
        async function getMoreAppointmentsOnObserve() {
            if (entriesObserver && entriesObserver[0]?.isIntersecting) {
                try {
                    if (
                        categories &&
                        pagination &&
                        pagination?.total > Number(pagination.size * pagination.page) &&
                        isLoadMoreAppointments === false
                    ) {
                        setIsLoadMoreAppointments(true);
                        await dispatch(getCategoriesThunk({ categories, page: pagination.page + 1 }));
                    }
                } catch (err) {
                    defaultErrorCallback({ errorMessage: getErrorString({ err }) });
                } finally {
                    setIsLoadMoreAppointments(false);
                }
            }
        }

        getMoreAppointmentsOnObserve();
    }, [entriesObserver]);

    useEffect(() => {
        optionsContainerRef.current?.scrollTo(0, 0);
    }, [selectedTab]);

    const handleChangeTab = (tabValue: EIssueHelpType) => {
        dispatch(setWorkerSelectedTabsAction(tabValue));
    };

    function onChangeSubOptions(e: ChangeEvent<HTMLInputElement>, checked: boolean) {
        const subOptionId = e.target.value;

        let newFormValues = formValues?.length ? [...formValues] : [];
        const foundIndex = newFormValues.findIndex(({ help_type }) => help_type === selectedTab);

        if (foundIndex === -1) {
            newFormValues.push({ help_type: selectedTab, category_ids: [subOptionId] });
        } else if (foundIndex !== -1) {
            newFormValues =
                formValues?.map((obj) => {
                    if (obj.help_type === selectedTab) {
                        return {
                            help_type: obj.help_type,
                            // eslint-disable-next-line no-nested-ternary
                            category_ids: checked
                                ? obj.category_ids.length
                                    ? [...obj.category_ids, subOptionId]
                                    : [subOptionId]
                                : obj.category_ids.filter((categoryId) => categoryId !== subOptionId),
                        };
                    }
                    return obj;
                }) || [];
        }
        dispatch(setFormValuesEditWorkerSpecializationsAction(newFormValues));
    }

    return (
        <div className="edit-worker-specialization-form-wrapper">
            <FormControl
                fullWidth
                error={!!errors?.sub_options}
            >
                <FormGroup>
                    {categories?.length && (
                        <>
                            <Tabs
                                value={selectedTab}
                                onChange={(event: React.SyntheticEvent, tabValue) => handleChangeTab(tabValue)}
                            >
                                {helpTypeForWorkersMock.map(({ value, label }) => (
                                    <Tab
                                        key={value}
                                        value={value}
                                        label={`${label} ${
                                            (formValues?.length &&
                                                getCurrentFormValuesSpecialization(formValues, value)?.length) ||
                                            0
                                        }`}
                                    />
                                ))}
                            </Tabs>
                            <Divider />
                        </>
                    )}
                    {categories && (
                        <div
                            className="specialization-options-container"
                            ref={optionsContainerRef}
                        >
                            {categories?.map((category) => {
                                const currentFormValues = getCurrentFormValuesSpecialization(formValues, selectedTab);
                                return (
                                    <div
                                        className="specialization-option"
                                        key={category.id}
                                    >
                                        <div
                                            className="specialization-sub-option"
                                            key={category.id}
                                        >
                                            <Checkbox
                                                onChange={onChangeSubOptions}
                                                value={category.id}
                                                checked={
                                                    currentFormValues ? currentFormValues.includes(category.id) : false
                                                }
                                            />
                                            <span className="sub-option-title">{category.name}</span>
                                        </div>
                                    </div>
                                );
                            })}
                            <div
                                ref={loadMoreAppointmentsElem}
                                style={{ position: 'relative', bottom: 5, height: 20, border: 'none' }}
                            >
                                <div>{isLoadMoreAppointments && <Loader />}</div>
                            </div>
                        </div>
                    )}
                </FormGroup>
                <FormHelperText>{errors?.sub_options?.message}</FormHelperText>
            </FormControl>
            <FooterForm />
        </div>
    );
}

export default memo(EditWorkerSpecializationsForm);
