import Loader from 'components/Loader';
import { EChatTypes } from 'enums/chats.enum';
import { profileSelector } from 'store/selectors/profileSelectors';

import Close from '@mui/icons-material/Close';
import { IMessageWithSenderInfo } from 'interfaces/chat';
import { IChatAvailable } from 'interfaces/chats';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import AddMessageForm from './AddMessageForm';
import Messages from './Messages';

import './Chat.scss';

interface IProps {
    messages: IMessageWithSenderInfo[] | null;
    deleteMessage: (id: string) => void;
    sendMessage: (text?: string, files?: File[]) => void;
    isLoadingUploadFiles: boolean;
    markMessageAsViewed: (messageId: string) => void;
    chat_type?: EChatTypes;
    chat_available?: IChatAvailable | null;
    messagesLoading: boolean;
}

function Chat({
    messages,
    deleteMessage,
    sendMessage,
    isLoadingUploadFiles,
    markMessageAsViewed,
    chat_available,
    messagesLoading,
}: IProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const profile = useSelector(profileSelector);

    useEffect(() => {
        if (!wrapperRef.current) return;
        wrapperRef.current?.scrollTo(0, wrapperRef.current.scrollHeight);
    }, [messagesLoading]);

    useEffect(() => {
        if (!wrapperRef.current || !messages?.length) return;
        const shouldScroll = messages[0]?.sender_id === profile?.id;
        if (shouldScroll) {
            wrapperRef.current?.scrollTo(0, wrapperRef.current.scrollHeight);
        }
    }, [messages]);

    const chatRef = useRef(null);

    const [previewImgUrl, setPreviewImgUrl] = useState<string | null>(null);

    if (messagesLoading) return <Loader />;
    return (
        <div
            ref={chatRef}
            className="chat"
        >
            {previewImgUrl ? (
                <button
                    onClick={() => setPreviewImgUrl(null)}
                    className="image-preview"
                >
                    <Close className="close-btn" />
                    <img
                        onClick={(e) => e.stopPropagation()}
                        src={previewImgUrl}
                        alt="preview"
                    />
                </button>
            ) : null}
            <div
                className="messages-wrapper"
                ref={wrapperRef}
            >
                {messages?.length ? (
                    <Messages
                        messages={messages}
                        messagesLoading={messagesLoading}
                        deleteMessage={deleteMessage}
                        markMessageAsViewed={markMessageAsViewed}
                        setPreviewImgUrl={setPreviewImgUrl}
                    />
                ) : (
                    <div className="empty-messages-label">Пока сообщений нет</div>
                )}
            </div>
            {chat_available?.write && (
                <AddMessageForm
                    chatRef={chatRef}
                    sendMessage={sendMessage}
                    isLoadingUploadFiles={isLoadingUploadFiles}
                />
            )}
        </div>
    );
}

export default Chat;
