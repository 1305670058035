import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import GenerateXlsForm from './GenerateXlsForm';

import {
    setErrorsGenerateXlsAction,
    setFormValuesGenerateXlsAction,
    setIsOpenGenerateXlsAction,
} from '../../../store/actions/generateXls';
import { useAppDispatch } from '../../../store/hooks';
import { isOpenGenerateXlsSelector } from '../../../store/selectors/generateXlsSelectors';
import './GenerateXls.scss';

function GenerateXls() {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(isOpenGenerateXlsSelector);

    function onCloseHandler() {
        dispatch(setIsOpenGenerateXlsAction(false));
        dispatch(setFormValuesGenerateXlsAction(null));
        dispatch(setErrorsGenerateXlsAction(null));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="generate-xls-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Выгрузить отчёт xls</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <GenerateXlsForm />
            </DialogContent>
        </Dialog>
    );
}

export default GenerateXls;
