import { Chip, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EIssueStatus } from '../../../../../enums/issues.enum';
import { ERoutes } from '../../../../../enums/routes.enum';
import { IIssue } from '../../../../../interfaces/issues';
import { issueCreatedSourceMock, issueHelpTypeNamesMock, issueStatusesMock } from '../../../../../mockData/issues';
import { dateFormatter } from '../../../../../utils/helpers/dateFormatter';
import { getFullName } from '../../../../../utils/helpers/getFullName';
import { timeWindowsFormatter } from '../../../../../utils/helpers/timeWindowsFormatter';

function ArchiveIssueRow(issue: IIssue) {
    const navigate = useNavigate();

    const {
        id,
        oid,
        client,
        help_type,
        status,
        created_at,
        worker,
        created_source,
        region,
        count_unseen_messages,
        time_windows,
        partner,
    } = issue;

    return (
        <TableRow
            onClick={() => {
                navigate(`/${ERoutes.Archive}/${id}`);
            }}
            sx={{ cursor: 'pointer' }}
        >
            <TableCell>{oid}</TableCell>
            <TableCell>
                {!!client && !client.is_removed ? (
                    getFullName(client)
                ) : (
                    <span style={{ color: '#8A92A6' }}>Пользователь удален</span>
                )}
            </TableCell>
            <TableCell>{issueHelpTypeNamesMock[help_type]}</TableCell>
            <TableCell padding="none">
                <Chip
                    label={issueStatusesMock[status]?.title}
                    sx={{
                        fontWeight: '600',
                        backgroundColor: issueStatusesMock[status]?.colorHex,
                        color: issueStatusesMock[status]?.colorText,
                        '&:hover': { backgroundColor: `${issueStatusesMock[status]?.colorHex}`.slice(0, -2) + 99 },
                    }}
                    size={'small'}
                    clickable
                />
            </TableCell>
            <TableCell>
                {dateFormatter(
                    created_at,
                    {},
                    { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
                )}
            </TableCell>
            <TableCell sx={{ width: '130px' }}>{timeWindowsFormatter(time_windows)}</TableCell>
            <TableCell>
                {worker ? getFullName(worker) : <span style={{ color: '#8A92A6' }}>Мастер не назначен</span>}
            </TableCell>
            <TableCell sx={{ width: '150px !important' }}>
                {created_source && (
                    <>
                        {issueCreatedSourceMock[created_source]}
                        {partner && <span>/</span>}
                        <div>{partner?.name}</div>
                    </>
                )}
            </TableCell>
            <TableCell>{status === EIssueStatus.Solved ? 'Оплачено' : 'Не оплачено'}</TableCell>
            <TableCell>{region}</TableCell>
            <TableCell>
                {!!count_unseen_messages && (
                    <Chip
                        label={count_unseen_messages}
                        color={'error'}
                        size={'small'}
                    />
                )}
            </TableCell>
            <TableCell>{/*issue.reminders*/}</TableCell>
        </TableRow>
    );
}

export default ArchiveIssueRow;
