import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import FilterDate from '../../../components/FilterDate';
import { EIssueHelpType, EIssueStatus } from '../../../enums/issues.enum';
import { ICategoryRatiosFilter } from '../../../interfaces/categoriesRatiosFilter';
import { TCategoryPreset } from '../../../interfaces/issues';
import { dateCallFiltersMock } from '../../../mockData/issues';
import CategoriesRatiosFilter from '../../../modules/CategoriesRatiosFilter';
import DirectionsFilter from '../../../modules/DirectionsFilter';
import HelpTypeFilter from '../../../modules/HelpTypeFilter';
import IssuesStatusFilter from '../../../modules/IssuesStatusFilter';
import { setFiltersMapAction } from '../../../store/actions/map';
import { useAppDispatch } from '../../../store/hooks';
import { filtersMapSelector } from '../../../store/selectors/mapSelectors';
import './MapFilters.scss';

function MapFilters() {
    const dispatch = useAppDispatch();
    const filters = useSelector(filtersMapSelector);

    function onChangeDateCallFilter(data: { startTime: string | null; endTime: string | null }) {
        dispatch(
            setFiltersMapAction({
                ...(filters || {}),
                time_window__gte: data.startTime ? data.startTime : null,
                time_window__lte: data.endTime ? data.endTime : null,
            }),
        );
    }

    function onChangeRatiosHandler(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        dispatch(
            setFiltersMapAction({
                ...(filters || {}),
                region_id__in: categoriesIds,
            }),
        );
    }

    function selectHelpTypeForWorkers(helpType: EIssueHelpType[] | null) {
        dispatch(setFiltersMapAction({ ...(filters || {}), help_type: helpType }));
    }

    function onChangeCategoryPreset(categoryPreset: TCategoryPreset | null) {
        dispatch(setFiltersMapAction({ ...(filters || {}), category_preset: categoryPreset }));
    }

    function onChangeIssueStatus(issuesStatusStr: EIssueStatus[] | null) {
        dispatch(setFiltersMapAction({ ...(filters || {}), status: issuesStatusStr }));
    }

    return (
        <div className="map-filters-wrapper">
            <Box
                display={'flex'}
                columnGap={2}
                width="100%"
            >
                <CategoriesRatiosFilter onChange={onChangeRatiosHandler} />
                <FilterDate
                    title="Дата вызова"
                    filterItems={dateCallFiltersMock}
                    onChange={onChangeDateCallFilter}
                />
                <HelpTypeFilter
                    selectedHelpType={filters?.help_type || null}
                    onChange={selectHelpTypeForWorkers}
                />
                <DirectionsFilter
                    onChange={onChangeCategoryPreset}
                    selectedCategoryPreset={filters?.category_preset}
                />
                <IssuesStatusFilter
                    selectedIssueStatus={filters?.status || null}
                    onChange={onChangeIssueStatus}
                />
            </Box>
        </div>
    );
}

export default MapFilters;
