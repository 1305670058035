import { EToastActions } from '../../enums/actions.enum';
import { IToastState, TToastActions } from '../../interfaces/toast';

const initState: IToastState = {
    visible: false,
    message: null,
    vertical: null,
    horizontal: null,
    delay: null,
    severity: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const toastReducer = (state = initialState, action: TToastActions): IToastState => {
    switch (action.type) {
        case EToastActions.SetToast:
            return {
                ...state,
                visible: true,
                ...action.payload,
            };
        case EToastActions.SetCancelToast:
            return {
                ...initState,
            };
        default:
            return state;
    }
};
