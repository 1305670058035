import { EIssueHelpType } from '../enums/issues.enum';
import { EPartsOrdersStatus } from '../enums/partsOrders.enum';

export const partsOrdersStatusMock = {
    [EPartsOrdersStatus.InSearch]: {
        title: 'Поиск запчастей',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EPartsOrdersStatus.WaitingApproval]: {
        title: 'Согласование с клиентом',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EPartsOrdersStatus.InProgress]: {
        title: 'Заказ запчастей',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EPartsOrdersStatus.Awaiting]: {
        title: 'Ожидание запчастей',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EPartsOrdersStatus.InStock]: {
        title: 'Запчасть на складе',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EPartsOrdersStatus.WorkerShipped]: {
        title: 'Запчасть отгружена мастеру',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EPartsOrdersStatus.WaitingDelivery]: {
        title: 'Запчасть ожидает выдачи',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
    },
    [EPartsOrdersStatus.Received]: {
        title: 'Запчасть получена',
        color: 'success',
        colorHex: '#5FEA7E66',
        colorText: '#388E3C',
    },
    [EPartsOrdersStatus.Canceled]: {
        title: 'Отказ от запчасти',
        color: 'success',
        colorHex: '#5FEA7E66',
        colorText: '#388E3C',
    },
    [EPartsOrdersStatus.Completed]: {
        title: 'Выполнен',
        color: 'success',
        colorHex: '#5FEA7E66',
        colorText: '#388E3C',
    },
    [EPartsOrdersStatus.Closed]: {
        title: 'Закрыт',
        color: 'success',
        colorHex: '#5FEA7E66',
        colorText: '#388E3C',
    },
};

export const partsOrderEditButtonAllowed: EPartsOrdersStatus[] = [
    EPartsOrdersStatus.InSearch,
    EPartsOrdersStatus.WorkerShipped,
    EPartsOrdersStatus.WaitingApproval,
    EPartsOrdersStatus.InProgress,
    EPartsOrdersStatus.Awaiting,
    EPartsOrdersStatus.InStock,
    EPartsOrdersStatus.WaitingDelivery,
    EPartsOrdersStatus.Canceled,
    EPartsOrdersStatus.Completed,
];

export const partsOrderEditAllowed: EPartsOrdersStatus[] = [
    EPartsOrdersStatus.InSearch,
    EPartsOrdersStatus.WaitingApproval,
];

export const partsOrderAllowed: EIssueHelpType[] = [EIssueHelpType.Repair, EIssueHelpType.Installation];
