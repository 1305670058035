import { EChatGroupByUsersActions } from '../../enums/actions.enum';
import { IChatGroupByUser, IChatGroupByUsersState, TChatGroupByUsersActions } from '../../interfaces/chatGroupByUsers';
import mergeArrays from '../../utils/helpers/mergeArrays';

const initialState: IChatGroupByUsersState = {
    items: null,
    isLoading: false,
    pagination: { page: 0, size: 0, total: 0 },
    filters: {
        params: {
            search: null,
            user_type: null,
            has_unread_message: null,
        },
        isLoading: false,
    },
    reload: {
        shouldReload: false,
        reloadCallback: () => {},
    },
};

function insertChatGroupByUsersItems(current: IChatGroupByUser[], items: IChatGroupByUser[]) {
    let newItems = mergeArrays(current, items, (value) => `${value.user_type}:${value.user_id}`);
    newItems = newItems.sort((a, b) => {
        if (a.last_message && b.last_message) {
            // @ts-ignore
            return new Date(b.last_message.created_at) - new Date(a.last_message.created_at);
        } else if (a.last_message && !b.last_message) {
            return -1;
        } else if (!a.last_message && b.last_message) {
            return 1;
        } else {
            // @ts-ignore
            return new Date(b.created_at) - new Date(a.created_at);
        }
    });

    return newItems.slice(0, current.length);
}

// eslint-disable-next-line default-param-last
export const chatGroupByUsersReducer = (state = initialState, action: TChatGroupByUsersActions) => {
    switch (action.type) {
        case EChatGroupByUsersActions.PutChatGroupByUsers:
            return {
                ...state,
                items: insertChatGroupByUsersItems(action.payload || [], []),
            };
        case EChatGroupByUsersActions.SetChatGroupByUsers:
            return {
                ...state,
                items: insertChatGroupByUsersItems([...(state.items || []), ...(action.payload || [])], []),
            };
        case EChatGroupByUsersActions.SetChatGroupByUsersIsLoading:
            return {
                ...state,
                isLoading: action.payload,
            };
        case EChatGroupByUsersActions.SetChatGroupByUsersPagination:
            return {
                ...state,
                pagination: action.payload,
            };
        case EChatGroupByUsersActions.InsertChatGroupByUsers:
            return {
                ...state,
                items: insertChatGroupByUsersItems(state.items || [], action.payload),
            };
        case EChatGroupByUsersActions.SetChatGroupByUsersFilters:
            return {
                ...state,
                filters: {
                    params: action.payload.params,
                    isLoading: action.payload.isLoading,
                },
            };
        case EChatGroupByUsersActions.SetShouldReload:
            return {
                ...state,
                reload: { ...state.reload, ...action.payload },
            };
        case EChatGroupByUsersActions.ResetChatGroupByUsers:
            return initialState;
        default:
            return state;
    }
};
