import { EUnbindWorkerMoySkladActions } from '../../../enums/actions.enum';
import { IUnbindWorkerMoySkladState, TUnbindWorkerMoySkladActions } from '../../../interfaces/edit/unbindMoySklad';

const initState: IUnbindWorkerMoySkladState = {
    workerId: null,
    storeId: null,
};

const initialState = { ...initState };

export const unbindWorkerMoySkladReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TUnbindWorkerMoySkladActions,
): IUnbindWorkerMoySkladState => {
    switch (action.type) {
        case EUnbindWorkerMoySkladActions.SetFormValues:
            if (action.payload) {
                return {
                    ...state,
                    workerId: action.payload.worker_id,
                    storeId: action.payload.store_id,
                };
            }
            return state;
        default:
            return state;
    }
};
