import { EReminderActions } from '../../enums/actions.enum';
import { IReminderState, TReminderActions } from '../../interfaces/reminder';

const initState: IReminderState = {
    reminders: null,
    reminder: null,
    isOpenDeleteReminder: false,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const remindersReducer = (state = initialState, action: TReminderActions): IReminderState => {
    switch (action.type) {
        case EReminderActions.SetReminders:
            return {
                ...state,
                reminders: action.payload,
            };
        case EReminderActions.SetReminder:
            return {
                ...state,
                reminder: action.payload,
            };
        case EReminderActions.SetIsOpenDelete:
            return {
                ...state,
                isOpenDeleteReminder: action.payload,
            };
        default:
            return state;
    }
};
