import { AppDispatch } from 'store/store';

import { websocketsApi } from '../../api';
import { EWebsocketsActions } from '../../enums/actions.enum';
import {
    IMarkAsViewedReq,
    ISetWSChannelAction,
    ISetWSHistory,
    IWSChannel,
    IWSHistory,
} from '../../interfaces/websockets';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';

export const setWSChannelAction = (payload: IWSChannel | null): ISetWSChannelAction => ({
    type: EWebsocketsActions.SetWSChannel,
    payload,
});

export const setWSHistoryAction = (payload: IWSHistory[] | null): ISetWSHistory => ({
    type: EWebsocketsActions.SetWSHistory,
    payload,
});

export const getWebsocketChannelThunk = () => async (dispatch: AppDispatch) => {
    try {
        const { data } = await websocketsApi.getChanel();

        dispatch(setWSChannelAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getWSHistoryThunk = () => async (dispatch: AppDispatch) => {
    try {
        const defaultPage = 1;
        const defaultSize = 25;
        const { data } = await websocketsApi.getHistory(
            getQueryParamsAsString([
                { page: defaultPage },
                { size: defaultSize },
                { userSeen: 'all' },
                { order_by: '-created_at' },
            ]),
        );
        const { items } = data;
        dispatch(setWSHistoryAction(items));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

interface IMarkAsViewedWSHistoryThunkParams {
    notification_ids: IMarkAsViewedReq['notification_ids'];
    wsHistory: IWSHistory[];
}
export const markAsViewedWSHistoryThunk =
    ({ notification_ids, wsHistory }: IMarkAsViewedWSHistoryThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            await websocketsApi.postMarkAsViewed({ notification_ids });
            const newWsHistory = wsHistory.map((history) => {
                return { ...history };
            });
            notification_ids.forEach((id) => {
                newWsHistory.forEach((history) => {
                    if (id === history.id && 'seen' in history) {
                        // eslint-disable-next-line no-param-reassign
                        history.seen = true;
                    }
                });
            });
            dispatch(setWSHistoryAction(newWsHistory));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
