import { priceListsApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import { FilterIn } from 'features/filter-in';
import { FilterIs } from 'features/filter-is';
import { fetchPriceLists, setFilters } from 'pages/price-lists/store';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { useAppDispatch } from 'store/hooks';

import { Button } from '@mui/material';
import { AxiosPromise } from 'axios';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ControlsWrapper, FiltersWrapper } from './styles';

type TFilterTypes = 'pk__in' | 'service_type_id__in' | 'company_id__in' | 'region_id__in';

const isActiveItems = [
    { value: true, title: 'Да' },
    { value: false, title: 'Нет' },
];

export const Controls = () => {
    const dispatch = useAppDispatch();

    const { filters } = useSelector(priceListsSelector);

    const converter = async (func: any, params: any) => {
        const res = await func({ size: 20, ...params });
        return { data: { items: res.data, total: res.data.length } };
    };

    const handleChangeFilter = (itemId: string, filterType: TFilterTypes) => {
        const getIds = () => {
            if (filters[filterType].includes(itemId as never)) {
                return filters[filterType].filter((id) => id !== itemId);
            } else {
                return [...filters[filterType], itemId];
            }
        };

        dispatch(setFilters({ [filterType]: getIds() }));
        dispatch(fetchPriceLists());
    };

    const handleChangeIsActiveFilter = (value: boolean | null) => {
        dispatch(setFilters({ is_active: value }));
        dispatch(fetchPriceLists());
    };

    const handleFilterClean = (key: string) => {
        dispatch(setFilters({ [key]: [] }));
        dispatch(fetchPriceLists());
    };

    return (
        <ControlsWrapper>
            <FiltersWrapper>
                <FilterIn
                    title="Тип"
                    onChange={(itemId) => handleChangeFilter(itemId, 'service_type_id__in')}
                    activeIds={filters.service_type_id__in}
                    fetcher={(params) => converter(priceListsApi.getServicesTypes, params) as AxiosPromise}
                    cleaner={() => handleFilterClean('service_type_id__in')}
                />
                <FilterIn
                    title="Партнер"
                    onChange={(itemId) => handleChangeFilter(itemId, 'company_id__in')}
                    activeIds={filters.company_id__in}
                    fetcher={(params) => converter(priceListsApi.getCompanies, params) as AxiosPromise}
                    cleaner={() => handleFilterClean('company_id__in')}
                />
                <FilterIn
                    title="Название"
                    onChange={(itemId) => handleChangeFilter(itemId, 'pk__in')}
                    activeIds={filters.pk__in}
                    fetcher={(params) => priceListsApi.getPriceLists({ size: 20, ...params })}
                    cleaner={() => handleFilterClean('pk__in')}
                />
                <FilterIn
                    title="Регион"
                    onChange={(itemId) => handleChangeFilter(itemId, 'region_id__in')}
                    activeIds={filters.region_id__in}
                    fetcher={(params) => converter(priceListsApi.getRegionality, params) as AxiosPromise}
                    cleaner={() => handleFilterClean('region_id__in')}
                />
                <FilterIs
                    title="Активность"
                    options={isActiveItems}
                    activeOption={
                        isActiveItems.find((item) => item.value === filters.is_active) ?? {
                            title: '',
                            value: null,
                        }
                    }
                    onChange={(value) => handleChangeIsActiveFilter(value)}
                />
            </FiltersWrapper>
            <Button variant="contained">
                <Link to={`${ERoutes.CreatePriceLists}`}>Создать прайс-лист</Link>
            </Button>
        </ControlsWrapper>
    );
};
