import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import React, { FunctionComponent } from 'react';

import PricesPagination from './PricesPagination';
import PricesTableHead from './PricesTableHead';

import Loader from '../../../../components/Loader';
import Plug from '../../../../components/Plug';
import { IPagination } from '../../../../interfaces/pagination';
import { IPricesTableHead } from '../../../../interfaces/prices';
import './PricesTable.scss';

interface IProps<T> {
    tableHead: IPricesTableHead[] | null;
    ComponentRow: FunctionComponent<T>;
    tableContent: T[] | null;
    isLoading: boolean;
    pagination: IPagination;
    changePagination: (newPagination: IPagination) => void;
}

function PricesTable<T>({ tableHead, ComponentRow, tableContent, isLoading, pagination, changePagination }: IProps<T>) {
    return (
        <Paper className={'prices-table-wrapper'}>
            {(() => {
                switch (true) {
                    case isLoading:
                        return <Loader />;
                    case !!tableContent && !!tableContent?.length:
                        return (
                            <TableContainer className={'prices-table-container'}>
                                <Table
                                    stickyHeader
                                    size={'small'}
                                    sx={{ background: 'white' }}
                                >
                                    <PricesTableHead tableHead={tableHead} />
                                    <TableBody>
                                        {tableContent &&
                                            tableContent.map((rowContent: T & any) => {
                                                return (
                                                    <ComponentRow
                                                        key={rowContent.id}
                                                        {...rowContent}
                                                    />
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                                <PricesPagination
                                    isLoading={isLoading}
                                    pagination={pagination}
                                    changePagination={changePagination}
                                />
                            </TableContainer>
                        );
                    default:
                        return <Plug />;
                }
            })()}
        </Paper>
    );
}

export default PricesTable;
