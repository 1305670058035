import { AppDispatch } from 'store/store';

import { ordersApi } from '../../api';
import { EOrdersActions } from '../../enums/actions.enum';
import { EPartsOrdersStatus } from '../../enums/partsOrders.enum';
import {
    IGetPartsOrdersParams,
    IOrder,
    IPutOrderReq,
    ISelectedOrders,
    ISetIsOpenCancelOrderAction,
    ISetIsOpenNextStatusOrderAction,
    ISetIsOrdersLoading,
    ISetOrderAction,
    ISetOrdersAction,
    ISetSelectedOrders,
} from '../../interfaces/orders';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const setOrdersAction = (payload: IOrder[] | null): ISetOrdersAction => ({
    type: EOrdersActions.SetOrders,
    payload,
});

export const setOrderAction = (payload: IOrder | null): ISetOrderAction => ({
    type: EOrdersActions.SetOrder,
    payload,
});

export const setIsOpenNextStatusOrderAction = (payload: boolean): ISetIsOpenNextStatusOrderAction => ({
    type: EOrdersActions.SetIsOpenNextStatusOrder,
    payload,
});

export const setIsOpenCancelOrderAction = (payload: {
    isOpen: boolean;
    type: 'move' | 'loss' | null;
}): ISetIsOpenCancelOrderAction => ({
    type: EOrdersActions.SetIsOpenCancelOrder,
    payload,
});

export const setIsOrdersLoadingAction = (payload: boolean): ISetIsOrdersLoading => ({
    type: EOrdersActions.SetIsOrdersLoading,
    payload,
});

export const setSetSelectedOrdersAction = (payload: ISelectedOrders[] | null): ISetSelectedOrders => ({
    type: EOrdersActions.SetSelectedOrders,
    payload,
});

export const getOrdersThunk =
    ({ filters, issue_id }: IGetPartsOrdersParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [{ issue_id }];
            if (filters) {
                // eslint-disable-next-line no-shadow
                const { issue_id } = filters;
                if (issue_id) {
                    if (issue_id) {
                        queriesObj.push({ issue_id });
                    }
                }
            }
            const queries = getQueryParamsAsString(queriesObj);

            const { data } = await ordersApi.getOrders(queries);

            dispatch(setOrdersAction(data));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const cancelOrderThunk = (order_ids: Pick<IPutOrderReq, 'order_ids'>) => async () => {
    try {
        await ordersApi.cancelOrder({ data: order_ids });
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const deleteOrderMoveThunk =
    ({ spare_part_order_ids }: { spare_part_order_ids: string[] }, moveId: string) =>
    async () => {
        try {
            await ordersApi.deleteOrderMove({ spare_part_order_ids, moveId });
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const deleteOrderLossThunk =
    ({ spare_part_order_ids }: { spare_part_order_ids: string[] }, lossId: string) =>
    async () => {
        try {
            await ordersApi.deleteOrderLoss({ spare_part_order_ids, lossId });
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const nextStatusOrderThunk =
    ({ order_ids, status, moysklad_move_target_store }: IPutOrderReq) =>
    async () => {
        try {
            switch (status) {
                case EPartsOrdersStatus.InSearch:
                    await ordersApi.putNextStatusOrder({
                        data: { status: EPartsOrdersStatus.WaitingApproval, order_ids },
                    });
                    break;
                case EPartsOrdersStatus.WaitingApproval:
                    await ordersApi.putNextStatusOrder({
                        data: { status: EPartsOrdersStatus.InProgress, order_ids },
                    });
                    break;
                case EPartsOrdersStatus.InProgress:
                    await ordersApi.putNextStatusOrder({
                        data: { status: EPartsOrdersStatus.Awaiting, order_ids },
                    });
                    break;
                case EPartsOrdersStatus.Awaiting:
                    await ordersApi.putNextStatusOrder({
                        data: { status: EPartsOrdersStatus.InStock, order_ids },
                    });
                    break;
                case EPartsOrdersStatus.InStock:
                    await ordersApi.putNextStatusOrder({
                        data: { status: EPartsOrdersStatus.WorkerShipped, order_ids },
                    });
                    break;
                case EPartsOrdersStatus.WorkerShipped:
                    await ordersApi.putNextStatusOrder({
                        data: { status: EPartsOrdersStatus.WaitingDelivery, order_ids },
                    });
                    break;
                case EPartsOrdersStatus.WaitingDelivery:
                    await ordersApi.putNextStatusOrder({
                        data: { status: EPartsOrdersStatus.Received, order_ids, moysklad_move_target_store },
                    });
                    break;
                default:
                    console.error('Unknown PartsOrdersStatus');
            }
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
