import MainLayout from 'layouts/MainLayout';
import { setIsOpenGenerateXlsAction } from 'store/actions/generateXls';
import { useAppDispatch } from 'store/hooks';

import { Button, Typography } from '@mui/material';

import GenerateXls from './GenerateXls';

import './AutomationPage.scss';

function AutomationPage() {
    const dispatch = useAppDispatch();

    function showDialogCreateGroup() {
        dispatch(setIsOpenGenerateXlsAction(true));
    }

    return (
        <MainLayout>
            <div className="automation-page-wrapper">
                <div className="automation-page-header">
                    <Typography
                        variant={'h4'}
                        fontWeight={500}
                    >
                        Автоматизация
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={showDialogCreateGroup}
                    >
                        Выгрузить xls
                    </Button>
                    <GenerateXls />
                </div>
            </div>
        </MainLayout>
    );
}

export default AutomationPage;
