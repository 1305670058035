import { ECreateIssuePartnerActions } from '../../../enums/actions.enum';
import { ICreateIssuePartnerState, TCreateIssuePartnerActions } from '../../../interfaces/create/createIssuePartner';

const initState: ICreateIssuePartnerState = {
    formValues: null,
    selectedPrices: null,
    step: null,
    errors: null,
};

const initialState = { ...initState };

export const createIssuePartnerReducer = (
    // eslint-disable-next-line default-param-last
    state = initialState,
    action: TCreateIssuePartnerActions,
): ICreateIssuePartnerState => {
    switch (action.type) {
        case ECreateIssuePartnerActions.SetSelectedPrices:
            return {
                ...state,
                selectedPrices: action.payload,
            };
        case ECreateIssuePartnerActions.SetFormValues:
            return {
                ...state,
                formValues: action.payload ? { ...state.formValues, ...action.payload } : null,
            };
        case ECreateIssuePartnerActions.SetStep:
            return {
                ...state,
                step: action.payload,
            };
        case ECreateIssuePartnerActions.SetErrors:
            return {
                ...state,
                errors: action.payload,
            };
        default:
            return state;
    }
};
