import { Box } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import SideBar from './SideBar';
import TopBar from './TopBar';

import { isOpenSideBarSelector } from '../../store/selectors/sideBarSelectors';
import './MainLayoutStyles.scss';

interface IProps {
    children: React.ReactNode;
}

function MainLayout({ children }: IProps) {
    const isOpenSidebar = useSelector(isOpenSideBarSelector);
    return (
        <Box className={cn('main-layout', { 'side-bar-is-open': isOpenSidebar })}>
            <TopBar />

            <SideBar />
            <Box className="main-content">{children}</Box>
        </Box>
    );
}

export default MainLayout;
