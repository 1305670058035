import { Paper, Table, TableBody } from '@mui/material';

import WorkerRow from './WorkerRow';
import WorkersTablePagination from './WorkersPagination';
import WorkersTableHead from './WorkersTableHead';

import Loader from '../../../components/Loader';
import { TChatsSortField } from '../../../interfaces/chats';
import { IPagination } from '../../../interfaces/pagination';
import { IWorker, TDirection, TWorkersSortField } from '../../../interfaces/workers';
import './WorkersTable.scss';

interface IProps {
    workers: IWorker[] | null;
    isLoader: boolean;
    pagination: IPagination;
    changePagination: (newPagination: IPagination) => void;
    sortOptions: Map<TChatsSortField, TDirection>;
    changeSortOption: (value: { sortField: TWorkersSortField; direction: TDirection }) => void;
}

function WorkersTable({ workers, isLoader, pagination, changePagination, sortOptions, changeSortOption }: IProps) {
    return (
        <Paper className={'workers-table-wrapper'}>
            {isLoader && <Loader />}

            <Table
                stickyHeader
                size={'small'}
            >
                <WorkersTableHead
                    sortOptions={sortOptions}
                    changeSortOption={changeSortOption}
                />
                <TableBody>
                    {workers &&
                        workers.map((worker) => {
                            return (
                                <WorkerRow
                                    key={worker.id}
                                    candidate={worker}
                                />
                            );
                        })}
                </TableBody>
            </Table>
            <WorkersTablePagination
                pagination={pagination}
                changePagination={changePagination}
            />
        </Paper>
    );
}

export default WorkersTable;
