import { Add, Delete } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React, { memo, useMemo, useState } from 'react';

import TextField from '../TextField/index';
import './InputStack.scss';

export interface InputStackProps {
    addButtonLabel?: string;
    values?: string[] | undefined;
    onChange?: ((values: string[]) => void) | undefined;
}

function InputStack({ values, onChange, addButtonLabel }: InputStackProps) {
    const [stackValues, setStackValues] = useState(values || []);

    const [forceRerender, setForceRerender] = useState('');

    useMemo(() => {
        if (values?.length) {
            setStackValues(values);
            setForceRerender(Math.random().toFixed(10));
        }
        return null;
    }, [!!(values && values[0])]);

    function updateState(newValues: string[]) {
        setStackValues(newValues);
        onChange && onChange(newValues);
    }

    function onChangeValue(index: number, event: React.ChangeEvent<HTMLInputElement>) {
        const newValues = [...stackValues];
        newValues[index] = event.target.value;
        updateState(newValues);
    }

    function onClickAddButton() {
        const newValues = [...stackValues, ''];
        updateState(newValues);
    }

    function onClickDeleteButton(index: number) {
        const newValues = [...stackValues];
        newValues.splice(index, 1);
        updateState(newValues);
    }

    return (
        <Stack key={forceRerender}>
            {stackValues?.map((value, index) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div
                        className="stack-row"
                        key={`stack_inp_${index}`}
                    >
                        <TextField
                            className="stack-input"
                            name={`stack_inp_${index}`}
                            labelText={`${index + 1}`}
                            value={value}
                            onChange={(event) => onChangeValue(index, event)}
                            params={{ margin: 'dense', inputProps: { maxLength: 500 } }}
                        />
                        <Button
                            className="delete-button"
                            onClick={() => onClickDeleteButton(index)}
                        >
                            <Delete />
                        </Button>
                    </div>
                );
            })}
            <Button
                className="add-button"
                onClick={onClickAddButton}
            >
                <Add /> {addButtonLabel || 'Добавить'}
            </Button>
        </Stack>
    );
}

export default memo(InputStack);
