import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';

import { EIssueHelpType } from '../../../enums/issues.enum';
import { ICategoryRatiosFilter } from '../../../interfaces/categoriesRatiosFilter';
import { IGroup } from '../../../interfaces/groups';
import CategoriesFilter from '../../../modules/CategoriesFilter';
import CategoriesRatiosFilter from '../../../modules/CategoriesRatiosFilter';
import GroupFilter from '../../../modules/GroupFilter';
import HelpTypeFilter from '../../../modules/HelpTypeFilter';
import Search from '../../../modules/WorkersSelect/Search';
import { setGroupAction } from '../../../store/actions/groups';
import { setWorkersFiltersAction } from '../../../store/actions/workers';
import { useAppDispatch } from '../../../store/hooks';
import { workersFiltersSelector } from '../../../store/selectors/workersSelectors';
import WorkersTabs from '../WokersTabs';
import './WorkersFilters.scss';

function WorkersFilters() {
    const dispatch = useAppDispatch();

    const filters = useSelector(workersFiltersSelector);

    function selectCategories(categoriesIds: ICategoryRatiosFilter['id'][] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), area_id__in: categoriesIds }));
    }

    function selectCRFIdsForWorkers(selectCRFIds: string[] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), specialization__category_id__in: selectCRFIds }));
    }
    function selectHelpTypeForWorkers(helpType: EIssueHelpType[] | null) {
        dispatch(setWorkersFiltersAction({ ...(filters || {}), specialization__help_type__in: helpType }));
    }

    function selectGroup(group: IGroup | null) {
        dispatch(setGroupAction(group));
        dispatch(setWorkersFiltersAction({ ...filters, group_id__in: group }));
    }

    function searchWorker(event: ChangeEvent<HTMLInputElement>) {
        dispatch(setWorkersFiltersAction({ ...filters, search: event.target.value }));
    }

    return (
        <div className="workers-filters-wrapper">
            <WorkersTabs />
            <div className="extra-filters">
                <CategoriesRatiosFilter onChange={selectCategories} />
                <CategoriesFilter
                    selectedCRFIdsForWorkers={filters?.specialization__category_id__in || null}
                    onChange={selectCRFIdsForWorkers}
                />
                <HelpTypeFilter
                    selectedHelpType={filters?.specialization__help_type__in || null}
                    onChange={selectHelpTypeForWorkers}
                />
                <GroupFilter
                    selectedGroup={filters?.group_id__in || null}
                    onChange={selectGroup}
                />
            </div>
            <Search
                onChange={searchWorker}
                placeholder="Поиск по ФИО, номеру телефона..."
            />
        </div>
    );
}

export default WorkersFilters;
