import { TableCell, TableHead, TableRow } from '@mui/material';
import React, { memo } from 'react';

function ImportTableHead() {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Номер заказа партнера</TableCell>
                <TableCell>ФИО</TableCell>
                <TableCell>Номер телефона</TableCell>
                <TableCell>Адрес</TableCell>
                <TableCell>Техника</TableCell>
                <TableCell>Серийный номер</TableCell>
                <TableCell>Дата покупки</TableCell>
                <TableCell>Услуга</TableCell>
                <TableCell>Примечание к заявке</TableCell>
                <TableCell colSpan={2}>Дубли</TableCell>
            </TableRow>
        </TableHead>
    );
}

export default memo(ImportTableHead);
