import src from 'static/images/video.png';

import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { Dialog } from '@mui/material';
import { memo, useState } from 'react';

import { IVideoFile } from '../../interfaces';

import './VideoFiles.scss';

interface IProps {
    videoFiles: IVideoFile[];
}

function VideoFiles({ videoFiles }: IProps) {
    const [activeUrl, setUrl] = useState('');

    return (
        <div className="dv-photo-files">
            {videoFiles.map(({ url, id, previews }) => {
                const preview = previews && previews[0];
                return (
                    <div
                        className="dv-video"
                        key={id}
                        onClick={() => setUrl(url)}
                    >
                        <PlayCircleOutlinedIcon
                            color="primary"
                            className="dv-pf-play-icon"
                        />
                        {preview?.url ? (
                            <img
                                src={preview?.url}
                                alt="video"
                            />
                        ) : (
                            <img
                                height="100%"
                                width="100%"
                                src={src}
                                alt="video"
                            />
                        )}
                    </div>
                );
            })}
            <Dialog
                sx={{ '.MuiPaper-root': { background: 'transparent', width: 'auto', maxWidth: 'fit-content' } }}
                open={Boolean(activeUrl)}
                onClose={() => setUrl('')}
                fullWidth
            >
                <video
                    controls
                    style={{
                        margin: '0 auto',
                        objectFit: 'contain',
                        maxHeight: '100vh',
                        maxWidth: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <source src={activeUrl} />
                    <track
                        src="captions_en.vtt"
                        kind="captions"
                        label="Видео про отзыв"
                    />
                </video>
            </Dialog>
        </div>
    );
}

export default memo(VideoFiles);
