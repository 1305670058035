import { AppDispatch } from 'store/store';

import { profileApi } from '../../api';
import { EProfileActions } from '../../enums/actions.enum';
import { ELocalStorageItems } from '../../enums/localStorage.enum';
import { ILogoutAction, IProfile, ISetIsAuthAction, ISetProfileAction } from '../../interfaces/profile';

export const setProfileAction = (payload: IProfile | null): ISetProfileAction => ({
    type: EProfileActions.SetProfile,
    payload,
});

export const setIsAuthAction = (payload: boolean): ISetIsAuthAction => ({
    type: EProfileActions.SetIsAuth,
    payload,
});

export const logout = (): ILogoutAction => ({
    type: EProfileActions.Logout,
});

export const getProfileThunk = () => async (dispatch: AppDispatch) => {
    if (localStorage.getItem(ELocalStorageItems.token)) {
        try {
            const { data: profile } = await profileApi.getProfile();
            dispatch(setProfileAction(profile));
            dispatch(setIsAuthAction(true));
        } catch (error) {
            console.error(error);
            dispatch(setIsAuthAction(false));
        }
    } else {
        dispatch(setIsAuthAction(false));
    }
};
