import { Button } from 'components/Button';
import { setIsOpenBindWorkerMoySkladAction } from 'store/actions/create/bindMoySklad';
import {
    getWorkerAssortmentThunk,
    setIsOpenCreateWorkerMoySkladAction,
    setWorkerMoySkladAssortmentAction,
    setWorkerMoySkladUnbindAction,
} from 'store/actions/create/createMoySklad';
import { unbindWorkerMoySkladThunk } from 'store/actions/edit/unbindMoySklad';
import { useAppDispatch } from 'store/hooks';
import { workerDetailSelector } from 'store/selectors/workerSelectors';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import SearchIcon from '@mui/icons-material/Search';
import { Paper, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import './WorkerStore.scss';

import BindWorkerMoySklad from './BindWorkerMoySkald';
import CreateWorkerMoySklad from './CreateWorkerMoySklad';

function WorkerStore() {
    const dispatch = useAppDispatch();
    const worker = useSelector(workerDetailSelector);

    useEffect(() => {
        const fetchWorkerAssortment = async () => {
            if (!worker?.moysklad_store) return;
            try {
                const workerAssortment = await dispatch(
                    getWorkerAssortmentThunk({ stockStore: worker.moysklad_store.meta_data.href }),
                );
                dispatch(setWorkerMoySkladAssortmentAction(workerAssortment));
            } catch (err) {
                console.error(err);
            }
        };
        fetchWorkerAssortment();
    }, [worker?.moysklad_store]);

    async function submitFormHandler() {
        if (!worker?.id || !worker.moysklad_store?.store_id) return;
        try {
            await dispatch(unbindWorkerMoySkladThunk({ worker_id: worker.id, store_id: worker.moysklad_store.id }));
            dispatch(setWorkerMoySkladUnbindAction(null));
        } catch (err) {
            console.error(err);
        }
    }

    function openCreateWorkerMoySkladModal() {
        if (worker) {
            dispatch(setIsOpenCreateWorkerMoySkladAction(true));
        }
    }

    function openBindWorkerMoySkladModal() {
        if (worker) {
            dispatch(setIsOpenBindWorkerMoySkladAction(true));
        }
    }

    return (
        <Paper className="moy-sklad-container">
            {worker?.moysklad_store ? (
                <>
                    <div className="emty-moy-sklad-container">
                        <div className="moy-sklad-container-title">Склад</div>
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<ClearRoundedIcon />}
                                sx={{
                                    height: '32px',
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    background: '#F56767',
                                    textTransform: 'none',
                                    '&:hover': {
                                        background: '#D35151',
                                    },
                                }}
                                onClick={submitFormHandler}
                            >
                                Отвязать склад
                            </Button>
                        </div>
                    </div>
                    <div>
                        <Typography>
                            <span style={{ fontWeight: '600' }}>Название склада:</span> {worker?.moysklad_store.name}
                        </Typography>
                        <Typography>
                            <span style={{ fontWeight: '600' }}>Адрес склада:</span> {worker?.moysklad_store.address}
                        </Typography>
                        <br />
                        <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Запчасти</Typography>
                    </div>

                    <TableContainer sx={{ marginBottom: '30px' }}>
                        <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ paddingLeft: 0 }}>
                                        <Typography sx={{ fontSize: '14px', color: 'grey' }}>НАЗВАНИЕ</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography sx={{ fontSize: '14px', color: 'grey' }}>АРТИКУЛ</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography sx={{ fontSize: '14px', color: 'grey' }}>ЦЕНА ПОКУПКИ</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography sx={{ fontSize: '14px', color: 'grey' }}>ЦЕНА ПРОДАЖИ</Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography sx={{ fontSize: '14px', color: 'grey' }}>В НАЛИЧИИ</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {worker?.assortment?.map((row) => (
                                    <TableRow key={row.name}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{ paddingLeft: 0 }}
                                        >
                                            <Typography sx={{ fontSize: '14px' }}>{row.name}</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography sx={{ fontSize: '14px' }}>{row.article}</Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography sx={{ fontSize: '14px' }}>
                                                {row.buy_price.toLocaleString()} ₽
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography sx={{ fontSize: '14px' }}>
                                                {row.sale_price.toLocaleString()} ₽
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="left">
                                            <Typography sx={{ fontSize: '14px' }}>{row.stock}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <>
                    <div className="emty-moy-sklad-container">
                        <div className="moy-sklad-container-title">Склад</div>
                        <div>
                            <Button
                                variant="contained"
                                startIcon={<AddRoundedIcon />}
                                onClick={openCreateWorkerMoySkladModal}
                            >
                                Создать склад
                            </Button>
                            <Button
                                variant="outlined"
                                startIcon={<SearchIcon />}
                                sx={{ marginLeft: '15px' }}
                                onClick={openBindWorkerMoySkladModal}
                            >
                                Привязать склад
                            </Button>
                        </div>
                    </div>
                    <div className="container-empty-moy-sklad-text">
                        <div className="empty-moy-sklad-text">Склад не добавлен</div>
                    </div>
                </>
            )}
            <CreateWorkerMoySklad />
            <BindWorkerMoySklad />
        </Paper>
    );
}

export default WorkerStore;
