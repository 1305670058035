import { companiesApi, priceListsApi, servicesApi } from 'api';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { DeleteBasket } from 'static/Icons/DeleteBasket';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonsContainer, CloseIconWrapper, CopyItemWrapper, DeleteIconWrapper, Field, SubTitle } from './styles';

type Props = {
    setOpen: (isOpen: boolean) => void;
};

type Option = { label: string; value: string };

export const CopyModal: FC<Props> = ({ setOpen }) => {
    const { priceList } = useSelector(priceListsSelector);

    const [priceListForCopy, setPriceListsForCopy] = useState([priceList]);

    const [companies, setCompanies] = useState<Option[]>([]);
    const [serviceTypes, setServiceTypes] = useState<Option[]>([]);
    const [regions, setRegions] = useState<Option[]>([]);

    const fetchAutocompleteData = async () => {
        const [companiesRes, serviceTypesRes, regionsRes] = await Promise.all([
            companiesApi.getCompanies(),
            servicesApi.getServiceTypes(),
            priceListsApi.getRegionality({}),
        ]);

        const mapper = (item: { name: string; id: string }) => ({ label: item.name, value: item.id });

        setCompanies(companiesRes.data.map(mapper));
        setServiceTypes(serviceTypesRes.data.map(mapper));
        setRegions(regionsRes.data.map(mapper));
    };

    useEffect(() => {
        fetchAutocompleteData();
    }, []);

    const handleConfirm = () => {
        const items = priceListForCopy.map((item) => ({ ...item, is_replace: true }));

        priceListsApi.copyPriceList({ items, original_id: priceList?.id }).then(() => {
            enqueueSnackbar('Прайс лист успешно скопирован', { variant: 'success' });
            setOpen(false);
        });
    };

    return (
        <Dialog
            open
            onClose={() => setOpen(false)}
            sx={{ '.MuiPaper-root': { maxWidth: '730px' } }}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography
                    fontWeight={700}
                    sx={{ fontSize: '24px' }}
                >
                    Копировать прайс-лист
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {priceListForCopy.map((priceList, index) => (
                        <CopyItemWrapper key={priceList?.id}>
                            <Field>
                                <SubTitle>Партнер</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    isOptionEqualToValue={(option, { value }) => option.value === value}
                                    options={companies}
                                    defaultValue={{
                                        label: priceList?.company?.name,
                                        value: priceList?.company?.id,
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Тип услуги</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    defaultValue={{
                                        label: priceList?.service_type?.name,
                                        value: priceList?.service_type?.id,
                                    }}
                                    isOptionEqualToValue={(option, { value }) => option.value === value}
                                    options={serviceTypes}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Регион</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    isOptionEqualToValue={(option, { value }) => option.value === value}
                                    options={regions}
                                    defaultValue={{
                                        label: priceList?.region?.name,
                                        value: priceList?.region?.id,
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Системное наименование</SubTitle>
                                <TextField
                                    size="small"
                                    defaultValue={priceList?.system_name}
                                    sx={{ width: '392px' }}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Наименование</SubTitle>
                                <TextField
                                    size="small"
                                    defaultValue={priceList?.name}
                                    sx={{ width: '392px' }}
                                />
                            </Field>
                            <Field>
                                <SubTitle />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    label="Заменить прайс лист"
                                />
                            </Field>
                            <DialogActions>
                                <DeleteIconWrapper
                                    onClick={() => {
                                        setPriceListsForCopy((prev) => prev.filter((_, idx) => idx !== index));
                                    }}
                                >
                                    <DeleteBasket />
                                </DeleteIconWrapper>
                            </DialogActions>
                        </CopyItemWrapper>
                    ))}
                </Box>

                <Button
                    sx={{ color: '#0040F1' }}
                    startIcon={<AddIcon />}
                    onClick={() => setPriceListsForCopy((prev) => [...prev, prev[0]])}
                >
                    Добавить копию
                </Button>
                <ButtonsContainer>
                    <Button
                        variant="contained"
                        onClick={handleConfirm}
                    >
                        Подтвердить
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                    >
                        Отмена
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
