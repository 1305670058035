import CloseIcon from '@mui/icons-material/Close';
import { Alert, IconButton, Snackbar } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import { cancelToastAction } from '../../store/actions/toast';
import { useAppDispatch } from '../../store/hooks';
import { toastSelector } from '../../store/selectors/toastSelector';
import './Toast.scss';

function Toast() {
    const dispatch = useAppDispatch();
    const { visible, message, delay, severity, vertical, horizontal } = useSelector(toastSelector);

    const handleClose = () => {
        dispatch(cancelToastAction());
    };

    return (
        <Snackbar
            className="toast"
            anchorOrigin={{
                vertical: vertical || 'bottom',
                horizontal: horizontal || 'right',
            }}
            open={visible}
            autoHideDuration={delay || 10000}
            onClose={handleClose}
        >
            <Alert
                sx={{ width: '100%' }}
                severity={severity || 'success'}
                className={`MuiAlert-root_${severity || 'success'}`}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default Toast;
