import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { setIsOpenDeleteCommentDialogAction } from '../../../../../store/actions/issues';
import { useAppDispatch } from '../../../../../store/hooks';
import { issuesIsOpenDeleteCommentDialogSelector } from '../../../../../store/selectors/issuesSelectors';

interface IProps {
    deleteComment: () => void;
}

function CommentDialog({ deleteComment }: IProps) {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(issuesIsOpenDeleteCommentDialogSelector);

    function onClose() {
        dispatch(setIsOpenDeleteCommentDialogAction(false));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', marginBottom: 2 }}>
                    <div className="close-icon">
                        <CloseIcon onClick={onClose} />
                    </div>
                    <DeleteOutlineIcon
                        color="error"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />
                    <Typography variant="h6">Вы действительно хотите удалить этот комментарий?</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="buttons">
                    <Button
                        onClick={onClose}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={deleteComment}
                    >
                        Удалить
                    </LoadingButton>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default CommentDialog;
