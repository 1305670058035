import React, { memo } from 'react';

import './GenerateXlsForm.scss';
import FooterForm from './FooterForm';
import TimeWindows from './TimeWindows';

function GenerateXlsForm() {
    return (
        <div className="generate-xls-form-wrapper">
            <TimeWindows />
            <FooterForm />
        </div>
    );
}

export default memo(GenerateXlsForm);
