import { priceListsApi, servicePriceListsApi } from 'api';
import { FilterIn } from 'features/filter-in';
import { FootNote } from 'pages/services/components/footnote';
import { CloseIconWrapper } from 'pages/services/styles';
import { ActiveRadioChecked } from 'static/Icons/ActiveRadioChecked';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { InactiveRadioChecked } from 'static/Icons/InactiveRadioChecked';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';

import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, TextField, Typography } from '@mui/material';
import { AxiosPromise } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import {
    ButtonsWrapper,
    CellInputProps,
    CheckBox,
    CheckBoxContainer,
    CheckBoxItem,
    CheckLabel,
    ServiceName,
    SmallWrapper,
    SubTitle,
} from './styles';

import { Asterisk, Field, FieldContainer } from '../../../../create-service/styles';
import { fetchServicesPriceLists } from '../../store';
import { ButtonsContainer } from '../../styles';
import { AddConfirm } from '../add-confirm';

export type TForm = {
    name: string;
    description: string;
    service_ids: string[];
    amount: number;
    commission: {
        value: number;
    };
    include_descriptions: [
        {
            service_id: string | undefined;
            is_include_in_price: boolean;
        },
    ];
    is_active_integration: boolean;
    availability_type: string;
    external_id: string;
    price_lists: {
        PriceListServiceAddChosenPriceList?: {
            pk__in: any[];
        };
        all: boolean;
        type: string;
    };
};

type Props = {
    isOpenModal: boolean;
    setOpenModal: (value: boolean) => void;
};

export const AddInPriceLists: FC<Props> = ({ isOpenModal, setOpenModal }) => {
    const dispatch = useAppDispatch();

    const { serviceById } = useSelector(servicesSelector);

    const initialState: TForm = {
        name: '',
        description: '',
        service_ids: [],
        amount: 0,
        commission: {
            value: 0,
        },
        include_descriptions: [
            {
                service_id: serviceById?.id,
                is_include_in_price: true,
            },
        ],
        is_active_integration: false,
        availability_type: 'partner',
        external_id: '',
        price_lists: {
            PriceListServiceAddChosenPriceList: {
                pk__in: [],
            },
            all: true,
            type: 'all',
        },
    };

    const [checkValue, setCheckValue] = useState<string>('default');
    const [isConfirmOpen, setConfirmOpen] = useState(false);
    const [form, setForm] = useState<TForm>(initialState);

    const handleCreatePriceList = () => {
        servicePriceListsApi
            .createServicesComplex(form)
            .then(() => {
                setConfirmOpen(false);
                setOpenModal(false);

                dispatch(fetchServicesPriceLists());

                enqueueSnackbar('Прайс-лист добавлен', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    const converter = async (func: any, params: any) => {
        const res = await func({ size: 20, ...params });
        return { data: { items: res.data, total: res.data.length } };
    };

    const render = (value: string) => {
        switch (value) {
            case 'addFilters':
                return (
                    <ButtonsContainer>
                        <FilterIn
                            title="Регион"
                            onChange={(itemId) => {
                                console.log(itemId);
                            }}
                            activeIds={[]}
                            fetcher={(params) => converter(priceListsApi.getRegionality, params) as AxiosPromise}
                            cleaner={() => {}}
                        />
                        <FilterIn
                            title="Партнер"
                            onChange={(itemId) => {
                                console.log(itemId);
                            }}
                            activeIds={[]}
                            fetcher={(params) => converter(priceListsApi.getCompanies, params) as AxiosPromise}
                            cleaner={() => {}}
                        />
                    </ButtonsContainer>
                );
            case 'addInSelected':
                return (
                    <ButtonsContainer>
                        <FilterIn
                            title="Наименование"
                            onChange={(itemId) => {
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            ...prev.price_lists,
                                            priceListServiceAddChosenPriceList: {
                                                pk__in: [itemId],
                                                // pk__in: [...prev.price_lists.pk__in, itemId],
                                            },
                                        },
                                    };
                                });
                            }}
                            activeIds={form?.price_lists?.PriceListServiceAddChosenPriceList?.pk__in || []}
                            fetcher={(params) => priceListsApi.getPriceLists(params) as AxiosPromise}
                            cleaner={() => {
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            ...prev.price_lists,
                                            PriceListServiceAddChosenPriceList: {
                                                pk__in: [],
                                            },
                                        },
                                    };
                                });
                            }}
                        />
                    </ButtonsContainer>
                );
        }
    };

    return (
        <Dialog
            open={isOpenModal}
            onClose={() => setOpenModal(false)}
            sx={{
                '.MuiDialog-paper': {
                    minWidth: '992px',
                },
            }}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setOpenModal(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography variant="h5">Добавить в прайс-лист</Typography>
            </DialogTitle>
            <DialogContent>
                <CheckBoxContainer>
                    <CheckBoxItem>
                        <CheckBox
                            id="default"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => {
                                setCheckValue('default');
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            all: true,
                                            type: 'all',
                                        },
                                    };
                                });
                            }}
                            checked={checkValue === 'default'}
                        />
                        <CheckLabel htmlFor="default">Добавить во все прайс-листы</CheckLabel>
                    </CheckBoxItem>
                    <CheckBoxItem>
                        <CheckBox
                            id="addFilters"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => {
                                setCheckValue('addFilters');
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            all: true,
                                            type: 'filter',
                                        },
                                    };
                                });
                            }}
                            checked={checkValue === 'addFilters'}
                        />
                        <CheckLabel htmlFor="addFilters">
                            Добавить во все прайс-листы, соответствующие фильтру
                        </CheckLabel>
                    </CheckBoxItem>
                    <CheckBoxItem>
                        <CheckBox
                            id="addInSelected"
                            icon={<InactiveRadioChecked />}
                            checkedIcon={<ActiveRadioChecked />}
                            onClick={() => {
                                setCheckValue('addInSelected');
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        price_lists: {
                                            all: true,
                                            type: 'chosen',
                                        },
                                    };
                                });
                            }}
                            checked={checkValue === 'addInSelected'}
                        />
                        <CheckLabel htmlFor="addInSelected">Добавить в выбранные</CheckLabel>
                    </CheckBoxItem>
                </CheckBoxContainer>

                {render(checkValue)}

                <FieldContainer>
                    <Field>
                        <SubTitle>
                            Наименование<Asterisk>*</Asterisk>
                        </SubTitle>
                        <TextField
                            onChange={(e) => {
                                setForm((prev: any) => {
                                    return { ...prev, name: e.target.value.trim() };
                                });
                            }}
                            placeholder="Наименование"
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            Описание<Asterisk>*</Asterisk>
                        </SubTitle>
                        <TextField
                            onChange={(e) => {
                                setForm((prev: any) => {
                                    return { ...prev, description: e.target.value.trim() };
                                });
                            }}
                            placeholder="Описание"
                            size="small"
                            multiline
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            <SubTitle>Что входит в стоимость</SubTitle>
                            <Box sx={{ color: '#A4A7B8', fontSize: '12px' }}>
                                Невыбранные элементы будут отображаться в блоке «Что не входит в стоимость»
                            </Box>
                        </SubTitle>
                        <SmallWrapper sx={{ flexDirection: 'column' }}>
                            <SmallWrapper>
                                <CheckBox />
                                <ServiceName>Дочерняя услуга 1</ServiceName>
                            </SmallWrapper>
                            <SmallWrapper>
                                <CheckBox />
                                <ServiceName>Дочерняя услуга 3</ServiceName>
                            </SmallWrapper>
                            <SmallWrapper>
                                <CheckBox />
                                <ServiceName>Дочерняя услуга 5</ServiceName>
                            </SmallWrapper>
                        </SmallWrapper>
                    </Field>
                    <Field>
                        <SubTitle>
                            Цена<Asterisk>*</Asterisk>
                        </SubTitle>
                        <NumericFormat
                            onValueChange={(e) => {
                                setForm((prev: any) => {
                                    return { ...prev, amount: e.floatValue };
                                });
                            }}
                            suffix=" ₽"
                            customInput={TextField}
                            inputProps={CellInputProps}
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            Комиссия мастера<Asterisk>*</Asterisk>
                        </SubTitle>
                        <NumericFormat
                            onValueChange={(e) => {
                                setForm((prev: any) => {
                                    return {
                                        ...prev,
                                        commission: { value: e.floatValue },
                                        service_ids: [serviceById?.id as string],
                                    };
                                });
                            }}
                            suffix=" ₽"
                            customInput={TextField}
                            inputProps={CellInputProps}
                        />
                    </Field>
                    <Divider sx={{ margin: '20px 0' }} />
                    <Field>
                        <SubTitle>Доступен в API-интеграции</SubTitle>
                        <CheckBox
                            onChange={() => {
                                setForm((prev: any) => {
                                    return { ...prev, is_active_integration: !prev.is_active_integration };
                                });
                            }}
                        />
                    </Field>
                    <Field>
                        <SubTitle>
                            Внешний идентификатор<Asterisk>*</Asterisk>
                        </SubTitle>
                        <TextField
                            onChange={(e) => {
                                setForm((prev: any) => {
                                    return { ...prev, external_id: e.target.value };
                                });
                            }}
                            size="small"
                        />
                    </Field>
                </FieldContainer>
                <ButtonsWrapper>
                    <Button
                        variant="contained"
                        onClick={() => setConfirmOpen(true)}
                        disabled={
                            !form.amount ||
                            !form.commission.value ||
                            !form.name ||
                            !form.description ||
                            !form.external_id
                        }
                    >
                        Добавить
                    </Button>
                    <Button
                        onClick={() => setOpenModal(false)}
                        variant="outlined"
                        sx={{ marginRight: '157px' }}
                    >
                        Отмена
                    </Button>
                    <FootNote />
                </ButtonsWrapper>
                <AddConfirm
                    createPriceList={handleCreatePriceList}
                    isConfirmOpen={isConfirmOpen}
                    setConfirmOpen={setConfirmOpen}
                />
            </DialogContent>
        </Dialog>
    );
};
