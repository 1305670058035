import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IClient, IClientDetail } from '../../../../../../interfaces/clients';
import { IFormValuesCD } from '../../../../../../interfaces/devices';
import { brandsOptionsSelector } from '../../../../../../store/selectors/brandsSelectors';
import { categoriesOptionsSelector } from '../../../../../../store/selectors/categoriesSelectors';
import { modelsOptionsSelector } from '../../../../../../store/selectors/modelsSelectors';
import { getFullName } from '../../../../../../utils/helpers/getFullName';
import './ConfirmationContent.scss';

interface IProps {
    formValues: IFormValuesCD;
    client: IClient | IClientDetail;
    isNewCategory: boolean;
    isNewBrand: boolean;
    isNewModel: boolean;
}

function ConfirmationContent({ formValues, client, isNewCategory, isNewModel, isNewBrand }: IProps) {
    const categoriesOptions = useSelector(categoriesOptionsSelector);
    const brandsOptions = useSelector(brandsOptionsSelector);
    const modelsOptions = useSelector(modelsOptionsSelector);

    const names = useMemo(() => {
        let category = '';
        let brand = '';
        let model = '';

        if (formValues.category_id && !isNewCategory && categoriesOptions) {
            const foundOption = categoriesOptions.find(({ value }) => value === formValues.category_id);
            if (foundOption) category = foundOption.label;
        } else if (formValues.category_name) {
            category = formValues.category_name;
        }

        if (formValues.brand_id && !isNewBrand && brandsOptions) {
            const foundOption = brandsOptions.find(({ value }) => value === formValues.brand_id);
            if (foundOption) brand = foundOption.label;
        } else if (formValues.brand_name) {
            brand = formValues.brand_name;
        }

        if (formValues.category_id && !isNewModel && modelsOptions) {
            const foundOption = modelsOptions.find(({ value }) => value === formValues.model_id);
            if (foundOption) model = foundOption.label;
        } else if (formValues.model_name) {
            model = formValues.model_name;
        }

        return {
            category,
            brand,
            model,
        };
    }, [formValues]);

    return (
        <div className="confirmation-content-wrapper">
            <Box sx={{ minHeight: '500px', position: 'relative' }}>
                <Typography color="rgb(138, 146, 166)">
                    Вы добавили новые значения. После сохранения они будут доступны всем для работы
                </Typography>
                <div className="confirmation-items-list">
                    <div className="created-data-item">
                        <div>Категория</div>
                        <div>{names.category}</div>
                    </div>
                    <div className="created-data-item">
                        <div>Бренд</div>
                        <div>{names.brand}</div>
                    </div>
                    <div className="created-data-item">
                        <div>Модель</div>
                        <div>{names.model}</div>
                    </div>
                </div>
                <div className="client-wrapper">
                    <div>Клиент</div>
                    <div className="client-data">
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
                        <div>
                            <Typography>{getFullName(client)}</Typography>
                            <Typography>{client.id}</Typography>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default ConfirmationContent;
