// eslint-disable-next-line no-shadow
export const enum EPricesTabs {
    Services = 'services',
    // Products = 'products',
}

// eslint-disable-next-line no-shadow
export const enum ECommissionsType {
    Percentage = 'percentage',
    Fixation = 'fixation',
}
