import { createSelector } from 'reselect';

import { getFullNameHomeDevice } from '../../../utils/helpers/getFullNameHomeDevice';
import { RootState } from '../../rootReducer';
import { issueSelector } from '../issuesSelectors';

const state = ({ createOrder }: RootState) => createOrder;

/* CO === CreateOrder */
export const formValuesCOSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsCOSelector = createSelector(state, ({ errors }) => errors);
export const isOpenCOSelector = createSelector(state, ({ isOpenCreateOrder }) => isOpenCreateOrder);

export const homeDevicesOptionsCOSelector = createSelector([issueSelector], (issue) => {
    return issue?.sub_issues?.map(({ id, home_device }) => {
        return {
            value: id,
            label: getFullNameHomeDevice(home_device),
            category_id: home_device.category?.id,
        };
    });
});
