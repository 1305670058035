import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import './EditWorkerFIOForm.scss';
import FooterForm from './FooterForm';

import TextField from '../../../../../../components/inputs/TextField';
import { setFormValuesEditWorkerFIOAction } from '../../../../../../store/actions/edit/editWorkerFIO';
import { useAppDispatch } from '../../../../../../store/hooks';
import {
    errorsEditWorkerFIOSelector,
    formValuesEditWorkerFIOSelector,
} from '../../../../../../store/selectors/edit/editFIOSelectors';

function EditWorkerFIOForm() {
    const dispatch = useAppDispatch();

    const formValues = useSelector(formValuesEditWorkerFIOSelector);
    const errors = useSelector(errorsEditWorkerFIOSelector);

    function onChangeLastName(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditWorkerFIOAction({ last_name: e.target.value || null }));
    }

    function onChangeFirstName(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditWorkerFIOAction({ first_name: e.target.value || null }));
    }

    function onChangeMiddleName(e: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesEditWorkerFIOAction({ middle_name: e.target.value || null }));
    }

    return (
        <div className="edit-worker-fio-form-wrapper">
            <TextField
                fullWidth
                name="last_name"
                labelText="Фамилия *"
                params={{ margin: 'dense' }}
                defaultValue={formValues?.last_name || null}
                errors={errors}
                onChange={onChangeLastName}
            />
            <TextField
                fullWidth
                name="first_name"
                labelText="Имя *"
                params={{ margin: 'dense' }}
                defaultValue={formValues?.first_name || null}
                errors={errors}
                onChange={onChangeFirstName}
            />
            <TextField
                fullWidth
                name="middle_name"
                labelText="Отчество *"
                params={{ margin: 'dense' }}
                defaultValue={formValues?.middle_name || null}
                errors={errors}
                onChange={onChangeMiddleName}
            />
            <FooterForm />
        </div>
    );
}

export default memo(EditWorkerFIOForm);
