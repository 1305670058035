import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

export default class RoutingAPI {
    generateXls({ gte, lte }: { gte: string; lte: string }) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/routing/yandex/xlsx?time_window__gte=${gte}&time_window__lte=${lte}`,
            isAuthorization: true,
            headers: {
                'Content-Type': 'blob',
            },
            responseType: 'arraybuffer',
        }) as AxiosPromise<string>;
    }
}
