import React from 'react';

import plugSVG from '../../static/images/plug.svg';
import './Plug.scss';

function Plug() {
    return (
        <div className="plug">
            <img
                src={plugSVG}
                alt="plug"
            />
            <p>Здесь пока ничего нет</p>
        </div>
    );
}

export default Plug;
