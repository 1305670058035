import { useAppDispatch } from 'store/hooks';

import { Checkbox, FormControlLabel, TableCell, TableHead, TableRow, styled } from '@mui/material';
import { IWorker } from 'interfaces/workers';
import { useSelector } from 'react-redux';

import { setWorkerIds } from '../../store';
import { withdrawsSelector } from '../../store/selectors';

export const Head = ({ rows }: { rows: IWorker[] }) => {
    const dispatch = useAppDispatch();

    const { workerIds } = useSelector(withdrawsSelector);

    const ids = rows.filter((item) => !(!item.payment_details || item.payment_details.is_ie)).map((item) => item.id);

    const handleChange = (_: any, checked: boolean) => {
        if (checked) {
            dispatch(setWorkerIds(ids));
        } else {
            dispatch(setWorkerIds([]));
        }
    };

    return (
        <TableHead>
            <TableRow>
                <Cell>
                    <Label
                        onChange={handleChange}
                        control={<Checkbox checked={Boolean(ids.length && workerIds.length === ids.length)} />}
                        label={'ФИО'}
                    />
                </Cell>
                <Cell>СТАТУС</Cell>
                <Cell>БАЛАНС</Cell>
                <Cell align="center">РЕКВИЗИТЫ</Cell>
                <Cell align="center">ИП</Cell>
                <Cell />
            </TableRow>
        </TableHead>
    );
};

const Cell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
});

const Label = styled(FormControlLabel)({
    margin: '0',
    '.MuiTypography-root': {
        color: '#9E9E9E',
        fontWeight: '600',
        fontSize: '12px',
    },
});
