import { Button, IconButton, Popover } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import './Menu.scss';

interface IProps {
    icon: ReactElement;
    iconClass?: string;
    items: {
        title: string;
        color: 'inherit' | 'error';
        handle: () => void;
    }[];
}
/* TODO A.Ромашко Как вариант конечно, но не нужЁн он, лучше локально в глубину по месту делать такие вещи. Например:
 *  Используешь ты его в src/components/Chat/Message/index.tsx создай там src/components/Chat/Message/Menu/index.tsx и используй по месту.
 *   Вместо строгих items лучше просто ReactNode передай в children, если хочешь логику открытия закрытия здесь локализовать.
 *   Это будет лаконичнее и гибче */
function Menu({ icon, iconClass, items }: IProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                onClick={handlePopoverClick}
                disableRipple
                className={iconClass}
            >
                {icon}
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="options-container">
                    {items.map((item) => {
                        return (
                            <Button
                                key={item.title}
                                className="option-item"
                                color={item.color}
                                onClick={() => {
                                    item.handle();
                                    handlePopoverClose();
                                }}
                            >
                                {item.title}
                            </Button>
                        );
                    })}
                </div>
            </Popover>
        </>
    );
}

export default Menu;
