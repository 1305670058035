import { AppDispatch } from 'store/store';

import { brandsApi } from '../../api';
import { EBrandsActions } from '../../enums/actions.enum';
import { IBrand, IBrandDetail, ISetBrandAction, ISetBrandsAction } from '../../interfaces/brands';

export const setBrandAction = (payload: IBrandDetail | null): ISetBrandAction => ({
    type: EBrandsActions.SetBrand,
    payload,
});

export const setBrandsAction = (payload: IBrandDetail[] | null): ISetBrandsAction => ({
    type: EBrandsActions.SetBrands,
    payload,
});

interface ICreateBrandThunkParams {
    name: IBrand['name'];
}
export const createBrandThunk =
    ({ name }: ICreateBrandThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data } = await brandsApi.createBrand(name);
            dispatch(setBrandAction(data) as any);
            return data;
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const getBrandsThunk = (queries?: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await brandsApi.getBrands(queries);
        const { items: brands } = data;
        dispatch(setBrandsAction(brands));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
