/* eslint-disable no-shadow */
export const enum EPermissionsKeys {
    Issues = 'issues',
    IssueComments = 'issue_comments',
    Users = 'users',
    Workers = 'workers',
    Clients = 'clients',
    Articles = 'articles',
    Devices = 'devices',
    Services = 'services',
    Support = 'support',
    Settings = 'settings',
    Chat = 'chat',
    Files = 'files',
    SpareParts = 'spare_parts',
    Accounting = 'accounting',
}

export const enum EPermissionsValues {
    Read = 'read',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
    Owner = 'owner',
    Validation = 'validation',
    ChangeStatus = 'change_status',
    SetPriceSolved = 'set_price_solved',
}

/* Owner - особый внутри фронтовый тип
 * В случае profile.company === null && profile.role === null
 * считаем пользователя owner'ом
 * При возможности следует избавиться от использования EPermissionSpecial
 * Т.к. Случай - profile.company === null && profile.role === null является недоработкой бэкенда
 *  */
export const enum EPermissionSpecial {
    Owner = 'owner',
}
