import { ESignInActions } from '../../enums/actions.enum';
import { ISignInState, TSignInActions } from '../../interfaces/signIn';

const initState: ISignInState = {
    type: null,
    email: null,
    errors: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const signInReducer = (state = initialState, action: TSignInActions): ISignInState => {
    switch (action.type) {
        case ESignInActions.SetType: {
            return { ...state, type: action.payload };
        }
        case ESignInActions.SetEmail: {
            return { ...state, email: action.payload };
        }
        default:
            return state;
    }
};
