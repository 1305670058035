import CloseIcon from '@mui/icons-material/Close';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { Box, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

import CreateGroupForm from './CreateGroupForm';

import { setCreateGroupIsOpenAction } from '../../../store/actions/create/createGroup';
import { useAppDispatch } from '../../../store/hooks';
import { createGroupIsOpenSelector } from '../../../store/selectors/create/createGroupSelector';
import './CreateGroup.scss';

function CreateGroup() {
    const dispatch = useAppDispatch();
    const isOpen = useSelector(createGroupIsOpenSelector);

    function onCloseHandler() {
        dispatch(setCreateGroupIsOpenAction(false));
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="lg"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onCloseHandler}
        >
            <DialogTitle>
                <Box className="new-group-dialog-title">
                    <div className="close-icon">
                        <CloseIcon onClick={onCloseHandler} />
                    </div>
                    <NoteAddOutlinedIcon
                        color="primary"
                        sx={{ marginRight: '30px' }}
                        fontSize="large"
                    />{' '}
                    <Typography variant="h6">Новая группа мастеров</Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <CreateGroupForm />
            </DialogContent>
        </Dialog>
    );
}

export default CreateGroup;
