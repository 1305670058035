import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ dicts }: RootState) => dicts;

export const addressesDictsSelector = createSelector(state, ({ addresses }) => addresses);
export const addressesOptionsDictsSelector = createSelector(state, ({ addresses }) => {
    return (
        addresses?.map(({ fias_id, name }) => {
            return { value: fias_id, label: name };
        }) || null
    );
});
export const addressesOptionsWithNameInValueDictsSelector = createSelector(state, ({ addresses }) => {
    return (
        addresses?.map(({ name }) => {
            return { value: name, label: name };
        }) || null
    );
});
export const serviceAreasDictsSelector = createSelector(state, ({ serviceAreas }) => serviceAreas);
