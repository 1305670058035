import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { Tooltip } from '@mui/material';
import React from 'react';

import { MapInfoMock } from '../../../../mockData/map';
import './MapInfo.scss';

function MapInfo() {
    return (
        <div className="map-info-wrapper">
            <Tooltip
                title={Object.entries(MapInfoMock).map(([key, { color, title }]) => (
                    <div
                        key={key}
                        className="map-info-item"
                    >
                        <div
                            className="map-info-item-point"
                            style={{ backgroundColor: `${color}` }}
                        />
                        <div className="map-info-item-title">{title}</div>
                    </div>
                ))}
                arrow
            >
                <NotListedLocationIcon
                    fontSize="medium"
                    color="error"
                />
            </Tooltip>
        </div>
    );
}

export default MapInfo;
