import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SxProps } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { Theme } from '@mui/system';
import React, { PropsWithChildren, useState } from 'react';

import './Accordion.scss';

interface IProps {
    title: string | React.ReactNode;
    expanded?: boolean;
    sx?: SxProps<Theme>;
}

function Accordion({ title, expanded = true, children, sx }: PropsWithChildren<IProps>) {
    const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

    const handleChange = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <MuiAccordion
            className="accordion"
            disableGutters
            elevation={0}
            expanded={isExpanded}
            onChange={handleChange}
            sx={sx}
        >
            <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                className="accordion-summary"
            >
                <div className="title">{title}</div>
            </MuiAccordionSummary>
            <MuiAccordionDetails className="accordion-details">{children}</MuiAccordionDetails>
        </MuiAccordion>
    );
}

export default Accordion;
