import React, { ReactNode } from 'react';

import { useStyles } from './signInStyles';

import authLayoutRocketSVG from '../../static/images/authRocket.svg';
import authLayoutHashtagSVG from '../../static/images/hashTag.svg';
import mainLogoSVG from '../../static/images/mainLogo.svg';

interface IProps {
    children: ReactNode;
}

function AuthLayout({ children }: IProps) {
    const styles = useStyles();

    return (
        <div className={styles.AuthLayout}>
            <div className={styles.AuthLayout__Left}>
                <img
                    src={authLayoutRocketSVG}
                    alt=""
                />
            </div>
            <div className={styles.AuthLayout__Right}>
                <div className={styles.AuthLayout__ContainerLogo}>
                    <img
                        src={mainLogoSVG}
                        alt=""
                    />
                </div>
                <div className={styles.AuthLayout__ContainerHashtagImage}>
                    <img
                        src={authLayoutHashtagSVG}
                        alt=""
                    />
                </div>
                <div className={styles.AuthLayout__ContainerChildren}>
                    <div className={styles.AuthLayout__Children}>{children}</div>
                </div>
            </div>
        </div>
    );
}

export default AuthLayout;
