export const toDivideAmount = (amount: number) => {
    const int = String(Math.trunc(amount));
    if (int.length <= 3) return int;
    let space: number = 0;
    let numberStr: string = '';

    // eslint-disable-next-line no-plusplus
    for (let i = int.length - 1; i >= 0; i--) {
        if (space === 3) {
            numberStr = ` ${numberStr}`;
            space = 0;
        }
        numberStr = int.charAt(i) + numberStr;
        // eslint-disable-next-line no-plusplus
        space++;
    }

    return numberStr;
};
