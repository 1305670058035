import { AppDispatch } from 'store/store';

import { partnersApi } from '../../api';
import { EPartnersActions } from '../../enums/actions.enum';
import { IPartner, ISetPartnersAction } from '../../interfaces/partners';

export const setPartnersAction = (payload: IPartner[] | null): ISetPartnersAction => ({
    type: EPartnersActions.SetPartners,
    payload,
});

export const getPartnersThunk = (queries?: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await partnersApi.getPartners(queries);
        dispatch(setPartnersAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
