export const isDateFuture = (date: string | Date | null | undefined): boolean => {
    if (!date) return false;
    let formatDate = date;

    if (typeof date === 'string') {
        const USDate = date.split('.');
        if (USDate[2]?.length < 4) {
            return false;
        }
        formatDate = `${USDate[2]}/${USDate[1]}/${USDate[0]}`;
    }

    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    formatDate = new Date(formatDate);
    formatDate.setHours(0);
    formatDate.setMinutes(0);
    formatDate.setSeconds(0);
    formatDate.setMilliseconds(0);

    // eslint-disable-next-line no-unsafe-optional-chaining
    const diff = formatDate.getTime() - currentDate.getTime();

    return diff >= 0;
};
