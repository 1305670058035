import { ERolesActions } from '../../enums/actions.enum';
import { IRolesState, TRolesActions } from '../../interfaces/roles';

const initState: IRolesState = {
    roles: null,
};

const initialState = { ...initState };

// eslint-disable-next-line default-param-last
export const rolesReducer = (state = initialState, action: TRolesActions): IRolesState => {
    switch (action.type) {
        case ERolesActions.SetRoles:
            return {
                ...state,
                roles: action.payload,
            };
        default:
            return state;
    }
};
