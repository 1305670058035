import { priceListsApi } from 'api';
import Loader from 'components/Loader';
import { FilterIn } from 'features/filter-in';
import { useAppDispatch } from 'store/hooks';

import { Box } from '@mui/material';
import { AxiosPromise } from 'axios';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { fetchServicesPriceLists, setFilters } from './store';
import { servicesPriceListsSelector } from './store/selectors';
import { TFilters } from './store/types';
import { ButtonsContainer } from './styles';
import { Table } from './table';

export type TFilterTypes = keyof TFilters;

export const PriceLists = () => {
    const dispatch = useAppDispatch();

    const { filters, servicesPriceLists, loading } = useSelector(servicesPriceListsSelector);

    const converter = async (func: any, params: any) => {
        const res = await func({ size: 20, ...params });
        return { data: { items: res.data, total: res.data.length } };
    };

    const handleChangeFilter = (itemId: string, filterType: TFilterTypes) => {
        const getIds = () => {
            if (filters[filterType].includes(itemId as never)) {
                return filters[filterType].filter((id) => id !== itemId);
            } else {
                return [...filters[filterType], itemId];
            }
        };

        dispatch(setFilters({ [filterType]: getIds() }));
        dispatch(fetchServicesPriceLists());
    };

    const handleFilterClean = (key: string) => {
        dispatch(setFilters({ [key]: [] }));
        dispatch(fetchServicesPriceLists());
    };

    useEffect(() => {
        dispatch(fetchServicesPriceLists());
    }, []);

    return (
        <Box>
            <ButtonsContainer>
                <FilterIn
                    title="Наименование"
                    onChange={(itemId) => handleChangeFilter(itemId, 'pk__in')}
                    activeIds={filters.pk__in}
                    fetcher={(params) => priceListsApi.getPriceLists({ size: 20, ...params })}
                    cleaner={() => handleFilterClean('pk__in')}
                />
                <FilterIn
                    title="Регион"
                    onChange={(itemId) => handleChangeFilter(itemId, 'region_id__in')}
                    activeIds={filters.region_id__in}
                    fetcher={(params) => converter(priceListsApi.getRegionality, params) as AxiosPromise}
                    cleaner={() => handleFilterClean('region_id__in')}
                />
                <FilterIn
                    title="Партнер"
                    onChange={(itemId) => handleChangeFilter(itemId, 'company_id__in')}
                    activeIds={filters.company_id__in}
                    fetcher={(params) => converter(priceListsApi.getCompanies, params) as AxiosPromise}
                    cleaner={() => handleFilterClean('company_id__in')}
                />
            </ButtonsContainer>
            {loading ? <Loader /> : <Table rows={servicesPriceLists} />}
        </Box>
    );
};
