// @ts-ignore // todo разобраться в чём проблема с типизацией в пакете она есть

import TextField from 'components/inputs/TextField';
import { setFormValuesCPAction } from 'store/actions/create/createPart';
import { useAppDispatch } from 'store/hooks';
import { errorsCPSelector, formValuesCPSelector } from 'store/selectors/create/createPartSelectors';

import React from 'react';
import { useSelector } from 'react-redux';

import FooterForm from './FooterForm';

function CreatePartForm() {
    const dispatch = useAppDispatch();
    const formValues = useSelector(formValuesCPSelector);
    const errors = useSelector(errorsCPSelector);

    function changePartNameHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCPAction({ ...(formValues || {}), name: event.target.value }));
    }

    function changePartItemNumberHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCPAction({ ...(formValues || {}), item_number: event.target.value }));
    }

    function changePartAmountHandler(event: React.ChangeEvent<HTMLInputElement>) {
        dispatch(setFormValuesCPAction({ ...(formValues || {}), amount: Number(event.target.value) }));
    }

    return (
        <div className="create-part-form-wrapper">
            <TextField
                name="part_name"
                labelText="Название детали *"
                fullWidth
                params={{ margin: 'normal' }}
                errors={errors}
                value={formValues?.name || ''}
                onChange={changePartNameHandler}
            />
            <TextField
                name="part_item_name"
                labelText="Артикул детали *"
                fullWidth
                params={{ margin: 'normal' }}
                errors={errors}
                value={formValues?.item_number || ''}
                onChange={changePartItemNumberHandler}
            />
            <TextField
                name="part_price"
                labelText="Цена детали"
                fullWidth
                type="number"
                params={{ margin: 'normal' }}
                errors={errors}
                value={formValues?.amount || ''}
                onChange={changePartAmountHandler}
            />
            {/*TODO: пока было решено скрыть
			<Categories
				categoryOptions={categoryOptions}
				errors={errors}
				getCategoriesByScroll={getCategoriesByScroll}
				getCategoriesBySearch={onSearchHandlerDebounced}
				priceSelectHandler={priceSelectHandler}
			/>
			*/}
            <FooterForm />
        </div>
    );
}

export default CreatePartForm;
