import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import WorkerCanceledIssueHistory from './WorkerCanceledIssueHistory';
import WorkerCard from './WorkerCard';
import { WorkerRequisites } from './WorkerRequisites';
import WorkerSchedule from './WorkerSchedule';
import WorkerServiceAreas from './WorkerServiceAreas';
import WorkerSolvedIssueHistory from './WorkerSolvedIssueHistory';
import WorkerSpecializations from './WorkerSpecializations';
import WorkerStore from './WorkerStore';
import { WorkerTransactions } from './WorkerTransactions';

import { workersApi } from '../../api';
import ButtonGroup from '../../components/ButtonGroup';
import Loader from '../../components/Loader';
import { ERoutes } from '../../enums/routes.enum';
import { EWorkerStatus } from '../../enums/workers.enum';
import MainLayout from '../../layouts/MainLayout';
import { getCategoriesThunk } from '../../store/actions/categories';
import {
    getCanceledIssueHistoryForWorkerThunk,
    getSolvedIssueHistoryForWorkerThunk,
    getWorkerThunk,
    workerResetAction,
} from '../../store/actions/worker';
import { useAppDispatch } from '../../store/hooks';
import { workerDetailSelector } from '../../store/selectors/workerSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import { getFullName } from '../../utils/helpers/getFullName';

import './WorkerPage.scss';

function WorkerPage() {
    const { workerId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const worker = useSelector(workerDetailSelector);

    const isWorkerArchived: boolean = location.pathname.split('/').includes('archive');

    useEffect(() => {
        (async () => {
            await getWorker();
            await getCanceledIssueHistoryForWorker();
            await getSolvedIssueHistoryForWorker();
            await getCategories();
        })();

        return () => {
            dispatch(workerResetAction());
        };
    }, []);

    const ButtonGroupVisible = useMemo(() => {
        switch (worker?.status) {
            case EWorkerStatus.Disabled: {
                return false;
            }
            case EWorkerStatus.Deleted: {
                return false;
            }
            default:
                return true;
        }
    }, [worker?.status]);

    async function getWorker() {
        try {
            if (workerId) {
                await dispatch(getWorkerThunk(workerId));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function getCanceledIssueHistoryForWorker() {
        try {
            if (workerId) {
                await dispatch(getCanceledIssueHistoryForWorkerThunk(workerId));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function getSolvedIssueHistoryForWorker() {
        try {
            if (workerId) {
                await dispatch(getSolvedIssueHistoryForWorkerThunk(workerId));
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function changeWorkerIsArchivedValue() {
        try {
            worker && (await workersApi.changeWorkerIsArchivedValue(worker.id, { is_archived: !isWorkerArchived }));

            navigate(isWorkerArchived ? `/${ERoutes.Archive}?` : `/${ERoutes.Workers}`);
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    async function getCategories() {
        try {
            await dispatch(getCategoriesThunk({ size: 50 }));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    return (
        <MainLayout>
            {worker ? (
                <div className="worker-page-wrapper">
                    <div className="worker-page-header">
                        <div className="worker-page-title">
                            <div className="worker-page-title-label">Мастера/&nbsp;</div>
                            <div className="worker-page-title-worker-name">{getFullName(worker)}</div>
                        </div>
                        <ButtonGroup
                            title="Меню"
                            buttonsInfo={[
                                {
                                    title: isWorkerArchived ? 'Убрать из архива' : 'Перенести в архив',
                                    handler: () => changeWorkerIsArchivedValue(),
                                    disabled: ButtonGroupVisible,
                                },
                            ]}
                        />
                    </div>
                    <div className="worker-page-content">
                        <WorkerCard />
                        <WorkerSchedule />
                        <WorkerSpecializations />
                        <WorkerServiceAreas />
                        <WorkerCanceledIssueHistory />
                        <WorkerSolvedIssueHistory />
                        <WorkerStore />
                        <WorkerRequisites />
                        <WorkerTransactions />
                    </div>
                </div>
            ) : (
                <Loader />
            )}
        </MainLayout>
    );
}

export default WorkerPage;
