import React from 'react';
import { useSelector } from 'react-redux';

import WorkerItem from './WorkerItem';

import { selectedPinWorkerMapSelector, selectedWorkersMapSelector } from '../../../../store/selectors/mapSelectors';
import '../SidebarContent.scss';

function WorkersContent() {
    const workers = useSelector(selectedWorkersMapSelector);
    const selectedPinWorker = useSelector(selectedPinWorkerMapSelector);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {!!workers?.length && (
                <ul className="workers__content scroll-wrapper">
                    {workers?.map((worker) => (
                        <WorkerItem
                            key={worker.id}
                            worker={worker}
                            pin={selectedPinWorker}
                        />
                    ))}
                </ul>
            )}
        </>
    );
}

export default WorkersContent;
