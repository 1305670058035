import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ createIssuePartner }: RootState) => createIssuePartner;

export const selectedPricesSelector = createSelector(state, ({ selectedPrices }) => selectedPrices);
export const formValuesSelector = createSelector(state, ({ formValues }) => formValues);
export const stepSelector = createSelector(state, ({ step }) => step);
export const errorsCreateIssuePartnerFormSelector = createSelector(state, ({ errors }) => errors);
export const amountTotalSelector = createSelector(state, ({ selectedPrices }) => {
    if (!selectedPrices) return 0;
    let total = 0;
    selectedPrices.forEach(({ ratios, amount }) => {
        total += ratios?.total_ratio_amount || amount;
    });
    return total;
});
