import {
    setFormValuesWorkerMoySkladAction,
    setIsOpenBindWorkerMoySkladAction,
    setSearchResponseWorkerMoySkladAction,
} from 'store/actions/create/bindMoySklad';
import { searchWorkerMoySkaldThunk } from 'store/actions/create/createMoySklad';
import { useAppDispatch } from 'store/hooks';
import { isOpenBindMoySkladSelector, searchResponseMoySkladSelector } from 'store/selectors/create/bindMoySklad';
import { workerDetailSelector } from 'store/selectors/workerSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputAdornment,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import FooterForm from './FooterForm';

import './UnbindWorkerMoySklad.scss';

function BindWorkerMoySklad() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenBindMoySkladSelector);
    const sklads = useSelector(searchResponseMoySkladSelector);
    const worker = useSelector(workerDetailSelector);

    const changeInpHandleDebounced = useDebouncedCallback(onChangeSearchMoySklad, 800);

    function onClose() {
        dispatch(setIsOpenBindWorkerMoySkladAction(false));
    }

    async function onChangeSearchMoySklad(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.value.trim()) return;
        try {
            const searchResponseMoySklad = await dispatch(searchWorkerMoySkaldThunk({ search: e.target.value.trim() }));
            dispatch(setSearchResponseWorkerMoySkladAction(searchResponseMoySklad));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function onChangeFormHandler(e: ChangeEvent<HTMLInputElement>) {
        const findedSklad = sklads?.find(({ id }) => id === e.target.value);
        if (!findedSklad || !worker) return;
        dispatch(
            setFormValuesWorkerMoySkladAction({
                worker_id: worker?.id,
                store_id: findedSklad.id,
                meta_data: findedSklad.meta,
                name: findedSklad.name,
                address: findedSklad.address,
            }),
        );
    }

    return (
        <Dialog
            open={isOpen}
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableEscapeKeyDown
            onClose={onClose}
        >
            <DialogTitle>
                <div className="close-icon-box">
                    <CloseRoundedIcon
                        sx={{ color: '#CCCCCC', fontSize: '2rem' }}
                        onClick={onClose}
                    />
                </div>
                <Box className="edit-worker-specializations-dialog-title">
                    <Typography sx={{ fontSize: '24px', fontWeight: '600', lineHeight: '28.8px' }}>
                        Привязать склад
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <div className="edit-worker-fio-form-wrapper">
                    <TextField
                        fullWidth
                        name="name"
                        label="Введите название склада"
                        onChange={changeInpHandleDebounced}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div>
                        <FormControl component="fieldset">
                            <FormLabel
                                component="legend"
                                sx={{ color: 'black', fontSize: '16px', fontWeight: '500', paddingTop: '15px' }}
                            >
                                Результаты поиска ({sklads ? sklads?.length : 0})
                            </FormLabel>
                            <FormGroup
                                aria-label="position"
                                row
                            >
                                <div className="checkbox-container">
                                    <RadioGroup
                                        name="sklad_radio"
                                        onChange={onChangeFormHandler}
                                    >
                                        {sklads?.map((sklad) => (
                                            <React.Fragment key={sklad.id}>
                                                <FormControlLabel
                                                    value={sklad.id}
                                                    control={<Radio />}
                                                    label={sklad.name}
                                                    labelPlacement="start"
                                                    sx={{
                                                        fontSize: '14px',
                                                        marginLeft: 0,
                                                        height: '64px',
                                                        width: '560px',
                                                        fontWeight: '400',
                                                        justifyContent: 'space-between',
                                                    }}
                                                />
                                                <Divider sx={{ width: '100%' }} />
                                            </React.Fragment>
                                        ))}
                                    </RadioGroup>
                                </div>
                            </FormGroup>
                        </FormControl>
                    </div>
                </div>
                <FooterForm />
            </DialogContent>
        </Dialog>
    );
}

export default BindWorkerMoySklad;
