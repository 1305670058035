import { AppDispatch } from 'store/store';

import { searchApi } from '../../api';
import { ESearchActions } from '../../enums/actions.enum';
import {
    ISearchItem,
    ISearchReq,
    ISearchState,
    ISetFiltersSearchAction,
    ISetIndiciesSearchAction,
    ISetIsOpenSearchAction,
    ISetItemsSearchAction,
    ISetTextSearchAction,
} from '../../interfaces/search';

export const setItemsSearchAction = (payload: ISearchItem[] | null): ISetItemsSearchAction => ({
    type: ESearchActions.SetItems,
    payload,
});

export const setFiltersSearchAction = (payload: ISearchState['filters']): ISetFiltersSearchAction => ({
    type: ESearchActions.SetFilters,
    payload,
});

export const setIndiciesSearchAction = (payload: ISearchState['indicies']): ISetIndiciesSearchAction => ({
    type: ESearchActions.SetIndicies,
    payload,
});

export const setTextSearchAction = (payload: string | null): ISetTextSearchAction => ({
    type: ESearchActions.SetText,
    payload,
});

export const setIsOpenSearchAction = (payload: boolean): ISetIsOpenSearchAction => ({
    type: ESearchActions.SetIsOpen,
    payload,
});

export const getSearchThunk =
    ({ filters, indicies, text }: ISearchReq) =>
    async (dispatch: AppDispatch) => {
        try {
            const { data } = await searchApi.postSearch('?size=100', {
                filters,
                indicies,
                text,
            });
            const { items } = data;
            dispatch(setItemsSearchAction(items));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };
