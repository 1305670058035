import { TPriceList } from 'pages/price-lists/store/types';

import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

export default class PriceListsAPI {
    getPriceLists(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceLists`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    getPriceList(id: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceLists/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<TPriceList>;
    }

    getServicesTypes(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/prices/services`,
            params,
            isAuthorization: true,
        });
    }

    getCompanies(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies`,
            params,
            isAuthorization: true,
        });
    }

    getRegionality(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/regionality`,
            params: {
                ...params,
            },
            isAuthorization: true,
        }) as AxiosPromise<{ name: string; id: string }[]>;
    }

    createPriceList(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceLists`,
            data,
            isAuthorization: true,
        });
    }

    editPriceList(id: string, data: any) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceLists/${id}`,
            data,
            isAuthorization: true,
        });
    }

    copyPriceList(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceLists/copy`,
            data,
            isAuthorization: true,
        });
    }

    deletePriceList(id: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceLists/${id}`,
            isAuthorization: true,
        });
    }
}
