import { Divider } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '../../../../../components/inputs/Autocomplete';
import InputStack from '../../../../../components/inputs/InputStack';
import TextField from '../../../../../components/inputs/TextField';
import { IEducation } from '../../../../../interfaces/workers';
import {
    setAdditionalEducationAction,
    setEducationalProgramAction,
    setEducationTypeAction,
    setErrorsEditWorkerInfoAction,
    setInstitutionNameAction,
} from '../../../../../store/actions/edit/editWorkerInfo';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    additionalEducationSelector,
    educationalProgramSelector,
    educationTypeSelector,
    errorsEditWorkerInfoSelector,
    institutionNameSelector,
} from '../../../../../store/selectors/edit/editInfoSelectors';
import { workerDetailSelector } from '../../../../../store/selectors/workerSelectors';
import { workerEducationTypes } from '../index';

interface IProps {
    onChange: (education: IEducation) => void;
}

function EditEducation({ onChange }: IProps) {
    const dispatch = useAppDispatch();
    const worker = useSelector(workerDetailSelector);
    const errors = useSelector(errorsEditWorkerInfoSelector);
    const additionalEducation = useSelector(additionalEducationSelector);
    const educationType = useSelector(educationTypeSelector);
    const educationalProgram = useSelector(educationalProgramSelector);
    const institutionName = useSelector(institutionNameSelector);

    function getEducationType() {
        return worker?.education && worker.education.main_education.education_type
            ? {
                  value: worker.education.main_education.education_type,
                  label: worker.education.main_education.education_type,
              }
            : null;
    }

    function changeEducationType(option: { value: string; label: string } | null) {
        if (!option) {
            dispatch(setErrorsEditWorkerInfoAction({ ...errors, educationType: { message: 'Незаполненное поле' } }));
        } else {
            delete errors?.educationType;
            dispatch(setErrorsEditWorkerInfoAction(errors?.length ? { ...errors } : null));
        }
        dispatch(setEducationTypeAction(option && option.label));
    }

    function changeInstitutionName(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.value.trim()) {
            dispatch(setErrorsEditWorkerInfoAction({ ...errors, institutionName: { message: 'Незаполненное поле' } }));
        } else {
            delete errors?.institutionName;
            dispatch(setErrorsEditWorkerInfoAction(errors?.length ? { ...errors } : null));
        }
        dispatch(setInstitutionNameAction(e.target.value));
    }

    function changeEducationalProgram(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.value.trim()) {
            dispatch(
                setErrorsEditWorkerInfoAction({ ...errors, educationalProgram: { message: 'Незаполненное поле' } }),
            );
        } else {
            delete errors?.educationalProgram;
            dispatch(setErrorsEditWorkerInfoAction(errors?.length ? { ...errors } : null));
        }
        dispatch(setEducationalProgramAction(e.target.value));
    }

    function changeAdditionalEducation(values: string[]) {
        dispatch(setAdditionalEducationAction(values.length ? values.map((value) => ({ title: value })) : []));
    }

    useEffect(() => {
        if (!getEducationType()) {
            dispatch(
                setErrorsEditWorkerInfoAction({
                    educationType: { message: 'Обязательное поле' },
                    educationalProgram: { message: 'Незаполненное поле' },
                    institutionName: { message: 'Незаполненное поле' },
                }),
            );
        }
        dispatch(setAdditionalEducationAction(worker?.education?.additional_education || null));
        dispatch(setEducationTypeAction(worker?.education?.main_education?.education_type || null));
        dispatch(setInstitutionNameAction(worker?.education?.main_education?.institution_name || null));
        dispatch(setEducationalProgramAction(worker?.education?.main_education?.educational_program || null));
    }, []);

    useEffect(() => {
        onChange({
            main_education: {
                education_type: educationType || null,
                educational_program: educationalProgram || null,
                institution_name: institutionName || null,
            },
            certificates: [],
            additional_education: additionalEducation || [],
        });
    }, [additionalEducation, educationType, educationalProgram, institutionName]);

    return (
        <div>
            <Autocomplete
                name="educationType"
                errors={errors}
                options={workerEducationTypes.map((type) => ({ value: type, label: type }))}
                defaultValue={getEducationType()}
                onChange={changeEducationType}
                label="Тип образования"
                margin="normal"
            />
            <TextField
                name="institutionName"
                errors={errors}
                onChange={changeInstitutionName}
                defaultValue={worker?.education?.main_education?.institution_name}
                fullWidth
                labelText="Название учебного заведения"
                sxStyle={{ mb: 2, mt: 1 }}
            />
            <TextField
                name="educationalProgram"
                errors={errors}
                onChange={changeEducationalProgram}
                defaultValue={worker?.education?.main_education?.educational_program}
                fullWidth
                labelText="Образовательная программа или специализация"
                sxStyle={{ mb: 2 }}
            />
            <Divider sx={{ mb: 2 }} />
            <InputStack
                addButtonLabel={'Добавить образование'}
                onChange={changeAdditionalEducation}
                values={worker?.education?.additional_education?.map((education) => education.title)}
            />
        </div>
    );
}

export default EditEducation;
