import Centrifuge from 'centrifuge';

import { ECentrifugeActions } from '../../enums/actions.enum';
import { ISetCentrifugeInstanceAction, ISetIsConnectAction } from '../../interfaces/centrifuge';

export const setCentrifugeInstanceAction = (payload: Centrifuge | null): ISetCentrifugeInstanceAction => ({
    type: ECentrifugeActions.SetCentrifuge,
    payload,
});

export const setIsConnectAction = (payload: boolean | null): ISetIsConnectAction => ({
    type: ECentrifugeActions.SetIsConnect,
    payload,
});
