import { AppDispatch } from 'store/store';

import { chatsApi } from '../../api';
import { EChatGroupByUsersActions } from '../../enums/actions.enum';
import {
    IChatGroupByUser,
    IChatGroupByUserFilters,
    ISetChatGroupByUsersIsLoadingAction,
    ISetChatGroupByUsersPagination,
    ISetShouldReload,
} from '../../interfaces/chatGroupByUsers';
import { IPagination } from '../../interfaces/pagination';

export const setChatGroupByUsersAction = (payload: IChatGroupByUser[] | null) => ({
    type: EChatGroupByUsersActions.SetChatGroupByUsers,
    payload,
});

export const putChatGroupByUsersAction = (payload: IChatGroupByUser[] | null) => ({
    type: EChatGroupByUsersActions.PutChatGroupByUsers,
    payload,
});

export const setChatGroupByIsLoadingAction = (payload: boolean): ISetChatGroupByUsersIsLoadingAction => ({
    type: EChatGroupByUsersActions.SetChatGroupByUsersIsLoading,
    payload,
});

export const setChatGroupByPaginationAction = (payload: IPagination): ISetChatGroupByUsersPagination => ({
    type: EChatGroupByUsersActions.SetChatGroupByUsersPagination,
    payload,
});

export const insertChatGroupByUsersAction = (payload: IChatGroupByUser[]) => ({
    type: EChatGroupByUsersActions.InsertChatGroupByUsers,
    payload,
});

export const setChatGroupByUsersFiltersAction = (payload: IChatGroupByUserFilters) => ({
    type: EChatGroupByUsersActions.SetChatGroupByUsersFilters,
    payload,
});

export const setReloadAction = (payload: Partial<ISetShouldReload>) => ({
    type: EChatGroupByUsersActions.SetShouldReload,
    payload,
});

export const resetChatGroupByUsersAction = () => ({ type: EChatGroupByUsersActions.ResetChatGroupByUsers });

export const getChatGroupByUsersThunk =
    ({ page, size, ...rest }: any) =>
    async (dispatch: AppDispatch) => {
        try {
            dispatch(setChatGroupByIsLoadingAction(true));

            const { data } = await chatsApi.getChatsUsers({ page: page || 1, size: size || 50, ...rest });

            dispatch(setChatGroupByUsersAction(data.items));
            dispatch(
                setChatGroupByPaginationAction({
                    page: data.page,
                    size: data.size,
                    total: data.total,
                }),
            );
        } catch (err) {
            console.error(err);
            throw err;
        } finally {
            dispatch(setChatGroupByIsLoadingAction(false));
        }
    };

export const getChatGroupByUsersFiltersThunk = (params: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(
            setChatGroupByUsersFiltersAction({
                params,
                isLoading: true,
            }),
        );

        const { data } = await chatsApi.getChatsUsers({ page: 1, size: 50, ...params });

        dispatch(putChatGroupByUsersAction(data.items));
        dispatch(
            setChatGroupByPaginationAction({
                page: data.page,
                size: data.size,
                total: data.total,
            }),
        );
    } catch (err) {
        console.error(err);
        throw err;
    } finally {
        dispatch(
            setChatGroupByUsersFiltersAction({
                params,
                isLoading: false,
            }),
        );
    }
};
