import { MoreVert } from '@mui/icons-material';
import { Button, IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';

import { IPrice } from '../../../../../../interfaces/prices';
import { setIsOpenPriceDialogAction, setPriceIdPriceDialogAction } from '../../../../../../store/actions/priceDialog';
import { setIsOpenDeletePriceAction, setPriceAction } from '../../../../../../store/actions/prices';
import { useAppDispatch } from '../../../../../../store/hooks';

interface IProps {
    price: IPrice;
}

interface IButtonGroup {
    title: string;
    color: 'inherit' | 'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | undefined;
    handle: () => void;
}

function EditServiceButton({ price }: IProps) {
    const dispatch = useAppDispatch();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const ButtonsGroup: IButtonGroup[] = [
        {
            title: 'Редактировать',
            color: 'inherit',
            handle: openEditPriceModal,
        },
        {
            title: 'Удалить',
            color: 'error',
            handle: deletePrice,
        },
    ];

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    async function deletePrice() {
        await dispatch(setPriceAction(price));
        await dispatch(setIsOpenDeletePriceAction(true));
    }

    async function openEditPriceModal() {
        await dispatch(setPriceIdPriceDialogAction(price.id));
        await dispatch(setIsOpenPriceDialogAction({ isEdit: true, isOpen: true, isAdditional: !price?.is_additional }));
    }

    return (
        <>
            <IconButton
                onClick={handlePopoverClick}
                disableRipple
            >
                <MoreVert />
            </IconButton>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className="options-container">
                    {ButtonsGroup.map((item) => {
                        return (
                            <Button
                                key={item.title}
                                className="option-item"
                                color={item.color}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    item.handle();
                                    handlePopoverClose(event);
                                }}
                            >
                                {item.title}
                            </Button>
                        );
                    })}
                </div>
            </Popover>
        </>
    );
}

export default EditServiceButton;
