import { createSelector } from 'reselect';

import { RootState } from '../rootReducer';

const state = ({ priceDialog }: RootState) => priceDialog;

export const dialogStatePriceSelector = createSelector(state, ({ dialogState }) => dialogState);
export const priceIdPriceSelector = createSelector(state, ({ priceId }) => priceId);
export const categoriesPriceDialogSelector = createSelector(state, ({ categories }) => categories);
export const categoriesOptionsPriceDialogSelector = createSelector(state, ({ categories }) => {
    return categories?.map(({ id, name }) => ({ value: id, label: name || '' })) || null;
});
export const additionalPriceDialogSelector = createSelector(state, ({ additionalPrices }) => additionalPrices);
export const pricesOptionsPriceDialogSelector = createSelector(state, ({ additionalPrices }) => {
    return additionalPrices?.map(({ id, name }) => ({ value: id, label: name || '' })) || null;
});

export const categoriesPaginationPriceDialogSelector = createSelector(
    state,
    ({ categoriesPagination }) => categoriesPagination,
);

export const additionalPricesPaginationPriceDialogSelector = createSelector(
    state,
    ({ additionalPricesPagination }) => additionalPricesPagination,
);

export const formValuesPriceDialogSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsPriceDialogSelector = createSelector(state, ({ errors }) => errors);

export const selectedCategoriesPriceDialogSelector = createSelector(
    state,
    ({ selectedCategories }) => selectedCategories,
);
export const selectedAdditionalPricesPriceDialogSelector = createSelector(
    state,
    ({ selectedAdditionalPrices }) => selectedAdditionalPrices,
);
