import { Button, Checkbox, FormControlLabel, FormGroup, Paper } from '@mui/material';
import React, { MouseEvent } from 'react';

import { EIssueHelpType, EObserversFilter } from '../../../enums/issues.enum';
import { ObserversMock } from '../../../mockData/issues';
import './Observer.scss';

interface IProps {
    selectedObserverIds: string[] | null;
    selectedObserverIsnull: boolean;
    profileId: string | null;
    onChangeObserveIds: (observers_ids: string[] | null) => void;
    onChangeObserveIsNull: (observers__isnull: boolean) => void;
    onClear: () => void;
}

function Observer({
    selectedObserverIds,
    selectedObserverIsnull,
    profileId,
    onChangeObserveIds,
    onChangeObserveIsNull,
    onClear,
}: IProps) {
    function onClickFormGroup(e: MouseEvent) {
        e.stopPropagation();
    }

    function clearFilter() {
        onClear();
    }

    function selectObserve(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        checked
            ? onChangeObserveIds(
                  selectedObserverIds
                      ? [...selectedObserverIds, event.target.value]
                      : [event.target.value as EIssueHelpType],
              )
            : onChangeObserveIds(
                  selectedObserverIds && selectedObserverIds.filter((observeId) => observeId !== event.target.value),
              );
    }

    return (
        <Paper className="observe-wrapper">
            <div className="scroll-wrapper">
                {ObserversMock.map(({ value, label }) => {
                    switch (value) {
                        case EObserversFilter.CurrentUser: {
                            return (
                                <FormGroup
                                    key={value}
                                    sx={{ marginLeft: 1 }}
                                    onClick={onClickFormGroup}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={selectObserve}
                                                value={profileId}
                                                checked={
                                                    !!selectedObserverIds?.find((observeId) => observeId === profileId)
                                                }
                                            />
                                        }
                                        label={label}
                                    />
                                </FormGroup>
                            );
                        }
                        case EObserversFilter.Unassigned: {
                            return (
                                <FormGroup
                                    key={value}
                                    sx={{ marginLeft: 1 }}
                                    onClick={onClickFormGroup}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={() => onChangeObserveIsNull(!selectedObserverIsnull)}
                                                checked={selectedObserverIsnull}
                                            />
                                        }
                                        label={label}
                                    />
                                </FormGroup>
                            );
                        }
                        default:
                            return null;
                    }
                })}
            </div>
            <Button
                fullWidth
                onClick={clearFilter}
            >
                Очистить фильтр
            </Button>
        </Paper>
    );
}

export default Observer;
