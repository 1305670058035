import { createSelector } from 'reselect';

import { issueSelector } from './issuesSelectors';
import { workersSelector } from './workersSelectors';

import { RootState } from '../rootReducer';

const state = ({ candidatesModal }: RootState) => candidatesModal;
/* CM === CandidatesModal */
export const isOpenCMSelector = createSelector(state, ({ isOpen }) => isOpen);
export const filtersCMSelector = createSelector(state, ({ filters }) => filters);
export const selectedWorkersCMSelector = createSelector(state, ({ selectedWorkers }) => selectedWorkers);
export const workersCMSelector = createSelector(
    [workersSelector, issueSelector, selectedWorkersCMSelector],
    (workers, issue, selectedWorkers) => {
        if (!workers || !issue) return null;

        return workers.map((worker) => {
            const isSelected = selectedWorkers?.find(({ id }) => id === worker.id);
            if (isSelected) {
                return { ...worker, isChecked: true };
            } else {
                return { ...worker, isChecked: false };
            }
        });
    },
);
export const isLoadingCMSelector = createSelector(state, ({ isLoading }) => isLoading);
