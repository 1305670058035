import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import Accordion from '../../../../components/Accordion';
import { getNotesMasterIssueThunk } from '../../../../store/actions/issues';
import { useAppDispatch } from '../../../../store/hooks';
import { issueSelector, notesIssuesSelector } from '../../../../store/selectors/issuesSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import './NotesMaster.scss';

function NotesMaster() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const notesIssue = useSelector(notesIssuesSelector);

    const { id: issueId } = issue || {};

    useEffect(() => {
        async function handle() {
            try {
                if (!issueId) return;
                await dispatch(getNotesMasterIssueThunk(issueId));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [issueId]);

    return notesIssue ? (
        <Accordion
            title={<Typography>Заметки мастера</Typography>}
            sx={{ mt: 3 }}
            expanded={false}
        >
            <div className="note-issue-list">
                {notesIssue.map(({ name, text, slug }) => (
                    <div
                        key={slug}
                        className="note-issue"
                    >
                        <div className="note-name">{name}</div>
                        <pre className="note-text">{text || <span>заметки нет</span>}</pre>
                    </div>
                ))}
            </div>
        </Accordion>
    ) : null;
}

export default NotesMaster;
