import { variables } from 'styles/variables';

import { blueGrey, common } from '@mui/material/colors';
import { ruRU as coreRU } from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';
import { ruRU as dataGridRU } from '@mui/x-data-grid';
import { ruRU } from '@mui/x-date-pickers';

declare module '@mui/material' {
    interface ButtonPropsVariantOverrides {
        filter: true;
        filterActive: true;
        filterAppType: true;
        delete: true;
    }
}

const scrollBarStyles = {
    '&::-webkit-scrollbar-track': {
        position: 'absolute',
        right: 10,
        borderRadius: 10,
        backgroundColor: '#F5F5F5',
        margin: '5px 0 0 0',
    },
    '&::-webkit-scrollbar': {
        width: 4,
        height: 4,
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: 10,
        backgroundColor: '#949494',
    },
    '::-webkit-scrollbar:horizontal': {
        padding: 0,
    },
    '::-webkit-scrollbar:vertical': {
        padding: 0,
    },
};

const theme = createTheme(
    {
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    div: scrollBarStyles,
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        background: common.white,
                        border: blueGrey[100],
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    listbox: {
                        '& .MuiAutocomplete-option[aria-selected="true"]': {
                            backgroundColor: '#fff',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    },
                },
                variants: [
                    {
                        props: { variant: 'contained' },
                        style: {
                            height: '36.5px',
                            fontSize: '14px',
                            fontWeight: '600',
                            backgroundColor: '#0040F1',
                            ':hover': {
                                backgroundColor: '#0040F1',
                            },
                        },
                    },
                    {
                        props: { variant: 'outlined' },
                        style: {
                            color: '#0040F1',
                            fontWeight: '600',
                            borderColor: '#0040F1',
                            backgroundColor: '#fff',
                            ':hover': {
                                backgroundColor: '#fff',
                                borderColor: '#0040F1',
                            },
                        },
                    },
                    {
                        props: { variant: 'filter' },
                        style: {
                            backgroundColor: '#d1d5da',
                            boxShadow: variables.shadows.button.default,
                            ':hover': {
                                backgroundColor: '#d1d5da',
                                boxShadow: variables.shadows.button.hover,
                            },
                        },
                    },
                    {
                        props: { variant: 'filterActive' },
                        style: {
                            backgroundColor: '#232d42',
                            color: '#fff',
                            boxShadow: variables.shadows.button.default,
                            ':hover': {
                                backgroundColor: '#232d42',
                                boxShadow: variables.shadows.button.hover,
                            },
                        },
                    },
                    {
                        props: { variant: 'delete' },
                        style: {
                            height: '36.5px',
                            fontSize: '14px',
                            fontWeight: '600',
                            color: '#fff',
                            backgroundColor: '#F56767',
                            boxShadow: variables.shadows.button.default,
                            ':hover': {
                                backgroundColor: '#F56767', // '#D35151'
                                boxShadow: variables.shadows.button.hover,
                            },
                        },
                    },
                ],
            },
        },
        typography: {
            subtitle1: {
                fontSize: 12,
            },
        },
    },
    ruRU,
    dataGridRU,
    coreRU,
);
theme.typography.h5 = { ...theme.typography.h5, fontWeight: 600 };

export default theme;
