import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EPricesServicesTabs } from '../../../../../enums/pricesServices.enum';
import { TErrors } from '../../../../../interfaces';
import {
    createPriceThunk,
    setErrorsPriceDialogAction,
    setIsOpenPriceDialogAction,
    updatePriceThunk,
} from '../../../../../store/actions/priceDialog';
import { getPricesThunk } from '../../../../../store/actions/prices';
import { useAppDispatch } from '../../../../../store/hooks';
import {
    errorsPriceDialogSelector,
    formValuesPriceDialogSelector,
} from '../../../../../store/selectors/priceDialogSelector';
import { priceSelector, pricesFiltersSelector, pricesSelector } from '../../../../../store/selectors/pricesSelectors';
import { priceServiceSelector, priceServicesTabSelector } from '../../../../../store/selectors/pricesServicesSelector';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';
import showToast from '../../../../../utils/helpers/showToast';

interface IPriceFormProps {
    isEdit: boolean;
    isAdditional?: boolean;
}

const requiredMessage = 'Незаполненое поле';

function FooterForm({ isEdit, isAdditional }: IPriceFormProps) {
    const dispatch = useAppDispatch();

    const prices = useSelector(pricesSelector);
    const price = useSelector(priceSelector);
    const priceService = useSelector(priceServiceSelector);
    const formValues = useSelector(formValuesPriceDialogSelector);
    const filters = useSelector(pricesFiltersSelector);
    const serviceTabs = useSelector(priceServicesTabSelector);
    const errors = useSelector(errorsPriceDialogSelector);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        // eslint-disable-next-line no-shadow
        const errors: TErrors = {};

        if (!formValues?.category_ids?.length) {
            errors.category_ids = { message: requiredMessage };
        }
        if (!formValues?.name) {
            errors.price_name = { message: requiredMessage };
        }
        if (formValues?.amount == null) {
            errors.amount = { message: requiredMessage };
        }
        if (formValues?.partner_id == null) {
            errors.company_id = { message: requiredMessage };
        }

        const hasError = !!Object.keys(errors).length;
        dispatch(setErrorsPriceDialogAction((hasError && errors) || null));
        return !hasError;
    }, [formValues]);

    useEffect(() => {
        if (errors) {
            validator();
        }
    }, [formValues]);

    async function updatePrice() {
        if (validator()) {
            try {
                if (!price?.id || !formValues) return;
                setIsLoading(true);
                await dispatch(
                    updatePriceThunk(price?.id, {
                        ...formValues,
                        //TODO: Временное решение на бэке
                        category_ids: [formValues.category_ids?.length ? formValues.category_ids[0] : ''],
                        service_id: price.service?.id,
                        is_additional: price.is_additional,
                    }),
                );
                await (prices &&
                    dispatch(
                        getPricesThunk({
                            filters: {
                                ...(filters || {}),
                                is_additional: !isAdditional,
                                service_id__in: price.service?.id,
                            },
                        }),
                    ));
                await dispatch(setIsOpenPriceDialogAction({ isOpen: false, isEdit: false, isAdditional: false }));
                showToast({
                    isVisible: true,
                    message: 'Изменения сохранены',
                });
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    async function createPrice() {
        if (validator()) {
            try {
                if (!formValues) return;

                setIsLoading(true);
                await dispatch(
                    createPriceThunk({
                        ...formValues,
                        //TODO: Временное решение на бэке
                        category_ids: [formValues.category_ids?.length ? formValues.category_ids[0] : ''],
                        is_additional: !isAdditional,
                        service_id: priceService?.id,
                    }),
                );
                await (prices &&
                    dispatch(
                        getPricesThunk({
                            filters: {
                                ...(filters || {}),
                                is_additional: serviceTabs !== EPricesServicesTabs.BasicServices,
                                service_id__in: priceService?.id,
                            },
                        }),
                    ));
                await dispatch(setIsOpenPriceDialogAction({ isOpen: false, isEdit: false, isAdditional: false }));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    async function submitForm() {
        switch (true) {
            case isEdit:
                await updatePrice();
                break;
            default:
                await createPrice();
                break;
        }
    }

    function closeDialog() {
        dispatch(setIsOpenPriceDialogAction({ isEdit: false, isOpen: false }));
    }

    return (
        <Box mt={2}>
            <Button
                onClick={closeDialog}
                variant="outlined"
            >
                Отмена
            </Button>
            <LoadingButton
                onClick={submitForm}
                loading={isLoading}
                variant="contained"
                sx={{ ml: 1 }}
            >
                {isEdit ? 'Редактировать' : 'Создать'}
            </LoadingButton>
        </Box>
    );
}

export default FooterForm;
